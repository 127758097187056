import React from 'react';
import Text from '../Text';
import Title from '../Title';
import { BoxContainer, BoxWhite } from '../../GlobalStyles';
import { Header, ItemCard } from './style';
import defaultImage from '../../assets/images/image-default.png';
import { EnabledActionsCrudEnum } from '../../core/interfaces/IActionsCrud';
import ActionsCrud from '../ActionsCrud/ActionsCrud';
import { useNavigate } from 'react-router-dom';
import { convertDateToString } from '../../helpers/dates.helper';
import { Empty, Select } from 'antd';

interface IProps {
  entity: any;
  title: string;
  profilePath: string;
  disable?: (entityId: number) => void;
}

function EntityList({ entity, title, profilePath, disable }: IProps) {
  const { Option } = Select;
  const navigate = useNavigate();
  const filters = ['A-Z', 'Z-A', 'Data de entrada', 'Status'];

  const viewProfile = (entityId: number) => {
    navigate(profilePath, { state: { id: entityId } });
  };

  const column = (label: string, value: string) => {
    return (
      <div className="column">
        <Text weight={700}>{label}</Text>
        <span>{value}</span>
      </div>
    );
  };

  return (
    <BoxWhite>
      <Header>
        <Title size={18}> {title} </Title>
        <Select style={{ width: '100%', maxWidth: '200px' }} defaultValue={0}>
          {filters.map((item, index) => (
            <Option value={index} key={index}>
              {item}
            </Option>
          ))}
        </Select>
      </Header>
      {entity.length > 0 ? (
        <BoxContainer
          gap="10px"
          noPadding={true}
          style={{ overflow: 'auto', paddingBottom: '8px' }}
        >
          {entity.map((item: any, key: number) => {
            return (
              <ItemCard key={key} onClick={() => viewProfile(item.entityId)}>
                <div className="image">
                  <img alt="" src={item.url || defaultImage} />
                </div>
                <Text weight={700}> {item.name} </Text>
                {column('Data de entrada', convertDateToString(item.date))}
                {column(
                  'Última atualização',
                  convertDateToString(item.lastUpdate),
                )}
                {column('Status atual', item.active ? 'Ativo' : 'Inativo')}
                {column('E-mail', item.owner.email)}
                {disable && (
                  <div className="column">
                    <ActionsCrud
                      style={{ display: 'flex' }}
                      boxPosition="left"
                      remove={() => disable}
                      enabledActions={[EnabledActionsCrudEnum.REMOVE]}
                    />
                  </div>
                )}
              </ItemCard>
            );
          })}
        </BoxContainer>
      ) : (
        <BoxContainer noPadding={true}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Não existem dados cadastrados'}
          />
        </BoxContainer>
      )}
    </BoxWhite>
  );
}

export default EntityList;
