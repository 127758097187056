import { IZoomMeeting } from '../../core/interfaces/zoom/IZoom';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import AuthZoomService from './AuthZoomService';

class ZoomMeetingService {
  private requestMapping = 'zoom';

  public static init() {
    return new ZoomMeetingService();
  }

  public create(scheduleId: number) {
    const body = {
      token: AuthZoomService.init().getToken(),
      scheduleId,
    };

    return api
      .post<IZoomMeeting>(`${this.requestMapping}/create-meeting`, body)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ZoomMeetingService;
