import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function DiagnosisInfo() {
  return (
    <BoxInfo
      title="Diagnóstico"
      image={image}
      description={
        <>
          <p>
            Diagnóstico é a forma de avaliação da maturidade do negócio, e é por
            meio do diagnóstico, respondido pela sua startup, que é possível
            apontar a fase de desenvolvimento que cada ela está. À medida que a
            aceleradora vai cadastrando sua metodologia, incluindo as fases do
            negócio e os pilares, a startup vai selecionando as respostas que
            mais fazem sentido à maturidade do seu negócio.
          </p>
        </>
      }
    />
  );
}

export default DiagnosisInfo;
