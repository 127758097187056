import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';
import print from '../../assets/images/print-helper.png';

function ProfileInfosInfo() {
  return (
    <BoxInfo
      image={image}
      title="O que será alterado aqui?"
      description={
        <p>
          As tags adicionadas aqui servirão para alimentar as opções nos campos
          de seleção dos perfis no momento de seu cadastro, tal qual o exemplo
          abaixo.
        </p>
      }
      extra={<img alt="" src={print} style={{ width: '100%' }} />}
    />
  );
}

export default ProfileInfosInfo;
