import React from 'react';
import { Roles } from '../enums';
import { $enum } from 'ts-enum-util';
import {
  Home,
  Accelerator,
  Valuation,
  Member,
  Profile,
  Diagnostic,
  Metric,
  Course,
  Methodology,
  Startup,
  Mentor,
  Investor,
  Tool,
  Mentorship,
  Hiring,
  Calendar,
  ProfileInfo,
  InvestmentRound,
} from '../../components/Icons/Icon';
import { BsMegaphone } from 'react-icons/bs';

/*Definição de menus e as regras para exibição*/
export const routes = [
  {
    path: '/dashboard',
    title: 'Home',
    icon: <Home />,
    isMenu: true,
    authorize: [
      $enum(Roles).getKeyOrDefault(Roles.V_STARTUPS),
      $enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_ACCELERATOR),
      $enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_STARTUP),
      $enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_INVESTOR),
      $enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_MENTOR),
    ],
  },
  {
    path: '/accelerator',
    title: 'Aceleradora',
    icon: <Accelerator />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_ACCELERATOR)],
  },
  {
    path: '/profiles',
    title: 'Perfil',
    icon: <Profile />,
    isMenu: true,
    authorize: [
      $enum(Roles).getKeyOrDefault(Roles.C_PROFILE),
      $enum(Roles).getKeyOrDefault(Roles.V_PROFILE),
    ],
  },
  {
    path: '/users',
    title: 'Usuários',
    icon: <Member />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_USER)],
  },
  {
    path: '/members-startup',
    title: 'Membros',
    icon: <Member />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_MEMBER_STARTUP)],
  },
  {
    path: '/members-accelerator',
    title: 'Membros',
    icon: <Member />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_MEMBER_ACCELERATOR)],
  },
  {
    path: '/courses-startup',
    title: 'Cursos',
    icon: <Course />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_COURSE_STARTUP)],
  },
  {
    path: '/courses',
    title: 'Cursos',
    icon: <Course />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_COURSE)],
  },
  {
    path: '/valuations/valuation',
    title: 'Valuation',
    icon: <Valuation />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_VALUATION)],
  },
  {
    path: '/valuations/valuation-startup',
    title: 'Valuation',
    icon: <Valuation />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_VALUATION_ANSWER)],
  },
  {
    path: '/diagnostics/diagnostic',
    title: 'Diagnóstico',
    icon: <Diagnostic />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_DIAGNOSTIC)],
  },
  {
    path: '/diagnostics/diagnostic-startup',
    title: 'Diagnóstico',
    icon: <Diagnostic />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_DIAGNOSTIC_ANSWER)],
  },
  {
    path: '/methodologies',
    title: 'Metodologia',
    icon: <Methodology />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_METHODOLOGY)],
  },
  {
    path: '/metrics',
    title: 'Métricas',
    icon: <Metric />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_OKR)],
  },
  {
    path: '/startups',
    title: 'Startups',
    icon: <Startup />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_STARTUPS)],
  },
  {
    path: '/mentors',
    title: 'Mentores',
    icon: <Mentor />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_MENTOR)],
  },
  {
    path: '/accelerator-mentor',
    title: 'Mentores',
    icon: <Mentor />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_ACCELERATOR)],
  },
  {
    path: '/investors',
    title: 'Investidores',
    icon: <Investor />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_INVESTOR)],
  },
  {
    path: '/tools',
    title: 'Ferramentas',
    icon: <Tool />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_TOOL)],
  },
  {
    path: '/mentorship',
    title: 'Mentorias',
    icon: <Mentorship />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_MENTORING_MENTOR)],
  },
  {
    path: '/startup-mentorships',
    title: 'Mentorias',
    icon: <Mentorship />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_MENTORING_STARTUP)],
  },
  {
    path: '/hiring',
    title: 'Contratação',
    icon: <Hiring />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_HIRING)],
  },
  {
    path: '/calendar',
    title: 'Agenda',
    icon: <Calendar />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_SCHEDULE)],
  },
  {
    path: '/profile-infos',
    title: 'Info. de Perfil',
    icon: <ProfileInfo />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_PROFILE)],
  },
  {
    path: '/investment-round/investor',
    title: 'Rodada de Investimento',
    icon: <InvestmentRound />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_INVESTMENT_INVESTOR)],
  },
  {
    path: '/investment-round/startup',
    title: 'Rodada de Investimento',
    icon: <InvestmentRound />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_INVESTMENT_STARTUP)],
  },
  {
    path: '/startup/vacancies',
    title: 'Vagas',
    icon: <BsMegaphone />,
    isMenu: true,
    authorize: [$enum(Roles).getKeyOrDefault(Roles.V_DASHBOARD_STARTUP)],
  },
];
