import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Title from '../Title';
import { Select, Checkbox, Pagination, notification } from 'antd';
import { Header, SelectedCourseWrapper, WrapperBody } from './styles';
import { ModalBody } from '../../GlobalStyles';
import CardCourse from '../CardCourse/CardCourse';
import { IPropsModal } from '../../core/interfaces/modal/IPropsModal';
import ICourseCategory from '../../core/interfaces/course/ICourseCategory';
import FooterPage from '../FooterPage';
import { useAuth } from '../../core/hooks/auth';
import ErrorNotification from '../notifications/errorNotification';
import { isEmpty, isNull } from 'lodash';
import CoursePhaseService from '../../services/course-phase/CoursePhaseService';
import ICoursePhaseRequest from '../../core/interfaces/course-phase/ICoursePhaseRequest';
import IPhases from '../../core/interfaces/models/IPhases';
import Text from '../Text';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { fetchCourses } from '../../store/registered-courses.slice';
import CourseCategoryService from '../../services/course/CourseCategoryService';
import useLoading from '../../core/hooks/useLoading';
import usePagination from '../../core/hooks/usePagination';
import IPillars from '../../core/interfaces/models/IPillars';

interface Props extends IPropsModal {
  phase: IPhases | null;
  pillar: IPillars | null;
  reload(): void;
}

function RegisteredCourses({
  phase,
  pillar,
  reload,
  visible,
  onCancel,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { logged } = useAuth();
  const { courses } = useSelector(
    (state: RootState) => state.registeredCoursesSlice,
  );
  const categoryLoading = useLoading();
  const { currentPage, changePagination } = usePagination();

  const teacherId = useMemo(() => logged?.entityId, [logged]);
  const coursePhaseService = useMemo(() => CoursePhaseService.init(), []);
  const courseCategoryService = useMemo(() => CourseCategoryService.init(), []);

  const [categories, setCategories] = useState<ICourseCategory[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);

  const getCourses = useCallback(
    (categoriesId: number[] = []) => {
      dispatch(
        fetchCourses({
          teacherId,
          page: currentPage,
          ...(!isEmpty(categoriesId) && {
            categoriesId: categoriesId.join(','),
          }),
        }),
      ).catch(() => {
        ErrorNotification({
          defaultMessage: 'Erro ao listar cursos',
        });
      });
    },
    [dispatch, currentPage, teacherId],
  );

  const getCategories = () => {
    categoryLoading.showLoading();
    courseCategoryService
      .findAll()
      .then((data) => setCategories(data))
      .catch(() =>
        ErrorNotification({
          defaultMessage: 'Erro ao listar categorias',
        }),
      )
      .finally(categoryLoading.hideLoading);
  };

  const checkSelectedCourses = (courseId: number) => {
    return selectedCourses.includes(courseId);
  };

  const changeSelectedCourses = (courseId: number): void => {
    if (checkSelectedCourses(courseId)) {
      setSelectedCourses(selectedCourses.filter((id) => id !== courseId));
    } else {
      setSelectedCourses(selectedCourses.concat(courseId));
    }
  };

  const selectCourses = () => {
    const request: ICoursePhaseRequest = {
      coursesId: selectedCourses,
      phaseId: isNull(phase) ? 0 : Number(phase.entityId),
      pillarId: isNull(pillar) ? 0 : Number(pillar.entityId),
    };

    coursePhaseService
      .linkCoursesToPhase(request)
      .then(() => {
        closeModal();
        getCourses();
        reload();
        setSelectedCourses([]);
        notification.success({
          duration: 3.5,
          message: 'Cursos selecionados com sucesso',
        });
      })
      .catch(() => {
        ErrorNotification({
          defaultMessage: 'Erro ao selecionar cursos',
        });
      });
  };

  const closeModal = (): void => {
    setSelectedCourses([]);
    onCancel();
  };

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <ModalBody
      width={'100%'}
      maxWidth={'1100px'}
      footer={null}
      margin="0 auto"
      closable={true}
      open={visible}
      onCancel={closeModal}
      destroyOnClose={true}
      paddingBody="0"
      closeIconColor="#fff"
    >
      <Header>
        <Title margin="0.5rem 0 1.5rem 0" size={24} color="#fff" align="center">
          Cursos Cadastrados
        </Title>
        <Select
          loading={categoryLoading.loading}
          mode="multiple"
          style={{
            width: '100%',
            maxWidth: '450px',
            margin: '0 auto',
            display: 'block',
          }}
          placeholder="Pesquisar"
          onChange={getCourses}
          optionLabelProp="label"
          showSearch
        >
          {categories.map((category, index) => (
            <Select.Option
              value={category.entityId}
              key={`category_key_${index}`}
              label={category.name}
            >
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Header>
      <WrapperBody>
        {isEmpty(courses.content) && (
          <Text margin="auto" size={20} weight={600}>
            Cursos vazios
          </Text>
        )}
        {courses.content.map((course, index) => {
          const isSelected = checkSelectedCourses(course.entityId);
          return (
            <SelectedCourseWrapper
              key={`key_course_${index}`}
              onClick={() => changeSelectedCourses(course.entityId)}
            >
              <Checkbox value={isSelected} checked={isSelected} />
              <CardCourse
                {...course}
                height="100%"
                padding="8px"
                boxShadow={isSelected ? '0 0 0 2px #960096' : ''}
                borderColor={
                  isSelected ? 'transparent' : 'var(--support-color)'
                }
              />
            </SelectedCourseWrapper>
          );
        })}
      </WrapperBody>
      <Pagination
        style={{ padding: '1rem' }}
        total={courses.totalElements}
        pageSize={courses.size}
        onChange={changePagination}
      />

      <FooterPage
        position="sticky"
        text="Selecionar cursos"
        save={selectCourses}
        disabled={isEmpty(selectedCourses)}
      />
    </ModalBody>
  );
}

export default RegisteredCourses;
