import { Avatar, Col, notification, Row } from 'antd';
import React, { useState } from 'react';
import ActionsCrud from '../../../../components/ActionsCrud';
import BoxStatus from '../../../../components/BoxStatus/BoxStatus';
import { EnabledActionsCrudEnum } from '../../../../core/interfaces/IActionsCrud';
import { Info, ProfileBox, PurppleText } from '../style';
import UserService from '../../../../services/UserService';

interface IProps {
  entityId: number;
  name?: string;
  date: string;
  lastUpdate: string;
  email: string;
  status: boolean;
  updateList?: () => void;
  showLoading: () => void;
  hideLoading: () => void;
}

function ProfileItem(props: IProps) {
  const userService = UserService.init();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const md5 = require('md5');
  const getHash = (email: string) => {
    return md5(email);
  };

  const [avatar] = useState<string>(
    `https://www.gravatar.com/avatar/${getHash(props.email)}?d=identicon`,
  );

  const changeStatus = () => {
    props.showLoading();
    userService
      .changeStatus(props.entityId)
      .then(() => {
        notification.success({
          message: 'Sucesso',
          description: 'Perfil desativado com sucesso',
        });
        props.updateList && props.updateList();
      })
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Erro ao desativar perfil',
        });
      })
      .finally(() => {
        props.hideLoading();
      });
  };

  const editProfile = () => {
    alert('edit');
  };

  return (
    <ProfileBox>
      <Row>
        <Col xxl={23} sm={23} xs={23}>
          <Row>
            <Col xxl={1} xl={1} lg={2} md={2} sm={2} xs={4}>
              <Avatar src={avatar} size={48} />
            </Col>
            <Col xxl={8} xl={6} lg={5} md={4} sm={11} xs={20}>
              <Info width="200px" overflow={'true'} title={`${props.name}`}>
                <div className="label">Nome do perfil</div>
                <PurppleText fontBold={true}>
                  {props?.name || 'Sem Perfil'}
                </PurppleText>
              </Info>
            </Col>
            <Col xxl={5} xl={7} lg={6} md={7} sm={11} xs={24}>
              <Info width="200px" overflow={'true'} title={`${props.email}`}>
                <div className="label">E-mail</div>
                <div>{props.email}</div>
              </Info>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={12}>
              <Info width="120px">
                <div className="label">Data de entrada</div>
                <div>{props.date}</div>
              </Info>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={12}>
              <Info width="140px">
                <div className="label">Última atualização</div>
                <div>{props.lastUpdate || 'Sem atualização'}</div>
              </Info>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              xxl={2}
              xl={2}
              lg={3}
              md={3}
              sm={8}
              xs={24}
            >
              <Info width="110px">
                <div className="label">Status atual</div>
                <div>
                  <BoxStatus key={props.entityId} status={props.status} />
                </div>
              </Info>
            </Col>
          </Row>
        </Col>
        <Col sm={1} xs={1} xxl={'20px'}>
          <div className="actionsButtons">
            <ActionsCrud
              style={{
                display: 'flex',
              }}
              remove={() => changeStatus()}
              edit={() => editProfile()}
              enabledActions={[
                EnabledActionsCrudEnum.EDIT,
                EnabledActionsCrudEnum.REMOVE,
              ]}
            />
          </div>
        </Col>
      </Row>
    </ProfileBox>
  );
}

export default ProfileItem;
