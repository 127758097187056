import React from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';
import Diagnosis from '../../pages/diagnosis/Diagnosis';
import DiagnosisStartup from '../../pages/diagnosis/startup/Diagnosis';
import DiagnosisFormStartup from '../../pages/diagnosis/startup/DiagnosisForm';
import Valuation from '../../pages/valuation/Valuation';
import ValuationQuestions from '../../pages/valuation/ValuationQuestions';
import ResultsValuation from '../../pages/results/valuation';
import RegisterConclude from '../../pages/security/register-conclude';
import RegisterProfile from '../../pages/security/register-conclude/profile/box';
import FinalRegister from '../../pages/security/register-conclude/final-register';
import StartupValuation from '../../pages/startup/Valuation';
import { IUser } from '../interfaces';
import Methodology from '../../pages/methodology/Methodology';
import Metrics from '../../pages/startup/Metrics';
import MetricsForm from '../../pages/startup/Metrics/MetricsForm';
import MethodologyFormView from '../../pages/client/Methodology/MethodologyFormView';
import StartupDetails from '../../pages/client/StartupDetails/StartupDetails';
import KpiDetails from '../../pages/client/KpiDetails';
import Courses from '../../pages/courses/Courses';
import Course from '../../pages/courses/Course';
import StartupDiagnosticsList from '../../pages/client/Dashboard/StartupDiagnostics/StartupDiagnosticsList';
import ExerciseAnswer from '../../pages/courses/Answer/ExerciseAnswer';
import CourseCategory from '../../pages/courses/Category/CourseCategory';
import ProtectedRoute from './ProtectedRoute';
import { $enum } from 'ts-enum-util';
import { Roles } from '../enums';
import Dashboard from '../../pages/dashboard/Dashboard';
import StartupCourses from '../../pages/startup/Courses';
import AttendCourse from '../../components/AttendCourse';
import AnswerExercise from '../../pages/startup/AnswerExercise';
import StartupJourney from '../../pages/startup/Dashboard/StartupJourney';
import DiagnosisFormView from '../../pages/client/Diagnosis/DiagnosisFormView';
import ErrorPage from '../../pages/error-page';
import Login from '../../pages/security/login/Login';
import Profiles from '../../pages/admin/profiles/Profiles';
import ProfileList from '../../pages/admin/profiles/ProfileList';
import MemberAccelerator from '../../pages/client/Member/MemberAccelerator';
import MemberStartup from '../../pages/startup/Member/MemberStartup';
import Notifications from '../../pages/notifications/Notifications';
import Mentorship from '../../pages/mentorship/Mentorship';
import MentorshipScheduling from '../../pages/mentorship/MentorshipScheduling';
import ClientStartupJourney from '../../pages/client/Dashboard/StartupJourney';
import ExerciseCorrection from '../../pages/courses/Exercises/ExerciseCorrection/ExerciseCorrection';
import Zoom from '../../pages/zoom/Zoom';
import StartupJourneyReport from '../../components/StartupJourneyReport';
import Investor from '../../pages/investor/Investor';
import Mentors from '../../pages/startup/Mentors/Mentors';
import Mentor from '../../pages/Mentor/Mentor';
import MentorProfile from '../../pages/startup/Mentors/Profile/MentorProfile';
import ScheduleMentor from '../../pages/startup/Mentors/Schedule/ScheduleMentor';
import StartupWeekCalendar from '../../pages/startup/Mentors/Schedule/StartupWeekCalendar';
import ProfileInfos from '../../pages/admin/ProfileInfos/ProfileInfos';
import RenewPassword from '../../pages/security/renew-password';
import Template from '../../components/Template';
import InvestedStartup from '../../pages/investor/InvestedStartup/InvestedStartup';
import InvestmentRound from '../../pages/investor/InvestmentRound/InvestmentRound';
import InvestmentForm from '../../pages/investor/InvestmentRound/InvestmentForm/InvestmentForm';
import InvestmentRoundDetails from '../../pages/investor/InvestmentRound/InvestmentRoundDetails/InvestmentRoundDetails';
import InvestmentRoundList from '../../pages/startup/InvesmentRound/InvestmentRoundList';
import SubmitPich from '../../pages/investor/InvestmentRound/InvestmentRoundDetails/SubmitPitch';
import SubmittedStartups from '../../pages/investor/InvestmentRound/SubmittedStartups';
import InvestorChat from '../../pages/investor/InvestorChat';
import WAAC from '../../../src/pages/admin/WeightedAverageCapitalCost/WAAC';
import Tools from '../../pages/admin/Tools/Tools';
import ConfigValuation from '../../pages/admin/Tools/Config/ConfigValuation';
import ConfigCourses from '../../pages/admin/Tools/Config/ConfigCourses';
import ScheduleCalendar from '../../components/ScheduleCalendar/ScheduleCalendar';
import MyProfile from '../../components/Profiles/MyProfile/MyProfile';
import AcceleratorMentor from '../../pages/client/AcceleratorMentor';
import StartupList from '../../pages/client/startup/StartupList';
import StartupVacancies from '../../pages/startup/StartupVacancies';
import ViewProfileStartup from '../../components/Profiles/ViewProfile/ViewProfileStartup';
import InactivePage from '../../pages/security/inacitve-user/InactivePage';
import ValuationView from '../../pages/valuation/ValuationView';
import PreRegister from '../../pages/security/pre-register/PreRegister';
import UsersPage from '../../pages/admin/Users/UsersPage';
import { CoursePlans } from '../../pages/startup/Courses/CoursePlans';
import { MyAccelerator } from '../../pages/startup/MyAccelerator';
// import { StartupsList } from '../../pages/startup/MyAccelerator/StartupsList';
import { CoursesList } from '../../pages/startup/MyAccelerator/CoursesList';

interface IRoutesProps {
  user?: IUser;
}

export function PrivateRoutes(props: IRoutesProps) {
  const profile = props?.user?.profile;

  return (
    <>
      {profile === undefined ||
      profile.length === 0 ||
      props.user?.status === 'PENDING' ? (
        <Routes>
          <Route path="/" element={<RegisterConclude />} />
          <Route path="renew-password" element={<RenewPassword />} />
          <Route path="/register/conclude" element={<RegisterConclude />} />
          <Route path={'/login'} element={<Login />} />
          <Route
            path="/register/conclude/profile"
            element={<RegisterProfile />}
          />
          <Route
            path="/register/conclude/profile/final"
            element={<FinalRegister />}
          />
          <Route path="/pre-register" element={<PreRegister />} />
        </Routes>
      ) : (
        <>
          {props?.user?.active ? (
            <>
              <Routes>
                {/*Redefinir senha*/}
                <Route path="renew-password" element={<RenewPassword />} />
              </Routes>
              <Template>
                <Routes>
                  <Route
                    path="/accelerator-mentor"
                    element={<AcceleratorMentor />}
                  />
                  <Route path="/my-profile" element={<MyProfile />} />
                  {/* Lista todos oa usuários*/}
                  <Route path="/users">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_USER)]}
                        >
                          <UsersPage />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  {/* TODO -ADICIONAR O PATH /TOOLS */}
                  <Route
                    path="/config-valuation"
                    element={<ConfigValuation />}
                  />
                  <Route path="/config-courses" element={<ConfigCourses />} />

                  <Route
                    path="/startup-profile"
                    element={<InvestedStartup />}
                  />
                  <Route path="/chat" element={<InvestorChat />} />
                  <Route path={'/calendar'} element={<ScheduleCalendar />} />
                  <Route
                    path="/startup-profile"
                    element={<InvestedStartup />}
                  />
                  <Route
                    path="/startup/vacancies"
                    element={<StartupVacancies />}
                  />

                  <Route path="/startups">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_STARTUPS),
                          ]}
                        >
                          <StartupList />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="profile" element={<ViewProfileStartup />} />
                  </Route>

                  <Route path="/tools">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_TOOL)]}
                        >
                          <Tools />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="valuation" element={<WAAC />} />
                    <Route path="courses" element={<ConfigCourses />} />
                  </Route>

                  {/* ValuationForm questions */}
                  <Route path="/incubators" element={<ValuationQuestions />} />

                  {/* ACESSO ÀS INFORMAÇÔES DE PERFIL */}
                  <Route
                    path="/profile-infos"
                    element={
                      <ProtectedRoute
                        roles={[$enum(Roles).getKeyOrDefault(Roles.V_PROFILE)]}
                      >
                        <ProfileInfos />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/zoom" element={<Zoom />} />

                  {/* TEM ACESSO A RODADA DE INVESTMENTO */}
                  <Route path="/investment-round">
                    <Route
                      path="investor"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_INVESTMENT_INVESTOR,
                            ),
                          ]}
                        >
                          <InvestmentRound />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="startup"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_INVESTMENT_STARTUP,
                            ),
                          ]}
                        >
                          <InvestmentRoundList />
                        </ProtectedRoute>
                      }
                    />

                    <Route path="details">
                      <Route
                        index
                        element={
                          <ProtectedRoute
                            roles={[
                              $enum(Roles).getKeyOrDefault(
                                Roles.V_INVESTMENT_STARTUP,
                              ),
                              $enum(Roles).getKeyOrDefault(
                                Roles.V_INVESTMENT_INVESTOR,
                              ),
                            ]}
                          >
                            <InvestmentRoundDetails />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="submit-pitch" element={<SubmitPich />} />
                      <Route
                        path="submitted-startup"
                        element={<SubmittedStartups />}
                      />
                    </Route>

                    <Route
                      path="investment"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.C_INVESTMENT_INVESTOR,
                            ),
                          ]}
                        >
                          <InvestmentForm />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  {/* TEM ACESSO A MENTORIA E AGENDA DO MENTOR */}
                  <Route
                    path="/mentorship"
                    element={
                      <ProtectedRoute
                        roles={[
                          $enum(Roles).getKeyOrDefault(
                            Roles.V_MENTORING_MENTOR,
                          ),
                        ]}
                      >
                        <Mentorship />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/mentorship/scheduling"
                    element={
                      <ProtectedRoute
                        roles={[
                          $enum(Roles).getKeyOrDefault(Roles.V_SCHEDULE_MENTOR),
                        ]}
                      >
                        <MentorshipScheduling />
                      </ProtectedRoute>
                    }
                  />

                  {/* ValuationForm questions */}
                  <Route path="/incubators" element={<ValuationQuestions />} />

                  <Route
                    path="/register/conclude/profile/final"
                    element={<FinalRegister />}
                  />
                  {/* STARTUP EXERCISE ANSWER */}
                  <Route path="/answer/:id" element={<ExerciseAnswer />} />

                  {/* CATEGORY */}
                  <Route path="/category" element={<CourseCategory />} />

                  {/* NOTIFICATIONS */}
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="mentor">
                    <Route index element={<Mentor />} />
                  </Route>

                  {/* TEM ACESSO AS MENTORIAS E AGENDA DA STARTUP */}
                  <Route path="startup-mentorships">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_MENTORING_STARTUP,
                            ),
                          ]}
                        >
                          <Mentors />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="mentor-profile/:id"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_PROFILE_MENTOR,
                            ),
                          ]}
                        >
                          <MentorProfile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="mentor-profile/:mentorId/schedule/:mentoringId"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_SCHEDULE_MENTOR,
                            ),
                          ]}
                        >
                          <ScheduleMentor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="mentor-profile/:mentorId/schedule/:mentoringId/week"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_SCHEDULE_MENTOR,
                            ),
                          ]}
                        >
                          <StartupWeekCalendar />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="/mentors">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_MENTOR)]}
                        >
                          <Mentor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="profile/:id"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_PROFILE_MENTOR,
                            ),
                          ]}
                        >
                          <MentorProfile />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path={'/investors'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_INVESTOR),
                          ]}
                        >
                          <Investor />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="/startup-journey" element={<StartupJourney />} />
                  <Route path="/" element={<Navigate to={'dashboard'} />} />
                  <Route path="dashboard">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_ACCELERATOR,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_STARTUP,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_MENTOR,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_INVESTOR,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_STUDENT,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_PROFESSIONAL,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_ADMIN,
                            ),
                          ]}
                        >
                          {/* <Dashboard/> */}
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="startup">
                      <Route
                        index
                        element={
                          <ProtectedRoute
                            roles={[
                              $enum(Roles).getKeyOrDefault(Roles.V_STARTUPS),
                            ]}
                          >
                            <StartupDetails />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="diagnostics"
                        element={
                          <ProtectedRoute
                            roles={[
                              $enum(Roles).getKeyOrDefault(Roles.V_DIAGNOSTIC),
                            ]}
                          >
                            <StartupDiagnosticsList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="results-valuation"
                        element={
                          <ProtectedRoute
                            roles={[
                              $enum(Roles).getKeyOrDefault(
                                Roles.V_RESULTS_VALUATION,
                              ),
                            ]}
                          >
                            <ResultsValuation />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="detail/:startupId/kpi/:id"
                        element={
                          <ProtectedRoute
                            roles={[
                              $enum(Roles).getKeyOrDefault(Roles.V_OKR),
                              $enum(Roles).getKeyOrDefault(
                                Roles.V_DASHBOARD_ACCELERATOR,
                              ),
                            ]}
                          >
                            <KpiDetails />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="details/:userId/journey"
                        element={<ClientStartupJourney />}
                      />
                      <Route
                        path="details/:userId/journey-report"
                        element={<StartupJourneyReport />}
                      />
                      <Route
                        path=":userId/correct-exercise/:exerciseId"
                        element={<ExerciseCorrection />}
                      />
                    </Route>
                  </Route>
                  <Route path="valuations">
                    <Route
                      path={'valuation'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_VALUATION),
                          ]}
                        >
                          <Valuation />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'view'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_VALUATION),
                          ]}
                        >
                          <ValuationView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'valuation-startup'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_VALUATION_ANSWER,
                            ),
                          ]}
                        >
                          <StartupValuation />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'questions'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.U_VALUATION_ANSWER,
                            ),
                          ]}
                        >
                          <ValuationQuestions />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="results"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_RESULTS_VALUATION,
                            ),
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DASHBOARD_ACCELERATOR,
                            ),
                          ]}
                        >
                          <ResultsValuation />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="diagnostics">
                    <Route
                      path={'diagnostic'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_DIAGNOSTIC),
                          ]}
                        >
                          <Diagnosis />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'view'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_DIAGNOSTIC),
                          ]}
                        >
                          <DiagnosisFormView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'diagnostic-startup'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DIAGNOSTIC_ANSWER,
                            ),
                          ]}
                        >
                          <DiagnosisStartup />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'questions'}
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_DIAGNOSTIC_ANSWER,
                            ),
                          ]}
                        >
                          <DiagnosisFormStartup />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="methodologies">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_METHODOLOGY),
                          ]}
                        >
                          <Methodology />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="methodology/:id"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_METHODOLOGY),
                          ]}
                        >
                          <MethodologyFormView />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path={'profiles'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_USER)]}
                        >
                          <Profiles />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="list"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_PROFILE),
                          ]}
                        >
                          <ProfileList />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="metrics">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_OKR)]}
                        >
                          <Metrics />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="form"
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.C_OKR)]}
                        >
                          <MetricsForm />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="form/:id"
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.C_OKR)]}
                        >
                          <MetricsForm />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path={'members-accelerator'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_MEMBER_ACCELERATOR,
                            ),
                          ]}
                        >
                          <MemberAccelerator />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path={'members-startup'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_MEMBER_STARTUP,
                            ),
                          ]}
                        >
                          <MemberStartup />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path={'accelerator'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(Roles.V_ACCELERATOR),
                          ]}
                        >
                          <MyAccelerator />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="startups" element={<StartupsList />} /> */}
                    <Route path="courses" element={<CoursesList />} />
                  </Route>

                  <Route path={'courses'}>
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_COURSE)]}
                        >
                          <Courses />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="course/:id"
                      element={
                        <ProtectedRoute
                          roles={[$enum(Roles).getKeyOrDefault(Roles.V_COURSE)]}
                        >
                          <Course />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  {/* Coursos para startup */}
                  <Route path="courses-startup">
                    <Route
                      index
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_COURSE_STARTUP,
                            ),
                          ]}
                        >
                          <StartupCourses />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="attend-course/:id"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_COURSE_STARTUP,
                            ),
                          ]}
                        >
                          <AttendCourse />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="attend-course/:courseId/exercise/:id"
                      element={
                        <ProtectedRoute
                          roles={[
                            $enum(Roles).getKeyOrDefault(
                              Roles.V_COURSE_STARTUP,
                            ),
                          ]}
                        >
                          <AnswerExercise />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="course-plans" element={<CoursePlans />} />
                  </Route>
                  <Route path="error/:code" element={<ErrorPage />} />
                </Routes>
              </Template>
            </>
          ) : (
            <>
              <Routes>
                <Route index element={<InactivePage />} />
              </Routes>
            </>
          )}
        </>
      )}
    </>
  );
}
