import styled from 'styled-components';

const ButtonAction = styled.div`
  position: relative;

  & .addItem {
    position: absolute;
    left: 50%;

    :hover {
      background-color: var(--primary-color);

      svg {
        color: var(--white-color);
        transform: rotate(90deg);
        transition-duration: 500ms;
      }
    }
  }

  & .removeItem {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    cursor: pointer;

    :hover {
      svg {
        font-size: 18px;
        transition-duration: 500ms;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-top: 1px solid var(--support-color);

  .dashed,
  .default {
    background-color: var(--white-color);
    border-radius: 4px;
    border: 1px dashed var(--support-color);
  }

  .default {
    border: 1px solid var(--support-color);

    svg {
      margin-left: 5px;
      font-size: 12px;
    }
  }
`;

export { ButtonAction, Container };
