import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ICourseShowCase from '../../../../core/interfaces/course/ICourseShowCase';
import CourseService, {
  SortCourseParam,
} from '../../../../services/course/CourseService';
import { BoxContainer } from '../../../../GlobalStyles';
import { Col, Empty, Grid, Row, Select, Space } from 'antd';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import CourseCategoryService from '../../../../services/course/CourseCategoryService';

import { CoursesByCategory } from './CoursesByCategory';
import Loading from '../../../../components/Loading/Loading';

const { useBreakpoint } = Grid;

interface Category {
  entityId: number;
  name: string;
}

interface GroupedCourses {
  category: string;
  courses: ICourseShowCase[];
}

export function CoursesList() {
  const [courses, setCourses] = useState<ICourseShowCase[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [filterCategory, setFilterCategory] = useState<number | string | null>(
    null,
  );
  const [sortOption, setSortOption] = useState<SortCourseParam | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  async function fetchCoursesByAcceleratorId(acceleratorId: number) {
    const courseService = CourseService.init();
    const response =
      await courseService.fetchCoursesByAcceleratorId(acceleratorId);
    setCourses(response);
  }

  async function fetchCoursesCategories() {
    const courseCategoryService = CourseCategoryService.init();
    const response = await courseCategoryService.findAll();
    setCategories(response);
  }

  async function handleFilterByCategory(value: number | string) {
    setIsFetching(true);

    const courseService = CourseService.init();
    const params = {
      category: typeof value === 'string' ? undefined : value,
      sort: sortOption !== null ? sortOption : undefined,
    };

    const response = await courseService.fetchCoursesByAcceleratorId(
      location.state.acceleratorId,
      params,
    );

    setCourses(response);
    setFilterCategory(value);
    setIsFetching(false);
  }

  async function handleSorting(value: SortCourseParam) {
    setIsFetching(true);

    const courseService = CourseService.init();
    const params = {
      category: typeof filterCategory === 'number' ? filterCategory : undefined,
      sort: value,
    };

    const response = await courseService.fetchCoursesByAcceleratorId(
      location.state.acceleratorId,
      params,
    );

    setCourses(response);
    setSortOption(value);
    setIsFetching(false);
  }

  useEffect(() => {
    Promise.all([
      fetchCoursesCategories(),
      fetchCoursesByAcceleratorId(location.state.acceleratorId),
    ]).finally(() => {
      setIsFetching(false);
    });
  }, [location]);

  const coursesGroupedByCategory = useMemo(
    () =>
      courses.reduce<GroupedCourses[]>((acc, course) => {
        const currentCategory = course.categoryName;
        const categoryExists = acc.findIndex(
          (item) => item.category === currentCategory,
        );

        if (categoryExists !== -1) {
          acc[categoryExists].courses.push(course);
        } else {
          acc.push({ category: currentCategory, courses: [course] });
        }

        return acc;
      }, []),
    [courses],
  );

  return (
    <Loading size="large" spinning={isFetching} width={300} height={300}>
      <BoxContainer>
        <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '1rem' }}>
          <Col flex={1}>
            <Space>
              <GoBack
                enableBackground={true}
                goBackFunction={() => navigate(-1)}
              />

              <Title size={breakpoint.xs ? 16 : 26}>Cursos aceleradora</Title>
            </Space>
          </Col>

          <Col>
            <Select
              style={{ minWidth: 200 }}
              placeholder="Escolha uma categoria"
              value={filterCategory}
              onChange={handleFilterByCategory}
            >
              <Select.Option value="">Todas</Select.Option>
              {categories.map((category) => {
                return (
                  <Select.Option
                    key={category.entityId}
                    value={category.entityId}
                  >
                    {category.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>

          <Col>
            <Select
              style={{ minWidth: 200 }}
              placeholder="Organizar por"
              onChange={handleSorting}
            >
              <Select.Option value="A">A -Z</Select.Option>
              <Select.Option value="Z">Z - A</Select.Option>
              <Select.Option value="MR">Mais recente</Select.Option>
              <Select.Option value="MA">Mais antigo</Select.Option>
            </Select>
          </Col>
        </Row>

        <Space direction="vertical" size="large">
          {!isFetching && coursesGroupedByCategory.length === 0 && <Empty />}

          {coursesGroupedByCategory.map((item) => {
            return (
              <CoursesByCategory
                key={item.category}
                category={item.category}
                courses={item.courses}
              />
            );
          })}
        </Space>
      </BoxContainer>
    </Loading>
  );
}
