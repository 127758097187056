import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  .site-navigation-steps,
  .site-navigation-steps {
    margin-bottom: 60px;
    box-shadow: 0px -1px 0 0 #e8e8e8 inset;
  }

  .ant-setps-navigation {
    padding-top: 0;
  }

  .steps-content {
    margin-top: 0;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 2px;
  }

  .row-form {
    border-right: 1px solid #9aa4a8;
    :last-child {
      border-right: none;
    }
  }

  .ant-input-disabled {
    color: #000000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000000;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;

  & img {
    width: 100px;
  }

  .description {
    text-align: justify;
    vertical-align: center;
    margin-top: 20px;
  }
`;

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
`;

export { BoxContent, Header, Label };
