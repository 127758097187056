import React, { useMemo } from 'react';
import { Col, Form, Input, Row, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import useLoading from '../../../core/hooks/useLoading';
import { IPropsModal } from '../../../core/interfaces/modal/IPropsModal';
import { ModalBody, PurpleButton } from '../../../GlobalStyles';
import ChatService from '../../../services/chat/ChatService';
import { notification } from '../../../helpers/notification.helper';

interface Props extends IPropsModal {
  senderId?: number;
  recipientId?: number;
  recipientName?: string;
  senderName?: string;
}

function ModalStartChart({
  senderId = 0,
  recipientId = 0,
  onCancel,
  visible,
  recipientName = '',
  senderName = '',
}: Props) {
  const required = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const { loading, hideLoading, showLoading } = useLoading();
  const chatService = useMemo(() => ChatService.init(), []);

  const formIsValid = () => {
    if (senderId && recipientId && recipientName) {
      return true;
    }
    notification.info('Remetente não identificado');

    return false;
  };

  const submitForm = (values: Store) => {
    if (formIsValid()) {
      showLoading();
      chatService
        .start({
          message: values.message,
          senderUser: {
            entityId: senderId,
            name: senderName,
          },
          recipientUser: {
            entityId: recipientId,
            name: recipientName,
          },
        })
        .then(() => {
          notification.success('Mensagem enviada com sucesso!');
          onCancel();
        })
        .catch(() => {
          notification.error('Erro ao enviar mensagem.');
        })
        .finally(hideLoading);
    }
  };

  return (
    <ModalBody
      title="Iniciar conversa"
      width="100%"
      maxWidth="400px"
      margin="0 auto"
      footer={null}
      open={loading || visible}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form onFinish={submitForm}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item name="message" rules={required}>
                <Input placeholder="Mensagem" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <PurpleButton
                margin="0 auto"
                display="block"
                type="primary"
                htmlType="submit"
                maxWidth="200px"
                width="100%"
              >
                Enviar
              </PurpleButton>
            </Col>
          </Row>
        </Form>
      </Spin>
    </ModalBody>
  );
}

export default ModalStartChart;
