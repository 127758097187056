const Roles: any = {
  C_USER: 'CADASTRAR USUÁRIO',
  V_USER: 'VISUALIZAR USUÁRIO',
  C_PROFILE: 'CADASTRAR PERFIL',
  V_PROFILE: 'VISUALIZAR PERFIL',
  C_DIAGNOSTIC: 'CADASTRAR DIAGNÓSTICO',
  V_DIAGNOSTIC: 'VISUALIZAR DIAGNÓSTICO',
  V_DIAGNOSTIC_STARTUP: 'VISUALIZAR DIAGNÓSTICO DE STARTUP',
  C_DIAGNOSTIC_ANSWER: 'RESPONDER DIAGNÓSTICO',
  V_DIAGNOSTIC_ANSWER: 'VISUALIZAR RESPOSTA DO DIAGNÓSTICO',
  C_VALUATION_ANSWER: 'RESPONDER VALUATION',
  U_VALUATION_ANSWER: 'ATUALIZAR RESPOSTA VALUATION',
  V_VALUATION_ANSWER: 'VISUALIZAR RESPOSTA VALUATION',
  V_RESULTS_VALUATION: 'VISUALIZAR RESULTADOS DO VALUATION',
  C_STARTUPS: 'CADASTRAR STARTUP',
  C_OKR: 'CADASTRAR OKR',
  V_OKR: 'VISUALIZAR OKR',
  V_STARTUPS: 'VISUALIZAR STARTUP',
  C_CLIENTS: 'CADASTRAR CLIENTES',
  V_CLIENTS: 'VISUALIZAR CLIENTES',
  C_VALUATION: 'CADASTRAR VALUATION',
  V_VALUATION: 'VISUALIZAR VALUATION',
  U_VALUATION: 'ATUALIZAR VALUATION',
  C_METHODOLOGY: 'CADASTRAR METODOLOGIA',
  V_METHODOLOGY: 'VISUALIZAR METODOLOGIA',
  D_MONITORING_PANEL: 'MONITORAR PANEL',
  V_DASHBOARD_STARTUP: 'VISUALIZAR DASHBOARD STARTUP',
  V_DASHBOARD_ACCELERATOR: 'VISUALIZAR DASHBOARD ACELERADORA',
  V_DASHBOARD_MENTOR: 'VISUALIZAR DASHBOARD MENTOR',
  V_DASHBOARD_STUDENT: 'VISUALIZAR DASHBOARD ESTUDANTE',
  V_DASHBOARD_INVESTOR: 'VISUALIZAR DASHBOARD INVESTIDOR',
  V_DASHBOARD_PROFESSIONAL: 'VISUALIZAR DASHBOARD PROFISSIONAL',
  V_DASHBOARD_ADMIN: 'VISUALIZAR DASHBOARD ADMINISTRADOR',
  V_ACCELERATOR: 'VISUALIZAR ACELERADORA',
  R_REPORT_VALUATION: 'RELATÓRIO VALUATION',
  R_REPORT_DIAGNOSTIC: 'RELATÓRIO DIAGNÓSTICO',
  C_COURSE: 'CADASTRAR CURSO',
  V_COURSE: 'VISUALIZAR CURSOS',
  V_COURSE_STARTUP: 'VISUALIZAR CURSOS STARTUP',
  C_COURSE_STARTUP: 'CADASTRAR CURSOS STARTUP',
  V_MEMBER_STARTUP: 'VISUALIZAR MEMBROS DA STARTUP',
  C_MEMBER_STARTUP: 'CADASTRAR MEMBROS DA STARTUP',
  V_MEMBER_ACCELERATOR: 'VISUALIZAR MEMBROS DA ACELERADORA',
  C_MEMBER_ACCELERATOR: 'CADASTRAR MEMBROS DA ACELERADORA',
  V_MENTORING_STARTUP: 'VISUALIZAR MENTORING DA STARTUP',
  C_MENTORING_STARTUP: 'CADASTRAR MENTORING DA STARTUP',
  V_MENTORING_MENTOR: 'VISUALIZAR MENTORING DO MENTOR',
  C_MENTORING_MENTOR: 'CADASTRAR MENTORING DO MENTOR',
  V_INVESTMENT_STARTUP: 'VISUALIZAR INVESTIMENTOS DA STARTUP',
  C_INVESTMENT_STARTUP: 'CADASTRAR INVESTIMENTOS DA STARTUP',
  V_INVESTMENT_INVESTOR: 'VISUALIZAR INVESTIMENTOS DO INVESTIDOR',
  C_INVESTMENT_INVESTOR: 'CADASTRAR INVESTIMENTOS DO INVESTIDOR',
  V_SCHEDULE_MENTOR: 'VISUALIZAR AGENDA DO MENTOR',
  C_SCHEDULE_MENTOR: 'CADASTRAR AGENDA DO MENTOR',
  V_SCHEDULE_STARTUP: 'VISUALIZAR AGENDA DA STARTUP',
  C_SCHEDULE_STARTUP: 'CADASTRAR AGENDA DA STARTUP',
  V_PROFILE_STARTUP: 'VISUALIZAR PERFIL DA STARTUP',
  V_PROFILE_MENTOR: 'VISUALIZAR PERFIL DO MENTOR',
  V_PROFILE_INVESTOR: 'VISUALIZAR PERFIL DO INVESTIDOR',
  V_PROFILE_STUDENT: 'VISUALIZAR PERFIL DO ALUNO',
  V_PROFILE_ACCELERATOR: 'VISUALIZAR PERFIL DA ACELERADORA',
  V_PROFILE_ADMIN: 'VISUALIZAR PERFIL DO ADMINISTRADOR',
  V_CURRICULUM_PROFESSIONAL: 'VISUALIZAR CURRÍCULO PROFISSIONAL',
  C_CURRICULUM_PROFESSIONAL: 'CADASTRAR CURRÍCULO PROFISSIONAL',
  V_MY_INVESTMENTS_INVESTOR: 'VISUALIZAR MEUS INVESTIMENTOS',
  C_MY_INVESTMENTS_INVESTOR: 'CADASTRAR MEUS INVESTIMENTOS',
  V_TOOL: 'VISUALIZAR FERRAMENTA',
  C_TOOL: 'CADASTRAR FERRAMENTA',
  V_INVESTOR: 'VISUALIZAR INVESTIDOR',
  C_INVESTOR: 'CADASTRAR INVESTIDOR',
  V_MENTOR: 'VISUALIZAR MENTOR',
  C_MENTOR: 'CADASTRAR MENTOR',
  V_MENTORING: 'VISUALIZAR TODAS AS MENTORIAS',
  V_HIRING: 'VISUALIZAR TODAS AS CONTRATAÇÕES',
  C_HIRING: 'CADASTRAR CONTRATAÇÃO',
  V_SCHEDULE: 'VISUALIZAR AGENDA',
};
const findRole = (role: string) => {
  return Roles[role];
};

export { Roles, findRole };
