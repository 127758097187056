import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { GrClose } from 'react-icons/gr';
import { BiFilter } from 'react-icons/bi';
import Text from '../../../../../components/Text';
import { WrapperButton } from './styles';
import { Col, Row, Select } from 'antd';
import CourseCategoryService from '../../../../../services/course/CourseCategoryService';
import ICourseCategory from '../../../../../core/interfaces/course/ICourseCategory';
import { notification } from '../../../../../helpers/notification.helper';
import { IFilterList } from '../../../../../core/interfaces/course/IFilterList';

type Props = {
  filters: IFilterList;
  changeFilter(filters: IFilterList): void;
};

function FilterList({ filters, changeFilter }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const [categories, setCategories] = useState<ICourseCategory[]>([]);

  const courseCategoryService = useMemo(() => CourseCategoryService.init(), []);

  const getCategories = useCallback(() => {
    courseCategoryService
      .findAll()
      .then((categories) => setCategories(categories))
      .catch(() => notification.error('Erro ao listar categorias'));
  }, [courseCategoryService]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      <WrapperButton onClick={() => setShow(!show)}>
        {show ? <GrClose size={18} /> : <BiFilter size={22} />}
        <Text color="#05233D" size={18} weight={700}>
          Filtrar
        </Text>
      </WrapperButton>
      {show && (
        <Row
          justify="start"
          gutter={16}
          style={{ width: '100%', margin: '1rem 0' }}
        >
          <Col>
            <Text size={14} weight={500}>
              Categorias
            </Text>
            <Select
              style={{ width: 230 }}
              mode="multiple"
              value={filters.categories}
              placeholder="Escolha uma categoria"
              onChange={(values) =>
                changeFilter({ ...filters, categories: values })
              }
              options={categories.map((category) => ({
                value: category.entityId,
                label: category.name,
              }))}
            />
          </Col>
          <Col>
            <Text size={14} weight={500}>
              Organizado por:
            </Text>
            <Select
              style={{ width: 230 }}
              onChange={(value: string) =>
                changeFilter({ ...filters, sort: value })
              }
              defaultValue={'name,desc'}
              options={[
                {
                  value: 'name,asc',
                  label: 'Nome A-Z',
                },
                {
                  value: 'name,desc',
                  label: 'Nome Z-A',
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default FilterList;
