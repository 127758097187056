import React, { useEffect, useMemo, useState } from 'react';
import Text from '../../../../components/Text';
import { IconOptions, PurpleButton } from '../../../../GlobalStyles';
import MetricService from '../../../../services/MetricService';
import {
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Spin,
} from 'antd';
import IMetric, { IAttachment } from '../../../../core/interfaces/IMetric';
import {
  CaretDownFilled,
  CaretUpFilled,
  PaperClipOutlined,
} from '@ant-design/icons';
import {
  Box,
  ButtonCollapse,
  CollapseCustom,
  TextBox,
  ButtonShowComments,
} from './styles';
import AddListButton from '../../../../components/AddListButton';
import MetricCrudService from '../../../../services/MetricCrudService';
import { IPerformaceIndicatorStatus } from '../../../../core/enums/IKeyPerformaceIndicators';
import AttachmentLabel from '../AttachmentLabel';
import IFileStorage from '../../../../core/interfaces/IFileStorage';
import StatusObjectiveKeyResult from '../../../../components/StatusObjectiveKeyResult';
import FileService from '../../../../services/FileService';
import StatusFileStorageEnum from '../../../../core/enums/StatusFileStorageEnum';
import { CgTrashEmpty } from 'react-icons/cg';
import { FiEdit2 } from 'react-icons/fi';
import useLoading from '../../../../core/hooks/useLoading';
import ConfirmAction from '../../../../components/ConfirmAction';
import { useNavigate } from 'react-router';
import ComentsBox from './ComentsBox';

interface Props {
  metric: IMetric;

  reload(): void;
}

function MetricListBox({ metric, reload }: Props) {
  const metricService = useMemo(() => MetricService.init(), []);
  const navigate = useNavigate();
  const metricCrudService = useMemo(() => new MetricCrudService(), []);
  const fileService = useMemo(() => FileService.init(), []);
  const [form] = Form.useForm();

  const loadingBox = useLoading();

  const [isNewKpi, setIsNewKpi] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<IAttachment>({});
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  const attachFile = (
    target: HTMLInputElement,
    files: IFileStorage[],
    index: number,
  ) => {
    const attachments = attachment[index] ?? [];
    const filesFormatted = files.map((item) => ({
      name: item.originalName,
    }));

    metricService
      .validateAttachment(target.files, [...attachments, ...filesFormatted])
      .then((file) => {
        attachments.push(file);
        setAttachment({
          ...attachment,
          [index]: attachments,
        });
      })
      .catch((errors: string[]) => {
        errors.forEach((error) => {
          notification.info({
            message: error,
          });
        });
      })
      .finally(() => (target.value = ''));
  };

  const addKpi = (values: Record<string, string>) => {
    const data = {
      text: values.kpi,
      files: [],
      metric: {
        entityId: metric.entityId,
      },
      status: IPerformaceIndicatorStatus.Pending,
    };

    metricCrudService
      .addKpi(data)
      .then(() => {
        reload();
        resetForm();
        notification.success({
          message: 'Kpi adicionado com sucesso',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao adicionar kpi',
        });
      });
  };

  const resetForm = () => {
    form.resetFields();
    setIsNewKpi(false);
  };

  const removeAttachment = (kpiIndex: number, attachmentIndex: number) => {
    const items = attachment[kpiIndex];
    items?.splice(attachmentIndex, 1);
    setAttachment({
      ...attachment,
      [kpiIndex]: items,
    });
  };

  const attachmentsIsNotEmpty = (index: number): boolean => {
    return Object.keys(attachment[index] ?? []).length !== 0;
  };

  const sendEvidence = (performaceIndicatorId: number, index: number) => {
    setLoadingAttachments(true);
    const attachments = attachment[index];
    metricCrudService
      .attach(attachments, performaceIndicatorId)
      .then(() => {
        reload();
        setAttachment({
          ...attachment,
          [index]: [],
        });
        notification.success({
          message: 'Arquivos enviados com sucesso',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao enviar arquivos',
        });
      })
      .finally(() => setLoadingAttachments(false));
  };

  const deleteFileStorage = (fileId: number) => {
    setLoadingAttachments(true);
    metricCrudService
      .removeAttach(fileId)
      .then(() => {
        reload();
        notification.success({
          message: 'Anexo removido com sucesso',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao remover anexo',
        });
      })
      .finally(() => setLoadingAttachments(false));
  };

  const openFile = (file: IFileStorage) => {
    setLoadingAttachments(true);
    fileService.open(file).finally(() => setLoadingAttachments(false));
  };

  const openFileTemporary = (file: File) => {
    window.open(window.URL.createObjectURL(file), '_target');
  };

  const editMetric = () => {
    navigate(`/metrics/form/${metric.entityId}`);
  };

  const deleteMetric = () => {
    loadingBox.showLoading();
    metricCrudService
      .delete(Number(metric.entityId))
      .then(() => {
        reload();
        notification.success({
          message: 'Métrica removida com sucesso',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao remover métrica',
        });
      })
      .finally(loadingBox.hideLoading);
  };

  const status = metricService.getStatusMetric(metric.keyPerformanceIndicators);

  const enabledAction = status === IPerformaceIndicatorStatus.Pending;

  return (
    <Spin spinning={loadingBox.loading} delay={300}>
      <Box>
        <Row
          gutter={[12, 12]}
          justify="space-between"
          style={{ marginBottom: '8px' }}
        >
          <Col>
            <Text weight={700} size={20}>
              OKR
            </Text>
          </Col>
          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <ComentsBox comments={metric.comments || []}>
                  <ButtonShowComments>Comentários</ButtonShowComments>
                </ComentsBox>
              </Col>
              <Col>
                <StatusObjectiveKeyResult status={status} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={18}>
            <TextBox>
              <div>
                <Text size={17} weight={700} color="#960096">
                  Eu vou
                </Text>
                <Text size={17} weight={500}>
                  {metric.objectives}
                </Text>
              </div>
              <div>
                <Text size={17} weight={700} color="#960096">
                  Medido por
                </Text>
                <Text size={17} weight={500}>
                  {metric.keyResult}
                </Text>
              </div>
            </TextBox>
          </Col>
          <Col xs={9} sm={5} md={5} lg={6}>
            <ButtonCollapse>
              {enabledAction && (
                <>
                  <FiEdit2 cursor="pointer" size={20} onClick={editMetric} />
                  <ConfirmAction onConfirm={deleteMetric} boxPosition="left">
                    <CgTrashEmpty cursor="pointer" size={20} />
                  </ConfirmAction>
                </>
              )}
              <button onClick={() => setActiveKey(!activeKey)}>
                {activeKey ? <CaretUpFilled /> : <CaretDownFilled />}
              </button>
            </ButtonCollapse>
          </Col>
          {!activeKey && (
            <Col span={24}>
              <Text color="#9AA4A8">
                {metric.keyPerformanceIndicators.length} KPIs cadastrados
              </Text>
            </Col>
          )}
          <Divider style={{ margin: '8px 0' }} />

          {/*Inicio do Collapse*/}
          <Col span={24}>
            <CollapseCustom ghost activeKey={activeKey ? '1' : ''}>
              <Collapse.Panel showArrow={false} header="" key="1">
                <Row>
                  <Col span={24}>
                    <Text weight={700} size={20}>
                      KPIs
                    </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    {metric?.keyPerformanceIndicators?.map((kpi, kpiIndex) => {
                      const { Pending, Approvaed } = IPerformaceIndicatorStatus;
                      const allowedAttach = [Pending, Approvaed].includes(
                        kpi.status,
                      );

                      return (
                        <Row
                          align="middle"
                          gutter={[12, 12]}
                          key={`key_kpis_${kpiIndex}`}
                        >
                          <Col span={24}>
                            <Text size={17} weight={500}>
                              {kpi.text}
                            </Text>
                          </Col>
                          <Col xs={24} sm={16} md={15} xxl={20} lg={20} xl={20}>
                            <Row gutter={[10, 10]}>
                              <Col>
                                {allowedAttach && (
                                  <label>
                                    <IconOptions cursor="pointer" size="28px">
                                      <PaperClipOutlined />
                                    </IconOptions>

                                    <input
                                      type="file"
                                      className="d-none"
                                      accept="application/pdf"
                                      onChange={({ target }) =>
                                        attachFile(target, kpi.files, kpiIndex)
                                      }
                                    />
                                  </label>
                                )}
                              </Col>
                              {kpi.files.map((file, index) => (
                                <Col key={`key_attachment_${index}`}>
                                  <AttachmentLabel
                                    preview={() => openFile(file)}
                                    enabledRemove={
                                      file?.status ===
                                      StatusFileStorageEnum.Pending
                                    }
                                    name={file.originalName}
                                    isLoading={loadingAttachments}
                                    remove={() =>
                                      deleteFileStorage(file.entityId)
                                    }
                                  />
                                </Col>
                              ))}
                              {attachment[kpiIndex]?.map(
                                (file, attachmentIndex) => (
                                  <Col
                                    key={`key_attachment_${attachmentIndex}`}
                                  >
                                    <AttachmentLabel
                                      name={file.name}
                                      preview={() => openFileTemporary(file)}
                                      isLoading={loadingAttachments}
                                      remove={() =>
                                        removeAttachment(
                                          kpiIndex,
                                          attachmentIndex,
                                        )
                                      }
                                    />
                                  </Col>
                                ),
                              )}
                            </Row>
                          </Col>
                          <Col xs={24} sm={8} md={9} xxl={4} lg={4} xl={4}>
                            <Row gutter={[10, 10]}>
                              <Col>
                                <ComentsBox comments={kpi.comments || []}>
                                  <ButtonShowComments>
                                    Comentários
                                  </ButtonShowComments>
                                </ComentsBox>
                              </Col>
                              <Col>
                                <StatusObjectiveKeyResult status={kpi.status} />
                              </Col>
                            </Row>
                          </Col>
                          {attachmentsIsNotEmpty(kpiIndex) && (
                            <Col
                              xs={24}
                              sm={24}
                              md={5}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <PurpleButton
                                htmlType="button"
                                type="primary"
                                onClick={() =>
                                  sendEvidence(Number(kpi.entityId), kpiIndex)
                                }
                                loading={loadingAttachments}
                              >
                                Enviar evidências
                              </PurpleButton>
                            </Col>
                          )}

                          <Divider />
                        </Row>
                      );
                    })}
                  </Col>
                </Row>

                {isNewKpi && (
                  <Form form={form} onFinish={addKpi}>
                    <Row align="stretch" gutter={16}>
                      <Col span={20}>
                        <Form.Item
                          name={'kpi'}
                          rules={[
                            { required: true, message: 'Campo obrigatório' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col>
                        <PurpleButton htmlType="submit" type="primary">
                          Salvar KPI
                        </PurpleButton>
                      </Col>
                      <Col>
                        <PurpleButton isOutline={true} onClick={resetForm}>
                          Cancelar
                        </PurpleButton>
                      </Col>
                    </Row>
                  </Form>
                )}

                {!isNewKpi &&
                  !status.includes(IPerformaceIndicatorStatus.Concluded) && (
                    <AddListButton onClick={() => setIsNewKpi(true)}>
                      Adicionar
                    </AddListButton>
                  )}
              </Collapse.Panel>
            </CollapseCustom>
          </Col>
        </Row>
      </Box>
    </Spin>
  );
}

export default MetricListBox;
