import styled from 'styled-components';

const StatusContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .status {
    border-radius: 10px;
  }
  .text {
    color: var(--thirdy-color);
  }
`;

export { StatusContent };
