import React, { useCallback, useMemo, useEffect, useState } from 'react';
import Text from '../../../../components/Text';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '../../../../components/Title';
import ContentBase from '../../../templates/ContentBase';
import { PublishButton, PublishButtonWrapper } from './styles';
import closeIcon from '../../../../assets/icons/close_icon.svg';
import { Card, Col, Form, Input, notification, Row, Spin } from 'antd';
import IMetric, { IValuesForm } from '../../../../core/interfaces/IMetric';
import AddListButton from '../../../../components/AddListButton';
import MetricCrudService from '../../../../services/MetricCrudService';
import { IPerformaceIndicatorStatus } from '../../../../core/enums/IKeyPerformaceIndicators';
import useLoading from '../../../../core/hooks/useLoading';

function MetricsForm() {
  const navigate = useNavigate();
  const params = useParams();

  const metricCrudService = useMemo(() => MetricCrudService.init(), []);

  const [form] = Form.useForm();
  const loadingBox = useLoading();

  const [loading, setLoading] = useState<boolean>(false);

  const id = Number(params.id);
  const fieldRequired = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const handleSubmit = (values: IValuesForm) => {
    const { keyResult, objectives, kpis } = values;
    const data = {
      ...(id && { entityId: id }),
      keyResult,
      objectives,
      active: true,
      keyPerformanceIndicators: kpis.map((item) => ({
        ...(item.id && { entityId: item.id }),
        text: item.kpis,
        active: true,
        status: IPerformaceIndicatorStatus.Pending,
      })),
    };

    setLoading(true);
    metricCrudService
      .create(data)
      .then(() => {
        notification.success({
          message: 'Métrica salva com sucesso',
        });
        navigate('/metrics');
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao salvar métrica',
        });
      })
      .finally(() => setLoading(false));
  };

  const edit = useCallback(
    (id: number) => {
      loadingBox.showLoading();
      metricCrudService
        .findById(id)
        .then((data: IMetric) => {
          form.setFieldsValue({
            objectives: data.objectives,
            keyResult: data.keyResult,
            kpis: data.keyPerformanceIndicators.map((kpi) => ({
              kpis: kpi.text,
              id: kpi.entityId,
              active: true,
            })),
          });
        })
        .catch(() => {
          notification.info({
            message: 'Métrica não encontrada',
          });
        })
        .finally(loadingBox.hideLoading);
    },
    [form, metricCrudService],
  );

  useEffect(() => {
    if (id) edit(id);
  }, [id, edit]);

  return (
    <ContentBase
      title="Cadastrar métricas"
      content={
        <Spin spinning={loadingBox.loading}>
          <Card>
            <Form
              initialValues={{
                kpis: [''],
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <Title size={22} margin="0 0 12px 0">
                OKR
              </Title>
              <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label={
                      <Text color="#960096" weight={600} margin="0 0 5px 0">
                        Eu vou
                      </Text>
                    }
                    name="objectives"
                    rules={fieldRequired}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label={
                      <Text color="#960096" weight={600} margin="0 0 5px 0">
                        Medido por
                      </Text>
                    }
                    name="keyResult"
                    rules={fieldRequired}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Title size={22} margin="0 0 12px 0">
                KPI
              </Title>
              <Form.List name="kpis">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map(({ name, ...field }, index) => {
                      const isFirstKpi = index === 0;
                      return (
                        <div key={`field_key_${index}`}>
                          <Row justify="space-between">
                            <Col>
                              <Text weight={600} margin="0 0 5px 0">
                                {`${isFirstKpi ? '*' : ''} KPI ${index + 1}`}
                              </Text>
                            </Col>
                            {fields.length > 1 && (
                              <Col>
                                <img
                                  width={15}
                                  height={15}
                                  src={closeIcon}
                                  alt="icone remover"
                                  onClick={() => remove(index)}
                                  style={{
                                    marginTop: '4px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                          <Form.Item
                            {...field}
                            name={[name, 'kpis']}
                            rules={fieldRequired}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      );
                    })}
                    <AddListButton onClick={add}>Adicionar</AddListButton>
                  </div>
                )}
              </Form.List>

              <PublishButtonWrapper>
                <PublishButton
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Publicar Métricas
                </PublishButton>
              </PublishButtonWrapper>
            </Form>
          </Card>
        </Spin>
      }
    />
  );
}

export default MetricsForm;
