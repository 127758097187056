import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Title from '../../components/Title';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FormOutlined,
  HolderOutlined,
  MoreOutlined,
  PlaySquareOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActionsBox,
  BoxTopic,
  ContainerTopic,
  Conteiner,
  HeaderTopic,
  Info,
  CourseItemsBox,
  CourseItem,
  BoxFile,
  BoxImage,
} from './style';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Menu,
  notification,
  Popover,
  Row,
  Spin,
} from 'antd';
import { BoxContainer, ModalBody, PurpleButton } from '../../GlobalStyles';
import type { MenuProps } from 'antd/es/menu';
import GoBack from '../../components/GoBack/GoBack';
import notificationMessage from '../../components/notifications/notificationMessage';
import errorNotification from '../../components/notifications/errorNotification';
import CourseService from '../../services/course/CourseService';
import TopicService from '../../services/course/TopicService';
import ITopic from '../../core/interfaces/course/ITopic';
import ICourseShowCase from '../../core/interfaces/course/ICourseShowCase';
import ExtraMaterialForm from './ExtraMaterial/ExtraMaterialForm';
import LessonForm from './Lesson/LessonForm';
import useLoading from '../../core/hooks/useLoading';
import ActionsCrud from '../../components/ActionsCrud/ActionsCrud';
import { EnabledActionsCrudEnum } from '../../core/interfaces/IActionsCrud';
import LessonService from '../../services/lesson/LessonService';
import ILesson from '../../core/interfaces/course/ILesson';
import IExtraMaterial from '../../core/interfaces/course/IExtraMaterial';
import { GrDocumentPdf } from 'react-icons/gr';
import FileService from '../../services/FileService';
import ExtraMaterialService from '../../services/extra-material/ExtraMaterialService';
import ExerciseForm from './Exercises/ExerciseForm';
import { useModal } from 'sunflower-antd';
import ModalConfirmDelete from './ModalConfirm';
import CoursesForm from './CoursesForm';
import ICourse from '../../core/interfaces/models/ICourse';
import Text from '../../components/Text';
import ReactPlayer from 'react-player';
import ExerciseService from '../../services/exercise/ExerciseService';
import IExerciseResponse from '../../core/interfaces/course/IExerciseResponse';

type MenuItem = Required<MenuProps>['items'][number];

interface IModalContent {
  title: string;
  body: any;
}

function Course() {
  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { loading, showLoading, hideLoading } = useLoading();
  const removeLoading = useLoading();

  const courseService = useMemo(() => CourseService.init(), []);
  const topicService = useMemo(() => TopicService.init(), []);
  const lessonService = useMemo(() => LessonService.init(), []);
  const fileService = useMemo(() => FileService.init(), []);
  const extraMaterialService = useMemo(() => ExtraMaterialService.init(), []);
  const exerciseService = useMemo(() => ExerciseService.init(), []);

  const [course, setCourse] = useState<ICourseShowCase>();
  const [courseEdit, setCourseEdit] = useState<ICourse>();
  const [topicEdit, setTopicEdit] = useState<ITopic | null>(null);
  const [topicId, setTopicId] = useState<number>(0);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [contentModal, setContentModal] = useState<IModalContent[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [activeTopics, setActiveTopics] = useState<number[]>([]);
  const [topicFormVisible, setTopicFormVisible] = useState<string>('none');

  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const modalConfirmDelete = useModal({
    defaultVisible: false,
  });

  const topicConfirmDelete = useModal({
    defaultVisible: false,
  });

  const modalCoursesForm = useModal({
    defaultVisible: false,
  });

  const getData = useCallback(() => {
    const courseId = Number(params.id);
    const requests = [
      courseService.showCaseById(courseId),
      topicService.getByCourse(courseId),
    ];

    Promise.all(requests)
      .then(([course, topics]) => {
        setCourse(course);
        setTopics(topics);
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'curso não encontrado',
        }),
      );
  }, [courseService, topicService, params.id]);

  const handleSort = () => {
    topicService
      .updateOrder(
        topics[dragItem.current].entityId,
        topics[dragOverItem.current].entityId,
      )
      .then(() => {
        getTopics();
        dragItem.current = null;
        dragOverItem.current = null;
      })
      .catch(() => {
        errorNotification({ message: 'Erro ao ordernar o tópico!' });
      });
  };

  const closeModal = (): void => setVisibleModal(false);

  const checkPanel = (panelKey: number) => {
    return activeTopics.includes(panelKey);
  };

  const handlePanel = (panelKey: number) => {
    if (checkPanel(panelKey)) {
      setActiveTopics(activeTopics.filter((key) => panelKey !== key));
    } else {
      setActiveTopics(activeTopics.concat(panelKey));
    }
  };

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    onClick?: () => void,
  ): MenuItem {
    return {
      key,
      icon,
      onClick,
      label,
    } as MenuItem;
  }

  const menuItens = (topic: ITopic) => {
    const items: MenuItem[] = [
      getItem('Adicionar aula', '1', <PlaySquareOutlined />, () =>
        addLessonOption(topic),
      ),
      getItem('Adicionar exercício', '2', <FileTextOutlined />, () =>
        exerciseOption(topic),
      ),
      getItem(
        'Adicionar material complementar',
        '3',
        <SnippetsOutlined />,
        () => extraMaterialOption(topic),
      ),
      getItem('Editar tópico', '4', <FormOutlined />, () =>
        editTopicOption(topic),
      ),
      getItem('Excluir tópico', '5', <DeleteOutlined />, () => {
        topicConfirmDelete.show();
        setTopicEdit(topic);
      }),
    ];

    return items;
  };

  const onFinishTopicForm = () => {
    const dataForm = {
      name: form.getFieldValue('title'),
      course: {
        entityId: params.id,
      },
      position: topics.length + 1,
    };

    showLoading();
    topicService
      .updateOrCreate(dataForm, topicId)
      .then(() => {
        getTopics();
        setTopicId(0);
        showTopicForm('none');
        notificationMessage('default');
      })
      .catch(() => {
        errorNotification({
          defaultMessage: 'erro ao salvar tópico',
        });
      })
      .finally(hideLoading);
  };

  const deleteTopicOption = () => {
    showLoading();
    topicService
      .delete(Number(topicEdit?.entityId))
      .then(() => {
        getTopics();
        notificationMessage('delete');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao remover tópico',
        }),
      )
      .finally(() => {
        hideLoading();
        topicConfirmDelete.close();
      });
  };

  const showTopicForm = (display: string) => {
    setTopicFormVisible(display);
    form.resetFields();
  };

  const removeLesson = (lessonId: number) => {
    showLoading();
    lessonService
      .delete(lessonId)
      .then(() => {
        getTopics();
        notificationMessage('delete');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao remover aula',
        }),
      )
      .finally(hideLoading);
  };

  const editLesson = (topic: ITopic, lessonId: number) => {
    showLoading();
    lessonService
      .findById(lessonId)
      .then((data) => addLessonOption(topic, data))
      .catch(() =>
        errorNotification({
          defaultMessage: 'aula não encontrada',
        }),
      )
      .finally(hideLoading);
  };

  let countItem = 0;
  const openExtraMaterial = (fileName: string) => {
    showLoading();
    fileService
      .openByName(fileName)
      .catch(() =>
        errorNotification({ defaultMessage: 'erro ao exibir arquivo' }),
      )
      .finally(hideLoading);
  };

  const removeExtraMaterial = (id: number) => {
    showLoading();
    extraMaterialService
      .delete(id)
      .then(() => {
        getTopics();
        notificationMessage('delete');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao remover material complementar',
        }),
      )
      .finally(hideLoading);
  };

  const editExtraMaterial = (topic: ITopic, id: number) => {
    showLoading();
    extraMaterialService
      .findById(id)
      .then((data) => extraMaterialOption(topic, data))
      .catch(() =>
        errorNotification({
          defaultMessage: 'material complementar não encontrado',
        }),
      )
      .finally(hideLoading);
  };

  const removeExercise = (id: number) => {
    console.log(id);
  };

  const editExercise = (topic: ITopic, id: number) => {
    showLoading();
    exerciseService
      .findById(id)
      .then((exercise) => exerciseOption(topic, exercise))
      .catch(() =>
        errorNotification({
          defaultMessage: 'exercício não encontrado',
        }),
      )
      .finally(hideLoading);
  };

  const removeCourse = () => {
    const id = Number(params.id);
    removeLoading.showLoading();
    courseService
      .delete(id)
      .then(() => {
        notification.success({
          duration: 3.5,
          message: 'Curso desativado com sucesso',
        });
        navigate('/courses');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao desativar curso',
        }),
      )
      .finally(removeLoading.showLoading);
  };

  const editCourse = () => {
    const id = Number(params.id);
    courseService
      .findById(id)
      .then((data) => {
        setCourseEdit(data);
        modalCoursesForm.show();
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'Curso não encontrado',
        }),
      );
  };

  const addLessonOption = (topic: ITopic, lesson?: ILesson) => {
    showModal();
    setContentModal([
      {
        title: 'Adicionar aula',
        body: (
          <LessonForm
            entity={lesson}
            topic={topic}
            reload={getTopics}
            close={closeModal}
          />
        ),
      },
    ]);
  };

  const exerciseOption = (topic: ITopic, exercise?: IExerciseResponse) => {
    showModal();
    setContentModal([
      {
        title: exercise?.entityId ? 'Editar exercício' : 'Adicionar exercício',
        body: (
          <ExerciseForm
            topic={topic}
            entity={exercise}
            reload={getTopics}
            close={closeModal}
          />
        ),
      },
    ]);
  };

  const extraMaterialOption = (
    topic: ITopic,
    extraMaterial?: IExtraMaterial,
  ) => {
    showModal();
    setContentModal([
      {
        title: 'Adicionar material complementar',
        body: (
          <ExtraMaterialForm
            topic={topic}
            entity={extraMaterial}
            reload={getTopics}
            close={closeModal}
          />
        ),
      },
    ]);
  };

  const editTopicOption = (topic: ITopic) => {
    setTopicFormVisible('table');
    setTopicId(topic.entityId);
    form.setFieldValue('title', topic.name);
  };

  const showModal = () => setVisibleModal(!visibleModal);

  const getTopics = () => {
    showLoading();
    topicService
      .getByCourse(Number(params.id))
      .then((data) => setTopics(data))
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao listar tópicos',
        }),
      )
      .finally(hideLoading);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <BoxContainer>
      <ModalConfirmDelete
        title="Excluir tópico?"
        confirm={deleteTopicOption}
        textConfirm="Excluir tópico"
        loading={removeLoading.loading}
        {...topicConfirmDelete.modalProps}
      >
        <Text align="center" size={15} margin="10px 0 20px 0">
          Deseja remover este tópico?
        </Text>
      </ModalConfirmDelete>

      <ModalConfirmDelete
        title="Desativar curso?"
        confirm={removeCourse}
        textConfirm="Desativar curso"
        loading={removeLoading.loading}
        {...modalConfirmDelete.modalProps}
      >
        <Text align="center" size={15} margin="10px 0 20px 0">
          Ao desativar um curso, ele não ficará mais disponível no catálogo de
          suas startups, sendo exibido apenas para você em uma aba{' '}
          <strong>cursos desativados</strong>. As startups que estiverem
          executando o curso ainda terão acesso a ele até que o finalizem.
          <br />
          <strong>Deseja desativar o curso?</strong>
        </Text>
      </ModalConfirmDelete>

      <CoursesForm
        {...modalCoursesForm.modalProps}
        course={courseEdit}
        reload={() => {
          getData();
          modalCoursesForm.close();
        }}
      />

      <ModalBody
        title={contentModal[0]?.title}
        width={800}
        open={visibleModal}
        destroyOnClose={true}
        onCancel={showModal}
        footer={null}
      >
        {contentModal[0]?.body}
      </ModalBody>
      <GoBack enableBackground={true} path="courses" />
      <Conteiner>
        <BoxImage srcImage={course?.coverPicture} />
        <ActionsBox justify="space-between">
          <Title size={20} margin="10px 0 10px 0">
            {course?.name}
          </Title>
          <ActionsCrud
            boxPosition="left"
            style={{ display: 'flex' }}
            remove={() => modalConfirmDelete.show()}
            edit={() => editCourse()}
            enabledActions={[
              EnabledActionsCrudEnum.EDIT,
              EnabledActionsCrudEnum.REMOVE,
            ]}
          />
        </ActionsBox>
        <Info width="auto" align="left">
          <div className="label">
            <ClockCircleOutlined /> &nbsp; {course?.duration}
          </div>
          <div className="label">
            <PlaySquareOutlined /> &nbsp;{' '}
            {`${course?.lessonQuantity} aulas em vídeo`}
          </div>
          <div className="label">
            <FileTextOutlined /> &nbsp;{' '}
            {`${course?.amountExercises} exercícios`}
          </div>
          <div className="label">
            <DownloadOutlined /> &nbsp; {course?.extraMaterialAmount ?? 0}{' '}
            arquivos complementares
          </div>
        </Info>
        <div
          style={{
            margin: '10px 0 10px 0',
            textAlign: 'justify',
            fontSize: '1rem',
          }}
        >
          {course?.description}
        </div>
        {course?.videoUrl && (
          <ReactPlayer
            height={450}
            controls={true}
            url={course?.videoUrl}
            playing={true}
            width={'100%'}
            config={{
              youtube: { playerVars: { disablekb: 1 } },
            }}
          />
        )}
        <Title size={20} margin="10px 0 10px 0">
          Grade do curso
        </Title>
        <ContainerTopic>
          <Spin spinning={loading}>
            {topics.map((topic, index) => (
              <BoxTopic
                key={index}
                draggable
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <Collapse ghost activeKey={activeTopics}>
                  <Panel
                    key={index}
                    extra={
                      <div onClick={() => handlePanel(index)}>
                        {checkPanel(index) ? (
                          <CaretUpOutlined />
                        ) : (
                          <CaretDownOutlined />
                        )}
                      </div>
                    }
                    showArrow={false}
                    header={
                      <HeaderTopic>
                        <HolderOutlined
                          id="decoration-img"
                          style={{
                            position: 'absolute',
                            top: 15,
                            left: '-20px',
                          }}
                        />
                        <div>{topic.name}</div>
                        <Popover
                          placement="left"
                          content={
                            <Menu mode="vertical" items={menuItens(topic)} />
                          }
                          title=""
                          trigger="hover"
                        >
                          <MoreOutlined
                            style={{
                              position: 'absolute',
                              right: 40,
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                        <Info
                          width="auto"
                          align="left"
                          color="var(--primary-color)"
                        >
                          <div className="label">
                            <PlaySquareOutlined /> &nbsp;{' '}
                            {`${topic.lessons.length} | 20 min`}
                          </div>
                          <div className="label">
                            <FileTextOutlined /> &nbsp;{' '}
                            {`${topic.exercises.length} exercícios`}
                          </div>
                        </Info>
                      </HeaderTopic>
                    }
                  >
                    <CourseItemsBox>
                      {topic.lessons.map((lesson, index) => {
                        countItem++;
                        return (
                          <CourseItem key={`lesson_info_${index}`}>
                            <strong>
                              Aula {countItem}: {lesson.name}{' '}
                            </strong>
                            <ActionsBox>
                              <PlaySquareOutlined /> &nbsp; {lesson.duration}{' '}
                              min
                              <ActionsCrud
                                style={{ display: 'flex' }}
                                remove={() =>
                                  removeLesson(Number(lesson.entityId))
                                }
                                edit={() =>
                                  editLesson(topic, Number(lesson.entityId))
                                }
                                enabledActions={[
                                  EnabledActionsCrudEnum.EDIT,
                                  EnabledActionsCrudEnum.REMOVE,
                                ]}
                              />
                            </ActionsBox>
                          </CourseItem>
                        );
                      })}
                      {topic.extraMaterials.map((extraMaterial, index) => {
                        countItem++;
                        return (
                          <CourseItem key={`extraMaterial_info_${index}`}>
                            <strong>
                              Material extra {countItem}: {extraMaterial.name}
                            </strong>
                            <ActionsBox justify="flex-end">
                              <ActionsCrud
                                style={{ display: 'flex' }}
                                edit={() =>
                                  editExtraMaterial(
                                    topic,
                                    Number(extraMaterial.entityId),
                                  )
                                }
                                remove={() =>
                                  removeExtraMaterial(
                                    Number(extraMaterial.entityId),
                                  )
                                }
                                enabledActions={[
                                  EnabledActionsCrudEnum.EDIT,
                                  EnabledActionsCrudEnum.REMOVE,
                                ]}
                              ></ActionsCrud>
                            </ActionsBox>
                            <BoxFile
                              onClick={() =>
                                openExtraMaterial(extraMaterial.fileName)
                              }
                            >
                              <GrDocumentPdf size={22} />
                              {extraMaterial.name}
                            </BoxFile>
                          </CourseItem>
                        );
                      })}
                      {topic.exercises.map((exercise, index) => {
                        countItem++;
                        return (
                          <CourseItem key={`lesson_info_${index}`}>
                            <strong>
                              Exercício {countItem}: {exercise.name}
                            </strong>
                            <ActionsBox justify="flex-end">
                              <ActionsCrud
                                style={{ display: 'flex' }}
                                remove={() =>
                                  removeExercise(Number(exercise.entityId))
                                }
                                edit={() =>
                                  editExercise(topic, Number(exercise.entityId))
                                }
                                enabledActions={[
                                  EnabledActionsCrudEnum.EDIT,
                                  EnabledActionsCrudEnum.REMOVE,
                                ]}
                              ></ActionsCrud>
                            </ActionsBox>
                          </CourseItem>
                        );
                      })}
                    </CourseItemsBox>
                  </Panel>
                </Collapse>
              </BoxTopic>
            ))}
          </Spin>
        </ContainerTopic>
        <div style={{ marginTop: ' 10px', display: `${topicFormVisible}` }}>
          <Form
            layout="vertical"
            onFinish={onFinishTopicForm}
            autoComplete={'off'}
            name={'formTopic'}
            form={form}
          >
            <Row align="middle" gutter={[4, 4]}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={'title'}
                  label="Nome do tópico"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório',
                    },
                    {
                      min: 5,
                      message: 'deve ter pelo menos 5 caracteres',
                    },
                    {
                      max: 150,
                      message: 'deve ter no máximo 150 caracteres',
                    },
                  ]}
                >
                  <Input
                    placeholder="Digite o nome do tópico"
                    maxLength={255}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <PurpleButton type="primary" htmlType="submit" form="formTopic">
                  Salvar
                </PurpleButton>
                <Button
                  type="default"
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    showTopicForm('none');
                    setTopicId(0);
                  }}
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <PurpleButton
          type="primary"
          onClick={() => showTopicForm('table')}
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '10px',
          }}
        >
          Novo tópico
        </PurpleButton>
      </Conteiner>
    </BoxContainer>
  );
}

export default Course;
