import { IFeature } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';

class FeatureService extends CrudService {
  constructor() {
    super({
      requestMapping: '/feature',
    });
  }

  public static init(): FeatureService {
    return new FeatureService();
  }

  public async getAllFeature(): Promise<IFeature[]> {
    return api
      .get<IFeature[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default FeatureService;
