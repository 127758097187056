import React, { useEffect, useMemo, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import useLoading from '../../../../core/hooks/useLoading';
import OnEmptyOrList from '../../../../components/Screen/OnEmptyOrList';
import UserService from '../../../../services/UserService';
import { IUser } from '../../../../core/interfaces';
import ItemList from '../List/ItemList';

function UsersRejected() {
  const { loading, showLoading, hideLoading } = useLoading();
  const userService = useMemo(() => UserService.init(), []);
  const [users, setUsers] = useState<IUser[]>([]);
  const getUsers = () => {
    showLoading();
    userService
      .getAllUsersByProfilesIsNull()
      .then((res) => {
        setUsers(res);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Loading spinning={loading} width={200} height={200} position={'absolute'}>
      <OnEmptyOrList list={users} description={'Nao há usuários rejeitados.'}>
        {users.map((item, index) => {
          return (
            <ItemList
              data={item}
              key={index}
              updateList={getUsers}
              showLoading={showLoading}
              hideLoading={hideLoading}
            />
          );
        })}
      </OnEmptyOrList>
    </Loading>
  );
}

export default UsersRejected;
