import IRequestLessonProgress from '../../core/interfaces/attend-course/IRequestLessonProgress';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class ProgressService {
  private readonly baseLesson = 'lesson-progress';

  public static init() {
    return new ProgressService();
  }

  public lesson(request: IRequestLessonProgress): Promise<void> {
    return api
      .post(this.baseLesson, {
        ...request,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ProgressService;
