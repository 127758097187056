import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class TopicWithProgressService {
  private readonly requestMapping = 'topic-progress';

  public static init() {
    return new TopicWithProgressService();
  }

  public getByCourse(course: number, student: number) {
    const params = {
      course,
      student,
    };

    return api
      .get(this.requestMapping, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default TopicWithProgressService;
