import styled from 'styled-components';

const Wrapper = styled.div`
  width: 230px;
  min-height: 200px;
  box-shadow: 0 0 0 1px var(--support-color);
  border-radius: 8px;
`;

const Body = styled.div`
  text-align: center;
  padding: 16px 8px;
  display: grid;
  gap: 10px;
`;

const ImgPicture = styled.img`
  object-fit: cover;
  width: 65px;
  height: 65px;
  border-radius: 50%;
`;

const CompanyInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 6px;
`;

const SkillsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  gap: 8px;
`;

const Footer = styled.footer`
  background-color: #05233d;
  color: #fff;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 0 1px #05233d;

  &:hover {
    background-color: var(--quaternary-support);
  }
`;

export { Body, ImgPicture, SkillsWrapper, Footer, CompanyInformation, Wrapper };
