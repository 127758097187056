import styled from 'styled-components';

const ButtonUpload = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;

  &:focus-visible {
    box-shadow: 0 0 0 1px var(--primary-color);
  }
`;

export { ButtonUpload };
