import styled from 'styled-components';

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 10px 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;

  & .addItem {
    position: absolute;
    top: 10px;
    right: 10px;

    :hover {
      background-color: var(--primary-color);

      svg {
        color: var(--white-color);
        transform: scaleX(-1);
        transition-duration: 500ms;
      }
    }
  }
`;

const BoxLevels = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 10px 0 10px 0;
`;

const BoxTree = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 33%;
  max-width: 33%;
  margin-bottom: 5px;
  padding: 5px 5px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
`;

const SubTitle = styled.div`
  font-size: 0.8rem;
  font-style: italic;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  .icon-button {
    padding: 5px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    &:hover {
      background-color: #f3f3f3;
    }
  }
`;

const BoxProfilesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;

  span {
    text-align: center;
  }
`;

const ProfileBox = styled.div`
  align-items: center;
  padding: 5px 5px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  margin-bottom: 2px;

  .actionsButtons {
    display: flex;
    position: absolute;
    right: 0;
    height: 100%;
    align-items: center;

    svg {
      cursor: pointer;
      font-size: 20px;
    }
  }
`;

const Info = styled.div<{
  width?: string;
  overflow?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin-left: 5%;
  ${(props) =>
    props.overflow === 'true'
      ? 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'
      : ''};

  .label {
    font-weight: bold;
  }
`;

const PurppleText = styled.div<{
  fontBold?: boolean;
  fontSize?: string;
}>`
  color: var(--thirdy-color);
  font-weight: ${(props) => (props.fontBold ? 'bold' : 'normal')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
`;

const SeeAll = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  span {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: var(--thirdy-color);
    }
  }
`;

const BoxMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .item-button {
    border: none;
    color: #ffffff;
    background-color: var(--thirdy-color);

    &:hover {
      background-color: var(--quaternary-color);
    }
  }
`;

export {
  Box,
  BoxTree,
  BoxLevels,
  Header,
  BoxProfilesList,
  ProfileBox,
  Info,
  SeeAll,
  SubTitle,
  PurppleText,
  BoxMessage,
};
