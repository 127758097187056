import axios, { RawAxiosRequestHeaders } from 'axios';

const STATUS_CODE_FORBIDDEN = 403;
const STATUS_CODE_UNAUTHORIZED = 401;
const STATUS_CODE_BAD_REQUEST = 400;
const STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

api.defaults.headers.common['Content-Type'] = 'application/json';

api.interceptors.request.use((config) => {
  const AUTH_TOKEN = localStorage.getItem('@innovation:auth');
  const headers = { ...config.headers } as RawAxiosRequestHeaders;
  if (AUTH_TOKEN && config.headers) {
    headers['Authorization'] = `Bearer ${AUTH_TOKEN}`;
  }

  config.headers = headers;

  return config;
});

api.interceptors.response.use(
  (config) => config,
  (error) => {
    const jwtToken = localStorage.getItem('@innovation:auth');

    if (error?.response?.status === STATUS_CODE_FORBIDDEN) {
      return Promise.reject(error);
    } else if (
      error?.response?.status === STATUS_CODE_UNAUTHORIZED &&
      jwtToken
    ) {
      localStorage.clear();
      //redirect to login page
      window.location.href = '/session-expired';
      return Promise.reject(error);
    } else if (error?.response?.status === STATUS_CODE_INTERNAL_SERVER_ERROR) {
      return Promise.reject(error);
    } else if (error?.response?.status === STATUS_CODE_BAD_REQUEST) {
      return Promise.reject(error);
    }
  },
);
export default api;
