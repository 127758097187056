import { notification } from 'antd';
import { IError } from '../../../core/interfaces';

const ErrorNotification = (error: IError) => {
  notification.error({
    message: 'Erro',
    description: error.error ?? error.defaultMessage,
  });
};
export default ErrorNotification;
