import { Col, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IDashboardMetricStartup from '../../../../core/interfaces/dashboard-startup/IDashboardMetricStartup';
import { Card, PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import DashboardStartupService from '../../../../services/dashboard-startup/DashboardStartupService';
import OkrProgressCard from './OkrProgressCard';

function Okrs() {
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();
  const [metrics, setMetrics] = useState<IDashboardMetricStartup[]>([]);
  const dashboardStartup = useMemo(() => DashboardStartupService.init(), []);

  const getLastMetrics = useCallback(() => {
    showLoading();
    dashboardStartup
      .getLatestMetrics(Number(logged?.entityId))
      .then((data) => setMetrics(data))
      .catch(() => notification.info('Erro ao listar métricas'))
      .finally(hideLoading);
  }, [logged, hideLoading, showLoading, dashboardStartup]);

  useEffect(() => {
    getLastMetrics();
  }, [getLastMetrics]);

  return (
    <Spin spinning={loading} delay={300}>
      <Card>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <Title align="center" size={22} lineHeight={1.3}>
              OKRs
            </Title>
          </Col>
          <Col span={24}>
            {isEmpty(metrics) ? (
              <>
                <Text
                  size={18}
                  weight={700}
                  align="center"
                  margin="0 0 0.5rem 0"
                >
                  Você ainda não possui OKRs cadastrados
                </Text>
                <Text size={16} align="center" maxWidth="50ch" margin="0 auto">
                  Cadastre rapidamente seus objetivos para que sua startup seja
                  vista com bons olhos pelos investidores.
                </Text>
              </>
            ) : (
              <Row gutter={[0, 16]}>
                {metrics.map((metric, index) => (
                  <Col span={24} key={`key_okr_progress_${index}`}>
                    <OkrProgressCard {...metric} />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
          <Col span={24}>
            <PurpleButton
              onClick={() => navigate('/metrics')}
              type="primary"
              style={{ margin: '0 auto', display: 'block' }}
            >
              Ver lista de OKRs
            </PurpleButton>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
}

export default Okrs;
