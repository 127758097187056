enum IExerciseQuizType {
  OBJECTIVE,
  DISCURSIVE,
  ATTACHMENT,
  MULTIPLE,
}

const getValueString = (value: number) => {
  return ['OBJECTIVE', 'DISCURSIVE', 'ATTACHMENT', 'MULTIPLE'][value];
};

const getDescription = (type: IExerciseQuizType): string => {
  const { ATTACHMENT, DISCURSIVE, OBJECTIVE, MULTIPLE } = IExerciseQuizType;
  const texts = {
    [OBJECTIVE]: 'Múltipla escolha',
    [DISCURSIVE]: 'Resposta em parágrafo',
    [ATTACHMENT]: 'Anexo de arquivo',
    [MULTIPLE]: 'Múltiplas escolha',
  };

  return texts[type];
};

export default IExerciseQuizType;
export { getValueString, getDescription };
