import React, { useEffect, useState } from 'react';
import ValuationTopic from './ValuationTopic';
import ContentBase from '../../templates/ContentBase';
import { Col, Row, Divider, notification } from 'antd';
import { isEmpty } from '../../../helpers/array.helper';
import { IValuationResult } from '../../../core/interfaces';
import ScreenInformationVoid from '../../../components/Screen/ScreenInformationVoid';
import { useNavigate } from 'react-router-dom';
import {
  getValuationResult,
  verifyValuation,
} from '../../../services/ValuationService';
import api from '../../../core/services/api';
import ValuationTopicMobile from './ValuationTopicMobile';
import { MediaQueryVisible } from '../../../GlobalStyles';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import ValuationStartupInfo from '../../Infos/ValuationStartupInfo';
import { useAuth } from '../../../core/hooks/auth';
import Text from '../../../components/Text';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';

interface IListValuationResult {
  active: Array<IValuationResult>;
  inactive: Array<IValuationResult>;
}

function StartupValuation() {
  const navigate = useNavigate();
  const [resultActive, setResultActive] = useState<Array<IValuationResult>>([]);
  const [resultInactive, setResultInactive] = useState<Array<IValuationResult>>(
    [],
  );
  const { loading, showLoading, hideLoading } = useLoading();
  const { logged } = useAuth();
  const id = logged?.entityId;
  const [isValuation, setIsValuation] = useState(false);

  const getData = () => {
    showLoading();
    getValuationResult()
      .then((data: IListValuationResult) => {
        setResultActive(data.active);
        setResultInactive(data.inactive);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao listar valuation',
        });
      })
      .finally(hideLoading);
  };

  const verifyValuationToAccelerator = () => {
    showLoading();
    verifyValuation(Number(id))
      .then((data: any) => {
        setIsValuation(data);
      })
      .finally(hideLoading);
  };

  const newValuation = async () => {
    showLoading();
    await api
      .post('valuationResult/create')
      .then((response: any) => {
        navigate('/valuations/questions', {
          state: response.data,
        });
        notification.success({
          message: 'Tudo certo!',
          description: 'Valor salvo com sucesso!',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Algo deu errado!',
          description: 'Erro!',
        });
      })
      .finally(hideLoading);
  };

  const updateValuation = async (id: number) => {
    if (id === null)
      return notification.error({
        message: 'Algo deu errado!',
        description: 'O id não pode ser nulo!',
      });
    showLoading();
    await api
      .put(`valuationResult/update/${id}`)
      .then((response: any) => {
        navigate('/valuations/questions', {
          state: response.data,
        });
        notification.success({
          message: 'Tudo certo!',
          description: 'Valor salvo com sucesso!',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Algo deu errado!',
          description: error.response.data.message,
        });
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getData();
    verifyValuationToAccelerator();
  }, []);

  const contentBase = () => {
    if (!isEmpty(resultActive)) {
      return (
        <>
          <Text size={18} weight={700} margin={'0 0 14px 0'}>
            Valuation Atual
          </Text>
          {resultActive?.map((item, index: number) => (
            <div key={`item_valuation_key_${item.entityId}`}>
              <Row gutter={[0, 16]}>
                <Col key={`key_topic_valuation_${item.entityId}`} span={24}>
                  <MediaQueryVisible minWidth="501px" display="none">
                    <ValuationTopicMobile
                      id={item.entityId}
                      preMoney={item.preMoney}
                    />
                  </MediaQueryVisible>
                  <MediaQueryVisible maxWidth="500px" display="none">
                    <ValuationTopic
                      {...item}
                      editResult={() => updateValuation(Number(item.entityId))}
                    />
                  </MediaQueryVisible>
                </Col>
              </Row>
              <Divider />
            </div>
          ))}
          <Text size={18} weight={700} margin={'0 0 14px 0'}>
            Histórico de Valuations
          </Text>
          <OnEmptyOrList list={resultInactive}>
            {resultInactive?.map((item, index: number) => (
              <div key={`item_valuation_key_${item.entityId}`}>
                <Row gutter={[0, 16]}>
                  <Col key={`key_topic_valuation_${item.entityId}`} span={24}>
                    <MediaQueryVisible minWidth="501px" display="none">
                      <ValuationTopicMobile
                        id={item.entityId}
                        preMoney={item.preMoney}
                      />
                    </MediaQueryVisible>
                    <MediaQueryVisible maxWidth="500px" display="none">
                      <ValuationTopic
                        {...item}
                        editResult={() => newValuation()}
                      />
                    </MediaQueryVisible>
                  </Col>
                </Row>
                <Divider />
              </div>
            ))}
          </OnEmptyOrList>
        </>
      );
    } else {
      return (
        <>
          {!isValuation ? (
            <ScreenInformationVoid
              message={'Sua aceleradora não possui valuation'}
              title={'Não há valuation para ser realizado'}
              description={
                'Provavelmente sua aceleradora ainda definiu um valuation, entre em contato com sua aceleradora'
              }
            />
          ) : (
            <ScreenInformationVoid
              message={'Sem valuation no momento...'}
              title={'Você ainda não possui Valuation'}
              description={'Clique no botão abaixo para realizar um valuation'}
              textButton={'Realizar valuation'}
              isButton={true}
              onClickButton={newValuation}
            />
          )}
        </>
      );
    }
  };
  return (
    <>
      <Loading
        width={200}
        height={200}
        position={'absolute'}
        spinning={loading}
      >
        <ContentBase
          title="Valuation"
          modalContent={<ValuationStartupInfo />}
          content={contentBase()}
        />
      </Loading>
    </>
  );
}

export default StartupValuation;
