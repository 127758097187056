import React, { useEffect, useMemo, useState } from 'react';
import {
  getKeys,
  getFullName,
  getAbbreviation,
} from '../../../core/enums/IWeekDay';
import {
  BoxDay,
  BoxDayTime,
  BoxLegend,
  BoxSchedule,
  BoxScheduleInfo,
  BoxTime,
  BoxTimeContent,
  Header,
  Item,
  Legend,
} from './style';
import { Divider, Popover, Spin } from 'antd';
import { ModalBody } from '../../../GlobalStyles';
import ScheduleWeekDayForm from '../ScheduleWeekDayForm/ScheduleWeeKDayForm';
import moment, { Moment } from 'moment';
import ZoomMeetingService from '../../../services/zoom/ZoomMeetingService';
import { notification } from '../../../helpers/notification.helper';
import useLoading from '../../../core/hooks/useLoading';
import { ISchedule } from '../../../core/interfaces/ISchedule';

interface IProps {
  date: string;
  reloadDates(): void;
  schedules: ISchedule[];
  /**
   * @param "the start hour to show"
   * @example 08:00
   */
  minRange?: string;
  /**
   * @param "the end hour to show"
   * @example 18:00
   */
  maxRange?: string;
}
interface ICalendar {
  entityId?: number;
  date: string;
  start: string;
  end: string;
  mentorName?: string;
}

function ScheduleWeekCalendar({
  schedules,
  date,
  reloadDates,
  minRange = '06:00',
  maxRange = '22:00',
}: IProps) {
  const weekDays = getKeys();
  const clickedDay = moment(date).format('DD');
  const firstDay = moment(date).startOf('week').format('YYYY-MM-DD');
  const month = moment(date).month() + 1;
  let counterDay = firstDay;
  const disableRangeTime: Array<number> = useMemo(() => [], []);

  const zoomMeetingService = useMemo(() => ZoomMeetingService.init(), []);

  const { loading, showLoading, hideLoading } = useLoading();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [selectedTime, setSelectedTime] = useState<Moment | null>(null);
  const [dataCalendar, setDataCalendar] = useState<ICalendar[]>([]);

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const convertMinsToHour = (minsToHour: number, mins?: number) => {
    const time = Math.floor(minsToHour / 60);
    const adjustedHour = time < 10 ? '0' + time : time;
    const adjustedMins = mins ? mins : '00';

    return adjustedHour + ':' + adjustedMins;
  };

  const getDay = (index: number) => {
    return moment(firstDay).add(index, 'days');
  };

  const getTime = (day: Moment, time: string) => {
    const [hour, minute] = time.split(':');
    return moment(day)
      .set('hour', parseInt(hour))
      .set('minute', parseInt(minute));
  };

  const checkMonth = (index: number) => {
    const pointerMonth = moment(getDay(index)).month() + 1;

    return pointerMonth === month ? '' : 'no-click';
  };

  const getWeekDay = (index: number) => {
    counterDay =
      index !== 0
        ? moment(counterDay).add(1, 'days').format('YYYY-MM-DD')
        : counterDay;

    return moment(counterDay).format('DD');
  };

  const getTimes = () => {
    const calendar: ICalendar[] = [];

    schedules.forEach((schedule) => {
      const date = moment(schedule.initialDate);
      const dateFormat = date.format('YYYY-MM-DD');

      const start = moment(schedule.initialDate);
      const startFormat = start.format('HH:mm');

      const end = moment(schedule.finalDate);
      const endFormat = end.format('HH:mm');

      const mentorName = schedule.mentor?.user?.name;
      const entityId = schedule.entityId;

      calendar.push({
        entityId,
        date: dateFormat,
        start: startFormat,
        end: endFormat,
        mentorName,
      });
    });

    setDataCalendar(calendar);
  };

  const verifyScheduleTimes = (day: string, time: string) => {
    let result = true;

    dataCalendar
      .filter((calendar: ICalendar) => calendar.date === day)
      .forEach((calendar: ICalendar) => {
        const start = parseInt(calendar.start.replace(':', ''));
        const end = parseInt(calendar.end.replace(':', ''));
        const paramTime = parseInt(time.replace(':', ''));

        if (paramTime >= start && paramTime <= end) {
          result = false;
        }
      });
    return result;
  };

  const handleClickDay = (index: number, time: string) => {
    const day = getDay(index);

    if (verifyScheduleTimes(day.format('YYYY-MM-DD'), time)) {
      setSelectedDate(day);
      setSelectedTime(getTime(day, time));
      showModal();
    }
  };

  const createMeeting = (scheduleId: number) => {
    showLoading();
    zoomMeetingService
      .create(scheduleId)
      .then((data) => window.open(data.start_url, '_blank'))
      .catch(() => notification.error('Erro ao criar reunião'))
      .finally(hideLoading);
  };

  const authorizedSchedule = (calendar: ICalendar): boolean => {
    const { date, start: startTime, end: endTime } = calendar;

    const endDate = moment(`${date} ${endTime}`);
    const startDate = moment(`${date} ${startTime}`);
    const currentDate = moment();
    return currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
  };

  const startClass = (calendar: ICalendar) => {
    if (authorizedSchedule(calendar)) {
      createMeeting(Number(calendar.entityId));
    } else {
      notification.info('Horário selecionado não está disponível');
    }
  };

  const scheduleTime = (calendar: ICalendar) => {
    return (
      <Spin spinning={loading} delay={300}>
        <BoxScheduleInfo>
          <span>{`${calendar.start} - ${calendar.end}`}</span>
          <span className="reschedule">Reagendar</span>
          <Divider
            style={{ backgroundColor: 'var(--white-color)', margin: 0 }}
          />
          <span className="start-class" onClick={() => startClass(calendar)}>
            Iniciar aula
          </span>
        </BoxScheduleInfo>
      </Spin>
    );
  };

  const mountWeekDays = () => {
    return weekDays.map((item, index) => {
      const day = getWeekDay(index);
      const className =
        moment(counterDay).format('DD') === clickedDay
          ? `${checkMonth(index)} today`
          : `${checkMonth(index)}`;

      return (
        <Item key={day} className={className}>
          <span>{day}</span>
          <abbr title={getFullName(index)}>{getAbbreviation(index)}</abbr>
        </Item>
      );
    });
  };

  const mountScheduleTimes = (index: number, time: string) => {
    const div: JSX.Element[] = [];
    const day = moment(getDay(index)).format('YYYY-MM-DD');

    dataCalendar
      .filter((item: ICalendar) => item.date === day)
      .forEach((item: ICalendar) => {
        const start = parseInt(item.start.replace(':', ''));
        const end = parseInt(item.end.replace(':', ''));
        const paramTime = parseInt(time.replace(':', ''));

        if (paramTime >= start && paramTime <= end) {
          div.push(
            <div key={`schedule-${day}-${time}`} className="time-scheduled">
              {
                <Popover
                  placement="left"
                  content={scheduleTime(item)}
                  title=""
                  trigger="click"
                  destroyTooltipOnHide={true}
                  showArrow={false}
                />
              }
            </div>,
          );
        }
      });

    return div;
  };

  const mountHourTimeLine = () => {
    const div: JSX.Element[] = [];
    let countHour = 0;

    for (let i = 0; i < 1440; i += 60) {
      countHour += 60;

      if (i === 0 || countHour === 60) {
        const convertedMins = convertMinsToHour(i);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          div.push(
            <div
              key={`header-${convertedMins}`}
              className="timeline-header-hour"
            >
              <span>{convertedMins}</span>
            </div>,
          );
        }
        countHour = 0;
      }
    }

    return div;
  };

  const mountMinutesTimeLine = (index: number) => {
    const div: JSX.Element[] = [];
    let countMinutes = 0;
    let countHour = 0;

    const getDiv = (
      index: number,
      convertedMins: string,
      className: string,
    ) => {
      div.push(
        <div
          key={`${index}-${convertedMins}`}
          className={`${checkMonth(index)} ${className}`}
          onClick={() => handleClickDay(index, convertedMins)}
        >
          <span>{convertedMins}</span>
          {mountScheduleTimes(index, convertedMins)}
        </div>,
      );
    };

    for (let i = 0; i < 1440; i++) {
      if (
        (countMinutes === 15 || countMinutes === 30 || countMinutes === 45) &&
        countHour < 60
      ) {
        const convertedMins = convertMinsToHour(i, countMinutes);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          getDiv(index, convertedMins, 'timeline-minute');
        }
      } else if (countHour === 60) {
        const convertedMins = convertMinsToHour(i);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          getDiv(index, convertedMins, 'timeline-hour');
        }
        countHour = 0;
        countMinutes = 0;
      }
      countMinutes++;
      countHour++;
    }

    return div;
  };

  {
    /* ADICIONAR OS AGENDAMENTOS AO RANGE */
  }
  useEffect(() => {
    const disableRange = () => {
      const [minHour] = minRange.replace('0', '').split(':');
      const [maxHour] = maxRange.replace('0', '').split(':');

      for (let i = 0; i < 24; i++) {
        if (i < parseInt(minHour) || i > parseInt(maxHour)) {
          disableRangeTime.push(i);
        }
      }
    };

    disableRange();
  }, [minRange, maxRange, disableRangeTime]);

  useEffect(() => {
    getTimes();
  }, [schedules]);

  return (
    <>
      <ModalBody
        title=""
        width={500}
        open={isModalVisible}
        closable={false}
        destroyOnClose={true}
        onCancel={showModal}
        footer={false}
      >
        <ScheduleWeekDayForm
          sucessCreateSchedule={reloadDates}
          closeModal={showModal}
          date={selectedDate}
          time={selectedTime}
          disableRangeTime={disableRangeTime}
        />
      </ModalBody>
      <BoxSchedule>
        <Header>
          <div style={{ width: '60px' }}>&nbsp;</div>
          {mountWeekDays()}
        </Header>
        <BoxTimeContent>
          <BoxTime>{mountHourTimeLine()}</BoxTime>
          <BoxDayTime>
            {Array.from(Array(7)).map((item, index) => (
              <BoxDay key={index}>{mountMinutesTimeLine(index)}</BoxDay>
            ))}
          </BoxDayTime>
        </BoxTimeContent>
        <BoxLegend>
          <Divider
            style={{ backgroundColor: 'var(--support-color)', margin: 0 }}
          />
          <Legend>
            <Legend>
              <span className="avaiable">&nbsp;</span>
              Horários disponíveis
            </Legend>
            <Legend>
              <span className="scheduled">&nbsp;</span>
              Horários agendados
            </Legend>
          </Legend>
        </BoxLegend>
      </BoxSchedule>
    </>
  );
}

export default ScheduleWeekCalendar;
