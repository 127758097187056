import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, FormInstance, Input, notification, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import useLoading from '../../../../../core/hooks/useLoading';
import { IClient, IState } from '../../../../../core/interfaces';
import api from '../../../../../core/services/api';
import { ClientServices } from '../../../../../services/ClientServices';

interface IProps {
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setButtonState: (buttonState: boolean) => void;
  setFormName: (value: string) => void;
  initialValues?: any;
}

function AcceleratorForm(props: IProps) {
  const [states, setStates] = React.useState<IState[]>([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const clientService = ClientServices.init();
  const { loading, showLoading, hideLoading } = useLoading();

  const arrangeValues = (values: FormInstance) => {
    const valuesMap = new Map(Object.entries(values));

    const result: IClient = {
      name: valuesMap.get('name'),
      cnpj: valuesMap.get('cnpj'),
      numberEmployee: valuesMap.get('numberEmployees'),
      email: valuesMap.get('email'),
      address: {
        street: valuesMap.get('address'),
        number: valuesMap.get('number'),
        complement: valuesMap.get('complement'),
        neighborhood: valuesMap.get('neighborhood'),
        city: valuesMap.get('city'),
        zipCode: valuesMap.get('zipCode'),
        ufId: selectState(valuesMap.get('uf')),
      },
      userId: props.initialValues.entityId,
      nameUser: props.initialValues?.name,
      cellPhone: props.initialValues?.cellPhone,
      cpf: props.initialValues?.cpf,
      office: props.initialValues?.office,
      area: props.initialValues?.area,
      profileId: 2,
    };

    return result;
  };

  const onChangeCep = (value: string) => {
    if (value.length === 8) {
      showLoading();
      api
        .get(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => {
          const data = response.data;

          if (data.erro) {
            form.setFields([{ name: 'zipCode', errors: ['CEP inexistente!'] }]);
          } else {
            form.setFieldsValue({
              address: data.logradouro,
              neighborhood: data.bairro,
              uf: data.uf,
              city: data.localidade,
            });
          }
        })
        .finally(hideLoading);
    } else {
      form.setFieldsValue({
        address: '',
        neighborhood: '',
        uf: '',
        city: '',
      });
    }
  };

  const selectState = (uf: string) => {
    const UFselected: any = states.filter((item) => item.uf === uf);

    return UFselected[0]?.entityId;
  };

  const getState = () => {
    api.get('/state').then((response) => {
      setStates(response.data);
    });
  };

  const onFinish = (values: FormInstance) => {
    clientService
      .createClient(arrangeValues(values))
      .then((response: any) => {
        notification.success({
          message: 'Sucesso',
          description: 'Cliente cadastrado com sucesso!',
        });
        props.setCurrent(props.current + 1);
        localStorage.setItem('@innovation:auth', response.jwttoken);
        navigate('/register/conclude/profile/final');
      })
      .catch((error) => {
        notification.error({
          message: 'Erro',
          description: 'Erro ao cadastrar cliente!',
        });
      });
  };

  useEffect(() => {
    getState();
    props.setFormName('formSpeeder');
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name={'formSpeeder'}
      layout="vertical"
      labelWrap
    >
      <Row align="bottom" gutter={[8, 8]}>
        <Col xs={24} sm={24} lg={12} md={24}>
          <Form.Item
            label="Qual seu nome da aceleradora?"
            name="name"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite o nome da aceleradora" maxLength={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            label="CNPJ e Razão Social"
            name={'cnpj'}
            rules={[
              {
                required: true,
                message: 'CNPJ é um campo obrigatório',
              },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
                message: 'CNPJ inválido',
              },
            ]}
          >
            <MaskedInput mask="00.000.000/0000-00" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            label="Quantos funcionários possui?"
            name="numberEmployees"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input
              type={'number'}
              placeholder="Total de funcionários"
              maxLength={11}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} span={12}>
          <Form.Item
            label="Qual o email principal da aceleradora?"
            name="email"
            rules={[
              {
                required: true,
                message: 'Email é um campo obrigatório!',
              },
              {
                type: 'email',
                message: 'Email inválido!',
              },
            ]}
          >
            <Input placeholder="E-mail" maxLength={80} />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>
      <Row align="top" gutter={[8, 8]}>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            label="CEP"
            name="zipCode"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              {
                pattern: /^\d{8}$/,
                message: 'CEP inválido!',
              },
            ]}
            extra={'Digite somente números'}
            validateStatus={loading ? 'validating' : undefined}
            hasFeedback={loading}
          >
            <Input
              maxLength={8}
              placeholder="Somente números"
              onChange={(e) => onChangeCep(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={10}>
          <Form.Item
            label="Endereço"
            name="address"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite seu endereço" maxLength={100} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={3}>
          <Form.Item
            label="Número"
            name="number"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Nº 00000" maxLength={6} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={5}>
          <Form.Item
            label="Bairro"
            name="neighborhood"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Bairro" readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item label="Complemento" name="complement">
            <Input placeholder="Complemento" maxLength={40} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Estado"
            name="uf"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="UF" readOnly />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Cidade"
            name="city"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="nome da cidade" readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AcceleratorForm;
