import React, { useEffect, useMemo, useState } from 'react';
import useLoading from '../../core/hooks/useLoading';
import notificationMessage from '../../components/notifications/notificationMessage';
import errorNotification from '../../components/notifications/errorNotification';
import EntityList from '../../components/EntityList/EntityList';
import { Spin } from 'antd';
import { IInvestor } from '../../core/interfaces/IInvestor';
import InvestorService from '../../services/InvestorService';

function InvestorList() {
  const { loading, showLoading, hideLoading } = useLoading();
  const [investors, setInvestors] = useState<IInvestor[]>([]);

  const investorService = useMemo(() => InvestorService.init(), []);

  const getInvestors = async () => {
    showLoading();
    investorService
      .getAllInvestor()
      .then((response) => {
        setInvestors(response);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const disableInvestor = () => {
    showLoading();
    investorService
      .update(1, { staus: false })
      .then(() => {
        notificationMessage('status');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao desativar investidor',
        }),
      )
      .finally(hideLoading);
  };

  useEffect(() => {
    getInvestors();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityList
        title={`Investidores (${investors.length})`}
        entity={investors}
        disable={disableInvestor}
        profilePath="investor/profile/"
      />
    </Spin>
  );
}

export default InvestorList;
