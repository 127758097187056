import styled from 'styled-components';
import { Modal, ModalProps, Button, ButtonProps } from 'antd';

const ModalContainer = styled(Modal)<ModalProps>``;

const Image = styled.img`
  width: 100px;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  & > button {
    max-width: 250px;
    width: 100%;
    height: auto;

    & > span {
      white-space: normal;
    }
  }
`;

const ButtonModal = styled(Button)<ButtonProps>`
  background-color: var(--thirdy-color);
  border: none;
`;
export { ModalContainer, Image, ModalContent, ButtonContainer, ButtonModal };
