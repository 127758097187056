import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Box, CarouselMobile, ItemProgress } from './styles';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { Card, PurpleButton } from '../../../../GlobalStyles';
import { IStagesStartupProgress } from '../../../../core/interfaces/dashboard-startup/IDashboardStartup';

interface Props {
  phases: IStagesStartupProgress[];
  bordered?: boolean;
  boxShadow?: string;
  title?: string;
  marginBox?: string;
  activePhase?: IStagesStartupProgress;
  showSelected?: boolean;
  speederHandler?: () => void;
  changeActivePhase?: (value: IStagesStartupProgress) => void;
}

function StartupProgress({
  phases,
  boxShadow,
  activePhase,
  bordered = true,
  changeActivePhase,
  showSelected = false,
  speederHandler,
  marginBox,
  title = 'Progresso',
}: Props) {
  const itemsProgress = useMemo(() => {
    return phases.map((phase) => {
      const { isCurrentPhase } = phase;
      return (
        <span key={`key_progress_${phase.entityId}`}>
          <ItemProgress
            accomplished={isCurrentPhase}
            selected={showSelected && phase === activePhase}
            onClick={() => changeActivePhase && changeActivePhase(phase)}
          >
            <span aria-label="bolinha"></span>
            <Text
              weight={700}
              size={19}
              align="center"
              color={isCurrentPhase ? '#05233D' : '#9AA4A8'}
            >
              {phase.name}
            </Text>
          </ItemProgress>
        </span>
      );
    });
  }, [activePhase, changeActivePhase, phases, showSelected]);

  return (
    <Card bordered={bordered} boxShadow={boxShadow}>
      <Title size={22} align="center" margin="0 0 30px 0">
        {title}
      </Title>
      <CarouselMobile dots={false}>{itemsProgress}</CarouselMobile>
      <Box marginBox={marginBox}>{itemsProgress}</Box>
      {!isEmpty(phases) && speederHandler && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PurpleButton type="primary" onClick={speederHandler}>
            Ver aceleração completa
          </PurpleButton>
        </div>
      )}
    </Card>
  );
}

export default StartupProgress;
