import React, { useEffect, useState } from 'react';
import Collapsed from '../../collapsed/collapsed';
import api from '../../../core/services/api';
import { useAuth } from '../../../core/hooks/auth';
import { BoxContent, Description } from './style';
import AlertMessage from '../../Alert/AlertMessage';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  ask: boolean;
  setAsk: (ask: boolean) => void;
  setFormName: (formName: string) => void;
  nameDiagnostic: string;
  setNext: (next: string) => void;
  setData: (data: any) => void;
  initialData?: any;
}

function MethodologyAssociation(props: IParams) {
  const { logged } = useAuth();
  const id = logged?.client as number;
  const [methodology, setMethodology] = useState<any>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { loading, showLoading, hideLoading } = useLoading();

  const getMethodology = () => {
    showLoading();
    api
      .get('methodology/listMethodologyActive', {
        params: {
          client: id,
        },
      })
      .then((response: any) => {
        setMethodology(response.data);
      })
      .finally(hideLoading);
  };

  const onOk = () => {
    props.setNext('1');
    setIsOpenModal(false);
  };

  useEffect(() => {
    getMethodology();
  }, []);

  return (
    <BoxContent>
      <Loading
        spinning={loading}
        width={300}
        height={300}
        position={'absolute'}
      >
        <Description>
          O diagnóstico deve ser correspondente à uma fase dentro de uma
          metodologia pois ao final de cada etapa, sua startup deverá preencher
          um novo diagnóstico para ter a certeza de que está apta para a próxima
          fase.
        </Description>
        <Collapsed
          setFormName={props.setFormName}
          nameDiagnostic={props.nameDiagnostic}
          setOpenModal={setIsOpenModal}
          content={methodology}
          clientId={id}
          setData={props.setData}
          initialData={props.initialData}
        />
        <AlertMessage
          visible={isOpenModal}
          message="Diagnóstico cadastrado com sucesso!"
          description="Avance para a próxima etapa."
          textCancelButton="Fechar"
          textOkButton="Continuar"
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Loading>
    </BoxContent>
  );
}

export default MethodologyAssociation;
