import { Radar } from '@ant-design/plots';

interface IParams {
  data: Array<any>;
  xField: string;
  yField: string;
  color: string;
  appendPadding: any;
  meta: any;
  point: object;
  area: object;
  xAxis: object;
  yAxis: object;
}

function GraphicResult(props: { data: Array<any>; xField: any; yField: any }) {
  const config: IParams = {
    data: props.data,
    xField: props.xField,
    yField: props.yField,
    color: '#05233d',
    appendPadding: [0, 20, 0, 20],
    meta: {
      result: {
        alias: 'Resultado',
        min: 0,
        max: 10,
        nice: true,
        formatter: (v: number) => Math.floor(v).toFixed(0),
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
      label: {
        style: {
          fill: '#05233d',
          opacity: 1,
          fontSize: 10,
        },
      },
      grid: {
        line: {
          style: {
            lineDash: null,
          },
        },
      },
    },
    yAxis: {
      line: null,
      tickLine: null,
      tickInterval: 1,
      grid: {
        line: {
          type: 'line',
          style: {
            lineDash: null,
          },
        },
        alternateColor: 'rgba(5, 35, 61, 0.1)',
      },
    },
    point: {
      size: 5,
      color: '#05233d',
    },
    area: {
      color: '#960096',
      style: {
        fillOpacity: 0.3,
        cursor: 'pointer',
        shadowColor: '#05233d',
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
    },
  };

  return (
    <>
      <Radar {...config} />
    </>
  );
}

export default GraphicResult;
