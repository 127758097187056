import styled from 'styled-components';
import { Collapse } from 'antd';

const Box = styled.div`
  padding: 14px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #05233d29;

  & .ant-collapse-header {
    cursor: default !important;
  }
`;

const TextBox = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;

  & > div {
    display: flex;
    gap: 12px;

    & > p:first-child {
      min-width: max-content;
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 400px) {
    & > div {
      gap: 0;
      flex-direction: column;
    }
  }
`;

const ButtonCollapse = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;

  & button {
    border: 0;
    width: 45px;
    height: 45px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
  }

  & button:hover {
    background-color: #f1f3f2;
  }
`;

const CollapseCustom = styled(Collapse)`
  & .ant-collapse-content-box {
    padding: 0;
  }
`;

const ButtonShowComments = styled.button`
  border: 0;
  cursor: pointer;
  height: 32px;
  border-radius: 5px;
  background-color: #fff;

  &:hover {
    color: var(--thirdy-color);
  }
`;

export { CollapseCustom, Box, TextBox, ButtonCollapse, ButtonShowComments };
