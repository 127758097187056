import { Col, Row } from 'antd';
import React from 'react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { BoxPreMoney } from '../../style';
import { Card } from '../styles';
import { maskMoney } from '../../../../helpers/mask.helper';
interface IParams {
  preMoneyPerpetuity?: number;
  preMoneyMultiplos?: number;
}
function DiscountedCashFlow(props: IParams) {
  return (
    <section>
      <Title size={25}>Métodos De Fluxo de Caixa Descontado</Title>
      <Text size={17} margin="0 0 30px 0">
        Os métodos DCF (Discounted Cash Flow) representam a abordagem mais
        renomada para avaliação de empresas, recomendada por acadêmicos e uma
        ferramenta diária para analistas financeiros. A avaliação é o valor
        presente de todos os fluxos de caixa livres para o patrimônio líquido
        startup vai gerar no futuro, descontado pelo seu risco. Esses métodos
        ponderam o fluxo de caixa livre projetado para o patrimônio líquido pela
        probabilidade de sobrevivência da startup. Em seguida, os fluxos são
        descontado para apresentar por uma taxa que representa riscos
        relacionados à indústria, tamanho, estágio de desenvolvimento e
        lucratividade. Por último, um desconto de iliquidez é aplicado à soma
        dos fluxos de caixa descontados para calcular a avaliação. O valor dos
        fluxos de caixa além dos projetados é representado pelo TV (Valor
        Terminal) e a forma como é calculado é o diferencia entre os dois
        métodos a seguir.
      </Text>

      <div style={{ margin: '0 auto' }}>
        <Title size={19} margin="0 0 5px 0">
          Perpetuidade
        </Title>
        <Row gutter={[12, 12]} style={{ marginBottom: '30px' }}>
          <Col xs={24} sm={24} md={16}>
            <Card height="100%">
              FCD com Perpetuidade assume que os fluxos de caixa além dos
              projetados crescerão para sempre a uma taxa constante com base na
              indústria e calcula a TV de acordo.
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <BoxPreMoney maxWidth="initial" boxShadow={'none'}>
              <Title size={20}>Valuation Pré-money</Title>
              <Title size={25} color="#960096">
                {maskMoney(props.preMoneyPerpetuity || '0')}
              </Title>
            </BoxPreMoney>
          </Col>
        </Row>

        <Title size={19} margin="0 0 5px 0">
          Múltiplos
        </Title>
        <Row gutter={[12, 12]} style={{ marginBottom: '30px' }}>
          <Col sm={24} xs={24} md={16}>
            <Card height="100%">
              O DCF com Múltiplo assume que o TV (Valor Terminal) é igual ao
              valor de saída da empresa calculado com um múltiplo de EBITDA
              baseado na indústria.
            </Card>
          </Col>
          <Col sm={24} xs={24} md={8}>
            <BoxPreMoney maxWidth="initial" boxShadow={'none'}>
              <Title size={20}>Valuation Pré-money</Title>
              <Title size={25} color="#960096">
                {maskMoney(props.preMoneyMultiplos || '0')}
              </Title>
            </BoxPreMoney>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default DiscountedCashFlow;
