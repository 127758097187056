import api from '../core/services/api';
import { IAddress, IClient, IProfile, IUser } from '../core/interfaces';
import CrudService from './CrudService';
import { IMember } from '../core/interfaces/IMember';
import { errorCallback } from '../helpers/promise.helper';
import IMemberAccelerator from '../core/interfaces/accelerator/IMemberAccelerator';
import IListAccelerator from '../core/interfaces/list/IListAccelerator';

export class ClientServices extends CrudService {
  constructor() {
    super({
      requestMapping: '/clients',
    });
  }

  public static init() {
    return new ClientServices();
  }

  async listClientsByUser(user: IUser | undefined) {
    return await api.get('clients/byUser', {
      params: { user: user?.entityId },
    });
  }

  public async getAllClients(): Promise<IClient[]> {
    return api
      .get<IClient[]>(`${this.requestMapping}/get-all`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createProfileToMembers(
    payload: IProfile,
    id: number,
  ): Promise<IProfile> {
    return api
      .post<any>(`${this.requestMapping}/save-profile/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllMembersByAccelerator(id: number): Promise<IMember[]> {
    return api
      .get<IMember[]>(`${this.requestMapping}/members/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createClient(payload: IClient): Promise<any> {
    return api
      .post<any>(`${this.requestMapping}/save`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createMember(
    id: number,
    payload: IMemberAccelerator,
  ): Promise<IMember> {
    return api
      .post<IMember>(`${this.requestMapping}/members/create/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAddressByUserId(id: number) {
    return api
      .get<IAddress>(`${this.requestMapping}/address/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async listAllAccelerators(): Promise<IListAccelerator[]> {
    return api
      .get<IListAccelerator[]>(`${this.requestMapping}/list-all-accelerators`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}
