import React from 'react';
import { Box } from './styles';

interface IParams {
  name?: string;
  methodology?: string;
  numberPhase?: number;
  phaseCurrent?: string;
}

function DiagnosisHeader(props: IParams) {
  return (
    <Box>
      <div>
        <strong>Aceleradora responsável:</strong>
        <p className="underline">{props.name}</p>
      </div>
      <div>
        <strong>Metodologia:</strong>
        <p>{props?.methodology}</p>
      </div>
      <div>
        <strong>Quantidade de Fases:</strong>
        <p>{props?.numberPhase} - Fases</p>
      </div>
      <div>
        <strong>Fase atual</strong>
        <p>Fase: {props?.phaseCurrent}</p>
      </div>
    </Box>
  );
}

export default DiagnosisHeader;
