import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxWhite } from '../../../GlobalStyles';
import InvestmentRoundInfo from '../../Infos/InvestmentRoundInfo';
import ContentBase from '../../templates/ContentBase';
import InvestmentFinished from './InvestmentFinished';
import InvestmentInProgress from './InvestmentInProgress';
import { Content } from './style';
import IInvestmentRound from '../../../core/interfaces/IInvestmentRound';
import InvestmentRoundService from '../../../services/InvestmentRoundService';
import { useAuth } from '../../../core/hooks/auth';
import { notification } from '../../../helpers/notification.helper';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';

function InvestmentRound() {
  const navigate = useNavigate();
  const [investmentRoundsIsActive, setInvestmentRoundsIsActive] = useState<
    IInvestmentRound[]
  >([]);
  const [investmentroundsNotActive, setInvestmentRoundsNotActive] = useState<
    IInvestmentRound[]
  >([]);
  const investmentRoundService = InvestmentRoundService.init();
  const { logged } = useAuth();
  const id = logged?.entityId;
  const { loading, showLoading, hideLoading } = useLoading();
  const getInvestmentRoundsIsActive = async () => {
    showLoading();
    investmentRoundService
      .getByUserInvestor(Number(id), true)
      .then((response) => {
        setInvestmentRoundsIsActive(response);
      })
      .catch(() => {
        notification.error('Erro ao buscar rodadas de investimento');
      })
      .finally(hideLoading);
  };

  const getInvestmentRoundsNotActive = async () => {
    showLoading();
    investmentRoundService
      .getByUserInvestor(Number(id), false)
      .then((response) => {
        setInvestmentRoundsNotActive(response);
      })
      .catch(() => {
        notification.error('Erro ao buscar rodadas de investimento');
      })
      .finally(hideLoading);
  };

  const handleCreate = () => {
    navigate('/investment-round/investment');
  };

  useEffect(() => {
    getInvestmentRoundsIsActive();
    getInvestmentRoundsNotActive();
  }, []);

  return (
    <Content>
      <Loading spinning={loading} height={200} width={200}>
        <ContentBase
          title="Rodada de investimento"
          modalContent={<InvestmentRoundInfo />}
          content={
            <>
              <Button
                className="circled-button"
                type="primary"
                title="Nova rodada de investimento"
                icon={<PlusOutlined />}
                onClick={handleCreate}
              />
              <BoxWhite>
                <InvestmentInProgress entity={investmentRoundsIsActive} />
              </BoxWhite>
              <BoxWhite>
                <InvestmentFinished entity={investmentroundsNotActive} />
              </BoxWhite>
            </>
          }
        />
      </Loading>
    </Content>
  );
}

export default InvestmentRound;
