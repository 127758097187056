import { Col, notification, Row, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IDiagnosticResult,
  IStartup,
  IValuationResult,
} from '../../../core/interfaces';
import DiagnosticResultService from '../../../services/DiagnosticResultService';
import ValuationResultService from '../../../services/ValuationResultService';
import StartupService from '../../../services/StartupService';
import DiagnosticResult from '../../diagnosis/startup/DiagnosticResult';
import ContentBase from '../../templates/ContentBase';
import InformationBox from './InformationBox';
import ObjectivesKeyResultsListing from './ObjectivesKeyResultsListing';
import StartupProgress from './StartupProgress/StartupProgress';
import { WrapperInformationBox } from './styles';
import { useModal } from 'sunflower-antd';
import StatusEnum from '../../../core/enums/StatusEnum';
import { maskMoney } from '../../../helpers/mask.helper';
import useStartupProgress from '../../../core/hooks/useStartupProgress';
import Loading from '../../../components/Loading/Loading';

function StartupDetails() {
  const navigate = useNavigate();
  const diagnosticResultModal = useModal({
    defaultVisible: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [startup, setStartup] = useState<IStartup | null>(null);
  const [diagnosticResult, setDiagnosticResult] = useState<IDiagnosticResult>();
  const [valuationResult, setValuationResult] = useState<IValuationResult>();
  //ultimo diagnostico respondido
  const [diagnosticLast, setDiagnosticLast] = useState<IDiagnosticResult>();

  const state = useLocation();
  const { id } = state.state;

  const startupService = useMemo(() => StartupService.init(), []);
  const diagnosticResultService = useMemo(
    () => DiagnosticResultService.init(),
    [],
  );
  const valuationResultService = useMemo(
    () => ValuationResultService.init(),
    [],
  );

  const { progress, loading: loadingProgress } = useStartupProgress(
    startup?.owner.entityId,
  );

  const getData = React.useCallback(() => {
    const requests = [
      startupService.getStartup(Number(id)),
      diagnosticResultService.getByStartupAndStatus(
        Number(id),
        StatusEnum.Open,
      ),
      diagnosticResultService.getLastDiagnostic(Number(id)),
      valuationResultService.getLastValuation(Number(id)),
    ];

    setLoading(true);
    Promise.all(requests)
      .then(([startup, diagnostic, diagnosticLast, valuationLast]) => {
        setStartup(startup);
        setDiagnosticResult(diagnostic);
        setDiagnosticLast(diagnosticLast);
        setValuationResult(valuationLast);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao obter informação startup',
          duration: 3.5,
        });
      })
      .finally(() => setLoading(false));
  }, [id, startupService, diagnosticResultService, valuationResultService]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getConfigMaturityStartup = () => {
    const phase = diagnosticResult?.diagnostic?.phase;

    return {
      title: 'Maturidade atual da Startup',
      text: phase?.description ?? 'Fase não identificado',
      value: phase?.name ?? 'descrição não identificado',
      actions: [
        {
          text: 'Ver diagnóstico atual',
          click: diagnosticResultModal.show,
        },
        {
          text: 'Ver lista de diagnósticos',
          click: () =>
            navigate('/dashboard/startup/diagnostics/', {
              state: {
                id,
                name: startup?.name,
              },
            }),
        },
      ],
    };
  };

  const speederHandler = () => {
    navigate(`/dashboard/startup/details/${startup?.owner.entityId}/journey`, {
      state: {
        startup: startup?.name,
      },
    });
  };

  return (
    <>
      <DiagnosticResult
        show={diagnosticResultModal.visible}
        close={diagnosticResultModal.close}
        startup={startup}
        diagnostic={diagnosticLast}
      />
      <ContentBase
        title={startup?.name}
        questionIsVisible={false}
        goBack={true}
        content={
          <Loading
            spinning={loading || loadingProgress}
            height={200}
            width={200}
          >
            <Row gutter={[16, 16]} align="stretch">
              <Col span={24}>
                <StartupProgress
                  phases={progress.phases}
                  speederHandler={speederHandler}
                />
              </Col>
              <Col span={24}>
                <WrapperInformationBox>
                  <InformationBox {...getConfigMaturityStartup()} />
                  <InformationBox
                    value={maskMoney(valuationResult?.preMoney || 0)}
                    title="ValuationForm Pré-money atual da Startup"
                    text="O Valuation Pré-Money é o indicador que informa quanto uma startup pode valer antes de começar
                                        a receber qualquer investimento, sem incluir investimento externo ou a última rodada de investimentos na avaliação."
                    actions={[
                      {
                        click: () =>
                          navigate('/valuations/results', {
                            state: {
                              id: valuationResult?.entityId,
                              startup: id,
                              name: startup?.name,
                            },
                          }),
                        text: 'Valuation atual',
                      },
                    ]}
                  />
                </WrapperInformationBox>
              </Col>
              <Col span={24}>
                <ObjectivesKeyResultsListing
                  id={id}
                  reload={getData}
                  objectivesKeyResults={startup?.metrics ?? []}
                />
              </Col>
            </Row>
          </Loading>
        }
      />
    </>
  );
}

export default StartupDetails;
