enum IPerformaceIndicatorStatus {
  Approvaed = 'APPROVED',
  Pending = 'PENDING',
  Disapproved = 'DISAPPROVED',
  Concluded = 'CONCLUDED',
}

const getStatusText = (status: IPerformaceIndicatorStatus): string => {
  const { Pending, Approvaed, Disapproved, Concluded } =
    IPerformaceIndicatorStatus;
  const texts: Record<IPerformaceIndicatorStatus, string> = {
    [Pending]: 'Pendente',
    [Approvaed]: 'Aprovado',
    [Disapproved]: 'Desaprovado',
    [Concluded]: 'Concluído',
  };

  return texts[status];
};

export { IPerformaceIndicatorStatus, getStatusText };
