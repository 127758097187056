import Title from '../../Title';
import { Box, ProgressBar } from './styles';

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  title: string;
  width?: number;
}

function CourseProgressWrapper({ title, width = 25, ...props }: Props) {
  return (
    <Box {...props}>
      <Title lineClamp={1} size={16}>
        {title}
      </Title>
      <ProgressBar width={width}>
        <div>{width.toFixed(1)}%</div>
      </ProgressBar>
    </Box>
  );
}

export default CourseProgressWrapper;
