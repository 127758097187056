import { ISave, IUpdate } from '../core/interfaces/crud/ICrudService';
import api from '../core/services/api';
import { errorCallback } from '../helpers/promise.helper';

class CrudService {
  protected params = {};
  protected requestMapping = '';

  constructor({ params = {}, requestMapping = '' }) {
    this.params = params;
    this.requestMapping = requestMapping;
  }

  public static pageableDefault() {
    return {
      totalPages: 0,
      content: [],
      empty: true,
      first: true,
      last: false,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
    };
  }

  public getPage() {
    return api
      .get(this.requestMapping, {
        params: this.params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public findAll() {
    return api
      .get(this.requestMapping)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public findPageable() {
    return api
      .get(`${this.requestMapping}/pageable`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public findById(id: number) {
    return api
      .get(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public update(id: number, data: IUpdate) {
    return api
      .put(`${this.requestMapping}/${id}`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public create(data: ISave) {
    return api
      .post(this.requestMapping, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number) {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public updateOrCreate(
    dataForm: ISave | IUpdate,
    entityId = 0,
  ): Promise<unknown> {
    if (entityId) {
      return this.update(entityId, dataForm);
    } else {
      return this.create(dataForm);
    }
  }
}

export default CrudService;
