import { Empty, Input } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { ActionWrapper, BodyItem, Header, Wrapper } from './styles';
import { VscSearch } from 'react-icons/vsc';
import Text from '../../Text';
import { IParticipant } from '../../../core/interfaces/chat/IChat';
import { Client } from 'webstomp-client';
import {
  IGetParticipants,
  IUpdateParticipant,
} from '../../../core/interfaces/chat/IChatHook';
import { debounce, isEmpty } from 'lodash';

type Props = {
  senderId: number;
  stomp?: Client | null;
  participants: IParticipant[];
  getParticipants: IGetParticipants;
  selected: IParticipant | null;
  changeParticipant(participant: IParticipant): void;
  updateParticipant: IUpdateParticipant;
};

function Participants({
  changeParticipant,
  stomp,
  participants,
  selected,
  senderId,
  getParticipants,
  updateParticipant,
}: Props) {
  useEffect(() => {
    if (stomp && senderId) {
      stomp.subscribe(`/topic/get-participants/${senderId}`, ({ body }) => {
        const payload: IParticipant = JSON.parse(body);
        updateParticipant(payload);
      });
    }
  }, [stomp, updateParticipant, senderId]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        getParticipants(senderId, { name: event.target.value.trim() });
      }, 350),
    [getParticipants, senderId],
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <Wrapper>
      <Header>
        <Input
          allowClear
          prefix={<VscSearch />}
          placeholder="Pesquisar"
          onChange={debouncedChangeHandler}
        />
      </Header>
      {isEmpty(participants) && (
        <Empty
          style={{ margin: '12px 0' }}
          description={
            <Text size={16} weight={600}>
              Não há participantes
            </Text>
          }
        />
      )}
      <ul>
        {participants.map((participant, index) => {
          const isActive =
            participant.conversationId === selected?.conversationId;
          return (
            <BodyItem
              className={isActive ? 'active' : ''}
              onClick={() => changeParticipant(participant)}
              key={`user_key_${index}`}
            >
              <div>
                <img
                  width={45}
                  height={45}
                  src={`https://www.gravatar.com/avatar/${participant.name}/?s=200&r=pg&d=retro`}
                  alt={participant.name}
                />
                <div>
                  <Text wordBreak="break-all" lineClamp={1}>
                    {participant.name}
                  </Text>
                  <Text
                    wordBreak="break-all"
                    lineClamp={1}
                    size={13}
                    color="#9AA4A8"
                  >
                    {participant.lastMessage}
                  </Text>
                </div>
              </div>
              <ActionWrapper>
                <Text size={12} color="#9AA4A8">
                  1h
                </Text>
              </ActionWrapper>
            </BodyItem>
          );
        })}
      </ul>
    </Wrapper>
  );
}

export default Participants;
