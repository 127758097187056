import React from 'react';
import { Spin, SpinProps } from 'antd';
import { Player } from '@lottiefiles/react-lottie-player';
import jsonAnimate from '../../assets/animation/loading.json';

interface IProps extends SpinProps {
  width?: number;
  height?: number;
  position?: 'absolute' | 'relative';
}

function Loading({ width, height, position = 'absolute', ...props }: IProps) {
  return (
    <Spin
      {...props}
      indicator={
        <Player
          autoplay
          loop
          src={jsonAnimate}
          style={{
            height: height || 100,
            width: width || 100,
            marginLeft: width ? -width / 2 : -50,
            position: position,
          }}
        />
      }
    ></Spin>
  );
}

export default Loading;
