import styled from 'styled-components';

const FormContent = styled.div`
  position: relative;

  & .addItem {
    position: absolute;
    left: 50%;

    :hover {
      background-color: var(--primary-color);

      svg {
        color: var(--white-color);
        transform: rotate(90deg);
        transition-duration: 500ms;
      }
    }
  }

  .ant-input-disabled {
    color: #000000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000000;
  }

  & .removeItem {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    cursor: pointer;

    :hover {
      svg {
        font-size: 18px;
        transition-duration: 500ms;
      }
    }
  }
`;

const BoxForm = styled.div`
  & .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: start;

    & .ant-form-item-tooltip {
      color: var(--secondary-color);
    }
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: var(--primary-color);
  }

  & .ant-form-item-control {
    & .ant-input {
      border-color: var(--primary-color);
    }

    & .ant-input:focus {
      border-color: #294c8d;
    }
  }

  & .ant-btn-dashed {
    margin-top: 18px;
    border-color: var(--primary-color);
  }

  & .ant-btn-dashed:hover {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }

  & .ant-btn-dashed:focus {
    color: var(--primary-color);
  }

  .ant-card {
    margin-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;

    & .ant-card-head {
      background-color: #0d4173;
      color: #ffffff;
      font-weight: bold;
    }
  }
`;

const ButtonDelete = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 auto;

  & .ant-btn-primary {
    padding: 0 40px;
    background-color: var(--thirdy-color);
    border: none;
    border-radius: 6px;

    & span {
      font-size: 0.8em;
      line-height: 25px !important;
    }

    &:hover,
    :focus {
      & span {
        color: var(--white-color);
      }
      background-color: var(--quaternary-color) !important;
    }
  }
`;

export { BoxForm, ButtonContainer, ButtonDelete, FormContent };
