import styled from 'styled-components';
import { Slider as SliderComponent, SliderSingleProps } from 'antd';

const Box = styled.div`
  display: grid;
  gap: 20px;
  align-items: flex-end;
  grid-template-columns: 220px 1fr 220px;
  margin-bottom: 16px;

  & p {
    margin: 0;
    text-align: center;
    line-height: 1.4;
    font-weight: 500;
    font-family: 'Segoe UI', sans-serif;
    color: var(--primary-color);
  }
`;

const Question = styled.p`
  font-weight: 700 !important;
  font-size: 21px;
  margin-bottom: 20px !important;
`;

const Slider = styled(SliderComponent)<SliderSingleProps>`
  .ant-slider-rail {
    height: 3px;
  }
  .ant-slider-dot {
    border-width: 0;
    background-color: transparent;
  }
`;

export { Box, Question, Slider };
