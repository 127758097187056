enum IWorkplaceEnum {
  Presence = 'PRESENCE',
  Hybrid = 'HYBRID',
  Remote = 'REMOTE',
}

const getDescriptionWorkplace = (value: IWorkplaceEnum) => {
  const texts = {
    [IWorkplaceEnum.Presence]: 'Presencial',
    [IWorkplaceEnum.Hybrid]: 'Híbrido',
    [IWorkplaceEnum.Remote]: 'Remoto',
  };

  return texts[value];
};

export { getDescriptionWorkplace, IWorkplaceEnum };
