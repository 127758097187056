import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, Input, message, Row } from 'antd';
import { ButtonDelete, Description, QuestionsContent } from './style';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import api from '../../../core/services/api';
import notificationMessage from '../../notifications/notificationMessage';
import errorNotification from '../../notifications/errorNotification';
import { Panel } from '../../../pages/client/Methodology/MethodologyFormView/styles';
import AlertMessage from '../../Alert/AlertMessage';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  idPillars: number;
  ask: boolean;
  setAsk: (ask: boolean) => void;
  setFormName: (formName: string) => void;
  setNext?: (next: string) => void;
  next?: string;
  initialData?: any;
  setData?: (data: any) => void;
  nameDiagnostic?: string;
  setNameStorage?: (name: string) => void;
}

interface IPillar {
  entityId: number;
}

interface IQuestion {
  entityId: number;
  preQuestion?: string;
  sufixQuestion?: string;
  question: string;
  weight?: 0;
  hint: string;
  pillar: IPillar;
  scales: IScale[];
}

interface IScale {
  orderScale: number;
  description: string;
}

interface IDiagnostic {
  entityId: number;
  questions?: IQuestion[];
}

function Questions(props: IParams) {
  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { loading, showLoading, hideLoading } = useLoading();

  const arrageValues = (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));

    const result: IDiagnostic = {
      entityId: props.initialData.entityId,
      questions: valuelMap.get('questionsList').map((question: any) => {
        return {
          question: question.question,
          pillar: {
            entityId: props.idPillars,
          },
          scales: question.scales.map((scale: any, i: number) => {
            return {
              orderScale: i,
              description: scale.scale,
            };
          }),
        };
      }),
    };
    return result;
  };

  const onFinish = (values: FormInstance) => {
    if (props.nameDiagnostic === undefined) {
      return message.error('O nome do diagnóstico não pode ser vazio');
    }
    showLoading();
    api
      .post('/diagnosis/createQuizToDiagnostic', arrageValues(values))
      .then((response) => {
        notificationMessage('create');
        props.setAsk(true);
        props.setData && props.setData(response.data);
        setIsOpenModal(true);
      })
      .catch((error) => {
        errorNotification(error);
      })
      .finally(hideLoading);
  };

  const onOk = () => {
    props.next &&
      props.setNext &&
      props.setNext((Number(props.next) + 1).toString());
    setIsOpenModal(false);
  };

  useEffect(() => {
    props.setFormName('questionsForm');
  }, []);

  useEffect(() => {
    const nameStorage = `form-question-methodology-${props.idPillars}`;
    setTimeout(() => {
      const data = sessionStorage.getItem(nameStorage);
      const dataForm = data
        ? JSON.parse(data)
        : Array(1)
            .fill(0)
            .map(() => ({
              question: '',
            }));
      form.setFieldValue('questionsList', dataForm);
    }, 150);

    return () => {
      sessionStorage.setItem(
        nameStorage,
        JSON.stringify(form.getFieldValue('questionsList')),
      );
      props.setNameStorage && props.setNameStorage(nameStorage);
    };
  }, [form, props.idPillars]);

  return (
    <Form
      name="questionsForm"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      initialValues={{
        questionsList: [''],
      }}
    >
      <Loading
        spinning={loading}
        width={300}
        height={300}
        position={'absolute'}
      >
        <Description>
          Cadastre as perguntas que a startup deverá responder conforme os
          pilares de sua metodologia.
        </Description>
        <QuestionsContent>
          <Form.List name="questionsList">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={'question-' + key}>
                    {fields.length > 1 ? (
                      <ButtonDelete>
                        <DeleteOutlined
                          className="removeItem"
                          onClick={() => remove(name)}
                        />
                      </ButtonDelete>
                    ) : null}
                    <Row gutter={[4, 4]}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          label={'Pergunta ' + (name + 1)}
                          name={[index, 'question']}
                          rules={[
                            {
                              required: true,
                              message: 'Campo obrigatório!',
                            },
                            {
                              max: 1000,
                              message: 'tamanho deve ser entre 1 é 1000',
                            },
                          ]}
                        >
                          <Input placeholder="Digite a pergunta" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Collapse
                      bordered={false}
                      defaultActiveKey={['0']}
                      expandIconPosition="right"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 180 : 90} />
                      )}
                    >
                      <Panel
                        className="parent-item"
                        header={'Descrição da escala (0 a 10)'}
                        key={`${index}`}
                      >
                        <Form.List name={[index, 'scales']} initialValue={['']}>
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              <Row gutter={[4, 4]}>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 0'}
                                    name={[0, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 1'}
                                    name={[1, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 2'}
                                    name={[2, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 3'}
                                    name={[3, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={[4, 4]}>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 4'}
                                    name={[4, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 5'}
                                    name={[5, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 6'}
                                    name={[6, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 7'}
                                    name={[7, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={[4, 4]}>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 8'}
                                    name={[8, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 9'}
                                    name={[9, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    label={'Escala 10'}
                                    name={[10, 'scale']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Campo obrigatório!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Digite uma descrição"
                                      maxLength={255}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </Panel>
                    </Collapse>
                  </div>
                ))}
                <div className="item">
                  <Form.Item>
                    <Button
                      size="small"
                      shape="circle"
                      type="dashed"
                      className="addItem"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
        </QuestionsContent>

        <AlertMessage
          visible={isOpenModal}
          message="Pergunta cadastrada com sucesso!"
          description="A pergunta foi cadastrada com sucesso."
          textCancelButton="Fechar"
          textOkButton="Prosseguir"
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Loading>
    </Form>
  );
}

export default Questions;
