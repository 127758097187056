import React from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import ToolsInfo from '../../Infos/ToolsInfo';
import ContentBase from '../../templates/ContentBase';
import { BoxContainer, BoxWhite, PurpleButton } from '../../../GlobalStyles';
import { useNavigate } from 'react-router-dom';

function Tools() {
  const navigate = useNavigate();

  const lastUpdate = '03/02/2023';

  return (
    <ContentBase
      title="Ferramentas da plataforma"
      modalContent={<ToolsInfo />}
      content={
        <>
          <BoxWhite>
            <Title size={16}>Valuation</Title>
            <BoxContainer noPadding={true} flexDirection={'row'}>
              <Text style={{ width: '80%' }} margin={'10px 0 0 0'}>
                Informações com o WACC e Múltiplos são fundamentais para o
                cálculo de valuations de todas as aceleradoras da plataforma.
                Não esqueça de sempre manter esses dados atualizados.
              </Text>
              <PurpleButton
                type={'primary'}
                onClick={() => navigate('/tools/valuation')}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  padding: '0 50px',
                }}
              >
                Atualizar dados
              </PurpleButton>
            </BoxContainer>
            <Text weight={700} margin={'10px 0 0 0'}>
              <BoxContainer noPadding={true} flexDirection={'row'}>
                <Text weight={700}>Última atualização:</Text>
                <Text margin={'0 0 0 5px'} color={'var(--thirdy-color)'}>
                  lastUpdate
                </Text>
              </BoxContainer>
            </Text>
          </BoxWhite>
          <BoxWhite>
            <Title size={16}>Cursos</Title>
            <BoxContainer noPadding={true} flexDirection={'row'}>
              <Text style={{ width: '80%' }} margin={'10px 0 0 0'}>
                Configure as categorias dos cursos para que as aceleradoras da
                plataforma, ao cadastrarem seus materiais, possam categoriza-los
                de maneira que possam ser exibidos e organizados mais claramente
                para seus clientes.
              </Text>
              <PurpleButton
                type={'primary'}
                onClick={() => navigate('/tools/courses')}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  padding: '0 50px',
                }}
              >
                Atualizar dados
              </PurpleButton>
            </BoxContainer>
            <Text weight={700} margin={'10px 0 0 0'}>
              <BoxContainer noPadding={true} flexDirection={'row'}>
                <Text weight={700}>Última atualização:</Text>
                <Text margin={'0 0 0 5px'} color={'var(--thirdy-color)'}>
                  lastUpdate
                </Text>
              </BoxContainer>
            </Text>
          </BoxWhite>
        </>
      }
    />
  );
}

export default Tools;
