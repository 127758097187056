import React, { useMemo } from 'react';
import { BoxWhite } from '../../GlobalStyles';
import useLoading from '../../core/hooks/useLoading';
import notificationMessage from '../../components/notifications/notificationMessage';
import errorNotification from '../../components/notifications/errorNotification';
import { ClientServices } from '../../services/ClientServices';

import EntityList from '../../components/EntityList/EntityList';

function MentorList() {
  const { loading, showLoading, hideLoading } = useLoading();

  const speederService = useMemo(() => ClientServices.init(), []);

  const disableMentor = (entityId: number) => {
    showLoading();
    speederService
      .update(entityId, { staus: false })
      .then(() => {
        notificationMessage('status');
      })
      .catch(() =>
        errorNotification({
          defaultMessage: 'erro ao desativar essa conta',
        }),
      )
      .finally(hideLoading);
  };

  const MOCKLIST = [
    {
      name: 'mentor 1',
      date: '2023/01/01',
      lastUpdate: '2023/01/01',
      status: 'A',
      url: 'https://i.ibb.co/D4K7ypM/dribbble-image-14.png',
      email: 'mentor01@gmail.com',
    },
    {
      name: 'mentor 2',
      date: '2023/01/02',
      lastUpdate: '2023/01/02',
      status: 'A',
      url: '',
      email: 'mentor02@gmail.com',
    },
    {
      name: 'mentor 3',
      date: '2023/01/02',
      lastUpdate: '2023/01/02',
      status: 'A',
      url: 'https://i.ibb.co/D4K7ypM/dribbble-image-14.png',
      email: 'mentor03@gmail.com',
    },
    {
      name: 'mentor 4',
      date: '2023/01/02',
      lastUpdate: '2023/01/02',
      status: 'A',
      url: 'https://i.ibb.co/D4K7ypM/dribbble-image-14.png',
      email: 'mentor04@gmail.com',
    },
    {
      name: 'mentor 5',
      date: '2023/01/02',
      lastUpdate: '2023/01/02',
      status: 'D',
      url: 'https://i.ibb.co/D4K7ypM/dribbble-image-14.png',
      email: 'mentor05@gmail.com',
    },
  ];

  return (
    <BoxWhite>
      <EntityList
        title="Mentores"
        entity={MOCKLIST}
        disable={disableMentor}
        profilePath="mentor/profile/"
      />
    </BoxWhite>
  );
}

export default MentorList;
