import React, { useEffect } from 'react';
import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  notification,
  Row,
  Select,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { FormInstance } from 'rc-field-form';
import { useNavigate } from 'react-router-dom';
import api from '../../../../../core/services/api';
import ContentForm from '../ContentForm';
import { useAuth } from '../../../../../core/hooks/auth';
import { convertStringToDate } from '../../../../../helpers/dates.helper';
import notificationMessage from '../../../../../components/notifications/notificationMessage';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  setFormName: (value: string) => void;
}

function ScreenStudent(props: IParams) {
  const [form] = Form.useForm();
  const [birthDay, setBirthDate] = React.useState<any>();

  const { logged } = useAuth();
  const id = Number(logged?.entityId);

  const navigate = useNavigate();

  const { Option } = Select;

  const genderOptions = [
    {
      id: 'masculino',
      name: 'Masculino',
    },
    {
      id: 'feminino',
      name: 'Feminino',
    },
    {
      id: 'binario',
      name: 'Binário',
    },
    {
      id: 'naobinario',
      name: 'Não binário',
    },
    {
      id: 'naoinformado',
      name: 'Prefiro não informar',
    },
  ];

  const arrageValue = (value: FormInstance) => {
    const valueMap = new Map(Object.entries(value));
    const name = valueMap.get('name');
    const cpf = valueMap.get('cpf');
    const birthDate = convertStringToDate(valueMap.get('birthDate'));
    const cellPhone = valueMap.get('cellPhone');
    const genre = valueMap.get('gender');
    const email = logged?.email;
    const profileId = 6;

    return {
      name,
      cpf,
      birthDate,
      cellPhone,
      genre,
      email,
      profileId,
    };
  };

  const onFinish = (values: FormInstance) => {
    api
      .put(`/user/student/${id}`, arrageValue(values))
      .then((response) => {
        props.setCurrent(props.current + 1);
        notificationMessage('create');
        localStorage.setItem('@innovation:auth', response.data.jwttoken);
        navigate('/register/conclude/profile/final');
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao cadastrar',
          description:
            error.response.data.message ||
            'Houve um erro ao cadastrar o usuário',
        });
      });
  };

  const setInitialValues = () => {
    form.setFieldsValue({
      name: logged?.name,
    });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setBirthDate(dateString);
  };

  useEffect(() => {
    props.setFormName('formStudent');
    setInitialValues();
  }, []);

  return (
    <ContentForm
      title="Aluno"
      subTitle="Precisamos saber algumas informações a respeito de você"
      description="Dados pessoais"
      content={
        <Form
          name={'formStudent'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={'name'}
                label="Qual seu nome?"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Input placeholder="Nome completo" maxLength={255} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="CPF"
                name={'cpf'}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  ({ getFieldValue }) => ({
                    validator(_) {
                      if (getFieldValue('cpf').includes('_')) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'CPF inválido',
                  }),
                ]}
              >
                <MaskedInput mask="000.000.000-00" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Telefone"
                name="cellPhone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_) {
                      if (getFieldValue('cellPhone').includes('_')) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'Telefone inválido',
                  }),
                ]}
              >
                <MaskedInput
                  placeholder="Digite seu número"
                  mask="(00) 00000-0000"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Form.Item
                label="Qual seu gênero?"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select placeholder="Selecione seu gênero">
                  {genderOptions.map((item, key) => {
                    return (
                      <Option value={item.id} key={key}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Form.Item
                label="Quando você nasceu?"
                name="birthDate"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  locale={locale}
                  format={'DD/MM/YYYY'}
                  placeholder="Seleciona a data de nascimento"
                  onChange={onChange}
                  value={form.getFieldValue('birthdate')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
}

export default ScreenStudent;
