import Status from '../../components/Status/Status';

enum StatusValuationEnum {
  BLOCKED = 'Bloqueado',
  ACTIVE = 'Ativo',
  PENDING = 'Pendente',
  DISABLED = 'Desativado',
  OPEN = 'Aberto',
}

const findSatusValuation = (status: string) => {
  const { BLOCKED, ACTIVE, PENDING, DISABLED, OPEN } = StatusValuationEnum;
  switch (status) {
    case 'BLOCKED':
      return (
        <Status
          status={BLOCKED}
          color="red"
          info={
            'Esse valuation está bloqueado, pois já foi ativo e agora foi substituído, porém ainda possuem startups utilizando-o'
          }
        />
      );
    case 'ACTIVE':
      return (
        <Status
          status={ACTIVE}
          color="green"
          info={
            'Esse valuation está ativo, pois possui todas as informações necessárias para ser utilizado'
          }
        />
      );
    case 'PENDING':
      return (
        <Status
          status={PENDING}
          color="orange"
          info={
            'Esse valuation está pendente, pois não possui todas as informações necessárias para ser utilizado'
          }
        />
      );
    case 'DISABLED':
      return (
        <Status
          status={DISABLED}
          color="red"
          info={
            'Esse valuation está desativado, pois foi criado um novo item para este dado.'
          }
        />
      );
    case 'OPEN':
      return (
        <Status
          status={OPEN}
          color="blue"
          info={
            'Esse valuation está aberto, pois foi criado mas ainda não foi finalizado'
          }
        />
      );
  }
};

const getDescriptions = (status: StatusValuationEnum) => {
  const { BLOCKED, ACTIVE, PENDING, DISABLED, OPEN } = StatusValuationEnum;
  const description = {
    [BLOCKED]: (
      <Status
        status={BLOCKED}
        color="red"
        info={
          'Esse valuation está bloqueado, pois já foi ativo e agora foi substituído, porém ainda possuem startups utilizando-o'
        }
      />
    ),
    [ACTIVE]: (
      <Status
        status={ACTIVE}
        color="green"
        info={
          'Esse valuation está ativo, pois possui todas as informações necessárias para ser utilizado'
        }
      />
    ),
    [PENDING]: (
      <Status
        status={PENDING}
        color="orange"
        info={
          'Esse valuation está pendente, pois não possui todas as informações necessárias para ser utilizado'
        }
      />
    ),
    [DISABLED]: (
      <Status
        status={DISABLED}
        color="red"
        info={
          'Esse valuation está desativado, pois foi criado um novo item para este dado.'
        }
      />
    ),
    [OPEN]: (
      <Status
        status={OPEN}
        color="blue"
        info={
          'Esse valuation está aberto, pois possivelmente este item está incompleto e não foi finalizado'
        }
      />
    ),
  };

  return description[status];
};

export { StatusValuationEnum, getDescriptions, findSatusValuation };
