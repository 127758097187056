import { IPerformaceIndicatorStatus } from '../core/enums/IKeyPerformaceIndicators';
import IMetric, { IKpi } from '../core/interfaces/IMetric';
import api from '../core/services/api';
import CrudService from './CrudService';

class MetricCrudService extends CrudService {
  static init(): MetricCrudService {
    return new MetricCrudService();
  }

  constructor() {
    super({
      requestMapping: '/metrics',
      params: {
        sort: 'creation,desc',
      },
    });
  }

  public findyByStartup(userId: number): Promise<IMetric[]> {
    return api
      .get<IMetric[]>(`${this.requestMapping}/findy-by-user`, {
        params: { user: userId },
      })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public attach(files: File[], performaceIndicatorId: number) {
    const form = new FormData();
    for (const file of files) {
      form.append('files', file);
    }

    return api({
      url: `${this.requestMapping}/upload/${performaceIndicatorId}`,
      data: form,
      method: 'POST',
    })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public removeAttach(fileId: number) {
    return api
      .delete(`${this.requestMapping}/remove-upload/${fileId}`)
      .then((response) => response.data);
  }

  public updateStatusKpi(
    kpiId: number,
    status: IPerformaceIndicatorStatus,
  ): Promise<IKpi> {
    const path = `${this.requestMapping}/update-status-kpi/${kpiId}`;
    return api
      .put<IKpi>(path, null, {
        params: { status },
      })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public addKpi(data: IKpi): Promise<IKpi> {
    return api
      .post<IKpi>(`${this.requestMapping}/add-kpi`, data)
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default MetricCrudService;
