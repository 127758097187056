import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
} from 'antd';
import { ButtonDelete } from '../style';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { MethodologyFormContent, TextDescription } from './style';
import { useAuth } from '../../../core/hooks/auth';
import api from '../../../core/services/api';
import notificationMessage from '../../notifications/notificationMessage';
import ErrorFormNotification from '../../notifications/errorFormNotification';
import AlertMessage from '../../Alert/AlertMessage';
import { useForm } from 'antd/lib/form/Form';
import { isEmpty } from 'lodash';
import IPillars from '../../../core/interfaces/models/IPillars';
import StatusEnum from '../../../core/enums/StatusEnum';
import IMethodology from '../../../core/interfaces/models/IMethodology';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;
  initialValues: Record<string, unknown>;
  params?: IMethodology;
  setParamsResponse: (methodology: IMethodology) => void;

  getNameMethodology(): unknown;
}

function PillarsForm(props: IParams) {
  const [form] = useForm();
  const { logged } = useAuth();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const id = logged?.client;
  const { loading, showLoading, hideLoading } = useLoading();

  const count = (item: any) => Object.keys(item).length;

  const nameIsInValid = () => {
    const initialValues = props.initialValues;
    const params = props.params;

    const dataForm = form.getFieldValue('methodologyPillarsList');
    const countForm = count(dataForm);

    const countMethodology = count(initialValues?.pillars ?? {});
    const isChangePhases = countForm > countMethodology;

    const nameMethodology = props.getNameMethodology();
    const isNameChange = params
      ? params?.name !== nameMethodology
      : initialValues?.name !== nameMethodology;

    return isChangePhases && !isNameChange;
  };

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));
    const initialValues = props.initialValues;

    const result = {
      name: props.getNameMethodology(),
      entityId: props?.params?.entityId || initialValues?.entityId,
      phases: props.params?.phases || initialValues?.phases || [],
      status: initialValues?.status ?? StatusEnum.Pending,
      active: true,
      client: {
        entityId: id,
      },
      pillars: valueMap
        .get('methodologyPillarsList')
        .map((value: Record<string, string>, index: number) => {
          const pillars = initialValues?.pillars as IPillars[] | undefined;
          const item = pillars?.at(index);

          return {
            ...value,
            ...(item && { entityId: item.entityId }),
            active: true,
          };
        }),
    };

    return result;
  };

  const onOk = () => {
    props.setNext('2');
    setIsOpenModal(false);
  };

  const register = (values: FormInstance) => {
    const isEditing = Boolean(props.initialValues?.entityId);
    if (isEditing && nameIsInValid()) {
      notification.info({
        message: 'Altere o nome da métodologia',
      });

      return;
    }

    showLoading();
    api({
      method: isEditing ? 'PUT' : 'POST',
      url: `methodology/${isEditing ? 'update' : 'create'}`,
      data: arrageValues(values),
    })
      .then((response) => {
        notificationMessage('create');
        setIsOpenModal(true);
        props.setParamsResponse(response.data);
      })
      .catch((error) => {
        ErrorFormNotification(form, error);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    const values = props.initialValues;
    if (!isEmpty(values)) {
      setTimeout(() => {
        const pillars = (values.pillars as IPillars[]).map(
          (pillar: IPillars) => ({
            name: pillar.name,
            description: pillar.description,
          }),
        );
        form.setFieldValue('methodologyPillarsList', pillars);
      }, 175);
    }
  }, [form, props.initialValues]);

  useEffect(() => {
    setTimeout(() => {
      const data = sessionStorage.getItem('form-pillars-methodology');
      const dataForm = data
        ? JSON.parse(data)
        : Array(1)
            .fill(0)
            .map(() => ({
              phase: '',
            }));
      form.setFieldValue('methodologyPillarsList', dataForm);
    }, 150);

    return () => {
      sessionStorage.setItem(
        'form-pillars-methodology',
        JSON.stringify(form.getFieldValue('methodologyPillarsList')),
      );
    };
  }, [form]);

  useEffect(() => {
    props.setFormName('methodologyPillarsForm');
  }, []);
  return (
    <Loading spinning={loading} width={200} height={200}>
      <Form
        name="methodologyPillarsForm"
        layout="vertical"
        onFinish={register}
        autoComplete="off"
        form={form}
      >
        <TextDescription>
          <p>
            Os pilares são áreas de conhecimento que estão presentes em todas as
            fases.
          </p>
        </TextDescription>
        <Form.List name="methodologyPillarsList">
          {(fields, { add, remove }) => (
            <MethodologyFormContent>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  {fields.length > 1 && (
                    <ButtonDelete>
                      <DeleteOutlined
                        className="removeItem"
                        onClick={() => remove(name)}
                      />
                    </ButtonDelete>
                  )}
                  <Row key={key} gutter={12}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label={'Pilar ' + (name + 1)}
                        name={[name, 'name']}
                        rules={[
                          {
                            required: false,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Digite o nome do pilar"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        {...restField}
                        label={'Descrição (opcional) '}
                        name={[name, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Digite uma descrição"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                  </Row>
                </>
              ))}
              <div className="item">
                <Form.Item>
                  <Button
                    size="small"
                    shape="circle"
                    type="dashed"
                    className="addItem"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </div>
            </MethodologyFormContent>
          )}
        </Form.List>

        {/* Modal */}
        <AlertMessage
          visible={isOpenModal}
          message="Metodologia salva!"
          description="Muito bem, o proximo passo você terá que cadastrar seus conteúdos"
          textCancelButton="Fechar"
          textOkButton="Continuar"
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Form>
    </Loading>
  );
}

export default PillarsForm;
