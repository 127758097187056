import styled from 'styled-components';

const IncomingCall = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  height: 80px;
  padding: 10px;
  color: var(--white-color);
  background-color: var(--thirdy-color);
  border-radius: 6px;

  & .mentorship {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .name {
      font-weight: bold;
      font-size: 1.2rem;
    }

    .mentor {
      font-size: 0.8rem;
    }
  }

  & .time {
    font-weight: bold;
    font-size: 2.2rem;
  }

  svg {
    font-size: 48px;
  }

  & .ant-btn {
    padding: 0 30px;
    color: var(--white-color);
    background-color: var(--thirdy-color);
    border: 1px solid var(--white-color);

    span {
      font-weight: bold;
    }

    :hover {
      color: var(--thirdy-color);
      background-color: var(--white-color);
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoxMentorShip = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  gap: 10px;
  padding-bottom: 10px;
  overflow-x: auto;
`;

const MentorshipCard = styled.div<{
  align?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align ? align : 'center')};
  width: ${({ width }) => (width ? width : '240px')};
  height: ${({ height }) => (height ? height : '280px')};
  position: relative;
  min-width: 240px;
  padding: 10px 10px;
  border: 1px solid var(--support-color);
  border-radius: 6px;

  svg {
    font-size: 56px;
  }

  span {
    margin-bottom: 5px;
  }

  & .status,
  .name,
  .time {
    font-weight: bold;
  }

  & .time {
    font-size: 1.4rem;
  }

  & .name,
  .mentor,
  .time {
    color: var(--thirdy-color);
  }

  & .name,
  .mentor {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .column {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 36px;
    height: 36px;
    top: 10px;
    right: 10px;
    cursor: pointer;

    svg {
      font-size: 24px;
    }

    :hover {
      border-radius: 50%;
      background-color: var(--grey-color);
    }
  }

  & .price {
    margin-top: 10px;
    font-weight: bold;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 15px 0;
  gap: 20px;
  flex-wrap: wrap;

  span {
    color: var(--support-color);
    cursor: pointer;
  }

  span:hover {
    color: var(--primary-color);
  }

  .selected {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: default;
  }
`;

const MentorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 240px;
  min-height: 240px;
  padding: 10px;
  border: 1px solid var(--support-color);
  border-radius: 6px 6px 0 0;
  position: relative;

  span,
  .image {
    margin-bottom: 5px;
  }

  & .image {
    display: flex;
    align-items: center;

    img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }

  & .startup {
    display: flex;
    flex-direction: row;
    margin: 10px 0;

    .innovation {
      margin-left: 5px;
      font-weight: bold;
      color: var(--primary-color);
    }
  }

  & .name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--thirdy-color);
  }
`;

const Specialties = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  & .item {
    display: -webkit-box;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-size: 0.8rem;
    padding: 2px 10px;
    border: 1px solid var(--support-color);
    border-radius: 20px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
  border-radius: 0 0 6px 6px;
`;

const Summary = styled.div`
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export {
  IncomingCall,
  Box,
  Filters,
  BoxMentorShip,
  MentorshipCard,
  MentorCard,
  Specialties,
  Summary,
  BoxBottom,
};
