import CrudService from '../CrudService';

class StudentAnswerService extends CrudService {
  constructor() {
    super({
      requestMapping: '/student-response',
    });
  }

  public static init() {
    return new StudentAnswerService();
  }
}

export default StudentAnswerService;
