import styled from 'styled-components';

const EmptyWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export { EmptyWrapper };
