import { IStartChartRequest } from '../../core/interfaces/chat/IChartRequest';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class ChatService {
  private baseRequest = '/chat';

  public static init() {
    return new ChatService();
  }

  public deleteConversation(id: number, senderId: number) {
    return api
      .delete(`${this.baseRequest}/delete-conversation/${id}`, {
        params: { senderId },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public start(request: IStartChartRequest) {
    return api
      .post(this.baseRequest, request)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ChatService;
