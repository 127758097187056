import { isEmpty } from 'lodash';
import { IPerformaceIndicatorStatus } from '../core/enums/IKeyPerformaceIndicators';
import { IKpi } from '../core/interfaces/IMetric';

class MetricService {
  static init(): MetricService {
    return new MetricService();
  }

  public getStatusMetric(kpis: IKpi[]) {
    const status = kpis.map(
      (performaceIndicator) => performaceIndicator.status,
    );

    const { Pending, Approvaed, Disapproved, Concluded } =
      IPerformaceIndicatorStatus;
    if (status.includes(Pending)) {
      return Pending;
    }

    if (status.includes(Disapproved)) {
      return Disapproved;
    }

    if (status.every((item) => item.includes(Concluded))) {
      return Concluded;
    }

    return Approvaed;
  }

  public async validateAttachment(
    files: FileList | null,
    attachments: Array<{ name: string }>,
  ) {
    if (!files) return Promise.reject([]);
    const firstFile = files[0];

    const validations = [
      {
        message: 'Só é possível anexar PDF',
        isInvalid: () => !firstFile.type.includes('pdf'),
      },
      {
        message: 'Arquivo já anexado',
        isInvalid: () =>
          attachments.some((item) => item.name === firstFile?.name),
      },
    ];

    const errors: string[] = [];
    validations.forEach((validation) => {
      if (validation.isInvalid()) {
        errors.push(validation.message);
      }
    });

    return isEmpty(errors)
      ? Promise.resolve(firstFile)
      : Promise.reject(errors);
  }

  public getColorStatus(status: IPerformaceIndicatorStatus): string {
    const { Approvaed, Disapproved, Pending, Concluded } =
      IPerformaceIndicatorStatus;
    const colors: Record<string, string> = {
      [Approvaed]: '#31FF2A',
      [Disapproved]: '#EC1616',
      [Pending]: '#05233D',
      [Concluded]: '#960096',
    };

    return colors[status] ?? colors.Pending;
  }

  public getStatusDescription(status: string, isStartup = false): string {
    const descriptions: Record<string, string> = isStartup
      ? this.getStartupDescriptions()
      : this.getClientDescriptions();
    return descriptions[status] ?? descriptions.default;
  }

  public getStartupDescriptions() {
    const { Approvaed, Disapproved, Pending, Concluded } =
      IPerformaceIndicatorStatus;
    return {
      [Approvaed]: 'A aceleradora responsável aprovou!',
      [Pending]: 'A aceleradora responsável está analisando sua solicitação',
      default: 'Status não identificado',
      [Disapproved]: 'A aceleradora considerou algo errado na sua solicitação',
      [Concluded]:
        'Você conseguiu provar, por meio das evidências, ter concluído esse objetivo',
    };
  }

  public getClientDescriptions() {
    const { Approvaed, Disapproved, Pending, Concluded } =
      IPerformaceIndicatorStatus;
    return {
      [Approvaed]:
        'Essa startup conseguiu provar, por meio das evidências, ter concluído esse objetivo.',
      [Pending]:
        'Você precisa verificar os KPIs da sua startup para que seu OKR possa ser aprovado ou não',
      default: 'Status não identificado',
      [Disapproved]: 'Há algum KPI que não foi concluído',
      [Concluded]: 'Concluído',
    };
  }
}

export default MetricService;
