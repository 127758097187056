import { useState } from 'react';
import { ButtonContainer, FormContainer } from '../style';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import BaseScreen from '..';
import api from '../../../core/services/api';
import ErrorFormNotification from '../../../components/notifications/errorFormNotification';
import { notification } from '../../../helpers/notification.helper';
import Text from '../../../components/Text';
import { isValidPassword } from '../../../helpers/password.helper';

function RenewPassword() {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: FormInstance) => {
    setConfirmLoading(true);
    await api
      .post('/change-password', values)
      .then(() => {
        notification.success('Senha alterada com sucesso!');
        navigate('/');
      })
      .catch((error) => {
        ErrorFormNotification(form, error.response.data);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  return (
    <BaseScreen
      title="Definir nova senha"
      content={
        <FormContainer>
          <Form name="loginForm" layout="vertical" onFinish={onFinish}>
            <Row gutter={8}>
              <Col span={24}>
                <Text size={13} margin="0 0 12px 0" weight={500}>
                  * Sua senha deve conter número, letra maiúscula, letra
                  minúscula e caractere especial. Tamanho de 8 a 12 caracteres.
                </Text>
              </Col>
            </Row>

            <Form.Item
              label="Nova senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
                {
                  min: 8,
                  message: 'Tamanho mínimo são 8 caracteres.',
                },
                {
                  max: 12,
                  message: 'Tamanho máximo é 12 caracteres.',
                },
                {
                  validator: (_, value: string) => {
                    if (value.trim().length < 8 || value.trim().length > 12) {
                      return Promise.resolve();
                    }

                    if (!isValidPassword(value)) {
                      return Promise.reject(
                        'Sua senha deve conter número, letra maiúscula, letra minúscula e caractere especial',
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password placeholder="Digite sua senha" maxLength={12} />
            </Form.Item>
            <Form.Item
              label="Confirme a nova senha"
              name="passwordConfirm"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'Essas senhas não coincidem. Tentar novamente.',
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirme sua senha" maxLength={12} />
            </Form.Item>
            <ButtonContainer>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                Salvar
              </Button>
            </ButtonContainer>
          </Form>
        </FormContainer>
      }
    />
  );
}

export default RenewPassword;
