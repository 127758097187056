import React from 'react';
import { QRCode, IProps } from 'react-qrcode-logo';
import logo from '../../assets/images/logo-white-rocket.png';

type IPrintableQRCode = IProps;

function QrCode(props: IPrintableQRCode) {
  return (
    <>
      <QRCode {...props} logoImage={logo} logoHeight={150} logoWidth={150} />
    </>
  );
}

export default QrCode;
