import React from 'react';
import DocumentIcon from '../../../src/assets/icons/smile.png';
import { Button, Card, Col, Row } from 'antd';
import Text from '../../../src/components/Text';
import Box from './styles';

interface IProps {
  message?: string;
  title?: string;
  description?: string;
  textButton?: string;
  onClickButton?: () => void;
  isButton?: boolean;
}

function ScreenInformationVoid(props: IProps) {
  return (
    <>
      <Card bodyStyle={{ padding: '8px 12px' }}>
        <Row align="middle" gutter={16}>
          <Col span={2}>
            <img
              height={78}
              width={78}
              src={DocumentIcon}
              alt="icone documento"
            />
          </Col>
          <Col span={4}>
            <Text size={18} color="#05233D" weight={700}>
              {props.message}
            </Text>
          </Col>
        </Row>
      </Card>
      <Box>
        <Text size={28} color="#05233D" weight={700}>
          {props.title}
        </Text>
        <Text size={16} color="#05233D" weight={400}>
          {props.description}
        </Text>
        {props.isButton && (
          <Button onClick={props.onClickButton} type={'primary'}>
            {props.textButton}
          </Button>
        )}
      </Box>
    </>
  );
}

export default ScreenInformationVoid;
