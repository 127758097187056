import React from 'react';
import Title from '../../components/Title';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function ValuationToolsInfo() {
  return (
    <BoxInfo
      title="Configuração do valuation"
      image={image}
      description={
        <>
          <Title size={16}>O que é WACC?</Title>
          <p>BLA BLA BLA</p>
          <Title size={16}>Para que serve os estágios das empresas</Title>
          <p>BLA BLA BLA</p>
          <Title size={16}>Para que serve os múltiplos?</Title>
          <p>
            Avaliação da empresa por meio de análise comparativa com o
            desempenho econômico-financeiro do mercado. Logo, a ideia é agrupar
            empresas em termos de risco, crescimento e potencial de geração de
            resultados semelhantes aos da empresa em análise. Para preenchimento
            dos múltiplos será opcional o upload do arquivo com dados do Setor
            de Mercado, como: Tipo de setor; Beta levered, D/E Ratio (%), Beta
            Unlevered e EV/EBITDA. Esses dados devem ser atualizados anualmente
            ou conforme necessidade.
          </p>
        </>
      }
    />
  );
}

export default ValuationToolsInfo;
