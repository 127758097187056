import styled from 'styled-components';

const Header = styled.header`
  background-color: #051c34;
  padding: 1.8rem 0.5rem;
  box-shadow: inset 0 -3rem #fff;
`;

const WrapperBody = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 18.75rem;
  justify-content: flex-start;
`;

const SelectedCourseWrapper = styled.div`
  position: relative;

  & .ant-checkbox-wrapper {
    position: absolute;
    z-index: 5;
    right: 1.8rem;
    top: 1rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #960096;
    border-color: #960096;
  }
`;

// const FooterWrapper = styled.footer``;

export { WrapperBody, Header, SelectedCourseWrapper };
