import React from 'react';
import { Col, Form, FormInstance, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PercentageOutlined } from '@ant-design/icons';
import { PurpleButton } from '../../../GlobalStyles';
import { monetaryValueFormat } from '../../../helpers/mask.helper';
import { maskPercentage } from '../../../helpers/convertion.helper';

interface Props {
  submit?: (values: FormInstance) => void;
}
function InvestForm({ submit }: Props) {
  const [form] = useForm();
  const { TextArea } = Input;

  return (
    <Form
      name="investForm"
      form={form}
      layout={'vertical'}
      autoComplete="off"
      onFinish={submit}
      initialValues={[]}
    >
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <Form.Item
            label={'Valor do investimento'}
            name={'investmentValue'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input
              type="text"
              addonBefore={'R$'}
              placeholder={'100.000,00'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldsValue({
                  investmentValue: monetaryValueFormat(e.target.value),
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Equity'}
            name={'equity'}
            rules={[
              {
                required: true,
                message: 'campo obrigatório',
              },
            ]}
          >
            <Input
              type="text"
              addonBefore={<PercentageOutlined />}
              placeholder={'100'}
              maxLength={5}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                form.setFieldsValue({ equity: maskPercentage(e.target.value) });
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldsValue({ equity: maskPercentage(e.target.value) });
              }}
            />
          </Form.Item>
        </Col>
        <Form.Item label={'Adicionar comentário'} name={'comment'}>
          <TextArea
            showCount
            style={{ resize: 'none' }}
            rows={2}
            placeholder="Comentário"
            maxLength={255}
          />
        </Form.Item>
      </Row>
      <PurpleButton
        type={'primary'}
        htmlType={'submit'}
        style={{ display: 'block', margin: '0 auto', padding: '0 50px' }}
      >
        Investir
      </PurpleButton>
    </Form>
  );
}

export default InvestForm;
