import { Image, Row, Col, Space, Grid } from 'antd';
import { BoxContainer, PurpleButton } from '../../../../GlobalStyles';
import Title from '../../../../components/Title';
import { PlanCard } from '../PlanCard';

import backgroundPlans from '../../../../assets/images/background-plans.jpg';
import robotImage from '../../../../assets/images/robot-image.png';
import iconAccelerator from '../../../../assets/icons/icon-accelerator.svg';
import iconInvestors from '../../../../assets/icons/icon-investors.svg';
import iconProfessional from '../../../../assets/icons/icon-professional.svg';
import iconStartup from '../../../../assets/icons/icon-startup.svg';

import { Content, ProfileBannerContainer, Center } from './styles';

const { useBreakpoint } = Grid;

export function CoursePlans() {
  const breakpoints = useBreakpoint();

  return (
    <BoxContainer noPadding>
      <Row>
        <Col span={24}>
          <Image width="100%" preview={false} src={backgroundPlans} alt="" />
        </Col>
      </Row>

      <Content>
        <Space direction="vertical" size={22} style={{ width: '100%' }}>
          <Title align="center" size={24}>
            Planos disponíveis para o seu perfil
          </Title>

          <Row gutter={[16, 24]}>
            <Col xs={24} xl={12} xxl={6}>
              <Center>
                <PlanCard
                  title="Aceleradora"
                  image={iconAccelerator}
                  description="Faça parte do ecossistema de inovação com o Plano Aceleradora. Acesso exclusivo a recursos avançados, networking poderoso e suporte personalizado. Estamos aqui para acelerar o seu caminho rumo ao sucesso!"
                  amount={990}
                />
              </Center>
            </Col>

            <Col xs={24} xl={12} xxl={6}>
              <Center>
                <PlanCard
                  title="Profissional"
                  image={iconProfessional}
                  description="Desbloqueie o potencial máximo com o Plano Profissional. Recursos premium, atualizações contínuas e uma plataforma dedicada para impulsionar sua carreira. Seja reconhecido como o profissional de destaque que você é!"
                  amount={199}
                />
              </Center>
            </Col>

            <Col xs={24} xl={12} xxl={6}>
              <Center>
                <PlanCard
                  title="Startups"
                  image={iconStartup}
                  description="O Plano Startups é a chave para transformar visões em realidade. Desde suporte técnico até estratégias de crescimento, estamos aqui para impulsionar o seu empreendimento rumo ao sucesso. Desperte o potencial inexplorado de sua startup!"
                  amount={199}
                />
              </Center>
            </Col>

            <Col xs={24} xl={12} xxl={6}>
              <Center>
                <PlanCard
                  title="Investidor"
                  image={iconInvestors}
                  description="O Plano Investidor oferece insights poderosos e análises aprofundadas para orientar suas decisões. Esteja à frente das oportunidades de investimento, com dados precisos e exclusivos. Otimize sua jornada no mundo dos investimentos!"
                  amount={990}
                />
              </Center>
            </Col>
          </Row>
        </Space>

        <ProfileBannerContainer>
          <Row align="middle" gutter={[16, 24]}>
            <Col md={24} lg={18}>
              <Space direction="horizontal">
                {breakpoints.lg && (
                  <Image width={200} preview={false} src={robotImage} />
                )}

                <div>
                  <Title
                    tag="h3"
                    align={breakpoints.lg ? 'left' : 'center'}
                    size={24}
                    color="var(--white-color)"
                  >
                    Possui uma startup ou quer se candidatar em uma?
                  </Title>
                  <Title
                    tag="h4"
                    size={16}
                    color="var(--white-color)"
                    margin="8px 0 0 0"
                    weight={400}
                    align={breakpoints.lg ? 'left' : 'center'}
                  >
                    Para cadastrar sua startup ou se candidatar em uma, você
                    precisa fazer uma atualização do seu modelo de perfil.
                  </Title>
                </div>
              </Space>
            </Col>

            <Col
              xs={{ span: 24 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 5, offset: 1 }}
            >
              <PurpleButton
                display="block"
                width="100%"
                maxWidth={breakpoints.lg ? '300px' : 'initial'}
              >
                Criar novo perfil
              </PurpleButton>
            </Col>
          </Row>
        </ProfileBannerContainer>
      </Content>
    </BoxContainer>
  );
}
