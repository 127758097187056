import styled from 'styled-components';

const BoxExtra = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    font-size: 22px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & .date {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const BoxSchedule = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const BoxEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  overflow-x: auto;
  margin-top: 10px;
  padding-bottom: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 280px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  padding: 5px 5px;

  & .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;

      :hover {
        background-color: var(--grey-color);
      }

      svg {
        font-size: 22px;
      }
    }
  }

  & .description {
    width: 100%;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  & .price {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 0.8rem;
  }
`;

export { BoxExtra, BoxEmpty, BoxList, BoxSchedule, Header, Card };
