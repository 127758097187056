import React from 'react';
import BoxTip from './BoxTip';

function Professional() {
  return (
    <BoxTip
      title="Profissional"
      description="Por meio deste acesso, você poderá submeter seu perfil às contratações disponíveis pelas startups, dependendo da área que você tem competência e de acordo com suas habilidades, você terá a possibilidade de ser contratado por alguma organização. Também neste usuário, poderá realizar os cursos da plataforma Innovation Studio, a fim de aprimorar suas expertises, gerando uma gama de conhecimento da sua parte.
            Então, se você tem esse perfil profissional, aproveite as opções de conexão que o Innovation Studio oferece."
    />
  );
}

export default Professional;
