import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  max-width: 365px;
  height: 100px;
  grid-template-columns: 120px 1fr auto;
  border-radius: 5px;
  border: 1px solid #9aa4a8;
  gap: 1rem;

  & img {
    align-self: stretch;
    border-radius: inherit;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.7rem 0;

  & div:first-child {
    grid-column: 1 / -1;

    & h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const WrapperIcon = styled.div`
  align-self: flex-start;
  margin: 4px 4px 0 0;
`;

export { Wrapper, WrapperIcon, ContentWrapper };
