import React from 'react';
import { Box, ButtonSave } from './styles';

interface Props {
  save?: () => void;
  text?: string;
  disabled?: boolean;
  position?: string;
  formName?: string;
}

function FooterPage({
  text = 'Salvar',
  save,
  disabled,
  position,
  formName,
}: Props) {
  return (
    <Box position={position}>
      <ButtonSave
        htmlType={'submit'}
        form={formName}
        disabled={disabled}
        onClick={save}
      >
        {text}
      </ButtonSave>
    </Box>
  );
}

export default FooterPage;
