import styled from 'styled-components';
import { Image, Space } from 'antd';

export const ProfileContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #05233d29;
  padding-bottom: 1rem;
`;

export const Cover = styled(Image).attrs({
  width: '100%',
  height: '156px',
})``;

export const ProfileWrapper = styled.div`
  margin: 1.375rem;
  margin-top: calc(0px - 5rem - 4px);
`;

export const ProfileImage = styled(Image)`
  width: 10rem;
  height: 10rem;

  border: 4px solid #fff;
`;

export const ProfileContent = styled(Space)`
  width: 100%;
  padding: 0px 1.375rem;
`;
