import styled from 'styled-components';

const CardInvestment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;

  img {
    width: 80px;
    margin-bottom: 10px;
  }
`;

const Item = styled.div<{
  flexDirection?: string;
  align?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  align-items: ${({ align }) => (align ? align : 'center')};
  padding: 5px 0;
`;

export { CardInvestment, Item };
