import { Button, Col, Form, Input, Select, Spin } from 'antd';
import { useMemo, useState } from 'react';
import Divider from '../../../components/Divider';
import { PurpleButton } from '../../../GlobalStyles';
import { ButtonAction } from './style';
import QuestionForm from './QuestionForm';
import MultipleChoiceForm from './MultipleChoiceForm';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ExerciseService from '../../../services/exercise/ExerciseService';
import useLoading from '../../../core/hooks/useLoading';
import notificationMessage from '../../../components/notifications/notificationMessage';
import ErrorNotification from '../../../components/notifications/errorNotification';
import { DefaultFormProps } from '../../../core/interfaces/course/ICourseForm';
import IExerciseQuizType, {
  getDescription,
  getValueString,
} from '../../../core/enums/IExerciseQuizType';
import IObjectiveQuestions from '../../../core/interfaces/course/IObjectiveQuestion';
import IExerciseResponse from '../../../core/interfaces/course/IExerciseResponse';

interface ISelect {
  questionPosition?: number;
  selectValue?: number;
}

const sizeValidation = {
  min: 5,
  max: 1000,
  message: 'tamanho deve ser entre 5 e 1000',
};

function ExerciseForm(props: DefaultFormProps<IExerciseResponse>) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const { entity: exercise } = props;
  const [questionValueSelect, setQuestionValueSelect] = useState<ISelect[]>([
    { questionPosition: 0, selectValue: 0 },
  ]);

  const questionOptions = useMemo(() => {
    const { OBJECTIVE, DISCURSIVE, ATTACHMENT } = IExerciseQuizType;
    return [OBJECTIVE, DISCURSIVE, ATTACHMENT].map((type) => ({
      value: type,
      label: getDescription(type),
    }));
  }, []);

  const exerciseService = useMemo(() => ExerciseService.init(), []);
  const { hideLoading, loading, showLoading } = useLoading();

  const getDataForm = () => {
    const quizzes = form.getFieldValue('exerciseList');
    return {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      topic: {
        entityId: props.topic.entityId,
      },
      quizzes: quizzes.map((quiz: Record<string, string>) => {
        const objectiveQuestions: IObjectiveQuestions[] = [];
        const options = Object.entries(quiz).filter(([key]) =>
          key.includes('questionOption'),
        );

        options.forEach(([key, value]) => {
          const index = key.split('-').at(1) ?? 0;
          objectiveQuestions.push({
            description: value,
            status: Boolean(quiz[`questionValue-${index}`]),
          });
        });

        return {
          objectiveQuestions,
          question: quiz.question,
          type: getValueString(Number(quiz.type)),
        };
      }),
    };
  };

  const onFinish = () => {
    showLoading();
    exerciseService
      .updateOrCreate(getDataForm(), exercise?.entityId)
      .then(() => {
        notificationMessage('default');
        props.close();
        props.reload();
      })
      .catch(() =>
        ErrorNotification({ defaultMessage: 'erro ao salvar exercício' }),
      )
      .finally(hideLoading);
  };

  const setNewSelectPointer = () => {
    setQuestionValueSelect([
      ...questionValueSelect,
      { questionPosition: questionValueSelect.length, selectValue: 0 },
    ]);
  };

  const onChangeSelectQuestionType = (
    selectValue: number,
    questionKey: number,
  ) => {
    const selectedValue = questionValueSelect.at(questionKey);

    if (selectedValue) {
      selectedValue.selectValue = selectValue;
      setQuestionValueSelect([...questionValueSelect]);
    }
  };

  const initialValues = useMemo(() => {
    const questions = exercise?.exerciseQuizList.map((item, index) => {
      const questionOptions: Record<any, any> = {};
      item.objectiveQuestions.forEach((value, key) => {
        questionOptions[`questionOption-${key}`] = value.description;
        questionOptions[`checkbox_${key}`] = value.status;
      });

      return {
        type: IExerciseQuizType[item.type],
        question: item.question,
        ...questionOptions,
      };
    });

    return {
      name: exercise?.name ?? '',
      description: exercise?.description ?? '',
      list: questions ?? [],
    };
  }, [exercise]);

  return (
    <Spin spinning={loading}>
      <Form
        initialValues={initialValues}
        name={'exerciseForm'}
        onFinish={onFinish}
        form={form}
        layout={'vertical'}
        autoComplete={'off'}
      >
        <Form.Item
          name={'name'}
          label="Nome do exercício"
          rules={[
            { ...sizeValidation },
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input placeholder="digite o nome do exercício" maxLength={255} />
        </Form.Item>
        <Form.Item
          name={'description'}
          label="Descrição do exercício"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            { ...sizeValidation },
          ]}
        >
          <TextArea
            showCount
            style={{ resize: 'none' }}
            rows={4}
            placeholder="descrição do exercício"
            maxLength={255}
          />
        </Form.Item>
        <Divider color="#a209a2" margin="0 0 10px 0" />
        {questionValueSelect.length <= 1 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontStyle: 'italic',
            }}
          >
            Adicione questões clicando no botão abaixo
          </div>
        ) : null}
        <Form.List name="exerciseList" initialValue={initialValues.list}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => {
                const type = form.getFieldValue(['exerciseList', name, 'type']);
                const isObjective = IExerciseQuizType.OBJECTIVE === type;
                const hasNext = fields.at(key + 1);

                return (
                  <div key={`question-${key}`}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name={[name, 'type']}
                        label={'Tipo de questão'}
                        rules={[
                          {
                            required: true,
                            message:
                              'Selecione uma opção ou delete a questão vazia',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Escolha o tipo de questão"
                          value={questionValueSelect[key]?.selectValue}
                          onChange={(selectValue) => {
                            onChangeSelectQuestionType(selectValue, key);
                          }}
                          key={`selectQuestionOption-${key}`}
                        >
                          {questionOptions.map((question, index) => (
                            <Option value={question.value} key={index}>
                              {question.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {fields.length > 1 && (
                      <ButtonAction>
                        <DeleteOutlined
                          className="removeItem"
                          onClick={() => remove(name)}
                        />
                      </ButtonAction>
                    )}
                    {isObjective ? (
                      <MultipleChoiceForm
                        name={name}
                        question={initialValues.list[name]?.question ?? ''}
                        changeBox={(nameField, value) =>
                          form.setFieldValue(
                            ['exerciseList', name, nameField],
                            value,
                          )
                        }
                      />
                    ) : !type ? null : (
                      <QuestionForm
                        name={name}
                        setText={initialValues.list[name]?.question ?? ''}
                      />
                    )}

                    {Boolean(hasNext) && (
                      <Divider color="#a209a2" margin="0 0 10px 0" />
                    )}
                  </div>
                );
              })}
              <ButtonAction>
                <Form.Item>
                  <Button
                    size="small"
                    shape="circle"
                    type="dashed"
                    className="addItem"
                    onClick={() => add(setNewSelectPointer())}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </ButtonAction>
            </>
          )}
        </Form.List>
        <Divider color="#a209a2" margin="0 0 10px 0" />
        <PurpleButton
          type="primary"
          htmlType="submit"
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '10px',
          }}
        >
          Publicar exercício
        </PurpleButton>
      </Form>
    </Spin>
  );
}

export default ExerciseForm;
