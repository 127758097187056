import React from 'react';
import BoxTip from './BoxTip';

function Student() {
  return (
    <BoxTip
      title="Aluno"
      description="No perfil Aluno, você terá acesso a um diversificado portfólio de cursos voltados para o empreendedorismo, inovação, tecnologia e startups, com valores acessíveis e trazendo conhecimento sobre essas áreas que tendem a crescer.
            A plataforma do Innovation Studio, abre um leque de oportunidades para você, aluno, que quer aprender mais, sobre negócios inovadores. O Innovation Studio desenvolveu a plataforma a fim de gerar maior conexão com os atores do ecossistema de inovação."
    />
  );
}

export default Student;
