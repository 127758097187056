import styled from 'styled-components';

const Card = styled.div<{
  height?: string;
}>`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #9aa4a8;
  background-color: #fff;
  overflow: auto;
  height: ${({ height }) => height};
`;

export { Card };
