import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  & .ant-collapse-item {
    margin-bottom: 5px;
  }

  & .ant-collapse-header-text {
    width: 100% !important;
    color: var(--white-color);
  }

  & .ant-collapse-header {
    background-color: var(--primary-color) !important;
  }

  & .ant-collapse-arrow {
    svg {
      color: var(--white-color);
    }
  }
`;

const BoxForm = styled.div`
  width: 100%;

  & .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.4em;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 10px;

  & .title {
    display: flex;
    align-items: flex-start;
    font-size: 1.4em;
    color: var(--primary-color);
    font-weight: bold;
  }

  & .info {
    position: relative;
    margin: 12px 0 0 10px;

    & svg {
      font-size: 14px;
    }
  }

  & .subTitle {
    color: var(--support-color);
    font-size: 0.6em;
    font-style: italic;
  }
`;

const BoxInfo = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  display: inline-block;
  z-index: 10;
  width: 350px;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  background-color: var(--white-color);
  padding: 8px 8px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.4);

  & p {
    text-align: justify;
    font-size: 0.7em;
  }
`;

const ModalContent = styled.div`
  color: var(--font-color);
  text-align: justify;

  & a {
    font-weight: bold;
    color: var(--font-color);
  }
`;

const BoxButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  & .ant-btn-primary {
    padding: 0 40px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;

    & span {
      font-size: 0.8em;
    }
  }

  & .ant-btn-default {
    padding: 0 40px;
    border-radius: 6px;
    margin-right: 10px;

    & span {
      font-size: 0.8em;
    }
  }
`;

const BoxSlider = styled.div`
  position: relative;
  padding: 0px 30px;

  & .beforeSlider,
  .afterSlider {
    display: inline-block;
    text-align: center;
    text-transform: none;
    position: absolute;
    top: -10px;
    width: 150px;
    color: var(--font-color);
    font-size: 0.7em;
    font-weight: bold;
    text-align: center;
  }

  & .beforeSlider {
    left: 0;
  }

  & .afterSlider {
    right: 0;
  }

  & .ant-slider {
    width: calc(100% - 300px) !important;
    left: 150px;

    & .ant-slider-step {
      background-color: #d1d1d1 !important;

      & .ant-slider-dot {
        display: none;
      }
    }

    & .ant-slider-mark {
      & span {
        margin-top: 2px;
        font-size: 0.7em;
        color: var(--font-color);
      }
    }

    & .ant-slider-handle {
      width: 12px;
      height: 12px;
      margin-top: -4px;
      background-color: var(--primary-color);
    }
  }
`;

const CollapseHeader = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;

  & .title {
    font-weight: bold;
  }
`;

const PhasesHeader = styled.div`
  display: flex;
  flex-direction: column;

  & .subTitle {
    justify-content: flex-start;
    align-self: flex-start;
    font-size: 0.9em;
    font-style: italic;
    padding-bottom: 10px;

    b {
      font-size: 1em;
    }
  }

  & .scale {
    display: flex;
    flex-direction: row;

    & .item {
      flex: content;
      text-align: right;
      font-weight: bold;
      background-color: var(--grey-color);
    }

    & .item:first-child {
      min-width: 210px;
    }
  }
`;

const PhaseSliders = styled.div`
  display: flex;
  padding: 8px 0;
  flex-wrap: wrap;

  & .label {
    max-width: 185px;
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 32px;
  }

  & .slider {
    flex: 1;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const QuestionsContent = styled.div`
  & .addItem {
    position: absolute;
    left: 50%;
  }

  & .removeItem {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    cursor: pointer;
  }
`;

const Description = styled.div`
  display: flex;
  font-style: italic;
  margin-bottom: 20px;
`;

const ButtonDelete = styled.div`
  position: relative;
`;

export {
  BoxContent,
  BoxForm,
  BoxInfo,
  BoxSlider,
  BoxButton,
  ButtonDelete,
  CollapseHeader,
  Description,
  ModalContent,
  Header,
  PhasesHeader,
  PhaseSliders,
  QuestionsContent,
};
