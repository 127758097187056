import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { GrFormClose } from 'react-icons/gr';
import imgDefault from '../../../../assets/images/image-default.png';
import ActionsCrud from '../../../../components/ActionsCrud';
import ConfirmAction from '../../../../components/ConfirmAction';
import Text from '../../../../components/Text';
import { getTextTypeVacancy } from '../../../../core/enums/ITypeVacancyEnum';
import useLoading from '../../../../core/hooks/useLoading';
import IVacancies from '../../../../core/interfaces/IVacancies';
import { notification } from '../../../../helpers/notification.helper';
import VacanciesService from '../../../../services/VacanciesService';
import { StartupImage, VagueInformation } from './styles';

type Props = {
  startupEmail?: string;
  startupName?: string;
  vacancies: IVacancies;
  onSelectVacancy(): void;
  onReload(): void;
};

function VacanciesCard({
  startupName = '',
  startupEmail = '',
  vacancies,
  onReload,
  onSelectVacancy,
}: Props) {
  const { loading, showLoading, hideLoading } = useLoading();
  const vacanciesService = useMemo(() => VacanciesService.init(), []);

  const closeVacancy = () => {
    const { entityId = 0 } = vacancies;

    showLoading();
    vacanciesService
      .delete(entityId)
      .then(() => {
        onReload();
        notification.success('Vaga encerrada com sucesso');
      })
      .catch(() => notification.error('Erro ao encerrar vaga'))
      .finally(hideLoading);
  };

  const createdAt = moment(vacancies.creation);
  const diff = moment().diff(createdAt);
  const duration = moment.duration(diff);

  return (
    <Spin spinning={loading}>
      <Row
        gutter={[12, 12]}
        align="middle"
        justify="space-between"
        style={{
          padding: '12px',
          borderRadius: '8px',
          border: '1px solid #9AA4A8',
        }}
      >
        <Col>
          <VagueInformation>
            <StartupImage src={imgDefault} alt="imagem startup" />
            <div>
              <Text weight={600} size={20} lineHeight={1.2}>
                {vacancies.office}
              </Text>
              <Text lineHeight={1.3}>
                {startupName} - {vacancies.vacancyLocation} -{' '}
                {getTextTypeVacancy(vacancies.typeVacancy)}
              </Text>
              <Text lineHeight={1.3}>{startupEmail}</Text>
              <Text lineHeight={1.3}>
                Tempo de anúncio: {duration.days()}d {duration.hours()}hr{' '}
                {duration.minutes()}mn
              </Text>
            </div>
          </VagueInformation>
        </Col>
        <Col>
          <ActionsCrud
            sizeIcon={20}
            actions={[
              {
                label: 'Editar vaga',
                icon: <EditOutlined size={20} />,
                event: onSelectVacancy,
              },
              {
                label: (
                  <ConfirmAction
                    title="Deseja encerrar esta vaga?"
                    onConfirm={closeVacancy}
                  >
                    <p>Encerrar vaga</p>
                  </ConfirmAction>
                ),
                icon: <GrFormClose size={20} />,
              },
            ]}
          />
        </Col>
      </Row>
    </Spin>
  );
}

export default VacanciesCard;
