import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IAmountInvestment } from '../core/interfaces/IAmountInvestment';
import { IValuesInvestment } from '../core/interfaces/IValuesInvestment';

class ValuesInvestmentService extends CrudService {
  static init(): ValuesInvestmentService {
    return new ValuesInvestmentService();
  }

  constructor() {
    super({
      requestMapping: '/investimentsValues',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IValuesInvestment[]> {
    return api
      .get<IValuesInvestment[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(valuesInvestment: IValuesInvestment): Promise<IValuesInvestment> {
    return api
      .post<IValuesInvestment>(`${this.requestMapping}`, valuesInvestment)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ValuesInvestmentService;
