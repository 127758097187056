import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Form } from 'antd';
import { TextDescription } from './style';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  Panel,
  StepItem,
  WrapperStep,
} from '../../../pages/client/Methodology/MethodologyFormView/styles';
import IMethodology from '../../../core/interfaces/models/IMethodology';
import IPhases from '../../../core/interfaces/models/IPhases';
import IPillars from '../../../core/interfaces/models/IPillars';
import AddListButton from '../../AddListButton';
import { useModal } from 'sunflower-antd';
import RegisteredCourses from '../../RegisteredCourses';
import { isEmpty, sumBy } from 'lodash';
import ErrorNotification from '../../notifications/errorNotification';
import CoursePreviewCard from './CoursePreviewCard';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { fetchCourses } from '../../../store/registered-courses.slice';
import { useAuth } from '../../../core/hooks/auth';
import CoursePhaseService from '../../../services/course-phase/CoursePhaseService';
import IPillarPhaseCourse from '../../../core/interfaces/course-phase/IPillarPhaseCourse';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;
  methodology?: IMethodology;
  methodologyCreate?: IMethodology;

  getNameMethodology(): unknown;
}

function ContentMethodologyForm(props: IParams) {
  const [form] = Form.useForm();
  const { logged } = useAuth();
  const teacherId = useMemo(() => logged?.entityId, [logged]);
  const dipatch = useDispatch<AppDispatch>();
  const { loading, showLoading, hideLoading } = useLoading();

  const methodology = useMemo(
    () => props.methodologyCreate ?? props.methodology,
    [props.methodology, props.methodologyCreate],
  );

  const coursePhaseService = useMemo(() => CoursePhaseService.init(), []);
  const modalAddContent = useModal({
    defaultVisible: false,
  });

  const [coursePhase, setCoursePhase] = useState<IPillarPhaseCourse[]>([]);
  const [pillars, setPillars] = useState<IPillars[]>([]);
  const [phases, setPhases] = useState<IPhases[]>([]);
  const [phaseSelected, setPhaseSelected] = useState<null | IPhases>(null);
  const [pillarSelected, setPillarSelected] = useState<null | IPillars>(null);

  const reload = () => {
    getCoursePhase();
    dipatch(
      fetchCourses({
        teacherId,
      }),
    );
  };

  const addCourse = (phase: IPhases, pillar: IPillars, name: string) => {
    return (
      <StepItem key={`key_step_item_${name}`}>
        {name}
        {coursePhase
          .filter((coursePhase) => {
            const isPhase = coursePhase.phase.entityId === phase.entityId;
            const isPillar = coursePhase.pillar.entityId === pillar.entityId;
            return isPillar && isPhase;
          })
          .map(({ course, entityId }, index) => {
            const topics = course.topics ?? [];
            const { duration, lessonQuantity } = topics.reduce(
              (acum, topic) => {
                acum.lessonQuantity += topic.lessons.length;
                acum.duration += sumBy(topic.lessons, 'duration');
                return acum;
              },
              { lessonQuantity: 0, duration: 0 },
            );

            return (
              <CoursePreviewCard
                duration={duration}
                reload={reload}
                lessonQuantity={lessonQuantity}
                title={course.name}
                key={`course_key_${index}`}
                srcImage={course.pictureName}
                entityId={Number(entityId)}
              />
            );
          })}

        <AddListButton
          onClick={() => {
            setPhaseSelected(phase);
            setPillarSelected(pillar);
            modalAddContent.show();
          }}
          background="none"
          backgroundHover="none"
          colorHover="initial"
          size={'middle'}
          margin="0"
        >
          Adicionar conteudo
        </AddListButton>
      </StepItem>
    );
  };

  const getCoursePhase = useCallback(() => {
    showLoading();
    if (methodology?.entityId) {
      coursePhaseService
        .findByMethodology(methodology.entityId)
        .then((data) => setCoursePhase(data))
        .catch(() =>
          ErrorNotification({
            defaultMessage: 'Erro ao listar curso do pillar',
          }),
        )
        .finally(hideLoading);
    }
  }, [methodology, coursePhaseService]);

  useEffect(() => {
    getCoursePhase();
  }, [getCoursePhase]);

  useEffect(() => {
    if (methodology) {
      setPillars(methodology?.pillars ?? []);
      setPhases(methodology?.phases ?? []);
    }
  }, [methodology]);

  return (
    <>
      <Loading spinning={loading} width={200} height={200}>
        <RegisteredCourses
          reload={getCoursePhase}
          phase={phaseSelected}
          pillar={pillarSelected}
          {...modalAddContent.modalProps}
        />
        <TextDescription>
          <p>
            Associe seus conteúdos conforme os passos e fases da sua
            metodologia.
          </p>
        </TextDescription>

        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 180 : 90} />
          )}
        >
          {pillars.map((pillar, index: number) => (
            <Panel
              className="parent-item"
              header={pillar.name}
              key={`key_panel_${index}`}
            >
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 180 : 90} />
                )}
              >
                {phases.map((phase, index: number) => (
                  <Panel
                    header={phase.name}
                    key={`key_children_panel_${index}`}
                  >
                    <Form form={form} name={'content-methodology'}>
                      <WrapperStep>
                        {isEmpty(phase.phases)
                          ? addCourse(
                              phase,
                              pillar,
                              `${index + 1} - ${phase.name}`,
                            )
                          : phase.phases.map((step: IPhases, index: number) =>
                              addCourse(
                                step,
                                pillar,
                                `${index + 1} - ${step.name}`,
                              ),
                            )}
                      </WrapperStep>
                    </Form>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      </Loading>
    </>
  );
}

export default ContentMethodologyForm;
