import React, { useState, useMemo, useEffect } from 'react';
import { Box, Wrapper } from './styles';
import FileService from '../../../services/FileService';
import defaultImage from '../../../assets/images/image-default.png';
import useLoading from '../../../core/hooks/useLoading';
import { Spin } from 'antd';
import Text from '../../../components/Text';

interface Props {
  text: string;
  filename: string;
}

function FileBox({ text, filename }: Props) {
  const { loading, showLoading, hideLoading } = useLoading();
  const fileService = useMemo(() => FileService.init(), []);
  const [pathImage, setPathImage] = useState<string>(defaultImage);

  const getFile = () => {
    showLoading();
    fileService
      .get(filename)
      .then((data) => setPathImage(data))
      .finally(hideLoading);
  };

  useEffect(() => {
    getFile();
  }, []);

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <Box>
          <img src={pathImage} height={90} width={90} alt={filename} />
          <Text weight={700}>{text}</Text>
        </Box>
      </Spin>
    </Wrapper>
  );
}

export default FileBox;
