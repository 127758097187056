import React, { useEffect } from 'react';
import { BoxContent, Card, Description, Title } from './style';
import imgFree from '../../../../assets/images/plan-card-free.svg';
import imgValuation from '../../../../assets/images/plan-card-valuation.svg';
import imgSpeeder from '../../../../assets/images/plan-card-speeder.svg';
import imgDiagnosis from '../../../../assets/images/plan-card-diagnosis.svg';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
}

function PlanCards(props: IParams) {
  const cards = [
    {
      img: imgFree,
      title: 'Free Ilimitado',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      img: imgSpeeder,
      title: 'Aceleração Innovation',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      img: imgDiagnosis,
      title: 'Diagnóstico',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      img: imgValuation,
      title: 'Valuation',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
  ];

  useEffect(() => {
    props.setButtonState(true);
  }, [props]);

  return (
    <BoxContent>
      {cards.map((item, key) => {
        return (
          <Card key={key}>
            <img alt="" src={item.img} />
            <Title>{item.title}</Title>
            <Description>{item.description}</Description>
          </Card>
        );
      })}
    </BoxContent>
  );
}

export default PlanCards;
