import { Button, Space } from 'antd';
import React from 'react';
import { DrawerContainer } from './style';

interface Params {
  children: React.ReactNode;
  visible: boolean;
  title: string;
  width: string | number;
  saveButton?: boolean;
  onClose: () => void;
  className?: string;
}

function Drawer(props: Params) {
  return (
    <>
      <DrawerContainer
        title={props.title}
        width={props.width}
        onClose={props.onClose}
        open={props.visible}
        className={props.className}
        getContainer={false}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={props.onClose}>Cancelar</Button>
            {props.saveButton && (
              <Button htmlType={'submit'} form={'form'} type="primary">
                Salvar
              </Button>
            )}
          </Space>
        }
      >
        {props.children}
      </DrawerContainer>
    </>
  );
}

export default Drawer;
