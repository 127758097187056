import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f1f3f2;
  border: 1px solid #9aa4a8;
  padding: 0.5rem;
  border-radius: 6px;
  max-width: 300px;
`;

const Box = styled.div`
  display: flex;
  gap: 1rem;
`;

export { Box, Wrapper };
