import React, { useMemo, useState, useEffect } from 'react';
import { Form, Input, Spin } from 'antd';
import Divider from '../../../components/Divider';
import DragAndDropFile from '../../../components/DragAndDropFile/DragAndDropFile';
import ReactQuill from '../../../components/JoditQuill/JoditQuill';
import { PurpleButton } from '../../../GlobalStyles';
import { convertBase64 } from '../../../helpers/convertion.helper';
import ErrorNotification from '../../../components/notifications/errorNotification';
import ExtraMaterialService from '../../../services/extra-material/ExtraMaterialService';
import { DefaultFormProps } from '../../../core/interfaces/course/ICourseForm';
import IExtraMaterial from '../../../core/interfaces/course/IExtraMaterial';
import useLoading from '../../../core/hooks/useLoading';
import notificationMessage from '../../../components/notifications/notificationMessage';

function ExtraMaterialForm(props: DefaultFormProps<IExtraMaterial>) {
  const [form] = Form.useForm();
  const { entity: extraMaterial } = props;
  const isEditing = Boolean(extraMaterial);

  const extraMaterialService = useMemo(() => ExtraMaterialService.init(), []);
  const { hideLoading, loading, showLoading } = useLoading();
  const [textValue, setTextValue] = useState<string>('');

  const getDataForm = () => {
    const file = form.getFieldValue('file');
    const fileName = form.getFieldValue('fileName');

    return {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      ...(file && { file, originalFileName: fileName }),
      topic: {
        entityId: props.topic.entityId,
      },
    };
  };

  const onFinish = () => {
    showLoading();
    extraMaterialService
      .updateOrCreate(getDataForm(), extraMaterial?.entityId)
      .then(() => {
        props.reload();
        props.close();
        notificationMessage('default');
      })
      .catch(() =>
        ErrorNotification({
          defaultMessage: 'erro ao salvar material complementar',
        }),
      )
      .finally(hideLoading);
  };

  const materialChange = (file: File) => {
    convertBase64(file)
      .then((data) =>
        form.setFieldsValue({
          file: data,
          fileName: file.name,
        }),
      )
      .catch(() =>
        ErrorNotification({
          defaultMessage: 'erro ao selecionar arquivo',
        }),
      );
  };

  const initialValues = useMemo(() => {
    return {
      name: extraMaterial?.name ?? '',
      description: extraMaterial?.description ?? '',
    };
  }, [extraMaterial]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setTextValue(initialValues.description);
  }, [form, initialValues]);

  return (
    <Spin spinning={loading}>
      <Form
        name={'lessonForm'}
        onFinish={onFinish}
        form={form}
        layout={'vertical'}
        autoComplete={'off'}
      >
        <Form.Item
          name={'name'}
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input placeholder="Digite o nome do material" maxLength={255} />
        </Form.Item>
        <Form.Item
          name={'description'}
          label="Descrição"
          rules={[
            { required: true, message: 'Descrição é um campo obrigatório!' },
            {
              max: 255,
              message: 'O campo deve possuir no máximo 255 caracteres',
            },
          ]}
        >
          <ReactQuill
            content={textValue}
            onChange={(text) => setTextValue(text)}
          />
        </Form.Item>
        <Divider color="#a209a2" />
        <Form.Item
          name={'file'}
          label={'Material'}
          rules={[
            {
              required: !isEditing,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <DragAndDropFile
            maxFiles={1}
            fileType={['application/pdf']}
            description="Suporta apenas arquivo PDF"
            onFileChange={(file: File) => materialChange(file)}
          />
        </Form.Item>
        <Divider color="#a209a2" />
        <PurpleButton
          type="primary"
          htmlType="submit"
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '10px',
          }}
        >
          Salvar
        </PurpleButton>
      </Form>
    </Spin>
  );
}

export default ExtraMaterialForm;
