enum ITypeVacancyEnum {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Contract = 'CONTRACT',
  Temporary = 'TEMPORARY',
  Internship = 'INTERNSHIP',
}

const getTextTypeVacancy = (value: ITypeVacancyEnum) => {
  const texts = {
    [ITypeVacancyEnum.FullTime]: 'Tempo integral',
    [ITypeVacancyEnum.PartTime]: 'Meio período',
    [ITypeVacancyEnum.Contract]: 'Contrato',
    [ITypeVacancyEnum.Temporary]: 'Temporário',
    [ITypeVacancyEnum.Internship]: 'Estágio',
  };

  return texts[value];
};

export { getTextTypeVacancy, ITypeVacancyEnum };
