import React from 'react';
import { BoxContent, BoxImg, Description, Extra, Title } from './style';

interface IParams {
  image?: string;
  urlVideo?: string;
  title: string;
  description: any;
  extra?: any;
}

function BoxInfo({ image, urlVideo, title, description, extra }: IParams) {
  return (
    <BoxContent>
      {urlVideo && (
        <video
          id="sampleVideo"
          width="100%"
          height="100%"
          controls
          controlsList="nodownload noplaybackrate"
        >
          <source src={urlVideo} type="video/mp4" />
        </video>
      )}
      {image && (
        <BoxImg>
          <img alt="" src={image} />
        </BoxImg>
      )}
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Extra>{extra}</Extra>
    </BoxContent>
  );
}

export default BoxInfo;
