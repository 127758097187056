import React from 'react';
import BoxInfo from './Box/InfoBox';

function CoursesInfo() {
  return (
    <BoxInfo
      title="Cursos"
      description={
        <>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry.
          </p>
        </>
      }
    />
  );
}

export default CoursesInfo;
