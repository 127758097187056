import ICoursePhaseRequest from '../../core/interfaces/course-phase/ICoursePhaseRequest';
import IPillarPhaseCourse from '../../core/interfaces/course-phase/IPillarPhaseCourse';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class CoursePhaseService {
  private readonly requestMapping = 'course-phases';

  public static init() {
    return new CoursePhaseService();
  }

  public findByMethodology(methodology: number) {
    const params = { methodology };
    return api
      .get<IPillarPhaseCourse[]>(`${this.requestMapping}/by-methodology`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public linkCoursesToPhase(data: ICoursePhaseRequest) {
    return api
      .put(this.requestMapping, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public unlinkCourse(courseId: number) {
    return api
      .delete(`${this.requestMapping}/unlink-course/${courseId}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default CoursePhaseService;
