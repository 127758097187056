import styled from 'styled-components';

const Box = styled.div`
  & li {
    .content-question {
      display: flex;
      gap: 8px;
      padding: 15px 11px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: var(--white-color);

      & p:last-child {
        color: var(--thirdy-color);
        margin-right: 25px;
      }

      & p {
        margin: 0;
        font-size: 19px;
        font-weight: 700;
        color: var(--primary-color);
        font-family: 'Segoe UI', sans-serif;

        @media print {
          font-size: 15px;
        }
      }

      @media (max-width: 600px) {
        & p:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const Title = styled.div`
  margin: 0;
  padding: 11px;
  font-weight: 700;
  font-size: 19px;
  font-family: 'Segoe UI', sans-serif;
  color: var(--white-color);
  background-color: var(--primary-color);
`;

export { Box, Title };
