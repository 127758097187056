import styled from 'styled-components';

export const FormContent = styled.div`
  width: 100%;

  & .ant-form-item-label {
    height: 24px;
    padding: 0;
  }

  & .ant-form-item .ant-form-item-required {
    font-size: 0.8em !important;
    color: var(--primary-color) !important;
    font-weight: bold;
    margin-left: -10px;
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: transparent;
  }

  & .ant-checkbox-wrapper {
    & .ant-checkbox {
      & .ant-checkbox-inner {
        width: 14px;
        height: 14px;
        border: none;
        outline: none;
        border: 2px solid var(--primary-color);
        border-radius: 2px;
      }
    }
  }

  & .terms {
    width: 100%;

    & span,
    a {
      color: var(--primary-color);
      font-size: 0.9em;
    }
  }
`;

export const BoxButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  & .ant-btn-primary {
    padding: 0 40px;
    border: none;
    border-radius: 6px;

    & span {
      font-size: 0.8em;
    }
  }
`;
