import { errorCallback } from '../../helpers/promise.helper';
import api from '../services/api';

class ClientProfileService {
  private baseUrl = '/client-profile';

  public static init() {
    return new ClientProfileService();
  }

  public update(id: number, request: Record<string, string>) {
    return api
      .put(`${this.baseUrl}/${id}`, request)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ClientProfileService;
