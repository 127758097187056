import React from 'react';
import { Line } from '@ant-design/plots';

interface IParams {
  data: Array<any>;
  xField: string;
  yField: string;
  label: any;
  tooltip: any;
  point: any;
}

interface IData {
  data: Array<any>;
}

function GraphicPrePosMoney({ data }: IData) {
  const config: IParams = {
    data,
    xField: 'month',
    yField: 'value',
    label: {},
    point: {
      size: 5,
      style: {
        fill: 'white',
        stroke: 'var(--primary-color)',
        lineWidth: 1,
      },
    },
    tooltip: {
      showMarkers: false,
    },
  };
  return <Line {...config} />;
}

export default GraphicPrePosMoney;
