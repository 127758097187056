import { useState } from 'react';
import { Button, Dropdown, Row, Col } from 'antd';
import { DownloadOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Title, Box, Card, CardItem, Actions } from './styles';
import {
  IconOptions,
  MenuOption,
  MenuDropdown,
} from '../../../../GlobalStyles';
import { DiagnosticTopicType } from '../../../../types/diagnosis/DiagnosisType';
import PathDiagnosticResult from '../../../../assets/icons/diagnostic-result.png';
import { useNavigate } from 'react-router-dom';
import DiagnosticResult from '../DiagnosticResult';

interface IProps {
  title: string;
  isBlocked?: boolean;
  topics: DiagnosticTopicType[];
}

function DiagnosticTopic(props: IProps) {
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);

  const [diagnostic, setDiagnostic] = useState<any>();
  const [startup, setStartup] = useState<any>();

  const { title, topics, isBlocked } = props;

  const performDiagnosis = async (params: any) => {
    navigate('/diagnostics/questions', { state: params });
  };

  const seeResult = (params: any, startup: any) => {
    setDiagnostic(params);
    setStartup(startup);
    setShowResult(true);
  };

  const menu = (params: any, startup: any) => (
    <MenuDropdown>
      <MenuOption>
        <DownloadOutlined />
        <p>Baixar PDF</p>
      </MenuOption>
      <MenuOption onClick={() => seeResult(params, startup)}>
        <EyeOutlined />
        <p>Visualizar</p>
      </MenuOption>
    </MenuDropdown>
  );

  return (
    <>
      <Box isBlocked={isBlocked}>
        <Title>{title}</Title>
        {topics?.map((topic, index) => {
          const isPending = /pendente/gi.test(topic.status);
          return (
            <Card key={`topic_key_${index}`}>
              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                gutter={[8, 8]}
              >
                <Col sm={5} md={6}>
                  <Row align="middle" gutter={8}>
                    <Col>
                      <img
                        src={PathDiagnosticResult}
                        alt="resultado diagnostico"
                        height={78}
                        width={78}
                      />
                    </Col>
                    <Col>
                      <Title fontSize="17px" maxWidth="180px">
                        {topic.title}
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <CardItem>
                    <b>Data de entrada:</b> <br /> {topic.entryDate}
                  </CardItem>
                </Col>
                <Col md={5}>
                  <CardItem>
                    <b>Data de execução:</b> <br />{' '}
                    {topic.executionDate || 'Não executado'}
                  </CardItem>
                </Col>
                <Col md={4}>
                  <CardItem>
                    <b>{topic.status}</b>
                  </CardItem>
                </Col>
                <Col md={4}>
                  <Actions>
                    {isPending ? (
                      <Button
                        type="primary"
                        style={{
                          border: 'none',
                          backgroundColor: 'var(--thirdy-color)',
                        }}
                        onClick={() => performDiagnosis(topic.diagnostic)}
                      >
                        Realizar diagnóstico
                      </Button>
                    ) : (
                      <Dropdown
                        overlay={menu(topic.diagnostic, topic.startup)}
                        trigger={['click']}
                        placement="bottomLeft"
                      >
                        <IconOptions cursor="pointer" size="24px">
                          <MoreOutlined />
                        </IconOptions>
                      </Dropdown>
                    )}
                  </Actions>
                </Col>
              </Row>
            </Card>
          );
        })}
      </Box>

      <DiagnosticResult
        show={showResult}
        close={() => setShowResult(false)}
        startup={startup}
        diagnostic={diagnostic}
      />
    </>
  );
}

export default DiagnosticTopic;
