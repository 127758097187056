import React, { useEffect, useState } from 'react';
import DiagnosisInfo from '../../Infos/DiagnosisInfo';
import ContentBase from '../../templates/ContentBase';
import DiagnosisHeader from './DiagnosisHeader';
import DiagnosticTopic from './DiagnosticTopic';
import { useAuth } from '../../../core/hooks/auth';
import api from '../../../core/services/api';
import AlertMessage from '../../../components/Alert/AlertMessage';
import ScreenInformationVoid from '../../../components/Screen/ScreenInformationVoid';
import Loading from '../../../components/Loading/Loading';
import DiagnosticTopicMobile from './DiagnosticTopicMobile';
import { Col, Row } from 'antd';
import { MediaQueryVisible } from '../../../GlobalStyles';

function Diagnosis() {
  const { logged } = useAuth();
  const id = logged?.entityId;
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [dataStartup, setDataStartup] = useState<any>();

  const getInviter = () => {
    setLoading(true);
    api
      .get('/startup/startupDiagnostic', {
        params: {
          user: id,
        },
      })
      .then((response) => {
        if (response.data !== null || response.data !== undefined) {
          setDataStartup(response.data);
        } else {
          setVisible(true);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setVisible(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOk = () => {
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const diagnoses = [
    {
      title: 'Diagnóstico atual',
      topics: [
        {
          title:
            (dataStartup?.diagnostic?.name ?? '') +
            ' - ' +
            (dataStartup?.phases?.name ?? ''),
          diagnostic: dataStartup?.diagnosticResult,
          entryDate: dataStartup?.diagnosticResult?.creation,
          status: 'Pendente',
          executionDate: dataStartup?.diagnosticResult?.lastUpdated,
          startup: dataStartup?.startup,
        },
      ],
    },
    {
      title: 'Diagnósticos futuros',
      isBlocked: true,
      topics: dataStartup?.diagnosticsNotAnswered?.map((diagnostic: any) => {
        return {
          title: diagnostic?.name + ' - ' + diagnostic?.phase?.name,
          diagnostic: undefined,
          entryDate: 'Não iniciado',
          status: 'Pendente',
          executionDate: 'Não Realizado',
          startup: dataStartup?.startup,
        };
      }),
    },
    {
      title: 'Diagnósticos realizados',
      topics: dataStartup?.diagnosticAnswered?.map((diagnostic: any) => {
        return {
          title:
            diagnostic?.diagnostic.name +
            ' - ' +
            diagnostic?.diagnostic.phase.name,
          diagnostic: diagnostic,
          entryDate: diagnostic.creation,
          status: 'Concluído',
          executionDate: diagnostic.executionDate,
          startup: dataStartup.startup,
        };
      }),
    },
  ];

  useEffect(() => {
    getInviter();
  }, []);

  return (
    <ContentBase
      title="Diagnóstico"
      content={
        <Loading size="large" spinning={loading} width={300} height={300}>
          {dataStartup !== undefined ? (
            <>
              <DiagnosisHeader
                name={dataStartup?.accelerator?.name}
                methodology={dataStartup?.methodology?.name}
                numberPhase={dataStartup?.methodology?.phases.length}
                phaseCurrent={dataStartup?.phases?.name}
              />
              <Row gutter={[0, 12]}>
                {diagnoses.map((diagnosis) => (
                  <Col key={`diagnostic_key_${diagnosis.title}`} span={24}>
                    <MediaQueryVisible maxWidth="680px" display="none">
                      <DiagnosticTopic
                        topics={diagnosis.topics}
                        title={diagnosis.title}
                        isBlocked={diagnosis.isBlocked}
                      />
                    </MediaQueryVisible>
                    <MediaQueryVisible minWidth="681px" display="none">
                      <DiagnosticTopicMobile
                        topics={diagnosis.topics}
                        title={diagnosis.title}
                        isBlocked={diagnosis.isBlocked}
                      />
                    </MediaQueryVisible>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              <ScreenInformationVoid
                isButton={false}
                message={'Sem diagnóstico no momento...'}
              />
              <AlertMessage
                message={'Atenção!'}
                description={
                  'A aceleradora não possui diagnósticos cadastrados. Por favor, entre em contato com a aceleradora para mais informações.'
                }
                textOkButton={'Ok'}
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
              />
            </>
          )}
        </Loading>
      }
      modalContent={<DiagnosisInfo />}
    />
  );
}

export default Diagnosis;
