import styled from 'styled-components';
import Text from '../Text';

interface IInfo {
  width?: string | 'auto';
  align?: string | 'center';
}

const BoxCard = styled.div<{
  padding?: string;
  borderColor?: string;
  boxShadow?: string;
  height?: string;
}>`
  display: flex;
  padding: ${({ padding }) => padding};
  flex-direction: column;
  width: 270px;
  height: ${({ height }) => height};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 10px;
  cursor: pointer;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    max-height: 150px;
    min-height: 150px;
    width: 100%;
    border-radius: 6px 6px 0 0;
  }

  box-shadow: ${({ boxShadow }) => boxShadow};
`;

const Category = styled.div`
  font-size: 0.8rem;
  font-style: italic;
`;

const BoxModal = styled.div`
  display: flex;
  flex-direction: column;

  img {
    height: 200px;
  }
`;

const BoxBody = styled.div`
  height: 100%;
  padding: 8px 12px 10px 12px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  box-shadow: inset 0 0 0 1px var(--support-color);

  &:hover {
    box-shadow:
      inset 0 0 0 1px var(--support-color),
      0px 1px 10px #061a2b29;
  }
`;

const Summary = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Info = styled.div<IInfo>`
  display: flex;
  flex-direction: row;

  .label,
  .value {
    width: ${(props) => (props.width ? props.width : 'calc(100%/3)')};
    text-align: ${(props) => (props.align ? props.align : 'center')};
    margin-right: ${(props) => (props.width ? '10px' : '0')};
    font-size: 0.7rem;

    svg {
      color: var(--thirdy-color);
    }
  }

  .label {
    font-weight: bold;
  }
`;

const FooterWrapper = styled.footer`
  display: flex;
  padding: 0.5rem 0;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
`;

export { FooterWrapper, BoxCard, Category, BoxModal, BoxBody, Summary, Info };
