import React from 'react';
import { Layout } from 'antd';

import './styles.css';
import Sidebar from './SideBar';
import TopicMenu from './TopicMenu';
import NavBar from './NavBar';

type Props = {
  children?: React.ReactNode;
};

function Template({ children }: Props) {
  return (
    <Layout className="template">
      <Sidebar>
        <TopicMenu />
      </Sidebar>
      <Layout>
        <NavBar />
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default Template;
