import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Title from '../../Title';
import { Col, Divider, notification, Row, Tooltip } from 'antd';
import ProfileHeader from '../ProfileHeader';
import useLoading from '../../../core/hooks/useLoading';
import StartupService from '../../../services/StartupService';
import { useLocation, useNavigate } from 'react-router-dom';
import { BoxContainer, Card } from '../../../GlobalStyles';
import {
  IDiagnosticResult,
  IStartup,
  IValuationResult,
} from '../../../core/interfaces';
import Text from '../../Text';
import InformationBox from '../../../pages/client/StartupDetails/InformationBox';
import { maskMoney } from '../../../helpers/mask.helper';
import DiagnosticResult from '../../../pages/diagnosis/startup/DiagnosticResult';
import { useModal } from 'sunflower-antd';
import DiagnosticResultService from '../../../services/DiagnosticResultService';
import ValuationResultService from '../../../services/ValuationResultService';
import StatusEnum from '../../../core/enums/StatusEnum';
import GoBack from '../../GoBack/GoBack';

const linkStyles = {
  color: '#00B6FF',
  textDecoration: 'underline',
};
function ViewProfileStartup() {
  const { hideLoading, showLoading, loading } = useLoading();
  const params = useLocation();
  const navigate = useNavigate();
  const { id } = params.state;
  const [startup, setStartup] = useState<IStartup>();
  const diagnosticResultModal = useModal({
    defaultVisible: false,
  });

  // ultimo diagnostico respondido com status fechado
  const [diagnosticLast, setDiagnosticLast] = useState<IDiagnosticResult>();

  //diagnostico atual aberto
  const [diagnosticResult, setDiagnosticResult] = useState<IDiagnosticResult>();
  const [valuationResult, setValuationResult] = useState<IValuationResult>();

  const startupService = useMemo(() => StartupService.init(), []);
  const diagnosticResultService = useMemo(
    () => DiagnosticResultService.init(),
    [],
  );
  const valuationResultService = useMemo(
    () => ValuationResultService.init(),
    [],
  );

  const getData = React.useCallback(() => {
    const requests = [
      startupService.findById(Number(id)),
      diagnosticResultService.getByStartupAndStatus(
        Number(id),
        StatusEnum.Open,
      ),
      diagnosticResultService.getLastDiagnostic(Number(id)),
      valuationResultService.getLastValuation(Number(id)),
    ];

    showLoading();
    Promise.all(requests)
      .then(([startup, diagnostic, diagnosticLast, valuationLast]) => {
        setStartup(startup);
        setDiagnosticResult(diagnostic);
        setDiagnosticLast(diagnosticLast);
        setValuationResult(valuationLast);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar dados da startup',
          description: 'Tente novamente mais tarde',
        });
      })
      .finally(hideLoading);
  }, [id, startupService, diagnosticResultService, valuationResultService]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <BoxContainer>
      <DiagnosticResult
        show={diagnosticResultModal.visible}
        close={diagnosticResultModal.close}
        startup={startup}
        diagnostic={diagnosticLast}
      />
      <GoBack goBackFunction={() => navigate(-1)} enableBackground={true} />
      <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
        <Col span={24}>
          <ProfileHeader
            name={startup?.name}
            loading={loading}
            description={startup?.owner?.description}
            metaData={[
              {
                label: 'Aceleradora',
                value: (
                  <span style={linkStyles}>
                    {startup?.owner?.inviter?.name}
                  </span>
                ),
              },
              {
                label: 'Entrada',
                value: startup?.creation ?? '--',
              },
              {
                label: 'Última atualização',
                value: startup?.lastUpdated ?? '--',
              },
              {
                label: 'Status atual',
                value: startup?.active ? 'Ativa' : 'Inativa' ?? '--',
              },
              {
                label: 'E-mail',
                value: (
                  <span style={linkStyles}>
                    {startup?.owner?.email ?? '--'}
                  </span>
                ),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card boxShadow="0px 3px 6px #05233D29" padding="12px 22px">
            <Row
              style={{ marginBottom: '12px' }}
              justify="space-between"
              wrap={true}
              gutter={[12, 12]}
              align="middle"
            >
              <Col flex={1}>
                <Title size={20} tag="h2">
                  Informações de negócio
                </Title>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <Text margin="0 0 6px 0" weight={600}>
                  Tipo de negócio
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {startup?.businessTypes?.description}
                </Text>
              </Col>
              <Col span={12}>
                <Text margin="0 0 6px 0" weight={600}>
                  Modelo de negócio
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {startup?.businessModels?.description}
                </Text>
              </Col>
              <Col span={12}>
                <Text margin="0 0 6px 0" weight={600}>
                  O que a startup resolve?
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {startup?.problem}
                </Text>
              </Col>
              <Col span={12}>
                <Text margin="0 0 6px 0" weight={600}>
                  Como a startup resolve?
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {startup?.resolution}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <InformationBox
                title="Maturidade atual da Startup"
                text={
                  startup?.currentPhase?.description ??
                  'descrição não identificado'
                }
                value={startup?.currentPhase?.name ?? 'fase não identificado'}
                actions={[
                  {
                    text: 'Ver diagnóstico atual',
                    click: diagnosticResultModal.show,
                    disable: !startup?.currentPhase,
                  },
                  {
                    text: 'Ver lista de diagnósticos',
                    disable: !startup?.currentPhase,
                    click: () =>
                      navigate('/dashboard/startup/diagnostics/', {
                        state: {
                          id,
                        },
                      }),
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <InformationBox
                value={maskMoney(valuationResult?.preMoney || 0)}
                title="ValuationForm Pré-money atual da Startup"
                text="O Valuation Pré-Money é o
                indicador que informa quanto uma startup pode valer antes de começar
                a receber qualquer investimento, sem incluir investimento externo ou
                a última rodada de investimentos na avaliação."
                actions={[
                  {
                    click: () =>
                      navigate('/valuations/results', {
                        state: {
                          id: 2,
                          startup: id,
                        },
                      }),
                    text: 'Valuation atual',
                    disable: !valuationResult,
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </BoxContainer>
  );
}

export default ViewProfileStartup;
