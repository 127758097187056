import Text from '../Text';
import Title from '../Title';
import { isEmpty } from 'lodash';
import { Col, Empty, Row } from 'antd';
import { useNavigate } from 'react-router';
import ProgressStartup from '../ProgressStartup';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Card, PurpleButton } from '../../GlobalStyles';
import CourseProgressWrapper from './CourseProgressWrapper';
import ContentBase from '../../pages/templates/ContentBase';
import { notification } from '../../helpers/notification.helper';
import { useState, useEffect, useCallback, useMemo } from 'react';
import IRecommendedTrail, {
  IRecommendedCourse,
} from '../../core/interfaces/dashboard-startup/IRecommendedTrail';
import DashboardStartupService from '../../services/dashboard-startup/DashboardStartupService';
import { useModal } from 'sunflower-antd';
import ConfirmStartupJourneyReport from '../ConfirmStartupJourneyReport';
import { CoursesWrapper } from './styles';

type Props = {
  userId: number;
  showDetails?: boolean;
  selectCourse?: (course: IRecommendedCourse) => void;
  nameStartup?: string;
};

function StartupJourneyBase({
  userId,
  showDetails,
  selectCourse,
  nameStartup,
}: Props) {
  const PREVIOUS_PAGE = -1;
  const navigate = useNavigate();
  const modalReport = useModal({
    defaultVisible: false,
  });

  const dashboardStartupService = useMemo(
    () => DashboardStartupService.init(),
    [],
  );

  const [recommendedTrail, setRecommendedTrail] = useState<IRecommendedTrail[]>(
    [],
  );

  const getRecommendedTrail = useCallback(() => {
    dashboardStartupService
      .getRecommendedTrail(userId)
      .then((data) => setRecommendedTrail(data))
      .catch(() => notification.info('Erro ao listar cursos recomendados'));
  }, [dashboardStartupService, userId]);

  const goBack = () => navigate(PREVIOUS_PAGE);

  useEffect(() => {
    getRecommendedTrail();
  }, [getRecommendedTrail]);

  return (
    <ContentBase
      content={
        <>
          <Row gutter={[0, 8]} align={'middle'} justify="space-between">
            <Col>
              <Row gutter={12} align="middle">
                <Col>
                  <PurpleButton
                    type="primary"
                    size="small"
                    onClick={goBack}
                    style={{
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MdKeyboardArrowLeft display="flex" size={20} />
                  </PurpleButton>
                </Col>
                <Col>
                  <Title sizeXs={24} size={30}>
                    Jornada da startup {nameStartup}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col>
              <PurpleButton onClick={modalReport.show}>
                Gerar relatório
              </PurpleButton>
            </Col>
            <Col span={24}>
              <Card>
                <ProgressStartup
                  userId={userId}
                  boxShadow="none"
                  bordered={false}
                  showDetails={showDetails}
                >
                  {(activePhase) => {
                    const recommendedSearch = recommendedTrail.find(
                      (recommended) =>
                        recommended.phase.id === activePhase?.entityId,
                    );
                    const courses = recommendedSearch?.courses ?? [];

                    return (
                      <>
                        <Row gutter={[8, 8]} style={{ marginTop: '1rem' }}>
                          {isEmpty(courses) && (
                            <Col span={24} style={{ marginTop: '2rem' }}>
                              <Empty
                                description={
                                  <Text weight={700} size={20}>
                                    Cursos vazios
                                  </Text>
                                }
                              />
                            </Col>
                          )}
                        </Row>

                        <CoursesWrapper>
                          {courses.map((course) => (
                            <CourseProgressWrapper
                              title={course.name}
                              width={course.progress}
                              onClick={() =>
                                selectCourse && selectCourse(course)
                              }
                              key={`key_course_recommended_${course.id}`}
                            />
                          ))}
                        </CoursesWrapper>
                      </>
                    );
                  }}
                </ProgressStartup>
              </Card>
            </Col>
          </Row>

          <ConfirmStartupJourneyReport
            userId={userId}
            {...modalReport.modalProps}
          />
        </>
      }
    />
  );
}

export default StartupJourneyBase;
