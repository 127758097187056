import React, { useState } from 'react';
import { BoxContent, Content, Title, SubTitle, Header, Extra } from './style';
import { QuestionOutlined } from '@ant-design/icons';
import { ModalBody } from '../../GlobalStyles';
import GoBack from '../../components/GoBack/GoBack';

interface IParams {
  content: any;
  title?: string;
  extra?: any;
  modalContent?: any;
  questionIsVisible?: boolean;
  spacingFooterPage?: boolean;
  subtitle?: string;
  goBack?: boolean | false;
  path?: string;
}

function ContentBase({
  questionIsVisible = true,
  goBack = false,
  path,
  ...props
}: IParams) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const openBox = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <ModalBody
        title=""
        width={'600px'}
        open={isVisible}
        destroyOnClose={true}
        onCancel={openBox}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {props.modalContent}
      </ModalBody>
      <BoxContent
        style={{
          marginBottom: props.spacingFooterPage ? '70px' : '',
        }}
      >
        <Header>
          {goBack && (
            <GoBack
              path={path}
              style={{ marginRight: '10px' }}
              enableBackground={true}
            />
          )}
          {props.title && (
            <Title>
              {props.title}
              {questionIsVisible && (
                <div className="info">
                  <QuestionOutlined onClick={openBox} />
                </div>
              )}
            </Title>
          )}
          {props.extra && <Extra>{props.extra}</Extra>}
        </Header>
        {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
        <Content>{props.content}</Content>
      </BoxContent>
    </>
  );
}

export default ContentBase;
