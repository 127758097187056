import React, { useEffect, useState } from 'react';
import IValuation from '../../core/interfaces/models/IValuation';
import ScoreCard from './socre-card/ScoreCard';
import Berkus from './berkus/Berkus';
import WeightValuation from './weight-valuation/WeightValuation';
import Vc from './vc/Vc';
import Tab from '../../components/tab';
import { BoxContainer } from '../../GlobalStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import VentureCapitalView from './vc/VentureCapitalView';
import GoBack from '../../components/GoBack/GoBack';

function ValuationForm() {
  const [activeKey, setActiveKey] = useState('0');
  const INDEX_WCC = 4;
  const isActiveWcc = +activeKey === INDEX_WCC;
  const [data, setData] = useState<IValuation>();
  const location = useLocation();
  const navigate = useNavigate();
  const { initialValues } = location.state as any;
  const [formName, setFormName] = useState<string>('');

  const content = [
    {
      tab: 'Score',
      body: (
        <ScoreCard
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
          initialValues={initialValues}
          setFormName={setFormName}
          formReadOnly={true}
        />
      ),
    },
    {
      tab: 'Berkus',
      body: (
        <Berkus
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
          initialValues={initialValues}
          setFormName={setFormName}
          formReadOnly={true}
        />
      ),
    },
    {
      tab: 'Pesos',
      body: (
        <WeightValuation
          setFormName={setFormName}
          initialValues={initialValues}
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
          formReadOnly={true}
        />
      ),
    },
    {
      tab: 'Venture Capital',
      body: <VentureCapitalView />,
    },
  ];

  useEffect(() => {
    const dataScoreCard = [
      'valuation-form-startup-potential',
      'valuation-form-startup-innovation',
      'valuation-form-startup-entrepreneur',
      'valuation-form-risk-mart',
      'valuation-form-risk-innovation',
      'valuation-form-risk-entrepreneur',
      'valuation-form-financial',
    ];

    const dataBerkus = [
      'berkus-form-business-model',
      'berkus-form-prototype-available',
      'berkus-form-management-team',
      'berkus-form-risk-reduction',
      'berkus-form-existing-customers',
      'berkus-form-max-valuation',
    ];

    const dataValuationCapital = ['form-valuation-capital'];
    const dataWaccMultiples = ['form-wacc-multiples'];

    dataScoreCard
      .concat(dataBerkus)
      .concat(dataWaccMultiples)
      .concat(dataValuationCapital)
      .forEach((data) => sessionStorage.removeItem(data));
  }, []);

  return (
    <BoxContainer>
      <GoBack
        enableBackground={true}
        style={{
          margin: '0 0 10px 0',
        }}
        goBackFunction={() => {
          navigate(-1);
        }}
      />
      <Tab
        title="Valuation"
        content={content}
        activeKey={activeKey}
        changeActiveKey={(value) => setActiveKey(value)}
        backgroundColor={isActiveWcc ? 'transparent' : '#fff'}
      />
    </BoxContainer>
  );
}

export default ValuationForm;
