import React, { useEffect, useState } from 'react';
import Profiles from '../profiles';
import StartupPersonalForm from './PersonalForm';
import StartupEntrepreneurForm from './EntrepreneurForm';
import { useAuth } from '../../../../core/hooks/auth';
import { IUser } from '../../../../core/interfaces';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setFormName: (formName: string) => void;
}

function StartupForm(props: IParams) {
  const [initialValues, setInitialValues] = useState<any>({});
  const [formData, setFormData] = useState<IUser>();
  const { logged } = useAuth();
  const subSteps = [
    {
      subTitle:
        'Precisamos saber algumas informações a respeito de você como empreendedor',
      description: 'Dados do empreendedor',
      content: (
        <StartupPersonalForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setFormName={props.setFormName}
          initialValues={initialValues}
          setFormData={setFormData}
        />
      ),
    },
    {
      subTitle:
        'Agora, precisamos saber algumas informações a respeito da startup',
      description: 'Dados da startup',
      content: (
        <StartupEntrepreneurForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setButtonState={props.setButtonState}
          current={props.current}
          setCurrent={props.setCurrent}
          setFormName={props.setFormName}
          initialValues={formData}
        />
      ),
    },
  ];

  useEffect(() => {
    setInitialValues({
      entityId: logged?.entityId,
      name: logged?.name,
      email: logged?.email,
    });
  }, []);

  return (
    <Profiles
      title="Startup"
      subTitle={subSteps[props.subStep].subTitle}
      description={subSteps[props.subStep].description}
      current={props.current}
      setCurrent={props.setCurrent}
      setFormName={props.setFormName}
      subStep={props.subStep}
      setSubStep={props.setSubStep}
      setButtonState={props.setButtonState}
      content={subSteps[props.subStep].content}
    />
  );
}

export default StartupForm;
