import styled from 'styled-components';

interface IParams {
  src: string;
}

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div<IParams>`
  width: 100%;
  height: 200px;
  background: ${(props) => `url(${props.src}) top center no-repeat`};
  background-size: cover;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  margin: 10px 0;
`;

const Description = styled.div`
  text-align: justify;
`;

const BoxVideo = styled.div`
  margin: 10px 0;
`;

export { BoxContent, BoxVideo, Description, Header, Title };
