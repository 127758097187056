import React from 'react';
import Title from '../../components/Title';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function MethodologyInfo() {
  return (
    <BoxInfo
      title="Metodologia"
      image={image}
      description={
        <>
          <Title margin="0 0 10px 0">Regras sobre a metodologia</Title>
          <p>
            <b>1 -</b> Para o cadastro da sua metodologia, é necessário que se
            tenha em mente que{' '}
            <b>apenas uma metodologia pode ficar ativa por vez</b> e que ao
            ativar outra metodologia um novo diagnóstico deve ser gerado para
            comtemplar os novos métodos.
          </p>
          <p>
            <b>OBS:</b> isso não invalidará o desenvolvimento de startups que já
            tenham utilizado o diagnóstico e metodologia anteriores.
          </p>
          <p>
            <b>2 -</b> Para cada metodologia é necessário ao mínimo 4 fases. Ao
            cadastrar um passo na primeira fase, <b>todas as outras fases</b>{' '}
            deverão ter ao menos um passo.
          </p>
          <p>
            <b>3-</b> A ordem do cadastro de fases e pilares importa para
            organizar sua metodologia.
          </p>
        </>
      }
    />
  );
}

export default MethodologyInfo;
