import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import INextMentorship from '../../core/interfaces/startup-mentoring/INextMentorship';

class StartupMentoringService {
  private requestMapping = 'startup-mentoring';

  public static init(): StartupMentoringService {
    return new StartupMentoringService();
  }

  public getTheNextMentorship(): Promise<INextMentorship | null> {
    return api
      .get(this.requestMapping)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default StartupMentoringService;
