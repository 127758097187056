import React, { useEffect } from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxContainer, BoxWhite } from '../../../GlobalStyles';
import { CardInvestment, Item } from './style';
import image from '../../../assets/icons/actions-icons/ic-investment-round.svg';
import { Divider, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import IInvestmentRound from '../../../core/interfaces/IInvestmentRound';
import InvestmentRoundService from '../../../services/InvestmentRoundService';
import {
  convertDateToString,
  countDaysBetweenDateNowAndDate,
} from '../../../helpers/dates.helper';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import { useAuth } from '../../../core/hooks/auth';

function InvestmentRoundList() {
  const navigate = useNavigate();
  const [investmentRoundList, setInvestmentRoundList] = React.useState<
    IInvestmentRound[]
  >([]);
  const investmentRoundService = InvestmentRoundService.init();
  const { loading, showLoading, hideLoading } = useLoading();
  const { logged } = useAuth();
  const id = logged?.entityId;

  const getInvestmentRoundList = async () => {
    showLoading();
    investmentRoundService
      .getAllInitialized()
      .then((response) => {
        setInvestmentRoundList(response);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar rodadas de investimento',
        });
      })
      .finally(hideLoading);
  };
  const getItem = (
    label: string,
    value: string,
    color?: string,
    weight?: boolean,
  ) => {
    return (
      <Item>
        <Text weight={700}>{label}</Text>
        <Text
          color={color ? color : 'var(--support-color)'}
          weight={weight ? 700 : 0}
        >
          {value}
        </Text>
      </Item>
    );
  };

  const openInvestmentRoundDetails = (id: number) => {
    navigate('/investment-round/details', {
      state: {
        entityId: id,
        idStartup: id,
        type: 'startup',
      },
    });
  };

  useEffect(() => {
    getInvestmentRoundList();
  }, []);

  return (
    <BoxContainer>
      <Title>Rodada de investimento</Title>
      <Loading spinning={loading} width={200} height={200}>
        <BoxWhite>
          <BoxContainer
            flexDirection="row"
            flexWrap="wrap"
            noPadding={true}
            gap={'10px'}
          >
            <OnEmptyOrList list={investmentRoundList}>
              {investmentRoundList.map((item) => (
                <CardInvestment
                  key={item.entityId}
                  onClick={() =>
                    openInvestmentRoundDetails(item?.entityId as number)
                  }
                >
                  <img alt="" src={image} />
                  <Text
                    size={20}
                    title={item.name}
                    weight={700}
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text weight={700} color={'var(--thirdy-color)'}>
                    {item.investmentAmount?.description}
                  </Text>
                  {getItem(
                    'Investidor responsável',
                    item.investor?.user?.name as string,
                  )}
                  {getItem(
                    'Fase de investimento',
                    item.phaseInvestment?.description as string,
                  )}
                  <Divider
                    style={{
                      backgroundColor: 'var(--support-color)',
                      margin: '5px',
                    }}
                  />
                  <BoxContainer
                    flexDirection={'row'}
                    noPadding={true}
                    justifyContent={'space-evenly'}
                    width={'100%'}
                  >
                    {getItem('Início', convertDateToString(item.startDate))}
                    {getItem('Término', convertDateToString(item.endDate))}
                  </BoxContainer>
                  <BoxContainer
                    flexDirection={'row'}
                    noPadding={true}
                    justifyContent={'space-evenly'}
                    width={'100%'}
                  >
                    {getItem(
                      'Dias restantes',
                      String(countDaysBetweenDateNowAndDate(item.endDate)),
                      'var(--thirdy-color)',
                      true,
                    )}
                  </BoxContainer>
                </CardInvestment>
              ))}
            </OnEmptyOrList>
          </BoxContainer>
        </BoxWhite>
      </Loading>
    </BoxContainer>
  );
}

export default InvestmentRoundList;
