import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Filter = styled.div`
  position: absolute;
  right: 0;
`;

const ItemCard = styled.div`
  display: flex;
  min-width: 720px;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  min-height: 50px;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  cursor: pointer;

  & .image {
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  & .column {
    display: flex;
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 90px;
  min-width: 390px;
  width: 400px;
  border: 1px solid #9aa4a8;
  border-radius: 6px;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;
const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;
const CardContent = styled.div`
  height: 100%;
  width: calc(100% - 90px);
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;

  margin-bottom: 20px;
`;

export {
  Header,
  Filter,
  ItemCard,
  CardContainer,
  CardImage,
  CardContent,
  Container,
};
