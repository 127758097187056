import React from 'react';
import { BoxBody, BoxCard, FooterWrapper, Summary } from './style';
import Title from '../Title';
import ICourseShowCase from '../../core/interfaces/course/ICourseShowCase';
import defaultImg from '../../assets/images/image-default.png';
import Text from '../Text';
import { maskMoney } from '../../helpers/mask.helper';

interface Props extends ICourseShowCase {
  click?: () => void;
  padding?: string;
  height?: string;
  borderColor?: string;
  boxShadow?: string;
}

function CardCourse(props: Props) {
  return (
    <BoxCard
      onClick={props.click}
      padding={props.padding}
      height={props.height}
      boxShadow={props.boxShadow}
      borderColor={props.borderColor}
    >
      <img alt={props.name} src={props.coverPicture ?? defaultImg} />
      <BoxBody>
        <Title lineHeight={1.3} size={18}>
          {props.name}
        </Title>
        <Text size={14} weight={700} color="rgb(150 0 150 / 85%)">
          {props.categoryName}
        </Text>
        <Summary size={14}>{props.description}</Summary>
        <FooterWrapper>
          <div>
            <Title size={14}>Nº aulas</Title>
            <Text size={12}>{props.lessonQuantity}</Text>
          </div>
          <div>
            <Title size={14}>Nº exercícios</Title>
            <Text size={12}>{props.amountExercises}</Text>
          </div>
          <div>
            <Title size={14}>Duração</Title>
            <Text size={12}>{props.duration}h</Text>
          </div>
        </FooterWrapper>
        <Text size={14} weight={700} color="rgb(150 0 150 / 85%)">
          {maskMoney(props.price)}
        </Text>
      </BoxBody>
    </BoxCard>
  );
}

export default CardCourse;
