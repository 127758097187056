import {
  Avatar,
  Col,
  Divider,
  Row,
  Space,
  Tag,
  Typography,
  notification,
} from 'antd';
import { BoxContainer, BoxWhite, PurpleButton } from '../../../../GlobalStyles';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { HighlightCard } from '../../../../components/HighlightCard';
import { InvestmentLargeCard } from '../../../../components/InvestmentLargeCard';
import InvestmentRoundService from '../../../../services/InvestmentRoundService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import IInvestmentRound from '../../../../core/interfaces/IInvestmentRound';
import IInvestmentRoundStartup from '../../../../core/interfaces/IInvestmentRoundStartup';
import { IStartup } from '../../../../core/interfaces/IStartup';
import useLoading from '../../../../core/hooks/useLoading';
import AlertMessage from '../../../../components/Alert/AlertMessage';
import {
  convertDateToString,
  countDaysBetweenDateNowAndDate,
} from '../../../../helpers/dates.helper';
import { maskMoney } from '../../../../helpers/mask.helper';
import { isEmpty } from '../../../../helpers/array.helper';
import CardList from '../../../../components/EntityList/CardList';

export default function InvestmentRoundDetails() {
  const [investmentRound, setInvestmentRound] = useState<IInvestmentRound>(
    {} as IInvestmentRound,
  );
  const [investmentRoundStartup, setInvestmentRoundStartup] = useState<
    IInvestmentRoundStartup[]
  >([]);
  const [startupList, setStartupList] = useState<IStartup[]>([]);
  const [showAlert, setShowAlert] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { loading, hideLoading, showLoading } = useLoading();

  const investmentRoundService = InvestmentRoundService.init();

  async function getInvestmentRound() {
    investmentRoundService
      .findById(state.entityId)
      .then((response) => {
        setInvestmentRound(response);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar rodadas de investimento',
        });
      });
  }

  async function getInvestmentRoundStartup() {
    if (state.type === 'startup') {
      investmentRoundService
        .getAllInvestmentRoundStartupByStartupIdAndInvestmentRoundId(
          state.idStartup,
          state.entityId,
        )
        .then((response) => {
          setInvestmentRoundStartup(response);
        })
        .catch(() => {
          notification.error({
            message: 'Erro ao buscar rodadas de investimento',
          });
        });
    } else {
      investmentRoundService
        .getAllInvestmentRoundStartupByInvestmentRoundId(state.entityId)
        .then((response) => {
          setInvestmentRoundStartup(response);
        })
        .catch(() => {
          notification.error({
            message: 'Erro ao buscar rodadas de investimento',
          });
        });
    }
  }

  function finishInvestment() {
    showLoading();
    investmentRoundService
      .finishInvestmentRound(state.entityId)
      .then(() => {
        notification.success({
          message: 'Rodada de investimento finalizada com sucesso',
        });
        setShowAlert(false);
        getInvestmentRound();
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao finalizar rodada de investimento',
        });
      })
      .finally(hideLoading);
  }

  function goToDashboard() {
    state.type === 'startup'
      ? navigate('/investment-round/startup')
      : navigate('/investment-round/investor');
  }

  function submitPitch() {
    navigate('/investment-round/details/submit-pitch', {
      state: { entityId: state.entityId },
    });
  }

  function goProfile(id: number) {
    navigate('/investment-round/details/submitted-startup', {
      state: {
        startupId: id,
        investmentRoundId: state.entityId,
      },
    });
  }

  function getLastText(text: string) {
    if (!text) return '';
    const str = text.split(' ').slice(2).join(' ');

    const number = str
      .replace(/milhão/g, '000000')
      .replace(/milhões/g, '000000')
      .replace(/mil/g, '000')
      .replace(/e/g, '');

    const numberWithoutSpecialCharacters = number.replace(/[^0-9]/g, '');

    return maskMoney(Number(numberWithoutSpecialCharacters));
  }

  useEffect(() => {
    getInvestmentRound();
    getInvestmentRoundStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (investmentRoundStartup.length > 0) {
      const items: IStartup[] = [];
      investmentRoundStartup.forEach((item) => {
        if (item.startup) items.push(item.startup);
      });

      setStartupList(items);
    }
  }, [investmentRoundStartup]);

  return (
    <BoxContainer>
      <AlertMessage
        message="A rodada de investimento está em andamento"
        description="Aguarde o término da rodada para visualizar os detalhes"
        visible={showAlert}
        onCancel={() => setShowAlert(false)}
        textCancelButton={'Cancelar'}
        textOkButton={'Ok'}
        onOk={finishInvestment}
      />

      {/* Header */}
      <Row gutter={16} align="middle">
        <Col>
          <GoBack enableBackground goBackFunction={goToDashboard} />
        </Col>

        <Col>
          <Title>Detalhes da rodada de investimento</Title>
        </Col>
      </Row>

      {/* Content */}
      <BoxWhite>
        <Space direction="vertical" size="middle" style={{ padding: '0 6px' }}>
          <Title size={22}>{investmentRound?.name}</Title>

          {/* Tese de investimento */}
          <Row gutter={16}>
            <Col span={12}>
              <Space direction="vertical">
                <Title size={18}>Tese de investimento</Title>
                <Text>{investmentRound?.thesis}</Text>
              </Space>
            </Col>

            <Col span={6}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <HighlightCard
                  title="Tempo restante"
                  content={
                    countDaysBetweenDateNowAndDate(investmentRound.endDate) <= 0
                      ? 'Finalizada'
                      : countDaysBetweenDateNowAndDate(
                          investmentRound.endDate,
                        ).toString()
                  }
                />
                <HighlightCard
                  title="Data de início"
                  content={convertDateToString(investmentRound.startDate)}
                />
                <HighlightCard
                  title="Data de término"
                  content={convertDateToString(investmentRound.endDate)}
                />
              </Space>
            </Col>

            <Col span={6}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <HighlightCard
                  title="Fase do investimento"
                  content={investmentRound.phaseInvestment?.description ?? '--'}
                />
                <InvestmentLargeCard
                  title="Capital de investimento"
                  amount={getLastText(
                    investmentRound?.investmentAmount?.description as string,
                  )}
                />
              </Space>
            </Col>
          </Row>

          {state.type === 'startup' && (
            <>
              {/* Sobre o investidor */}
              <Space direction="vertical">
                <Title size={22}>Sobre o investidor</Title>

                <Space size="middle">
                  <Avatar
                    size={48}
                    src="https://www.w3schools.com/howto/img_avatar.png"
                  />
                  <Text weight={700}>
                    {investmentRound.investor?.user?.name}
                  </Text>
                </Space>

                <Text>
                  Doutor em Engenharia de Produção pela Universidade Federal de
                  Santa Catarina (UFSC), com experiência em gestão de projetos
                  de inovação e empreendedorismo, com ênfase em gestão de
                  negócios e empreendedorismo. Atualmente é professor do curso
                  de Engenharia de Produção da Universidade Federal de Santa
                  Catarina (UFSC) e consultor de empresas de base tecnológica.
                </Text>
              </Space>

              {/* Modelo de negócio */}
              <Row gutter={16}>
                <Col span={6}>
                  <Space direction="vertical">
                    <Text weight={700}>Modelo de negócio</Text>
                    <Typography.Text type="secondary">
                      {investmentRound.investor?.businessModels?.description}
                    </Typography.Text>
                  </Space>
                </Col>

                <Col span={6}>
                  <Space direction="vertical">
                    <Text weight={700}>Fases do investimento</Text>

                    <Space wrap>
                      {investmentRound?.investor?.phaseInvestiment?.map(
                        (item) => {
                          return (
                            <Tag
                              key={item.entityId}
                              style={{ borderRadius: 8 }}
                            >
                              {item.description}
                            </Tag>
                          );
                        },
                      )}
                    </Space>
                  </Space>
                </Col>

                <Col span={12}>
                  <Space direction="vertical">
                    <Text weight={700}>Áreas de investimento</Text>

                    <Space wrap>
                      {investmentRound.investor?.businessTypes?.map((item) => {
                        return (
                          <Tag key={item.entityId} style={{ borderRadius: 8 }}>
                            {item.description}
                          </Tag>
                        );
                      })}
                    </Space>
                  </Space>
                </Col>
              </Row>

              <Divider />

              <Space
                direction="vertical"
                align="center"
                size="large"
                style={{ width: '100%' }}
              >
                <Text
                  align={'center'}
                  color={'var(--thirdy-color)'}
                  style={{ fontStyle: 'italic' }}
                >
                  *Fique atento com as negociações com o investidor e com os
                  cálculos que fazem sentido do percentual de sua startup e do
                  valor que será investido por essa porcentagem.
                </Text>

                {isEmpty(investmentRoundStartup) ? (
                  <PurpleButton type={'primary'} onClick={submitPitch}>
                    Submeter Pitch
                  </PurpleButton>
                ) : (
                  <>
                    <Title size={18} align={'center'}>
                      Pitch Submetido
                    </Title>
                    <Text align={'center'}>
                      Aguarde a aprovação do investidor para prosseguir com o
                      investimento
                    </Text>
                  </>
                )}
              </Space>
            </>
          )}

          {state.type !== 'startup' && (
            <>
              <Title size={18}>Startups cadastradas</Title>
              <CardList
                entity={startupList}
                goBackPath={(id) => goProfile(id)}
              />

              {investmentRound?.active && (
                <PurpleButton
                  type={'primary'}
                  onClick={() => setShowAlert(true)}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    padding: '0 50px',
                  }}
                  loading={loading}
                >
                  Finalizar investimento
                </PurpleButton>
              )}
            </>
          )}
        </Space>
      </BoxWhite>
    </BoxContainer>
  );
}
