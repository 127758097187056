import React from 'react';
import { useAuth } from '../../../core/hooks/auth';
import { BoxContainer } from '../../../GlobalStyles';
import ClientProfile from '../../../pages/client/Profile';
import InvestorProfile from '../../../pages/investor/InvestorProfile';
import MentorProfile from '../../../pages/Mentor/MentorProfile';
import StartupProfile from '../../../pages/startup/Profile';

function MyProfile() {
  const { isStartup, isInvestor, isClient, isMentor } = useAuth();

  const components = [
    {
      component: StartupProfile,
      allowed: isStartup(),
    },
    {
      component: ClientProfile,
      allowed: isClient(),
    },
    {
      component: InvestorProfile,
      allowed: isInvestor(),
    },
    {
      component: MentorProfile,
      allowed: isMentor(),
    },
  ];

  return (
    <BoxContainer>
      {components.map(({ allowed, component: Component }, index) => {
        return allowed && <Component key={`component_key_${index}`} />;
      })}
    </BoxContainer>
  );
}

export default MyProfile;
