import React from 'react';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { Col, Row } from 'antd';
import { Card } from '../styles';
import Divider from '../../../../components/Divider';
import ValueDriversChart from './ValueDriversChart';
import { IValueDrive } from '../../../../core/interfaces/IValueDrive';
import { maskMoney, maskPercent } from '../../../../helpers/mask.helper';

interface IParams {
  preMoney?: number;
  posMoney?: number;

  investmentAmount: number;
  equity: number;
  valueDrives: IValueDrive[];
}

function BerkusSection(props: IParams) {
  const investments = [
    {
      text: 'Pre-money Valuation',
      value: maskMoney(props.preMoney || 0),
    },
    {
      text: 'Investimento',
      value: maskMoney(props.investmentAmount),
    },
    {
      text: 'Post-money Valuation',
      value: maskMoney(props.posMoney || 0),
    },
    {
      text: 'Equity',
      value: maskPercent(props.equity),
    },
  ];

  return (
    <section>
      <Title size={25}>Berkus</Title>
      <Text size={17}>
        Criado por Dave Berkus, em 1990, o método Berkus tem como objetivo
        atender startups em suas fases iniciais, early stage, empresas que não
        têm faturamento consolidado, porém avalia bens intangíveis desses novos
        negócios. Para isso, há a utilização de 5 elementos básicos para a
        avaliação dessas empresas: valor da ideia (modelo de negócio), protótipo
        ou tecnologia, gestão do time ou habilidades, relações estratégicas
        (reducing risks market) e lançamento ou primeiras vendas.
      </Text>

      <div style={{ margin: '0 auto' }}>
        <Row
          gutter={[12, 12]}
          style={{ marginBottom: '40px', marginTop: '40px' }}
        >
          <Col xs={24} sm={24} md={13}>
            <Card height="100%">
              {investments.map(({ text, value }) => {
                const isEquity = /equity/gi.test(text);
                return (
                  <Row
                    justify="space-between"
                    style={{ margin: '5px 0' }}
                    key={`investiment_key_${text}`}
                  >
                    {isEquity && (
                      <Col span={24}>
                        <Divider width="30%" margin="6px 0 6px auto" />
                      </Col>
                    )}
                    <Col>
                      <Text weight={700}>{text}</Text>
                    </Col>
                    <Col>
                      <Text weight={isEquity ? 700 : 400} color="#960096">
                        {value}
                      </Text>
                    </Col>
                  </Row>
                );
              })}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={11}>
            <Card height="100%">
              <Text size={12} margin="0 0 4px 0">
                <strong data-bold={true}>Pre-money Valuation</strong> é o
                resultado calculado baseado na distribuição das respostas
                preenchidas pela startup.
              </Text>
              <Text size={12} margin="0 0 4px 0">
                <strong data-bold={true}>Investimento</strong> é o valor
                preenchido no campo {'"Investimento"'} que acompanha toda a
                sequência do valuation.
              </Text>
              <Text size={12} margin="0 0 4px 0">
                <strong data-bold={true}>Post-money Valuation</strong> é a soma
                do pre-money com o investimento.
              </Text>
              <Text size={12}>
                <strong data-bold={true}>Equity</strong> é o valor do
                investimento dividido pelo post-money e multiplicado por 100.
              </Text>
            </Card>
          </Col>
        </Row>
      </div>
      <Title size={19} margin="0 0 8px 0">
        Value drivers
      </Title>
      <ValueDriversChart valueDrives={props.valueDrives} />
    </section>
  );
}

export default BerkusSection;
