import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function ValuationInfo() {
  return (
    <BoxInfo
      title="Calculando seu valuation"
      image={image}
      description={
        <>
          <p>
            O objetivo do valuation do Innovation Studio é estimar o valor real
            de uma empresa. Analisaremos o potencial da sua startup, a análise
            de riscos e o financeiro do seu negócio, onde cada uma dessas fases
            será avaliada de acordo com as características de mercado, inovação
            e empreendedorismo.
          </p>
          <p>
            O método se baseia numa análise mais qualitativa do negócio e, é por
            meio de informações concedidas pelo próprio empreendedor, que o
            resultado dependerá diretamente daquele que irá preencher as
            questões do negócio a ser avaliado. Com a finalidade de aporte para
            uma startup, o investidor precisa conhecer onde vai investir, além
            disso, é muito provável que faça outras pesquisas sobre o negócio
            antes de deferir a proposta de investimento.
          </p>
        </>
      }
    />
  );
}

export default ValuationInfo;
