import styled, { css } from 'styled-components';

const BoxTitle = styled.div<{
  color?: string;
  size?: number;
  align?: string;
  weight?: number;
  margin?: string;
  lineHeight?: number;
  lineClamp?: number;
  sizeXs?: number;
}>`
  & > * {
    text-align: ${({ align }) => align};
    line-height: ${({ lineHeight }) => lineHeight};
    margin: ${({ margin }) => margin || 0};
    font-weight: ${({ weight }) => weight || 700};
    font-size: ${({ size }) => (size ? `${size}px` : '30px')};
    color: ${({ color }) => color || 'var(--primary-color)'};

    ${({ lineClamp }) =>
      lineClamp &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
      `}

    ${({ sizeXs }) =>
      sizeXs &&
      css`
        @media (max-width: 576px) {
          font-size: ${sizeXs}px;
        }
      `}
  }
`;

export { BoxTitle };
