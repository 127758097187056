import React, { useEffect, useState } from 'react';
import ScheduleCalendar from '../../../../components/ScheduleCalendar/ScheduleCalendar';
import { BoxContainer } from '../../../../GlobalStyles';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import { BoxTitle } from '../../../../components/Profiles/style';
import { useNavigate, useParams } from 'react-router-dom';
import ScheduleService from '../../../../services/ScheduleService';
import { ISchedule } from '../../../../core/interfaces/ISchedule';
import { Card } from 'antd';
import MentoringService from '../../../../services/MentoringService';
import { IMentoring } from '../../../../core/interfaces/IMentoring';
import { convertNumberToCurrencyMaskInput } from '../../../../helpers/convertion.helper';
import StartupCalendar from './StartupCalendar';

function ScheduleMentor() {
  const navigate = useNavigate();
  const params = useParams();
  const scheduleService = ScheduleService.init();
  const mentoringService = MentoringService.init();
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [mentoring, setMentoring] = useState<IMentoring>();

  const getSchedule = async () => {
    scheduleService
      .getAllByMentor(Number(params.mentorId))
      .then((response) => {
        setSchedule(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMentoring = async () => {
    mentoringService
      .getMentoringById(Number(params.mentoringId))
      .then((response) => {
        setMentoring(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSchedule();
    getMentoring();
  }, []);

  return (
    <BoxContainer>
      <BoxTitle>
        <GoBack
          enableBackground={true}
          goBackFunction={() => {
            navigate(-1);
          }}
        />
        <Title size={20} margin="0 0 0 10px">
          Agendar Mentoria
        </Title>
      </BoxTitle>
      <Card
        style={{
          justifyContent: 'space-between',
        }}
      >
        <div>
          Mentoria selecionada: <strong>{mentoring?.title}</strong>
        </div>
        <div> {convertNumberToCurrencyMaskInput(mentoring?.priceHour)}</div>
      </Card>
      <StartupCalendar
        schedule={schedule}
        legend={[
          {
            className: 'today',
            label: 'Dia Atual',
          },
          {
            className: 'scheduled',
            label: 'Disponível',
          },
        ]}
      />
    </BoxContainer>
  );
}
export default ScheduleMentor;
