import { MdAutoGraph } from 'react-icons/md';
import { Content, Description, InvestmentLargeCardContainer } from './styles';

interface InvestmentLargeCardProps {
  title: string;
  amount: string;
}

export function InvestmentLargeCard(props: InvestmentLargeCardProps) {
  return (
    <InvestmentLargeCardContainer>
      <Description>{props.title}</Description>
      <Content>{props.amount}</Content>

      <MdAutoGraph className="icon" />
    </InvestmentLargeCardContainer>
  );
}
