import { Col, Row, Spin } from 'antd';
import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IPreMoneyDashboardStartup from '../../../../core/interfaces/dashboard-startup/IPreMoneyDashboardStartup';
import { Card, PurpleButton } from '../../../../GlobalStyles';
import { maskMoney } from '../../../../helpers/mask.helper';
import { notification } from '../../../../helpers/notification.helper';
import DashboardStartupService from '../../../../services/dashboard-startup/DashboardStartupService';

function Valuation() {
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();
  const dashboardStartup = useMemo(() => DashboardStartupService.init(), []);

  const [preMoney, setPreMoney] = useState<IPreMoneyDashboardStartup>({
    preMoney: 0,
    id: 0,
  });

  const getPreMoney = useCallback(() => {
    showLoading();
    dashboardStartup
      .getPreMoney(Number(logged?.entityId))
      .then((data) => data && setPreMoney(data))
      .catch(() => notification.info('Erro ao recuperar pre money'))
      .finally(hideLoading);
  }, [logged, hideLoading, showLoading, dashboardStartup]);

  const seeReview = () => {
    const state = {
      id: preMoney.id,
    };
    navigate('/valuations/results', { state });
  };

  useEffect(() => {
    getPreMoney();
  }, [getPreMoney]);

  return (
    <Spin spinning={loading} delay={300}>
      <Card>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Title align="center" size={22} lineHeight={1.3}>
              Valuation Pré-money
            </Title>
          </Col>
          <Col span={24}>
            <Text
              size={30}
              weight={700}
              lineHeight={1.3}
              color="#960096"
              align="center"
              margin="0 0 8px 0"
            >
              {maskMoney(preMoney.preMoney)}
            </Text>
          </Col>
          <Col span={24}>
            <PurpleButton
              type="primary"
              onClick={seeReview}
              style={{ margin: '0 auto', display: 'block' }}
            >
              Ver valuation
            </PurpleButton>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
}

export default Valuation;
