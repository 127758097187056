import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { BoxTab, ModalContent, Title } from './style';
import { ModalBody } from '../../GlobalStyles';

interface ITab {
  content: any;
  ask?: boolean;
  setAsk?: (ask: boolean) => void;
  formName?: string;
  title?: string;
  titlePosition?: string;
  fontSize?: string;
  border?: boolean;
  tabKey?: string;
  setTabKey?: (key: string) => void;
  setNext?: (next: string) => void;
  next?: string;
}

function TabGray(props: ITab) {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState<string>('0');
  const [oldKey, setOldKey] = useState<string>('0');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const titleItem = <Title> {props.title} </Title>;
  const positionSlot = [
    {
      left: titleItem,
    },
    {
      right: titleItem,
    },
  ];

  const showModal = () => {
    setModalVisible(!modalVisible);
    props.setAsk && props.setAsk(false);
  };

  const handleSave = () => {
    setModalVisible(false);
    setTabKey((Number(tabKey) + 1).toString());
  };

  const onChange = (key: string) => {
    if (!props.ask) {
      setTabKey(key);
    } else {
      showModal();
      setOldKey(key);
    }

    props.setNext && props.setNext(key);
  };

  const withoutSave = () => {
    setTabKey(oldKey);
    showModal();
  };

  const extraContent = () => {
    const position =
      props.titlePosition !== undefined && props.titlePosition === 'right'
        ? 1
        : 0;

    return props.title !== undefined ? positionSlot[position] : {};
  };
  useEffect(() => {
    if (props.tabKey !== undefined) {
      setTabKey(props.tabKey);
    }
  }, [props.tabKey]);

  return (
    <>
      <ModalBody
        title=""
        width={550}
        open={modalVisible}
        closable={true}
        destroyOnClose={true}
        onCancel={showModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ModalContent>
          <div className="title">Salvar antes de sair?</div>
          <div className="text">
            Deseja salvar antes de avançar para a próxima seção?
            <p>
              Avançar sem salvar apagará o progresso das questões da sua seção
              atual.
            </p>
          </div>
          <Button
            type="primary"
            form={props.formName}
            key="submit"
            htmlType="submit"
            onClick={handleSave}
          >
            Salvar e avançar
          </Button>
          <Button type="default" onClick={withoutSave}>
            Avançar sem salvar
          </Button>
        </ModalContent>
      </ModalBody>
      <BoxTab fontSize={props.fontSize} border={props.border}>
        <Tabs
          type="card"
          defaultActiveKey="0"
          activeKey={tabKey}
          destroyInactiveTabPane={true}
          onChange={onChange}
          tabBarExtraContent={extraContent()}
        >
          {props.content.map((item: any, index: any) => {
            return (
              <TabPane tab={item.tab} key={index}>
                {item.body}
              </TabPane>
            );
          })}
        </Tabs>
      </BoxTab>
    </>
  );
}

export default TabGray;
