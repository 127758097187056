import React, { useMemo, useState, useEffect } from 'react';
import { BodyWrapper, ProgressBar, Wrapper } from './styles';
import defaultImg from '../../../../assets/images/image-default.png';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import useLoading from '../../../../core/hooks/useLoading';
import FileService from '../../../../services/FileService';
import { Spin } from 'antd';

interface Props {
  title: string;
  progress: number;
  description: string;
  pictureName: string | null;
  click: () => void;
}

function CardInProgress({
  title,
  progress,
  description,
  pictureName,
  click,
}: Props) {
  const { loading, hideLoading, showLoading } = useLoading();

  const fileService = React.useMemo(() => FileService.init(), []);
  const [filePath, setFilePath] = useState<string>(defaultImg);

  const image = useMemo(() => pictureName, [pictureName]);

  const getImage = (srcImage: string | null) => {
    if (srcImage) {
      showLoading();
      fileService
        .get(srcImage)
        .then((file) => {
          setFilePath(file);
        })
        .catch(() => setFilePath(defaultImg))
        .finally(hideLoading);
    } else {
      setFilePath(defaultImg);
    }
  };

  useEffect(() => {
    getImage(image);
  }, [image]);

  return (
    <Spin spinning={loading}>
      <Wrapper onClick={click}>
        <img src={filePath} alt="" width={90} height={115} />
        <BodyWrapper>
          <Title lineClamp={1} color="#05233D" size={16}>
            {title}
          </Title>
          <Text size={14} lineClamp={1}>
            {description}
          </Text>
          <ProgressBar progress={progress} />
        </BodyWrapper>
      </Wrapper>
    </Spin>
  );
}

export default CardInProgress;
