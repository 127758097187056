import React from 'react';
import RegisterForm from '../../../components/form/register';
import BaseScreen from '..';
import { RegisterBox } from '../style';
import { Link } from 'react-router-dom';

function Register() {
  return (
    <BaseScreen
      title="Bem vindo ao Innovation!"
      content={
        <>
          <RegisterForm />
          <RegisterBox>
            <Link to="/">Voltar para login</Link>
          </RegisterBox>
        </>
      }
    />
  );
}

export default Register;
