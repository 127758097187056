import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Tabs } from 'antd';
import { BoxContent, Title } from './style';
import ConfirmChangeTab from './ConfirmChangeTab/index';
import { IModalRef } from '../../core/interfaces/IModalRef';

interface ITab {
  activeKey?: string;
  changeActiveKey?: (key: string) => void;
  content: any;
  ask?: boolean;
  setAsk?: (ask: boolean) => void;
  formName?: string;
  title?: string;
  titlePosition?: string;
  fontSize?: string;
  border?: boolean;
  backgroundColor?: string;
  onSaved?: () => void;
}

const Tab = forwardRef<IModalRef, ITab>((props, ref) => {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState<string>('0');
  const [oldKey, setOldKey] = useState<string>('0');
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setModalVisible(false);
      setTabKey(oldKey);
    },
  }));

  const titleItem = <Title> {props.title} </Title>;
  const positionSlot = [
    {
      left: titleItem,
    },
    {
      right: titleItem,
    },
  ];

  const showModal = () => {
    setModalVisible(!modalVisible);
    props.setAsk && props.setAsk(false);
  };

  const handleSave = () => {
    props.onSaved && props.onSaved();
    setModalVisible(false);
    setTabKey((Number(tabKey) + 1).toString());
  };

  const onChange = (key: string) => {
    if (!props.ask) {
      setTabKey(key);
    } else {
      showModal();
      setOldKey(key);
    }

    props.activeKey && props.changeActiveKey && props.changeActiveKey(key);
  };

  const withoutSave = () => {
    setTabKey(oldKey);
    showModal();
  };

  const extraContent = () => {
    const position =
      props.titlePosition !== undefined && props.titlePosition === 'right'
        ? 1
        : 0;

    return props.title !== undefined ? positionSlot[position] : {};
  };

  return (
    <>
      <ConfirmChangeTab
        save={handleSave}
        close={showModal}
        formName={props.formName}
        withoutSave={withoutSave}
        modalVisible={modalVisible}
      />
      <BoxContent
        border={props.border}
        fontSize={props.fontSize}
        backgroundColor={props.backgroundColor}
      >
        <Tabs
          type="card"
          defaultActiveKey="0"
          activeKey={props.activeKey || tabKey}
          destroyInactiveTabPane={true}
          onChange={onChange}
          tabBarExtraContent={extraContent()}
        >
          {props.content.map((item: any, index: any) => {
            return (
              <TabPane tab={item.tab} key={index} tabKey={item.tab}>
                {item.body}
              </TabPane>
            );
          })}
        </Tabs>
      </BoxContent>
    </>
  );
});

Tab.displayName = 'Tab';

export default Tab;
