import { Content } from './style';
import logo from '../../assets/images/logo-blue.svg';
import Title from '../../components/Title';
import GoBack from '../GoBack/GoBack';

interface IParams {
  goBack?: boolean;
}

function Terms(props: IParams) {
  return (
    <Content url={logo}>
      {props.goBack && (
        <div className="back">
          <GoBack enableBackground={true} path={'/login'} />
        </div>
      )}
      <Title margin={'20px 0 0 0'} size={24}>
        Termos de uso
      </Title>
      <div className="bgLogo"></div>
      <div className="title">1. Introdução e disposições Gerais</div>
      <div className="text">
        1.1 Bem-vindo ao Innovation Studio. Estes são os termos de uso que regem
        o acesso e uso, como pessoa física, dentro do Brasil, dos serviços
        prestados através de aplicação tecnológica (“Aplicativos”), sítios da
        internet e conteúdos relacionados, disponibilizados pelo Innovation
        Studio Tecnologia LTDA, inscrita sob o CNPJ/MF 47.112.151/0001-03, com
        sede na rua Dr. Elviro Dantas, 587 – Coroado, na cidade de Manaus-AM, a
        toda e qualquer pessoa, a seguir denominado simplesmente “Usuários”.
      </div>
      <div className="text">
        1.1.1 Por favor, leia com atenção estes termos de antes de aceitar ou
        usar os serviços.
      </div>
      <div className="title">1.2 Aceitação dos termos e condições de uso.</div>
      <div className="text">
        1.2.1 Este documento foi elaborado com base na Lei Federal nº
        13.709/2018 – Lei Geral de Proteção de Dados “LGPD”. Ao se cadastrar e
        utilizar continuamente os serviços, o usuário declarará ter lido,
        entendido e aceito os termos. Caso, a qualquer tempo, o usuário não
        concorde com os termos, deverá cessar imediatamente a utilização da
        aplicação.
      </div>
      <div className="title">1.3 Comunicação com o usuário.</div>
      <div className="text">
        1.3.1 O usuário autoriza o Innovation Studio a enviar notificações
        administrativas na aplicação, por e-mail, ou qualquer outro meio de
        comunicação disponível com conteúdo de natureza informativa relacionados
        aos serviços.
      </div>
      <div className="title">1.4 Alteração dos termos.</div>
      <div className="text">
        1.4.1 O Innovation Studio se resguarda ao direito de realizar alterações
        e atualizações nos termos, a qualquer momento, sem necessidade de aviso
        prévio. Em caso de alterações dos termos que restrinjam direitos dos
        usuários, o Innovation Studio comunicará tal alteração, ao seu critério,
        através dos meios de comunicação. Todavia, está liberalidade não afasta
        a responsabilidade do usuário de verificar periodicamente os termos.
        Caso o usuário não concorde com as alterações dos termos, deverá
        cancelar sua conta, cessar toda e qualquer utilização dos serviços. O
        fato de o usuário continuar a acessar ou usar os serviços após essa
        postagem representa seu consentimento em vincular-se aos termos
        alterados.
      </div>
      <div className="title">2. Dados do usuário e privacidade.</div>
      <div className="text">
        2.1 O Innovation Studio possui uma política expressa sobre privacidade.
        As informações de registro e outras informações sobre o usuário estão
        sujeitas ao tratamento referido em tal política de privacidade. O
        usuário entende e concorda que o seu uso e a prestação do serviço
        envolvem a coleta e utilização de informações e dados sobre o usuário
        (conforme definido na política de privacidade aplicável), incluindo a
        transferência destas informações e dados para outros territórios, para
        fins de armazenamento, processamento e utilização pelo Innovation
        Studio. A política de privacidade do Innovation Studio constitui parte
        integrante dos termos.
      </div>
      <div className="title">3. Cadastro</div>
      <div className="text">
        3.1 Para utilizar os serviços do Innovation Studio, o usuário deve
        registrar-se e manter uma conta pessoal de usuário de serviços. O
        usuário deve ter capacidade civil, tendo idade mínima de 18 anos de
        idade para criar seu perfil, a menos que seja emancipado ou tenha obtido
        plena capacidade civil nos termos de legislação civil em vigor. Pais e
        responsáveis legais são responsáveis por avaliar a adequação da
        utilização de dispositivos móveis e dos serviços para menores de 18 anos
        de idade.
      </div>
      <div className="text">
        3.1.1 O usuário que desejar utilizar o serviço deverá obrigatoriamente
        preencher os campos de cadastro exigidos e responderá pela veracidade
        das informações declaradas, obrigando-se a manter informações válidas,
        atualizadas e corretas. O perfil do usuário é exclusivo e
        intransferível. Em caso de não confirmação de seus dados, o acesso do
        usuário poderá ser bloqueado, a exclusivo critério do Innovation Studio.
      </div>
      <div className="text">
        3.1.2 As informações da conta são de exclusiva responsabilidade de quem
        as inseriu. No caso de acarretarem danos ou prejuízo de qualquer
        espécie, as medidas cabíveis podem ser tomadas pelo Innovation Studio a
        fim de resguardar seus interesses e a integridade dos demais usuários do
        aplicativo.
      </div>
      <div className="title">4. Serviços</div>
      <div className="text">
        4.1 Serviços prestados pelo Innovation Studio. Os serviços prestados
        consistem na disponibilização produtos disponibilizados através de uma
        aplicação tecnológica que possibilita ao usuário cadastrado a realização
        de diagnóstico, valuation, mentoria, cursos e participar de rodadas de
        investimento, oferecidos diretamente por aceleradora, mentores e
        investidores. Nossos serviços não devem ser utilizados para quaisquer
        outras finalidades que não as expressamente autorizadas por este termo
        ou expresso em lei nacional.
      </div>
      <div className="text">
        4.1.1 Utilização do aplicativo. O uso dos serviços pelos usuários é
        gratuito. O Innovation Studio se reserva o direito de passar a cobrar
        pelo serviço ou parte dele a qualquer tempo. O usuário será previamente
        informado caso isso ocorra e terá a oportunidade de consentir com tais
        cobranças pelo serviço ou cessar o uso dele.
      </div>
      <div className="text">
        4.1.2 Modificação ou encerramento do serviço. O Innovation Studio se
        reserva o direito de, a qualquer tempo, modificar ou descontinuar,
        temporariamente ou permanentemente, o serviço ou parte dele, com ou sem
        notificação. O usuário concorda que o Innovation Studio não será
        responsabilizado, nem terá qualquer obrigação adicional, implícita ou
        explícita, para com o usuário ou terceiros em razão de qualquer
        modificação, suspensão ou desativação do serviço.
      </div>
      <div className="text">
        4.1.3 Serviços privativos e compartilhados o usuário tem conhecimento
        que os serviços oferecidos serão privativos (individual/grupo fechado) e
        compartilhado (coletivo) e que a escolha sobre o tipo de serviço ficará
        ao seu critério.
      </div>
      <div className="title">5. Conteúdo publicado.</div>
      <div className="text">
        5.1 O conteúdo publicado pelo usuário é de sua única e exclusiva
        responsabilidade civil e penal para todos os efeitos legais, inclusive
        em relação aos comentários e opiniões acerca de determinado conteúdo.
      </div>
      <div className="title">6. Acesso à rede e equipamentos.</div>
      <div className="text">
        6.1 O usuário entende e concorda que a utilização do serviço demanda a
        aquisição de dispositivos de telefonia móvel e a contratação de serviços
        de telecomunicação, e que a utilização do serviço poderá gerar cobranças
        por parte de tais prestadores de serviço de telecomunicação para conexão
        com a internet, por exemplo. O usuário é o responsável exclusivo por
        contratar e arcar com todo e qualquer custo e ônus relacionados à
        aquisição de seu dispositivo de telefonia móvel e a contratação de seu
        serviço de telecomunicação. O Innovation Studio não é responsável pela
        disponibilidade, qualidade e manutenção de tais serviços de
        telecomunicação e o usuário entende que as condições do serviço de
        telecomunicações poderão afetar a experiência do serviço. O Innovation
        Studio não será responsabilizado por qualquer problema relacionado ao
        serviço decorrente direta ou indiretamente de inconsistências ou falhas
        nos dispositivos de telefonia móvel e/ou nos serviços de
        telecomunicação.
      </div>
      <div className="title">
        7. Recusa de garantia; limitação de responsabilidade.
      </div>
      <div className="text">
        7.1 Garantia. O usuário expressamente concorda e está ciente de que: (i)
        a contratação do serviço será sob inteira responsabilidade do usuário.
        (ii) o Innovation Studio é responsável por administrar os cancelamentos,
        devoluções dos usuários, além de fiscalizar documentos técnicos
        comprobatórios para geração de orçamentos, controle de compras de
        produtos, monitorar denúncias de abusos, e supervisionar as boas
        práticas entre vendedores e usuários (iii) o usuário poderá solicitar o
        cancelamento do serviço a qualquer momento, conforme política de
        cancelamento em vigência.
      </div>
      <div className="text">
        7.2 Limitação de responsabilidade o usuário expressamente concorda e
        está ciente de que o Innovation Studio não terá qualquer
        responsabilidade, seja contratual ou extracontratual, por quaisquer
        danos patrimoniais ou morais, incluindo, sem limitação, danos por lucros
        cessantes ou de informações ou outras perdas intangíveis resultantes do:
        (a) uso ou incapacidade de usar o serviço; (b) quebras de segurança e
        acesso não autorizado às transmissões ou informações do usuário, bem
        como da alteração destas; (c) orientações ou condutas de terceiros sobre
        o serviço; e (d) orientações ou condutas de vendedores sobre o serviço
        (e) por motivos de força maior ou caso fortuito, atos praticados pelo
        próprio usuário e atos praticados por ou sob a responsabilidade de
        terceiros.
      </div>
      <div className="title">8. Propriedade intelectual</div>
      <div className="text">
        8.1 As marcas, nomes, logotipos, nomes de domínio e demais sinais
        distintivos, bem como todo e qualquer conteúdo, desenho, arte ou layout
        publicado no aplicativo e o próprio serviço, são de propriedade
        exclusiva do Innovation Studio.
      </div>
      <div className="text">
        8.2 São vedados quaisquer atos ou contribuições tendentes à
        descompilação, engenharia reversa, modificação das características,
        ampliação, alteração, mesclagem ou incorporação em quaisquer outros
        programas ou sistemas do aplicativo ou serviço. Enfim, toda e qualquer
        forma de reprodução do aplicativo ou serviço, total ou parcial,
        permanente, temporária ou provisória, de forma gratuita ou onerosa, sob
        quaisquer modalidades, formas ou títulos é expressamente vedada.
      </div>
      <div className="title">9. Cancelamento de seu acesso ao aplicativo</div>
      <div className="text">
        9.1 O usuário concorda que o Innovation Studio, à sua livre discrição,
        poderá cancelar sua utilização do serviço, incluindo, mas não se
        limitando: (i) por descumprimentos e/ou violação destes termos; (ii) em
        função de ordem judicial ou requisição legal e competente de autoridade
        governamental; (iii) por requisição do próprio usuário; (iv) por
        desativação ou modificação do serviço (ou de qualquer de suas partes);
        (v) por caso fortuito, força maior e/ou questões de segurança; (vi) por
        inatividade da conta por um período razoável de tempo; (vii) pela
        suposta prática de qualquer atividade fraudulenta ou ilegal por parte do
        usuário, a critério do Innovation Studio; (viii) ao uso inadequado do
        aplicativo, e/ou (ix) por inadimplemento por parte do usuário de
        quaisquer obrigações, valores, pagamentos devidos em razão do serviço,
        quando aplicável.
      </div>
      <div className="text">
        9.2 O usuário concorda que ao término de seu acesso ao serviço, por
        qualquer razão constante destes termos, pode ocorrer sem uma notificação
        prévia e todas as informações e dados constantes poderão ser
        permanentemente apagados.
      </div>
      <div className="title">10. Disposições Finais</div>
      <div className="text">
        10.1 Estes termos constituem o acordo integral entre o usuário e o
        Innovation Studio e regerão a utilização do serviço pelo usuário,
        substituindo qualquer acordo anterior.
      </div>
      <div className="text">
        10.2 Legislação aplicável. Estes termos e o relacionamento entre o
        usuário e o Innovation Studio serão regidos pelas leis da República
        Federativa do Brasil. Fica eleito o foro da Comarca da capital do Estado
        do Amazonas sendo o único competente para dirimir quaisquer litígios
        e/ou demandas que venham a envolver as partes em relação ao uso e acesso
        de seu site e aplicativo. O usuário e o Innovation Studio concordam em
        submeter-se à competência única e exclusiva dos tribunais localizados no
        Brasil.
      </div>
      <div className="text">
        10.3 Notificações. Todas as notificações, intimações, ofícios ou
        qualquer outra forma oficial de cientificação do Innovation Studio
        deverão ocorrer no endereço de sede do Innovation Studio, descrito no
        preâmbulo dos termos.
      </div>
      <div className="text">
        10.4 Renúncia de direitos. Caso o Innovation Studio deixe de exercer ou
        executar qualquer direito ou dispositivo destes termos, isto não será
        caracterizado como uma renuncia a tal direito ou dispositivo nem
        constituirá novação. Se qualquer dispositivo dos termos for considerado
        inválido por um tribunal competente, as partes, todavia concordam que o
        restante dos termos deverá ser interpretado de tal forma a refletir a
        intenção original, e os outros dispositivos dos termos permanecerão em
        pleno vigor e efeito.
      </div>
      <div className="text">
        10.5 Cessão de direitos. O usuário não poderá ceder tampouco transferir
        estes termos, total ou parcialmente, sem prévia aprovação por escrito do
        Innovation Studio. O usuário concede sua aprovação para que o Innovation
        Studio ceda e transfira estes termos total ou parcialmente, inclusive:
        (i) para sua controladora, subsidiária ou afiliada; (ii) um adquirente
        das participações acionárias, negócios ou bens do Innovation Studio; ou
        (iii) para um sucessor em razão de qualquer operação societária. Não
        existe joint-venture, sociedade, emprego ou relação de representação
        entre o usuário, o Innovation Studio ou quaisquer parceiros como
        resultado do contrato entre o usuário e o Innovation ou pelo uso dos
        serviços.
      </div>
      <div className="text">
        10.6 Indivisibilidade. Caso qualquer disposição destes termos seja tida
        como ilegal, inválida ou inexequível total ou parcialmente, por qualquer
        legislação, essa disposição ou parte dela será, naquela medida,
        considerada como não existente para os efeitos destes termos, mas a
        legalidade, validade e exequibilidade das demais disposições contidas
        nestes termos não serão afetadas. Nesse caso, as partes substituirão a
        disposição ilegal, inválida ou inexequível, ou parte dela, por outra que
        seja legal, válida e exequível e que, na máxima medida possível, tenha
        efeito similar à disposição tida como ilegal, inválida ou inexequível
        para fins de conteúdo e finalidade dos presentes termos. Estes termos
        constituem a totalidade do acordo e entendimento das partes sobre este
        assunto e substituem e prevalecem sobre todos os entendimentos e
        compromissos anteriores sobre este assunto.
      </div>
    </Content>
  );
}

export default Terms;
