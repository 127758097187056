import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import BerkusSection from './BerkusSection';
import Text from '../../../components/Text';
import api from '../../../core/services/api';
import Title from '../../../components/Title';
import {
  Box,
  BoxPreMoney,
  BoxPreMoneyWrapper,
  ButtonBackToTop,
  Wrapper,
} from '../style';
import { useReactToPrint } from 'react-to-print';
import Divider from '../../../components/Divider';
import ScoreCardSection from './ScoreCardSection';
import { DownloadOutlined, UpOutlined } from '@ant-design/icons';
import DiscountedCashFlow from './DiscountedCashFlow';
import { useLocation, useNavigate } from 'react-router-dom';
import { IValuationResult } from '../../../core/interfaces';
import ValuationMethodsChart from './ValuationMethodsChart';
import VentureCapitalSection from './VentureCapitalSection';
import {
  ButtonCloseDrawer,
  Container,
  PageBreak,
  PurpleButton,
} from '../../../GlobalStyles';
import { maskMoney } from '../../../helpers/mask.helper';
import Loading from '../../../components/Loading/Loading';

function Results() {
  const { state }: { state: any } = useLocation();
  const [result, setResult] = useState<IValuationResult>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showButtonBackToTop, setShowButtonBackToTop] =
    useState<boolean>(false);
  const componentRef = React.useRef<HTMLDivElement>(null);
  const scroll = React.useRef<HTMLDivElement>(null);

  const configDivider = {
    color: '#960096',
    margin: '33px 0',
  };

  const getResult = async () => {
    setLoading(true);

    await api
      .get<any>('valuationResult/result', {
        params: {
          valuationResult: state.id,
          startup: state.startupId,
        },
      })
      .then((response) => {
        setResult(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = () => {
    if (scroll.current) {
      const { scrollTop, scrollHeight, clientHeight } = scroll.current;
      if (scrollTop > (scrollHeight - clientHeight) / 2) {
        setShowButtonBackToTop(true);
      } else {
        setShowButtonBackToTop(false);
      }
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'resultado-valuation',
    pageStyle: '@page { size: A4; }',
  });

  const backToTop = () => {
    scroll.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box ref={scroll} onScroll={handleScroll}>
      <Loading size="large" spinning={loading} width={200} height={200}>
        <Container>
          <Wrapper>
            <div ref={componentRef} style={{ width: '100%' }}>
              <Row justify="space-between" align="middle" wrap={false}>
                <Col>
                  <Title sizeXs={22} size={26}>
                    Resultado do Valuation.
                  </Title>
                  <Text color={'var(--thirdy-color)'} size={18} weight={700}>
                    Startup: {state?.name}
                  </Text>
                </Col>
                <Col className="hide-print">
                  <ButtonCloseDrawer
                    onClick={() => {
                      if (state?.role === 'startup') {
                        navigate('/valuations/valuation-startup');
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    <span>Fechar janela</span>
                  </ButtonCloseDrawer>
                </Col>
              </Row>
              <BoxPreMoneyWrapper>
                <BoxPreMoney>
                  <Title size={20}>Valuation Pré-money</Title>
                  <Title size={25} color="#960096">
                    {maskMoney(result?.preMoney || '0')}
                  </Title>
                </BoxPreMoney>
                <PurpleButton
                  className="hide-print hide-in-mobile"
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handlePrint}
                >
                  Baixar PDF
                </PurpleButton>
              </BoxPreMoneyWrapper>
              <Text size={17} margin="0 0 30px 0">
                O Valuation Pré-Money é o indicador que informa quanto uma
                startup pode valer antes de começar a receber qualquer
                investimento, sem incluir investimento externo ou a última
                rodada de investimentos na avaliação.
              </Text>

              <Title margin="0 0 20px 0" size={22}>
                5 métodos de Valuation Trabalhados
              </Title>

              <ValuationMethodsChart
                scorecard={result?.preMoneyScoreCard || 0}
                berkus={result?.preMoneyBerkus || 0}
                vc={result?.preMoneyVC || 0}
                pepertuide={result?.preMoneyDCF_Perpetu || 0}
                multiples={result?.preMoneyDCF_Multiplos || 0}
                //pesos
                berkusWeigth={result?.weigthMethodsValuations[0]?.weigth || 0}
                scorecardWeigth={
                  result?.weigthMethodsValuations[1]?.weigth || 0
                }
                vcWeigth={result?.weigthMethodsValuations[2]?.weigth || 0}
                multiplesWeigth={
                  result?.weigthMethodsValuations[3]?.weigth || 0
                }
                pepertuideWeigth={
                  result?.weigthMethodsValuations[4]?.weigth || 0
                }
              />
              <Divider {...configDivider} />

              <ScoreCardSection
                enterprising={result?.enterprising || 0}
                distributed={result?.distributed || 0}
                marginDistribution={result?.marginDistribution || 0}
                investmentAmount={result?.investmentAmount || 0}
                divisionMax={result?.divisionMax || 0}
                divisionMin={result?.divisionMin || 0}
                risk={result?.risk || 0}
                potential={result?.potential || 0}
                preMoneyScoreCard={result?.preMoneyScoreCard || 0}
                posMoney={result?.posMoney}
                avgScoreCard={result?.avgScoreCard || 0}
              />
              <Divider {...configDivider} />

              <BerkusSection
                preMoney={result?.preMoneyBerkus}
                investmentAmount={result?.investmentAmount || 0}
                valueDrives={result?.valueDrives || []}
                posMoney={result?.posMoneyBerkus || 0}
                equity={result?.equity || 0}
              />
              <Divider {...configDivider} />

              <PageBreak />
              <VentureCapitalSection
                vcs={result?.vcs || []}
                preMoney={result?.preMoneyVC || 0}
              />
              <Divider {...configDivider} />

              <DiscountedCashFlow
                preMoneyPerpetuity={result?.preMoneyDCF_Perpetu || 0}
                preMoneyMultiplos={result?.preMoneyDCF_Multiplos || 0}
              />
            </div>
          </Wrapper>
        </Container>
      </Loading>
      <ButtonBackToTop show={showButtonBackToTop} onClick={backToTop}>
        <UpOutlined />
      </ButtonBackToTop>
    </Box>
  );
}

export default Results;
