import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Question = styled.div`
  width: 70%;
  text-align: justify;
  padding-right: 30px;
`;

export const BoxDescription = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  width: 30%;

  .description {
    font-size: 18px;
  }
`;
