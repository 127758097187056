import { Col, Row, Spin, Tooltip } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import { useModal } from 'sunflower-antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { Card } from '../../../../GlobalStyles';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import MentorService from '../../../../services/MentorService';
import BusinessInformationForm from '../BusinessInformationForm';
import { notification } from '../../../../helpers/notification.helper';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import IMentorBusinessInformation from '../../../../core/interfaces/IMentorBusinessInformation';

function BusinessInformationCard() {
  const { logged } = useAuth();
  const modalForm = useModal({
    defaultVisible: false,
  });
  const { loading, showLoading, hideLoading } = useLoading();
  const { entityId = 0 } = logged ?? {};

  const [information, setInformation] = useState<IMentorBusinessInformation>({
    linkLattes: '...',
    linkLinkedin: '...',
    occupationArea: '...',
  });

  const mentorService = useMemo(() => MentorService.init(), []);

  const getInformation = useCallback(() => {
    showLoading();
    mentorService
      .getBusinessInformation(entityId)
      .then((data) => setInformation(data))
      .catch(() => notification.error('Erro ao listar informações de négocio'))
      .finally(hideLoading);
  }, [entityId, hideLoading, showLoading, mentorService]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const metaData = [
    {
      label: 'Link Lattes',
      style: {
        color: '#00B6FF',
        textDecoration: 'underline',
      },
      value: information.linkLattes,
    },
    {
      label: 'Link Linkedin',
      style: {
        color: '#00B6FF',
        textDecoration: 'underline',
      },
      value: information.linkLinkedin,
    },
    {
      label: 'Área de atuação',
      value: information.occupationArea ?? '...',
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <Card boxShadow="0px 3px 6px #05233D29" padding="12px 22px">
          <Row
            style={{ marginBottom: '12px' }}
            justify="space-between"
            wrap={true}
            gutter={[12, 12]}
            align="middle"
          >
            <Col flex={1}>
              <Title size={20} tag="h2">
                Informações de negócio
              </Title>
            </Col>
            <Col>
              <Tooltip
                title="Editar"
                color="#fff"
                placement="bottomRight"
                overlayClassName="text-tooltip-primary"
              >
                <FiEdit2 cursor="pointer" size={20} onClick={modalForm.show} />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[24, 20]} wrap={true}>
            {metaData.map((data, index) => (
              <Col span={8} key={`key_information_${index}`}>
                <Text margin="0 0 6px 0" weight={600}>
                  {data.label}
                </Text>
                <Text color="#9AA4A8" weight={600} style={data.style}>
                  {data.value}
                </Text>
              </Col>
            ))}
          </Row>
        </Card>
      </Spin>

      <BusinessInformationForm
        onReload={getInformation}
        information={information}
        {...modalForm.modalProps}
      />
    </>
  );
}

export default BusinessInformationCard;
