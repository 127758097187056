import React, { useEffect, useState } from 'react';
import { BoxContent, Header } from './style';
import Tab from '../../../components/tab';
import image from '../../../assets/images/logo-rocket.png';
import { Form, Input } from 'antd';
import { useAuth } from '../../../core/hooks/auth';
import ValuationQuestions from '../../../components/ValuationQuestions/ValuationQuestions';

interface IParams {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;
  next: string;
  setParamsResponse: (response: any) => void;
  initialValues?: any;
  formReadOnly?: boolean;
}

function Berkus(props: IParams) {
  const [form] = Form.useForm();
  const { logged } = useAuth();
  const [ask, setAsk] = useState<boolean>(false);
  const entityId = logged?.client as number;
  const [next, setNext] = useState<string>('0');
  const [tabActive, setTabActive] = useState<string>('0');
  const max = Form.useWatch('maxValuation', form);

  const content = [
    {
      tab: 'Modelo de negócio',
      body: (
        <ValuationQuestions
          pillarId={5}
          initialValues={props.initialValues}
          typeValuation="BERKUS"
          tabName={'Modelo de negócio'}
          setAsk={setAsk}
          ask={ask}
          storageName="form-business-model"
          clientId={entityId}
          setNext={setNext}
          next={next}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
    {
      tab: 'Protótipo disponível',
      body: (
        <ValuationQuestions
          pillarId={6}
          initialValues={props.initialValues}
          typeValuation="BERKUS"
          tabName={'Protótipo disponível'}
          setAsk={setAsk}
          ask={ask}
          storageName="form-prototype-available"
          clientId={entityId}
          setNext={setNext}
          next={next}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
    {
      tab: 'Gestão do time',
      body: (
        <ValuationQuestions
          pillarId={7}
          initialValues={props.initialValues}
          tabName={'Gestão do time'}
          typeValuation="BERKUS"
          setAsk={setAsk}
          ask={ask}
          storageName="form-management-team"
          clientId={entityId}
          setNext={setNext}
          next={next}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
    {
      tab: 'Redução de riscos',
      body: (
        <ValuationQuestions
          pillarId={9}
          initialValues={props.initialValues}
          tabName={'Redução de riscos'}
          typeValuation="BERKUS"
          setAsk={setAsk}
          ask={ask}
          storageName="form-risk-reduction"
          clientId={entityId}
          setNext={setNext}
          next={next}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
    {
      tab: 'Clientes existentes',
      body: (
        <ValuationQuestions
          pillarId={10}
          initialValues={props.initialValues}
          tabName={'Clientes existentes'}
          typeValuation="BERKUS"
          setAsk={setAsk}
          ask={ask}
          storageName="form-existing-customers"
          clientId={entityId}
          setNext={setNext}
          next={next}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
  ];

  const maskMoney = (e: React.ChangeEvent<HTMLInputElement>) => {
    const money = e.target.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');

    form.setFieldsValue({
      maxValuation: money,
    });
  };

  const convertNumberToCurrency = (value: any) => {
    return value
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .replace('R$', '');
  };

  useEffect(() => {
    const data = sessionStorage.getItem('berkus-form-max-valuation');
    form.setFieldValue('maxValuation', data ?? '');
    return () => {
      sessionStorage.setItem(
        'berkus-form-max-valuation',
        form.getFieldValue('maxValuation'),
      );
    };
  }, [form]);

  useEffect(() => {
    if (next < '5') {
      setTabActive(next);
    }
  }, [next]);

  useEffect(() => {
    sessionStorage.setItem('berkus-form-max-valuation', max);
  }, [max]);

  useEffect(() => {
    if (props.initialValues.maxValue) {
      const maxValue = convertNumberToCurrency(props.initialValues.maxValue);
      form.setFieldsValue({
        maxValuation: maxValue,
      });
    }
  }, [props.initialValues]);

  return (
    <BoxContent>
      <Header>
        <img alt="" src={image} />
        <div className="description">
          Criado por Dave Berkus, em 1990, o <b>método Berkus</b> tem como
          objetivo atender startups em suas fases iniciais (early stage),
          empresas que não tem faturamento consolidade, porém avalia bens
          intangíveis desses novos negócios. Para isso, há utilização de 5
          elementos básicos para a avaliação dessas empresas: valor da ideia
          (modelo de negócio), protótipo ou tecnologia, gestão do time ou
          habilidades, relações estratégicas e lançamento ou primeiras vendas.
        </div>
      </Header>
      <Form
        disabled={props.formReadOnly}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Valor máximo do valuation"
          name="maxValuation"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input
            type={'text'}
            onChange={maskMoney}
            prefix={'R$'}
            style={{ width: '50%' }}
            placeholder="R$ 10.000,00"
            maxLength={13}
          />
        </Form.Item>
      </Form>
      <Tab
        content={content}
        activeKey={tabActive}
        changeActiveKey={setTabActive}
        ask={ask}
        setAsk={setAsk}
        fontSize={'0.8em'}
        formName={'valuationBerkusForm'}
        border={true}
      />
    </BoxContent>
  );
}

export default Berkus;
