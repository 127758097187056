import styled from 'styled-components';

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;

const BoxStartupsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  gap: 10px;
`;

const StartupCard = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 5px);
  background-color: var(--white-color);
  border-radius: 6px;
  padding: 10px 10px;
  cursor: pointer;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  text-transform: capitalize;

  .label {
    color: var(--support-color);
  }

  .val {
    font-weight: bold;
  }
`;

const BoxTotal = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 6px;
  background-color: var(--white-color);
`;

const Topo = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  padding: 10px;
  background-color: blue;

  .amount {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--white-color);
  }
`;

const RoundList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
`;

const RoundItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--support-color);
  padding: 15px 10px;
  cursor: pointer;
`;

export {
  BoxStartupsList,
  BoxTop,
  BoxTotal,
  StartupCard,
  CardItem,
  Topo,
  RoundList,
  RoundItem,
};
