import React from 'react';
import { ModalProps, Button } from 'antd';
import image from '../../assets/icons/actions-icons/ic-attention.svg';
import Title from '../Title';
import Text from '../Text';
import {
  ModalContainer,
  Image,
  ModalContent,
  ButtonContainer,
  ButtonModal,
} from './style';

interface IProps extends ModalProps {
  message: string;
  description: string;
  urlImage?: string;
  textCancelButton?: string;
  textOkButton?: string;
  fontSize?: number;
  visible: boolean;
}

function AlertMessage(props: IProps) {
  return (
    <ModalContainer
      {...props}
      centered
      open={props.visible}
      onCancel={props.onCancel}
      footer={false}
    >
      <ModalContent>
        <Image src={props.urlImage || image} alt="" />
        <Title size={props.fontSize || 20}> {props.message} </Title>
        <Text size={14} align="justify">
          {props.description}
        </Text>
        <ButtonContainer>
          <ButtonModal onClick={props.onOk} type="primary">
            {props.textOkButton}
          </ButtonModal>
          {props.textCancelButton && (
            <Button
              style={{ display: 'block', margin: '10px auto', height: '35px' }}
              onClick={props.onCancel}
              type={'default'}
            >
              {props.textCancelButton}
            </Button>
          )}
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
}
export default AlertMessage;
