import React from 'react';
import {
  BoxContent,
  Content,
  Header,
} from '../../../../components/form/profiles/style';

interface IParams {
  title: string;
  subTitle: string;
  description?: string;
  content: any;
}

function ContentForm(props: IParams) {
  return (
    <BoxContent>
      <Header>
        <div className="title">{props.title}</div>
        <div className="subTitle">{props.subTitle}</div>
        {props.description && (
          <div className="description">{props.description}</div>
        )}
      </Header>
      <Content>{props.content}</Content>
    </BoxContent>
  );
}

export default ContentForm;
