import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #2196f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    background: #2196f3;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 0 1px #fff;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
  }
`;

export { Wrapper };
