import { Collapse, Card } from 'antd';
import styled from 'styled-components';

const Panel = styled(Collapse.Panel)`
  background-color: #fff;

  & .ant-collapse-header-text {
    font-weight: 700;
    color: var(--primary-color);
    font-family: 'Segoe UI', sans-serif;
  }

  & .ant-collapse-content-box {
    padding-top: 16px !important;
  }

  &.parent-item.ant-collapse-item-active {
    & > div.ant-collapse-header .ant-collapse-header-text {
      color: var(--white-color);
    }

    & > div.ant-collapse-header {
      background-color: var(--primary-color);
    }
  }
`;

const WrapperStep = styled.ul`
  margin-left: 40px;

  & li + li {
    margin-top: 16px;
  }
`;

const StepItem = styled.li`
  padding: 6px 0;
  font-size: 13px;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  justify-content: flex-start;
  font-weight: 500;
  color: var(--primary-color);
  font-family: 'Segoe UI', sans-serif;
  border-bottom: 1.5px solid var(--thirdy-color);
`;

const CardItem = styled(Card)`
  display: flex;
  flex-direction: row-reverse;
  width: calc(100% / 4 - 10px);
  margin: 0 10px 10px 0;
  height: 100px;
  .ant-card-head {
    border-bottom: 0;
    height: auto;
    width: auto;
    padding: 0;
    min-height: 0;
    position: absolute;
    margin: 5px 5px 0 0;
  }
  .ant-card-extra {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
    width: 100%;
  }
`;

const CardItemContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const BoxImage = styled.div<{
  image: string;
}>`
  width: 25%;
  height: 100px;
  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--primary-color);
`;

const BoxContent = styled.div`
  width: 75%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    font-family: 'Segoe UI', sans-serif;
  }

  .box-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .box {
      display: flex;
      flex-direction: column;
      text-align: left;

      .label {
        font-size: 13px;
        font-weight: bold;
        color: var(--primary-color);
        font-family: 'Segoe UI', sans-serif;
      }

      .description {
        font-size: 13px;
        font-weight: 500;
        color: #7e7e7e;
        font-family: 'Segoe UI', sans-serif;
      }
    }
  }
`;

export {
  WrapperStep,
  StepItem,
  Panel,
  CardItem,
  CardItemContent,
  BoxImage,
  BoxContent,
};
