import { useState } from 'react';

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const changePagination = (page: number) => {
    setCurrentPage(page - 1);
  };

  return {
    currentPage,
    changePagination,
  };
};

export default usePagination;
