import React, { useEffect, useMemo, useState } from 'react';
import { BoxContainer } from '../../GlobalStyles';
import { Button, Pagination, PaginationProps } from 'antd';
import { Actions, PaginationContainer } from './style';
import { PlusOutlined } from '@ant-design/icons';
import CoursesForm from './CoursesForm';
import CardCourse from '../../components/CardCourse/CardCourse';
import CourseService from '../../services/course/CourseService';
import ErrorNotification from '../../components/notifications/errorNotification';
import ICourseShowCase from '../../core/interfaces/course/ICourseShowCase';
import { useModal } from 'sunflower-antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../core/hooks/auth';

interface Props {
  showActions?: boolean;
}

function CoursesList({ showActions = true }: Props) {
  const { logged } = useAuth();
  const [courses, setCourses] = useState<ICourseShowCase[]>([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const courseService = useMemo(() => CourseService.init(), []);

  const navigate = useNavigate();

  const modalCourseForm = useModal({
    defaultVisible: false,
  });

  const getCoursesList = React.useCallback(() => {
    courseService
      .showCase({
        page: pageCurrent - 1,
        teacherId: logged?.entityId,
        pageSize: pageSize,
      })
      .then(({ content, totalElements }) => {
        setCourses(content);
        setTotal(totalElements);
      })
      .catch(() => {
        ErrorNotification({
          message: 'Erro ao listar cursos',
        });
      });
  }, [courseService, logged?.entityId, pageCurrent, pageSize]);

  const changePage: PaginationProps['onChange'] = (page, pageSize) => {
    setPageCurrent(page);
    setPageSize(pageSize);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setPageCurrent(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    getCoursesList();
  }, [getCoursesList]);

  return (
    <>
      <CoursesForm {...modalCourseForm.modalProps} reload={getCoursesList} />
      <BoxContainer
        flex="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        gap="10px"
      >
        {showActions && (
          <Actions>
            <Button
              className="colorButton"
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              title="Novo curso"
              onClick={modalCourseForm.show}
            />
          </Actions>
        )}
        {courses.map((course, index) => (
          <CardCourse
            {...course}
            key={course.entityId}
            click={() => navigate(`/courses/course/${course.entityId}`)}
          />
        ))}
      </BoxContainer>
      <PaginationContainer>
        <Pagination
          defaultCurrent={pageCurrent}
          current={pageCurrent}
          defaultPageSize={pageSize}
          total={total}
          onChange={changePage}
          onShowSizeChange={onShowSizeChange}
          size="small"
        />
      </PaginationContainer>
    </>
  );
}

export default CoursesList;
