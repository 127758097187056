import styled from 'styled-components';

const Table = styled.table`
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid var(--support-color);
  width: 100%;

  & thead th {
    font-size: 20px;
  }

  & tbody tr td {
    border-bottom: 1px solid var(--support-color);
  }

  & tbody td + td {
    font-size: 17px;
    color: var(--thirdy-color);
  }

  & th,
  & td {
    padding: 5px 13px;
    font-weight: 700;
    text-align: left;
    color: var(--primary-color);
    font-family: 'Segoe UI', sans-serif;
  }
`;

const TableFooter = styled.tfoot`
  & tr:first-child {
    background-color: var(--primary-color);

    & td {
      color: var(--white-color);
    }
  }

  & tr:not(:first-child) td {
    & strong {
      font-family: inherit;
    }
    font-weight: 400;
  }
`;

export { Table, TableFooter };
