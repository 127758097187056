import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import CrudService from '../CrudService';

class TopicService extends CrudService {
  constructor() {
    super({
      requestMapping: '/topic',
    });
  }

  public static init() {
    return new TopicService();
  }

  public getByCourse(courseId: number) {
    const params = {
      course: courseId,
    };

    return api
      .get(`${this.requestMapping}/by-course`, {
        params,
      })
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public updateOrder(sourceId: number, targetId: number) {
    const body = {
      sourceId: sourceId,
      targetId: targetId,
    };
    return api
      .put(`${this.requestMapping}/update-order`, body)
      .then(() => Promise.resolve())
      .catch(errorCallback);
  }
}

export default TopicService;
