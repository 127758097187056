import React from 'react';
import {
  BoxContainer,
  ButtonContainer,
  Content,
  ImageBox,
  Introduction,
  Title,
} from '../../style';
import rocketIco from '../../../../assets/images/ico-logo-rocket.svg';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../../../GlobalStyles';
import { useAuth } from '../../../../core/hooks/auth';

function FinalRegister() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handler = () => {
    signOut();
    navigate('/');
    window.location.reload();
  };

  return (
    <Content isLight={true}>
      <Container>
        <BoxContainer width="100%" maxWidth="800px" position="initial">
          <ImageBox>
            <img
              alt="icone foguete innovation"
              src={rocketIco}
              width={150}
              height={150}
            />
          </ImageBox>
          <Title>Falta só um pouco...</Title>
          <Introduction>
            Bem vindo à plataforma Innovation Studio, seu cadastro foi concluído
            com sucesso! Seus dados estão sendo analisados pela nossa equipe, e
            em breve você receberá um e-mail de confirmação.
          </Introduction>
          <ButtonContainer>
            <Button
              onClick={handler}
              type="primary"
              style={{ backgroundColor: 'var(--thirdy-color)' }}
            >
              Concluir
            </Button>
          </ButtonContainer>
        </BoxContainer>
      </Container>
    </Content>
  );
}

export default FinalRegister;
