import React, { useEffect, useState } from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { Form, Input, notification, Space, Spin, Tag } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { BoxTags, Container } from './style';
import IModel from '../../../core/interfaces/models/IModel';
import { useForm, useWatch } from 'antd/lib/form/Form';
import useLoading from '../../../core/hooks/useLoading';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';

type serviceType = {
  listAll: () => Promise<any>;
  delete: (id: number) => Promise<any>;
  save: (data: any) => Promise<any>;
};

interface IEntity extends IModel {
  description: string;
}

interface IParams {
  title: string;
  description?: React.ReactNode;
  closabe?: () => void;
  line?: boolean;
  service: serviceType;
}

function FormInfo({ title, description, closabe, line, service }: IParams) {
  const [form] = useForm();
  const value = useWatch('description', form);
  const [dataInfos, setDateInfos] = useState<IEntity[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const { loading, showLoading, hideLoading } = useLoading();

  const setInputState = () => {
    setInputVisible(!inputVisible);
  };
  const showInput = () => {
    setInputState();
  };

  const handleInputConfirm = () => {
    if (value === '' || value === undefined) {
      notification.warning({
        message: 'Atenção',
        description: 'O campo não pode ser vazio',
      });
      return;
    }

    service
      .save({ description: value })
      .then((res) => {
        getAll();
        form.resetFields();
        setInputState();
      })
      .catch((err) => {
        notification.error({
          message: 'Erro ao salvar',
          description: 'Ocorreu um erro ao salvar a informação',
        });
      });
  };

  const getAll = async () => {
    showLoading();
    service
      .listAll()
      .then((response) => {
        setDateInfos(response);
      })
      .finally(hideLoading);
  };

  const handleDelete = (tag: IEntity) => {
    service
      .delete(tag?.entityId as number)
      .then(() => {
        getAll();
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao deletar',
          description: 'Não foi possível deletar o item',
        });
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <Container borderTop={line || true}>
      <Spin spinning={loading}>
        <Title margin={description ? '0' : '0 0 10px 0'} size={16}>
          {title}
        </Title>
        {description && (
          <Text style={{ fontStyle: 'italic' }} margin={'0 0 10px 0'}>
            {description}
          </Text>
        )}
        {inputVisible ? (
          <Form form={form}>
            <Form.Item
              name={'description'}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                maxLength={60}
                type="text"
                size="small"
                style={{ maxWidth: '480px' }}
                placeholder="Digite o nome e aperte ENTER para salvar"
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            </Form.Item>
          </Form>
        ) : (
          <Tag
            className="dashed"
            style={{ width: '70px', cursor: 'pointer' }}
            onClick={showInput}
          >
            <PlusOutlined /> Novo
          </Tag>
        )}
        <BoxTags>
          <OnEmptyOrList list={dataInfos}>
            <Space
              style={{
                width: '100%',
                flexWrap: 'wrap',
              }}
              direction="horizontal"
            >
              {dataInfos.map((tag, index) => {
                return (
                  <Tag
                    className="default"
                    key={`TAGS_${index}`}
                    closable={true}
                    onClose={() => handleDelete(tag)}
                    closeIcon={<CloseOutlined />}
                  >
                    {tag.description}
                  </Tag>
                );
              })}
            </Space>
          </OnEmptyOrList>
        </BoxTags>
      </Spin>
    </Container>
  );
}

export default FormInfo;
