import { notification as notificationAntd } from 'antd';
import INotificationMethod from '../core/enums/INotificationMethod';
import INotificationParameters from '../core/interfaces/notification/INotification';

const notification = {
  alert(params: INotificationParameters) {
    const method = notificationAntd[params.method];
    method({
      duration: 3.5,
      message: params.message,
    });
  },
  success(message: string) {
    this.alert({
      message,
      method: INotificationMethod.SUCCESS,
    });
  },
  info(message: string) {
    this.alert({
      message,
      method: INotificationMethod.INFO,
    });
  },
  error(message: string) {
    this.alert({
      message,
      method: INotificationMethod.ERROR,
    });
  },
};

export { notification };
