import StatusFileStorageEnum from '../core/enums/StatusFileStorageEnum';
import api from '../core/services/api';
import CrudService from './CrudService';

class KpiService extends CrudService {
  public static init() {
    return new KpiService();
  }

  constructor() {
    super({
      requestMapping: '/kpi',
    });
  }

  public removeComment(commentId: number) {
    return api
      .delete(`${this.requestMapping}/remove-comment/${commentId}`)
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public changeStatusFile(fileId: number, status: StatusFileStorageEnum) {
    const path = `${this.requestMapping}/change-status-file/${fileId}`;
    const params = {
      status,
    };
    return api
      .put(path, null, { params })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public addComment(data: Record<string, unknown>) {
    return api
      .post(`${this.requestMapping}/add-comment`, data)
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default KpiService;
