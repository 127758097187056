import styled from 'styled-components';
import { Progress } from 'antd';

const HeaderPhaseCourse = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;

    & hr {
      display: none;
    }
  }
`;

const ProgressPhaseCourse = styled(Progress)`
  .ant-progress-inner {
    width: calc(100% - 16px);
  }

  .ant-progress-text {
    margin-left: 0;
  }
`;

export { HeaderPhaseCourse, ProgressPhaseCourse };
