import React from 'react';
import ContentBase from '../templates/ContentBase';
import MentorListInfo from '../Infos/MentorListInfo';
import MentorList from './MentorList';

function Mentor() {
  return (
    <ContentBase
      title="Mentores"
      modalContent={<MentorListInfo />}
      content={<MentorList />}
    />
  );
}

export default Mentor;
