import React from 'react';
import PopoverPosition from './types/IPopover';

enum EnabledActionsCrudEnum {
  EDIT,
  VIEW,
  REMOVE,
  STATUS,
}

type IAction = {
  label: React.ReactNode;
  event?: () => void;
  icon: React.ReactNode;
};

interface ParamsActionsCrud {
  enabledActions?: EnabledActionsCrudEnum[];
  style?: React.CSSProperties;
  boxPosition?: PopoverPosition;
  edit?: () => void;
  status?: () => void;
  view?: () => void;
  remove?: () => void;
  sizeIcon?: number;
  actions?: IAction[];
}

export { EnabledActionsCrudEnum };
export type { IAction, ParamsActionsCrud };
