import React from 'react';
import { CardContainer, CardImage, CardContent, Container } from './style';
import image from '../../../src/assets/images/ico-question.png';
import Text from '../Text';
import { IStartup } from '../../core/interfaces';

interface IProps {
  entity: IStartup[];
  goBackPath: (id: number) => void;
}

function CardList({ entity, goBackPath }: IProps) {
  return (
    <Container>
      {entity.map((item) => (
        <CardContainer
          key={item.entityId}
          onClick={() => goBackPath(item.entityId as number)}
        >
          <CardImage>
            <img src={image} alt="" />
          </CardImage>
          <CardContent>
            <Text margin={'0 0 0 10px'} size={18} weight={700}>
              {item.name}
            </Text>
            <Text margin={'0 0 0 10px'} size={13} weight={500}>
              {item.problem}
            </Text>
          </CardContent>
        </CardContainer>
      ))}
    </Container>
  );
}

export default CardList;
