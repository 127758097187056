import React, { useEffect, useState } from 'react';
import Tab from '../../../../components/tab';
import DiagnosisInfo from '../../../Infos/DiagnosisInfo';
import ContentBase from '../../../templates/ContentBase';
import FooterPage from '../../../../components/FooterPage';
import { Title } from '../../../templates/style';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../../core/services/api';
import { notification, Slider } from 'antd';
import AlertMessage from '../../../../components/Alert/AlertMessage';
import DiagnosticResult from '../DiagnosticResult';
import IPillarsDiagnosis from '../../../../core/interfaces/IPillarsDiagnosis';
import {
  BoxDescription,
  BoxSlider,
  Line,
  Question,
} from '../../../valuation/form/style';
import Text from '../../../../components/Text';
import useLoading from '../../../../core/hooks/useLoading';
import Loading from '../../../../components/Loading/Loading';

interface IData {
  entityId: number;
  diagnosticResult: {
    entityId: number;
  };
  question: {
    entityId: number;
  };
  description: number;
}

function DiagnosisForm() {
  const { loading, showLoading, hideLoading } = useLoading();
  const [activeKey, setActiveKey] = useState<string>('0');
  const [isOpenModalFinish, setIsOpenModalFinish] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [pillars, setPillars] = useState<IPillarsDiagnosis[]>([]);
  const [data, setData] = useState<IData[]>([]);
  const content: any = [];
  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState<any[]>([]);
  const [nameButton, setNameButton] = useState<string>('Próximo');
  const getMethodology = () => {
    api
      .get('questions/byDiagnostic/' + state.diagnostic.entityId)
      .then((response: any) => {
        setPillars(response.data);
      });
  };

  const onChangeValue = (id: number, value: any, valueAnswer: number) => {
    if (data.find((item: IData) => item.question.entityId === id)) {
      const newData = data.map((item: IData) => {
        if (item.question.entityId === id) {
          item.description = valueAnswer;
        }
        return item;
      });
      setData(newData);
    }

    const El = document.getElementById('description-' + id) as HTMLInputElement;
    El.innerHTML = value;
  };

  const onFinish = () => {
    api
      .post('diagnostic/finish', state)
      .then(() => {
        setShowResult(true);
        setIsOpenModalFinish(false);
      })
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Ocorreu um erro ao realizar o diagnóstico!',
        });
      });
  };

  const save = (next: boolean) => {
    showLoading();
    data.forEach((item: IData) => {
      if (item.diagnosticResult === undefined) {
        item.diagnosticResult = {
          entityId: state.entityId,
        };
      }
    });

    api
      .post('answer/list', data)
      .then((response: any) => {
        // if (next) {
        //   notification.success({
        //     message: 'Tudo certo!',
        //     description: 'Respostas salvas com sucesso!',
        //   });
        // }
        data.length = 0;

        if (next) {
          if (parseInt(activeKey) === (pillars?.length as number) - 1) {
            setIsOpenModalFinish(true);
          }

          setActiveKey((parseInt(activeKey) + 1).toString());
        }
      })
      .catch(() => {
        if (next) {
          notification.error({
            message: 'Algo deu errado!',
            description: 'Ocorreu um erro ao salvar as respostas!',
          });
          navigate(-1);
        }
      })
      .finally(hideLoading);
  };

  const generateItemsForm = (pillar: any) => {
    return (
      <div>
        <Title justify="center">
          {pillar.name}
          <div className="info">
            <QuestionCircleOutlined onClick={() => window.alert('info')} />
          </div>
        </Title>
        {pillar.questions.map((item: any, key: number) => {
          return (
            <Line key={key}>
              <Question>
                <Text size={17} weight={700}>
                  {key + 1 + ' - ' + item.question}
                </Text>
                <BoxSlider>
                  <Slider
                    dots={true}
                    min={0}
                    max={10}
                    marks={{
                      0: '0',
                      1: '1',
                      2: '2',
                      3: '3',
                      4: '4',
                      5: '5',
                      6: '6',
                      7: '7',
                      8: '8',
                      9: '9',
                      10: '10',
                    }}
                    defaultValue={
                      answers.find(
                        (i: any) => i.question?.entityId === item?.entityId,
                      )?.description
                    }
                    value={
                      answers.find(
                        (i: any) => i.question?.entityId === item?.entityId,
                      )?.description
                    }
                    tooltipVisible={false}
                    onChange={(value: number) =>
                      onChangeValue(
                        item?.entityId,
                        item.scales[value].orderScale +
                          ' - ' +
                          item.scales[value].description,
                        value,
                      )
                    }
                  />
                </BoxSlider>
              </Question>
              <BoxDescription>
                <div
                  className="description"
                  id={'description-' + item?.entityId}
                >
                  {answers.find(
                    (i: any) => i.question?.entityId === item?.entityId,
                  )?.answer
                    ? item.scales[
                        answers.find(
                          (i: any) => i.question?.entityId === item?.entityId,
                        )?.answer as number
                      ].orderScale +
                      ' - ' +
                      item.scales[
                        answers.find(
                          (i: any) => i.question?.entityId === item?.entityId,
                        )?.answer as number
                      ].description
                    : 'Esta questão ainda não foi respondida'}
                </div>
              </BoxDescription>
            </Line>
          );
        })}
      </div>
    );
  };

  const createContent = () => {
    pillars.map((pillar, index) => {
      content.push({
        tab: pillar.name,
        body: generateItemsForm(pillar),
      });
    });

    return content;
  };

  const getAnswers = () => {
    showLoading();
    api
      .get('answer/list/' + state.entityId)
      .then((response: any) => {
        setAnswers(response.data);
        setData(response.data);
      })
      .finally(hideLoading);
  };

  createContent();

  useEffect(() => {
    getMethodology();
    getAnswers();
  }, []);

  useEffect(() => {
    getAnswers();
    if (Number(activeKey) + 1 === pillars.length) {
      setNameButton('Finalizar');
    } else {
      setNameButton('Proximo');
    }
  }, [activeKey]);

  function handleCancel() {
    setIsOpenModalFinish(false);
    navigate(-1);
  }

  return (
    <>
      <Loading
        spinning={loading}
        width={200}
        height={200}
        position={'absolute'}
      >
        <ContentBase
          questionIsVisible={false}
          title="Diagnóstico da sua Startup"
          content={
            <Tab
              activeKey={activeKey}
              content={content}
              changeActiveKey={(value: any) => {
                setActiveKey(value);
                save(false);
              }}
              onSaved={() => save(false)}
            />
          }
          spacingFooterPage={true}
          modalContent={<DiagnosisInfo />}
        />

        <FooterPage save={() => save(true)} text={nameButton} />

        <AlertMessage
          visible={isOpenModalFinish}
          message="Parabéns!"
          description="Você finalizou o diagnóstico da sua startup."
          textCancelButton="Fechar"
          textOkButton="Gerar relatório"
          onOk={onFinish}
          onCancel={handleCancel}
        />

        <DiagnosticResult
          show={showResult}
          close={() => {
            setShowResult(false);
            navigate(-1);
          }}
          startup={state.startup}
          diagnostic={state}
        />
      </Loading>
    </>
  );
}

export default DiagnosisForm;
