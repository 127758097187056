import React, { useEffect, useState } from 'react';
import PersonalInfo from '../../../../components/Profiles/PersonalInfo/PersonalInfo';
import ProfileBase from '../../../../components/Profiles/ProfilesBase';
import Title from '../../../../components/Title';
import { BoxContainer, BoxWhite } from '../../../../GlobalStyles';
import { BoxMentorShip, MentorshipCard, Summary } from '../style';
import { useNavigate, useParams } from 'react-router-dom';
import MentorService from '../../../../services/MentorService';
import { IMentor } from '../../../../core/interfaces/IMentor';
import MentoringService from '../../../../services/MentoringService';
import { IMentoring } from '../../../../core/interfaces/IMentoring';
import { notification } from 'antd';

function MentorProfile() {
  const params = useParams();
  const naviage = useNavigate();
  const mentorService = MentorService.init();
  const mentoringService = MentoringService.init();
  const [mentor, setMentor] = useState<IMentor>();
  const [mentoring, setMentoring] = useState<IMentoring[]>([]);
  const [dataObject, setDataObject] = useState<any>({
    name: 'Mentor Senhor Tal',
    description:
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
    entry: '2023/01/03',
    lastUpdate: '2023/01/03',
    status: 'Ativo',
    email: 'mentorsenhortal@gmail.com',
  });

  const getMentorById = () => {
    mentorService
      .getMentorById(Number(params.id))
      .then((response) => {
        setMentor(response);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao buscar mentor',
          description:
            'Ocorreu um erro ao buscar o mentor, tente novamente mais tarde',
        });
      });
  };

  const getMentoringByMentorId = () => {
    mentoringService
      .getMentoringByMentor(Number(params.id))
      .then((response) => {
        setMentoring(response);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao buscar mentorias',
          description:
            'Ocorreu um erro ao buscar as mentorias, tente novamente mais tarde',
        });
      });
  };

  const generateDataObject = () => {
    setDataObject({
      name: mentor?.user?.name,
      description:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
      entry: mentor?.creation,
      lastUpdate: mentor?.lastUpdated,
      status: mentor?.active ? 'Ativo' : 'Inativo',
      email: mentor?.user?.email,
    });
  };

  const goToMentoring = (id: number) => {
    naviage(`schedule/${id}`);
  };

  useEffect(() => {
    getMentorById();
    getMentoringByMentorId();
  }, []);

  useEffect(() => {
    generateDataObject();
  }, [mentor]);

  return (
    <BoxContainer>
      <ProfileBase
        title="Perfil mentor"
        entity={dataObject}
        goBackPath="mentors"
      />
      <PersonalInfo />
      <BoxWhite>
        <Title size={16} weight={700}>
          Temas de mentoria
        </Title>
        <BoxMentorShip>
          {mentoring.map((item, i) => (
            <MentorshipCard
              style={{ cursor: 'pointer' }}
              onClick={() => goToMentoring(item?.entityId as number)}
              key={i}
              align="flex-start"
              width="320px"
              height="140px"
            >
              <Title size={16} color="var(--thirdy-color)">
                {item?.title}
              </Title>
              <Summary>{item?.description}</Summary>
              <div className="price">R$ {item?.priceHour}</div>
            </MentorshipCard>
          ))}
        </BoxMentorShip>
      </BoxWhite>
      <BoxWhite>
        {'temos uma padrão dessa mesma lista, verificar dados para reutilizar!'}
      </BoxWhite>
    </BoxContainer>
  );
}

export default MentorProfile;
