import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  button {
    background: var(--thirdy-color);
    margin-top: 24px;
    border-bottom-color: #521452;
  }
`;

export default Box;
