import styled from 'styled-components';

const BoxCalendar = styled.div<{
  titleContent?: string;
}>`
  display: flex;
  flex-direction: column;

  & .react-calendar__navigation {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 10px 10px;
    position: relative;
    margin-bottom: 5px;

    & .react-calendar__navigation__label {
      flex-grow: unset !important;
      background-color: transparent;
      border: none;
      padding: 0 20px;

      & .react-calendar__navigation__label__labelText {
        font-weight: bold;
      }
    }

    & .react-calendar__navigation__arrow {
      height: 24px;
      background-color: var(--white-color);
      border: 1px solid var(--support-color);
      border-radius: 6px;
      padding: 0 8px;
      cursor: pointer;
    }

    ::before {
      content: ${({ titleContent }) =>
        titleContent ? `'${titleContent}'` : ''};
      position: absolute;
      left: 10px;
      font-weight: bold;

      @media (max-width: 500px) {
        position: relative;
        width: 100%;
        text-align: center;
      }
    }
  }

  &
    .react-calendar__viewContainer
    .react-calendar__month-view__weekdays__weekday {
    display: flex;
    align-items: center !important;
    justify-content: center;
    background-color: var(--primary-color);

    abbr {
      text-decoration: none;
      color: var(--white-color);
      font-weight: bold;
      padding: 6px 0;
    }
  }

  & .react-calendar__month-view__days {
    .schedule,
    .available,
    .today {
      abbr {
        position: relative;
        padding: 4px 10px;
        border-radius: 50%;
      }
    }

    .schedule abbr,
    .available abbr {
      &::after {
        content: '';
        border-radius: 100%;
        position: absolute;
        height: 10px;
        width: 10px;
        top: 8px;
        left: -6px;

        @media (max-width: 500px) {
          height: 8px;
          width: 8px;
          left: 0;
        }
      }
    }

    .disabled abbr {
      opacity: 0.5;
    }

    .schedule abbr {
      &::after {
        background: var(--thirdy-color);
      }
    }

    .available abbr {
      &::after {
        border: 1px solid var(--primary-color);
      }
    }

    .today abbr {
      &::before {
        content: '';
        border-radius: 100%;
        position: absolute;
        background-color: var(--primary-color);
        height: 10px;
        width: 10px;
        top: -6px;
        right: 0;

        @media (max-width: 500px) {
          height: 8px;
          width: 8px;
        }
      }
    }

    & .react-calendar__tile {
      padding: 10px 0;
      border: none;
      background-color: var(--white-color);

      abbr {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  & .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--support-color) !important;
    pointer-events: none !important;

    abbr {
      cursor: default !important;
    }
  }
  /* CASO MUDEM DE IDEIA - MUDA A COR DOS FINAIS DE SEMANA */
  /* & .react-calendar__month-view__days__day--weekend {
    color: var(--tertiary-color);
  } */
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.8rem;
  padding: 10px 0;

  & .scheduled,
  .today,
  .avaiable {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 10px 0 20px;
  }

  & .avaiable {
    border: 1px solid var(--primary-color);
  }

  & .scheduled {
    background-color: var(--thirdy-color);
  }

  & .today {
    background-color: var(--primary-color);
  }
`;

export { BoxCalendar, Legend };
