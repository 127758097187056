import { Empty } from 'antd';
import React from 'react';
import Text from '../../Text';

function NoConversationSelected() {
  return (
    <Empty
      style={{ margin: 'auto' }}
      description={
        <Text size={16} weight={600}>
          Não há conversa selecionada
        </Text>
      }
    />
  );
}

export default NoConversationSelected;
