import { useCallback, useMemo, useState } from 'react';
import { notification } from '../../helpers/notification.helper';
import CrudService from '../../services/CrudService';
import MentorService from '../../services/MentorService';
import { IMentor } from '../interfaces/IMentor';
import IPageable from '../interfaces/IPageable';
import useLoading from './useLoading';

const useAcceleratorMentor = () => {
  const { loading, hideLoading, showLoading } = useLoading();
  const [mentors, setMentors] = useState<IPageable<IMentor>>(
    CrudService.pageableDefault,
  );

  const mentorService = useMemo(() => MentorService.init(), []);

  const getMentors = useCallback(
    (id: number, params: Record<string, unknown>) => {
      showLoading();
      return mentorService
        .findAllByAccelerator(id, { ...params, size: 10 })
        .then((data) => {
          setMentors(data);
          return data;
        })
        .catch(() => notification.error('Erro ao listar mentores'))
        .finally(hideLoading);
    },
    [hideLoading, showLoading, mentorService],
  );

  return {
    loading,
    mentors,
    getMentors,
  };
};

export default useAcceleratorMentor;
