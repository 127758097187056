import styled from 'styled-components';

const BoxTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.div<{
  url: string;
  borderType: boolean | undefined;
}>`
  display: flex;
  width: 100%;
  height: 60px;
  background-image: url(${({ url }) => (url ? url : '')});
  background-size: 100% 60px;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 60px;
  position: relative;

  img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 10px;
    left: 10px;
    border-radius: ${({ borderType }) => (borderType ? '50%' : '0')};
    border: 2px solid var(--white-color);
  }

  & .actions {
    position: absolute;
    top: 70px;
    right: 0;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 16px;
  flex-wrap: wrap;

  & .column {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.8rem;
      color: var(--support-color);

      a {
        font-size: 0.8rem;
        color: var(--thirdy-color);
        text-decoration: underline;
      }
    }
  }
`;

const BoxInfoContent = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;

  @media (max-width: 1000px) {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;

  & .specialization {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    span {
      font-size: 0.8rem;
      padding: 0 4px;
      border: 1px solid var(--support-color);
      border-radius: 20px;
    }
  }

  & .school {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      margin-right: 10px;
    }
    span {
      flex-direction: column;
      font-size: 0.8rem;

      :first-child {
        font-weight: bold;
      }
    }
  }

  a {
    word-break: break-all;
    word-wrap: break-word;
    font-size: 0.8rem;
    color: var(--thirdy-color);
    text-decoration: underline;
  }
`;

const BoxActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: -50px;

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--thirdy-color);
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--white-color);
    }
  }
`;

export { BoxTitle, Header, Info, BoxInfo, BoxInfoContent, BoxActions };
