import { useEffect, useMemo, useState } from 'react';
import { BoxCalendar, Legend } from './style';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { Divider, notification } from 'antd';
import { BoxContainer } from '../../GlobalStyles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ISchedule } from '../../core/interfaces/ISchedule';
import ScheduleService from '../../services/ScheduleService';
import { useAuth } from '../../core/hooks/auth';
import { SQL_FORMAT } from '../../constants/date-formats';

type minDetail = 'century' | 'decade' | 'year' | 'month';
type weekDay = 'oneLetter' | 'abbreviation';

interface IProps {
  title?: string;
  /**
   * @param "use number of years you want to go foward"
   * @example 5
   * @description "assuming the year is 2022, the result is 2027"
   */
  maxDateRange?: number;
  /**
   * @param "use number of years you want to go backward"
   * @example 3
   * @description "assuming the year is 2022, the result is 2019"
   */
  minDateRange?: number;
  minDetail?: minDetail;
  weekDay?: weekDay;
  onClickDay?: () => void;
}

function ScheduleCalendar({
  title,
  maxDateRange = 1,
  minDateRange = -1,
  minDetail = 'decade',
  weekDay = 'oneLetter',
}: IProps) {
  const today = new Date();

  const [dateValue, setDatevalue] = useState<Date>(() => today);
  const weekDayNames =
    weekDay === 'oneLetter'
      ? ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
      : ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const { logged } = useAuth();
  const userId = logged?.entityId;

  const navigate = useNavigate();
  const scheduleService = useMemo(() => ScheduleService.init(), []);

  const [schedules, setSchedule] = useState<ISchedule[]>([]);

  const getDate = (yearPoint: number) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + yearPoint);

    return date;
  };

  const getSchedule = async () => {
    scheduleService
      .getAllSchedulesByMentor(Number(userId))
      .then((data) => setSchedule(data))
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar agendamentos!',
          description:
            'Desculpe, não foi possível buscar os agendamentos. Tente novamente mais tarde.',
        });
      });
  };

  const clickDayAction = (value: Date) => {
    navigate('/mentorship/scheduling', {
      state: {
        date: value,
      },
    });
  };

  const checkDay = (date: Date) => {
    const paramDate = moment(date).format(SQL_FORMAT);
    let classes = '';

    if (moment(today).format(SQL_FORMAT) === paramDate) {
      classes += ' today';
    }

    schedules.forEach((item) => {
      if (
        moment(item.initialDate).format(SQL_FORMAT) ===
        moment(date).format(SQL_FORMAT)
      ) {
        classes += ' schedule';
      } else {
        classes += ' available';
      }
    });

    return classes;
  };

  useEffect(() => {
    getSchedule();
  }, []);

  return (
    <BoxCalendar titleContent={title}>
      <Calendar
        locale="pt-BR"
        value={dateValue}
        defaultValue={today}
        formatShortWeekday={(locale, value) => weekDayNames[value.getDay()]}
        maxDate={getDate(maxDateRange)}
        minDate={getDate(minDateRange)}
        minDetail={minDetail}
        onChange={setDatevalue}
        onClickDay={(value) => clickDayAction(value)}
        tileClassName={({ date }: CalendarTileProperties) => checkDay(date)}
      />
      <Divider style={{ backgroundColor: 'var(--support-color)', margin: 0 }} />
      <BoxContainer flexDirection="row" noPadding={true}>
        <Legend>
          <span className="today">&nbsp;</span>
          Dia atual
        </Legend>
        <Legend>
          <span className="avaiable">&nbsp;</span>
          Dias disponíveis
        </Legend>
        <Legend>
          <span className="scheduled">&nbsp;</span>
          Dias agendados
        </Legend>
      </BoxContainer>
    </BoxCalendar>
  );
}

export default ScheduleCalendar;
