import styled from 'styled-components';

const BoxCollapse = styled.div`
  display: flex;
  flex-direction: column;

  .ant-collapse-header-text {
    width: 100%;
    margin-right: 20px;
  }

  .ant-collapse-extra {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    padding: 15px;
  }

  .ant-collapse-extra:hover {
    border-radius: 50%;
    background-color: var(--grey-color);
  }
`;

const BoxFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  img {
    width: 35px;
    margin-right: 10px;
  }
`;

export { BoxCollapse, BoxFile };
