import React, { useEffect } from 'react';
import { BoxContainer, ButtonContainer, Content } from '../../style';
import { Button } from 'antd';
import { Container } from '../../../../GlobalStyles';

interface IParams {
  content: any;
  buttonText: string;
  buttonState: boolean;
  finishHandler: () => void;
  formName?: string | undefined;
}

function Profile(props: IParams) {
  const [isButtonForm, setIsButtonForm] = React.useState<boolean>(true);
  useEffect(() => {
    if (props.formName) {
      setIsButtonForm(true);
    } else {
      setIsButtonForm(false);
    }
  }, [props.formName]);
  return (
    <Content isLight={true}>
      <Container>
        <BoxContainer position="initial" maxWidth="1000px" width="100%">
          {props.content}
          <ButtonContainer position="right">
            {isButtonForm ? (
              <>
                <Button
                  form={props.formName}
                  type="primary"
                  key="submit"
                  htmlType="submit"
                  disabled={props.buttonState}
                >
                  {props.buttonText}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  onClick={() => props.finishHandler()}
                  disabled={props.buttonState}
                >
                  {props.buttonText}
                </Button>
              </>
            )}
          </ButtonContainer>
        </BoxContainer>
      </Container>
    </Content>
  );
}

export default Profile;
