import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

type ParamsQuestion = {
  pillarId: number;
  valuationId: number;
};

type ParamsAnswer = {
  valuationResultId: number;
  pillarId: number;
};

export class ValuationAnswerService {
  private requestMapping = 'valuation';

  public static init(): ValuationAnswerService {
    return new ValuationAnswerService();
  }

  getQuestion({ pillarId, valuationId }: ParamsQuestion) {
    return api
      .get(`${this.requestMapping}/questions/getByClient/${pillarId}`, {
        params: {
          valuation: valuationId,
        },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  getAnswer({ pillarId, valuationResultId }: ParamsAnswer) {
    return api
      .get(`${this.requestMapping}/answer/list/${valuationResultId}`, {
        params: {
          idPillar: pillarId,
        },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}
