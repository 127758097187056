import styled from 'styled-components';

const BoxDropFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;

  .error-message {
    font-size: 0.8rem;
    font-style: italic;
    color: var(--error-color);
  }
`;

const DropFileInput = styled.div`
  position: relative;
  width: 100%;
  min-height: 160px;
  border: 1px dashed var(--support-color);
  border-radius: 10px;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  :hover,
  .dragover {
    opacity: 0.6;
  }

  .drop-file-input__label {
    text-align: center;
    color: var(--primary-color);
    padding: 10px;

    p {
      margin: 0;
      font-size: 0.9rem;
      font-weight: bold;
    }

    span {
      font-size: 0.8rem;
      font-style: italic;
    }
  }

  .drop-file-input__label img {
    width: 100px;
  }
`;

const DropFilePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;

  .drop-file-preview__title {
    text-align: center;
    font-style: italic;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .drop-file-preview__item {
    display: flex;
    width: 100%;
    position: relative;
    background-color: var(--grey-color);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2px;
  }

  .drop-file-preview__item img {
    height: 50px;
    margin-right: 10px;
  }

  .drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .drop-file-preview__item__del {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: inset 0 0 2px #000;
    background-color: var(--white-color);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
  }
`;

export { BoxDropFile, DropFileInput, DropFilePreview };
