import React, { useState, useEffect, useRef } from 'react';
import List from '../../components/list';
import DiagnosisForm from './DiagnosisForm';
import { useAuth } from '../../core/hooks/auth';
import { useNavigate } from 'react-router-dom';
import BoxStatus from '../../components/BoxStatus/BoxStatus';
import { IListRef } from '../../core/interfaces/IListRef';

function DiagnosisList() {
  const [formName, setFormName] = useState<string>('');
  const { logged } = useAuth();
  const id = logged?.client;
  const navigate = useNavigate();
  const refList = useRef<IListRef>(null);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, phase: any) => {
        return (
          <strong style={{ display: 'block', minWidth: '150px' }}>
            {`${name} - (${phase.phase?.name})`}
          </strong>
        );
      },
    },
    {
      title: 'Fase',
      dataIndex: 'phase',
      key: 'phase',
      render: (phase: any) => {
        return <div style={{ minWidth: '120px' }}>{phase?.name}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (status: boolean) => {
        return <BoxStatus status={status}></BoxStatus>;
      },
    },
    {
      title: 'Nº de startups',
      dataIndex: 'countStartups',
      key: 'countStartups',
      render: (countStartups: number | null) => {
        return Number(countStartups);
      },
    },
    {
      title: 'Data',
      dataIndex: 'creation',
      key: 'creation',
    },
  ];

  const clearFields = () => {
    setTimeout(() => {
      const questionsStorage = Object.keys(sessionStorage).filter((key) =>
        key.includes('form-question-methodology'),
      );
      questionsStorage
        .concat('phases-diagnostic')
        .concat('method-diagnostic')
        .forEach((key) => sessionStorage.removeItem(key));
    }, 800);
  };

  useEffect(() => {
    clearFields();
  }, []);

  return (
    <>
      <List
        ref={refList}
        id="form-diagnosis"
        model="diagnosis"
        dataSource="diagnosis/list"
        columns={columns}
        edit={true}
        form={() => (
          <DiagnosisForm
            closeForm={() => {
              refList.current?.closeDrawer();
            }}
            setFormName={setFormName}
          />
        )}
        formName={formName}
        params={{
          client: id,
        }}
        view={(row) =>
          navigate('/diagnostics/view', { state: { id: row.entityId } })
        }
        closeDrawer={clearFields}
        title="Diagnóstico"
        modalWidth={'100%'}
        modalBackgroundColor="var(--grey-color)"
        paginationBottom="bottomRight"
      />
    </>
  );
}

export default DiagnosisList;
