import { Col, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IBusinessInformation from '../../../../core/interfaces/IBusinessInformation';
import { Card, Label } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import ProfileDashboardService from '../../../../services/profile-dashboard/ProfileDashboardService';
import BusinessInformationForm from '../BusinessInformationForm';
import { useModal } from 'sunflower-antd';

function BusinessInformation() {
  const { logged } = useAuth();
  const { loading, showLoading, hideLoading } = useLoading();
  const formModal = useModal({
    defaultVisible: false,
  });

  const [information, setInformation] = useState<IBusinessInformation>({
    businessAreas: '...',
    businessModel: '...',
    problematicTheme: '...',
    solutionProblem: '...',
  });

  const profileDashboardService = useMemo(
    () => ProfileDashboardService.init(),
    [],
  );

  const getInformation = useCallback(() => {
    const { entityId = 0 } = logged ?? {};
    showLoading();

    profileDashboardService
      .getBusinessInformation(entityId)
      .then((data) => setInformation(data))
      .catch(() => {
        notification.info('Erro ao listar informações de negócio');
      })
      .finally(hideLoading);
  }, [logged, profileDashboardService, showLoading, hideLoading]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const metaData = [
    {
      col: 6,
      label: 'Problemática tema',
      value: (
        <Text color="#9AA4A8" weight={600}>
          {information.problematicTheme}
        </Text>
      ),
    },
    {
      col: 4,
      label: 'Solução do problema',
      value: (
        <Text color="#9AA4A8" weight={600}>
          {information.solutionProblem}
        </Text>
      ),
    },
    {
      label: 'Áreas do negócio',
      value: <Label>{information.businessAreas}</Label>,
    },
    {
      label: 'Modelo de negócio',
      value: <Label>{information.businessModel}</Label>,
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <Card boxShadow="0px 3px 6px #05233D29" padding="12px 22px">
          <Row
            style={{ marginBottom: '12px' }}
            justify="space-between"
            wrap={true}
            gutter={[12, 12]}
            align="middle"
          >
            <Col flex={1}>
              <Title size={20} tag="h2">
                Informações de negócio
              </Title>
            </Col>
            <Col>
              <Tooltip
                title="Editar"
                color="#fff"
                placement="bottomRight"
                overlayClassName="text-tooltip-primary"
              >
                <FiEdit2 onClick={formModal.show} cursor="pointer" size={20} />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            {metaData.map((data, index) => (
              <Col span={data.col} key={`key_information_${index}`}>
                <Text margin="0 0 6px 0" weight={600}>
                  {data.label}
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {data.value}
                </Text>
              </Col>
            ))}
          </Row>
        </Card>
      </Spin>

      <BusinessInformationForm
        {...formModal.modalProps}
        reload={getInformation}
        information={information}
      />
    </>
  );
}

export default BusinessInformation;
