import React, { useState, useMemo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  notification,
  Row,
  Space,
  Spin,
} from 'antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import {
  IconOptions,
  MenuOption,
  PurpleButton,
} from '../../../../GlobalStyles';
import KpiService from '../../../../services/KpiService';
import IKpiComment from '../../../../core/interfaces/models/IKpiComment';
import ConfirmAction from '../../../../components/ConfirmAction';
import { isEmpty } from 'lodash';

interface Props {
  kpiId?: number;
  reload(): void;
  comments: IKpiComment[];
  isNewComment: boolean;
  cancelIsNewComment(): void;
}

function CommentsKpiDetails({
  kpiId,
  reload,
  comments,
  isNewComment,
  cancelIsNewComment,
}: Props) {
  const [form] = Form.useForm();

  const kpiService = useMemo(() => KpiService.init(), []);
  const [commentId, setCommentId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const sendComment = (comment?: IKpiComment) => {
    const data = {
      ...comment,
      comment: form.getFieldValue('comment'),
      performanceIndicator: {
        entityId: kpiId,
      },
    };

    setLoading(true);
    kpiService
      .addComment(data)
      .then(() => {
        reload();
        cancelIsNewComment();
        setCommentId(0);
        form.resetFields();

        notification.success({
          message: 'Comentário salvo com sucesso',
          duration: 3.5,
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao salvar comentário',
          duration: 3.5,
        });
      })
      .finally(() => setLoading(false));
  };

  const actionsComment = (comment?: IKpiComment) => (
    <Row gutter={16} style={{ marginBottom: '25px' }}>
      <Col>
        <PurpleButton
          size="small"
          type="primary"
          onClick={() => {
            form.validateFields().then(() => sendComment(comment));
          }}
        >
          Comentar
        </PurpleButton>
      </Col>
      <Col>
        <Button
          size="small"
          type="default"
          htmlType="button"
          onClick={() => {
            setCommentId(0);
            form.resetFields();
            cancelIsNewComment();
          }}
        >
          Cancelar
        </Button>
      </Col>
    </Row>
  );

  const fieldComment = () => (
    <Form form={form} name="form-comment">
      <Form.Item
        name={'comment'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input placeholder="Seu comentário" maxLength={500} />
      </Form.Item>
    </Form>
  );

  const removeComment = (commentId?: number) => {
    setLoading(true);
    kpiService
      .removeComment(Number(commentId))
      .then(() => {
        reload();
        notification.success({
          message: 'Comentário removido com sucesso',
          duration: 3.5,
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao remover comentário',
          duration: 3.5,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin delay={300} spinning={loading}>
      <Title margin="0 0 8px 0" size={16}>
        Comentários
      </Title>

      {isEmpty(comments) && (
        <Text align="center" weight={600}>
          Comentários vazios
        </Text>
      )}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        {comments.map((comment) => {
          const isEditing = comment.entityId === commentId;
          return (
            <Row
              key={comment.entityId}
              align="middle"
              gutter={16}
              wrap={isEditing}
            >
              <Col flex={1}>
                {isEditing ? (
                  fieldComment()
                ) : (
                  <Text size={17}>{comment.comment}</Text>
                )}
              </Col>
              <Col>
                {isEditing ? (
                  actionsComment(comment)
                ) : (
                  <Dropdown
                    trigger={['click']}
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <MenuOption
                              onClick={() => {
                                setCommentId(Number(comment.entityId));
                                form.setFieldValue('comment', comment.comment);
                              }}
                            >
                              <p>Editar comentário</p>
                            </MenuOption>
                          ),
                        },
                        {
                          key: '2',
                          label: (
                            <MenuOption>
                              <ConfirmAction
                                title="Deseja remover este comentário?"
                                onConfirm={() =>
                                  removeComment(comment.entityId)
                                }
                              >
                                <p>Excluir comentário</p>
                              </ConfirmAction>
                            </MenuOption>
                          ),
                        },
                      ],
                    }}
                  >
                    <IconOptions cursor="pointer" display="flex" size="22px">
                      <MoreOutlined />
                    </IconOptions>
                  </Dropdown>
                )}
              </Col>
            </Row>
          );
        })}
        {isNewComment && (
          <>
            <Divider style={{ margin: '8px 0' }} />
            <Title size={16}>Novo Comentário</Title>
            <Row align="middle" gutter={16}>
              <Col flex={1}>{fieldComment()}</Col>
              <Col>{actionsComment()}</Col>
            </Row>
          </>
        )}
      </Space>
    </Spin>
  );
}

export default CommentsKpiDetails;
