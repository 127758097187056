import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  margin-right: 10px;
  width: 200px;
  height: 350px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center !important;
  font-weight: bold;
`;

const Description = styled.div`
  text-align: justify;
`;

export { BoxContent, Card, Description, Title };
