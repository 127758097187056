import { LeftOutlined } from '@ant-design/icons';
import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from './style';

interface IProps {
  enableText?: boolean;
  enableBackground?: boolean;
  path?: string;
  goBackFunction?: () => void;
  style?: CSSProperties;
}

function GoBack({
  enableText,
  enableBackground,
  path,
  goBackFunction,
  style,
}: IProps) {
  const navigate = useNavigate();
  const pathname = path ? '/' + path : '/';

  const checkPath = () => {
    return path ? navigate(-1) : navigate(pathname);
  };

  const onClickHandle = () => {
    goBackFunction ? goBackFunction() : checkPath();
  };

  return (
    <Box
      style={{ ...style }}
      enableBackground={enableBackground}
      enableText={enableText}
      onClick={onClickHandle}
    >
      <LeftOutlined /> {enableText ? 'Voltar' : ''}
    </Box>
  );
}

export default GoBack;
