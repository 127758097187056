import styled from 'styled-components';

const Box = styled.div<{
  width?: string;
  height?: string;
  background?: string;
  colorHover?: string;
  margin?: string;
  backgroundHover?: string;
}>`
  margin: ${({ margin }) => margin || '0 auto'};
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: max-content;
  cursor: pointer;
  float: start;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    & button {
      color: ${({ colorHover }) => colorHover || 'var(--white-color)'};
      background: ${({ backgroundHover }) =>
        backgroundHover || 'var(--primary-color)'};
    }

    & button [data-icon] {
      transform: rotate(45deg);
    }

    & [data-text] {
      animation: show-right 0.5s forwards;
    }
  }

  & [data-icon] {
    transition: transform 0.3s;
  }

  & [data-text] {
    opacity: 0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--primary-color);
    font-family: 'Segoe UI', sans-serif;
  }

  @keyframes show-right {
    from {
      opacity: 0;
      transform: translate3d(-30px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export { Box };
