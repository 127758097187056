import React, { useEffect, useState, useMemo, useCallback } from 'react';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import { BoxContainer, PurpleButton } from '../../../../GlobalStyles';
import {
  CheckSquareOutlined,
  CommentOutlined,
  DislikeOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import {
  Box,
  BoxLine,
  BoxQuestions,
  Header,
  Info,
  Option,
  CorrectAnswer,
  Point,
  BoxAnswer,
  BoxApproval,
} from './style';
import { ImageConfig } from '../../../../components/DragAndDropFile/ImageConfig';
import ExerciseService from '../../../../services/exercise/ExerciseService';
import { useNavigate, useParams } from 'react-router-dom';
import IExerciseWithStatus, {
  IExerciseQuizWithStatus,
} from '../../../../core/interfaces/exercise/IExerciseWithStatus';
import { notification } from '../../../../helpers/notification.helper';
import { ResetMarginDescription } from '../../../../components/AttendCourse/styles';
import FileService from '../../../../services/FileService';
import Text from '../../../../components/Text';
import {
  getDescription,
  StatusAnswerEnum,
} from '../../../../core/enums/StatusAnswerEnum';
import { Button, Col, Form, Input, Row } from 'antd';
import { RiArrowGoBackLine } from 'react-icons/ri';
import ActionsCrud from '../../../../components/ActionsCrud';
import { EnabledActionsCrudEnum } from '../../../../core/interfaces/IActionsCrud';
import TeacherResponseService from '../../../../services/exercise/TeacherResponseService';
import useLoading from '../../../../core/hooks/useLoading';
import { isNull } from 'lodash';

/**
 * Código criado por ranniere
 */

type IContentResponse = {
  status: StatusAnswerEnum;
  comment?: string;
};

function ExerciseCorrection() {
  const itemType = 'pdf';
  const navigate = useNavigate();
  const exerciseLoading = useLoading();

  const { userId, exerciseId } = useParams();
  const fileService = useMemo(() => FileService.init(), []);
  const exerciseService = useMemo(() => ExerciseService.init(), []);
  const teacherResponseService = useMemo(
    () => TeacherResponseService.init(),
    [],
  );

  const [commentQuizId, setCommentQuizId] = useState(0);
  const [responses, setResponses] = useState<Record<number, IContentResponse>>(
    {},
  );
  const [exercise, setExercise] = useState<IExerciseWithStatus>({
    name: '',
    isPendingApproval: false,
    grade: null,
    quizzes: [],
    description: '',
  });

  const changeStatus = (quizId: number, status: StatusAnswerEnum) => {
    const oldQuiz = responses[quizId] ?? {};
    setResponses({
      ...responses,
      [quizId]: { ...oldQuiz, status },
    });
  };

  const changeComment = (quizId: number, comment: string) => {
    const oldQuiz = responses[quizId] ?? {};
    setResponses({
      ...responses,
      [quizId]: { ...oldQuiz, comment },
    });
  };

  const getQuestionType = (exerciseQuiz: IExerciseQuizWithStatus) => {
    const { quiz, answers, status, fileName } = exerciseQuiz;

    if (['OBJECTIVE', 'MULTIPLE'].includes(quiz.type.toString())) {
      return (
        <>
          {quiz.objectiveQuestions?.map((objective, index) => (
            <BoxLine
              flexDirection="row"
              border={objective.status}
              key={`box_line_${objective.entityId}`}
            >
              <Option width="calc(100% - 100px)">
                <Point>
                  {answers.includes(objective.description) ? (
                    <div className="chosen">&nbsp;</div>
                  ) : (
                    ''
                  )}
                </Point>
                <div className="text">{objective.description}</div>
              </Option>
              <CorrectAnswer>
                {objective.status ? (
                  <CheckSquareOutlined title="Alternativa correta" />
                ) : (
                  <Point border="0%" />
                )}
              </CorrectAnswer>
            </BoxLine>
          ))}
        </>
      );
    } else {
      const isAttachment = quiz.type.toString().includes('ATTACHMENT');
      const quizId = Number(quiz.entityId);
      const { status: statusRespose, comment } = responses[quizId] ?? {};

      return (
        <>
          <BoxAnswer>
            {isAttachment ? (
              <div
                className="image"
                onClick={() => fileName && fileService.openByName(fileName)}
              >
                <img
                  src={ImageConfig[itemType] || ImageConfig['default']}
                  alt=""
                />
                <span>Arquivo</span>
              </div>
            ) : (
              answers.at(0) || 'Aguardando resposta'
            )}
          </BoxAnswer>
          <BoxApproval>
            {(status && status !== StatusAnswerEnum.Pending) ||
            (statusRespose && statusRespose !== StatusAnswerEnum.Pending) ? (
              <Text
                weight={600}
                color={
                  (statusRespose || status) === StatusAnswerEnum.Approved
                    ? '#319125'
                    : '#EC1616'
                }
              >
                {getDescription(statusRespose ?? status)}
              </Text>
            ) : (
              <>
                <span>Como deseja avaliar essa questão?</span>
                <LikeOutlined
                  onClick={() =>
                    changeStatus(quizId, StatusAnswerEnum.Approved)
                  }
                />
                <DislikeOutlined
                  onClick={() =>
                    changeStatus(quizId, StatusAnswerEnum.Disapproved)
                  }
                />
              </>
            )}
            {!comment && StatusAnswerEnum.Pending === status && (
              <div className="comment">
                <CommentOutlined
                  onClick={() =>
                    setCommentQuizId(commentQuizId === quizId ? 0 : quizId)
                  }
                />
              </div>
            )}

            {statusRespose && statusRespose !== StatusAnswerEnum.Pending && (
              <Button
                type="primary"
                size="small"
                onClick={() => changeStatus(quizId, StatusAnswerEnum.Pending)}
                icon={<RiArrowGoBackLine />}
              />
            )}
          </BoxApproval>
          {(exerciseQuiz.comment || comment) && (
            <Row justify={'space-between'}>
              <Col span={24}>
                <Title size={16} margin="8px 0 0 0" lineHeight={1.3}>
                  Comentários
                </Title>
              </Col>
              <Col>
                <Text>{exerciseQuiz.comment || comment}</Text>
              </Col>
              {comment && (
                <Col>
                  <ActionsCrud
                    edit={() => setCommentQuizId(quizId)}
                    remove={() => changeComment(quizId, '')}
                    enabledActions={[
                      EnabledActionsCrudEnum.REMOVE,
                      EnabledActionsCrudEnum.EDIT,
                    ]}
                  />
                </Col>
              )}
            </Row>
          )}
          {commentQuizId === quizId && (
            <Form
              style={{ marginTop: '1rem' }}
              onFinish={({ comment }) => {
                setCommentQuizId(0);
                changeComment(quizId, comment);
              }}
              initialValues={{
                comment,
              }}
            >
              <Row gutter={16} align={'middle'}>
                <Col flex={1}>
                  <Form.Item
                    name={'comment'}
                    style={{ margin: '0' }}
                    rules={[
                      { required: true, message: 'Campo obrigatório' },
                      {
                        max: 255,
                        message:
                          'O campo deve possuir no máximo 255 caracteres',
                      },
                    ]}
                  >
                    <Input placeholder="Seu comentário" />
                  </Form.Item>
                </Col>
                <Col>
                  <PurpleButton htmlType="submit" size="small" type="primary">
                    Comentar
                  </PurpleButton>
                </Col>
                <Col>
                  <Button
                    htmlType="button"
                    size="small"
                    type="default"
                    onClick={() => setCommentQuizId(0)}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </>
      );
    }
  };

  const getData = () => {
    return {
      studentId: userId,
      exerciseId: exerciseId,
      responses: Object.entries(responses).map(([quizId, content]) => {
        const { status, comment } = content;
        return {
          status,
          exerciseQuizId: quizId,
          ...(comment && { comment }),
        };
      }),
    };
  };

  const formIsValid = (): boolean => {
    const quantityResponses = Object.keys(responses).length;
    const quantityQuiz = exercise.quizzes.filter(
      ({ quiz, status }) =>
        ['DISCURSIVE', 'ATTACHMENT'].includes(quiz.type.toString()) &&
        status === StatusAnswerEnum.Pending,
    ).length;

    return quantityQuiz === quantityResponses;
  };

  const sendApproval = () => {
    if (formIsValid()) {
      exerciseLoading.showLoading();
      teacherResponseService
        .create(getData())
        .then(() => {
          notification.success('Nota lançada com sucesso');
          navigate(-1);
        })
        .catch(() => {
          notification.success('Erro ao lançar nota');
        })
        .finally(exerciseLoading.hideLoading);
    } else {
      notification.info('Avalie todas as questões');
    }
  };

  const getExercise = useCallback(() => {
    exerciseService
      .findByIdWithStatus(Number(exerciseId), Number(userId))
      .then((data) => setExercise(data))
      .catch(() => notification.error('Exercício não encontrado'));
  }, [exerciseService, userId, exerciseId]);

  useEffect(() => {
    getExercise();
  }, [getExercise]);

  return (
    <BoxContainer>
      <Header>
        <GoBack path="back" enableBackground={true} />
        <Title margin="0 0 0 10px">{exercise.name}</Title>
      </Header>
      <Box>
        <Info>
          <div className="description">{exercise.description}</div>
          <div className="grade">
            <Title size={18}>Nota da startup</Title>
            {isNull(exercise.grade) ? (
              <span className="subtitle">
                Pendente de correção para lançamento da nota
              </span>
            ) : (
              <div className="score">{exercise.grade}</div>
            )}
          </div>
        </Info>
      </Box>
      {exercise.quizzes.map((item, index) => {
        const { quiz, answers } = item;
        const isObjective = ['OBJECTIVE', 'MULTIPLE'].includes(
          quiz.type.toString(),
        );

        const rightQuestions = (quiz.objectiveQuestions ?? [])
          .filter((question) => question.status)
          .map((item) => item.description);

        const rightAnswers = answers.filter((answer) =>
          rightQuestions.includes(answer),
        );

        return (
          <Box key={`key_quiz_${quiz.entityId}`}>
            <BoxQuestions>
              <div className="description">
                <ResetMarginDescription
                  className="text"
                  dangerouslySetInnerHTML={{ __html: quiz.question }}
                />
                <div className="points">
                  Pontos:{' '}
                  {isObjective
                    ? `${rightAnswers.length}/${rightQuestions.length}`
                    : '1/1'}
                </div>
              </div>
              <BoxLine margin="5px 0 0 0">{getQuestionType(item)}</BoxLine>
            </BoxQuestions>
          </Box>
        );
      })}
      {isNull(exercise.grade) && exercise.isPendingApproval && (
        <PurpleButton
          type="primary"
          loading={exerciseLoading.loading}
          onClick={sendApproval}
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '20px',
          }}
        >
          Lançar nota
        </PurpleButton>
      )}
    </BoxContainer>
  );
}

export default ExerciseCorrection;
