import React from 'react';
import BoxTip from './BoxTip';

function Speeder() {
  return (
    <BoxTip
      title="Aceleradora"
      description="Neste espaço, pronto para sua aceleradora, você, como administrador do perfil, conseguirá incluir sua metodologia de aceleração para startups, da mesma forma, cadastrar seus métodos de valuation e cadastrar seus mentores. 
            Outro benefício é que a plataforma Innovation Studio te permitirá acompanhar o progresso, maturidade, resultados dos OKR’s e KPI’s de cada uma de suas startups cadastradas. O Innovation Studio desenvolveu a plataforma a fim de gerar maior conexão com os atores do ecossistema de inovação."
    />
  );
}

export default Speeder;
