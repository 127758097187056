enum StatusEnum {
  Open = 'OPEN',
  Enabled = 'ENABLED',
  Blocked = 'BLOCKED',
  Close = 'CLOSE',
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Disabled = 'DISABLED',
}

export default StatusEnum;
