import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { ModalBody, ModalContent } from '../../../../GlobalStyles';
import { useAuth } from '../../../../core/hooks/auth';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import QrCode from '../../../../components/QrCode/QrCode';
import img from '../../../../assets/icons/icon_new_startup.png';
import { LinkOutlined } from '@ant-design/icons';
import { QRcodeWrapper } from './styles';
import StartupService from '../../../../services/StartupService';

interface FormFields {
  email: string;
}

function StartupInvitationModal({ visible, onCancel }: IPropsModal) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();

  const { logged } = useAuth();
  const { origin } = window.location;

  const startupService = StartupService.init();

  const inviteUrl = new URL('/register', origin);
  inviteUrl.searchParams.set('inviter', String(logged?.tokenShared));

  function handleCloseModal() {
    form.resetFields();
    onCancel();
  }

  function copyLink() {
    copy(inviteUrl.toString());

    notification.success({
      message: 'Link copiado como sucesso!',
      duration: 3.5,
    });

    handleCloseModal();
  }

  async function onFinish({ email }: FormFields) {
    setIsSubmitting(true);

    try {
      await startupService.inviteStartups(
        Number(logged?.entityId),
        email,
        String(logged?.tokenShared),
      );

      notification.success({
        message: 'Convite enviado com sucesso!',
        duration: 3.5,
      });

      handleCloseModal();
    } catch (error) {
      notification.error({
        message: 'Não conseguimos enviar o convite!',
        description: 'Verifique o e-mail ou contate o administrador.',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <ModalBody
      width={500}
      footer={null}
      closable={true}
      open={visible}
      onCancel={handleCloseModal}
      destroyOnClose={true}
    >
      <ModalContent>
        <img src={img} alt="foguete innovation" width={180} />
        <Title size={24}>Convidar startups</Title>
        <Text align={'center'} size={15} margin="10px 0 20px 0">
          Aumente seu portfólio de startups enviando um convite diretamente ao
          e-mail delas ou enviando o link gerado.
        </Text>
        <QRcodeWrapper>
          <QrCode
            value={inviteUrl.toString()}
            size={200}
            bgColor={'#233862'}
            fgColor={'#e5e5e5'}
            quietZone={6}
          />
        </QRcodeWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '0 0 10px 0',
          }}
        >
          <Form
            name={'send-mail'}
            form={form}
            style={{ width: '100%' }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message:
                    'Para enviar o convite é necessário informar o e-mail!',
                },
                {
                  type: 'email',
                  message: 'Informe um e-mail válido para enviar o convite!',
                },
              ]}
            >
              <Input placeholder={'E-mail da startup'} maxLength={100} />
            </Form.Item>
          </Form>
        </div>
        <Row
          wrap
          align="middle"
          justify="space-between"
          className="w-100"
          gutter={[8, 8]}
        >
          <Col>
            <Button type="ghost" onClick={copyLink} icon={<LinkOutlined />}>
              Copiar link
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType={'submit'}
              form={'send-mail'}
              loading={isSubmitting}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </ModalContent>
    </ModalBody>
  );
}

export default StartupInvitationModal;
