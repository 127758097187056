import styled from 'styled-components';

const Box = styled.div`
  padding: 1rem;
  border-radius: 6px;
  background-color: #fff;
  border: 0.5px solid #ddd;
`;

export { Box };
