import React, { useEffect, useState } from 'react';
import { BoxContainer } from './style';
import TabGray from '../../../components/tab/tabGray';
import BodySlider from './BodySlider';
import AlertMessage from '../../../components/Alert/AlertMessage';

interface IParams {
  setNext?: (next: string) => void;
  next?: string;
  params?: any;
  showLoading(): void;
  hideLoading(): void;
  loding: boolean;
  setFormName?(formName: string): void;
  setAsk?(ask: boolean): void;
  formName?: string;
  closeModal?(): void;
}

function PotentialStartup(props: IParams) {
  const [tab, setTab] = React.useState('0');

  const PotentialStartup = [
    {
      tab: 'Potencial de mercado',
      body: (
        <BodySlider
          next={'0'}
          setNext={setTab}
          id={11}
          tabName="Potencial de mercado"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
    {
      tab: 'Potencial de Inovação',
      body: (
        <BodySlider
          next={'1'}
          id={12}
          setNext={setTab}
          tabName="Potencial de Inovação"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
    {
      tab: 'Potencial de Empreendedor',
      body: (
        <BodySlider
          next={'2'}
          id={13}
          setNext={setTab}
          tabName="Potencial de Empreendedor"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
  ];

  useEffect(() => {
    if (tab === '3') {
      props.setNext && props.setNext((Number(props.next) + 1).toString());
    }
  }, [tab]);

  return (
    <BoxContainer>
      <TabGray content={PotentialStartup} tabKey={tab} />
    </BoxContainer>
  );
}

export default PotentialStartup;
