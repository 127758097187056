import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DragAndDropFile from '../../../../components/DragAndDropFile/DragAndDropFile';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { BoxContainer, BoxWhite, PurpleButton } from '../../../../GlobalStyles';
import { convertBase64 } from '../../../../helpers/convertion.helper';
import { notification } from 'antd';
import { useAuth } from '../../../../core/hooks/auth';
import IInvestmentRoundStartup from '../../../../core/interfaces/IInvestmentRoundStartup';
import InvestmentRoundService from '../../../../services/InvestmentRoundService';
import AlertMessage from '../../../../components/Alert/AlertMessage';

interface IFormValues {
  fileName?: string;
  fileData?: string;
  videoFileName?: string;
  videoData?: string;
}

function SubmitPich() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formValues, setFormValues] = useState<IFormValues>({});
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const { logged } = useAuth();
  const id = logged?.entityId;
  const inevstmentRoundService = InvestmentRoundService.init();

  const handleBack = () => {
    navigate('/investment-round/details', {
      state: { entityId: state.entityId, type: 'startup' },
    });
  };

  const arrageValues = () => {
    const result: IInvestmentRoundStartup = {
      fileName: formValues.fileName,
      file: formValues.fileData,
      videoFileName: formValues.videoFileName as string,
      video: formValues.videoData as string,
      investmentRoundId: state.entityId,
      status: 'PENDING',
    };

    return result;
  };

  const getImageFile = (file: File) => {
    convertBase64(file)
      .then((data) => {
        setFormValues({
          ...formValues,
          fileName: file.name,
          fileData: data as string,
        });
      })
      .catch(() => {
        notification.warning({
          message: 'Erro ao converter imagem',
          description:
            'Ocorreu um erro ao converter a imagem para base64. Por favor, tente novamente.',
        });
      });
  };

  const getVideoFile = (file: File) => {
    convertBase64(file)
      .then((data) => {
        setFormValues({
          ...formValues,
          videoFileName: file.name,
          videoData: data as string,
        });
      })
      .catch(() => {
        notification.warning({
          message: 'Erro ao converter vídeo',
          description:
            'Ocorreu um erro ao converter o vídeo para base64. Por favor, tente novamente.',
        });
      });
  };

  const sendMaterial = () => {
    if (!formValues.videoData) {
      notification.warning({
        message: 'Envio de pitch',
        description: 'Por favor, envie o pitch em vídeo.',
      });
      return;
    }

    inevstmentRoundService
      .sendRequestInvestmentRound(Number(id), arrageValues())
      .then(() => {
        notification.success({
          message: 'Envio de pitch',
          description: 'Pitch enviado com sucesso.',
        });
        handleBack();
      })
      .catch(() => {
        notification.error({
          message: 'Envio de pitch',
          description:
            'Ocorreu um erro ao enviar o pitch. Por favor, tente novamente.',
        });
      });
  };

  return (
    <BoxContainer>
      <AlertMessage
        width={350}
        message={'Enviar pitch'}
        description={
          'Tudo certo para submeter seu pich para essa rodada de investimento? Se está tudo certo, basta clicar em enviar, e boa sorte no processo!'
        }
        textOkButton={'Enviar pitch'}
        visible={alertVisible}
        onOk={sendMaterial}
        onCancel={() => setAlertVisible(false)}
      />
      <BoxContainer
        flexDirection={'row'}
        noPadding={true}
        alignItems={'center'}
      >
        <GoBack enableBackground={true} goBackFunction={handleBack} />
        <Title margin={'0 0 0 10px'}>Submeter pitch</Title>
      </BoxContainer>
      <BoxWhite>
        <Title size={16}>*Arquivo em vídeo</Title>
        <Text>
          <span>
            Seu pitch em vídeo deve ter no máximo <b>3 minutos</b> de duração e
            não pode ultrapassar mais que <b>100MB</b>.
          </span>
        </Text>
        <DragAndDropFile
          maxFiles={1}
          maxFileSize={100}
          fileType={['video/avi', 'video/mkv', 'video/mp4']}
          title={'Solte seu vídeo aqui ou navegue'}
          description="Suporta vídeos no formato MP4, AVI ou MKV"
          onFileChange={(file) => getVideoFile(file)}
        />
        <Title size={16}>*Arquivo em PDF</Title>
        <Text>
          Para aumentar suas chances de ser selecionado, compartilhe com o
          investidor uma apresentação detalhada do seu negócio, mas seja
          suscinto. Não envie uma apresentação muito grande para não ser
          cansativa de leitura.
        </Text>
        <DragAndDropFile
          maxFiles={1}
          fileType={['application/pdf']}
          title={'Solte aqui seu material'}
          description="Suporta apenas arquivo PDF"
          onFileChange={(file) => getImageFile(file)}
        />
        <PurpleButton
          type={'primary'}
          onClick={() => setAlertVisible(true)}
          style={{ display: 'block', margin: '0 auto', padding: '0 50px' }}
        >
          Enviar pitch
        </PurpleButton>
      </BoxWhite>
    </BoxContainer>
  );
}

export default SubmitPich;
