import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px 0;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    display: inline-block;
    background-color: #e3e2e2;
  }
`;

export { Wrapper };
