import { EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { Card, IconOptions, MenuOption } from '../../../../GlobalStyles';
import { ContentGrid, HeaderInformationBox } from './styles';

interface Props {
  text: string;
  title: string;
  value: string;
  actions: Array<{ text: string; click(): void; disable?: boolean }>;
}

function InformationBox({ title, value, text, actions }: Props) {
  const generateItems = () => {
    return actions.map((action, index) => ({
      key: `action_box_${action.text}_${index}`,
      label: (
        <MenuOption onClick={action.click} disable={action.disable}>
          <p>
            <EyeOutlined
              style={{ marginRight: '5px', fontSize: '40px', color: '#a209a2' }}
            />
            {action.text}
          </p>
        </MenuOption>
      ),
    }));
  };
  const items = generateItems();

  return (
    <Card boxShadow="none" style={{ minHeight: '150px' }}>
      <HeaderInformationBox>
        <Title size={20} weight={700}>
          {title}
        </Title>
        <Dropdown trigger={['click']} placement="bottomRight" menu={{ items }}>
          <IconOptions cursor="pointer" display="flex" size="22px">
            <MoreOutlined />
          </IconOptions>
        </Dropdown>
      </HeaderInformationBox>
      <ContentGrid>
        <Text size={15}>{text}</Text>
        <Text color="#960096" size={20} weight={700}>
          {value}
        </Text>
      </ContentGrid>
    </Card>
  );
}

export default InformationBox;
