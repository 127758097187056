/* eslint-disable array-callback-return */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import jwt_decode from 'jwt-decode';
import { IAuth, ILogin, IUser } from '../interfaces';
import api from '../services/api';
import FileService from '../../services/FileService';
import useLoading from './useLoading';
import pictureDefault from '../../assets/images/image-default.png';

interface IProps {
  children: React.ReactNode;
}

interface IAuthContext {
  isLogged: boolean;
  isMentor(): boolean;
  isClient: () => boolean;
  isInvestor: () => boolean;
  isStartup: () => boolean;
  logged?: IUser;
  setProfilePicture(value: string): void;
  loadingProfilePicture: boolean;
  profilePicture: string;
  getProfilePicture(): void;
  getDescriptionProfile(): string;
  updateLogged(jwtToken: string): void;
  signIn(values: ILogin): Promise<any>;
  signOut(): void;
  isPermission(roles: any[]): any;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

function AuthProvider({ children }: IProps) {
  const getUser = (): IUser | undefined => {
    const token = localStorage.getItem('@innovation:auth');
    if (!token) return;

    const expiration: any = jwt_decode(token);
    return expiration;
  };

  const { loading, showLoading, hideLoading } = useLoading();
  const fileService = useMemo(() => FileService.init(), []);

  const [profilePicture, setProfilePicture] = useState(() => pictureDefault);
  const [logged, setLogged] = useState<IUser | undefined>(() => getUser());

  const [isLogged, setIsLogged] = useState<boolean>(
    !!localStorage.getItem('@innovation:logged'),
  );

  const updateLogged = (jwtToken: string) => {
    localStorage.setItem('@innovation:auth', jwtToken);
    setIsLogged(true);
    setLogged(jwt_decode(jwtToken));
  };

  const signIn = async (values: ILogin): Promise<any> => {
    const response = await api.post<IAuth>('authenticate', values);

    localStorage.setItem('@innovation:auth', response.data.jwttoken);
    localStorage.setItem('@innovation:logged', 'true');

    setIsLogged(true);
    setLogged(getUser());
    return response;
  };

  const signOut = () => {
    localStorage.removeItem('@innovation:logged');
    localStorage.removeItem('@innovation:auth');
    setIsLogged(false);
    setLogged(undefined);
  };

  const getProfilePicture = useCallback(() => {
    if (logged?.profileImageUrl) {
      showLoading();
      return fileService
        .get(logged?.profileImageUrl)
        .then((data) => setProfilePicture(data))
        .finally(hideLoading);
    } else {
      setProfilePicture(pictureDefault);
      return Promise.resolve();
    }
  }, [fileService, logged?.profileImageUrl, showLoading, hideLoading]);

  const isPermission = (rolesMenu: string[]) => {
    try {
      const rolesUser = getUser()?.roles ?? [];
      let isPermited = false;

      rolesUser.map((value) => {
        if (rolesMenu.includes(value)) {
          isPermited = true;
        }
      });
      return isPermited;
    } catch (Error) {
      console.error(Error);
    }
  };

  const checkProfile = (search: string) => {
    return (logged?.profile ?? []).some(
      ({ description = '' }) => description.toLowerCase() === search,
    );
  };

  const isAdmin = () => checkProfile('admin');
  const isMentor = () => checkProfile('mentor');
  const isStudent = () => checkProfile('student');
  const isProfessional = () => checkProfile('professional');
  const isClient = () => checkProfile('client');
  const isInvestor = () => checkProfile('investor');
  const isStartup = () => checkProfile('startup');

  const getDescriptionProfile = () => {
    const profiles = [
      {
        allowed: isAdmin(),
        text: 'Admin',
      },
      {
        allowed: isClient(),
        text: 'Aceleradora',
      },
      {
        allowed: isStartup(),
        text: 'Startup',
      },
      {
        allowed: isMentor(),
        text: 'Mentor',
      },
      {
        allowed: isInvestor(),
        text: 'Investidor',
      },
      {
        allowed: isStudent(),
        text: 'Estudante',
      },
      {
        allowed: isProfessional(),
        text: 'Profissional',
      },
    ];

    const search = profiles.find((item) => item.allowed);
    return search ? search.text : 'Perfil não identificado';
  };

  return (
    <AuthContext.Provider
      value={{
        getDescriptionProfile,
        isStartup,
        isInvestor,
        isClient,
        isMentor,
        isLogged,
        logged,
        updateLogged,
        signIn,
        signOut,
        isPermission,
        profilePicture,
        setProfilePicture,
        loadingProfilePicture: loading,
        getProfilePicture,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = (): IAuthContext => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
