import styled from 'styled-components';

interface IParams {
  enableBackground?: boolean;
  enableText?: boolean;
  margin?: string;
}

const Box = styled.div<IParams>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.enableText ? '60px' : '28px')};
  height: ${(props) => (props.enableText ? 'auto' : '28px')};
  background-color: ${(props) =>
    props.enableBackground ? 'var(--thirdy-color)' : ''};
  border-radius: 6px;
  font-size: 0.9rem;
  margin: ${(props) => (props.margin ? props.margin : '')};
  cursor: pointer;

  svg {
    color: ${(props) =>
      props.enableBackground ? 'var(--white-color)' : 'var(--primary-color)'};
    margin-right: ${(props) => (props.enableText ? '5px' : '0')};
  }
`;

export { Box };
