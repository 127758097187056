import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../core/hooks/auth';
import { Steps } from 'antd';
import Profile from '../register-conclude/profile';
import ScreenStartupForm from './form/startup/ScreenStartupForm';
import ScreenAccelerator from './form/accelerator/ScreenAccelerator';
import ScreenStudent from './form/student/ScreenStudent';
import ScreenMentor from './form/mentor/ScreenMentor';
import ScreenInvestor from './form/investor/ScreenInvestor';
import ScreenProfessional from './form/professional/ScreenProfessional';

function PreRegister() {
  const { logged } = useAuth();
  const id = logged?.profile?.[0].id;
  const { Step } = Steps;
  const [current, setCurrent] = useState<number>(0);
  const [subStep, setSubStep] = useState<number>(0);
  const [buttonState, setButtonState] = useState<boolean>(true);
  const [screen, setScreen] = useState<number>(0);
  const [buttonText, setButtonText] = useState<string>('Avançar');
  const [formName, setFormName] = useState<string | undefined>();
  const [finish, setFinish] = useState(() => () => {
    setCurrent(current + 1);
  });

  const ProfileScreen = [
    <ScreenAccelerator
      key={2}
      profileId={2}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
    <ScreenStartupForm
      key={3}
      profileId={3}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
    <ScreenMentor
      key={4}
      profileId={4}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
    <ScreenInvestor
      key={5}
      profileId={5}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
    <ScreenStudent
      key={6}
      profileId={6}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
    <ScreenProfessional
      key={7}
      profileId={7}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
  ];

  const Screens = [
    {
      content: ProfileScreen[screen],
    },
  ];

  useEffect(() => {
    if (id === 2) {
      setScreen(0);
    }
    if (id === 3) {
      setScreen(1);
    }
    if (id === 4) {
      setScreen(2);
    }
    if (id === 5) {
      setScreen(3);
    }
    if (id === 6) {
      setScreen(4);
    }
    if (id === 7) {
      setScreen(5);
    }
  }, [id]);

  return (
    <Profile
      buttonState={false}
      buttonText={buttonText}
      finishHandler={finish}
      formName={formName}
      content={
        <>
          {Screens[current].content}
          <Steps progressDot current={current}>
            <Step key="0" />
          </Steps>
        </>
      }
    />
  );
}

export default PreRegister;
