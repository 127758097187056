import React, { useEffect, useState } from 'react';
import { Col, Row, Drawer, Spin, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import MaturityTable from './MaturityTable';
import MaturityChart from './MaturityChart';
import QuizFeedback from './QuizFeedback';
import { Divider, MaturityStatus } from './styles';
import Text from '../../../../components/Text';
import { useReactToPrint } from 'react-to-print';
import {
  ButtonCloseDrawer,
  Container,
  PageBreak,
  PurpleButton,
} from '../../../../GlobalStyles';
import { IAnswer } from '../../../../core/interfaces';
import api from '../../../../core/services/api';
import GraphicResult from '../../../../components/graphics/diagnosis';
import useLoading from '../../../../core/hooks/useLoading';
import { Box, Wrapper } from '../../../results/style';
import Loading from '../../../../components/Loading/Loading';
import Title from '../../../../components/Title';

interface Props {
  show: boolean;

  close(): void;

  startup?: any;

  diagnostic: any;
}

interface IResult {
  answers: IAnswer[];
  valueMaturityIndicator: number;
  maturityIndicator?: { title: string; descritpion: string };
  pillarDTOS: [{ pillars: string; result: string }];
  startup: {
    name: string;
    cnpj: string;
    problem: string;
    resolution: string;
    businessTypes: { description: string };
    businessModels: { description: string };
    state: { name: string; uf: string };
    owner: {
      cpf: string;
    };
  };
}

function DiagnosticResult({ show, close, startup, diagnostic }: Props) {
  const [result, setResult] = useState<IResult>();
  const [data, setData] = useState<Array<any>>();
  const { loading, showLoading, hideLoading } = useLoading();

  const getResult = async () => {
    showLoading();
    api
      .get<any>('diagnostic/resultByDiagnostic', {
        params: { diagnostic: diagnostic.entityId },
      })
      .then((response: any) => {
        setResult(response.data);
        setData(response.data.pillarDTOS);
      })
      .catch(() => {
        notification.error({
          duration: 3.5,
          message: 'Erro ao gerar resultado diagnóstico',
        });
      })
      .finally(hideLoading);
  };

  const componentRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'resultado-diagnostico/innovation-studio',
    pageStyle:
      '@page { size: A4; margin: 10; ' +
      '@bottom-right { content: counter(page); } ' +
      '@bottom-left { content: counter(page); } }' +
      '@media print { body { -webkit-print-color-adjust: exact; } }' +
      'body { font-family: "Roboto", sans-serif; min-height: 1500px; min-width: 900px }' +
      '}',
  });

  useEffect(() => {
    if (show) {
      getResult();
    }
  }, [show]);

  return (
    <Drawer
      open={show}
      closable={false}
      className="reset-padding-body-mobile"
      width={'100%'}
      onClose={close}
      destroyOnClose={true}
    >
      <Box>
        <Loading size={'large'} width={300} height={300} spinning={loading}>
          <Container>
            <Wrapper>
              <div ref={componentRef} style={{ width: '100%' }}>
                <Row align="middle" wrap={false} justify={'space-between'}>
                  <Col>
                    <Title sizeXs={22} size={26}>
                      Resultado Diagnóstico.
                    </Title>
                    <Text color={'var(--thirdy-color)'} size={18} weight={700}>
                      Startup: {startup?.name}
                    </Text>
                  </Col>
                  <Col className="hide-print">
                    <ButtonCloseDrawer onClick={close}>
                      <span>Fechar janela</span>
                    </ButtonCloseDrawer>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{ margin: '20px 0' }}
                >
                  <Col sm={24} md={15}>
                    <Text sizeXs={22} size={26} weight={700}>
                      O que diz seu diagnóstico?
                    </Text>
                  </Col>
                  <Col className="hide-in-mobile hide-print">
                    <PurpleButton
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={handlePrint}
                    >
                      Baixar PDF
                    </PurpleButton>
                  </Col>
                </Row>
                <Text size={16} weight={400} className="mb-2">
                  O diagnóstico irá mostrar qual o nível em que sua startup se
                  encontra e detalhar características importantes para que
                  possamos compreender qual a melhor maneira de apoiar e
                  apresentar oportunidades para sua startup.
                </Text>
                <Row gutter={[18, 25]} style={{ marginTop: 50 }}>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Qual o nome da sua organização?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.name}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Qual a UF da sua organização?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup.state.name +
                        ' (' +
                        result?.startup?.state.uf +
                        ')'}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      CNPJ e Razão Social
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.cnpj}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Como seu negócio pode ser classificado?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.businessTypes.description}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Qual o problema que sua startup resolve?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.problem}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Como resolve?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.resolution}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Text sizeXs={18} size={20} weight={700}>
                      Como seu modelo de negócio pode ser classificado?
                    </Text>
                    <Text size={15} weight={500}>
                      {result?.startup?.businessModels.description}
                    </Text>
                  </Col>
                </Row>
                <Divider />
                <PageBreak>
                  <Text sizeXs={22} size={26} weight={700}>
                    Sobre as questões e gráfico
                  </Text>
                  <Text
                    margin={'10px 0 20px 0'}
                    size={16}
                    weight={400}
                    className="mb-2"
                  >
                    As questões do diagnóstico foram elaboradas de acordo com
                    cada pilar, que o Innovation Studio considera importante
                    para cada fase de maturidade do negócio. A tabela
                    Pilar/Pontuação explana a média das questões que foram
                    respondidas por você, e como uma forma de melhor visualizar
                    essas médias, o Innovation Studio utiliza-se do Gráfico de
                    Aranha. O gráfico de aranha fornece uma visão geral melhor e
                    sem esforço da média de todas as questões respondidas em que
                    as variáveis são usadas. O Innovation Studio utiliza números
                    inteiros e, caso as as médias das respostas não alcancem um
                    número inteiro mais alto, o sistema arredonda para o número
                    inteiro abaixo. Dessa forma, acredita-se que o processo de
                    formação da startup se torne mais completa e consolidada.
                  </Text>
                  <div style={{ margin: '0 auto' }}>
                    <Row className="mb-2" gutter={[12, 12]}>
                      <Col xs={24} sm={24} md={12}>
                        <Row style={{ flexDirection: 'column' }}>
                          <Col style={{ marginBottom: '16px' }}>
                            <MaturityStatus>
                              <p>Maturidade atual da Startup</p>
                              <strong>
                                {result?.maturityIndicator?.title}
                              </strong>
                            </MaturityStatus>
                          </Col>
                          <Col>
                            <MaturityTable
                              maturity={result?.valueMaturityIndicator}
                              maturityIndicator={result?.maturityIndicator}
                              data={data}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <MaturityChart>
                          <GraphicResult
                            yField={'result'}
                            xField={'pillars'}
                            data={data || []}
                          />
                        </MaturityChart>
                      </Col>
                    </Row>
                  </div>
                </PageBreak>
                <PageBreak>
                  <Text size={20} weight={700} className="mb-1">
                    Questionário
                  </Text>
                  {data?.map((item, index) => (
                    <QuizFeedback
                      key={`quiz_key_${index}`}
                      title={item.pillars}
                      questions={item.answers}
                    />
                  ))}
                </PageBreak>
              </div>
            </Wrapper>
          </Container>
        </Loading>
      </Box>
    </Drawer>
  );
}

export default DiagnosticResult;
