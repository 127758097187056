import Text from '../../Text';
import Title from '../../Title';
import { FiEdit2 } from 'react-icons/fi';
import { Col, Row, Spin, Tooltip } from 'antd';
import { useModal } from 'sunflower-antd';
import { ModalBody } from '../../../GlobalStyles';
import { Box, PictureWrapper, ProfilePicture } from './styles';
import { IParamsProfileHeader } from '../../../core/interfaces/IProfileHeader';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import ModalProfilePicture from '../../ModalProfilePicture';
import { useAuth } from '../../../core/hooks/auth';

const linkStyles = {
  color: '#00B6FF',
  textDecoration: 'underline',
};

function ProfileHeader({
  name,
  dateExpiration,
  children,
  description,
  metaData = [],
  loading = false,
}: IParamsProfileHeader) {
  const { loadingProfilePicture, profilePicture } = useAuth();

  const modalEdit = useModal({
    defaultVisible: false,
  });

  const modalProfilePicture = useModal({
    defaultVisible: false,
  });

  const editEnabled = Boolean(children);

  return (
    <>
      <ModalProfilePicture {...modalProfilePicture.modalProps} />

      <Spin spinning={loading}>
        <Box>
          <PictureWrapper>
            <Spin spinning={loadingProfilePicture}>
              <ProfilePicture onClick={modalProfilePicture.show}>
                <img
                  width={145}
                  height={145}
                  src={profilePicture}
                  alt="imagem perfil"
                />
                <div>
                  <MdOutlinePhotoCamera size={26} />
                  <Text lineHeight={1.3} color="#fff" size={16}>
                    Editar foto de <br /> perfil
                  </Text>
                </div>
              </ProfilePicture>
            </Spin>
            {editEnabled && (
              <Tooltip
                title="Editar perfil"
                color="#fff"
                placement="bottomRight"
                overlayClassName="text-tooltip-primary"
              >
                <FiEdit2 onClick={modalEdit.show} cursor="pointer" size={20} />
              </Tooltip>
            )}
          </PictureWrapper>

          {name && <Title margin="12px 0">{name}</Title>}
          {
            <Text margin="0 0 8px 0" color="#9AA4A8" size={18} weight={500}>
              Seu acesso expira em: {dateExpiration}
            </Text>
          }
          {description ? (
            <Text weight={500} margin="0 0 8px 0">
              {description}
            </Text>
          ) : (
            <Text weight={500} margin="8px 0">
              Descrição vazia, atualize seu cadastro
            </Text>
          )}

          <Row wrap={true} justify="space-between" align="middle">
            {metaData.map((data, index) => (
              <Col key={`metadata_${index}`}>
                <Text margin="0 0 4px 0" weight={600} size={18}>
                  {data.label}
                </Text>
                <Text
                  color="#9AA4A8"
                  {...(data.isLink && { style: linkStyles })}
                >
                  {data.value}
                </Text>
              </Col>
            ))}
          </Row>
        </Box>
      </Spin>

      <ModalBody
        title="Editar perfil"
        width={'100%'}
        margin="0 auto"
        maxWidth="800px"
        open={modalEdit.visible}
        destroyOnClose={true}
        onCancel={modalEdit.close}
        footer={null}
      >
        {children && children(modalEdit.modalProps)}
      </ModalBody>
    </>
  );
}

export default ProfileHeader;
