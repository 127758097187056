import React from 'react';
import BaseScreen from '../index';
import { Button } from 'antd';
import { useAuth } from '../../../core/hooks/auth';

function InactivePage() {
  const { signOut } = useAuth();
  return (
    <BaseScreen
      title={'Usuário Inativo'}
      content={
        <>
          <p>
            Seu usuário está inativo, entre em contato com o administrador do
            sistema.
          </p>
          <Button block type="primary" onClick={signOut}>
            Voltar para login
          </Button>
        </>
      }
    />
  );
}

export default InactivePage;
