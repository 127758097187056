import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  padding: 5px 5px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  margin-bottom: 2px;

  .actionsButtons {
    display: flex;
    position: absolute;
    right: 0;
    height: 100%;
    align-items: center;

    svg {
      cursor: pointer;
      font-size: 20px;
    }
  }
`;

const Info = styled.div<{
  width?: string;
  overflow?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin-left: 5%;
  ${(props) =>
    props.overflow === 'true'
      ? 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'
      : ''};

  .label {
    font-weight: bold;
  }
`;

const PurppleText = styled.div<{
  fontBold?: boolean;
  fontSize?: string;
}>`
  color: var(--thirdy-color);
  font-weight: ${(props) => (props.fontBold ? 'bold' : 'normal')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
`;

export { Container, Info, PurppleText };
