import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ActionsWrapper, CoursesWrapper } from './styles';
import FilterList from './FilterList';
import { IFilterList } from '../../../../core/interfaces/course/IFilterList';
import CourseService from '../../../../services/course/CourseService';
import { notification } from '../../../../helpers/notification.helper';
import { isEmpty } from 'lodash';
import ICourseShowCase from '../../../../core/interfaces/course/ICourseShowCase';
import IPageable from '../../../../core/interfaces/IPageable';
import CrudService from '../../../../services/CrudService';
import CardCourse from '../../../../components/CardCourse/CardCourse';
import usePagination from '../../../../core/hooks/usePagination';
import { Empty, Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import Text from '../../../../components/Text';
import useLoading from '../../../../core/hooks/useLoading';
import { useAuth } from '../../../../core/hooks/auth';

function StartupCourseListing() {
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { currentPage, changePagination } = usePagination();
  const { loading, hideLoading, showLoading } = useLoading();

  const [courses, setCourses] = useState<IPageable<ICourseShowCase>>(() =>
    CrudService.pageableDefault(),
  );

  const [filters, setFilters] = useState<IFilterList>({
    categories: [],
    sort: '',
  });

  const courseService = useMemo(() => CourseService.init(), []);

  const getCourses = useCallback(() => {
    const { categories, sort } = filters;
    const params = {
      ...(sort && { sort }),
      page: currentPage,
      size: 12,
      filterPhase: true,
      ...(logged && { studentId: logged.entityId }),
      ...(!isEmpty(categories) && { categoriesId: categories.join(',') }),
    };

    showLoading();
    courseService
      .showCase(params)
      .then((data) => setCourses(data))
      .catch(() => notification.error('Erro ao listar cursos'))
      .finally(hideLoading);
  }, [courseService, currentPage, filters, hideLoading, showLoading, logged]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  return (
    <Spin spinning={loading}>
      <ActionsWrapper>
        <FilterList
          filters={filters}
          changeFilter={(data: IFilterList) => setFilters(data)}
        />
        <Pagination
          style={{ padding: '1rem' }}
          total={courses.totalElements}
          pageSize={courses.size}
          onChange={changePagination}
        />
      </ActionsWrapper>

      <CoursesWrapper>
        {!loading && isEmpty(courses.content) && (
          <Empty
            style={{ margin: '0 auto' }}
            description={
              <Text size={22} weight={700}>
                Cursos vazios
              </Text>
            }
          />
        )}
        {courses.content.map((course, index) => (
          <CardCourse
            key={`course_item_${index}`}
            {...course}
            click={() =>
              navigate(`/courses-startup/attend-course/${course.entityId}`)
            }
          />
        ))}
      </CoursesWrapper>
    </Spin>
  );
}

export default StartupCourseListing;
