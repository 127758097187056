import React from 'react';
import CoursesInfo from '../Infos/CoursesInfo';
import ContentBase from '../templates/ContentBase';
import CoursesList from './CoursesList';

function Courses() {
  return (
    <ContentBase
      title="Cursos"
      modalContent={<CoursesInfo />}
      content={<CoursesList />}
    />
  );
}

export default Courses;
