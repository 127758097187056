import React from 'react';

import ContentBase from '../templates/ContentBase';
import ValuationInfo from '../Infos/ValuationInfo';
import ValuationStartup from './form/ValuationStartup';

function ValuationQuestions() {
  return (
    <ContentBase
      title="Valuation"
      modalContent={<ValuationInfo />}
      content={<ValuationStartup />}
    />
  );
}

export default ValuationQuestions;
