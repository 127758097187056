import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IAmountInvestment } from '../core/interfaces/IAmountInvestment';
import { IPeriodInvestment } from '../core/interfaces/IPeriodInvestment';

class PeriodInvestmentService extends CrudService {
  static init(): PeriodInvestmentService {
    return new PeriodInvestmentService();
  }

  constructor() {
    super({
      requestMapping: '/periodInvestiment',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IPeriodInvestment[]> {
    return api
      .get<IPeriodInvestment[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(periodInvestment: IPeriodInvestment): Promise<IPeriodInvestment> {
    return api
      .post<IPeriodInvestment>(`${this.requestMapping}`, periodInvestment)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default PeriodInvestmentService;
