import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function MentorshipStartupInfo() {
  return (
    <BoxInfo
      image={image}
      title="Mentorias"
      description={
        <p>
          A sua startup poderá organizar e visualizar os dias com mentorias
          agendadas, além disso, adicionar e programar mentorias e reuniões, e
          receber lembretes das atividades para acompanhar.
        </p>
      }
    />
  );
}

export default MentorshipStartupInfo;
