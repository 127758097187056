import React, { useEffect, useMemo, useState } from 'react';
import { ModalBody, PurpleButton } from '../../../../GlobalStyles';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { Col, Form, Input, Row, Select } from 'antd';
import api from '../../../../core/services/api';
import {
  IBusinessModels,
  IBusinessTypes,
} from '../../../../components/form/profiles/startup/EntrepreneurForm';
import { notification } from '../../../../helpers/notification.helper';
import IBusinessInformation from '../../../../core/interfaces/IBusinessInformation';
import ProfileDashboardService from '../../../../services/profile-dashboard/ProfileDashboardService';
import { useAuth } from '../../../../core/hooks/auth';
import { isNumber } from 'lodash';
import useLoading from '../../../../core/hooks/useLoading';

interface Props extends IPropsModal {
  information: IBusinessInformation;
  reload(): void;
}

function BusinessInformationForm({
  visible,
  onCancel,
  reload,
  information,
}: Props) {
  const { logged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();
  const { entityId = 0 } = logged ?? {};

  const [businessModels, setBusinessModels] = useState<IBusinessModels[]>([]);
  const [businessTypes, setBusinessTypes] = useState<IBusinessTypes[]>([]);

  const profileDashboardService = useMemo(
    () => ProfileDashboardService.init(),
    [],
  );

  const required = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const getBusinessType = () => {
    api
      .get('businessTypes')
      .then((response) => {
        setBusinessTypes(response.data);
      })
      .catch(() => {
        notification.info('Erro ao listart tipos de négocio');
      });
  };

  const getBusinessModel = () => {
    api
      .get<IBusinessModels[]>('businessModels')
      .then((response) => {
        setBusinessModels(response.data);
      })
      .catch(() => {
        notification.info('Erro ao listart modelos de négocio');
      });
  };

  useEffect(() => {
    getBusinessModel();
    getBusinessType();
  }, []);

  const initialValues = useMemo(
    () => ({
      problem: information.problematicTheme,
      businessType: information.businessAreas,
      resolution: information.solutionProblem,
      businessModel: information.businessModel,
    }),
    [information],
  );

  const getDataForm = (values: Record<string, string>) => {
    const body = {
      problem: values.problem,
      resolution: values.resolution,
      businessTypes: {
        entityId: 0,
      },
      businessModels: {
        entityId: 0,
      },
    };

    const { businessType, businessModel } = values;

    if (isNumber(businessType)) {
      body.businessTypes.entityId = businessType;
    } else {
      const { entityId = 0 } =
        businessTypes.find((item) => item.description === businessType) ?? {};

      body.businessTypes.entityId = entityId;
    }

    if (isNumber(businessModel)) {
      body.businessModels.entityId = businessModel;
    } else {
      const { entityId = 0 } =
        businessModels.find((item) => item.description === businessModel) ?? {};

      body.businessModels.entityId = entityId;
    }

    return body;
  };

  const handleSubmit = (values: Record<string, string>) => {
    showLoading();
    profileDashboardService
      .editBusinessInformation(entityId, getDataForm(values))
      .then(() => {
        reload();
        onCancel();
        notification.success('Informações de négocio atualizada com sucesso');
      })
      .catch(() => {
        notification.error('Erro ao atualizar informações');
      })
      .finally(hideLoading);
  };

  return (
    <ModalBody
      title="Editar informações de négocio"
      width={'100%'}
      margin="0 auto"
      maxWidth="800px"
      open={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <Row gutter={[12, 0]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={'problem'}
              label="Qual problema sua startup soluciona?"
              rules={[
                ...required,
                {
                  max: 255,
                  message: 'O campo deve possuir no máximo 255 caracteres',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="businessType"
              label="Como seu negócio pode ser classificado?"
              rules={required}
            >
              <Select showSearch placeholder="Selecione o tipo de negócio">
                {businessTypes.map((item, index) => (
                  <Select.Option
                    value={item.entityId}
                    key={`business_type_${index}`}
                  >
                    {item?.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={'resolution'}
              label="Como sua startup resolve esse problema?"
              rules={[
                ...required,
                {
                  max: 255,
                  message: 'O campo deve possuir no máximo 255 caracteres',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name={'businessModel'}
              label="Como seu modelo de negócio pode ser classificado?"
              rules={[
                {
                  required: true,
                  message: 'Modelo de negócio é um campo obrigatório!',
                },
              ]}
            >
              <Select showSearch placeholder="Selecione o modelo de negócio">
                {businessModels.map((item, index) => (
                  <Select.Option
                    value={item.entityId}
                    key={`business_model_${index}`}
                  >
                    {item?.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <PurpleButton loading={loading} htmlType="submit" type="primary">
              Salvar
            </PurpleButton>
          </Col>
        </Row>
      </Form>
    </ModalBody>
  );
}

export default BusinessInformationForm;
