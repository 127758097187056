import React from 'react';

interface Props {
  margin?: string;
  color?: string;
  width?: string;
}

function Divider({ width, color, margin = '1px' }: Props) {
  return (
    <hr
      color={color}
      style={{
        width,
        margin,
      }}
    />
  );
}

export default Divider;
