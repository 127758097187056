import styled from 'styled-components';
import { Button } from 'antd';

const Box = styled.div<{
  position?: string;
}>`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: ${({ position }) => position || 'fixed'};
  bottom: 0;
  left: 0;
  z-index: 10;
  right: 0;
  padding: 10px;
  background: #f0f2f5;
  box-shadow: 0px -3px 6px #00000029;
`;

const ButtonSave = styled(Button)`
  color: #fff;
  border: 0;
  padding: 9px;
  border-radius: 6px;
  cursor: pointer;
  min-width: 150px;
  background-color: var(--thirdy-color);

  &[disabled] {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
`;

export { Box, ButtonSave };
