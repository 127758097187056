import Status from '../../components/Status/Status';

export const enum StatusEnum {
  BLOCKED = 'Bloqueado',
  ACTIVE = 'Ativo',
  PENDING = 'Pendente',
  DISABLED = 'Desativado',
  OPEN = 'Aberto',
}

export const findSatus = (status: string) => {
  switch (status) {
    case 'BLOCKED':
      return (
        <Status
          status={StatusEnum.BLOCKED}
          color="red"
          info="Metodologia que já foi ativa e agora foi substituída, porém ainda possuem startups utilizando-a"
        />
      );
    case 'ACTIVE':
      return (
        <Status
          status={StatusEnum.ACTIVE}
          color="green"
          info="Metodologia completa que possui Fases, Pilares e Diagnóstico cadastrados"
        />
      );
    case 'PENDING':
      return (
        <Status
          status={StatusEnum.PENDING}
          color="orange"
          info="O Diagnóstico ficará ativo assim que todas as fases de desenvolvimentos tiverem seus respectivos diagnósticos associados e cadastrados"
        />
      );
    case 'DISABLED':
      return (
        <Status
          status={StatusEnum.DISABLED}
          color="red"
          info="Significa que foi criado um novo item para este dado"
        />
      );
    case 'OPEN':
      return (
        <Status
          status={StatusEnum.OPEN}
          color="blue"
          info="Possivelmente este item está incompleto e não foi finalizado"
        />
      );
  }
};
