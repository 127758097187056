import React from 'react';
import { Pie } from '@ant-design/charts';

interface IParams {
  distributed: number;
  enterprising: number;
  marginDistribution: number;
}

function InvestorAnalysisChart(props: IParams) {
  const data = [
    {
      name: 'Distribuídos',
      value: props.distributed,
    },
    {
      name: 'Emprendedores',
      value: props.enterprising,
    },
    {
      name: 'Mg. Distribuição',
      value: props.marginDistribution,
    },
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'name',
    radius: 0.9,
    innerRadius: 0.64,
    color: ['#960096', '#d1d1d1', '#05233d'],
    label: {
      type: 'outer',
      content: '{value}%',
      style: {
        fontWeight: 700,
        fontFamily: 'Segoe UI',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  };

  return <Pie {...config} />;
}

export default InvestorAnalysisChart;
