import CrudService from './CrudService';
import { IMentoring } from '../core/interfaces/IMentoring';
import api from '../core/services/api';
import { errorCallback } from '../helpers/promise.helper';

class MentoringService extends CrudService {
  public static init(): MentoringService {
    return new MentoringService({
      requestMapping: 'mentoring',
    });
  }

  public async getAllMentorings(): Promise<IMentoring[]> {
    return api
      .get<IMentoring[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createMentoring(
    mentoring: IMentoring,
    userId: number,
  ): Promise<any> {
    return api
      .post<any>(`${this.requestMapping}/${userId}`, mentoring)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getMentoringByMentor(mentorId: number): Promise<IMentoring[]> {
    return api
      .get<IMentoring[]>(`${this.requestMapping}/mentor/${mentorId}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getMentoringByUser(userId: number): Promise<IMentoring[]> {
    return api
      .get<IMentoring[]>(`${this.requestMapping}/user/${userId}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async deleteMentoringById(id: number): Promise<any> {
    return api
      .delete<any>(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async updateMentoringById(
    id: number,
    mentoring: IMentoring,
  ): Promise<any> {
    return api
      .put<any>(`${this.requestMapping}/${id}`, mentoring)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getMentoringById(id: number): Promise<IMentoring> {
    return api
      .get<IMentoring>(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default MentoringService;
