import IExerciseWithStatus from '../../core/interfaces/exercise/IExerciseWithStatus';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import CrudService from '../CrudService';

class ExerciseService extends CrudService {
  constructor() {
    super({
      requestMapping: '/exercise',
    });
  }

  public static init() {
    return new ExerciseService();
  }

  public findByIdWithStatus(exerciseId: number, studentId: number) {
    const url = `${this.requestMapping}/${exerciseId}/with-status`;
    return api
      .get<IExerciseWithStatus>(url, {
        params: { studentId },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ExerciseService;
