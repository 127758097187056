import React, { useMemo } from 'react';
import { Form, Input, Spin } from 'antd';
import Title from '../../../components/Title';
import ErrorNotification from '../../../components/notifications/errorNotification';
import notificationMessage from '../../../components/notifications/notificationMessage';
import useLoading from '../../../core/hooks/useLoading';
import { PurpleButton } from '../../../GlobalStyles';
import CourseCategoryService from '../../../services/course/CourseCategoryService';

function CourseCategory() {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const { hideLoading, loading, showLoading } = useLoading();
  const courseCategoryService = useMemo(() => CourseCategoryService.init(), []);

  const onFinish = () => {
    showLoading();
    courseCategoryService
      .create(form.getFieldsValue())
      .then(() => {
        notificationMessage();
      })
      .catch((error) => ErrorNotification(error))
      .finally(hideLoading);
  };

  return (
    <>
      <Title size={20} margin="10px 0 10px 0">
        Adicionar categoria
      </Title>
      <Spin spinning={loading}>
        <Form
          name={'courseCategoryForm'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          <Form.Item
            name="name"
            label="Digite o nome da categoria"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="digite o nome da categoria" maxLength={255} />
          </Form.Item>
          <Form.Item
            name={'description'}
            label="Descrição do exercício"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <TextArea
              showCount
              style={{ resize: 'none' }}
              rows={2}
              placeholder="Descrição do exercício"
              maxLength={255}
            />
          </Form.Item>
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{
              width: '200px',
              marginLeft: 'calc(50% - 100px)',
              marginTop: '10px',
            }}
          >
            Salvar
          </PurpleButton>
        </Form>
      </Spin>
    </>
  );
}

export default CourseCategory;
