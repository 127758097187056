import { useRef, useState } from 'react';
import { ImageConfig } from './ImageConfig';
import uploadImg from './Icons/ico-upload.png';
import { BoxDropFile, DropFileInput, DropFilePreview } from './style';
import { DeleteOutlined } from '@ant-design/icons';

type ITypes =
  | 'application/pdf'
  | 'application/vnd.ms-excel'
  | 'image/png'
  | 'image/jpg'
  | 'image/jpeg'
  | 'video/mp4'
  | 'video/avi'
  | 'video/mkv';

interface IProps {
  maxFiles?: number;
  /**
   * @param "the maximum file size in MB (megabytes)"
   * @example 100
   */
  maxFileSize?: number;
  fileType?: ITypes[];
  imageUrl?: string;
  title?: string;
  description?: string;
  onFileChange: (file: File) => void;
  onRemoveFile?: (file: File[]) => void;
}

function DragAndDropFile({
  maxFiles = 10,
  maxFileSize,
  fileType,
  imageUrl,
  title,
  description,
  onFileChange,
  onRemoveFile,
}: IProps) {
  const wrapperRef = useRef<any>(null);

  const [fileList, setFileList] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const maxSize = maxFileSize ? maxFileSize * 1048600 : 104860000;

  const getFormat = () => {
    let tmp = '';

    fileType?.forEach((item) => {
      const splitted = item.split('/');

      tmp += ` ${splitted[1]},`;
    });

    return tmp.slice(0, -1);
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const kb = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const dm = decimals < 0 ? 0 : decimals;
    const type = Math.floor(Math.log(bytes) / Math.log(1024));

    return (
      parseInt((bytes / Math.pow(kb, type)).toFixed(dm)) + ` ${sizes[type]}`
    );
  };

  const setFile = (file: File) => {
    setFileList(fileList.concat(file));
    onFileChange(file);
  };

  const checkMaxFiles = () => {
    let tmp = false;

    if (maxFiles && fileList.length < maxFiles) {
      tmp = true;
    } else {
      setErrorMessage(`Quantidade máxima (${maxFiles}) de arquivos atingida!`);
    }

    return tmp;
  };

  const checkSizeFile = (file: File) => {
    let tmp = false;

    if (file.size <= maxSize) {
      tmp = true;
    } else {
      setErrorMessage(
        `Arquivo excede o tamanho máximo de ${formatBytes(maxSize)}!`,
      );
    }

    return tmp;
  };

  const checkFileType = (file: File) => {
    let tmp = false;
    const sendedFileType = file.type;

    if (!fileType) {
      tmp = true;
    } else {
      fileType?.forEach((item) => {
        if (sendedFileType === item) {
          tmp = true;
          return null;
        } else {
          const format = sendedFileType.split('/');
          setErrorMessage(
            `O arquivo [ ${
              format[1]
            } ], não possui o(s) formato(s) requerido(s) [ ${getFormat()} ]!`,
          );
        }
      });
    }

    return tmp;
  };

  const onFileDrop = (target: HTMLInputElement) => {
    const files = target.files;

    if (files) {
      const firstFile = files[0];

      if (checkMaxFiles()) {
        if (checkSizeFile(firstFile)) {
          if (checkFileType(firstFile)) {
            setFile(firstFile);
            setErrorMessage('');
          }
        }
      }
    } else {
      setErrorMessage('Não é possível fazer upload desse arquivo!');
    }
  };

  const fileRemove = (file: any) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onRemoveFile && onRemoveFile(updatedList);
    setErrorMessage('');
  };

  return (
    <BoxDropFile>
      <DropFileInput
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={imageUrl ? imageUrl : uploadImg} alt="" />
          <p>{title ? title : 'Arraste e solte seus arquivos aqui'}</p>
          <span>{description}</span>
        </div>
        <input
          type="file"
          value=""
          onChange={({ target }) => onFileDrop(target)}
        />
      </DropFileInput>
      {fileList.length > 0 ? (
        <DropFilePreview>
          <span className="error-message">{errorMessage}</span>
          <p className="drop-file-preview__title">Pronto para salvar</p>
          {fileList.map((item: File) => {
            const itemType = item.type.split('/')[1];

            return (
              <div key={`${item.name}`} className="drop-file-preview__item">
                <img
                  src={ImageConfig[itemType] || ImageConfig['default']}
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <span>{item.name}</span>
                  <span>{formatBytes(item.size)}</span>
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  <DeleteOutlined />
                </span>
              </div>
            );
          })}
        </DropFilePreview>
      ) : (
        <span className="error-message">{errorMessage}</span>
      )}
    </BoxDropFile>
  );
}

export default DragAndDropFile;
