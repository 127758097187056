import ContentBase from '../../templates/ContentBase';
import pathBanner from '../../../assets/images/banner_course.png';
import CourseProgression from './CourseProgression';
import { WrapperBannerImg } from './styles';
import Title from '../../../components/Title';
import StartupCourseListing from './StartupCourseListing';
import { useNavigate } from 'react-router-dom';

function Courses() {
  const navigate = useNavigate();

  return (
    <ContentBase
      title="Cursos"
      questionIsVisible={false}
      content={
        <>
          <WrapperBannerImg
            onClick={() => navigate('/courses-startup/course-plans')}
            height={191}
            src={pathBanner}
            alt="banner curso"
          />
          <CourseProgression />
          <Title margin="1rem 0" color="#960096" size={26} align="center">
            Últimos lançamentos
          </Title>
          <StartupCourseListing />
        </>
      }
    />
  );
}

export default Courses;
