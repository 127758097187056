import React, { useEffect, useState } from 'react';
import DiagnosisInfo from '../../../Infos/DiagnosisInfo';
import ContentBase from '../../../templates/ContentBase';
import ScreenInformationVoid from '../../../../components/Screen/ScreenInformationVoid';
import Loading from '../../../../components/Loading/Loading';
import StartupService from '../../../../services/StartupService';
import { useLocation } from 'react-router-dom';
import { Title, Box, Card, CardItem } from './styles';
import { Row } from 'antd/es';
import { Col, Dropdown, Menu } from 'antd';
import PathDiagnosticResult from '../../../../assets/icons/diagnostic-result.png';
import {
  IconOptions,
  MediaQueryVisible,
  MenuOption,
} from '../../../../GlobalStyles';
import { DownloadOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Actions } from '../../../diagnosis/startup/DiagnosticTopic/styles';
import DiagnosticResult from '../../../diagnosis/startup/DiagnosticResult';
import DiagnosticTopicMobile from '../../../diagnosis/startup/DiagnosticTopicMobile';
import { isEmpty } from '../../../../helpers/array.helper';

function StartupDiagnosticsList() {
  const [loading, setLoading] = useState<boolean>(false);
  const startupService = StartupService.init();
  const [dataStartup, setDataStartup] = useState<any>();
  const [showResult, setShowResult] = useState(false);
  const [diagnostic, setDiagnostic] = useState<any>();

  const location = useLocation();
  const { state } = location;

  const getDiagnosis = () => {
    setLoading(true);
    startupService
      .getAllDiagnostics(Number(state.id))
      .then((response) => {
        setDataStartup(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const seeResult = (params: any) => {
    setDiagnostic(params);
    setShowResult(true);
  };

  const menu = (params: any) => (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <MenuOption onClick={() => seeResult(params)}>
              <EyeOutlined />
              <p>Ver resultado</p>
            </MenuOption>
          ),
        },
        {
          key: '2',
          label: (
            <MenuOption onClick={() => seeResult(params)}>
              <DownloadOutlined />
              <p>Baixar PDF</p>
            </MenuOption>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    getDiagnosis();
  }, []);

  const cardAccomplished = (diagnosis: any) => {
    return (
      <Row align="middle">
        <Col span={8}>
          <Row align="middle" gutter={10}>
            <Col>
              <img
                src={PathDiagnosticResult}
                alt="resultado diagnostico"
                height={78}
                width={78}
              />
            </Col>
            <Col>
              <Title fontSize="17px" maxWidth="200px">
                {`${diagnosis.diagnostic?.name} - (${diagnosis.diagnostic?.phase?.name})`}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <CardItem>
            <b>Data de entrada:</b> {diagnosis.creation}
          </CardItem>
        </Col>
        <Col span={5}>
          <CardItem>
            <b>Data de execução: </b>
            {diagnosis.executionDate}
          </CardItem>
        </Col>
        <Col span={4}>
          <CardItem>
            <b
              style={{
                color: '#00C853',
              }}
            >
              Concluído
            </b>
          </CardItem>
        </Col>
        <Col span={2}>
          <Actions>
            <Dropdown
              overlay={menu(diagnosis)}
              trigger={['click']}
              placement="topLeft"
            >
              <IconOptions cursor="pointer" size="24px">
                <MoreOutlined />
              </IconOptions>
            </Dropdown>
          </Actions>
        </Col>
      </Row>
    );
  };

  return (
    <ContentBase
      title="Diagnóstico"
      content={
        <Loading size="large" spinning={loading} width={300} height={300}>
          {dataStartup !== undefined || dataStartup ? (
            <>
              <DiagnosticResult
                show={showResult}
                close={() => setShowResult(false)}
                startup={diagnostic?.startup}
                diagnostic={diagnostic}
              />

              <MediaQueryVisible maxWidth="700px" display="none">
                <Box isBlocked={false}>
                  <Title> Diagnósticos Realizados, {state?.name} </Title>
                  {dataStartup?.map((diagnosis: any, index: number) => (
                    <Card key={`topic_key_${index}`}>
                      {cardAccomplished(diagnosis)}
                    </Card>
                  ))}
                </Box>
              </MediaQueryVisible>

              <MediaQueryVisible minWidth="701px" display="none">
                <DiagnosticTopicMobile
                  topics={dataStartup}
                  title={`Diagnósticos Realizados, ${state?.name}`}
                />
              </MediaQueryVisible>
            </>
          ) : (
            <>
              <ScreenInformationVoid
                isButton={false}
                message={'Sem diagnóstico no momento...'}
              />
            </>
          )}
        </Loading>
      }
      modalContent={<DiagnosisInfo />}
    />
  );
}

export default StartupDiagnosticsList;
