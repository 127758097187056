import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Col, notification, Row, Space } from 'antd';
import Text from '../../../components/Text';
import MetricListBox from './MetricListBox';
import { useNavigate } from 'react-router-dom';
import { BoxWhite, PurpleButton } from '../../../GlobalStyles';
import ContentBase from '../../templates/ContentBase';
import IMetric from '../../../core/interfaces/IMetric';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import MetricCrudService from '../../../services/MetricCrudService';
import { useAuth } from '../../../core/hooks/auth';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import MetricsInfo from '../../Infos/MetricsInfo';

function Metrics() {
  const { logged } = useAuth();
  const navigate = useNavigate();
  const metricCrudService = useMemo(() => MetricCrudService.init(), []);
  const { loading, showLoading, hideLoading } = useLoading();

  const [metrics, setMetrics] = useState<IMetric[]>([]);

  const getMetrics = useCallback(() => {
    showLoading();
    metricCrudService
      .findyByStartup(Number(logged?.entityId))
      .then((data: IMetric[]) => setMetrics(data))
      .catch(() => {
        notification.error({
          message: 'Erro ao listar métricas',
        });
      })
      .finally(hideLoading);
  }, [logged, metricCrudService]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <Loading spinning={loading} height={200} width={200}>
      <ContentBase
        title="Métricas"
        modalContent={<MetricsInfo />}
        content={
          <>
            <Row gutter={10}>
              <Col>
                <PurpleButton
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => navigate('/metrics/form')}
                  style={{ height: '40px', width: '40px' }}
                />
              </Col>
              <Col>
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<SearchOutlined />}
                  style={{
                    height: '40px',
                    width: '40px',
                    backgroundColor: 'var(--white-color)',
                  }}
                />
              </Col>
            </Row>
            <BoxWhite>
              <Text margin="6px 0" size={16} weight={600}>
                Métricas atuais
              </Text>
              <Space direction="vertical">
                <OnEmptyOrList
                  list={metrics}
                  description="Nenhuma métrica cadastrada"
                >
                  {metrics.map((metric, index) => (
                    <MetricListBox
                      key={`key_metric_${index}`}
                      metric={metric}
                      reload={getMetrics}
                    />
                  ))}
                </OnEmptyOrList>
              </Space>
            </BoxWhite>
          </>
        }
      />
    </Loading>
  );
}

export default Metrics;
