import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Row, Col, Divider, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import image from '../../../assets/images/logo-rocket.png';
import { Header, BoxContent, Label } from './style';
import WeigthValuationService from '../../../services/WeightMethodValuationService';
import StagesService from '../../../services/StagesService';
import IWeigthMethodsValuations from '../../../core/interfaces/IWeigthMethodsValuations';
import useLoading from '../../../core/hooks/useLoading';
import notificationMessage from '../../../components/notifications/notificationMessage';
import ErrorNotification from '../../../components/notifications/errorNotification';
import { useAuth } from '../../../core/hooks/auth';
import { IStages } from '../../../core/interfaces/IStages';
import AlertMessage from '../../../components/Alert/AlertMessage';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: 'Campo obrigatório!',
  maxLength: 'Máximo de ${max} caracteres!',
};

const validator = (rule: any, value: any) => {
  if (value > 100) {
    return Promise.reject('Valor não pode ser maior que 100%');
  }
  return Promise.resolve();
};

interface IProps {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;
  next: string;
  setParamsResponse: (response: any) => void;
  initialValues?: any;
  formReadOnly?: boolean;
}

function WeightValuation(props: IProps) {
  const [nameForm] = useState('form-weight-valuation');
  const [form] = Form.useForm();
  const [stages, setStages] = useState<IStages[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { logged } = useAuth();
  const { hideLoading, loading, showLoading } = useLoading();
  const weightValuationService = React.useMemo(
    () => WeigthValuationService.init(),
    [],
  );
  const stagesService = React.useMemo(() => StagesService.init(), []);
  const [payload, setPayload] = useState<IWeigthMethodsValuations[]>([]);
  const id: any = logged?.client;

  const [loadDataForm, setLoadDataForm] = useState<
    {
      stages: IStages;
      weightMethodValuation: IWeigthMethodsValuations[];
    }[]
  >([]);

  const getWeightValuation = () => {
    showLoading();
    weightValuationService
      .getAllByClient(id)
      .then((response) => {
        if (response.length > 0) {
          response.forEach((item) => {
            setPayload((prevState) => {
              return [
                ...prevState,
                {
                  entityId: item.entityId,
                  weigth: item.weigth,
                  clientId: item.client?.entityId,
                  typeValuation: item.typeValuation,
                  stagesId: item.stages?.entityId,
                },
              ];
            });
          });
        }
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const getStages = useCallback(() => {
    showLoading();
    stagesService
      .listAll()
      .then((response) => {
        setStages(response);
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        hideLoading();
      });
  }, []);

  const arrageValues = (values: FormInstance) => {
    const valuesMap = new Map(Object.entries(values));
    const result: IWeigthMethodsValuations[] = [];

    Array.from(valuesMap).map((item) => {
      const [key, value] = item;
      const map = new Map(Object.entries(value));
      return map.forEach((value, i) => {
        result.push({
          weigth: value as number,
          stagesId: Number(i),
          clientId: id,
          typeValuation: key,
        });
      });
    });

    return result;
  };

  const onFinishSave = async (values: FormInstance) => {
    showLoading();
    await weightValuationService
      .saveAll(arrageValues(values))
      .then(() => {
        notificationMessage();
        setIsModalVisible(true);
        clearForm();
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(hideLoading);
  };

  const onFinishUpdate = async () => {
    showLoading();
    await weightValuationService
      .updateAll(payload)
      .then(() => {
        notificationMessage();
        setIsModalVisible(true);
        clearForm();
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(hideLoading);
  };

  const onOk = () => {
    setIsModalVisible(false);
    props.setNext && props.setNext((Number(props.next) + 1).toString());
  };

  const clearForm = () => {
    form.resetFields();
  };

  const handleChange = (value: string, id: number) => {
    if (id !== undefined) {
      payload.find(
        (item) => item.entityId === id && (item.weigth = Number(value)),
      );
    }
  };

  const setInitialValuesForm = useCallback(() => {
    loadDataForm.map((item) =>
      item.weightMethodValuation.map((item) => {
        form.setFieldsValue({
          [`${item.typeValuation}`]: {
            [`${item.stagesId}`]: item.weigth,
          },
        });
      }),
    );
  }, [loadDataForm]);

  props.setFormName && props.setFormName(nameForm);

  useEffect(() => {
    getWeightValuation();
    getStages();
  }, []);

  useEffect(() => {
    if (payload.length > 0) {
      stages.forEach((stage, index) => {
        const aux: IStages = stage;
        let filter: any = [];
        payload.forEach((data) => {
          if (aux.entityId === data.stagesId) {
            filter = payload.filter((item) => item.stagesId === stage.entityId);
          }
        });

        setLoadDataForm((prevState) => {
          return [
            ...prevState,
            {
              stages: stage,
              weightMethodValuation: filter,
            },
          ];
        });
      });
    } else {
      stages.forEach((stage, index) => {
        setLoadDataForm((prevState) => {
          return [
            ...prevState,
            {
              stages: stage,
              weightMethodValuation: [
                {
                  weigth: 20,
                  stagesId: stage.entityId,
                  clientId: id,
                  typeValuation: 'SCORECARD',
                  stages: stage,
                },
                {
                  weigth: 20,
                  stagesId: stage.entityId,
                  clientId: id,
                  typeValuation: 'BERKUS',
                  stages: stage,
                },
                {
                  weigth: 20,
                  stagesId: stage.entityId,
                  clientId: id,
                  typeValuation: 'VENTURYCAPITAL',
                  stages: stage,
                },
                {
                  weigth: 20,
                  stagesId: stage.entityId,
                  clientId: id,
                  typeValuation: 'PERPETUITY',
                  stages: stage,
                },
                {
                  weigth: 20,
                  stagesId: stage.entityId,
                  clientId: id,
                  typeValuation: 'DCF',
                  stages: stage,
                },
              ],
            },
          ];
        });
      });
    }
  }, [payload, stages]);

  useEffect(() => {
    setInitialValuesForm();
  }, [loadDataForm]);

  return (
    <BoxContent>
      <Header>
        <img alt="" src={image} />
        <div className="description">
          Os pesos para cada método de valuation serão definidos pela sua
          aceleradora, precisa estar de acordo com cada fase de maturidade das
          startups.
        </div>
      </Header>
      <Spin spinning={loading}>
        <Form
          disabled={props.formReadOnly}
          {...layout}
          name={nameForm}
          onFinish={payload.length > 0 ? onFinishUpdate : onFinishSave}
          validateMessages={validateMessages}
          form={form}
          onFieldsChange={(changedFields, allFields) => {
            let total = 0;
            if (changedFields) {
              const { name }: any = changedFields[0];

              const fields = allFields.filter((item: any) => {
                return name[1] === item.name[1];
              });

              fields.forEach((item: any) => {
                total += Number(item.value);
              });

              if (total !== 100) {
                //set error in field
                fields.forEach((item: any) => {
                  form.setFields([
                    {
                      name: item.name,
                      errors: ['A soma dos pesos deve ser igual a 100%'],
                    },
                  ]);
                });
              } else {
                //remove error in field
                fields.forEach((item: any) => {
                  form.setFields([
                    {
                      name: item.name,
                      errors: [],
                    },
                  ]);
                });
              }
            }
          }}
        >
          <Row gutter={16}>
            <Col span={4} className={'row-form'}>
              <Label>Estágio de desenvolvimento</Label>
              <Divider />
            </Col>
            <Col span={4} className={'row-form'}>
              <Label>Método Berkus</Label>
              <Divider />
            </Col>
            <Col span={4} className={'row-form'}>
              <Label>Método Score Card</Label>
              <Divider />
            </Col>
            <Col span={4} className={'row-form'}>
              <Label>Método Venture Capital</Label>
              <Divider />
            </Col>
            <Col span={4} className={'row-form'}>
              <Label>FDC com Múltiplos</Label>
              <Divider />
            </Col>
            <Col span={4} className={'row-form'}>
              <Label>FDC com Perpetuidade</Label>
              <Divider />
            </Col>
          </Row>
          <>
            {loadDataForm.length > 0 &&
              loadDataForm.map((data, index) => (
                <React.Fragment key={index}>
                  <Row gutter={16}>
                    <Col span={4} className={'row-form'}>
                      <Label>
                        {data.stages.name} - Stage 0{index + 1}
                      </Label>
                    </Col>
                    {data.weightMethodValuation.map((item, index) => (
                      <Col span={4} className={'row-form'} key={index}>
                        <Form.Item
                          name={[
                            `${item.typeValuation}`,
                            `${data.stages.entityId}`,
                          ]}
                          rules={[
                            { required: true },
                            {
                              validator: validator,
                            },
                          ]}
                        >
                          <Input
                            maxLength={5}
                            suffix="%"
                            onChange={(e) => {
                              handleChange(
                                e.target.value,
                                item.entityId as number,
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              ))}
          </>
        </Form>
      </Spin>

      <AlertMessage
        message={'Cadastro salvo com sucesso!'}
        description={
          'O questionário foi salvo com sucesso! Continue cadastrando...'
        }
        visible={isModalVisible}
        onOk={onOk}
        textOkButton={'Próximo'}
      />
    </BoxContent>
  );
}

export default WeightValuation;
