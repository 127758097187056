import styled from 'styled-components';

const StartupImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
`;

const VagueInformation = styled.div`
  display: flex;
  gap: 12px;
`;

export { StartupImage, VagueInformation };
