import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function ValuationStartupInfo() {
  return (
    <BoxInfo
      title="Valuation"
      image={image}
      description={
        <p>
          Valuation é um processo para avaliar o valor da empresa que um
          investidor está disposto a pagar para participar de um retorno
          financeiro, assim, você terá como base o preço justo que sua startup
          está valendo. Conforme dados e aspectos que você insere da sua
          startup, é resultante um cálculo que levará em consideração
          pontuações, múltiplos e outros, gerando o valor do seu Valuation.
        </p>
      }
    />
  );
}

export default ValuationStartupInfo;
