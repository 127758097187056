import { BoxContainer } from './style';
import { Form } from 'antd';
import api from '../../../core/services/api';
import { useLocation } from 'react-router-dom';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import { LineScale } from '../../../components/LineScale';
import { onlyNumbers } from '../../../helpers/string.helper';
import { notification } from '../../../helpers/notification.helper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import notificationMessage from '../../../components/notifications/notificationMessage';
import { ValuationAnswerService } from '../../../services/valuation/ValuationAnswerService';
import {
  Props,
  AnswerType,
  QuestionType,
} from '../../../types/body-slider/BodySliderType';

function BodySlider(props: Props) {
  const [form] = Form.useForm();
  const [question, setQuestion] = useState<QuestionType[]>([]);
  const [answers, setAnswers] = useState<AnswerType[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();
  const service = useMemo(() => ValuationAnswerService.init(), []);
  const { state }: { state: any } = useLocation();
  const idValuationResult = state?.entityId;

  const fetchQuestion = useCallback(() => {
    showLoading();
    service
      .getQuestion({
        pillarId: props.id,
        valuationId: state?.valuation.entityId,
      })
      .then((data) => setQuestion(data ?? []))
      .catch(() => notification.error('Erro ao listar questões'))
      .finally(hideLoading);
  }, [service, showLoading, hideLoading, state?.valuation.entityId, props.id]);

  const fetchAnswer = useCallback(() => {
    showLoading();
    service
      .getAnswer({
        pillarId: props.id,
        valuationResultId: state?.entityId,
      })
      .then((data) => setAnswers(data ?? []))
      .catch(() => notification.error('Erro ao carregar respostas'))
      .finally(hideLoading);
  }, [service, showLoading, hideLoading, state?.entityId, props.id]);

  useEffect(() => {
    fetchAnswer();
    fetchQuestion();
  }, [fetchQuestion, fetchAnswer]);

  const getRequestBody = () =>
    answers.map(({ entityId, answer }) => ({
      entityId,
      answer,
    }));

  const saveAnswer = () => {
    showLoading();
    api
      .post(`valuation/answer/create/${idValuationResult}`, getRequestBody(), {
        params: { offer: props.params },
      })
      .then(() => {
        notificationMessage('create');
        props.setNext && props.setNext((Number(props.next) + 1).toString());
        props.closeModal && props.closeModal();
      })
      .catch((err) => {
        notification.error(err.response.data.message);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    props.setFormName && props.setFormName('valuation');
  }, []);

  useEffect(() => {
    if (answers.length > 0) {
      form.setFieldsValue(answers);
    }
  }, [answers]);

  const findAnswerByQuestionId = (id: number) => {
    return answers.find((answer) => answer?.entityId === id);
  };

  const findQuestionById = (id: number) => {
    return answers.find((answer) => answer.question?.entityId === id);
  };

  const changeAnswer = (newAnswer: number, questionId = 0) => {
    const searchAnswer = findAnswerByQuestionId(questionId);
    if (searchAnswer) {
      searchAnswer.answer = newAnswer;
      setAnswers([...answers]);
    } else {
      console.info(`Answer by question ${questionId} not found!`);
    }
  };

  const validateSendingResponses = () => {
    return new Promise((resolve, reject) => {
      const offerValue = props.params;
      const offerIsValid = Boolean(offerValue);
      const offerIsPositive = Number(onlyNumbers(offerValue)) > 0;

      if (offerIsValid && offerIsPositive) {
        return resolve('Formulário valido');
      }

      return reject('Valor de oferta não informado');
    });
  };

  const handleSubmit = () => {
    validateSendingResponses()
      .then(saveAnswer)
      .catch((error) => {
        console.warn(error);
        notification.info(error);
      });
  };

  return (
    <BoxContainer>
      <Loading spinning={loading} width={300} height={300}>
        <Form name="valuation" form={form} onFinish={handleSubmit}>
          {question.map(({ entityId, scales, question }, index: number) => {
            const answer = findQuestionById(entityId);
            return (
              <Form.Item key={`question_${entityId}`} name={entityId} required>
                <LineScale
                  scales={scales ?? []}
                  answer={answer?.answer ?? 1}
                  title={`${index + 1} - ${question}`}
                  changeAnswer={(value) =>
                    changeAnswer(value, answer?.entityId)
                  }
                />
              </Form.Item>
            );
          })}
        </Form>
      </Loading>
    </BoxContainer>
  );
}

export default BodySlider;
