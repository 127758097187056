import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = {
  entityId: number | null;
};

const initialState: IState = {
  entityId: null,
};

const ValuationSlice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {
    _updateValuation(state, action: PayloadAction<IState>) {
      state.entityId = action.payload.entityId;
    },
  },
});

export const valuationReducer = ValuationSlice.reducer;
export const { _updateValuation } = ValuationSlice.actions;
