import styled from 'styled-components';

const Box = styled.div`
  gap: 0.5rem;
  display: grid;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr;
  max-width: 280px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #05233d66;
`;

const ProgressBar = styled.div<{
  width: number;
}>`
  background: #f0f2f5;
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  overflow: hidden;

  & > div {
    color: #fff;
    padding: 3px 1px;
    min-width: 40px;
    text-align: center;
    background-color: #960096;
    width: ${({ width }) => `${width}%`};
  }
`;

export { ProgressBar, Box };
