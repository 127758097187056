import React from 'react';
import CoursesToolsInfo from '../../../Infos/CoursesToolsInfo';
import ContentBase from '../../../templates/ContentBase';
import { BoxWhite } from '../../../../GlobalStyles';
import FormCourseCategory from './FormCourseCategory';

function ConfigCourses() {
  return (
    <ContentBase
      title={'Categoria de cursos'}
      goBack={true}
      path={'tools'}
      modalContent={<CoursesToolsInfo />}
      content={
        <BoxWhite>
          <FormCourseCategory />
        </BoxWhite>
      }
    />
  );
}

export default ConfigCourses;
