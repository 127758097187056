import React, { CSSProperties } from 'react';
import Box from './styles';

interface Props extends React.StyleHTMLAttributes<HTMLParagraphElement> {
  size?: number;
  color?: string;
  align?: string;
  weight?: number;
  maxWidth?: string;
  margin?: string;
  display?: string;
  className?: string;
  lineHeight?: number;
  lineClamp?: number;
  style?: CSSProperties;
  children: React.ReactNode;
  sizeXs?: number;
  wordBreak?: string;
}

function Text({ children, ...props }: Props) {
  return <Box {...props}>{children}</Box>;
}

export default Text;
