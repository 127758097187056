import React from 'react';
import { ContentWrapper } from './styles';
import Text from '../../../../components/Text';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../GlobalStyles';
import Title from '../../../../components/Title';
import { maskMoney } from '../../../../helpers/mask.helper';
import pathIcon from '../../../../assets/icons/document-icon.png';

type Props = {
  id?: number;
  preMoney?: number;
};

function ValuationTopicMobile({ id, preMoney = 0 }: Props) {
  const navigate = useNavigate();

  const getResult = () => {
    navigate('/valuations/results', { state: { id } });
  };

  return (
    <Card onClick={getResult}>
      <ContentWrapper>
        <div>
          <img height={60} width={60} src={pathIcon} alt="icone documento" />
          <Title size={16}>Valuation fixo</Title>
        </div>
        <Text size={16} color="#9AA4A8">
          Pré-money valuation
        </Text>
        <Text weight={700} size={16} color="#960096">
          {maskMoney(preMoney)}
        </Text>
      </ContentWrapper>
    </Card>
  );
}

export default ValuationTopicMobile;
