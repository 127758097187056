import styled from 'styled-components';

const WrapperCheckbox = styled.div`
  .ant-checkbox-group-item {
    & div.active::after {
      content: '';
      display: block;
      height: 2px;
      left: 0;
      right: 0;
      background-color: #960096;
      position: absolute;
    }
  }
`;

export { WrapperCheckbox };
