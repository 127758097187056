import styled from 'styled-components';

const WrapperInformationBox = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

export { WrapperInformationBox };
