import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { IconOptions } from '../../GlobalStyles';
import { Box } from './styles';
import { Button } from 'antd';

type typeButton = 'primary' | 'default' | 'dashed' | 'link' | 'text';
type typeShape = 'circle' | 'round' | 'default';
type typeSize = 'small' | 'middle' | 'large';
interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  width?: string;
  height?: string;
  background?: string;
  colorHover?: string;
  margin?: string;
  backgroundHover?: string;
  sizeIcon?: string;
  disabled?: boolean;
  type?: typeButton;
  shape?: typeShape;
  size?: typeSize;
  position?: string;
}

function AddListButton({
  children,
  onClick,
  disabled = false,
  type = 'default',
  shape = 'circle',
  size = 'large',
  ...props
}: Props) {
  return (
    <Box onClick={onClick} {...props}>
      <Button type={type} shape={shape} disabled={disabled} size={size}>
        <IconOptions data-icon display="flex" size={props.sizeIcon || '20px'}>
          <PlusOutlined />
        </IconOptions>
      </Button>
      {!disabled && <span data-text>{children}</span>}
    </Box>
  );
}

export default AddListButton;
