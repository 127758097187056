import styled from 'styled-components';

const Description = styled.div`
  display: flex;
  text-align: justify;
`;

const BoxRadio = styled.div`
  display: flex;
  flex-direction: column;
`;

export { BoxRadio, Description };
