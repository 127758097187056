import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import Title from '../../../components/Title';
import { useAuth } from '../../../core/hooks/auth';
import BusinessInformation from './BusinessInformation';
import { CONVENTIONAL_FORMAT } from '../../../constants/date-formats';
import ProfileHeader from '../../../components/Profiles/ProfileHeader';
import ProfileForm from './ProfileForm';
import EntrepreneurData from './EntrepreneurData';
import StartupService from '../../../services/StartupService';
import { notification } from '../../../helpers/notification.helper';
import useLoading from '../../../core/hooks/useLoading';

function Profile() {
  const { logged } = useAuth();
  const { hideLoading, showLoading, loading } = useLoading();

  const { entityId = 0 } = logged ?? {};

  const [startupName, setStartupName] = useState('...');
  const [dateExpiration, setDateExpiration] = useState<string | undefined>('');

  const startupService = useMemo(() => StartupService.init(), []);

  const getStartup = useCallback(() => {
    showLoading();
    startupService
      .getByOwner(entityId)
      .then((startup) => {
        setDateExpiration(startup.owner?.expiration);
        setStartupName(startup.name);
      })
      .catch(() => notification.error('Startup não encontrada'))
      .finally(hideLoading);
  }, [entityId, hideLoading, showLoading, startupService]);

  useEffect(() => {
    getStartup();
  }, [getStartup]);

  return (
    <>
      <Title size={28} margin="0 0 10px 0">
        Meu perfil
      </Title>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <ProfileHeader
            dateExpiration={dateExpiration}
            name={startupName}
            loading={loading}
            description={logged?.description}
            metaData={[
              {
                label: 'Aceleradora',
                isLink: true,
                value: logged?.acceleratorName ?? '--',
              },
              {
                label: 'Entrada',
                value:
                  moment(logged?.creation).format(CONVENTIONAL_FORMAT) ?? '--',
              },
              {
                label: 'Última atualização',
                value:
                  moment(logged?.lastUpdated).format(CONVENTIONAL_FORMAT) ??
                  '--',
              },
              {
                label: 'Status atual',
                value: logged?.active ? 'Ativo' : 'Inativo',
              },
              {
                label: 'E-mail',
                isLink: true,
                value: logged?.email ?? '--',
              },
            ]}
          >
            {(propsModal) => (
              <ProfileForm onReload={getStartup} {...propsModal} />
            )}
          </ProfileHeader>
        </Col>
        <Col span={24}>
          <BusinessInformation />
        </Col>
        <Col span={24}>
          <EntrepreneurData />
        </Col>
      </Row>
    </>
  );
}

export default Profile;
