import React, { useEffect, useState } from 'react';
import { BoxContent, Header } from './style';
import Tab from '../../../components/tab';
import image from '../../../assets/images/logo-rocket.png';
import { useAuth } from '../../../core/hooks/auth';
import TabGray from '../../../components/tab/tabGray';
import ValuationQuestions from '../../../components/ValuationQuestions/ValuationQuestions';

interface IParams {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;
  next: string;
  setParamsResponse: (response: any) => void;
  initialValues?: any;
  formReadOnly?: boolean;
}

function ScoreCard(props: IParams) {
  const [ask, setAsk] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<string>('0');
  const [tabGray, setTabGray] = useState<string>('0');
  const [next, setNext] = useState<string>('0');
  const { logged } = useAuth();
  const entityId = logged?.client;

  const content = [
    {
      tab: 'Potencial Startup',
      body: (
        <TabGray
          setAsk={setAsk}
          ask={ask}
          formName={'valuationBerkusForm'}
          tabKey={tabGray}
          setNext={setNext}
          content={[
            {
              tab: 'Potencial de mercado',
              body: (
                <ValuationQuestions
                  pillarId={11}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Potencial de mercado'}
                  legend={true}
                  weight={true}
                  setAsk={setAsk}
                  ask={ask}
                  storageName="form-startup-potential"
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
            {
              tab: 'Potencial de Inovação',
              body: (
                <ValuationQuestions
                  pillarId={12}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Potencial de Inovação'}
                  legend={true}
                  weight={true}
                  storageName="form-startup-innovation"
                  setAsk={setAsk}
                  ask={ask}
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
            {
              tab: 'Potencial de Empreendedor',
              body: (
                <ValuationQuestions
                  pillarId={13}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Potencial de Empreendedor 555'}
                  legend={true}
                  weight={true}
                  setAsk={setAsk}
                  storageName="form-startup-entrepreneur"
                  ask={ask}
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
          ]}
        />
      ),
    },
    {
      tab: 'Análises de Risco',
      body: (
        <TabGray
          formName={'valuationBerkusForm'}
          setAsk={setAsk}
          ask={ask}
          tabKey={tabGray}
          setNext={setNext}
          content={[
            {
              tab: 'Risco de Mercado',
              body: (
                <ValuationQuestions
                  pillarId={14}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Risco de Mercado'}
                  legend={true}
                  weight={true}
                  setAsk={setAsk}
                  storageName="form-risk-mart"
                  ask={ask}
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
            {
              tab: 'Risco de Inovação',
              body: (
                <ValuationQuestions
                  pillarId={15}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Risco de Inovação'}
                  legend={true}
                  weight={true}
                  setAsk={setAsk}
                  ask={ask}
                  storageName="form-risk-innovation"
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
            {
              tab: 'Risco de Empreendedor',
              body: (
                <ValuationQuestions
                  pillarId={16}
                  initialValues={props.initialValues}
                  typeValuation="SCORECARD"
                  tabName={'Risco de Empreendedor'}
                  legend={true}
                  weight={true}
                  setAsk={setAsk}
                  ask={ask}
                  storageName="form-risk-entrepreneur"
                  clientId={entityId}
                  setNext={setNext}
                  next={next}
                  setFormName={props.setFormName}
                  formReadOnly={props.formReadOnly}
                />
              ),
            },
          ]}
        />
      ),
    },
    {
      tab: 'Financeiro',
      body: (
        <ValuationQuestions
          pillarId={17}
          initialValues={props.initialValues}
          typeValuation="SCORECARD"
          tabName={'Financeiro'}
          legend={true}
          weight={true}
          setAsk={setAsk}
          storageName="form-financial"
          ask={ask}
          clientId={entityId}
          setFormName={props.setFormName}
          formReadOnly={props.formReadOnly}
        />
      ),
    },
  ];

  useEffect(() => {
    setTabGray(next);
  }, [next]);

  useEffect(() => {
    if (tabGray === '3') {
      setTabActive((Number(tabActive) + 1).toString());
      setNext('0');
    }
  }, [tabGray]);

  return (
    <BoxContent>
      <Header>
        <img alt="" src={image} />
        <div className="description">
          É um método com aspectos qualitativos, cujo principal princípio deste
          método é que transações comparáveis e recentes são relevantes na
          precificação de uma empresa. O Innovation Studio revisou o sistema de
          pontuação e as informações sobre as quais as pontuações são
          atribuídas. As áreas de avaliação da startup são: força da equipe,
          tamanho da oportunidade, ambiente competitivo, força e proteção de
          produto/serviço, relações estratégicas com parceiros e por fim,
          financiamento. Cada um desses pontos têm seus respectivos pesos.
        </div>
      </Header>
      <Tab
        content={content}
        ask={ask}
        setAsk={setAsk}
        fontSize={'0.8em'}
        border={true}
        formName={'valuationBerkusForm'}
        activeKey={tabActive}
        changeActiveKey={setTabActive}
      />
    </BoxContent>
  );
}

export default ScoreCard;
