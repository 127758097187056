import styled from 'styled-components';
import { Slider } from 'antd';

const WeightMethod = styled(Slider)`
  margin: 8px auto 0 auto;
  max-width: 180px;
  cursor: default;

  & .ant-slider-rail {
    height: 7px !important;
    background: var(--primary-color) !important;
  }

  & .ant-slider-track {
    height: 15px !important;
    top: 1px;
    background: var(--thirdy-color) !important;
  }

  & .ant-slider-handle {
    display: none !important;
  }

  @media (max-width: 1400px) {
    max-width: 140px;
  }

  @media print {
    max-width: 100px;
    margin-left: 50px;
  }
`;

export { WeightMethod };
