import React from 'react';
import {
  BoxContainer,
  ButtonContainer,
  Content,
  Introduction,
  RocketImage,
  Title,
} from '../style';
import rocketIco from '../../../assets/images/ico-logo-rocket.svg';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Container } from '../../../GlobalStyles';

function RegisterConclude() {
  return (
    <Content isLight={true}>
      <Container>
        <BoxContainer maxWidth="800px" width="100%" position="initial">
          <RocketImage>
            <img alt="icone innovation" src={rocketIco} />
          </RocketImage>
          <Title>Bem vindo ao Innovation Studio!</Title>
          <Introduction>
            Somos uma plataforma que conecta startups com aceleradoras,
            investidores e outros atores do ecossistema de inovação do Brasil e
            do mundo. Dessa forma, precisamos saber qual o seu perfil para que,
            assim, possamos ajudar na sua jornada empreendedora.
            <p>Está preparado?</p>
          </Introduction>
          <ButtonContainer>
            <Button
              type="primary"
              style={{ backgroundColor: 'var(--thirdy-color)' }}
            >
              <Link to={'/register/conclude/profile'}>Iniciar Jornada</Link>
            </Button>
          </ButtonContainer>
        </BoxContainer>
      </Container>
    </Content>
  );
}

export default RegisterConclude;
