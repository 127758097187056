import { PlusOutlined } from '@ant-design/icons';
import { Col, Pagination, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'sunflower-antd';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useAuth } from '../../../core/hooks/auth';
import useLoading from '../../../core/hooks/useLoading';
import usePagination from '../../../core/hooks/usePagination';
import IPageable from '../../../core/interfaces/IPageable';
import IVacancies from '../../../core/interfaces/IVacancies';
import { Card, PurpleButton } from '../../../GlobalStyles';
import { notification } from '../../../helpers/notification.helper';
import CrudService from '../../../services/CrudService';
import VacanciesService from '../../../services/VacanciesService';
import ContentBase from '../../templates/ContentBase';
import { EmptyWrapper } from './styles';
import VacanciesCard from './VacanciesCard';
import VacanciesForm from './VacanciesForm';

function StartupVacancies() {
  const { logged } = useAuth();
  const vacanciesModal = useModal({
    defaultVisible: false,
  });
  const { currentPage, changePagination } = usePagination();
  const { loading, hideLoading, showLoading } = useLoading();

  const { entityId = 0 } = logged ?? {};

  const [vacancies, setVacancies] = useState<IPageable<IVacancies>>(
    CrudService.pageableDefault(),
  );
  const [selectedVacancy, setSelectedVacancy] = useState<IVacancies | null>(
    null,
  );

  const vacanciesService = useMemo(() => VacanciesService.init(), []);

  const getVacancies = useCallback(() => {
    showLoading();
    vacanciesService
      .findByUserId(entityId, {
        page: currentPage,
        size: 10,
        sort: 'entityId,desc',
      })
      .then((data) => setVacancies(data))
      .catch(() => notification.error('Erro ao listar vagas'))
      .finally(hideLoading);
  }, [entityId, currentPage, hideLoading, showLoading, vacanciesService]);

  useEffect(() => {
    getVacancies();
  }, [getVacancies]);

  return (
    <ContentBase
      title="Contratação"
      questionIsVisible={false}
      content={
        <>
          <VacanciesForm
            {...vacanciesModal.modalProps}
            onReload={getVacancies}
            onCancel={() => {
              vacanciesModal.close();
              setSelectedVacancy(null);
            }}
            vacancy={selectedVacancy}
          />

          <Spin spinning={loading}>
            {isEmpty(vacancies) ? (
              <EmptyWrapper>
                <Title size={24}>Você não cadastrou vagas</Title>
                <Text weight={500} maxWidth="70ch">
                  Cadastre vagas para compor seu time que farão sua startup
                  decolar! Lembre-se de que as tratativas entre startup e
                  profissional são por conta dos próprios interessados, ficando
                  aqui apenas a divulgação dos dados referentes a vaga em
                  questão.
                </Text>
                <PurpleButton type="primary" onClick={vacanciesModal.show}>
                  Criar uma vaga
                </PurpleButton>
              </EmptyWrapper>
            ) : (
              <Row style={{ marginTop: '6px' }}>
                <Col span={24}>
                  <PurpleButton
                    shape="circle"
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={vacanciesModal.show}
                  />
                </Col>
                <Col span={24}>
                  <Card
                    boxShadow="0px 3px 6px #05233D29"
                    style={{ marginTop: '12px' }}
                  >
                    <Row gutter={[12, 12]}>
                      {vacancies.content.map((vacancy) => (
                        <Col key={`vacancy_key_${vacancy.entityId}`} span={24}>
                          <VacanciesCard
                            vacancies={vacancy}
                            onReload={getVacancies}
                            startupName={logged?.name}
                            startupEmail={logged?.email}
                            onSelectVacancy={() => {
                              vacanciesModal.show();
                              setSelectedVacancy(vacancy);
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Pagination
                      pageSize={vacancies.size}
                      onChange={changePagination}
                      total={vacancies.totalElements}
                      style={{
                        padding: '1rem',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          </Spin>
        </>
      }
    />
  );
}

export default StartupVacancies;
