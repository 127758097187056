import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxContainer, BoxWhite, PurpleButton } from '../../../GlobalStyles';
import {
  BoxStartupsList,
  BoxTop,
  BoxTotal,
  CardItem,
  RoundItem,
  RoundList,
  StartupCard,
  Topo,
} from './style';
import image from '../../../assets/images/image-default.png';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import IInvestmentRound from '../../../core/interfaces/IInvestmentRound';
import InvestmentRoundService from '../../../services/InvestmentRoundService';
import { message, notification, Spin } from 'antd';
import { useAuth } from '../../../core/hooks/auth';
import useLoading from '../../../core/hooks/useLoading';
import { convertDateToString } from '../../../helpers/dates.helper';
import IInvestmentRoundStartup from '../../../core/interfaces/IInvestmentRoundStartup';
import CardList from '../../../components/EntityList/CardList';
import { IStartup } from '../../../core/interfaces';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';

function DashboardInvestor() {
  const { loading, showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();
  const [investmentRoundList, setInvestmentRoundList] = useState<
    IInvestmentRound[]
  >([]);
  const [investmentRoundStartups, setInvestmentRoundStartups] = useState<
    IInvestmentRoundStartup[]
  >([]);
  const [startupList, setStartupList] = useState<IStartup[]>([]);
  const investmentRoundService = useMemo(
    () => InvestmentRoundService.init(),
    [],
  );
  const { logged } = useAuth();
  const id = logged?.entityId;

  const getInvestmentRoundList = async () => {
    showLoading();
    investmentRoundService
      .getByUserInvestor(Number(id), false)
      .then((response) => {
        setInvestmentRoundList(response);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar rodadas de investimento',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const getInvestmentRoundStartupList = async () => {
    showLoading();
    investmentRoundService
      .getStartupsByInvestmentRound(Number(id))
      .then((response) => {
        setInvestmentRoundStartups(response);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar startups',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const getCardItem = (label: string, val: string | number, color: string) => {
    let icon: ReactElement | string = '';
    let itemVal = '';

    if (typeof val === 'string') {
      itemVal = val;
    } else {
      itemVal = `${val}%`;
      /* Só validar, não sei se vem negativo ou qual parâmetro para dizer que o índice teve queda */
      icon = val < 10 ? <CaretDownOutlined /> : <CaretUpOutlined />;
    }

    return (
      <CardItem>
        <div className="label">{label}</div>
        <div className="val" style={{ color: `${color}` }}>
          {icon}
          {itemVal}
        </div>
      </CardItem>
    );
  };

  const openStartupProfile = () => {
    navigate('/startup-profile', { state: 0 }); /* ID DA STARTUP AQUI */
  };

  const openInvestmentRoundDetails = (id?: number) => {
    if (id) {
      navigate('/investment-round/details', {
        state: {
          entityId: id,
        },
      });
    } else {
      message.error({
        content: 'Erro ao abrir detalhes da rodada de investimento',
        style: {
          marginTop: '20vh',
          backgroundColor: '#f5222d',
        },
      });
    }
  };

  useEffect(() => {
    getInvestmentRoundList();
    getInvestmentRoundStartupList();
  }, []);

  useEffect(() => {
    if (investmentRoundStartups.length > 0) {
      investmentRoundStartups.forEach((item) => {
        if (item.startup) setStartupList([...startupList, item.startup]);
      });
    }
  }, [investmentRoundStartups]);

  return (
    <BoxContainer>
      <Title>Dashboard</Title>
      <BoxTop>
        <BoxStartupsList>
          {
            /* LOOP AQUI */
            Array.from(Array(4)).map(() => {
              return (
                <>
                  <StartupCard onClick={openStartupProfile}>
                    <div className="header">
                      <img alt="" src={image} />
                      <Text className="text" weight={700} margin="0 0 0 10px">
                        STARTUP NAME
                      </Text>
                    </div>
                    {getCardItem('fase', 'operação', 'var(--thirdy-color)')}
                    {getCardItem(
                      'Evolução do faturamento',
                      parseInt('22'),
                      'var(--success-color)',
                    )}
                    {getCardItem(
                      'Evolução do custo',
                      parseInt('7'),
                      'var(--error-color)',
                    )}
                  </StartupCard>
                </>
              );
            })
          }
        </BoxStartupsList>
        <BoxTotal>
          <Spin spinning={loading}>
            <Topo>
              <Text color="var(--white-color)" margin="0 0 5px 0" weight={700}>
                Total investido em rodadas
              </Text>
              <span className="amount">{`R$ 5.730.000,00`}</span>
            </Topo>
            <RoundList>
              {investmentRoundList.map((item, index) => {
                if (index < 3) {
                  return (
                    <RoundItem
                      key={`INVESTMENT_ROUND_${item.entityId}`}
                      onClick={() =>
                        openInvestmentRoundDetails(item?.investorId)
                      }
                    >
                      <Text
                        title={item.name}
                        style={{
                          width: '30%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        weight={700}
                      >
                        {item.name}
                      </Text>
                      <Text>{`Encerrado: ${convertDateToString(
                        item.endDate,
                      )}`}</Text>
                      <Text>Candidatas: 12</Text>
                      <Text>Valor total: R$6.6444.66</Text>
                    </RoundItem>
                  );
                }
              })}
              <PurpleButton
                type="primary"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  marginTop: '20px',
                  padding: '0 50px',
                }}
                onClick={() => navigate('/investment-round/investor')}
              >
                Ver tudo
              </PurpleButton>
            </RoundList>
          </Spin>
        </BoxTotal>
      </BoxTop>
      <OnEmptyOrList list={startupList}>
        <BoxWhite>
          <Spin spinning={loading}>
            <CardList
              entity={startupList}
              goBackPath={() => {
                alert('teste');
              }}
            />
          </Spin>
        </BoxWhite>
      </OnEmptyOrList>
    </BoxContainer>
  );
}

export default DashboardInvestor;
