import { useState, useEffect, useMemo } from 'react';
import { Form, Input, Spin } from 'antd';
import Divider from '../../../components/Divider';
import notificationMessage from '../../../components/notifications/notificationMessage';
import ReactQuill from '../../../components/JoditQuill/JoditQuill';
import useLoading from '../../../core/hooks/useLoading';
import { DefaultFormProps } from '../../../core/interfaces/course/ICourseForm';
import ILesson from '../../../core/interfaces/course/ILesson';
import { PurpleButton } from '../../../GlobalStyles';
import LessonService from '../../../services/lesson/LessonService';
import { notification } from '../../../helpers/notification.helper';

function LessonForm(props: DefaultFormProps<ILesson>) {
  const [form] = Form.useForm();
  const { entity: lesson } = props;
  const { loading, showLoading, hideLoading } = useLoading();
  const lessonService = useMemo(() => LessonService.init(), []);
  const [textValue, setTextValue] = useState<string>('');

  const getDataForm = () => {
    const videoUrl = form.getFieldValue('videoUrl');
    return {
      name: form.getFieldValue('lessonName'),
      description: form.getFieldValue('description'),
      ...(videoUrl && { videoUrl }),
      topic: {
        entityId: props.topic.entityId,
      },
    };
  };

  const initialValues = useMemo(() => {
    return {
      lessonName: lesson?.name ?? '',
      videoUrl: lesson?.videoUrl ?? '',
      description: lesson?.description ?? '',
    };
  }, [lesson]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setTextValue(initialValues.description);
  }, [form, initialValues]);

  const handleSubmit = () => {
    showLoading();
    lessonService
      .updateOrCreate(getDataForm(), lesson?.entityId)
      .then(() => {
        props.reload();
        props.close();
        notificationMessage('default');
      })
      .catch(() => {
        notification.error(
          'Não foi possível salvar a aula, verifique os campos e tente novamente.',
        );
      })
      .finally(hideLoading);
  };

  return (
    <Spin spinning={loading}>
      <Form
        name={'lessonForm'}
        onFinish={handleSubmit}
        form={form}
        layout={'vertical'}
        autoComplete={'off'}
      >
        <Form.Item
          name={'lessonName'}
          label="Nome da aula"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              max: 150,
              message: 'O campo deve possuir no máximo 150 caracteres',
            },
            {
              min: 3,
              message: 'O campo deve possuir no mínimo 3 caracteres',
            },
          ]}
        >
          <Input placeholder="Digite o nome da aula" maxLength={150} />
        </Form.Item>
        <Form.Item
          name={'videoUrl'}
          label="Link da aula"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input placeholder="Cole o link da aula" maxLength={255} />
        </Form.Item>
        <Form.Item
          name={'description'}
          label="Descrição"
          rules={[
            { required: true, message: 'Descrição é um campo obrigatório!' },
            {
              min: 5,
              message: 'O campo deve possuir no mínimo 5 caracteres',
            },
            {
              max: 1000,
              message: 'O campo deve possuir no máximo 1000 caracteres',
            },
          ]}
        >
          <ReactQuill
            heigth={'300px'}
            content={textValue}
            onChange={(text) => setTextValue(text)}
          />
        </Form.Item>
        <Divider color="#a209a2" />
        <PurpleButton
          type="primary"
          htmlType="submit"
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '10px',
          }}
        >
          Salvar
        </PurpleButton>
      </Form>
    </Spin>
  );
}

export default LessonForm;
