import { Layout } from 'antd';
import Notifications from '../../header-options/notifications';
import ProfileOptions from '../../header-options/profile';
import { ContainerHeader, Content, InformationUser } from './styles';
import { TbMessageCircle2 } from 'react-icons/tb';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../core/hooks/auth';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Text from '../../Text';

function NavBar() {
  const { logged, getDescriptionProfile } = useAuth();
  const navigate = useNavigate();
  const { isInvestor, isStartup } = useAuth();
  const enabledNotification = isInvestor() || isStartup();
  const location = useLocation();

  return (
    <Layout.Header style={{ display: 'flex', padding: 0, background: '#fff' }}>
      <Content>
        <BreadCrumb location={location} />
      </Content>
      <ContainerHeader>
        {enabledNotification && (
          <TbMessageCircle2
            onClick={() => navigate('/chat')}
            cursor={'pointer'}
            size={22}
          />
        )}
        <Notifications />
        <InformationUser>
          <Text lineHeight={1.3} color="var(--support-color)">
            {getDescriptionProfile()}
          </Text>
          <Text lineClamp={1} lineHeight={1.3} weight={600}>
            {logged?.name}
          </Text>
        </InformationUser>
        <ProfileOptions />
      </ContainerHeader>
    </Layout.Header>
  );
}

export default NavBar;
