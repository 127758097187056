import React from 'react';
import PopoverPosition from '../../../../../core/interfaces/types/IPopover';

enum EnabledActions {
  BLOCK,
  ACTIVATE,
  REJECT,
  REMOVE,
}

type IAction = {
  label: React.ReactNode;
  event?: () => void;
  icon: React.ReactNode;
};

interface ParamsActions {
  enabledActions?: EnabledActions[];
  style?: React.CSSProperties;
  boxPosition?: PopoverPosition;
  block?: () => void;
  activate?: () => void;
  reject?: () => void;
  remove?: () => void;
  sizeIcon?: number;
  actions?: IAction[];
}

export { EnabledActions };
export type { IAction, ParamsActions };
