import CrudService from './CrudService';

class MetricCommentService extends CrudService {
  public static init() {
    return new MetricCommentService();
  }

  constructor() {
    super({
      requestMapping: '/metrics-comment',
    });
  }
}

export default MetricCommentService;
