import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Input, notification, Select, Spin } from 'antd';
import { ModalBody, PurpleButton } from '../../../../GlobalStyles';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import useLoading from '../../../../core/hooks/useLoading';
import ErrorNotification from '../../../../components/notifications/errorNotification';
import { IProfile } from '../../../../core/interfaces';
import ProfileService from '../../../../services/ProfileService';
import { ClientServices } from '../../../../services/ClientServices';
import IMemberAccelerator from '../../../../core/interfaces/accelerator/IMemberAccelerator';

interface IProps extends IPropsModal {
  title: string;
  id?: number;
  updateList?: () => void;
}

function InviteNewUser(props: IProps) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { hideLoading, loading, showLoading } = useLoading();
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const profilesService = ProfileService.init();
  const clientService = ClientServices.init();

  const getProfiles = (id: number) => {
    showLoading();
    profilesService
      .getAllProfilesByAccelarator(id)
      .then((response) => {
        setProfiles(response);
      })
      .catch(() => {
        ErrorNotification({ defaultMessage: 'Erro ao carregar perfis' });
      })
      .finally(hideLoading);
  };

  const getProfilesAdmin = () => {
    showLoading();
    profilesService
      .getAllProfiles()
      .then((response) => {
        setProfiles(response);
      })
      .catch(() => {
        ErrorNotification({ defaultMessage: 'Erro ao carregar perfis' });
      })
      .finally(hideLoading);
  };

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));
    const result: IMemberAccelerator = {
      name: valueMap.get('name') as string,
      email: valueMap.get('email') as string,
      profileId: valueMap.get('profileLevel') as number,
    };
    return result;
  };

  const onFinish = (value: FormInstance) => {
    showLoading();
    clientService
      .createMember(props.id as number, arrageValues(value))
      .then(() => {
        notification.success({
          message: 'Tudo certo!!!',
          description: 'Convite enviado com sucesso!',
        });
        props.onCancel();
        props.updateList && props.updateList();
      })
      .catch((err) => {
        notification.error({
          message: 'Erro!!!',
          description: err.response.data.message || 'Erro ao enviar convite',
        });
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    if (props.id) {
      getProfiles(props.id);
    } else {
      getProfilesAdmin();
    }
  }, [props.id]);

  return (
    <ModalBody
      title={props.title}
      width={400}
      footer={null}
      closable={true}
      open={props.visible}
      onCancel={props.onCancel}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form
          name={'inviteNewUserForm'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          <Form.Item
            name={'name'}
            label="Nome do usuário"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="digite o nome do usuário" maxLength={255} />
          </Form.Item>
          <Form.Item
            label="E-mail do usuário"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Por favor insira um email válido!',
              },
            ]}
          >
            <Input placeholder="Digite seu e-mail" maxLength={100} />
          </Form.Item>
          <Form.Item
            label="Selecione o nível de perfil"
            name={'profileLevel'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select allowClear placeholder="Selecione uma opção">
              {profiles.map((item, key) => {
                return (
                  <Option key={key} value={item.id}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{
              width: '200px',
              marginLeft: 'calc(50% - 100px)',
              marginTop: '10px',
            }}
          >
            Enviar convite
          </PurpleButton>
        </Form>
      </Spin>
    </ModalBody>
  );
}

export default InviteNewUser;
