const routeMapNames = new Map<string, string>([
  ['courses', 'cursos'],
  ['diagnosis', 'diagnóstico'],
  ['metrics', 'métricas'],
  ['valuation', 'valuation'],
  ['startup', 'detalhes da startup'],
  ['diagnosisStartup', 'diagnóstico da startup'],
  ['users', 'usuários'],
  ['profiles', 'perfil'],
  ['dashboard', 'home'],
  ['diagnostics', 'diagnósticos'],
  ['view', 'visualizar'],
  ['metric', 'métrica'],
  ['accelerator', 'aceleradora'],
  ['courses-startup', 'Cursos'],
  // ['startups', 'Startups da aceleradora'],
  ['courses', 'Cursos aceleradora'],
]);

export const RoutesName = (routeName: string): string => {
  if (routeMapNames.has(routeName)) {
    return routeMapNames.get(routeName) || '';
  } else return routeName;
};

export default RoutesName;
