import React from 'react';
import SpeederMemberInfo from '../../Infos/SpeederMemberInfo';
import ContentBase from '../../templates/ContentBase';
import MemberScreen from './MemberScreen';

function MemberAccelerator() {
  return (
    <>
      <ContentBase
        title="Membros"
        modalContent={<SpeederMemberInfo />}
        content={<MemberScreen />}
      />
    </>
  );
}

export default MemberAccelerator;
