/* eslint-disable prefer-spread */
import React, { useState, useEffect } from 'react';
import { Col, Collapse, Divider, notification, Row, Slider } from 'antd';
import { useLocation } from 'react-router-dom';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { Card } from '../../../../GlobalStyles';
import { CaretRightOutlined } from '@ant-design/icons';
import ContentBase from '../../../templates/ContentBase';
import { Panel, StepItem } from './styles';
import IPillars from '../../../../core/interfaces/models/IPillars';
import DiagnosticService from '../../../../services/DiagnosticServices';
import Meta from 'antd/es/card/Meta';
import { PhaseSliders } from '../../../../components/form/diagnosis/style';
import Loading from '../../../../components/Loading/Loading';
import useLoading from '../../../../core/hooks/useLoading';
import SliderComponent from '../../../../components/Slider/Slider';

function DiagnosisFormView() {
  const location = useLocation();
  const { state } = location;
  const diagnosticService = DiagnosticService.init();
  const [nameDiagnostic, setNameDiagnostic] = useState<string>('');
  const [data, setData] = useState<any>();
  const [pillars, setPillars] = useState<IPillars[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();

  const getDiagnostic = () => {
    showLoading();
    diagnosticService
      .getDiagnosticById(Number(state.id))
      .then((data) => {
        setNameDiagnostic(data.data.diagnostic.name);
        setPillars(data.data.pillars);
        setData(data.data);
      })
      .catch(() => {
        notification.error({
          message: 'Diagnostico não encontrado',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const executeMarks: any = (min: number, max: number) => {
    let result: any;

    for (let i = min; i <= max; i++) {
      result = {
        ...result,
        [i]: i.toString(),
      };
    }
    return result;
  };

  const getValues = (scale: any[]) => {
    const max = Math.max.apply(
      Math,
      scale.map((o) => o.orderScale),
    );
    const min = Math.min.apply(
      Math,
      scale.map((o) => o.orderScale),
    );

    return {
      max: max,
      min: min,
    };
  };

  const onChangeValue = (id: any, value: any) => {
    const El = document.getElementById('description-' + id) as HTMLInputElement;

    El.innerHTML = value;
  };

  useEffect(() => {
    getDiagnostic();
  }, []);

  return (
    <ContentBase
      goBack={true}
      questionIsVisible={false}
      title="Diagnóstico"
      content={
        <Loading
          spinning={loading}
          tip="Carregando..."
          width={300}
          height={300}
        >
          <Card>
            <Text margin={'0 0 4px 0'} size={13} color="#960096" weight={700}>
              NOME DA METODOLOGIA
            </Text>
            <Title lineHeight={1} size={24} margin="0 0 0 0">
              {data?.methodology.name}
            </Title>
            <Divider />

            <Text margin={'0 0 4px 0'} size={13} color="#960096" weight={700}>
              NOME DO DIAGNÓSTICO
            </Text>
            <Title lineHeight={1} size={24} margin="0 0 0 0">
              {nameDiagnostic}
            </Title>
            <Divider />

            <Text margin={'0 0 4px 0'} size={13} color="#960096" weight={700}>
              FASE DO DIAGNÓSTICO
            </Text>
            <Title lineHeight={1} size={24} margin="0 0 8px 0">
              {data?.phase?.name}
            </Title>

            <Card>
              <Meta
                title={'Descrição'}
                description={data?.phase?.description}
              />
            </Card>

            <Divider />
            <Text margin={'4px 0 4px 0'} size={13} color="#960096" weight={700}>
              ESCALAS
            </Text>

            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 180 : 90} />
              )}
            >
              {data?.scalesPhases?.map((item: any, index: number) => {
                const { max, min } = getValues(item.scaleLikert);
                return (
                  <PhaseSliders key={`key_panel_${index}`}>
                    <div className="label">{`Fase: ${item.phase.name}`}</div>
                    <div className="slider">
                      <Slider
                        min={0}
                        max={10}
                        marks={executeMarks(0, 10)}
                        key={index}
                        range={true}
                        value={[max, min]}
                      />
                    </div>
                  </PhaseSliders>
                );
              })}
            </Collapse>

            <Text margin="10px 0 18px 0" size={13} color="#960096" weight={700}>
              QUESTÕES POR PILARES
            </Text>

            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 180 : 90} />
              )}
            >
              {pillars.map((item, index) => (
                <Panel
                  className="parent-item"
                  header={item.name}
                  key={`key_panel_${index}`}
                >
                  <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 180 : 90} />
                    )}
                  >
                    {item?.questions?.map((question, index) => (
                      <StepItem key={`key_panel_${index}`}>
                        <Text
                          weight={700}
                          style={{
                            display: 'flex',
                          }}
                        >
                          {index + 1}{' '}
                          <p
                            style={{
                              color: 'orange',
                              marginLeft: '5px',
                              marginRight: '5px',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}
                          >
                            {' '}
                            -{' '}
                          </p>{' '}
                          {question.question}
                        </Text>
                        <Row
                          style={{
                            marginTop: '10px',
                            marginBottom: '12px',
                          }}
                        >
                          <Col span={16}>
                            <SliderComponent
                              min={0}
                              max={10}
                              marks={executeMarks(0, 10)}
                              key={index}
                              onChange={(value: number) =>
                                onChangeValue(
                                  question.entityId,
                                  question.scales &&
                                    question?.scales[value].orderScale +
                                      ' - ' +
                                      question?.scales[value].description,
                                )
                              }
                            />
                          </Col>
                          <Col span={8}>
                            <Text>
                              <div
                                className={'description'}
                                id={'description-' + question?.entityId}
                              ></div>
                            </Text>
                          </Col>
                        </Row>
                      </StepItem>
                    ))}
                  </Collapse>
                </Panel>
              ))}
            </Collapse>
          </Card>
        </Loading>
      }
    />
  );
}

export default DiagnosisFormView;
