enum IWeekDay {
  DOMINGO = 'Domingo',
  SEGUNDA = 'Segunda-feira',
  TERCA = 'Terça-feira',
  QUARTA = 'Quarta-feira',
  QUINTA = 'Quinta-feira',
  SEXTA = 'Sexta-feira',
  SABADO = 'Sábado',
}

const getKeys = () => {
  return Object.keys(IWeekDay);
};

const getFullName = (value: number): string => {
  const items = Object.values(IWeekDay);

  return items[value];
};

const getAbbreviation = (value: number): string => {
  const name = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
  return name[value];
};

export default IWeekDay;
export { getKeys, getFullName, getAbbreviation };
