import React, { useEffect } from 'react';
import { Col, Form, FormInstance, Input, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';

interface IProps {
  subStep: number;
  setSubStep: (subStep: number) => void;
  setButtonState: (buttonState: boolean) => void;
  setFormName: (formName: string) => void;
  initialValues?: any;
  setFormData: (data: any) => void;
}

function AcceleratorPersonalForm(props: IProps) {
  const [form] = Form.useForm();

  const arrageForm = (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));

    const entityId = props.initialValues?.entityId;
    const name: string = valuelMap.get('name');
    const email = props.initialValues?.email;
    const cellPhone: string = valuelMap.get('cellPhone');
    const cpf: string = valuelMap.get('cpf');
    const office: string = valuelMap.get('office');
    const area: string = valuelMap.get('area');

    return {
      entityId,
      name,
      email,
      cellPhone,
      cpf,
      office,
      area,
    };
  };

  const onFinish = (values: FormInstance) => {
    props.setSubStep(props.subStep + 1);
    props.setFormData(arrageForm(values));
  };

  const setInitialValues = () => {
    form.setFieldsValue({
      name: props.initialValues?.name,
    });
  };

  useEffect(() => {
    props.setFormName('formAcceleratorPersonal');
    setInitialValues();
  }, [props.initialValues]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name={'formAcceleratorPersonal'}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Qual seu nome?"
            name="name"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite seu nome completo" maxLength={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="CPF"
            name={'cpf'}
            rules={[
              {
                required: true,
                message: 'CPF é um campo obrigatório',
              },
              ({ getFieldValue }) => ({
                validator(_) {
                  if (getFieldValue('cpf').includes('_')) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'CPF inválido',
              }),
            ]}
          >
            <MaskedInput mask="000.000.000-00" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Número de telefone"
            name="cellPhone"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              {
                pattern: /^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/,
                message: 'Número de telefone inválido!',
              },
            ]}
          >
            <MaskedInput mask="(00) 00000-0000" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Qual a sua área dentro da organização"
            name="area"
            rules={[
              {
                required: true,
                message: 'Área é um campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite sua área" maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Qual seu cargo"
            name="office"
            rules={[
              {
                required: true,
                message: 'Cargo é um campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite o seu cargo" maxLength={255} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AcceleratorPersonalForm;
