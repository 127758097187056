import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  .ant-input-disabled {
    color: #000000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000000;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;

  & img {
    width: 100px;
  }

  & div {
    text-align: justify;
  }
`;

export { BoxContent, Header };
