import React from 'react';
import { Empty } from 'antd';
import { isEmpty } from '../../helpers/array.helper';

interface IProps {
  list: any[];
  children: any;
  description?: string;
}

/**
 * @description
 * This component is used to show a message when the list is empty
 * Can be used in any component that has a list
 * @param list
 * @param children
 * @param description
 * */
function OnEmptyOrList({
  children,
  list,
  description = 'Não há dados para exibir',
  ...props
}: IProps) {
  return (
    <div
      {...props}
      style={{
        width: '100%',
      }}
    >
      {isEmpty(list) ? <Empty description={description} /> : children}
    </div>
  );
}

export default OnEmptyOrList;
