import { Row, Col, Typography } from 'antd';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { convertDateToString } from '../../../../helpers/dates.helper';

import backgroundProfile from '../../../../assets/images/background-profile.png';

import {
  Cover,
  ProfileContainer,
  ProfileContent,
  ProfileImage,
  ProfileWrapper,
} from './styles';

export interface Accelerator {
  entityId: number;
  creation: string;
  lastUpdated: string;
  active: boolean;
  name: string;
  cnpj: string;
  numberEmployee: number;
  email: string;
  user: {
    description: string | null;
    profileImageUrl: string | null;
  };
}

interface AboutProps {
  accelerator: Accelerator;
}

export function About({ accelerator }: AboutProps) {
  let imgUrl = `https://www.gravatar.com/avatar/${accelerator.entityId}?d=identicon`;

  if (accelerator.user.profileImageUrl) {
    imgUrl = accelerator.user.profileImageUrl;
  }

  return (
    <ProfileContainer>
      <Cover src={backgroundProfile} alt="" preview={false} />

      <ProfileWrapper>
        <ProfileImage src={imgUrl} alt="" />
      </ProfileWrapper>

      <ProfileContent direction="vertical">
        <Title tag="h2">{accelerator.name}</Title>
        <Text>
          {accelerator.user.description
            ? accelerator.user.description
            : 'Ainda não tem uma descrição para esta aceleradora.'}
        </Text>

        <Row
          wrap={true}
          justify="space-between"
          align="middle"
          style={{ marginTop: '1rem' }}
        >
          <Col>
            <Text weight={600} size={18}>
              Entrada
            </Text>
            <Text color="#9AA4A8">
              {convertDateToString(accelerator.creation)}
            </Text>
          </Col>

          <Col>
            <Text weight={600} size={18}>
              Última atualização
            </Text>
            <Text color="#9AA4A8">
              {convertDateToString(accelerator.lastUpdated)}
            </Text>
          </Col>

          <Col>
            <Text weight={600} size={18}>
              Status atual
            </Text>
            <Text color="#9AA4A8">
              {accelerator.active ? 'Ativa' : 'Inativa'}
            </Text>
          </Col>

          <Col>
            <Text weight={600} size={18}>
              E-mail
            </Text>

            <Typography.Link
              underline
              href={`mailto:${accelerator.email}`}
              target="_blank"
              style={{ color: '#00b6ff' }}
            >
              {accelerator.email}
            </Typography.Link>
          </Col>
        </Row>
      </ProfileContent>
    </ProfileContainer>
  );
}
