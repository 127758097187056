import React from 'react';
import { Menu, Popover } from 'antd';
import {
  ParamsActionsCrud,
  EnabledActionsCrudEnum,
} from '../../core/interfaces/IActionsCrud';
import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import ConfirmAction from '../ConfirmAction';
import type { MenuProps } from 'antd/es/menu';

type MenuItem = Required<MenuProps>['items'][number];

function ActionsCrud({
  enabledActions,
  style,
  boxPosition = 'top',
  edit,
  status,
  view,
  remove,
}: ParamsActionsCrud) {
  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    onClick?: () => void,
  ): MenuItem {
    return {
      key,
      icon,
      onClick,
      label,
    } as MenuItem;
  }

  const actions = [
    {
      label: <p>Editar</p>,
      event: edit,
      icon: <EditOutlined />,
      value: EnabledActionsCrudEnum.EDIT,
    },
    {
      label: <p>Visualizar</p>,
      event: view,
      icon: <EyeOutlined />,
      value: EnabledActionsCrudEnum.VIEW,
    },
    {
      label: (
        <ConfirmAction
          title="Deseja alterar o status desse item?"
          boxPosition={boxPosition}
          onConfirm={() => status && status()}
        >
          <p>Alterar status</p>
        </ConfirmAction>
      ),
      icon: <ControlOutlined />,
      value: EnabledActionsCrudEnum.STATUS,
    },
    {
      label: (
        <ConfirmAction
          title="Deseja remover este item?"
          boxPosition={boxPosition}
          onConfirm={() => remove && remove()}
        >
          <p>Deletar</p>
        </ConfirmAction>
      ),
      icon: <DeleteOutlined />,
      value: EnabledActionsCrudEnum.REMOVE,
    },
  ];

  const items = actions
    .filter((action) =>
      enabledActions ? enabledActions.includes(action.value) : true,
    )
    .map((action, index) =>
      getItem(
        action.label,
        `action_crud_item_${index}`,
        action.icon,
        action.event,
      ),
    );

  return (
    <Popover
      placement={boxPosition}
      content={<Menu mode="vertical" items={items} />}
    >
      <MoreOutlined style={{ ...style, cursor: 'pointer' }} />
    </Popover>
  );
}

export default ActionsCrud;
