import { IError } from '../../../core/interfaces';
import ErrorNotification from '../errorNotification';

const isArray = (error: any): error is Array<IError> => {
  return error?.errors !== undefined;
};

const ErrorFormNotification = (
  form: any,
  errors: IError,
  messageDefault?: string,
) => {
  if (isArray(errors)) {
    errors.errors?.forEach((item: IError, index: number) => {
      return form.setFields([
        { name: item.field, errors: [item.error ?? item.defaultMessage] },
      ]);
    });
  } else {
    ErrorNotification(errors);
  }
};
export default ErrorFormNotification;
