import React, { useEffect, useState } from 'react';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import Title from '../../../components/Title';
import ProfilesAccessInfo from '../../Infos/ProfilesAccessInfo';
import ContentBase from '../../templates/ContentBase';
import { Box, Header, BoxProfilesList, SeeAll } from './style';
import { Button, Spin } from 'antd';
import ProfileItem from './ProfileItem/ProfileItem';
import InviteNewUser from './ModalNewUser/InviteNewUser';
import ProfileLevel from './ProfileLevel/ProfileLevel';
import ProfileLevelList from './ProfileLevel/ProfileLevelList';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../core/interfaces';
import UserService from '../../../services/UserService';
import useLoading from '../../../core/hooks/useLoading';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import { convertDateToString } from '../../../helpers/dates.helper';

function Profiles() {
  const navigate = useNavigate();
  const [modalNewUserVisible, setModalNewUserVisible] = useState(false);
  const [modalProfileVisible, setModalProfileVisible] = useState(false);
  const userService = UserService.init();
  const [users, setUsers] = useState<IUser[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();

  let countItems = 0;

  const getUsers = () => {
    showLoading();
    userService
      .getAllUsers()
      .then((res) => {
        setUsers(res);
      })
      .finally(hideLoading);
  };

  const showModalInvite = () => {
    setModalNewUserVisible(!modalNewUserVisible);
  };

  const showModalProfileLevel = () => {
    setModalProfileVisible(!modalProfileVisible);
  };

  const showModal = () => {
    navigate('/profiles/list');
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <InviteNewUser
        title="Novo usuário"
        visible={modalNewUserVisible}
        onCancel={() => showModalInvite()}
      />
      <ProfileLevel
        title="Novo perfil de acesso"
        visible={modalProfileVisible}
        onCancel={() => showModalProfileLevel()}
      />
      <ContentBase
        title="Perfis de acesso"
        modalContent={<ProfilesAccessInfo />}
        content={
          <>
            <Box>
              <Spin spinning={loading}>
                <Header>
                  <Title size={16}>Perfis</Title>
                  <Button
                    size="small"
                    shape="circle"
                    type="dashed"
                    className="addItem"
                    style={{ top: 0, right: 0 }}
                    onClick={showModalInvite}
                    icon={<UserAddOutlined />}
                  />
                </Header>
                <OnEmptyOrList
                  list={users}
                  description={'Nenhum usuário cadastrado'}
                >
                  <BoxProfilesList>
                    {users.map((item) => {
                      countItems++;
                      return (
                        <>
                          {countItems <= 2 ? (
                            <ProfileItem
                              showLoading={showLoading}
                              hideLoading={hideLoading}
                              key={item.entityId?.toString() && item.entityId}
                              updateList={getUsers}
                              entityId={item.entityId as number}
                              name={
                                item.profiles
                                  ? item.profiles[0]?.description
                                  : ''
                              }
                              date={convertDateToString(
                                item.creation as string,
                              )}
                              lastUpdate={convertDateToString(
                                item?.lastUpdated as string,
                              )}
                              email={item.email}
                              status={item?.active || false}
                            />
                          ) : (
                            countItems === 3 && (
                              <SeeAll>
                                <span onClick={showModal}>Ver tudo</span>
                              </SeeAll>
                            )
                          )}
                        </>
                      );
                    })}
                  </BoxProfilesList>
                </OnEmptyOrList>
              </Spin>
            </Box>
            <Box>
              <Header>
                <Title size={16}>Níveis de perfil</Title>
                <Button
                  size="small"
                  shape="circle"
                  type="dashed"
                  className="addItem"
                  onClick={showModalProfileLevel}
                  icon={<PlusOutlined />}
                />
              </Header>
              <ProfileLevelList />
            </Box>
          </>
        }
      />
    </>
  );
}

export default Profiles;
