import React from 'react';
import { BoxActions, BoxTitle, Header, Info } from './style';
import defaultImage from '../../assets/images/image-default.png';
import bannerProfile from '../../assets/images/background-profile.png';
import { BoxContainer, BoxWhite, ModalBody } from '../../GlobalStyles';
import Title from '../Title';
import Text from '../Text';
import GoBack from '../GoBack/GoBack';
import moment from 'moment';
import { ReactComponent as ChatIco } from '../../assets/icons/actions-icons/ic-chat.svg';
import { ReactComponent as InvestmentIco } from '../../assets/icons/actions-icons/ic-investment.svg';
import InvestForm from './InvestForm/InvestForm';
import { useModal } from 'sunflower-antd';
import ModalStartChart from './ModalStartChart';
import {
  CloseOutlined,
  EditOutlined,
  RetweetOutlined,
} from '@ant-design/icons';

type IActions = 'chat' | 'disable' | 'edit' | 'investment';

interface IProps {
  title: string;
  entity: any;
  goBackPath?: string;
  senderId?: number;
  recipientName?: string;
  senderName?: string;
  recipientId?: number;
  circledBorder?: boolean;
  viewActions?: IActions[];
  disableEntity?: () => void;
  editEntity?: () => void;
  inviteEntity?: () => void;
  goBack?: () => void;
  submitForm?: (values: any) => void;
  showModal?: () => void;
  isVisible?: boolean;
}

function ProfileBase({
  title,
  entity,
  goBackPath,
  viewActions,
  circledBorder,
  disableEntity,
  editEntity,
  inviteEntity,
  goBack,
  submitForm,
  showModal,
  isVisible,
  senderId,
  recipientId,
  recipientName,
  senderName,
}: IProps) {
  const modalChat = useModal({
    defaultVisible: false,
  });

  const actions = [
    {
      chat: (
        <div title={'Chat'} className="action">
          <ChatIco />
        </div>
      ),
      disable: (
        <div
          title={'Desativar'}
          className="action"
          onClick={() => disableEntity}
        >
          <CloseOutlined />
        </div>
      ),
      edit: (
        <div title={'Editar'} className="action" onClick={() => editEntity}>
          <EditOutlined />
        </div>
      ),
      investment: (
        <div title={'Investimento'} className="action" onClick={showModal}>
          <InvestmentIco />
        </div>
      ),
      invite: (
        <div
          title={'Solicitar troca de acessoria'}
          className="action"
          onClick={() => inviteEntity}
        >
          <RetweetOutlined />
        </div>
      ),
    },
  ];

  const column = (label: string, value: string, email?: boolean | false) => {
    return (
      <div className="column">
        <Text weight={700}>{label}</Text>
        {email ? (
          <span className="email">
            <a href={`mailto:${value}`}>{value}</a>
          </span>
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  return (
    <>
      <ModalBody
        title={''}
        width={400}
        footer={null}
        closable={false}
        open={isVisible}
        onCancel={showModal}
        destroyOnClose={true}
      >
        <BoxContainer flexDirection="row" alignItems="center" noPadding={true}>
          <GoBack enableBackground={true} goBackFunction={showModal} />
          <Title size={24} margin="0 0 0 10px" align={'center'}>
            Investimento
          </Title>
        </BoxContainer>
        <Text margin={'10px 0'}>
          Sinalize seu interesse por investir nessa startup e coloque suas
          considerações de negociação.
        </Text>
        <InvestForm
          submit={(values: any) => {
            if (submitForm) {
              submitForm(values);
            }
          }}
        />
      </ModalBody>
      <BoxContainer noPadding={true}>
        <BoxTitle>
          <GoBack
            goBackFunction={goBack}
            enableBackground={true}
            path={goBackPath}
          />
          <Title size={20} margin="0 0 0 10px">
            {title}
          </Title>
        </BoxTitle>
        <BoxWhite>
          <Header url={bannerProfile} borderType={circledBorder}>
            <img alt="" src={defaultImage} />
            <BoxActions>
              {viewActions &&
                viewActions.map((item) => {
                  return actions[0][item];
                })}
            </BoxActions>
          </Header>
          <Title size={20}>{entity?.name}</Title>
          <Text size={12} align="justify" color={'#000'}>
            {entity?.problem}
          </Text>
          <Text size={12} align="justify" color={'#000'}>
            {entity?.resolution}
          </Text>
          <Info>
            {entity?.speeder && column('Aceleradora', entity?.speeder)}
            {column('Entrada', moment(entity?.entry).format('DD/MM/YYYY'))}
            {column(
              'Última atualização',
              moment(entity?.lastUpdate).format('DD/MM/YYYY'),
            )}
            {column('Status atual', entity?.status ? 'Ativo' : 'Inativo')}
            {column('E-mail', entity?.email, true)}
          </Info>
        </BoxWhite>
      </BoxContainer>
      <ModalStartChart
        recipientName={recipientName}
        recipientId={recipientId}
        senderName={senderName}
        senderId={senderId}
        {...modalChat.modalProps}
      />
    </>
  );
}

export default ProfileBase;
