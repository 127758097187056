import React from 'react';
import ContentBase from '../../templates/ContentBase';
import MemberScreen from './MemberScreen';

function MemberStartup() {
  return (
    <>
      <ContentBase title="Membros" content={<MemberScreen />} />
    </>
  );
}

export default MemberStartup;
