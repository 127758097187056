import api from '../core/services/api';
import CrudService from './CrudService';

class DiagnosticService extends CrudService {
  constructor() {
    super({
      requestMapping: '/diagnosis',
    });
  }

  public static init(): DiagnosticService {
    return new DiagnosticService();
  }

  async getDiagnosticByClient(client: number) {
    return await api.get('diagnosis/list', { params: { client: client } });
  }

  async getDiagnosticById(id: number) {
    return await api.get('diagnosis/diagnosis/' + id);
  }
}

export default DiagnosticService;
