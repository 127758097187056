import { notification } from 'antd';
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Divider from '../../../components/Divider';
import { IKpi } from '../../../core/interfaces/IMetric';
import { Card } from '../../../GlobalStyles';
import KpiService from '../../../services/KpiService';
import ContentBase from '../../templates/ContentBase';
import CommentsKpiDetails from './CommentsKpiDetails';
import EvidencesKpiDetails from './EvidencesKpiDetails';
import HeaderKpiDetails from './HeaderKpiDetails';
import Loading from '../../../components/Loading/Loading';
import useLoading from '../../../core/hooks/useLoading';

function KpiDetails() {
  const { id } = useParams();
  const kpiService = useMemo(() => KpiService.init(), []);
  const [kpi, setKpi] = useState<IKpi | null>(null);
  const [isNewComment, setIsNewComment] = useState<boolean>(false);
  const { loading, showLoading, hideLoading } = useLoading();

  const getKpi = useCallback(() => {
    showLoading();
    kpiService
      .findById(Number(id))
      .then((data) => setKpi(data))
      .catch(() => {
        notification.info({
          message: 'Kpi não encontrado',
          duration: 3.5,
        });
      })
      .finally(hideLoading);
  }, [kpiService, id]);

  useEffect(() => {
    getKpi();
  }, [getKpi]);

  return (
    <ContentBase
      content={
        <Loading spinning={loading} width={200} height={200}>
          <Card>
            <HeaderKpiDetails
              reload={getKpi}
              status={kpi?.status}
              kpiId={kpi?.entityId}
              title={kpi?.text ?? ''}
              addNewComment={() => setIsNewComment(true)}
            />

            <CommentsKpiDetails
              reload={getKpi}
              kpiId={kpi?.entityId}
              comments={kpi?.comments ?? []}
              isNewComment={isNewComment}
              cancelIsNewComment={() => setIsNewComment(false)}
            />
            <Divider margin="18px 0" color={'#a209a2'} />

            <EvidencesKpiDetails
              reload={getKpi}
              status={kpi?.status}
              kpiId={kpi?.entityId}
              files={kpi?.files ?? []}
            />
          </Card>
        </Loading>
      }
    />
  );
}

export default KpiDetails;
