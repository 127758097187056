import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  .circled-button {
    border: none;
    border-radius: 50%;
    background-color: var(--thirdy-color);
    margin-bottom: 10px;

    :hover {
      background-color: var(--primary-color);
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoxList = styled.div<{
  flex?: string;
}>`
  display: flex;
  flex-direction: ${({ flex }) => (flex ? flex : 'column')};
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px 0;
`;

const InvestmentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;

  .first-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: help;
    }
  }

  :hover {
    background-color: #f3f0f0;
  }
`;

const InvestmentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  padding: 10px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  cursor: pointer;

  img {
    width: 80px;
    height: 80px;
  }

  .name {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
  }
`;

const Item = styled.div<{
  align?: string;
  margin?: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 145px;
  align-items: ${({ align }) => (align ? align : 'center')};
  margin-bottom: ${({ margin }) => (margin ? margin : 0)};

  .label {
    font-weight: bold;
  }
`;

const BoxDates = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .box-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    cursor: pointer;

    :hover {
      background-color: #dddee0;
      border: 1px solid var(--support-color);
    }

    img {
      width: 32px;
      margin-right: 10px;
    }
  }
`;

export {
  Content,
  Header,
  BoxList,
  InvestmentItem,
  InvestmentCard,
  Item,
  BoxDates,
  FileList,
};
