import React from 'react';
import { CardSecurityDefault, Content, FlexPage, SubTitle } from './style';
import Title from '../../components/Title';
import { Container } from '../../GlobalStyles';
import { useNavigate } from 'react-router-dom';

interface IParams {
  title: string;
  alignTitle?: string;
  content: any;
  subTitle?: string;
}

function BaseScreen({ title, content, alignTitle, subTitle }: IParams) {
  const navigate = useNavigate();
  return (
    <Content>
      <Container width="100%">
        <FlexPage>
          <div className="logo" />
          <CardSecurityDefault>
            <Title
              align={alignTitle}
              size={24}
              lineHeight={1.3}
              margin="0 0 1rem 0"
            >
              {title}
            </Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            {content}
          </CardSecurityDefault>
        </FlexPage>
      </Container>
      <div className="footer">
        <div>
          <span className="action" onClick={() => navigate('/terms-of-use')}>
            Termos de uso
          </span>
          <span> | </span>
          <span className="action" onClick={() => navigate('/privacy-policy')}>
            Política de privacidade
          </span>
        </div>
        <p>© {new Date().getFullYear()} Innovation Studio, Creathus</p>
      </div>
    </Content>
  );
}

export default BaseScreen;
