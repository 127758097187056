import React, { useEffect, useRef, useState } from 'react';
import {
  CoffeeOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../core/hooks/auth';
import {
  BoxContent,
  BoxMenu,
  BoxProfile,
  MenuContainer,
  MenuItem,
} from './style';
import { Avatar, Spin } from 'antd';
import pictureDefault from '../../assets/images/image-default.png';

function ProfileOptions() {
  const navigate = useNavigate();
  const {
    signOut,
    logged,
    loadingProfilePicture,
    profilePicture,
    getProfilePicture,
  } = useAuth();
  const { email } = logged ?? {};

  const wrapperRef = useRef(null);

  const [boxIsVisible, setBoxIsVisible] = useState<string>('none');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const md5 = require('md5');
  const emailHash = useState(md5(email));

  useOutsideAlerter(wrapperRef);

  const handleClick = () => {
    boxIsVisible === 'none'
      ? setBoxIsVisible('table')
      : setBoxIsVisible('none');
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setBoxIsVisible('none');
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const logout = (): void => {
    signOut();
    navigate('/');
  };

  const menu = (
    <MenuContainer mode="inline" selectable={false}>
      <MenuItem
        key="/my-profile"
        icon={<CoffeeOutlined />}
        onClick={() => navigate('/my-profile')}
      >
        Meu perfil
      </MenuItem>
      <MenuItem
        key="/logout"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout();
        }}
      >
        Logout
      </MenuItem>
    </MenuContainer>
  );

  useEffect(() => {
    getProfilePicture();
  }, [getProfilePicture]);

  return (
    <BoxContent onClick={handleClick}>
      <Spin spinning={loadingProfilePicture}>
        <BoxProfile>
          {emailHash || profilePicture ? (
            <Avatar
              alt=""
              src={
                profilePicture === pictureDefault
                  ? `https://www.gravatar.com/avatar/${emailHash}?s=200&r=pg&d=identicon`
                  : profilePicture
              }
            />
          ) : (
            <UserOutlined />
          )}
        </BoxProfile>
      </Spin>
      <BoxMenu style={{ display: `${boxIsVisible}` }}>
        <div ref={wrapperRef} className="boxMenu">
          {menu}
        </div>
      </BoxMenu>
    </BoxContent>
  );
}

export default ProfileOptions;
