import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IAmountInvestment } from '../core/interfaces/IAmountInvestment';

class AmountInvestmentService extends CrudService {
  static init(): AmountInvestmentService {
    return new AmountInvestmentService();
  }

  constructor() {
    super({
      requestMapping: '/amountInvestiments',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IAmountInvestment[]> {
    return api
      .get<IAmountInvestment[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(amountInvestment: IAmountInvestment): Promise<IAmountInvestment> {
    return api
      .post<IAmountInvestment>(`${this.requestMapping}`, amountInvestment)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default AmountInvestmentService;
