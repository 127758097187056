import { notification } from 'antd';

const notificationMessage = (type = 'default', message?: string) => {
  const map = new Map(
    Object.entries({
      create: {
        message: 'Tudo certo!',
        description: 'Dados criados com sucesso!',
      },
      update: {
        message: 'Tudo certo!',
        description: 'Dados atualizados com sucesso!',
      },
      delete: {
        message: 'Tudo certo!',
        description: 'Dados excluídos com sucesso!',
      },
      status: {
        message: 'Tudo certo!',
        description: 'Status alterado com sucesso!',
      },
      login: {
        message: 'Perfeito!',
        description: 'Login efetuado com sucesso!',
      },
      forgot: {
        message: 'Tudo pronto!',
        description: 'Senha alterada com sucesso!',
      },
      custom: {
        message: 'Tudo certo!',
        description: message ? message : 'Dados salvos com sucesso!',
      },
      default: {
        message: 'Tudo ok!',
        description: 'Dados salvos com sucesso!',
      },
    }),
  );

  notification.success({
    message: map.get(type)?.description,
    description: map.get(type)?.message,
  });
};

export default notificationMessage;
