import React from 'react';
import { BoxContainer, BoxWhite } from '../../../GlobalStyles';
import Title from '../../Title';
import { BoxInfo, BoxInfoContent } from '../style';
import defaultImage from '../../../assets/images/image-default.png'; /* REMOVER EXEMPLO */

function PersonalInfo() {
  return (
    <BoxWhite>
      <Title size={14} color="var(--thirdy-color)">
        Informações pessoais
      </Title>
      <BoxInfoContent>
        <BoxInfo>
          <Title size={14} margin="0 0 5px 0">
            Desenvolvedor
          </Title>
        </BoxInfo>
        <BoxInfo>
          <Title size={14} margin="0 0 5px 0">
            Áreas de atuação
          </Title>
          <div className="specialization">
            <span>PHP</span>
            <span>IOS</span>
            <span>Android</span>
            <span>Front end</span>
            <span>Back end</span>
            <span>Data base</span>
            <span>C#</span>
            <span>Jquery</span>
            <span>Python</span>
            <span>Mongo DB</span>
          </div>
        </BoxInfo>
        <BoxInfo>
          <Title size={14} margin="0 0 5px 0">
            Escolarização
          </Title>
          <div className="school">
            <img alt="" src={defaultImage} />
            <BoxContainer noPadding={true}>
              <span>Faculdade de alguma coisa</span>
              <span>Bacharel em alguma coisa</span>
            </BoxContainer>
          </div>
          <div className="school">
            <img alt="" src={defaultImage} />
            <BoxContainer noPadding={true}>
              <span>Faculdade de alguma coisa 2</span>
              <span>Bacharel em alguma coisa 2</span>
            </BoxContainer>
          </div>
          <div className="school">
            <img alt="" src={defaultImage} />
            <BoxContainer noPadding={true}>
              <span>Faculdade de alguma coisa 3</span>
              <span>Bacharel em alguma coisa 3</span>
            </BoxContainer>
          </div>
        </BoxInfo>
        <BoxInfo>
          <Title size={14} margin="0 0 5px 0">
            Curriculo Lattes
          </Title>
          <a
            href="https://lattes.cnpq.br/carlosaugusto"
            target="_blank"
            rel="noreferrer"
          >
            https://lattes.cnpq.br/carlosaugusto9DS7A9719823798ASDADA98DA8D97A98DS7A89D7A89D7A897189319832
          </a>
        </BoxInfo>
      </BoxInfoContent>
    </BoxWhite>
  );
}

export default PersonalInfo;
