import React, { useEffect, useState } from 'react';
import { Collapse, Form, message, Radio, Space } from 'antd';
import { BoxContent, CollapseHeader } from '../form/diagnosis/style';
import { findSatus } from '../../core/enums';
import api from '../../core/services/api';
import notificationMessage from '../notifications/notificationMessage';
import ErrorNotification from '../notifications/errorNotification';
import useLoading from '../../core/hooks/useLoading';
import Loading from '../Loading/Loading';

interface IParams {
  content: any;
  position?: Iposition;
  setFormName: (formName: string) => void;
  nameDiagnostic?: string;
  clientId?: number;
  setOpenModal: (openModal: boolean) => void;
  setData?: (data: any) => void;
  initialData?: any;
}

type Iposition = 'start' | 'end';

function Collapsed(props: IParams) {
  const [form] = Form.useForm();
  const [position, setPosition] = useState<Iposition>('end');
  const [radioValue, setRadioValue] = useState<number>(0);
  const { Panel } = Collapse;
  const { loading, showLoading, hideLoading } = useLoading();

  const arrageValue = () => {
    return {
      entityId: props.initialData?.entityId,
      name: props.nameDiagnostic,
      client: {
        entityId: props.clientId,
      },
      phase: {
        entityId: radioValue,
      },
    };
  };

  const register = () => {
    if (props.nameDiagnostic === undefined) {
      message.error('Preencha o nome do diagnóstico');
      return 0;
    }
    showLoading();
    api
      .post('diagnosis/create', arrageValue())
      .then((response: any) => {
        notificationMessage('create');
        props.setOpenModal(true);
        props.setData && props.setData(response.data);
      })
      .catch((error: any) => {
        ErrorNotification(error);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    props.position && setPosition(props.position);
    props.setFormName('diagnosisPhaseForm');
  }, []);

  useEffect(() => {
    const data = sessionStorage.getItem('method-diagnostic');
    setRadioValue(Number(data));
  }, []);

  useEffect(() => {
    sessionStorage.setItem('method-diagnostic', String(radioValue));
    form.setFieldValue('phase', radioValue);
  }, [form, radioValue]);

  return (
    <BoxContent>
      <Loading
        spinning={loading}
        width={300}
        height={300}
        position={'absolute'}
      >
        <Form
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
          name={'diagnosisPhaseForm'}
          onFinish={register}
        >
          <Collapse expandIconPosition={position}>
            {props.content.map((item: any, index: number) => (
              <Panel
                key={`key_panel_${index}`}
                header={
                  <CollapseHeader>
                    <div className="title">{item?.name}</div>
                    <div>{findSatus(item?.status)}</div>
                  </CollapseHeader>
                }
              >
                <Form.Item
                  name={'phase'}
                  rules={[
                    {
                      required: true,
                      message: 'A escolha da fase é obrigatória',
                    },
                  ]}
                >
                  <Space direction="vertical">
                    {item?.phases?.map((val: any, key: number) => {
                      const isChecked = val.entityId === radioValue;
                      return (
                        <Radio
                          key={`phase_key_${key}`}
                          checked={isChecked}
                          value={val.entityId}
                          onClick={() => setRadioValue(val.entityId)}
                        >
                          {val.name}
                        </Radio>
                      );
                    })}
                  </Space>
                </Form.Item>
              </Panel>
            ))}
          </Collapse>
        </Form>
      </Loading>
    </BoxContent>
  );
}

export default Collapsed;
