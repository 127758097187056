import styled from 'styled-components';

const WrapperButton = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
`;

export { WrapperButton };
