import { useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { ISchedule } from '../../../../core/interfaces/ISchedule';
import {
  BoxCalendar,
  Legend,
} from '../../../../components/ScheduleCalendar/style';
import { BoxContainer } from '../../../../GlobalStyles';

type minDetail = 'century' | 'decade' | 'year' | 'month';
type weekDay = 'oneLetter' | 'abbreviation';
type className = 'today' | 'avaiable' | 'scheduled';

interface legend {
  className: className;
  label: string;
}

interface IProps {
  title?: string;
  /**
   * @param "use number of years you want to go foward"
   * @example 5
   * @description "assuming the year is 2022, the result is 2027"
   */
  maxDateRange?: number;
  /**
   * @param "use number of years you want to go backward"
   * @example 3
   * @description "assuming the year is 2022, the result is 2019"
   */
  minDateRange?: number;
  minDetail?: minDetail;
  weekDay?: weekDay;
  onClickDay?: () => void;
  schedule?: ISchedule[];
  legend?: legend[];
}

function StartupCalendar({
  title,
  maxDateRange = 1,
  minDateRange = -1,
  minDetail = 'decade',
  weekDay = 'oneLetter',
  onClickDay,
  schedule,
  legend,
}: IProps) {
  const today = new Date();
  const [dateValue, setDatevalue] = useState<Date>(today);
  const weekDayNames =
    weekDay === 'oneLetter'
      ? ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
      : ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const navigate = useNavigate();
  const params = useParams();
  const getDate = (yearPoint: number) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + yearPoint);

    return date;
  };
  const clickDayAction = (date: Date) => {
    navigate('week', {
      state: {
        date: date,
        mentorId: params.mentorId,
        mentoringId: params.mentoringId,
      },
    });
  };

  const disabledDate = (date: Date) => {
    let disabled = true;

    schedule?.map((item) => {
      if (
        moment(item.initialDate).format('YYYY-MM-DD') ===
          moment(date).format('YYYY-MM-DD') &&
        item.startup === null
      ) {
        disabled = false;
      }
    });

    return disabled;
  };

  const checkDay = (date: Date) => {
    const paramDate = moment(date).format('YYYY-MM-DD');
    let classes = '';

    if (moment(today).format('YYYY-MM-DD') === paramDate) {
      classes += ' today';
    }

    if (moment(today).format('YYYY-MM-DD') > paramDate) {
      classes += ' disabled';
    }

    schedule?.map((item) => {
      if (
        moment(item.initialDate).format('YYYY-MM-DD') === paramDate &&
        item.startup === null
      ) {
        classes += ' schedule';
      }
    });

    return classes;
  };

  return (
    <BoxCalendar titleContent={title}>
      <Calendar
        locale="pt-BR"
        value={dateValue}
        defaultValue={today}
        formatShortWeekday={(locale, value) => weekDayNames[value.getDay()]}
        maxDate={getDate(maxDateRange)}
        minDate={getDate(minDateRange)}
        minDetail={minDetail}
        onChange={setDatevalue}
        tileDisabled={({ date }) => disabledDate(date)}
        onClickDay={(value, e) => clickDayAction(value)}
        tileClassName={({ date, view }: CalendarTileProperties) =>
          checkDay(date)
        }
      />
      <Divider style={{ backgroundColor: 'var(--support-color)', margin: 0 }} />
      <BoxContainer flexDirection="row" noPadding={true}>
        {legend?.map((item, index) => (
          <Legend key={index}>
            <span className={item.className} />
            &nbsp;
            {item.label}
          </Legend>
        ))}
      </BoxContainer>
    </BoxCalendar>
  );
}

export default StartupCalendar;
