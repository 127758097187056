import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = {
  name: string;
};

const exampleSlice = createSlice({
  name: 'example',
  initialState: {
    name: '',
  },
  reducers: {
    _updateUser(state, action: PayloadAction<IState>) {
      state.name = action.payload.name;
    },
  },
});

export const exampleReducer = exampleSlice.reducer;
export const { _updateUser } = exampleSlice.actions;
