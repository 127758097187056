import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import IProgressStartup from '../../core/interfaces/dashboard-startup/IDashboardStartup';
import IRecommendedTrail from '../../core/interfaces/dashboard-startup/IRecommendedTrail';
import IDashboardMetricStartup from '../../core/interfaces/dashboard-startup/IDashboardMetricStartup';
import IPreMoneyDashboardStartup from '../../core/interfaces/dashboard-startup/IPreMoneyDashboardStartup';
import ICurrentTrack from '../../core/interfaces/ICurrentTrack';

class DashboardStartupService {
  readonly requestMapping = 'dashboard-startup';

  public static init() {
    return new DashboardStartupService();
  }

  private getUrl(path: string): string {
    return `${this.requestMapping}/${path}`;
  }

  public getProgress(userId: number): Promise<IProgressStartup> {
    return api
      .get<IProgressStartup>(this.getUrl(`progress/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getRecommendedTrail(userId: number): Promise<IRecommendedTrail[]> {
    return api
      .get<IRecommendedTrail[]>(this.getUrl(`recommended-trail/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getLatestMetrics(userId: number): Promise<IDashboardMetricStartup[]> {
    return api
      .get<IDashboardMetricStartup[]>(this.getUrl(`latest-metrics/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getPreMoney(userId: number) {
    return api
      .get<IPreMoneyDashboardStartup | null>(this.getUrl(`pre-money/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getStartupTrailReport(userId: number): Promise<IRecommendedTrail[]> {
    return api
      .get<IRecommendedTrail[]>(this.getUrl(`startup-trail-report/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getCurrentTrack(userId: number): Promise<ICurrentTrack> {
    return api
      .get<ICurrentTrack>(this.getUrl(`get-current-track/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default DashboardStartupService;
