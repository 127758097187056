import { Col, Row, Spin } from 'antd';
import Text from '../Text';
import { Card, IParamsCard } from '../../GlobalStyles';
import useStartupProgress from '../../core/hooks/useStartupProgress';
import StartupProgress from '../../pages/client/StartupDetails/StartupProgress/StartupProgress';
import { IStagesStartupProgress } from '../../core/interfaces/dashboard-startup/IDashboardStartup';

interface Props extends IParamsCard {
  bordered?: boolean;
  userId: number;
  showSelected?: boolean;
  showDetails?: boolean;
  children?: (activePhase?: IStagesStartupProgress) => JSX.Element;
}

function ProgressStartup({
  children,
  userId,
  showDetails = true,
  showSelected = true,
  ...props
}: Props) {
  const { progress, loading, activePhase, changeActivePhase } =
    useStartupProgress(userId);

  return (
    <Spin spinning={loading} delay={300}>
      <Card {...props}>
        <Row>
          <Col span={24}>
            <StartupProgress
              boxShadow="none"
              bordered={false}
              showSelected={showSelected}
              phases={progress.phases}
              activePhase={activePhase}
              changeActivePhase={changeActivePhase}
            />
          </Col>
          {showDetails && (
            <Col span={24}>
              <Row gutter={[12, 12]} wrap={true} justify="space-evenly">
                <Col>
                  <Text>
                    <span data-bold="true">Aceleradora responsável: </span>
                    {progress.details?.acceleratorName ?? 'não identificado'}
                  </Text>
                </Col>
                <Col>
                  <Text>
                    <span data-bold="true">Metodologia: </span>
                    {progress.details?.methodologyName ?? 'não identificado'}
                  </Text>
                </Col>
                <Col>
                  <Text>
                    <span data-bold="true">Quantidade de Fases: </span>
                    {progress.phases.length} fases cadastradas
                  </Text>
                </Col>
              </Row>
            </Col>
          )}
          {children && <Col span={24}>{children(activePhase)}</Col>}
        </Row>
      </Card>
    </Spin>
  );
}

export default ProgressStartup;
