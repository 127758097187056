import { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, notification, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { BoxButton, FormContent } from './style';
import api from '../../../core/services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ModalBody } from '../../../GlobalStyles';
import Terms from '../../terms-of-use';
import Text from '../../Text';
import { isValidPassword } from '../../../helpers/password.helper';

function Register() {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const openTerms = () => {
    setIsModalVisible(true);
  };

  const onClose = () => {
    setIsModalVisible(false);
    form.setFieldValue('agreement', true);
  };

  const register = async (values: FormInstance) => {
    const inviter = searchParams.get('inviter');
    setLoading(true);
    await api
      .post('user', values, { params: { inviter: inviter } })
      .then(() => {
        notification.success({
          message: 'Tudo certo!',
          description:
            'Usuário cadastrado com sucesso!\n' +
            'Acessar email para validação.',
        });
        navigate('/');
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao cadastrar usuário',
          description:
            error.response.data.message ||
            error.response.data.errors.email ||
            error.response.data.errors.name ||
            'Erro ao cadastrar usuário',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ModalBody
        title="Termos de uso"
        width={'800px'}
        style={{ height: '80vh !important' }}
        open={isModalVisible}
        destroyOnClose={true}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Terms />
      </ModalBody>
      <FormContent>
        <Form
          name="registerForm"
          layout="vertical"
          onFinish={register}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Nome completo"
            name="name"
            rules={[
              {
                required: true,
                message: 'Nome é um campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite seu nome completo" maxLength={120} />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Esse e-mail não é válido!',
              },
            ]}
          >
            <Input
              placeholder="Digite seu endereço de e-mail"
              maxLength={120}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={24}>
              <Text size={13} margin="0 0 12px 0" weight={500}>
                * Sua senha deve conter número, letra maiúscula, letra minúscula
                e caractere especial. Tamanho de 8 a 12 caracteres.
              </Text>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Crie uma senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  {
                    min: 8,
                    message: 'Tamanho mínimo são 8 caracteres.',
                  },
                  {
                    max: 12,
                    message: 'Tamanho máximo é 12 caracteres.',
                  },
                  {
                    validator: (_, value: string) => {
                      if (value.trim().length < 8 || value.trim().length > 12) {
                        return Promise.resolve();
                      }

                      if (!isValidPassword(value)) {
                        return Promise.reject(
                          'Sua senha deve conter número, letra maiúscula, letra minúscula e caractere especial',
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password placeholder="Digite sua senha" maxLength={12} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Confirme sua senha"
                name="passwordConfirm"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Essas senhas não coincidem');
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirme sua senha"
                  maxLength={12}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('É necessário ler e aceitar os termos'),
                      ),
              },
            ]}
          >
            <Checkbox className="terms">
              Li e concordo com os
              <span onClick={openTerms}>
                <b> termos de uso</b>
              </span>
            </Checkbox>
          </Form.Item>
          <BoxButton>
            <Button type="primary" htmlType="submit" loading={loading}>
              Cadastrar
            </Button>
          </BoxButton>
        </Form>
      </FormContent>
    </>
  );
}

export default Register;
