import React, { useEffect, useState } from 'react';
import { useModal } from 'sunflower-antd';
import ActionsCrud from '../../components/ActionsCrud/ActionsCrud';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { EnabledActionsCrudEnum } from '../../core/interfaces/IActionsCrud';
import { BoxWhite, ModalBody, PurpleButton } from '../../GlobalStyles';
import MentorshipForm from './MentorshipForm/MentorshipForm';
import { BoxEmpty, BoxList, Card } from './style';
import MentoringService from '../../services/MentoringService';
import { IMentoring } from '../../core/interfaces/IMentoring';
import { notification } from 'antd';
import { useAuth } from '../../core/hooks/auth';

function MentorshipList() {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const mentoringService = MentoringService.init();
  const [mentoringList, setMentoringList] = useState<IMentoring[]>([]);
  const [initialValues, setInitialValues] = useState<IMentoring>();
  const { logged } = useAuth();

  const getMentoringList = async () => {
    await mentoringService
      .getMentoringByUser(Number(logged?.entityId))
      .then((response) => {
        setMentoringList(response);
      });
  };

  const deleteMentoring = async (id: number) => {
    await mentoringService
      .deleteMentoringById(id)
      .then(() => {
        notification.success({
          message: 'Mentoria deletada com sucesso!',
          description: 'Sua mentoria foi deletada com sucesso',
        });
        getMentoringList();
        modalConfirmDelete.close();
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao deletar mentoria',
          description: 'Ocorreu um erro ao deletar sua mentoria',
        });
      });
  };

  const showModal = () => {
    setModalIsVisible(!modalIsVisible);
    setInitialValues(undefined);
  };

  const editMentoring = (mentoring: IMentoring) => {
    showModal();
    setInitialValues(mentoring);
  };

  const modalConfirmDelete = useModal({
    defaultVisible: false,
  });

  useEffect(() => {
    getMentoringList();
  }, []);

  return (
    <>
      <ModalBody
        title={initialValues ? 'Editar mentoria' : 'Criar mentoria'}
        width={'500px'}
        visible={modalIsVisible}
        destroyOnClose={true}
        onCancel={showModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <MentorshipForm
          initialValues={initialValues}
          showModal={showModal}
          loadMentoringList={getMentoringList}
        />
      </ModalBody>
      <BoxWhite>
        <Title size={16}>Suas mentorias</Title>
        {mentoringList ? (
          <>
            <BoxList>
              {mentoringList.map((item, index) => {
                return (
                  <Card key={index}>
                    <div className="header">
                      <Title
                        size={16}
                        color="var(--thirdy-color)"
                        margin="0 0 10px 0"
                      >
                        {item.title}
                      </Title>
                      <div className="action">
                        <ActionsCrud
                          boxPosition="left"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                          remove={() => {
                            deleteMentoring(item.entityId as number);
                          }}
                          edit={() => editMentoring(item)}
                          enabledActions={[
                            EnabledActionsCrudEnum.EDIT,
                            EnabledActionsCrudEnum.REMOVE,
                          ]}
                        />
                      </div>
                    </div>
                    <div className="description" title={item.description}>
                      {item.description}
                    </div>
                    <div className="price">{`R$${item.priceHour}`}</div>
                  </Card>
                );
              })}
            </BoxList>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <PurpleButton
                type="primary"
                onClick={showModal}
                style={{
                  width: '200px',
                  marginTop: '10px',
                }}
              >
                Adicionar mentoria
              </PurpleButton>
            </div>
          </>
        ) : (
          <BoxEmpty>
            <Title margin="0 0 10px 0">Sem mentorias cadastradas</Title>
            <Text>
              Cadastre suas mentorias conforme suas capacidades e a torne
              disponíveis para que startups possam contratá-las.
            </Text>
            <PurpleButton
              type="primary"
              onClick={showModal}
              style={{
                width: '200px',
                marginTop: '10px',
              }}
            >
              Cadastrar mentorias
            </PurpleButton>
          </BoxEmpty>
        )}
      </BoxWhite>
    </>
  );
}

export default MentorshipList;
