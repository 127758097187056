import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
  onChange?: (text: string) => void;
  content?: string;
  value?: string;
  placeHolder?: string;
  maxLength?: number;
  heigth?: string;
  width?: string;
}

function JoditQuill(props: IProps) {
  const text = `<h1 class="ql-align-center">Agronegócio</h1>`;

  return (
    <>
      <ReactQuill
        theme="snow"
        defaultValue={props.value}
        value={props.value}
        formats={[
          'header',
          'font',
          'size',
          'color',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'align',
        ]}
        onChange={props.onChange}
        modules={{
          toolbar: [
            [{ header: [] }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link'],
            ['clean'],
            [{ color: [] }, { background: [] }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
          ],
        }}
      />
    </>
  );
}

export default JoditQuill;
