import React, { useState } from 'react';
import { Steps } from 'antd';
import Profile from '..';
import ProfileOptions from '../profile-options';
import Plans from '../../plans/Plans';
import Startup from '../../../../../components/form/profiles/startup/FormScreen';
import Professional from '../../../../../components/form/profiles/professional/professionalForm';
import Speeder from '../../../../../components/form/profiles/speeder/FormScreen';
import Student from '../../../../../components//form/profiles/student';
import Mentor from '../../../../../components//form/profiles/mentor';
import Investor from '../../../../../components/form/profiles/investor/FormScreen';

function Box() {
  const { Step } = Steps;

  const [current, setCurrent] = useState<number>(0);
  const [subStep, setSubStep] = useState<number>(0);
  const [buttonState, setButtonState] = useState<boolean>(true);
  const [profileId, setProfileId] = useState<number>(0);
  const [buttonText, setButtonText] = useState<string>('Avançar');
  const [formName, setFormName] = useState<string | undefined>();
  const [finish, setFinish] = useState(() => () => {
    setCurrent(current + 1);
  });

  const ProfileScreen = [
    <Startup
      key={1}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
    <Speeder
      key={2}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
    <Professional
      key={3}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
    <Student
      key={4}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
    <Mentor
      key={5}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      setFormName={setFormName}
    />,
    <Investor
      key={6}
      profileId={profileId}
      setButtonState={setButtonState}
      current={current}
      setCurrent={setCurrent}
      subStep={subStep}
      setSubStep={setSubStep}
      setFormName={setFormName}
    />,
  ];

  const Screens = [
    {
      content: (
        <ProfileOptions
          profileId={profileId}
          setProfileId={setProfileId}
          setButtonState={setButtonState}
        />
      ),
    },
    {
      content: ProfileScreen[profileId - 1],
    },
    {
      content: (
        <Plans
          profileId={profileId}
          setButtonState={setButtonState}
          current={current}
          setCurrent={setCurrent}
          setFinish={setFinish}
        />
      ),
    },
  ];

  return (
    <Profile
      buttonState={buttonState}
      buttonText={buttonText}
      finishHandler={finish}
      formName={formName}
      content={
        <>
          {Screens[current].content}
          <Steps progressDot current={current}>
            <Step key="0" />
            <Step key="1" />
            <Step key="2" />
          </Steps>
        </>
      }
    />
  );
}

export default Box;
