import { useState, useCallback } from 'react';

const useLoading = (valueInitial = false) => {
  const [loading, setLoading] = useState<boolean>(() => valueInitial);

  const hideLoading = useCallback(() => setLoading(false), []);
  const showLoading = useCallback(() => setLoading(true), []);

  return { loading, hideLoading, showLoading };
};

export default useLoading;
