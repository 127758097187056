import Title from '../../../Title';
import { Wrapper } from './styles';
import { Checkbox } from 'antd';
import Text from '../../../Text';

interface Props {
  title: string;
  grade?: number | null;
  isComplete: boolean;
}

function CourseItem({ title, grade, isComplete }: Props) {
  return (
    <Wrapper>
      <div>
        <Title size={16}>{title}</Title>
        {grade && (
          <Text size={16} color="#960096">
            <span data-bold="true">Nota: </span>
            {grade.toFixed(1)}
          </Text>
        )}
      </div>
      <Checkbox value={isComplete} checked={isComplete} />
    </Wrapper>
  );
}

export default CourseItem;
