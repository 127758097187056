import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function ProfilesAccessInfo() {
  return (
    <BoxInfo
      title="Perfis de acesso"
      image={image}
      description={
        <>
          <p>
            Administrador, neste perfil você terá acesso ao comando dos demais
            perfis cadastrados na Plataforma Innovation Studio. Portanto, é
            necessário ter bastante atenção ao selecionar as funções de cada
            perfil, pois isso influenciará as ações desses perfis.
          </p>
          <p>
            Em <b>Perfis</b>, existem todos os perfis já cadastrados na
            plataforma Innovation Studio, contendo e-mail, status atual, nome do
            perfil e data de entrada. Podendo o Administrador editar ou excluir
            quaisquer desses perfis.
          </p>
          <p>
            Em <b>níveis de perfil</b>, estão todas as permissões dos perfis já
            cadastrados, você tem a ação de adicionar mais perfis e editar que
            já existem.
          </p>
        </>
      }
    />
  );
}

export default ProfilesAccessInfo;
