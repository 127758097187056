import CrudService from '../CrudService';

class ExtraMaterialService extends CrudService {
  constructor() {
    super({
      requestMapping: '/extra-material',
    });
  }

  public static init() {
    return new ExtraMaterialService();
  }
}

export default ExtraMaterialService;
