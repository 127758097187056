import { useState } from 'react';
import { Header } from './style';
import GoBack from '../../GoBack/GoBack';
import Text from '../../Text';
import moment, { Moment } from 'moment';
import { Col, Divider, TimePicker, Form, notification, Row } from 'antd';
import { PurpleButton } from '../../../GlobalStyles';
import { ISchedule } from '../../../core/interfaces/ISchedule';
import { useAuth } from '../../../core/hooks/auth';
import ScheduleService from '../../../services/ScheduleService';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IProps {
  sucessCreateSchedule?: () => void;
  date: Moment | null;
  time: Moment | null;
  disableRangeTime: Array<number>;
  closeModal: () => void;
}

function ScheduleWeekDayForm({
  date,
  time,
  sucessCreateSchedule,
  disableRangeTime,
  closeModal,
}: IProps) {
  const [form] = Form.useForm();
  const [startTime, setStartTime] = useState<Moment | null | undefined>(time);
  const [endTime, setEndTime] = useState<Moment | null | undefined>(null);
  const { logged } = useAuth();
  const id = logged?.entityId;
  const secheduleService = ScheduleService.init();
  const { loading, showLoading, hideLoading } = useLoading();

  const arrangePayload = () => {
    const startTime = moment(form.getFieldValue('startTime')).format('HH:mm');
    const endTime = moment(form.getFieldValue('endTime')).format('HH:mm');
    const dateTime = moment(date).format('YYYY-MM-DD');
    const payload: ISchedule = {
      initialDate: moment(`${dateTime} ${startTime}`).format(),
      finalDate: moment(`${dateTime} ${endTime}`).format(),
    };
    return payload;
  };

  const onFinish = () => {
    showLoading();
    secheduleService
      .createSchedule(Number(id), arrangePayload())
      .then(() => {
        notification.success({
          message: 'Horário agendado com sucesso!',
          description:
            'Seu horário foi agendado com sucesso, você será redirecionado para a página de agendamentos.',
        });
        closeModal();
        if (sucessCreateSchedule) sucessCreateSchedule();
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao agendar horário!',
          description:
            'Ocorreu um erro ao agendar seu horário, tente novamente.',
        });
      })
      .finally(hideLoading);
  };

  const handleStartTime = (time: Moment) => {
    setStartTime(time);
  };

  const handleEndTime = (time: Moment) => {
    setEndTime(time);
  };

  function disabledRangeTime(date: Moment) {
    console.log(date);
    return {
      disabledHours: () => disableRangeTime,
    };
  }

  return (
    <>
      <Loading spinning={loading} width={200} height={200}>
        <Header>
          <GoBack enableBackground={true} goBackFunction={closeModal} />
          <Text
            style={{
              display: 'flex',
              flexBasis: '100%',
              justifyContent: 'center',
            }}
            size={20}
            weight={700}
          >
            {moment(date).format('DD/MM/YYYY')}
          </Text>
        </Header>
        <Text style={{ margin: '10px 0' }} size={14} align="justify">
          Defina a quantidade de tempo que você disponibilizará para que
          startups e alunos possam contratar suas horas de serviço.
        </Text>
        <Form
          form={form}
          name="formScheduleTimeRange"
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            startTime: startTime,
            endTime: endTime,
          }}
        >
          <Row gutter={[4, 4]}>
            <Col span={12}>
              <Form.Item
                label="Hora inicial"
                name="startTime"
                rules={[
                  {
                    required: true,
                    message: 'Início obrigatório',
                  },
                ]}
              >
                <TimePicker
                  placeholder="Hora inicial"
                  style={{ width: '100%' }}
                  format="HH:mm"
                  minuteStep={15}
                  value={startTime}
                  disabledTime={(date) => disabledRangeTime(date)}
                  defaultValue={moment(startTime, 'HH:mm')}
                  onChange={() => handleStartTime}
                  inputReadOnly={true}
                  showNow={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Hora final"
                name="endTime"
                rules={[
                  {
                    required: true,
                    message: 'Fim obrigatório',
                  },
                ]}
              >
                <TimePicker
                  placeholder="Hora final"
                  style={{ width: '100%' }}
                  format="HH:mm"
                  minuteStep={15}
                  defaultValue={moment(endTime, 'HH:mm')}
                  value={endTime}
                  disabledTime={(date) => disabledRangeTime(date)}
                  onChange={() => handleEndTime}
                  inputReadOnly={true}
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: '5px 0' }} />
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{
              width: '200px',
              marginLeft: 'calc(50% - 100px)',
              marginTop: '10px',
            }}
          >
            Salvar horário
          </PurpleButton>
        </Form>
      </Loading>
    </>
  );
}

export default ScheduleWeekDayForm;
