import styled from 'styled-components';

const TextAttachFile = styled.p`
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 7px 0 0 0;
  font-family: 'Segoe UI', sans-serif;
`;

export { TextAttachFile };
