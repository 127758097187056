import api from '../../core/services/api';
import { IAuth } from '../../core/interfaces';
import { errorCallback } from '../../helpers/promise.helper';

class ProfilePictureService {
  private baseUrl = '/profile-picture';

  static init() {
    return new ProfilePictureService();
  }

  remove(id: number) {
    return api
      .delete<IAuth>(`${this.baseUrl}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  add(id: number, image: File) {
    const form = new FormData();
    form.append('image', image);

    return api
      .post<IAuth>(`${this.baseUrl}/${id}`, form)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ProfilePictureService;
