import React, { useRef, useState } from 'react';
import List from '../../components/list';
import { useAuth } from '../../core/hooks/auth';
import ValuationForm from './ValuationForm';
import { IListRef } from '../../core/interfaces/IListRef';
import { findSatusValuation } from '../../core/enums/StatusValuation';
import { values } from 'lodash';
import { useNavigate } from 'react-router-dom';

function ValuationList() {
  const [formName, setFormName] = useState<string>('');
  const { logged } = useAuth();
  const id = logged?.client && logged?.client;
  const refList = useRef<IListRef>(null);
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render(value: string) {
        return <div style={{ minWidth: '150px' }}>{value}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return findSatusValuation(status);
      },
    },
    {
      title: 'Data',
      dataIndex: 'creation',
      key: 'creation',
    },
  ];

  return (
    <>
      <List
        ref={refList}
        id="form-valuation"
        model="valuation"
        dataSource="valuation/list"
        columns={columns}
        edit={true}
        form={(values) => (
          <ValuationForm
            setFormName={setFormName}
            initialValues={values}
            closeForm={() => refList.current?.closeDrawer()}
          />
        )}
        view={(row) =>
          navigate('/valuations/view', {
            state: {
              initialValues: row,
            },
          })
        }
        formName={formName}
        params={{
          client: id,
        }}
        title="Valuations"
        modalWidth={'100%'}
        modalBackgroundColor="var(--grey-color)"
        paginationBottom="bottomRight"
      />
    </>
  );
}

export default ValuationList;
