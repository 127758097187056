import React, { useEffect, useMemo, useState } from 'react';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { useForm } from 'antd/lib/form/Form';
import { convertBase64 } from '../../helpers/convertion.helper';
import { BoxContainer, PurpleButton } from '../../GlobalStyles';
import { Card, Col, Divider, Form, Input, Select, Row, Drawer } from 'antd';
import notificationMessage from '../../components/notifications/notificationMessage';
import errorNotification from '../../components/notifications/errorNotification';
import DragAndDropFile from '../../components/DragAndDropFile/DragAndDropFile';
import CourseService from '../../services/course/CourseService';
import CourseCategoryService from '../../services/course/CourseCategoryService';
import ICourseCategory from '../../core/interfaces/course/ICourseCategory';
import { useAuth } from '../../core/hooks/auth';
import { IPropsModal } from '../../core/interfaces/modal/IPropsModal';
import ICourse from '../../core/interfaces/models/ICourse';
import FileBox from './FileBox';
import useLoading from '../../core/hooks/useLoading';

interface IFormValues {
  backgroundPicture?: string;
  backgroundPictureName?: string;
  coverPicture?: string;
  coverPictureName?: string;
}

interface Props extends IPropsModal {
  reload(): void;
  course?: ICourse;
}

function CoursesForm({ reload, course, visible, onCancel }: Props) {
  const { logged } = useAuth();
  const { loading, showLoading, hideLoading } = useLoading();
  const [form] = useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const [formValues, setFormValues] = useState<IFormValues>({});
  const [category, setCategory] = useState<ICourseCategory[]>([]);
  const courseService = useMemo(() => CourseService.init(), []);
  const courseCategoryService = useMemo(() => CourseCategoryService.init(), []);

  const getCategory = () => {
    courseCategoryService
      .findAll()
      .then((response) => {
        setCategory(response);
      })
      .catch((error) => {
        errorNotification(error);
      });
  };

  const getDataForm = () => {
    const picture = formValues.coverPicture;
    const coverPicture = formValues.backgroundPicture;
    const originalPictureName = formValues.coverPictureName;
    const originalCoverPictureName = formValues.backgroundPictureName;

    return {
      name: form.getFieldValue('fullCourseName'),
      description: form.getFieldValue('description'),
      courseCategory: {
        entityId: form.getFieldValue('category'),
      },
      average: form.getFieldValue('average'),
      teacher: {
        entityId: logged?.entityId,
      },
      ...(picture && { picture, originalPictureName }),
      ...(coverPicture && { coverPicture, originalCoverPictureName }),
      videoUrl: form.getFieldValue('videoUrl'),
    };
  };

  const onFinish = () => {
    showLoading();
    courseService
      .updateOrCreate(getDataForm(), course?.entityId)
      .then(() => {
        reload();
        form.resetFields();
        notificationMessage('default');
        onCancel();
      })
      .catch((error) => {
        errorNotification(error);
      })
      .finally(hideLoading);
  };

  const backgroundPictureChange = (file: File) => {
    convertBase64(file)
      .then((data) => {
        setFormValues({
          ...formValues,
          backgroundPictureName: file.name,
          backgroundPicture: data as string,
        });
      })
      .catch((er) => {
        console.log('erro: ', er);
      });
  };

  const coverPictureChange = (file: File) => {
    convertBase64(file)
      .then((data) => {
        setFormValues({
          ...formValues,
          coverPictureName: file.name,
          coverPicture: data as string,
        });
      })
      .catch((er) => console.log('erro: ', er));
  };

  const initialValues = React.useMemo(() => {
    return {
      fullCourseName: course?.name ?? '',
      average: course?.average ?? 0,
      videoUrl: course?.videoUrl ?? '',
      category: course?.courseCategory.entityId,
      description: course?.description ?? '',
    };
  }, [course]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Drawer
      className="reset-padding-body-mobile"
      width={'100%'}
      open={visible}
      onClose={onCancel}
      destroyOnClose={true}
    >
      <BoxContainer noPadding>
        <Form
          name="coursesForm"
          form={form}
          layout={'vertical'}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={[]}
          labelWrap
        >
          <Card bodyStyle={{ padding: '16px' }}>
            <Title size={22} margin="0 0 10px 0">
              Adicionar novo curso
            </Title>
            <Text size={11}>
              Antes de fazer o upload das suas aulas é necessário a criação do
              curso que irá organizar todos os conteúdos referentes a essa
              seção, além de categorizar, organizando em temas que irão auxiliar
              na escolha do material que irá compor suas futuras metodologias.
            </Text>
            <Title size={18} margin="5px 0 5px 0">
              Informações gerais
            </Title>
            <Form.Item
              name={'fullCourseName'}
              label={'Nome completo do curso'}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                placeholder="digite o nome completo do curso"
                maxLength={255}
              />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name={'average'}
                  label={'Média do curso'}
                  rules={[{ min: 1, message: 'Tamanho mínimo é 1' }]}
                >
                  <Input
                    placeholder="digite a média do curso"
                    maxLength={1}
                    type="number"
                    min={1}
                    max={9}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={18}>
                <Form.Item
                  name={'category'}
                  label={'Categoria do curso'}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione a categoria"
                    optionFilterProp="children"
                  >
                    {category.map((item, index) => (
                      <Option value={item.entityId} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ backgroundColor: 'var(--thirdy-color)' }} />
            <Title size={18} margin="0 0 10px 0">
              Informações específicas do curso
            </Title>
            <Title size={14} margin="0 0 5px 0">
              Foto de capa do curso
            </Title>
            <Text size={11} margin="0 0 10px 0">
              Essa foto irá aparecer no mosaico de seus cursos tanto para você
              quanto para suas startups, facilitando na identificação. Lembre-se
              de colocar imagens fieis ao curso e que se for utilizado text nas
              imagens, dê prioridade para a legibilidade e leiturabilidade da
              mensagem em destaque.
            </Text>
            {course?.pictureName && (
              <FileBox
                text={course.originalPictureName}
                filename={course.pictureName}
              />
            )}
            <DragAndDropFile
              maxFiles={1}
              maxFileSize={50}
              fileType={['image/jpeg', 'image/jpg', 'image/png']}
              description="Suporta imagens nos formatos JPG, JPEG e PNG, tamanho recomendado é 1080 x 720"
              onFileChange={(file) => coverPictureChange(file)}
            />
            <Title size={14} margin="0 0 5px 0">
              Foto de fundo do curso
            </Title>
            <Text size={11} margin="0 0 10px 0">
              Essa foto aparecerá no topo da janela do curso, após aberto.
              Funciona como um outdoor do próprio curso, podendo conter
              informações mais relevantes a respeito do que esperar dos seus
              conteúdos, ou apenas uma arte ilustrativa.
            </Text>
            {course?.coverPicture && (
              <FileBox
                text={course.originalCoverPicture}
                filename={course.coverPicture}
              />
            )}
            <DragAndDropFile
              maxFiles={1}
              fileType={['image/jpeg', 'image/jpg', 'image/png']}
              description="Suporta imagens nos formatos JPG, JPEG e PNG, tamanho recomendado é 1920 x 1080"
              onFileChange={(file) => backgroundPictureChange(file)}
            />
            <Title size={14} margin="0 0 5px 0">
              Vídeo marketing do curso
            </Title>
            <Form.Item name={'videoUrl'} label={'URL do seu vídeo'}>
              <Input placeholder="digite a url do vídeo" />
            </Form.Item>

            <Title size={14} margin="0 0 5px 0">
              Descrição
            </Title>
            <Text size={11} margin="0 0 5px 0">
              Essa descrição irá complementar a exibição do seu curso para suas
              startups. Nessa descrição, as startups poderão ver, de forma
              reduzida, qual o caminho que irá trabalhar dentro desse curso.
            </Text>
            <Form.Item
              name={'description'}
              label=""
              rules={[
                {
                  max: 255,
                  message: 'O campo deve possuir no máximo 255 caracteres',
                },
              ]}
            >
              <TextArea
                showCount
                style={{ resize: 'none' }}
                rows={2}
                placeholder="Descrição do curso"
                maxLength={255}
              />
            </Form.Item>
            <PurpleButton loading={loading} htmlType="submit" type="primary">
              Salvar
            </PurpleButton>
          </Card>
        </Form>
      </BoxContainer>
    </Drawer>
  );
}

export default CoursesForm;
