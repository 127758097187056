import moment from 'moment';

const daysBetweenDates = (date1: Date, date2: Date): number => {
  const diff = moment(date2).diff(moment(date1));
  const days = moment.duration(diff).asDays();
  const [integer] = days.toString().split('.');

  return parseInt(integer);
};

const convertDateToString = (date: Date | string): string => {
  return moment(date).format('DD/MM/YYYY');
};

const countDaysBetweenDateNowAndDate = (date: Date): number => {
  const now = new Date();
  if (now > date) return 0;
  return daysBetweenDates(now, date);
};

const convertStringToDate = (date: string): Date => {
  return moment(date).toDate();
};

export {
  daysBetweenDates,
  convertDateToString,
  convertStringToDate,
  countDaysBetweenDateNowAndDate,
};
