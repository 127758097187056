import React from 'react';
import BoxTip from './BoxTip';

function Investor() {
  return (
    <BoxTip
      title="Investidor"
      description="No perfil de Investidor, você conseguirá visualizar e acompanhar informações e o desempenho das startups que você tem relação de investimento, saber o quanto investiu e cadastrar rodadas de investimentos, assim como, receber notificações se houve o aceite ou não das propostas de investimento. É possível também convidar as startups para fazer parte do seu portfólio de aportes, ter conhecimento dos diagnósticos e valuation das startups, sinalizar interesse em investir em uma startups e mais.
            Além destes benefícios que a plataforma Innovation Studio concede, você conseguirá acompanhar o progresso das suas startups no dashboard e mais detalhadamente nos perfis de cada empresa. O Innovation Studio desenvolveu a plataforma a fim de gerar maior conexão com os atores do ecossistema de inovação."
    />
  );
}

export default Investor;
