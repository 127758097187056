import { ModalBody } from '../../GlobalStyles';
import rocketIcon from '../../assets/icons/icon_new_startup.png';
import { IPropsModal } from '../../core/interfaces/modal/IPropsModal';
import Title from '../Title';
import Text from '../Text';
import copy from 'copy-to-clipboard';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { notification } from '../../helpers/notification.helper';
import { useAuth } from '../../core/hooks/auth';

interface Props extends IPropsModal {
  description: string;
  title: string;
}

function BaseInvitation({ title, visible, onCancel, description }: Props) {
  const { logged } = useAuth();

  const copyLink = () => {
    try {
      const { origin } = window.location;
      copy(`${origin}/register?inviter=${logged?.tokenShared}`);
      notification.success('Link copiado com sucesso!');
      onCancel();
    } catch (exception) {
      notification.error('Erro ao copiar link');
    }
  };

  return (
    <ModalBody
      maxWidth={'450px'}
      width="100%"
      margin="0 auto"
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={null}
    >
      <img
        src={rocketIcon}
        alt="foguete innovation"
        width={180}
        height={180}
        style={{ display: 'block', margin: '0 auto' }}
      />
      <Title size={24} align="center">
        {title}
      </Title>
      <Text align={'center'} size={15} margin="10px 0 20px 0">
        {description}
      </Text>

      <Form>
        {/* <Form.ItemList name="email">
          <Input placeholder="E-mail" />
        </Form.ItemList> */}
        <Row
          wrap
          align="middle"
          justify="space-between"
          className="w-100"
          gutter={[8, 8]}
        >
          <Col span={24}>
            <Button
              className="w-100"
              type="ghost"
              htmlType="button"
              onClick={copyLink}
              icon={<LinkOutlined />}
            >
              Copiar link
            </Button>
          </Col>
          {/* <Col>
            <Button type="primary" htmlType={'submit'}>
              Enviar
            </Button>
          </Col> */}
        </Row>
      </Form>
    </ModalBody>
  );
}

export default BaseInvitation;
