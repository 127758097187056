import { useEffect, useMemo, useState } from 'react';
import {
  BoxDay,
  BoxDayTime,
  BoxLegend,
  BoxSchedule,
  BoxScheduleInfo,
  BoxTime,
  BoxTimeContent,
  Header,
  Item,
  Legend,
} from '../../../../components/ScheduleCalendar/ScheduleWeekCalendar/style';
import { Button, Divider, notification, Popover } from 'antd';
import {
  getAbbreviation,
  getFullName,
  getKeys,
} from '../../../../core/enums/IWeekDay';
import moment from 'moment/moment';
import { ISchedule } from '../../../../core/interfaces/ISchedule';
import { BoxContainer } from '../../../../GlobalStyles';
import { BoxTitle } from '../../../../components/Profiles/style';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleService from '../../../../services/ScheduleService';
import { useAuth } from '../../../../core/hooks/auth';

interface IProps {
  minRange?: string;
  /**
   * @param "the end hour to show"
   * @example 18:00
   */
  maxRange?: string;
}

function ScheduleWeekCalendar({
  minRange = '06:00',
  maxRange = '22:00',
}: IProps) {
  const weekDays = getKeys();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { date } = state;
  const { mentorId } = state;
  const { mentoringId } = state;

  const clickedDay = moment(date).format('DD');
  const firstDay = moment(date).startOf('week').format('YYYY-MM-DD');
  const month = moment(date).month() + 1;
  let counterDay = firstDay;
  const disableRangeTime: Array<number> = useMemo(() => [], []);
  const scheduleService = ScheduleService.init();
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [dataCalendar, setDataCalendar] = useState<any>([
    {
      entityId: 0,
      date: '',
      start: '',
      end: '',
      mentorName: '',
    },
  ]);

  const { logged } = useAuth();
  const userId = logged?.entityId;
  const getScheduleTimes = () => {
    scheduleService
      .getAllByMentorAndDate(mentorId, moment(date).format('DD-MM-YYYY'))
      .then((response) => {
        setSchedules(response);
      });
  };

  const getTimes = () => {
    schedules.map((schedule) => {
      const start = moment(schedule.initialDate).format('HH:mm');
      const end = moment(schedule.finalDate).format('HH:mm');
      const date = moment(schedule.initialDate).format('YYYY-MM-DD');

      setDataCalendar((prevState: any) => [
        ...prevState,
        {
          entityId: schedule.entityId,
          date: date,
          start: start,
          end: end,
          mentorName: schedule.mentor?.user?.name,
        },
      ]);
    });
  };

  const handleOk = (id: number) => {
    const payload: ISchedule = {
      mentoringId: mentoringId,
    };
    scheduleService
      .toSchedule(id, Number(userId), payload)
      .then(() => {
        notification.success({
          message: 'Agendamento realizado com sucesso!',
          description:
            'Agora você pode acessar o seu agendamento na aba "Meus Agendamentos"',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao realizar o agendamento!',
          description: 'Tente novamente mais tarde',
        });
      });
  };

  const convertMinsToHour = (minsToHour: number, mins?: number) => {
    const time = Math.floor(minsToHour / 60);
    const adjustedHour = String(time).padStart(2, '0');
    const adjustedMins = mins ? String(mins).padStart(2, '0') : '00';

    return adjustedHour + ':' + adjustedMins;
  };

  const getDay = (index: number) => {
    return moment(firstDay).add(index, 'days');
  };

  const checkMonth = (index: number) => {
    const pointerMonth = moment(getDay(index)).month() + 1;

    return pointerMonth === month ? '' : 'no-click';
  };

  const getWeekDay = (index: number) => {
    counterDay =
      index !== 0
        ? moment(counterDay).add(1, 'days').format('YYYY-MM-DD')
        : counterDay;

    return moment(counterDay).format('DD');
  };

  const mountWeekDays = () => {
    return weekDays.map((item, index) => {
      const day = getWeekDay(index);
      const className =
        moment(counterDay).format('DD') === clickedDay
          ? `${checkMonth(index)} today`
          : `${checkMonth(index)}`;

      return (
        <Item key={day} className={className}>
          <span>{day}</span>
          <abbr title={getFullName(index)}>{getAbbreviation(index)}</abbr>
        </Item>
      );
    });
  };

  const scheduleTime = (item: any) => {
    return (
      <BoxScheduleInfo>
        <span>{`${item.start} - ${item.end}`}</span>
        <span title={`${item.name}`}>{item.mentorName}</span>
        <span className="reschedule">
          <Button
            type="primary"
            size={'small'}
            onClick={() => {
              handleOk(item.entityId);
            }}
          >
            Agendar
          </Button>
        </span>
        <Divider style={{ backgroundColor: 'var(--white-color)', margin: 0 }} />
        <span className="start-class"></span>
      </BoxScheduleInfo>
    );
  };

  const mountScheduleTimes = (index: number, time: string) => {
    const div: JSX.Element[] = [];
    const day = moment(getDay(index)).format('YYYY-MM-DD');

    dataCalendar
      .filter((item: any) => item.date === day)
      .map((item: any) => {
        const start = parseInt(item.start.replace(':', ''));
        const end = parseInt(item.end.replace(':', ''));
        const paramTime = parseInt(time.replace(':', ''));

        if (paramTime >= start && paramTime <= end) {
          div.push(
            <div key={`schedule-${day}-${time}`} className="time-scheduled">
              {
                <Popover
                  placement="left"
                  content={scheduleTime(item)}
                  title=""
                  trigger="click"
                  destroyTooltipOnHide={true}
                  showArrow={false}
                />
              }
            </div>,
          );
        }
        return null;
      });

    return div;
  };

  const mountHourTimeLine = () => {
    const div: JSX.Element[] = [];
    let countHour = 0;

    for (let i = 0; i < 1440; i += 60) {
      countHour += 60;

      if (i === 0 || countHour === 60) {
        const convertedMins = convertMinsToHour(i);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          div.push(
            <div
              key={`header-${convertedMins}`}
              className="timeline-header-hour"
            >
              <span>{convertedMins}</span>
            </div>,
          );
        }
        countHour = 0;
      }
    }

    return div;
  };

  const mountMinutesTimeLine = (index: number) => {
    const div: JSX.Element[] = [];
    let countMinutes = 0;
    let countHour = 0;

    const getDiv = (
      index: number,
      convertedMins: string,
      className: string,
    ) => {
      div.push(
        <div
          key={`${index}-${convertedMins}`}
          className={`${checkMonth(index)} ${className}`}
        >
          <span>{convertedMins}</span>
          {mountScheduleTimes(index, convertedMins)}
        </div>,
      );
    };

    for (let i = 0; i < 1440; i++) {
      if (
        (countMinutes === 15 || countMinutes === 30 || countMinutes === 45) &&
        countHour < 60
      ) {
        const convertedMins = convertMinsToHour(i, countMinutes);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          getDiv(index, convertedMins, 'timeline-minute');
        }
      } else if (countHour === 60) {
        const convertedMins = convertMinsToHour(i);

        if (minRange <= convertedMins && maxRange >= convertedMins) {
          getDiv(index, convertedMins, 'timeline-hour');
        }
        countHour = 0;
        countMinutes = 0;
      }
      countMinutes++;
      countHour++;
    }

    return div;
  };

  useEffect(() => {
    const disableRange = () => {
      const [minHour] = minRange.replace('0', '').split(':');
      const [maxHour] = maxRange.replace('0', '').split(':');

      for (let i = 0; i < 24; i++) {
        if (i < parseInt(minHour) || i > parseInt(maxHour)) {
          disableRangeTime.push(i);
        }
      }
    };

    disableRange();
  }, [minRange, maxRange, disableRangeTime]);

  useEffect(() => {
    getScheduleTimes();
    getTimes();
  }, []);

  useEffect(() => {
    getTimes();
  }, [schedules]);

  return (
    <BoxContainer>
      <BoxTitle
        style={{
          marginBottom: '10px',
        }}
      >
        <GoBack
          enableBackground={true}
          goBackFunction={() => {
            navigate(-1);
          }}
        />
        <Title size={20} margin="0 0 0 10px">
          Agenda
        </Title>
      </BoxTitle>
      <BoxSchedule>
        <Header>
          <div style={{ width: '60px' }}>&nbsp;</div>
          {mountWeekDays()}
        </Header>
        <BoxTimeContent>
          <BoxTime>{mountHourTimeLine()}</BoxTime>
          <BoxDayTime>
            {Array.from(Array(7)).map((item, index) => (
              <BoxDay key={index}>{mountMinutesTimeLine(index)}</BoxDay>
            ))}
          </BoxDayTime>
        </BoxTimeContent>
        <BoxLegend>
          <Divider
            style={{ backgroundColor: 'var(--support-color)', margin: 0 }}
          />
          <Legend>
            <Legend>
              <span className="avaiable">&nbsp;</span>
              Horários sem agendamento
            </Legend>
            <Legend>
              <span className="scheduled">&nbsp;</span>
              Horários Disponíveis
            </Legend>
          </Legend>
        </BoxLegend>
      </BoxSchedule>
    </BoxContainer>
  );
}

export default ScheduleWeekCalendar;
