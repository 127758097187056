import styled from 'styled-components';
import { Input } from 'antd';

const InputCurrencyComponent = styled(Input)<{
  negative?: boolean;
}>`
  color: ${(props) => (props.negative ? 'red' : 'black')};
`;

export default InputCurrencyComponent;
