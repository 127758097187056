import React from 'react';
import { Table, TableFooter } from './styles';

interface IProps {
  data?: Array<any>;
  maturity?: number;
  maturityIndicator?: any;
}

function MaturityTable(props: IProps) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Pilares</th>
          <th>Pontuação</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.map((item, index) => (
          <tr key={`maturity_table_key_${index}`}>
            <td style={{ width: '57%' }}>{item.pillars}</td>
            <td>{Math.floor(item?.result).toFixed(0)}</td>
          </tr>
        ))}
      </tbody>
      <TableFooter>
        <tr>
          <td>Indicador de Maturidade da Startup</td>
          <td>{`| ${Math.floor(props.maturity || 0).toFixed(0)} | * ${
            props.maturityIndicator?.title ?? ''
          }`}</td>
        </tr>
        {Boolean(props.maturityIndicator) && (
          <tr>
            <td colSpan={2}>
              <strong>{props.maturityIndicator?.title}: </strong>
              {props.maturityIndicator?.descritpion}
            </td>
          </tr>
        )}
      </TableFooter>
    </Table>
  );
}

export default MaturityTable;
