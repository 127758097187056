import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #f0f2f5;
  border-radius: 5px;
  padding: 1rem;

  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const ProgressBar = styled.div<{
  width: number;
}>`
  background: #f0f2f5;
  height: 8px;
  border-radius: 8px;

  &::before {
    content: '';
    height: inherit;
    border-radius: inherit;
    display: block;
    width: ${({ width }) => `${width}%`};
    background-color: var(--primary-color);
  }
`;

export { ProgressBar, Wrapper };
