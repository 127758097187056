import React from 'react';
import BoxTip from './BoxTip';

function Mentor() {
  return (
    <BoxTip
      title="Mentor"
      description="No perfil de Mentor, você conseguirá cadastrar suas mentorias para que startups possam contratar seus serviços, terá acesso a agenda onde aparecerá todas as suas mentorias agendadas, fazer alterações, cancelar e/ou reagendar. A plataforma Innovation Studio, dará autonomia e facilidade para você, mentor, oferecer os seus serviços e acompanhar as startups que você mentora. O Innovation Studio desenvolveu a plataforma a fim de gerar maior conexão com os atores do ecossistema de inovação."
    />
  );
}

export default Mentor;
