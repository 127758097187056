import React, { useMemo } from 'react';
import { $enum } from 'ts-enum-util';
import { Roles } from '../../core/enums';
import DashboardStartup from '../../pages/startup/Dashboard';
import DashboardClient from '../client/Dashboard';
import DashboardInvestor from '../investor/Dashboard/DashboardInvestor';
import { useAuth } from '../../core/hooks/auth';

function Dashboard() {
  const { logged } = useAuth();

  const components = [
    {
      permission: Roles.V_DASHBOARD_STARTUP,
      component: DashboardStartup,
    },
    {
      permission: Roles.V_DASHBOARD_ACCELERATOR,
      component: DashboardClient,
    },
    {
      permission: Roles.V_DASHBOARD_INVESTOR,
      component: DashboardInvestor,
    },
  ];

  const roles = useMemo(
    () => logged?.roles?.map((role) => role) ?? [],
    [logged],
  );

  return (
    <>
      {components
        .filter(({ permission }) =>
          roles.includes($enum(Roles).getKeyOrDefault(permission) ?? ''),
        )
        .map(({ permission, component: Component }) => (
          <Component key={`dashboard_${permission}`} />
        ))}
    </>
  );
}
export default Dashboard;
