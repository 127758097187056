import styled from 'styled-components';

const BoxSchedule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 135px);
  position: relative;
  border: 1px solid var(--primary-color);
  background-color: var(--white-color);
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  max-height: 95px;
  padding: 10px 0;
  justify-items: flex-start;
  background-color: var(--primary-color);

  & .today span,
  .today abbr {
    color: var(--secondary-color);
  }

  & .no-click {
    span,
    abbr {
      color: var(--support-color);
    }
  }
`;
const Item = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span,
  abbr {
    font-size: 2rem;
    color: var(--white-color);
    font-weight: bold;
  }

  abbr {
    font-size: 1.2rem;
    text-decoration: none;
    cursor: help;
  }
`;

const BoxTimeContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: auto;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`;

const BoxTime = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  justify-content: flex-start;

  & .timeline-header-hour {
    width: 100%;
    min-height: 80px;
    max-height: 80px;
    position: relative;

    span {
      position: absolute;
      top: -5px;
      left: 10px;
    }
  }
`;

const BoxDayTime = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const BoxDay = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 4px;

  & .timeline-minute,
  .timeline-hour {
    width: 100%;
    height: 20px;
    border-right: 1px solid var(--support-color);
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      font-size: 0.8rem;
      opacity: 0;
    }

    :hover {
      background-color: var(--primary-color);
      color: var(--white-color);
      cursor: pointer;

      span {
        opacity: 1;
      }
    }

    :last-child {
      border-bottom: 1px solid var(--support-color);
    }

    :has(.time-scheduled) {
      :hover {
        cursor: default;
        color: var(--primary-color);
        background-color: transparent;

        span {
          opacity: 0;
        }
      }
    }

    & .time-scheduled {
      position: absolute;
      top: -1px;
      right: 0;
      width: 20px;
      height: 20px;
      color: var(--white-color);
      text-align: center;
      background-color: var(--thirdy-color);
      cursor: pointer !important;
    }
  }

  & .timeline-minute {
    border-top: 1px dashed var(--support-color);
  }

  & .timeline-hour {
    border-top: 1px solid var(--support-color);
  }

  :last-child {
    & .timeline-minute,
    .timeline-hour {
      border-right: none;
    }
  }

  & .no-click {
    background-color: var(--grey-color);
    pointer-events: none;

    span {
      opacity: 0;
    }
  }
`;

const BoxScheduleInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 120px;
  background-color: var(--thirdy-color);
  margin: -12px -12px;
  border-radius: 6px;
  padding: 10px 10px;

  span {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    color: var(--white-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :nth-child(1) {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  & .reschedule,
  .start-class {
    font-style: italic;
    margin: 5px 0;
    cursor: pointer;
  }

  & .reschedule {
    margin-top: 20px;
  }
`;

const BoxLegend = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--white-color);
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px 0;

  & .scheduled,
  .avaiable {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 0 10px 0 20px;
  }

  & .avaiable {
    border: 1px solid var(--primary-color);
  }

  & .scheduled {
    background-color: var(--thirdy-color);
  }
`;

export {
  BoxSchedule,
  Header,
  Item,
  BoxTime,
  BoxDayTime,
  BoxDay,
  BoxTimeContent,
  BoxScheduleInfo,
  BoxLegend,
  Legend,
};
