//convert a string to a number
const convertCurrencyMaskInputToNumber = (amount: string) => {
  if (amount) {
    return Number(amount.replace(/\./g, '').replace(',', '.'));
  }
  return 0;
};

//convert a number to a string
const convertNumberToCurrencyMaskInput = (amount?: number) => {
  if (amount) {
    return amount.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  return 'R$ 0,00';
};

const convertNumberToCurrencyMaskInputWithoutPrefix = (amount: number) => {
  return convertNumberToCurrencyMaskInput(amount).replace('R$', '').trim();
};

//convert numeric value to a string
const convertNumberToString = (amount: number) => {
  if (amount) {
    return amount.toLocaleString('pt-BR');
  }
  return '0';
};

//create mask for input percentage
const maskPercentage = (value: string) => {
  // if size is greater than 4 return integer value: exemple: 100,0 -> 100
  if (value.length > 4) {
    return value.replace(/\D/g, '').slice(0, 3);
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{1})(\d{1})$/, '$1,$2')
    .replace(/(\d{2})(\d{1})$/, '$1,$2');
};
//convert float in percentage
const convertFloatToPercentage = (amount: number) => {
  if (amount) {
    return (amount * 100).toFixed(2);
  }
  return '0';
};

// Conver file into base64
const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      return resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      return reject(error);
    };
  });
};

export {
  convertCurrencyMaskInputToNumber,
  convertNumberToCurrencyMaskInput,
  convertNumberToCurrencyMaskInputWithoutPrefix,
  convertNumberToString,
  convertFloatToPercentage,
  convertBase64,
  maskPercentage,
};
