import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Col, Select, Row, Space, Spin, notification } from 'antd';
import Title from '../../../../components/Title';
import { CustomSelect, WrapperEvidence } from './styles';
import { GrDocumentPdf } from 'react-icons/gr';
import Text from '../../../../components/Text';
import IFileStorage from '../../../../core/interfaces/IFileStorage';
import FileService from '../../../../services/FileService';
import StatusFileStorageEnum from '../../../../core/enums/StatusFileStorageEnum';
import KpiService from '../../../../services/KpiService';
import { PurpleButton } from '../../../../GlobalStyles';
import { IPerformaceIndicatorStatus } from '../../../../core/enums/IKeyPerformaceIndicators';
import MetricCrudService from '../../../../services/MetricCrudService';
import { useModal } from 'sunflower-antd';
import FinishActivityModal from '../FinishActivityModal';

interface Props {
  reload(): void;
  kpiId?: number;
  status?: IPerformaceIndicatorStatus;
  files: IFileStorage[];
}

function EvidencesKpiDetails({ reload, status, kpiId, files }: Props) {
  const styleOptions = {
    background: '#fff',
  };
  const finishActivity = useModal({
    defaultVisible: false,
  });

  const fileService = useMemo(() => FileService.init(), []);
  const kpiService = useMemo(() => KpiService.init(), []);

  const [loading, setLoading] = useState<boolean>(false);

  const viewEvidence = (file: IFileStorage): void => {
    setLoading(true);
    fileService
      .open(file)
      .catch(() => {
        notification.info({
          message: 'Erro ao abrir evidência',
          duration: 3.5,
        });
      })
      .finally(() => setLoading(false));
  };

  const changeStatus = (status: StatusFileStorageEnum, fileId?: number) => {
    setLoading(true);
    kpiService
      .changeStatusFile(Number(fileId), status)
      .then(() => {
        reload();
        notification.success({
          message: 'Status alterado com sucesso',
          duration: 3.5,
        });
      })
      .catch(() => {
        notification.info({
          message: 'Erro ao alterar status',
          duration: 3.5,
        });
      })
      .finally(() => setLoading(false));
  };

  const isConcluded = status?.includes(IPerformaceIndicatorStatus.Concluded);
  const enableComplete = files.some(
    (file) => file.status === StatusFileStorageEnum.Approved,
  );

  const completeKpi = () => {
    MetricCrudService.init()
      .updateStatusKpi(Number(kpiId), IPerformaceIndicatorStatus.Concluded)
      .then(() => {
        reload();
        finishActivity.close();
        notification.success({
          message: 'KPI concluído com sucesso',
          duration: 3.5,
        });
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao concluir KPI',
          duration: 3.5,
        });
      });
  };

  return (
    <Spin delay={300} spinning={loading}>
      <Title margin="0 0 8px 0" size={16}>
        Evidências
      </Title>

      {isEmpty(files) && (
        <Text align="center" weight={600}>
          Evidências vazias
        </Text>
      )}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        {files.map((file) => (
          <Row gutter={16} key={`file_key_${file.entityId}`}>
            <Col flex={1}>
              <WrapperEvidence
                minWidth="175px"
                onClick={() => viewEvidence(file)}
              >
                <GrDocumentPdf size={22} />
                {file.originalName}
              </WrapperEvidence>
            </Col>
            <Col>
              <CustomSelect
                labelInValue
                defaultValue={file.status}
                bordered={false}
                disabled={!file.status.includes(StatusFileStorageEnum.Pending)}
                style={{ minWidth: 189 }}
                onChange={(params) => {
                  const { value } = params as Record<
                    string,
                    StatusFileStorageEnum
                  >;
                  changeStatus(value, file.entityId);
                }}
              >
                <Select.Option
                  value={StatusFileStorageEnum.Pending}
                  style={styleOptions}
                >
                  <Text weight={700}>Validar evidência</Text>
                </Select.Option>
                <Select.Option
                  value={StatusFileStorageEnum.Approved}
                  style={styleOptions}
                >
                  <Text weight={700} color="#31FF2A">
                    Aprovado
                  </Text>
                </Select.Option>
                <Select.Option
                  value={StatusFileStorageEnum.Disapproved}
                  style={styleOptions}
                >
                  <Text weight={700} color="#EC1616">
                    Desaprovado
                  </Text>
                </Select.Option>
              </CustomSelect>
            </Col>
          </Row>
        ))}
      </Space>

      {enableComplete && !isConcluded && (
        <Row justify="center" style={{ marginTop: '2rem' }}>
          <Col>
            <PurpleButton
              type="primary"
              onClick={() => finishActivity.show()}
              style={{ minWidth: '200px' }}
            >
              Concluir KPI
            </PurpleButton>
          </Col>
        </Row>
      )}

      <FinishActivityModal
        finish={completeKpi}
        {...finishActivity.modalProps}
      />
    </Spin>
  );
}

export default EvidencesKpiDetails;
