import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ContentForm from '../ContentForm';
import { useAuth } from '../../../../../core/hooks/auth';
import { IUser } from '../../../../../core/interfaces';
import AcceleratorPersonalForm from './AcceleratorPersonalForm';
import AcceleratorForm from './AcceleratorForm';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setFormName: Dispatch<SetStateAction<string | undefined>>;
}

function ScreenAccelerator(props: IParams) {
  const [initialValues, setInitialValues] = useState<any>({});
  const [formData, setFormData] = useState<IUser>();
  const { logged } = useAuth();
  const subSteps = [
    {
      subTitle:
        'Precisamos saber algumas informações a respeito de você como representante',
      description: 'Dados pessoais',
      content: (
        <AcceleratorPersonalForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setButtonState={props.setButtonState}
          setFormName={props.setFormName}
          initialValues={initialValues}
          setFormData={setFormData}
        />
      ),
    },
    {
      subTitle:
        'Agora, precisamos saber algumas informações a respeito da aceleradora',
      description: 'Dados da aceleradora',
      content: (
        <AcceleratorForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setButtonState={props.setButtonState}
          current={props.current}
          setCurrent={props.setCurrent}
          setFormName={props.setFormName}
          initialValues={formData}
        />
      ),
    },
  ];

  useEffect(() => {
    setInitialValues({
      entityId: logged?.entityId,
      name: logged?.name,
      email: logged?.email,
    });
  }, []);

  return (
    <ContentForm
      title={'Cadastro de aceleradora'}
      subTitle={subSteps[props.subStep].subTitle}
      description={subSteps[props.subStep].description}
      content={subSteps[props.subStep].content}
    />
  );
}

export default ScreenAccelerator;
