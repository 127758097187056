import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
} from 'antd';
import { ButtonDelete } from '../style';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { MethodologyFormContent, TextDescription } from './style';
import { useAuth } from '../../../core/hooks/auth';
import api from '../../../core/services/api';
import notificationMessage from '../../notifications/notificationMessage';
import AlertMessage from '../../Alert/AlertMessage';
import { isEmpty } from 'lodash';
import IPhases from '../../../core/interfaces/models/IPhases';
import IMethodology from '../../../core/interfaces/models/IMethodology';
import StatusEnum from '../../../core/enums/StatusEnum';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  setFormName: (formName: string) => void;
  setNext: (ask: string) => void;

  getNameMethodology(): unknown;

  methodology: string;
  setParamsResponse: (response: any) => void;
  initialValues?: any;
}

function PhasesForm(props: IParams) {
  const [form] = Form.useForm();
  const { logged } = useAuth();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const id = logged?.client as number;
  const { loading, showLoading, hideLoading } = useLoading();

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));
    const initialValues = props.initialValues;

    const result: IMethodology = {
      name: props.methodology,
      client: {
        entityId: id,
      },
      active: true,
      entityId: initialValues?.entityId,
      status: initialValues?.status ?? StatusEnum.Pending,
      pillars: initialValues?.pillars ?? [],
      phases: valueMap
        .get('methodologyPhaseList')
        .map((item: any, index: number) => {
          const phase = initialValues?.phases?.at(index);
          return {
            entityId: phase?.entityId,
            name: item.phase,
            description: item.description,
            orderPhase: index + 1,
            active: true,
            phases: item?.steps?.map((step: any, stepIndex: number) => {
              return {
                entityId: phase?.phases?.[stepIndex]?.entityId,
                name: step.step,
                active: true,
                orderPhase: stepIndex + 1,
              };
            }),
          };
        }),
    };

    return result;
  };

  const onOk = () => {
    props.setNext('1');
    setIsOpenModal(false);
  };

  const count = (item: any) => Object.keys(item).length;

  const register = (values: FormInstance) => {
    const dataForm = form.getFieldValue('methodologyPhaseList');
    const countForm = count(dataForm);

    const countMethodology = count(props.initialValues?.phases ?? {});
    const isChangePhases = countForm > countMethodology;
    const isNameChange =
      props.initialValues?.name !== props.getNameMethodology();

    if (isChangePhases && !isNameChange) {
      notification.info({
        message: 'Altere o nome da métodologia',
      });

      return;
    }

    const isEditing = Boolean(props.initialValues?.entityId);
    showLoading();
    api({
      method: isEditing ? 'PUT' : 'POST',
      url: `methodology/${isEditing ? 'update' : 'create'}`,
      data: arrageValues(values),
    })
      .then((response) => {
        notificationMessage('create');
        setIsOpenModal(true);
        props.setParamsResponse(response.data);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao cadastrar metodologia',
          description: error.response.data.message,
        });
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    const values = props.initialValues;
    if (!isEmpty(values)) {
      setTimeout(() => {
        const phases = values.phases.map((phase: IPhases) => ({
          phase: phase.name,
          description: phase.description,
        }));
        form.setFieldValue('methodologyPhaseList', phases);
      }, 175);
    }
  }, [form, props.initialValues]);

  useEffect(() => {
    setTimeout(() => {
      const data = sessionStorage.getItem('form-phases-methodology');
      const dataForm = data
        ? JSON.parse(data)
        : Array(4)
            .fill(0)
            .map(() => ({
              phase: '',
            }));
      form.setFieldValue('methodologyPhaseList', dataForm);
    }, 150);

    return () => {
      sessionStorage.setItem(
        'form-phases-methodology',
        JSON.stringify(form.getFieldValue('methodologyPhaseList')),
      );
    };
  }, [form]);

  useEffect(() => {
    props.setFormName('methodologyPhaseForm');
    //props.setNext('')
  }, [props.setFormName]);

  return (
    <Loading spinning={loading} width={200} height={200}>
      <Form
        name="methodologyPhaseForm"
        layout="vertical"
        onFinish={register}
        autoComplete="off"
        form={form}
      >
        <Form.List name="methodologyPhaseList">
          {(fields, { add, remove }) => (
            <MethodologyFormContent>
              <TextDescription>
                <p>
                  As fases são as etapas que uma startup passa durante todo o
                  período de sua aceleração, sendo a <b>fase 1</b> a inicial de
                  todo o processo, seguindo uma ordem lógica de evolução.
                </p>
              </TextDescription>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={'phase-' + key} className="item">
                  {fields.length > 4 ? (
                    <ButtonDelete>
                      <DeleteOutlined
                        className="removeItem"
                        onClick={() => remove(name)}
                      />
                    </ButtonDelete>
                  ) : null}
                  <Row gutter={[4, 4]}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label={'Fase ' + (name + 1)}
                        name={[index, 'phase']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                          {
                            max: 100,
                            message: 'Fase não pode ter mais de 100 caracteres',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Digite o nome da fase"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label={`Descrição ${name + 1}`}
                        name={[index, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={1}
                          showCount={true}
                          placeholder="Digite a descrição da fase"
                          maxLength={500}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.List name={[index, 'steps']}>
                    {(steps, { add, remove }) => (
                      <>
                        <div className="line">
                          {steps.map(({ key, name }, step) => (
                            <div key={'step-' + name} className="step">
                              <ButtonDelete>
                                <DeleteOutlined
                                  className="removeItem"
                                  onClick={() => remove(name)}
                                />
                              </ButtonDelete>
                              <Form.Item
                                label={'Passo ' + (name + 1)}
                                name={[step, 'step']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                  },
                                  {
                                    max: 100,
                                    message:
                                      'Passo não pode ter mais de 100 caracteres',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Digite o nome do passo"
                                  maxLength={255}
                                />
                              </Form.Item>
                            </div>
                          ))}
                        </div>
                        <Form.Item>
                          <Button
                            size="small"
                            shape="circle"
                            type="dashed"
                            style={{
                              margin: 0,
                            }}
                            onClick={() => {
                              add();
                            }}
                            icon={<PlusOutlined />}
                          />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Divider style={{ margin: 0, marginBottom: '10px' }} />
                </div>
              ))}
              <div className="item">
                <Form.Item>
                  <Button
                    size="small"
                    shape="circle"
                    type="dashed"
                    className="addItem"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </div>
            </MethodologyFormContent>
          )}
        </Form.List>

        {/* Modal */}
        <AlertMessage
          visible={isOpenModal}
          message="As fases foram salvas"
          description="Muito bem, deseja continuar? No proximo passo você irá cadastrar os pilares para sua metodologia"
          textCancelButton="Fechar"
          textOkButton="Continuar"
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Form>
    </Loading>
  );
}

export default PhasesForm;
