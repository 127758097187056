import { Col, Form, Input, Row, Spin } from 'antd';
import React, { useMemo, useCallback, useEffect } from 'react';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import ClientProfileService from '../../../../core/interfaces/ClientProfileService';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import { ClientServices } from '../../../../services/ClientServices';

type Prosp = Omit<IPropsModal, 'visible'>;

function FormClientProfile({ onCancel }: Prosp) {
  const [form] = Form.useForm();
  const { updateLogged, logged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();

  const { entityId = 0 } = logged ?? {};

  const required = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const clientProfileService = useMemo(() => ClientProfileService.init(), []);
  const clientService = useMemo(() => ClientServices.init(), []);
  const initialValues = useMemo(
    () => ({
      name: logged?.name,
      description: logged?.description,
      email: logged?.email,
    }),
    [logged],
  );

  const getAddress = useCallback(() => {
    showLoading();
    clientService
      .getAddressByUserId(entityId)
      .then((address) => {
        form.setFieldsValue({
          cep: address.zipCode,
          address: address.street,
          number: address.number,
          district: address.neighborhood,
        });
      })
      .catch(() => notification.info('Endereço não encontrado'))
      .finally(hideLoading);
  }, [form, hideLoading, showLoading, entityId, clientService]);

  const handleSubmit = (values: Record<string, string>) => {
    showLoading();
    clientProfileService
      .update(entityId, {
        ...values,
        neighborhood: values.district,
        street: values.address,
        zipCode: values.cep,
      })
      .then(({ jwttoken }) => {
        onCancel();
        updateLogged(jwttoken);
        notification.success('Dados atualizados com sucesso');
      })
      .catch(() => notification.error('Erro ao atualizar dados'))
      .finally(hideLoading);
  };

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Form.Item label="Nome" rules={required} name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Descrição" rules={required} name="description">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="E-mail" rules={required} name="email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label="CEP" rules={required} name="cep">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Endereço" rules={required} name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Nº" rules={required} name="number">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Bairro" rules={required} name="district">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <PurpleButton type="primary" htmlType="submit">
              Salvar
            </PurpleButton>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
}

export default FormClientProfile;
