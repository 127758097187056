import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BoxContent } from './style';

function Notifications() {
  const [notification] = useState<number>(0);

  return (
    <Link to={'/notifications'}>
      <BoxContent>
        <Badge
          title=""
          size="small"
          count={notification}
          style={{ marginTop: '5px' }}
        >
          <BellOutlined style={{ marginTop: '5px' }} />
        </Badge>
      </BoxContent>
    </Link>
  );
}

export default Notifications;
