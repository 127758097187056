import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const ContainerHeader = styled.div`
  display: flex;
  gap: 16px;
  height: inherit;
  align-items: center;
  justify-content: flex-end;
`;

const InformationUser = styled.div`
  display: flex;
  min-width: 70px;
  flex-direction: column;
`;

export { Content, InformationUser, ContainerHeader };
