import React from 'react';
import ContentBase from '../../templates/ContentBase';
import ModalInfo from './ModalInfo';
import Tab from '../../../components/tab';
import UsersActive from './Screens/UsersActive';
import UsersBlocked from './Screens/UsersBlocked';
import UsersPending from './Screens/UsersPending';
import UsersRejected from './Screens/UsersRejected';

function UsersPage() {
  const content = [
    {
      tab: 'Ativos',
      body: <UsersActive />,
    },
    {
      tab: 'Pendentes',
      body: <UsersPending />,
    },
    {
      tab: 'Bloqueados',
      body: <UsersBlocked />,
    },
    {
      tab: 'Rejeitados',
      body: <UsersRejected />,
    },
  ];
  return (
    <>
      <ContentBase
        title="Usuários"
        modalContent={<ModalInfo />}
        content={
          <>
            <Tab content={content} />
          </>
        }
      />
    </>
  );
}

export default UsersPage;
