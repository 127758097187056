import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxWhite } from '../../../GlobalStyles';
import ProfileInfosInfo from '../../Infos/ProfileInfosInfo';
import ContentBase from '../../templates/ContentBase';
import FormInfo from './FormInfo';
import BusinessModelService from '../../../services/BusinessModelService';
import BusinessTypeService from '../../../services/BusinessTypeService';
import PhaseInvestmentService from '../../../services/PhaseInvestmentService';
import AmountInvestmentService from '../../../services/AmountInvestmentService';
import PeriodInvestmentService from '../../../services/PeriodInvestmentService';
import ValuesInvestmentService from '../../../services/ValuesInvestmentService';

function ProfileInfos() {
  const text =
    'Aqui serão cadastrados todos os campos selecionáveis que irão aparecer para os ';

  const businessModelService = BusinessModelService.init();
  const businessTypeService = BusinessTypeService.init();
  const phaseInvestmentService = PhaseInvestmentService.init();
  const amountInvestmentService = AmountInvestmentService.init();
  const periodInvestmentService = PeriodInvestmentService.init();
  const valuesInvestmentService = ValuesInvestmentService.init();

  return (
    <ContentBase
      title="Informações de perfil"
      modalContent={<ProfileInfosInfo />}
      content={
        <>
          <BoxWhite>
            <Title size={18}>Cadastro de perfil</Title>
            <Text margin="0 0 10px 0">
              {text} <b>perfis que precisarem na criação de suas contas.</b>
            </Text>
            <FormInfo
              title="Modelo de negócio"
              service={businessModelService}
              description={
                <>
                  As tags cadastradas em modelo de negócio servirão{' '}
                  <b>todos os perfis</b> que precisarão preencher esse campo em
                  seus cadastros
                </>
              }
            />
            <FormInfo
              title="Como seu negócio pode ser classificado"
              service={businessTypeService}
            />
          </BoxWhite>
          <BoxWhite>
            <Title size={18}>Cadastro de perfil investidor</Title>
            <Text margin="0 0 10px 0">
              {text} <b>investidores na criação de suas contas.</b>
            </Text>
            <FormInfo
              title="Quais fases você investe?"
              service={phaseInvestmentService}
            />
            <FormInfo
              title="Quanto você normalmente investe?"
              service={amountInvestmentService}
            />
            <FormInfo
              title="Tempo de fundação da startup que você investe normalmente?"
              service={periodInvestmentService}
            />
            <FormInfo
              title="Aproximadamente quantas empresas de portfólio você tem nesse momento?"
              service={valuesInvestmentService}
            />
          </BoxWhite>
        </>
      }
    />
  );
}

export default ProfileInfos;
