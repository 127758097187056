import { Button } from 'antd';
import Title from '../../../../../components/Title';
import ICourseShowCase from '../../../../../core/interfaces/course/ICourseShowCase';

import {
  Container,
  Header,
  NavigationWrapper,
  HorizontalScroll,
  HorizontalContent,
} from './styles';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import CardCourse from '../../../../../components/CardCourse/CardCourse';
import Divider from '../../../../../components/Divider';

interface CoursesByCategoryProps {
  category: string;
  courses: ICourseShowCase[];
}

const COURSE_CARD_SCROLL_WIDTH = 600; // Pixels

export function CoursesByCategory({
  category,
  courses,
}: CoursesByCategoryProps) {
  const scrollingRef = useRef<HTMLDivElement>(null);

  function handleScrolling(value: number) {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += value;
    }
  }

  return (
    <Container>
      <Header>
        <Title size={26} weight={700}>
          {category}
        </Title>
        <NavigationWrapper>
          <Button
            size="middle"
            type="ghost"
            icon={<LeftOutlined />}
            onClick={() => handleScrolling(-COURSE_CARD_SCROLL_WIDTH)}
          />
          <Button
            size="middle"
            type="ghost"
            icon={<RightOutlined />}
            onClick={() => handleScrolling(COURSE_CARD_SCROLL_WIDTH)}
          />
        </NavigationWrapper>
      </Header>

      <Divider margin="1rem 0" />

      <HorizontalScroll ref={scrollingRef}>
        <HorizontalContent>
          {courses.map((course) => {
            return (
              <CardCourse
                key={course.entityId}
                name={course.name}
                amountExercises={course.amountExercises}
                categoryName={course.categoryName}
                description={course.description}
                duration={course.duration}
                lessonQuantity={course.lessonQuantity}
                price={course.price}
                coverPicture={course.coverPicture}
                entityId={course.entityId}
                extraMaterialAmount={course.extraMaterialAmount}
                progress={course.progress}
              />
            );
          })}
        </HorizontalContent>
      </HorizontalScroll>
    </Container>
  );
}
