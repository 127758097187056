import React from 'react';
import { ModalBody } from '../../../GlobalStyles';
import { IPropsModal } from '../../../core/interfaces/modal/IPropsModal';
import pathWarningIcon from '../../../assets/icons/warning_icon.png';
import { Button, Col, Row } from 'antd';
import Title from '../../../components/Title';

interface Props extends IPropsModal {
  confirm(): void;
  loading?: boolean;
  title: string;
  textConfirm: string;
  closable?: boolean;
  children?: React.ReactNode;
}

function ModalConfirm({
  visible,
  onCancel,
  confirm,
  title,
  loading,
  children,
  closable = true,
  textConfirm = 'Remover',
}: Props) {
  const styleButton = {
    width: '60%',
    display: 'block',
    margin: '0 auto',
  };

  return (
    <ModalBody
      width={420}
      footer={null}
      closable={closable}
      open={visible}
      onCancel={onCancel}
      wrapClassName={'modal-align-center'}
      destroyOnClose={true}
    >
      <Row gutter={[0, 16]} align="middle" justify="center">
        <Col span={24} style={{ display: 'grid' }}>
          <img
            src={pathWarningIcon}
            alt="icone alerta"
            height={150}
            width={150}
            style={{ margin: '0 auto' }}
          />
          <Title align="center" size={24}>
            {title}
          </Title>
        </Col>
        <Col span={24}>{children}</Col>
        <Col span={24}>
          <Button
            type="primary"
            onClick={confirm}
            loading={loading}
            style={{ ...styleButton, backgroundColor: 'var(--thirdy-color)' }}
          >
            {textConfirm}
          </Button>
        </Col>
        {closable && (
          <Col span={24}>
            <Button
              disabled={loading}
              style={styleButton}
              type="default"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </Col>
        )}
      </Row>
    </ModalBody>
  );
}

export default ModalConfirm;
