import React from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxDates, BoxList, InvestmentCard, Item } from './style';
import image from '../../../assets/icons/actions-icons/ic-investment-round.svg';
import { Divider } from 'antd';
import IInvestmentRound from '../../../core/interfaces/IInvestmentRound';
import { convertDateToString } from '../../../helpers/dates.helper';
import { useNavigate } from 'react-router-dom';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';

interface IProps {
  entity: IInvestmentRound[];
}

function InvestmentFinished({ entity }: IProps) {
  const navigate = useNavigate();
  const getItem = (
    label: string,
    val: string | number,
    style?: React.CSSProperties,
  ) => {
    return (
      <Item align="flex-start" margin="10px">
        <Text weight={700}>{label}</Text>
        <Text style={style}>
          {typeof val === 'number' && val < 10 ? `0${val}` : val}
        </Text>
      </Item>
    );
  };

  const openInvestmentRoundDetails = (id: number) => {
    navigate('/investment-round/details', {
      state: {
        entityId: id,
        type: 'investor',
      },
    });
  };

  return (
    <>
      <Title size={18}>Rodadas finalizadas</Title>

      <OnEmptyOrList
        list={entity}
        description="Nenhuma rodada de investimento finalizada"
      >
        <BoxList flex="row">
          {entity.map((item) => {
            return (
              <>
                <InvestmentCard
                  onClick={() =>
                    openInvestmentRoundDetails(item.entityId as number)
                  }
                >
                  <img alt="" src={image} />
                  <span className="name">{item.name}</span>
                  <Divider style={{ margin: '10px' }} />
                  {getItem(
                    'Fase de investimento',
                    item.phaseInvestment?.description as string,
                  )}
                  {getItem('Startup cadastradas', 10)}
                  <BoxDates>
                    {getItem('Início', convertDateToString(item.startDate))}
                    {getItem('Término', convertDateToString(item.endDate))}
                  </BoxDates>
                </InvestmentCard>
              </>
            );
          })}
        </BoxList>
      </OnEmptyOrList>
    </>
  );
}

export default InvestmentFinished;
