import React from 'react';
import ContentBase from '../../templates/ContentBase';
import CardStartup from '../../../components/CardStartup';

function Dashboard() {
  return (
    <ContentBase
      title="Dashboard"
      questionIsVisible={false}
      content={<CardStartup />}
    />
  );
}

export default Dashboard;
