import styled, { css } from 'styled-components';
import { Button, Card as CardComponent, Modal } from 'antd';
import closeIcon from './assets/icons/close_icon.svg';

const ModalRef = Modal;

const BoxContainer = styled.div<{
  noPadding?: boolean;
  width?: string;
  flexDirection?: string;
  flexWrap?: string;
  flex?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
}>`
  display: ${({ flex }) => (flex ? flex : 'flex')};
  gap: ${({ gap }) => gap};
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  flex-wrap: ${({ flexWrap = '' }) => flexWrap};
  width: ${({ width = 'auto' }) => width};
  padding: ${({ noPadding }) => (noPadding ? '' : '10px 10px')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : '')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : ''};
  gap: ${({ gap }) => (gap ? gap : '0')};
`;

const BoxWhite = styled.div<{
  width?: string;
  height?: string;
  noPadding?: boolean;
  noShadow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  background-color: var(--white-color);
  border: 1px solid var(--grey-color);
  border-radius: 6px;
  padding: ${({ noPadding }) => (noPadding ? '' : '10px 10px')};
  box-shadow: ${({ noShadow }) =>
    noShadow ? '' : '0 0 8px 0 rgba(0,0,0,0.2)'};
  margin: 5px 0;
`;

const PurpleButton = styled(Button)<{
  weight?: number;
  isOutline?: boolean;
  width?: string;
  maxWidth?: string;
  display?: string;
  margin?: string;
}>`
  border: 0;
  color: var(--white-color);
  background-color: var(--thirdy-color) !important;
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};

  & span {
    font-weight: ${({ weight }) => weight};
  }

  ${({ isOutline }) =>
    isOutline &&
    css`
      background-color: transparent;
      color: var(--thirdy-color);
      border: 1px solid;
    `}

  &:hover {
    color: var(--white-color);
    filter: brightness(0.9);
  }
`;

const ButtonContainer = styled.div`
  float: left;
  width: 100%;

  .ant-btn-primary {
    background-color: var(--primary-color);
    border: none;
  }
`;

const ModalBody = styled(ModalRef)<{
  paddingBody?: string;
  maxWidth?: string;
  margin?: string;
  closeIconColor?: string;
}>`
  &.ant-modal .ant-modal-content {
    max-width: ${({ maxWidth }) => maxWidth};
    margin: ${({ margin }) => margin};
    width: ${(props) => (props.width ? props.width : '400px')};
  }

  &.ant-modal .ant-modal-content .ant-modal-header {
    padding: 10px 30px;
  }

  &.ant-modal .ant-modal-content .ant-modal-body {
    padding: ${({ paddingBody }) => (paddingBody ? paddingBody : '10px')};
  }

  &.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: var(--font-color);
    font-weight: bold;
    font-size: 1.6em;
    text-align: center;
  }

  &.ant-modal .ant-modal-content button .ant-modal-close-x {
    width: 40px;
    height: 44px;
    line-height: 46px;
    color: ${({ closeIconColor }) => closeIconColor};
  }

  &.ant-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    :hover {
      border-color: var(--border-color);
      color: var(--border-color);
    }
  }

  &.ant-picker {
    width: 100%;
  }

  & .ant-btn-default {
    border: 1px solid var(--primary-color);
    border-radius: 6px;

    & span {
      font-size: 0.8em;
    }

    &:hover {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  & .ant-btn-primary {
    padding: 0 40px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;

    & span {
      font-size: 0.8em;
    }

    &:hover,
    :focus {
      & span {
        color: var(--white-color);
      }
      background-color: var(--font-color) !important;
    }
  }

  & .ant-modal-content {
    width: 100% !important;
  }

  /* & .itjhbu.ant-modal .ant-modal-content {
    
    } */
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--font-color);
  text-align: center;
  align-items: center;
`;

const BoxName = styled.div`
  margin-top: 2px;
  width: 80px;
  text-transform: capitalize;
  font-size: 0.8em;
  text-align: center;
`;

const IconOptions = styled.div<{
  size?: string;
  cursor?: string;
  display?: string;
}>`
  display: ${({ display }) => display};
  & svg {
    cursor: ${({ cursor }) => cursor};
    font-size: ${({ size }) => size};
  }
`;

const ButtonCloseDrawer = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;

  & span {
    opacity: 0;
    font-size: 15px;
    transition: 0.4s;
    font-weight: 600;
    color: var(--primary-color);
  }

  &:hover span {
    opacity: 1;
  }

  &::after {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    background-size: cover;
    background-image: url(${closeIcon});
  }

  @media (max-width: 600px) {
    & span {
      display: none;
    }
  }
`;

// quebra de pagina (media print)
const PageBreak = styled.div`
  page-break-after: always;
`;

const MenuOption = styled.div<{
  disable?: boolean;
}>`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  min-width: 170px;
  padding: 5px 10px;
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
  opacity: ${({ disable }) => (disable ? '0.5' : '1')};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  & p {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
  }
`;

//create menu dropdown
const MenuDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 10px;
  right: -10px;
  background-color: var(--white-color);
  border-radius: 1px;
  padding: 5px 0;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white-color);
  }

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary-color);
  }

  & ${MenuOption} {
    background-color: var(--white-color);
    color: var(--primary-color);
  }

  & ${MenuOption}:hover {
    background-color: var(--quaternary-color);
    color: var(--white-color);
  }

  & ${MenuOption}:hover p {
    color: var(--white-color);
    font-weight: bold;
  }
`;

export type IParamsCard = {
  padding?: string;
  bgColor?: string;
  boxShadow?: string;
};

const Card = styled(CardComponent)<IParamsCard>`
  & .ant-card-body {
    padding: ${({ padding }) => padding || '12px'};
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    border-radius: 5px;
  }

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
`;

export interface IPropsContainer {
  paddingVertical?: string;
  width?: string;
}

const Container = styled.div<IPropsContainer>`
  max-width: 1200px;
  margin: 0 auto;
  width: ${({ width }) => width};
  padding: ${({ paddingVertical = '10px' }) => `${paddingVertical} 20px`};

  @media (min-width: 1600px) {
    max-width: 1400px;
  }

  @media (max-width: 500px) {
    padding: ${({ paddingVertical = '10px' }) => `${paddingVertical} 1rem`};
  }
`;

const MediaQueryVisible = styled.div<{
  maxWidth?: string;
  minWidth?: string;
  display: string;
}>`
  ${({ maxWidth, display }) =>
    maxWidth &&
    css`
      @media (max-width: ${maxWidth}) {
        display: ${display};
      }
    `}

  ${({ minWidth, display }) =>
    minWidth &&
    css`
      @media (min-width: ${minWidth}) {
        display: ${display};
      }
    `}
`;

const Label = styled.div<{
  bgColor?: string;
  color?: string;
}>`
  background: ${({ bgColor = '#ffffff' }) => bgColor};
  border: 1px solid #9aa4a8;
  border-radius: 13px;
  padding: 0px 6px;
  font-size: 14px;
  max-width: max-content;
  color: ${({ color = 'var(--primary-color)' }) => color};
`;

export {
  Label,
  MediaQueryVisible,
  Container,
  Card,
  PageBreak,
  IconOptions,
  BoxContainer,
  BoxName,
  ButtonContainer,
  ModalBody,
  MenuOption,
  PurpleButton,
  ButtonCloseDrawer,
  ModalContent,
  MenuDropdown,
  BoxWhite,
};
