import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ICourseShowCase from '../core/interfaces/course/ICourseShowCase';
import IPageable from '../core/interfaces/IPageable';
import CourseService from '../services/course/CourseService';
import CrudService from '../services/CrudService';

type IState = {
  courses: IPageable<ICourseShowCase>;
};

const initialState: IState = {
  courses: CrudService.pageableDefault(),
};

const name = 'registered-courses';

export const fetchCourses = createAsyncThunk(
  `${name}/getCourses`,
  async (params: Record<string, unknown>) => {
    const data = await CourseService.init().showCase({
      size: 8,
      hasNoPillar: true,
      ...params,
    });
    return data;
  },
);

const registeredCoursesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      state.courses = action.payload;
    });
  },
});

export const registeredCoursesReducer = registeredCoursesSlice.reducer;
