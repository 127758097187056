import useLoading from './useLoading';
import { notification } from '../../helpers/notification.helper';
import { useMemo, useEffect, useCallback, useState } from 'react';
import IProgressStartup, {
  IStagesStartupProgress,
} from '../interfaces/dashboard-startup/IDashboardStartup';
import DashboardStartupService from '../../services/dashboard-startup/DashboardStartupService';

const useStartupProgress = (userId?: number) => {
  const { loading, hideLoading, showLoading } = useLoading();
  const [activePhase, setActivePhase] = useState<IStagesStartupProgress>();
  const [progress, setProgress] = useState<IProgressStartup>({
    details: null,
    phases: [],
  });

  const dashboardStartupService = useMemo(
    () => DashboardStartupService.init(),
    [],
  );

  const getProgress = useCallback(() => {
    if (!userId) return;
    showLoading();
    dashboardStartupService
      .getProgress(Number(userId))
      .then((data) => {
        setProgress(data);
        setActivePhase(data.phases.find((phase) => phase.isCurrentPhase));
      })
      .catch(() => notification.info('Dados do progresso não encontrado.'))
      .finally(hideLoading);
  }, [showLoading, dashboardStartupService, userId, hideLoading]);

  const changeActivePhase = (value: IStagesStartupProgress): void => {
    setActivePhase(value);
  };

  useEffect(() => {
    getProgress();
  }, [getProgress]);

  return { progress, activePhase, loading, changeActivePhase };
};

export default useStartupProgress;
