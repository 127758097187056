import styled from 'styled-components';

export const ButtonIconRound = styled.button<{
  color: string;
  textColor: string;
  width: string;
  height: string;
  typeShape: string;
}>`
  width: 40px;
  height: 40px;
  color: ${(props) => props.textColor};
  border-radius: 50%;
  border: ${(props) =>
    props.typeShape === 'default' ? 'none' : '1px dashed #000000'};
  background-color: var(--${({ color }) => color}-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  &:hover {
    background-color: var(--${({ color }) => color}-color-hover);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: var(--${({ color }) => color}-text-color-hover);
  }
`;
