import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BoxContainer, BoxWhite } from '../../../GlobalStyles';
import ProfileBase from '../../../components/Profiles/ProfilesBase';
import { IStartup } from '../../../core/interfaces';
import Text from '../../../components/Text';
import { FormInstance, notification } from 'antd';
import Title from '../../../components/Title';
import iconVideos from '../../../assets/images/flie-videos.svg';
import iconPdf from '../../../assets/images/file-pdf-solid-240.png';
import { FileList } from './style';
import FileService from '../../../services/FileService';
import InvestmentRoundService from '../../../services/InvestmentRoundService';
import IInvestmentRoundStartup from '../../../core/interfaces/IInvestmentRoundStartup';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import { convertCurrencyMaskInputToNumber } from '../../../helpers/convertion.helper';
import { useAuth } from '../../../core/hooks/auth';

function SubmittedStartups() {
  const { loading, hideLoading, showLoading } = useLoading();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { logged } = useAuth();

  const [startup, setStartup] = useState<IStartup>();
  const fileService = useMemo(() => FileService.init(), []);
  const investmentRoundService = useMemo(
    () => InvestmentRoundService.init(),
    [],
  );
  const [investmentRondStartup, setInvestmentRondStartup] =
    useState<IInvestmentRoundStartup>();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const { investmentRoundId, startupId } = state;

  const getData = useCallback(() => {
    showLoading();
    investmentRoundService
      .getInvestmentRoundStartupByInvestmentRoundIdAndStartupId(
        investmentRoundId,
        startupId,
      )
      .then((response) => {
        setStartup(response.startup);
        setInvestmentRondStartup(response);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar startup',
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [state, investmentRoundService]);

  const openFile = (srcImage: string) => {
    showLoading();
    fileService
      .openByName(srcImage)
      .then((response) => {
        notification.success({
          message: 'Arquivo aberto com sucesso',
        });
      })
      .catch(() => {
        hideLoading();
        notification.error({
          message: 'Erro ao abrir arquivo',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const getInvestmentRound = (
    values: FormInstance,
  ): IInvestmentRoundStartup => {
    const valueMap = new Map(Object.entries(values));
    const result: IInvestmentRoundStartup = {
      ...investmentRondStartup,
      ...{
        investmentValue: convertCurrencyMaskInputToNumber(
          valueMap.get('investmentValue'),
        ),
        equity: convertCurrencyMaskInputToNumber(valueMap.get('equity')),
        comment: valueMap.get('comment'),
      },
    };

    return result;
  };

  const getItem = (label: string, description: string, val: string) => {
    return (
      <BoxWhite width={'50%'}>
        <Title size={18} weight={700}>
          {label}
        </Title>
        <BoxContainer
          noPadding={true}
          flexDirection="row"
          justifyContent={'space-between'}
        >
          <Text>{description}</Text>
          <Text
            align={'center'}
            style={{ width: '20%' }}
            color={'var(--thirdy-color)'}
            weight={700}
          >
            {val}
          </Text>
        </BoxContainer>
      </BoxWhite>
    );
  };

  const loadListFiles = (label: string, icon: string, name: string) => {
    return (
      <div className="box-file" onClick={() => openFile(name)}>
        <img alt="" src={icon} />
        <Text>{label}</Text>
      </div>
    );
  };

  const onFinish = (value: FormInstance) => {
    if (!investmentRondStartup) {
      notification.warning({
        message: 'Não foi possível enviar o sinal de interesse',
      });

      return;
    }
    investmentRoundService
      .sendSignalInvestment(
        Number(investmentRondStartup?.entityId),
        getInvestmentRound(value),
      )
      .then(() => {
        notification.success({
          message: 'Sinal de interesse enviado com sucesso',
          description: 'Aguarde a aprovação da startup',
        });
        setModalVisible(!modalVisible);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao enviar sinal de interesse',
          description: 'Tente novamente mais tarde',
        });
      });
  };

  const showModal = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BoxContainer>
      <Loading position={'absolute'} spinning={loading}>
        <ProfileBase
          goBack={() => {
            navigate(-1);
          }}
          title="Startup Cadastrada"
          entity={startup}
          submitForm={(values: any) => onFinish(values)}
          showModal={showModal}
          recipientName={startup?.owner.name}
          recipientId={startup?.owner?.entityId}
          senderId={logged?.entityId}
          senderName={logged?.name}
          isVisible={modalVisible}
        />
        <BoxWhite>
          <Title size={18} weight={700}>
            Arquivos pitch
          </Title>
          <FileList>
            {loadListFiles(
              'Video do pitch',
              iconVideos,
              investmentRondStartup?.videoFileName || '',
            )}
            {loadListFiles(
              'PDF do pitch',
              iconPdf,
              investmentRondStartup?.fileName || '',
            )}
          </FileList>
        </BoxWhite>
        <BoxContainer noPadding={true} flexDirection={'row'} gap={'10px'}>
          {getItem(
            'Maturidade atual da startup',
            startup?.currentPhase?.description || '',
            startup?.currentPhase?.name || '',
          )}
          {getItem(
            'Valation Pré-money atual da startup',
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            'R$ 200000,00',
          )}
        </BoxContainer>
      </Loading>
    </BoxContainer>
  );
}

export default SubmittedStartups;
