import { Select } from 'antd';
import styled from 'styled-components';

const WrapperEvidence = styled.div<{
  minWidth?: string;
}>`
  gap: 1rem;
  padding: 9px;
  display: flex;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #ddd;
  justify-content: flex-start;
  cursor: pointer;
  min-width: ${({ minWidth }) => minWidth};

  &:hover {
    box-shadow: 0 0 0 1px var(--primary-color);
  }
`;

const CustomSelect = styled(Select)`
  border-radius: 4px;
  padding: 2px;

  &:hover {
    background-color: #05233d0d;
  }
`;

export { CustomSelect, WrapperEvidence };
