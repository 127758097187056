import { Col } from 'antd';
import Text from '../Text';
import { CustomRow } from './styles';

interface HighlightCardProps {
  title: string;
  content: string;
}

export function HighlightCard(props: HighlightCardProps) {
  return (
    <CustomRow justify="space-between" align="middle">
      <Col>
        <Text weight={700}>{props.title}</Text>
      </Col>

      <Col>
        <Text color="var(--thirdy-color)" weight={700}>
          {props.content}
        </Text>
      </Col>
    </CustomRow>
  );
}
