import CrudService from './CrudService';
import api from '../core/services/api';
import { errorCallback } from '../helpers/promise.helper';

class MethodologyService extends CrudService {
  constructor() {
    super({
      requestMapping: '/methodology',
    });
  }

  public static init(): MethodologyService {
    return new MethodologyService();
  }

  public verifyMethodology(id: number): Promise<boolean> {
    return api
      .get<boolean>(`${this.requestMapping}/verify-methodology/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default MethodologyService;
