import IEditStartup from '../../core/interfaces/IEditStartup';
import IEntrepreneurData from '../../core/interfaces/IEntrepreneurData';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class ProfileDashboardService {
  private baseUrl = 'profile-dashboard';

  public static init() {
    return new ProfileDashboardService();
  }

  private getUrl(path: string): string {
    return `${this.baseUrl}/${path}`;
  }

  public getBusinessInformation(userId: number) {
    return api
      .get(this.getUrl(`business-information/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public editBusinessInformation(
    userId: number,
    request: Record<string, unknown>,
  ) {
    return api
      .put(this.getUrl(`business-information/${userId}`), request)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public editStartupProfile(userId: number, request: Record<string, unknown>) {
    return api
      .put(this.getUrl(`edit-startup/${userId}`), request)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getEditStartup(userId: number): Promise<IEditStartup> {
    return api
      .get<IEditStartup>(this.getUrl(`edit-startup/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getEntrepreneurData(userId: number): Promise<IEntrepreneurData> {
    return api
      .get<IEntrepreneurData>(this.getUrl(`entrepreneur-data/${userId}`))
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ProfileDashboardService;
