import React from 'react';
import { Popconfirm } from 'antd';
import IPopover from '../../core/interfaces/types/IPopover';

interface Props {
  title?: string;
  boxPosition?: IPopover;
  onConfirm(): void;
  okText?: string;
  cancelText?: string;
  children: React.ReactNode;
}

function ConfirmAction({
  onConfirm,
  title = 'Deseja remover este item?',
  boxPosition = 'top',
  okText = 'Sim',
  cancelText = 'Não',
  children,
}: Props) {
  return (
    <Popconfirm
      placement={boxPosition}
      title={title}
      onConfirm={onConfirm}
      okText={okText}
      cancelText={cancelText}
    >
      {children}
    </Popconfirm>
  );
}

export default ConfirmAction;
