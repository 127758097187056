import styled from 'styled-components';
import { Checkbox as CheckboxAntd, Collapse } from 'antd';

const PanelItem = styled(Collapse.Panel)`
  padding: 0.5rem 0;
  box-shadow: 0 2px #dfdfdf;

  & > div {
    padding-left: 0 !important;
  }

  @media (max-width: 767px) {
    & .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

const PanelChildrenItem = styled.div`
  padding: 1rem 0.5rem;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #00498726;
  }

  @media (max-width: 767px) {
    .ant-row {
      & > div:nth-child(1) {
        order: 1;
      }

      & > div:nth-child(3) {
        order: 2;
      }

      & > div:nth-child(2) {
        order: 3;
      }
    }
  }
`;

const Checkbox = styled(CheckboxAntd)`
  & .ant-checkbox-inner {
    border-color: #072e4f;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #960096;
    border-color: #960096;
  }
`;

export { Checkbox, PanelItem, PanelChildrenItem };
