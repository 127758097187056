import styled from 'styled-components';

const ButtonAction = styled.div`
  position: relative;

  & .addItem {
    position: absolute;
    left: 50%;

    :hover {
      background-color: var(--primary-color);

      svg {
        color: var(--white-color);
        transform: rotate(90deg);
        transition-duration: 500ms;
      }
    }
  }

  & .removeItem {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    cursor: pointer;

    :hover {
      svg {
        font-size: 18px;
        transition-duration: 500ms;
      }
    }
  }
`;

const BoxCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: 10px;

  span {
    cursor: pointer;
  }
`;

export { ButtonAction, BoxCheckbox };
