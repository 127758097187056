import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  & .description {
    margin-bottom: 10px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;

  & .column {
    flex: 0 50%;
  }

  .content {
    margin: 10px 10px 10px 10px;
    width: 100%;

    .row {
      width: 100%;
    }
  }
`;

const PhasesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border: 1px solid #e1dddd;
  border-radius: 8px;

  & .item {
    position: relative;
    width: 100%;
    padding: 10px 5px 10px 5px;
    border-bottom: 1px solid var(--grey-color);

    & .name {
      width: calc(100% - 24px);
    }

    & .ico {
      position: absolute;
      top: 5px;
      right: 0;

      svg {
        font-size: 20px;
      }
    }
  }
`;

const Stages = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  & .name,
  .boxPhases {
    display: flex;
    flex-direction: column;
  }

  & .hiddenButton {
    display: none;
  }

  & .boxPhases .item {
    width: fit-content;
    padding: 4px 8px;
    border: 1px solid var(--support-color);
    border-radius: 6px;
    margin-bottom: 4px;
    margin-right: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;

  & img {
    width: 100px;
  }

  & div {
    text-align: justify;
  }
`;

const BoxButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 0 auto;
  margin-top: 20px;
`;

export { Box, BoxButton, BoxContent, PhasesList, Header, Stages };
