import { Col, Row } from 'antd';
import React, { FC } from 'react';
import Text from '../../../../../components/Text';
import IDashboardMetricStartup from '../../../../../core/interfaces/dashboard-startup/IDashboardMetricStartup';
import { ProgressBar, Wrapper } from './styles';

type Props = IDashboardMetricStartup;

function OkrProgressCard({ objectives, keyResult, percentage }: Props) {
  const style: React.CSSProperties = {
    color: '#960096',
  };

  return (
    <Wrapper>
      <Text>
        <span style={style} data-bold="true">
          Eu vou
        </span>{' '}
        {objectives}
      </Text>
      <Text>
        <span style={style} data-bold="true">
          Medido por
        </span>{' '}
        {keyResult}
      </Text>

      <Row gutter={16} align="middle" style={{ marginTop: '1rem' }}>
        <Col flex={1}>
          <ProgressBar width={percentage} />
        </Col>
        <Col>{percentage.toFixed(1)}%</Col>
      </Row>
    </Wrapper>
  );
}

export default OkrProgressCard;
