import React, { useEffect, useState } from 'react';
import { BoxContainer } from './style';
import TabGray from '../../../components/tab/tabGray';
import BodySlider from './BodySlider';

interface IParams {
  setNext?: (next: string) => void;
  next?: string;
  params?: any;
  setFormName?: (formName: string) => void;
  setAsk?: (ask: boolean) => void;
  formName?: string;
  closeModal?: () => void;
}

function AnalyzeRisk(props: IParams) {
  const [tab, setTab] = React.useState('0');

  const AnalyzeRisk = [
    {
      tab: 'Risco de Mercado',
      body: (
        <BodySlider
          next={'0'}
          setNext={setTab}
          id={14}
          tabName="Risco de Mercado"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
    {
      tab: 'Risco de Inovação',
      body: (
        <BodySlider
          next={'1'}
          setNext={setTab}
          id={15}
          tabName="Risco de Inovação"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
    {
      tab: 'Risco de Empreendedor',
      body: (
        <BodySlider
          next={'2'}
          id={16}
          setNext={setTab}
          tabName="Risco de Empreendedor"
          params={props.params}
          setFormName={props.setFormName}
          closeModal={props.closeModal}
        />
      ),
    },
  ];

  useEffect(() => {
    if (tab === '3') {
      props.setNext && props.setNext((Number(props.next) + 1).toString());
    }
  }, [tab]);

  return (
    <BoxContainer>
      <TabGray
        content={AnalyzeRisk}
        tabKey={tab}
        setAsk={props.setAsk}
        formName={props.params.formName}
      />
    </BoxContainer>
  );
}

export default AnalyzeRisk;
