import { useEffect } from 'react';
import { Col, Form, FormInstance, Input, notification, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../core/hooks/auth';
import MentorService from '../../../../../services/MentorService';
import useLoading from '../../../../../core/hooks/useLoading';
import { IMentor } from '../../../../../core/interfaces/IMentor';
import ContentForm from '../ContentForm';
import Loading from '../../../../../components/Loading/Loading';

interface IProps {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  setFormName: (value: string) => void;
}

function ScreenMentor(props: IProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { logged } = useAuth();
  const mentorService = MentorService.init();
  const { loading, showLoading, hideLoading } = useLoading();

  const arrageValues = (values: FormInstance) => {
    const valuesMap = new Map(Object.entries(values));

    const result: IMentor = {
      entityId: logged?.entityId,
      name: valuesMap.get('name'),
      email: logged?.email as string,
      cellPhone: valuesMap.get('cellPhone'),
      cpf: valuesMap.get('cpf'),
      linkLattes: valuesMap.get('linkLattes'),
      linkLinkedin: valuesMap.get('linkLinkedin'),
      active: true,
      profileId: 4,
      userId: logged?.entityId as number,
    };

    return result;
  };

  const onFinish = (values: FormInstance) => {
    showLoading();
    mentorService
      .createMentor(arrageValues(values))
      .then((response) => {
        notification.success({
          message: 'Mentor cadastrado com sucesso',
          description:
            'Agora você pode acessar o sistema com seu perfil de mentor',
        });
        props.setCurrent(props.current + 1);
        localStorage.setItem('@innovation:auth', response.jwttoken);
        navigate('/register/conclude/profile/final');
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao cadastrar mentor',
          description: 'Verifique se os dados estão corretos e tente novamente',
        });
      })
      .finally(hideLoading);
  };

  const setInitialValues = () => {
    form.setFieldsValue({
      name: logged?.name,
    });
  };

  useEffect(() => {
    setInitialValues();
    props.setFormName('formMentor');
  }, []);

  return (
    <Loading spinning={loading} width={300} height={300}>
      <ContentForm
        title="Mentor"
        subTitle="Preencha os dados abaixo para continuar"
        description="Dados pessoais"
        content={
          <Form
            form={form}
            name="formMentor"
            onFinish={onFinish}
            layout="vertical"
            labelWrap
          >
            <Row align="bottom" gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Qual o seu nome?"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, preencha o seu nome',
                    },
                  ]}
                >
                  <Input placeholder="Nome" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label="CPF"
                  name="cpf"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, preencha o seu CPF',
                    },
                    ({ getFieldValue }) => ({
                      validator(_) {
                        if (getFieldValue('cpf').includes('_')) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'CPF inválido',
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="000.000.000-00"
                    placeholder="000.000.000-00"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label="Numero de whatsapp"
                  name="cellPhone"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, preencha o seu numero de whatsapp',
                    },
                    {
                      pattern: /^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/,
                      message: 'Numero de whatsapp inválido',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="(00) 00000-0000"
                    placeholder="(00) 00000-0000"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="bottom" gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Link Lattes"
                  name="linkLattes"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, preencha o seu link Lattes',
                    },
                  ]}
                >
                  <Input placeholder="Link Lattes" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Link Linkedin"
                  name="linkLinkedin"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, preencha o seu link Linkedin',
                    },
                  ]}
                >
                  <Input placeholder="Link Linkedin" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </Loading>
  );
}

export default ScreenMentor;
