import React, { useEffect, useState } from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import api from '../../../../core/services/api';
import { IStartup } from '../../../../core/interfaces';
import TextArea from 'antd/lib/input/TextArea';
import notificationMessage from '../../../notifications/notificationMessage';
import ErrorFormNotification from '../../../notifications/errorFormNotification';
import { useNavigate } from 'react-router-dom';
import IModel from '../../../../core/interfaces/models/IModel';

interface IParams {
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setButtonState: (buttonState: boolean) => void;
  setFormName: (formName: string) => void;
  initialValues?: any;
}

interface IUF extends IModel {
  uf: string;
  name: string;
}

export interface IBusinessTypes extends IModel {
  description: string;
}

export interface IBusinessModels extends IModel {
  description: string;
}

function EntrepreneurForm(props: IParams) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [ufs, setUfs] = useState<IUF[]>([]);
  const [businessTypes, setBusinessTypes] = useState<IBusinessTypes[]>([]);
  const [businessModels, setBusinessModels] = useState<IBusinessModels[]>([]);

  const navigate = useNavigate();

  const arrangeValues = (values: FormInstance) => {
    const valuesMap = new Map(Object.entries(values));

    const result: IStartup = {
      problem: valuesMap.get('problem'),
      resolution: valuesMap.get('resolution'),
      cnpj: valuesMap.get('cnpj'),
      name: valuesMap.get('startupName'),
      state: {
        entityId: valuesMap.get('state'),
      },
      businessTypes: {
        entityId: valuesMap.get('businessType'),
      },
      businessModels: {
        entityId: valuesMap.get('businessModel'),
      },
      owner: {
        entityId: props.initialValues.entityId,
        email: props.initialValues?.email,
        cellPhone: props.initialValues?.cellPhone,
        name: props.initialValues?.name,
        office: props.initialValues?.office,
        area: props.initialValues?.area,
        cpf: props.initialValues?.cpf,
        profiles: [
          {
            id: 3,
          },
        ],
      },
    };

    return result;
  };

  const onFinish = async (values: FormInstance) => {
    api
      .post('/startup', arrangeValues(values))
      .then((response) => {
        notificationMessage('create');
        props.setCurrent(props.current + 1);
        localStorage.setItem('@innovation:auth', response.data.jwttoken);
        navigate('/register/conclude/profile/final');
      })
      .catch((error) => {
        ErrorFormNotification(form, error.response.data.message);
      });
  };

  const getStates = () => {
    api.get<IUF[]>('state').then((response) => {
      setUfs(response.data);
    });
  };

  const getBusinessType = () => {
    api.get<IBusinessTypes[]>('businessTypes').then((response) => {
      setBusinessTypes(response.data);
    });
  };

  const getBusinessModel = () => {
    api.get<IBusinessModels[]>('businessModels').then((response) => {
      setBusinessModels(response.data);
    });
  };

  useEffect(() => {
    props.setFormName('formStartupEntrepreneur');
    getStates();
    getBusinessType();
    getBusinessModel();
  }, []);

  return (
    <Form
      name={'formStartupEntrepreneur'}
      onFinish={onFinish}
      form={form}
      layout={'vertical'}
      autoComplete={'off'}
      labelWrap={true}
    >
      <Row gutter={[8, 8]} align={'bottom'}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name={'startupName'}
            label="Qual o nome da sua startup?"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="Nome da startup" maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <Form.Item label="CNPJ" name={'cnpj'}>
            <MaskedInput mask={'00.000.000/0000-00'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <Form.Item
            name="state"
            label="Qual a UF da sua organização?"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Selecione o estado"
              optionFilterProp="children"
            >
              {ufs.map((state, index) => (
                <Option value={state.entityId} key={index}>
                  {state?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} align="bottom">
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={'businessModel'}
            label="Como seu modelo de negócio pode ser classificado?"
            rules={[
              {
                required: true,
                message: 'Modelo de negócio é um campo obrigatório!',
              },
            ]}
          >
            <Select showSearch placeholder="Selecione o modelo de negócio">
              {businessModels.map((businessModel, index) => (
                <Option value={businessModel.entityId} key={index}>
                  {businessModel?.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="businessType"
            label="Como seu negócio pode ser classificado?"
            rules={[
              {
                required: true,
                message: 'Tipo de negócio é um campo obrigatório!',
              },
            ]}
          >
            <Select showSearch placeholder="Selecione o tipo de negócio">
              {businessTypes.map((businessType, index) => (
                <Option value={businessType.entityId} key={index}>
                  {businessType?.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} align={'bottom'}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={'problem'}
            label="Qual problema sua startup resolve e quem é impactado por ele?"
            rules={[
              {
                required: true,
                message: 'Descrição do problema é um campo obrigatório!',
              },
              {
                max: 255,
                message: 'O campo deve possuir no máximo 255 caracteres',
              },
            ]}
          >
            <TextArea
              showCount
              rows={2}
              placeholder="Descrição do problema"
              maxLength={255}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={'resolution'}
            label="Como sua startup resolve esse problema?"
            rules={[
              {
                required: true,
                message: 'Resolução do problema é um campo obrigatório!',
              },
              {
                max: 255,
                message: 'O campo deve possuir no máximo 255 caracteres',
              },
            ]}
          >
            <TextArea
              showCount
              rows={2}
              placeholder="Resolução do problema"
              maxLength={255}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default EntrepreneurForm;
