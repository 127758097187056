import styled from 'styled-components';

const HeaderInformationBox = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 16px;
  grid-template-columns: 1fr auto;
`;

const ContentGrid = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  grid-template-columns: 1fr auto;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;

    & > p:nth-child(1) {
      order: 2;
    }

    & > p:nth-child(2) {
      order: 1;
    }
  }
`;

export { HeaderInformationBox, ContentGrid };
