import styled from 'styled-components';

export const ContainerContent = styled.div`
  padding: 1.375rem;
  background-color: #fff;
  box-shadow: 0px 3px 6px #05233d29;
`;

export const StartupListWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const MoreItemsWrapper = styled.div`
  display: grid;
  place-items: center;

  margin-top: 2rem;
`;
