import React, { Dispatch, SetStateAction } from 'react';
import Profiles from '../../../../components/form/profiles/profiles';
import PlanCards from './PlanCards';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  setFinish: Dispatch<SetStateAction<() => void>>;
}

function Plans(props: IParams) {
  const profilePlan = [
    {
      title: 'Startup',
      subTitle:
        'Escolha o melhor plano para acompanhar o desenvolvimento de sua startup',
      description: 'Planos para startup',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
    {
      title: 'Startup',
      subTitle:
        'Escolha o melhor plano para acompanhar o desenvolvimento de sua startup',
      description: 'Planos para startup',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
    {
      title: 'Startup',
      subTitle:
        'Escolha o melhor plano para acompanhar o desenvolvimento de sua startup',
      description: 'Planos para startup',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
    {
      title: 'Aluno',
      subTitle:
        'Escolha qual plano mais se encaixa para você mas lembre-se que poderá altera-lo a qualquer momento dentro da plataforma.',
      description: 'Planos para aluno',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
    {
      title: 'Startup',
      subTitle:
        'Escolha o melhor plano para acompanhar o desenvolvimento de sua startup',
      description: 'Planos para startup',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
    {
      title: 'Startup',
      subTitle:
        'Escolha o melhor plano para acompanhar o desenvolvimento de sua startup',
      description: 'Planos para startup',
      content: (
        <PlanCards
          profileId={props.profileId}
          setButtonState={props.setButtonState}
        />
      ),
    },
  ];

  return (
    <Profiles
      title={profilePlan[props.profileId - 1].title}
      subTitle={profilePlan[props.profileId - 1].subTitle}
      description={profilePlan[props.profileId - 1].description}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setFormName={() => {}}
      current={props.current}
      setCurrent={props.setCurrent}
      setButtonState={props.setButtonState}
      content={profilePlan[props.profileId - 1].content}
    />
  );
}

export default Plans;
