import React from 'react';
import Text from '../../Text';
import Title from '../../Title';
import { Col, Collapse, Row } from 'antd';
import { FiFileText } from 'react-icons/fi';
import { MdOndemandVideo } from 'react-icons/md';
import { CaretRightOutlined } from '@ant-design/icons';
import ITopic from '../../../core/interfaces/course/ITopic';
import ILesson from '../../../core/interfaces/course/ILesson';
import { PanelChildrenItem, PanelItem, Checkbox } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAmountOfExercisesPerformed,
  getColorExercise,
  getLabelExercise,
  getNumberOfClassesAttended,
} from '../../../helpers/course.helper';
import { isNull } from 'lodash';

type Props = {
  topics: ITopic[];
  selectLesson(lesson: ILesson): void;
};

function TopicAccordion({ topics, selectLesson }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  let lessonIndex = 0;

  return (
    <Collapse
      bordered={false}
      ghost={true}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? -90 : 90} />
      )}
      expandIconPosition="end"
    >
      {topics.map((topic, index) => (
        <PanelItem
          key={index}
          header={
            <>
              <Title sizeXs={20} size={24}>
                {topic.name}
              </Title>
              <Row gutter={16}>
                <Col>
                  <Row align={'middle'}>
                    <MdOndemandVideo color="#05233D" size={19} />
                    <Text margin="0 0 0 0.5rem">
                      {getNumberOfClassesAttended(topic)}/{topic.lessons.length}{' '}
                      | 20min
                    </Text>
                  </Row>
                </Col>
                <Col>
                  <Row align={'middle'}>
                    <FiFileText color="#05233D" size={19} />
                    <Text margin="0 0 0 0.5rem">
                      {getAmountOfExercisesPerformed(topic)}/
                      {topic.exercises.length} exercícios
                    </Text>
                  </Row>
                </Col>
              </Row>
            </>
          }
        >
          {topic.lessons.map((lesson, index) => {
            lessonIndex++;
            const name = `Aula ${lessonIndex}: ${lesson.name}`;
            const isComplete = Boolean(lesson.isComplete);

            return (
              <PanelChildrenItem
                key={`lesson_item_${index}`}
                onClick={() => selectLesson({ ...lesson, name })}
              >
                <Row gutter={12} align="top">
                  <Col>
                    <Checkbox value={isComplete} checked={isComplete} />
                  </Col>
                  <Col xs={24} sm={24} md={20}>
                    <Text sizeXs={16} size={19} weight={700}>
                      {name}
                    </Text>
                  </Col>
                  <Col>
                    <Row
                      wrap={false}
                      align="middle"
                      style={{ marginRight: '1rem' }}
                    >
                      <MdOndemandVideo color="#05233D" size={19} />
                      <Text margin="0 0 0 0.5rem">0 min</Text>
                    </Row>
                  </Col>
                </Row>
              </PanelChildrenItem>
            );
          })}
          {topic.exercises.map((exercise, index) => {
            const { entityId, grade, name } = exercise;
            const isComplete = !isNull(grade);

            return (
              <PanelChildrenItem
                key={`exercise_item_${index}`}
                onClick={() =>
                  navigate(
                    `/courses-startup/attend-course/${id}/exercise/${entityId}`,
                  )
                }
              >
                <Row gutter={12} align="top">
                  <Col>
                    <Checkbox value={isComplete} checked={isComplete} />
                  </Col>
                  <Col xs={24} sm={24} md={20}>
                    <Text sizeXs={16} size={19} weight={700}>
                      Atividade: {name}
                    </Text>
                  </Col>
                  <Col>
                    <Text
                      margin="0 1rem 0 0"
                      weight={600}
                      color={getColorExercise(exercise)}
                    >
                      {getLabelExercise(exercise)}
                    </Text>
                  </Col>
                </Row>
              </PanelChildrenItem>
            );
          })}
        </PanelItem>
      ))}
    </Collapse>
  );
}

export default TopicAccordion;
