import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IBusinessType } from '../core/interfaces/IBusinessTypel';

class BusinessTypeService extends CrudService {
  static init(): BusinessTypeService {
    return new BusinessTypeService();
  }

  constructor() {
    super({
      requestMapping: '/businessTypes',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IBusinessType[]> {
    return api
      .get<IBusinessType[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(businessType: IBusinessType): Promise<IBusinessType> {
    return api
      .post<IBusinessType>(`${this.requestMapping}`, businessType)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default BusinessTypeService;
