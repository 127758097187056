import styled from 'styled-components';

export const InvestmentLargeCardContainer = styled.div`
  background: linear-gradient(to right, #05233d, #36175c);
  padding: 8px;
  border-radius: 6px;

  width: 100%;

  position: relative;

  svg {
    position: absolute;
    right: 8px;
    bottom: 4px;

    color: #600d75;
    font-size: 4.2rem;
    opacity: 0.8;

    z-index: 0;
  }
`;

export const Description = styled.span`
  font-weight: bold;
  color: var(--white-color);
`;

export const Content = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  color: var(--white-color);

  padding: 0;
  margin: 0;

  line-height: 1.8;

  position: relative;
  z-index: 10;
`;
