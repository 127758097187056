import CrudService from '../CrudService';

class TeacherResponseService extends CrudService {
  constructor() {
    super({
      requestMapping: '/teacher-response',
    });
  }

  public static init() {
    return new TeacherResponseService();
  }
}

export default TeacherResponseService;
