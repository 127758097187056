import React from 'react';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { IconOptions, MenuOption } from '../../../../GlobalStyles';
import { EnabledActionsCrudEnum } from '../../../../core/interfaces/IActionsCrud';

type Props = {
  enabledActions?: EnabledActionsCrudEnum[];
  view?: () => void;
  edit?: () => void;
};

function MenuTopic({ edit, view, enabledActions }: Props) {
  const actions = [
    {
      label: <p>Baixar PDF</p>,
      event: view,
      icon: <DownloadOutlined />,
      value: EnabledActionsCrudEnum.VIEW,
    },
    {
      label: <p>Atualizar Dados</p>,
      event: edit,
      icon: <EditOutlined />,
      value: EnabledActionsCrudEnum.EDIT,
    },
    {
      label: <p>Visualizar</p>,
      event: view,
      icon: <EyeOutlined />,
      value: EnabledActionsCrudEnum.VIEW,
    },
  ];

  const menu: MenuProps['items'] = actions
    .filter((action) =>
      enabledActions ? enabledActions.includes(action.value) : true,
    )
    .map((action, index) => {
      return {
        key: `action_crud_item_${index}`,
        label: (
          <MenuOption onClick={action.event}>
            <IconOptions size="14px !important">{action.icon}</IconOptions>
            {action.label}
          </MenuOption>
        ),
      };
    });

  return (
    <Dropdown trigger={['click']} placement="bottomLeft" menu={{ items: menu }}>
      <IconOptions cursor="pointer" size="24px">
        <MoreOutlined />
      </IconOptions>
    </Dropdown>
  );
}

export default MenuTopic;
