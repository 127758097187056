import styled from 'styled-components';
import { Card } from '../../GlobalStyles';

const WrapperStatus = styled.div<{
  color: string;
}>`
  cursor: pointer;
  padding: 4px 16px;
  width: max-content;
  border: 1px solid currentColor;
  color: ${({ color }) => color};
  border-radius: 5px;
`;

const WrapperPopover = styled(Card)`
  & .ant-card-head-title {
    text-align: center !important;
    font-weight: 600 !important;
    color: var(--primary-color) !important;
    font-size: 16px !important;
    padding: 12px !important;
  }

  & .ant-card-head {
    padding: 0;
  }

  & .ant-card-body {
    padding: 12px !important;
    font-weight: 500 !important;
    max-width: 250px;
  }
`;

export { WrapperStatus, WrapperPopover };
