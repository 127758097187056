import { Col, Form, Input, Row, Spin } from 'antd';
import React, { useMemo } from 'react';
import Divider from '../../../../components/Divider';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import UserService from '../../../../services/UserService';

const required = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

function MentorProfileForm({ onCancel }: IPropsModal) {
  const { logged, updateLogged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();

  const { entityId = 0 } = logged ?? {};

  const userService = useMemo(() => UserService.init(), []);
  const initialValues = useMemo(
    () => ({
      name: logged?.name,
      description: logged?.description,
      email: logged?.email,
    }),
    [logged],
  );

  const handleSubmit = (values: Record<string, unknown>) => {
    showLoading();
    userService
      .updateMyUserProfile(entityId, {
        ...values,
      })
      .then(({ jwttoken }) => {
        onCancel();
        updateLogged(jwttoken);
        notification.success('Informações atualizadas com sucesso');
      })
      .catch(() => notification.error('Erro ao atualizar informações'))
      .finally(hideLoading);
  };

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Form.Item label="Nome" name="name" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Descrição" name="description" rules={required}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="E-mail" name="email" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} style={{ margin: '0 0 14px 0' }}>
            <Divider color="#ececec" />
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <PurpleButton htmlType="submit" type="primary">
              Salvar
            </PurpleButton>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
}

export default MentorProfileForm;
