import Text from '../Text';
import React, { useMemo } from 'react';
import SliderComponent from '../Slider/Slider';
import { Line, Question, BoxDescription } from './styles';
import { PropsLineScaleType } from '../../types/line-scale/LineScaleType';

export function LineScale({
  scales,
  title,
  answer = 1,
  changeAnswer,
}: PropsLineScaleType) {
  const scale = useMemo(() => scales[answer - 1], [answer, scales]);
  const marks = useMemo<Record<number, string>>(() => {
    return Array(5)
      .fill(0)
      .reduce(
        (acumulator, _, index) => {
          acumulator[index + 1] = `${index + 1}`;
          return acumulator;
        },
        {} as Record<number, string>,
      );
  }, []);

  return (
    <>
      <Line>
        <Question>
          <Text
            weight={700}
            size={17}
            style={{
              display: 'flex',
            }}
          >
            {title}
          </Text>
          <SliderComponent
            min={1}
            max={5}
            marks={marks}
            value={answer}
            defaultValue={answer}
            onChange={changeAnswer}
          />
        </Question>
        {scale ? (
          <BoxDescription>
            <div className={'description'}>
              {scale.orderScale} - {scale.description}
            </div>
          </BoxDescription>
        ) : (
          'Escala não identificada'
        )}
      </Line>
    </>
  );
}
