import { Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../core/hooks/auth';
import useLoading from '../../../core/hooks/useLoading';
import { BoxWhite } from '../../../GlobalStyles';
import StartupService from '../../../services/StartupService';
import IModel from '../../../core/interfaces/models/IModel';
import CardList from '../../../components/CardList/CardList';
import { maskMoney } from '../../../helpers/mask.helper';
import { convertDateToString } from '../../../helpers/dates.helper';
import ContentBase from '../../templates/ContentBase';

interface IEntity extends IModel {
  name: string;
  email: string;
  description?: string;
  accelerator?: string;
  phase?: string;
  valuation?: string;
  preMoney?: number;
  img?: string;
}

function StartupList() {
  const { loading, showLoading, hideLoading } = useLoading();
  const startupService = useMemo(() => StartupService.init(), []);
  const [data, setData] = useState<IEntity[]>([]);

  const { logged, isClient } = useAuth();

  const clientId = isClient() ? logged?.client : undefined;

  const getStartups = () => {
    showLoading();
    startupService
      .listAllStartupsByClient(clientId as number)
      .then((response) => {
        setData(
          response.map((startup) => {
            return {
              img: startup.profileImageUrl,
              entityId: startup.entityId,
              name: startup.name,
              email: startup.email,
              description: startup.description,
              accelerator: startup.accelerator,
              phase: startup.phase,
              valuation: maskMoney(startup.preMoney as number),
              creation: convertDateToString(startup.creation as string),
              lastUpdated: convertDateToString(startup.lastUpdated as string),
            };
          }),
        );
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getStartups();
  }, []);

  return (
    <ContentBase
      title={'Startups'}
      content={
        <Spin spinning={loading}>
          <BoxWhite>
            <CardList
              entity={data}
              profileType={'startup'}
              urlViewDetail={'/startups/profile/'}
            />
          </BoxWhite>
        </Spin>
      }
    />
  );
}

export default StartupList;
