import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function MetricsInfo() {
  return (
    <BoxInfo
      image={image}
      title="Métricas"
      description={
        <p>
          As métricas são valores mensuráveis, que são utilizados para que
          gestores avaliem e acompanhem o desempenho da sua startup. Tendo em
          vista a importância, a startup precisa cadastrar os OKR’s, KPI’s e
          evidências, a aceleradora fará a avaliação, logo, aprovará ou não as
          informações cadastradas pela startup.
        </p>
      }
    />
  );
}

export default MetricsInfo;
