import fileDefault from '../../assets/images/file-default-solid-240.png';
import filePng from '../../assets/images/file-png-solid-240.png';
import fileJPEG from '../../assets/images/file-jpeg-solid-240.png';
import filePdf from '../../assets/images/file-pdf-solid-240.png';
import fileMp4 from '../../assets/images/file-mp4-solid-240.png';

interface IImage extends Record<string, string> {
  default: string;
  png: string;
  jpg: string;
  jpeg: string;
  pdf: string;
  mp4: string;
}

export const ImageConfig: IImage = {
  default: fileDefault,
  png: filePng,
  jpg: fileJPEG,
  jpeg: fileJPEG,
  pdf: filePdf,
  mp4: fileMp4,
};
