import React from 'react';
import { WrapperAttachment } from './styles';
import { FilePdfOutlined } from '@ant-design/icons';
import { IconOptions } from '../../../../GlobalStyles';
import closeIcon from '../../../../assets/icons/close_icon.svg';

interface Props {
  name: string;
  remove(): void;
  preview?: () => void;
  enabledRemove?: boolean;
  isLoading?: boolean;
}

function AttachmentLabel({
  name,
  remove,
  isLoading,
  preview,
  enabledRemove = true,
}: Props) {
  return (
    <WrapperAttachment isLoading={isLoading}>
      <IconOptions onClick={preview} size="19px" display="flex">
        <FilePdfOutlined />
      </IconOptions>
      <span onClick={preview}>{name}</span>
      {enabledRemove && (
        <img
          src={closeIcon}
          alt="icone remover"
          style={{ cursor: 'pointer' }}
          height={14}
          width={14}
          data-disabled={isLoading}
          onClick={remove}
        />
      )}
    </WrapperAttachment>
  );
}

export default AttachmentLabel;
