import React from 'react';
import { BoxContent, BoxVideo, Description, Header, Title } from './style';
import bkg from '../../../../../assets/images/background-login.png';

interface IParams {
  title: string;
  description: string;
  videoSrc?: string;
  subTitle?: string;
  subDescription?: string;
}

function BoxTip(props: IParams) {
  return (
    <BoxContent>
      <Header src={bkg} />
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
      {props.videoSrc && (
        <BoxVideo>
          <video
            id="sampleVideo"
            width="100%"
            height="100%"
            controls
            controlsList="nodownload noplaybackrate"
          >
            <source src={props.videoSrc} type="video/mp4" />
          </video>
        </BoxVideo>
      )}
      {props.subTitle && (
        <>
          <Title>{props.subTitle}</Title>
          <Description>{props.subDescription}</Description>
        </>
      )}
    </BoxContent>
  );
}

export default BoxTip;
