import { Col, Form, Input, Row } from 'antd';
import React, { useCallback, useMemo, useEffect } from 'react';
import Divider from '../../../../components/Divider';
import { useAuth } from '../../../../core/hooks/auth';
import { PurpleButton } from '../../../../GlobalStyles';
import { cnpj } from 'cpf-cnpj-validator';
import { notification } from '../../../../helpers/notification.helper';
import ProfileDashboardService from '../../../../services/profile-dashboard/ProfileDashboardService';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';

interface Props extends IPropsModal {
  onReload(): void;
}

function ProfileForm({ onCancel, onReload }: Props) {
  const { updateLogged, logged } = useAuth();
  const [form] = Form.useForm();
  const { entityId = 0 } = logged ?? {};

  const profileDashboard = useMemo(() => ProfileDashboardService.init(), []);

  const required = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const getData = useCallback(() => {
    profileDashboard
      .getEditStartup(entityId)
      .then((data) => {
        form.setFieldsValue({
          ...data,
          description: data.description ?? '',
        });
      })
      .catch(() => {
        notification.info('Erro ao listar informações do perfil');
      });
  }, [profileDashboard, entityId, form]);

  const handleSubmit = async (values: Record<string, string>) => {
    try {
      const { jwttoken } = await profileDashboard.editStartupProfile(
        entityId,
        values,
      );

      updateLogged(jwttoken);
      onCancel();
      onReload();
      notification.success('Informações salvas com sucesso');
    } catch (exception) {
      notification.info('Erro ao salvar informações');
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[12, 0]}>
        <Col span={18}>
          <Form.Item label="Nome" name="name" rules={required}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[
              ...required,
              {
                validator(_, value) {
                  return cnpj.isValid(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
                message: 'CNPJ invalido',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Descrição" name="description" rules={required}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="E-mail" name="email" rules={required}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} style={{ margin: '0 0 14px 0' }}>
          <Divider color="#ececec" />
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <PurpleButton htmlType="submit" type="primary">
            Salvar
          </PurpleButton>
        </Col>
      </Row>
    </Form>
  );
}

export default ProfileForm;
