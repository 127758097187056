import React, { useEffect, useState } from 'react';
import { Col, Form, FormInstance, Input, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { IUser } from '../../../../core/interfaces';

interface IParams {
  subStep: number;
  setSubStep: (subStep: number) => void;
  setFormName: (formName: string) => void;
  initialValues?: any;
  setFormData: (data: any) => void;
}

function PersonalForm(props: IParams) {
  const [form] = Form.useForm();
  const [user, setUser] = useState<IUser>();

  const arrageForm = (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));
    const entityId = props.initialValues?.entityId;
    const name: string = valuelMap.get('name');
    const email: string = props.initialValues?.email;
    const cellPhone: string = valuelMap.get('cellphone');
    const cpf: string = valuelMap.get('cpf');
    const area: string = valuelMap.get('area');
    const office: string = valuelMap.get('office');

    return {
      entityId,
      name,
      email,
      cellPhone,
      cpf,
      area,
      office,
    };
  };

  const onFinish = (values: any) => {
    props.setSubStep(props.subStep + 1);
    props.setFormData(arrageForm(values));
  };

  useEffect(() => {
    props.setFormName('formStartupPersonal');
    form.setFieldsValue({
      name: props.initialValues?.name,
      email: props.initialValues?.email,
    });

    setUser(props.initialValues);
  }, [props.initialValues]);

  return (
    <Form
      name={'formStartupPersonal'}
      onFinish={onFinish}
      form={form}
      layout={'vertical'}
      autoComplete={'off'}
    >
      <Row gutter={[4, 4]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={'name'}
            label="Qual seu nome?"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="Nome completo" maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="CPF"
            name={'cpf'}
            rules={[
              { required: true, message: 'Campo obrigatório' },
              ({ getFieldValue }) => ({
                validator(_) {
                  if (getFieldValue('cpf').includes('_')) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'CPF inválido',
              }),
            ]}
          >
            <MaskedInput mask="000.000.000-00" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="Telefone"
            name="cellphone"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              ({ getFieldValue }) => ({
                validator(_) {
                  if (getFieldValue('cellphone').includes('_')) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'Telefone inválido',
              }),
            ]}
          >
            <MaskedInput mask="(00) 00000-0000" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Qual a sua área dentro da organização"
            name="area"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite sua área" maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Qual seu cargo"
            name="office"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Digite o seu cargo" maxLength={255} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default PersonalForm;
