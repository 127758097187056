import React, { useState } from 'react';
import { Container, Info, PurppleText } from './style';
import { Avatar, Col, notification, Row, Modal } from 'antd';
import UserService from '../../../../services/UserService';
import { IUser } from '../../../../core/interfaces';
import { findSatusValuation } from '../../../../core/enums/StatusValuation';
import { convertDateToString } from '../../../../helpers/dates.helper';
import Menu from './Menu/Menu';
import { EnabledActions } from './Menu/EnabledActions';

interface IProps {
  data: IUser;
  updateList?: () => void;
  showLoading: () => void;
  hideLoading: () => void;
  actions?: EnabledActions[];
}
type idAction = 0 | 1 | 5;
function ItemList(props: IProps) {
  const userService = UserService.init();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const md5 = require('md5');
  const getHash = (email: string) => {
    return md5(email);
  };
  const [avatar] = useState<string>(
    `https://www.gravatar.com/avatar/${getHash(props.data.email)}?d=identicon`,
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const changeStatus = (idAction: idAction) => {
    props.showLoading();
    userService
      .changeStatusUser(props.data?.entityId ?? 0, idAction)
      .then(() => {
        notification.success({
          message: 'Sucesso',
          description: 'Ação realizada com sucesso!',
        });
        props.updateList && props.updateList();
      })
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Algo deu errado',
        });
      })
      .finally(() => {
        props.hideLoading();
      });
  };

  const confirmUserDelete = () => {
    if (props.data?.entityId) {
      setModalVisible(false);
      props.showLoading();
      userService
        .deleteUser(props.data.entityId)
        .then(() => {
          notification.success({
            message: 'Sucesso',
            description: 'Usuário excluído com sucesso!',
          });
          props.updateList && props.updateList();
        })
        .catch(() => {
          notification.error({
            message: 'Erro',
            description: 'Algo deu errado',
          });
        })
        .finally(() => {
          props.hideLoading();
        });
    }
  };

  return (
    <Container>
      <Row>
        <Col xxl={23} sm={23} xs={23}>
          <Row>
            <Col xxl={1} xl={1} lg={2} md={2} sm={2} xs={4}>
              <Avatar src={avatar} size={48} />
            </Col>
            <Col xxl={4} xl={3} lg={2} md={2} sm={5} xs={20}>
              <Info width="200px" overflow={'true'}>
                <div className="label">Perfil</div>
                <PurppleText fontBold={true}>
                  {props.data?.profiles
                    ? props.data?.profiles[0]?.description
                    : 'Sem Perfil'}
                </PurppleText>
              </Info>
            </Col>
            <Col xxl={4} xl={3} lg={3} md={2} sm={6} xs={24}>
              <Info
                width="200px"
                overflow={'true'}
                title={`${props.data.name}`}
              >
                <div className="label">Nome</div>
                <div>{props.data.name}</div>
              </Info>
            </Col>
            <Col xxl={5} xl={7} lg={6} md={7} sm={11} xs={24}>
              <Info
                width="200px"
                overflow={'true'}
                title={`${props.data.email}`}
              >
                <div className="label">E-mail</div>
                <div>{props.data.email}</div>
              </Info>
            </Col>
            {/*<Col style={{*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'start'*/}
            {/*}} xxl={2} xl={2} lg={3} md={3} sm={8} xs={24}>*/}
            {/*    <Info width="110px">*/}
            {/*        <div className="label">*/}
            {/*            Status atual*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            {*/}
            {/*                findSatusValuation(props.data.status)*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </Info>*/}
            {/*</Col>*/}
            <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={12}>
              <Info width="120px">
                <div className="label">Data de entrada</div>
                <div>{convertDateToString(props.data.creation as string)}</div>
              </Info>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={12}>
              <Info width="140px">
                <div className="label">Última atualização</div>
                <div>
                  {props.data.lastUpdated
                    ? convertDateToString(props.data.lastUpdated)
                    : 'Sem atualização'}
                </div>
              </Info>
            </Col>
          </Row>
        </Col>
        <Col sm={1} xs={1} xxl={'20px'}>
          <div className="actionsButtons">
            <Menu
              block={() => {
                changeStatus(1);
              }}
              reject={() => {
                changeStatus(5);
              }}
              activate={() => {
                changeStatus(0);
              }}
              remove={() => setModalVisible(true)}
              enabledActions={props.actions}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title="Atenção"
        open={modalVisible}
        onOk={() => confirmUserDelete()}
        onCancel={() => setModalVisible(false)}
        okText="Sim"
        cancelText="Cancelar"
      >
        Deseja realmente excluir usuário? Isso irá EXCLUIR TODOS os registros
        associados ao mesmo, acarretando na perda das informações
      </Modal>
    </Container>
  );
}

export default ItemList;
