import { Col, Row } from 'antd';
import React, { useState } from 'react';
import Title from '../../../../components/Title';
import { Card } from '../../../../GlobalStyles';
import { DiagnosticTopicType } from '../../../../types/diagnosis/DiagnosisType';
import pathIcon from '../../../../assets/icons/diagnostic-result.png';
import { Wrapper, Grid } from './styles';
import Text from '../../../../components/Text';
import DiagnosticResult from '../DiagnosticResult';
import { useModal } from 'sunflower-antd';

type Props = {
  title: string;
  isBlocked?: boolean;
  topics: DiagnosticTopicType[];
};

function DiagnosticTopicMobile({ title, topics, isBlocked = false }: Props) {
  const modalDiagnosticResult = useModal({
    defaultVisible: false,
  });

  const [diagnostic, setDiagnostic] = useState<any>();

  return (
    <>
      <Wrapper isBlocked={isBlocked}>
        <Title margin="0 0 4px 0" size={16}>
          {title}
        </Title>
        <Row gutter={[0, 8]}>
          {topics?.map((topic, index) => {
            const diagnostic = topic.diagnostic?.diagnostic;
            return (
              <Col key={`content_key_${index}`} span={24}>
                <Card
                  onClick={() => {
                    setDiagnostic(topic.diagnostic);
                    modalDiagnosticResult.show();
                  }}
                >
                  <Grid>
                    <div>
                      <div>
                        <img
                          src={pathIcon}
                          alt="icone diagnostico"
                          height={111}
                          width={111}
                        />
                      </div>
                      <Text weight={700}>
                        {diagnostic?.name || topic.diagnostic?.name}
                      </Text>
                    </div>
                    <Text weight={700}>{diagnostic?.phase?.name}</Text>
                    <div>
                      <Text weight={700}>Data de entrada:</Text>
                      <Text>{topic.entryDate || topic.creation}</Text>
                    </div>
                    <div>
                      <Text weight={700}>Data de execução:</Text>
                      <Text>{topic?.executionDate ?? 'Não executado'}</Text>
                    </div>
                  </Grid>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Wrapper>

      <DiagnosticResult
        show={modalDiagnosticResult.visible}
        close={modalDiagnosticResult.close}
        startup={diagnostic?.startup}
        diagnostic={diagnostic}
      />
    </>
  );
}

export default DiagnosticTopicMobile;
