import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Title from '../../../components/Title';
import { BoxWhite } from '../../../GlobalStyles';
import {
  AlertOutlined,
  BankOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {
  Box,
  BoxBottom,
  BoxMentorShip,
  Filters,
  IncomingCall,
  MentorCard,
  MentorshipCard,
  Specialties,
} from './style';
import { Button, notification, Spin } from 'antd';
import { EnabledActionsCrudEnum } from '../../../core/interfaces/IActionsCrud';
import ActionsCrud from '../../../components/ActionsCrud';
import MentorService from '../../../services/MentorService';
import { IMentor } from '../../../core/interfaces/IMentor';
import ScheduleService from '../../../services/ScheduleService';
import { ISchedule } from '../../../core/interfaces/ISchedule';
import { useAuth } from '../../../core/hooks/auth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import StartupMentoringService from '../../../services/startup-mentoring/StartupMentoringService';
import INextMentorship from '../../../core/interfaces/startup-mentoring/INextMentorship';
import useLoading from '../../../core/hooks/useLoading';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import ContentBase from '../../templates/ContentBase';
import MentorshipStartupInfo from '../../Infos/MentorshipStartupInfo';

function Mentors() {
  const [avaiableCall, setAvaiableCall] = React.useState(false);
  const { loading, showLoading, hideLoading } = useLoading();

  const mentorService = useMemo(() => MentorService.init(), []);
  const scheduleService = useMemo(() => ScheduleService.init(), []);
  const startupMentoringService = useMemo(
    () => StartupMentoringService.init(),
    [],
  );

  const [mentors, setMentors] = useState<IMentor[]>([]);
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [nextMentorship, setNextMentorship] = useState<INextMentorship | null>(
    null,
  );

  const { logged } = useAuth();
  const id = logged?.entityId;
  const navigate = useNavigate();

  const getMentors = async () => {
    showLoading();
    mentorService
      .getAllMentors()
      .then((response) => setMentors(response))
      .catch(() => {
        notification.error({
          message: 'Erro',
          description: 'Erro ao buscar mentores',
        });
      })
      .finally(hideLoading);
  };

  const getSchedule = async () => {
    scheduleService
      .getAllSchedulesByStartup(Number(id))
      .then((response) => setSchedule(response))
      .catch(() => {
        notification.error({
          message: 'Erro ao buscar agendamentos',
          description:
            'Ocorreu um erro ao buscar os agendamentos, tente novamente mais tarde',
        });
      });
  };

  const getNextMentoring = useCallback(() => {
    showLoading();
    startupMentoringService
      .getTheNextMentorship()
      .then((data) => setNextMentorship(data))
      .catch(() => {
        notification.error({
          message: 'Erro ao recuperar mentoria atual',
        });
      })
      .finally(hideLoading);
  }, [startupMentoringService]);

  const joinCall = () => {
    const joinMeetingUrl = nextMentorship?.joinMeetingUrl;
    if (joinMeetingUrl) {
      window.open(joinMeetingUrl, '_target');
    } else {
      notification.info({
        message: 'Seu mentor ainda não disponibilizou o link',
      });
    }
  };

  const disableMentorship = () => {
    console.log('cancel here');
  };

  const goToProfile = (id: number) => {
    navigate(`mentor-profile/${id}`);
  };

  const getHourToDateTime = (date: string) => {
    return moment(date).format('HH:mm');
  };

  const getDateToDateTime = (date: string) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const getSpan = (item: string, className?: string, title?: string) => {
    return (
      <span
        style={{
          textAlign: 'center',
        }}
        title={title}
        className={className}
      >
        {item}
      </span>
    );
  };

  useEffect(() => {
    getMentors();
    getSchedule();
  }, []);

  useEffect(() => {
    getNextMentoring();
  }, [getNextMentoring]);

  return (
    <ContentBase
      title="Mentorias"
      modalContent={<MentorshipStartupInfo />}
      subtitle={'Valuation atual'}
      content={
        <>
          {nextMentorship && (
            <IncomingCall>
              <Box>
                <AlertOutlined />
                <div className="mentorship">
                  <span className="name">{nextMentorship.title}</span>
                  <span className="mentor">{nextMentorship.name}</span>
                </div>
              </Box>
              <div className="time">
                {moment(nextMentorship.initialDate).format('HH:mm')}
              </div>
              <Button type="primary" disabled={avaiableCall} onClick={joinCall}>
                Juntar-se a chamada
              </Button>
            </IncomingCall>
          )}
          <BoxWhite>
            <Title size={20}>Suas mentorias</Title>
            <Filters>
              <span className="selected">Todos</span>
              {/*<span>Status X</span>*/}
              {/*<span>Status Y</span>*/}
              {/*<span>Status Z</span>*/}
            </Filters>
            <Spin spinning={loading}>
              <BoxMentorShip>
                <OnEmptyOrList
                  list={schedule}
                  description={'Não há nenhuma mentoria agendada'}
                >
                  {schedule.map((item, index) => (
                    <MentorshipCard
                      key={`mentorship_${index}`}
                      align={'center'}
                    >
                      <CalendarOutlined />
                      {getSpan('status here', 'status')}
                      {getSpan(
                        `${getHourToDateTime(
                          item?.initialDate as string,
                        )} - ${getHourToDateTime(item?.finalDate as string)}`,
                        'time',
                      )}
                      {getSpan(
                        `${getDateToDateTime(item?.initialDate as string)}`,
                      )}
                      {getSpan(
                        `${item.mentor?.user?.name}`,
                        'name',
                        'Nome do mentor',
                      )}
                      {getSpan(
                        item.mentoring?.title ?? 'mentoria não identificada',
                        'mentor',
                        'Mentoria de como tomar um chimarrão decentemente',
                      )}
                      <div className="column">
                        <ActionsCrud
                          status={() => disableMentorship()}
                          enabledActions={[EnabledActionsCrudEnum.STATUS]}
                        />
                      </div>
                    </MentorshipCard>
                  ))}
                </OnEmptyOrList>
              </BoxMentorShip>
            </Spin>
          </BoxWhite>
          <BoxWhite>
            <Spin spinning={loading}>
              <Title size={20}>Mentores do Innovation Studio</Title>
              <OnEmptyOrList
                list={mentors}
                description={'Não há nenhum mentor disponível'}
              >
                <BoxMentorShip style={{ marginTop: '10px' }}>
                  {mentors.map((mentor, index) => (
                    <div key={`mentor_${index}`}>
                      <MentorCard>
                        <div className="image">
                          <img
                            alt=""
                            src="https://www.w3schools.com/howto/img_avatar.png"
                          />
                        </div>
                        {getSpan(
                          `${mentor?.user?.name}`,
                          'name',
                          'Name here big name giant',
                        )}
                        {getSpan('nome da especialidade aqui')}
                        <div className="startup">
                          <BankOutlined />
                          {getSpan('Innovation Studio', 'innovation')}
                        </div>
                        <Specialties>
                          <div className="item">PHP</div>
                          <div
                            title={`Algo grande pra ver o comportamento`}
                            className="item"
                          >
                            Javascript
                          </div>
                          <div
                            title={`Algo grande pra ver o comportamento`}
                            className="item"
                          >
                            React
                          </div>
                        </Specialties>
                      </MentorCard>
                      <BoxBottom
                        onClick={() => {
                          goToProfile(mentor?.entityId as number);
                        }}
                      >
                        Visitar perfil
                      </BoxBottom>
                    </div>
                  ))}
                </BoxMentorShip>
              </OnEmptyOrList>
            </Spin>
          </BoxWhite>
        </>
      }
    />
  );
}

export default Mentors;
