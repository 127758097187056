import styled, { css } from 'styled-components';

const WrapperAttachment = styled.div<{
  isLoading?: boolean;
}>`
  cursor: pointer;
  gap: 8px;
  display: flex;
  max-width: 210px;
  padding: 5px 10px;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 0 0 1px #ccc;
  justify-content: flex-start;
  color: var(--primary-color);

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  & [data-disabled='true'] {
    pointer-events: none;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      cursor: wait;
    `}
`;

export { WrapperAttachment };
