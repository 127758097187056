import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Checkbox, Col, Collapse, Empty, Progress, Row } from 'antd';
import Drawer from '../../../../components/drawer/Drawer';
import Text from '../../../../components/Text';
import { BoxTopic, HeaderTopic, Info } from '../../../courses/style';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import { Header } from './styles';
import ITopic from '../../../../core/interfaces/course/ITopic';
import CourseService from '../../../../services/course/CourseService';
import { notification } from '../../../../helpers/notification.helper';
import ICourseShowCase from '../../../../core/interfaces/course/ICourseShowCase';
import TopicWithProgressService from '../../../../services/topic/TopicWithProgressService';
import { isEmpty, isNull } from 'lodash';
import {
  getAmountOfExercisesPerformed,
  getColorExercise,
  getLabelExercise,
  getNumberOfClassesAttended,
} from '../../../../helpers/course.helper';
import { PanelChildrenItem } from '../../../../components/AttendCourse/TopicAccordion/styles';
import { MdOndemandVideo } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Title from '../../../../components/Title';

interface IProps {
  drawerVisible: boolean;
  onClose: () => void;
  courseId?: number;
  userId: number;
}

function CourseProgress({ drawerVisible, onClose, courseId, userId }: IProps) {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [activeTopics, setActiveTopics] = useState<number[]>([]);

  const [topics, setTopics] = useState<ITopic[]>([]);
  const [course, setCourse] = useState<ICourseShowCase>({
    amountExercises: 0,
    categoryName: '',
    description: '',
    duration: 0,
    entityId: 0,
    extraMaterialAmount: 0,
    lessonQuantity: 0,
    name: '',
    progress: 0,
    price: 0,
  });

  const courseService = useMemo(() => CourseService.init(), []);
  const topicService = useMemo(() => TopicWithProgressService.init(), []);

  const getData = useCallback(
    (id: number) => {
      Promise.all([
        courseService.showCaseById(id, Number(userId)),
        topicService.getByCourse(id, userId),
      ])
        .then(([course, topics]) => {
          setCourse(course);
          setTopics(topics);
        })
        .catch(() => notification.error('Erro ao recuperar dados do curso'));
    },
    [courseService, topicService, userId],
  );

  useEffect(() => {
    if (courseId) getData(courseId);
  }, [courseId, getData]);

  const checkPanel = (panelKey: number) => {
    return activeTopics.includes(panelKey);
  };

  const handlePanel = (panelKey: number) => {
    if (checkPanel(panelKey)) {
      setActiveTopics(activeTopics.filter((key) => panelKey !== key));
    } else {
      setActiveTopics(activeTopics.concat(panelKey));
    }
  };

  let lessonIndex = 0;

  return (
    <Drawer
      width="100%"
      title={course.name}
      className="reset-padding-body-mobile"
      onClose={onClose}
      visible={drawerVisible}
    >
      <Header>
        <Text>{course.description}</Text>
        <div className="extra">
          <strong>Progresso da startup</strong>
          <Progress
            style={{ width: '200px' }}
            percent={course.progress}
            status="active"
            format={() => `${course.progress.toFixed(1)}%`}
          />
        </div>
      </Header>
      <Info width="auto" align="left" style={{ marginBottom: '10px' }}>
        <div className="label">
          <ClockCircleOutlined /> &nbsp; 1h
        </div>
        <div className="label">
          <PlaySquareOutlined /> &nbsp;{' '}
          {`${course.lessonQuantity} aulas em vídeo`}
        </div>
        <div className="label">
          <FileTextOutlined /> &nbsp; {`${course.amountExercises} exercícios`}
        </div>
        <div className="label">
          <DownloadOutlined /> &nbsp; {course.extraMaterialAmount} arquivos
          complementares
        </div>
      </Info>
      <BoxTopic noBorder={true}>
        {isEmpty(topics) && (
          <Empty
            description={
              <Text size={20} weight={700}>
                Tópicos vazios
              </Text>
            }
          />
        )}
        {topics.map((topic, index) => (
          <Collapse
            key={`collapse_key_${index}`}
            ghost
            activeKey={activeTopics}
          >
            <Panel
              key={index}
              extra={
                <div onClick={() => handlePanel(index)}>
                  {checkPanel(index) ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}
                </div>
              }
              showArrow={false}
              header={
                <HeaderTopic>
                  <Title weight={600} tag="h2" sizeXs={20} size={26}>
                    {topic.name}
                  </Title>
                  <Info width="auto" align="left" color="var(--primary-color)">
                    <div className="label">
                      <PlaySquareOutlined /> &nbsp;{' '}
                      {`${getNumberOfClassesAttended(topic)}/${
                        topic.lessons.length
                      } | 20 min`}
                    </div>
                    <div className="label">
                      <FileTextOutlined /> &nbsp;{' '}
                      {`${getAmountOfExercisesPerformed(topic)}/${
                        topic.exercises.length
                      } exercícios`}
                    </div>
                  </Info>
                </HeaderTopic>
              }
            >
              {topic.lessons.map((lesson, index) => {
                lessonIndex++;
                const name = `Aula ${lessonIndex}: ${lesson.name}`;
                const isComplete = Boolean(lesson.isComplete);

                return (
                  <PanelChildrenItem key={`lesson_item_${index}`}>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Checkbox value={isComplete} checked={isComplete} />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={16}>
                        <Text size={14} weight={500}>
                          {name}
                        </Text>
                      </Col>
                      <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <MdOndemandVideo color="#05233D" size={19} />
                        <Text margin="0 0 0 0.5rem">{lesson.duration}min</Text>
                      </Col>
                      <Col order={2}>
                        <Progress
                          style={{ width: '200px' }}
                          percent={isComplete ? 100 : 0}
                          status="active"
                        />
                      </Col>
                    </Row>
                  </PanelChildrenItem>
                );
              })}
              {topic.exercises.map((exercise, index) => {
                const { entityId, grade, name } = exercise;
                const isComplete = !isNull(grade);

                return (
                  <PanelChildrenItem
                    key={`exercise_item_${index}`}
                    onClick={() =>
                      navigate(
                        `/dashboard/startup/${userId}/correct-exercise/${entityId}`,
                      )
                    }
                  >
                    <Row gutter={12}>
                      <Col>
                        <Checkbox value={isComplete} checked={isComplete} />
                      </Col>
                      <Col xs={24} sm={24} md={15}>
                        <Text size={14} weight={500}>
                          Atividade: {name}
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          margin="0 1rem 0 0"
                          weight={600}
                          color={getColorExercise(exercise)}
                        >
                          {getLabelExercise(exercise)}
                        </Text>
                      </Col>
                    </Row>
                  </PanelChildrenItem>
                );
              })}
            </Panel>
          </Collapse>
        ))}
      </BoxTopic>
    </Drawer>
  );
}

export default CourseProgress;
