import React from 'react';
import BoxInfo from './Box/InfoBox';

function ToolsInfo() {
  return (
    <BoxInfo
      title="Ferramentas da plataforma"
      description={
        <>
          <p>Essas ferramentas servem pra BLA BLA BLA</p>
        </>
      }
    />
  );
}

export default ToolsInfo;
