import React from 'react';
import { Box, Title } from './styles';
import { Card, Divider } from 'antd';
import Text from '../../../../../components/Text';

interface Props {
  title: string;
  questions: Array<any>;
}

function QuizFeedback({ title, questions }: Props) {
  const findScale = (scale: any[], answer: number) => {
    if (scale.find((item: any) => item.orderScale === answer)) {
      return scale.find((item: any) => item.orderScale === answer).description;
    } else {
      return '';
    }
  };

  return (
    <Box>
      <Title>{title}</Title>
      <ul>
        {questions.map((question, index) => (
          <li key={`question_key_${index}`}>
            <div className={'content-question'}>
              <div style={{ display: 'flex' }}>
                <p> {index + 1} - </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.question.question,
                  }}
                />
              </div>
              <p>Sua resposta: {question.description}</p>
            </div>
            <Card size={'small'}>
              <Text size={14}>
                {findScale(
                  question.question.scales,
                  Number(question.description),
                )}
              </Text>
            </Card>
            <Divider />
          </li>
        ))}
      </ul>
    </Box>
  );
}

export default QuizFeedback;
