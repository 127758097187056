import React from 'react';
import BoxInfo from './Box/InfoBox';
import Title from '../../components/Title';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function CoursesToolsInfo() {
  return (
    <BoxInfo
      title="Ferramentas da plataforma"
      image={image}
      description={
        <>
          <Title>Para que servem essas categorias?</Title>
          <p>
            As categorias auxiliarão na organização dos cursos que as
            aceleradoras irão cadastrar para compor suas metodologias. Elas
            também organizarão a loja de cursos do Innovation Studio para
            startups e alunos adquirirem.
          </p>
        </>
      }
    />
  );
}

export default CoursesToolsInfo;
