import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import InnovationPagesRoutes from './innovation.routes';

function InnovationRoutes() {
  return (
    <>
      <BrowserRouter>
        <InnovationPagesRoutes />
      </BrowserRouter>
    </>
  );
}

export default InnovationRoutes;
