import styled from 'styled-components';
import { PurpleButton } from '../../../../GlobalStyles';

const PublishButtonWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
`;

const PublishButton = styled(PurpleButton)`
  max-width: 220px;
  width: 100%;
  height: auto;
  white-space: normal;
`;

export { PublishButton, PublishButtonWrapper };
