import { Dropdown, MenuProps } from 'antd';
import { IconOptions, MenuOption } from '../../../../../GlobalStyles';
import {
  CloseOutlined,
  LockOutlined,
  MoreOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ParamsActions, EnabledActions } from './EnabledActions';
import React from 'react';

function Menu({
  enabledActions,
  reject,
  block,
  activate,
  remove,
  sizeIcon = 16,
  ...props
}: ParamsActions) {
  const actionsCrud = [
    {
      label: <p>Rejeitar</p>,
      event: reject,
      icon: <CloseOutlined />,
      value: EnabledActions.REJECT,
    },
    {
      label: <p>Bloquear</p>,
      event: block,
      icon: <LockOutlined />,
      value: EnabledActions.BLOCK,
    },
    {
      label: <p>Ativar</p>,
      event: activate,
      icon: <UnlockOutlined />,
      value: EnabledActions.ACTIVATE,
    },
    {
      label: <p>Excluir</p>,
      event: remove,
      icon: <DeleteOutlined />,
      value: EnabledActions.REMOVE,
    },
  ];

  const items: MenuProps['items'] = (
    props.actions ??
    actionsCrud.filter((action) =>
      enabledActions ? enabledActions.includes(action.value) : true,
    )
  ).map((action, index) => {
    return {
      key: `action_crud_item_${index}`,
      label: (
        <MenuOption onClick={action.event}>
          <IconOptions display="flex" size="14px !important">
            {action.icon}
          </IconOptions>
          {action.label}
        </MenuOption>
      ),
    };
  });

  return (
    <Dropdown trigger={['hover']} placement="topLeft" menu={{ items: items }}>
      <IconOptions cursor="pointer" size={`${sizeIcon}px`}>
        <MoreOutlined />
      </IconOptions>
    </Dropdown>
  );
}

export default Menu;
