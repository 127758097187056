import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function MentorListInfo() {
  return (
    <BoxInfo
      title="MENTORES E BLA BLA BLA"
      image={image}
      description={
        <>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry.
          </p>
        </>
      }
    />
  );
}

export default MentorListInfo;
