import { Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { useMemo } from 'react';
import Text from '../../../../components/Text';
import {
  getTextTypeVacancy,
  ITypeVacancyEnum,
} from '../../../../core/enums/ITypeVacancyEnum';
import {
  getDescriptionWorkplace,
  IWorkplaceEnum,
} from '../../../../core/enums/IWorkplaceEnum';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IVacancies from '../../../../core/interfaces/IVacancies';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { ModalBody, PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import VacanciesService from '../../../../services/VacanciesService';

interface Props extends IPropsModal {
  entityId?: number;
  vacancy: IVacancies | null;
  onReload(): void;
}

function VacanciesForm({
  entityId,
  visible,
  vacancy,
  onCancel,
  onReload,
}: Props) {
  const required = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const { logged } = useAuth();
  const { loading, hideLoading, showLoading } = useLoading();

  const vacanciesService = useMemo(() => VacanciesService.init(), []);
  const initialValues = useMemo(
    () => ({
      ...vacancy,
    }),
    [vacancy],
  );

  const handleSubmit = (values: Record<string, unknown>) => {
    showLoading();
    vacanciesService
      .updateOrCreate(
        {
          ...values,
          user: {
            entityId: logged?.entityId,
          },
        },
        vacancy?.entityId,
      )
      .then(() => {
        onReload();
        onCancel();
        notification.success('Vaga salva com sucesso');
      })
      .catch(() => notification.error('Erro ao salvar vaga'))
      .finally(hideLoading);
  };

  const { Hybrid, Presence, Remote } = IWorkplaceEnum;
  const workPlaces = [Hybrid, Presence, Remote];

  const typesVacancy = [
    ITypeVacancyEnum.Contract,
    ITypeVacancyEnum.FullTime,
    ITypeVacancyEnum.Internship,
    ITypeVacancyEnum.PartTime,
    ITypeVacancyEnum.Temporary,
  ];

  return (
    <ModalBody
      title={`${entityId ? 'Editar' : 'Nova'} vaga`}
      maxWidth={'500px'}
      width="100%"
      margin="0 auto"
      footer={null}
      closable={false}
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      bodyStyle={{ padding: '12px' }}
    >
      <Spin spinning={loading}>
        <Text align="center" margin="0 0 24px 0">
          <strong data-bold="true">Lembrete</strong>: o Innovation Studio não
          contempla a contratação dentro da plataforma e não se responsabiliza
          por quaisquer problemas no processo. A negociação entre profissional e
          startup será por contato direto.
        </Text>

        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={initialValues}
        >
          <Row>
            <Col span={24}>
              <Form.Item rules={required} label="Cargo" name="office">
                <Input showCount maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={required}
                label="Local de trabalho"
                name="workplace"
              >
                <Select>
                  {workPlaces.map((workPlace) => (
                    <Select.Option
                      key={`workplace_${workPlace}`}
                      value={workPlace}
                    >
                      {getDescriptionWorkplace(workPlace)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={required}
                label="Localidade da vaga"
                name="vacancyLocation"
              >
                <Input showCount maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={required}
                label="Tipo de vaga"
                name="typeVacancy"
              >
                <Select>
                  {typesVacancy.map((typeVacancy) => (
                    <Select.Option
                      key={`vacancy_${typeVacancy}`}
                      value={typeVacancy}
                    >
                      {getTextTypeVacancy(typeVacancy)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item rules={required} label="Descrição" name="description">
                <Input.TextArea showCount maxLength={400} />
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <PurpleButton htmlType="submit" type="primary">
                Publicar vaga
              </PurpleButton>
            </Col>
          </Row>
        </Form>
      </Spin>
    </ModalBody>
  );
}

export default VacanciesForm;
