import styled from 'styled-components';
import { Menu } from 'antd';

const { Item } = Menu;

const BoxContent = styled.div`
  display: flex;
  cursor: pointer;

  & .ant-badge {
    & sup {
      background-color: var(--primary-color);
    }
  }

  & .ant-badge:hover {
    & sup {
      background-color: var(--secondary-color);
    }
  }

  & svg {
    font-size: 22px;
  }
`;

const BoxProfile = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  margin-right: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 4px 4px;

  :hover {
    border-color: var(--secondary-color);

    & svg {
      color: var(--secondary-color);
    }
  }
`;

const BoxMenu = styled.div`
  position: relative;

  & .boxMenu {
    position: absolute;
    z-index: 100;
    width: 160px;
    background-color: var(--white-color);
    top: 45px;
    right: 10px;
    padding: 5px 5px;
    border-radius: 8px;
    border-top-right-radius: 0;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
  }

  .ant-menu .ant-menu-item span {
    svg {
      font-size: 18px;
    }

    svg:hover {
      color: var(--secondary-color);
    }
  }

  .ant-menu .ant-menu-item:hover {
    color: var(--secondary-color);
  }
`;

const MenuContainer = styled(Menu)`
  background: transparent;

  &.ant-menu-inline,
  .ant-menu-item::after {
    border: none;
  }

  &.ant-menu > .ant-menu-item-selected::after {
    left: 0 !important;
  }

  & .ant-menu-item {
    height: 30px !important;
    padding-left: 10px !important;

    & .ant-badge {
      position: absolute;
      top: 8px;
      right: 5px;

      & sup {
        background-color: var(--primary-color);
      }
    }

    &.anticon {
      padding-top: 10px;
    }
  }

  & .ant-menu-item:hover {
    & .ant-badge {
      & sup {
        background-color: var(--secondary-color);
      }
    }
  }

  & .ant-menu-item,
  .ant-menu-item-active {
    color: var(--primary-color);
  }

  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    & a,
    .anticon {
      color: var(--primary-color);
    }
  }
`;

const MenuItem = styled(Item)`
  &:hover .anticon,
  a {
    color: var(--secondary-color);
  }

  & .ant-menu-title-content a {
    color: var(--primary-color);
    font-weight: bold;
  }

  &.ant-menu-item-selected span a {
    color: var(--secondary-color);
    font-weight: bold;
  }
`;

export { BoxContent, BoxMenu, BoxProfile, MenuContainer, MenuItem };
