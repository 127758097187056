import React from 'react';
import { Row, Col } from 'antd';
import { Card } from '../styles';
import Text from '../../../../components/Text';
import ScoreCardGauche from './ScoreCardGauche';
import Title from '../../../../components/Title';
import InvestorAnalysisChart from './InvestorAnalysisChart';
import { PageBreak } from '../../../../GlobalStyles';
import { maskMoney, maskPercent } from '../../../../helpers/mask.helper';
import { maskPercentage } from '../../../../helpers/convertion.helper';

interface IParams {
  distributed: number;
  enterprising: number;
  marginDistribution: number;
  preMoneyScoreCard?: number;
  posMoney?: number;
  avgScoreCard: number;
  risk: number;
  potential: number;
  investmentAmount: number;
  divisionMin: number;
  divisionMax: number;
}

function ScoreCardSection(props: IParams) {
  const investmentAnalysis = [
    {
      text: 'Valor total do Investimento',
      value: props.investmentAmount,
    },
    {
      text: 'Valuation pré-money',
      value: props.preMoneyScoreCard,
    },
    {
      text: 'Valuation pós-money',
      value: Number(props.preMoneyScoreCard) + Number(props.investmentAmount),
    },
  ];

  return (
    <section>
      <Title size={25}>Score Card</Title>
      <Text size={17} margin="0 0 30px 0">
        É um método com aspectos qualitativos, cujo principal princípio deste
        método é que transações comparáveis e recentes são relevantes na
        precificação de uma empresa. O Innovation Studio revisou o sistema de
        pontuação e as informações sobre as quais as pontuações são atribuídas.
        As áreas de avaliação da startup são: força da equipe, tamanho da
        oportunidade, ambiente competitivo, força e proteção de produto/serviço,
        relações estratégicas com parceiros e por fim, financiamento. Cada um
        desses pontos têm seus respectivos pesos.
      </Text>
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <Row gutter={[12, 12]} style={{ marginBottom: '30px' }}>
          <Col span={24}>
            <Title size={19}>Risco x Potencial de Crescimento</Title>
          </Col>
          <Col xs={24} sm={24} md={13}>
            <Card height="100%">
              <Row align="stretch">
                <Col span={12} style={{ height: '220px' }}>
                  <ScoreCardGauche value={props.risk} title="Risco" />
                </Col>
                <Col span={12} style={{ height: '220px' }}>
                  <ScoreCardGauche value={props.potential} title="Potencial" />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={11}>
            <Card height="100%">
              <Title size={19} color="#960096">
                Resultado
              </Title>
              <Text size={15} margin="8px 0">
                Idealmente, uma Startup muito bem avaliada seria posicionada no
                extremo superior direito. Ou seja, seu risco é 5 e seu potencial
                também. Lembrando que a nota 5 remete a um grande potencial de
                crescimento e alta segurança.
              </Text>
              <Row gutter={{ xs: 12, sm: 12, md: 25 }}>
                <Col>
                  <Title size={15} color="#960096">
                    Riscos
                  </Title>
                  <Text>{props.risk}</Text>
                </Col>
                <Col>
                  <Text lineHeight={1.3} weight={700} color="#960096">
                    x
                  </Text>
                </Col>
                <Col>
                  <Title size={15} color="#960096">
                    Potencial
                  </Title>
                  <Text>{props.potential}</Text>
                </Col>
                <Col>
                  <Text lineHeight={1.3} weight={700} color="#960096">
                    =
                  </Text>
                </Col>
                <Col>
                  <Title size={15} color="#960096">
                    Média
                  </Title>
                  <Text>{props.avgScoreCard || 0}</Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <PageBreak />
        <Title size={19} margin="0 0 8px 0" className="mt-1-print">
          Análise do investimento
        </Title>
        <Row gutter={[12, 12]} style={{ marginBottom: '30px' }}>
          <Col xs={24} sm={24} md={24} lg={13}>
            <Row style={{ flexDirection: 'column' }}>
              <Col style={{ marginBottom: '16px' }}>
                <Card>
                  {investmentAnalysis.map((analisy, index) => (
                    <Row
                      justify="space-between"
                      style={{ margin: '5px 0' }}
                      key={`investiment_key_${index}`}
                    >
                      <Col>
                        <Text weight={700}>{analisy.text}</Text>
                      </Col>
                      <Col>
                        <Text color="#960096">
                          {analisy.value
                            ? maskMoney(analisy.value)
                            : 'Não informado'}
                        </Text>
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
              <Col>
                <Card>
                  <Text size={14}>
                    Baseado nas informações imputadas pelo investidor e nos
                    pesos considerados para cada fator, considera-se que a
                    distribuição de capital da empresa deva ser a seguinte:
                  </Text>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Card height="100%">
              <InvestorAnalysisChart
                distributed={props.distributed}
                enterprising={props.enterprising}
                marginDistribution={props.marginDistribution}
              />
            </Card>
          </Col>
        </Row>
        <Title size={19} margin="0 0 8px 0">
          Considerações finais
        </Title>
        <Card>
          <Text>
            {'"'}O resultado encontrado acima quer dizer que para um
            investimento total de{' '}
            <Text display="inline-block" weight={700} color="#960096">
              {maskMoney(props.preMoneyScoreCard || '0')}
            </Text>{' '}
            na Startup, pode-se esperar que sejam distribuídos entre{' '}
            <Text display="inline-block" weight={700} color="#960096">
              {maskPercent(props.distributed / 100)} e{' '}
              {maskPercent(
                (props.distributed + props.marginDistribution) / 100,
              )}
            </Text>{' '}
            em participação social. Deve-se lembrar que esta avaliação não
            possui garantia real e a empresa pode fazer uma oferta bem
            diferente, nos termos que achar necessário.{'"&"'} Este sistema de
            avaliação não leva em consideração o valor intrínseco da Startup
            avaliada e depende muito da opinião e sensibilidade da pessoa que
            está julgando o negócio.{'"'}
          </Text>
        </Card>
      </div>
    </section>
  );
}

export default ScoreCardSection;
