import React, { useState } from 'react';
import List from '../../components/list';
import MethodologyForm from '../../components/form/methodology/Methodology';
import { findSatus } from '../../core/enums';
import { useAuth } from '../../core/hooks/auth';
import { EnabledActionsCrudEnum } from '../../core/interfaces/IActionsCrud';
import { useNavigate } from 'react-router-dom';
import { convertDateToString } from '../../helpers/dates.helper';

function MethodologyList() {
  const [formName, setFormName] = useState<string>('');
  const { logged } = useAuth();
  const id = logged?.client && logged?.client;

  const navigate = useNavigate();

  const { VIEW, EDIT } = EnabledActionsCrudEnum;

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => (
        <div style={{ minWidth: '150px' }}>{value}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return findSatus(status);
      },
    },
    {
      title: 'Data',
      dataIndex: 'creation',
      key: 'creation',
      render: (creation: string) => {
        return convertDateToString(creation);
      },
    },
    {
      title: 'Número de startups',
      dataIndex: 'countStartup',
      key: 'countStartup',
      render: (startups: any) => {
        return startups;
      },
    },
  ];

  return (
    <>
      <List
        id="entityId"
        model="methodology"
        dataSource="methodology/list-all-by-accelerator"
        columns={columns}
        edit={true}
        form={(values) => (
          <MethodologyForm setFormName={setFormName} initialValues={values} />
        )}
        formName={formName}
        params={{
          client: id,
        }}
        view={(row) => navigate(`/methodologies/methodology/${row.entityId}`)}
        getActionsEnable={(row) =>
          row.status === 'PENDING' || row.status === 'ACTIVE'
            ? [VIEW, EDIT]
            : [VIEW]
        }
        title="Metodologia"
        modalWidth={'100%'}
        modalBackgroundColor="var(--grey-color)"
        paginationBottom="bottomRight"
      />
    </>
  );
}

export default MethodologyList;
