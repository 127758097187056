import styled from 'styled-components';

const WrapperCards = styled.div`
  gap: 1rem;
  display: flex;
  overflow: auto;
  margin: 1rem 0;
  justify-content: flex-start;
  padding-bottom: 5px;

  @media (max-width: 1400px) {
    & > * {
      min-width: 300px;
      max-width: 300px;
    }
  }
`;

export { WrapperCards };
