import StatusEnum from '../core/enums/StatusEnum';
import { IDiagnosticResult } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';

class DiagnosticResultService extends CrudService {
  constructor() {
    super({
      requestMapping: '/diagnostic',
    });
  }

  public static init(): DiagnosticResultService {
    return new DiagnosticResultService();
  }

  public getByStartupAndStatus(
    startupId: number,
    status: StatusEnum,
  ): Promise<IDiagnosticResult> {
    const params = {
      startup: startupId,
      status,
    };
    return api
      .get<IDiagnosticResult>(`${this.requestMapping}/by-startup-and-status`, {
        params,
      })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public getLastDiagnostic(startupId: number): Promise<IDiagnosticResult> {
    const params = {
      startup: startupId,
    };
    return api
      .get<IDiagnosticResult>(`${this.requestMapping}/get-last-diagnostic`, {
        params,
      })
      .then((response) => response.data)
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default DiagnosticResultService;
