import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  padding: 1.875rem 4.625rem;
`;

export const ProfileBannerContainer = styled.div`
  width: 100%;

  background: rgb(11, 54, 92);
  background: radial-gradient(
    circle,
    rgba(11, 54, 92, 1) 40%,
    rgba(8, 45, 64, 1) 94%
  );

  margin-top: 5rem;
  padding: 0.75rem;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;

  height: 100%;
`;
