import ICourseShowCase from '../../core/interfaces/course/ICourseShowCase';
import IOngoingCourses from '../../core/interfaces/course/IOngoingCourses';
import IPageable from '../../core/interfaces/IPageable';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';
import CrudService from '../CrudService';

export type SortCourseParam = 'A' | 'Z' | 'MR' | 'MA';

interface CoursesByAcceleratorIdParams {
  category?: number;
  sort?: SortCourseParam;
}

class CourseService extends CrudService {
  constructor() {
    super({
      requestMapping: '/course',
    });
  }

  public static init() {
    return new CourseService();
  }

  public showCase(
    data?: Record<string, unknown>,
  ): Promise<IPageable<ICourseShowCase>> {
    const params = {
      page: data?.page,
      size: data?.pageSize,
      sort: 'entityId,desc',
      teacherId: data?.teacherId,
    };

    return api
      .get<IPageable<ICourseShowCase>>(`${this.requestMapping}/showcase`, {
        params,
      })
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public showCaseById(
    id: number,
    studentId?: number,
  ): Promise<ICourseShowCase> {
    return api
      .get(`${this.requestMapping}/showcase/${id}`, {
        params: { studentId },
      })
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public getName(id: number): Promise<{ name: string; videoUrl: string }> {
    return api
      .get(`${this.requestMapping}/get-name/${id}`)
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public getOngoingCourses() {
    return api
      .get<IOngoingCourses[]>(`${this.requestMapping}/ongoing-courses/`)
      .then(({ data }) => data)
      .catch(errorCallback);
  }

  public async fetchCoursesByAcceleratorId(
    id: number,
    params?: CoursesByAcceleratorIdParams,
  ): Promise<ICourseShowCase[]> {
    const response = await api.get(
      `${this.requestMapping}/showcase-accelerator`,
      {
        params: {
          accelerator: id,
          sort: params?.sort ?? 'A',
          category: params?.category,
        },
      },
    );

    return response.data;
  }
}

export default CourseService;
