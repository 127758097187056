import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import jsonAnimate from '../../assets/animation/session-expired.json';
function SessionExpied() {
  const navigate = useNavigate();
  return (
    <Result
      title={'Sessão Expirada'}
      subTitle={'Sua sessão expirou, por favor faça login novamente.'}
      extra={
        <Button onClick={() => navigate('/')} type="primary">
          Logar
        </Button>
      }
    >
      <Player
        autoplay
        loop
        src={jsonAnimate}
        style={{ height: '300px', width: '300px' }}
      />
    </Result>
  );
}

export default SessionExpied;
