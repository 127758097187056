import React from 'react';
import DiagnosisInfo from '../Infos/DiagnosisInfo';
import ContentBase from '../templates/ContentBase';
import DianosisList from './DianosisList';

function Diagnosis() {
  return (
    <ContentBase
      title="Diagnóstico"
      modalContent={<DiagnosisInfo />}
      content={<DianosisList />}
    />
  );
}

export default Diagnosis;
