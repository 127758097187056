import { useNavigate } from 'react-router-dom';
import { IPropsModal } from '../../core/interfaces/modal/IPropsModal';
import ModalConfirm from '../../pages/courses/ModalConfirm';
import Text from '../Text';

interface Props extends IPropsModal {
  userId: number;
}

function ConfirmStartupJourneyReport({ onCancel, visible, userId }: Props) {
  const navigate = useNavigate();

  const confirm = () => {
    navigate(`/dashboard/startup/details/${userId}/journey-report`);
  };

  return (
    <ModalConfirm
      visible={visible}
      confirm={confirm}
      onCancel={onCancel}
      title="Gerar relatório"
      textConfirm="Gerar relatório"
    >
      <Text size={18} align="center" weight={400}>
        O relatório que será gerado corresponderá a todas as etapas do processo
        de aceleração que a startup está envolvida, incluindo as etapas ainda
        não alcançadas. Vale ressaltar também que não haverá um
        {'"histórico"'} de relatórios, podendo ser gerado um novo sempre que for
        necessário.
      </Text>
    </ModalConfirm>
  );
}

export default ConfirmStartupJourneyReport;
