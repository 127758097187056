import { SidebarWrapper } from './styles';
import logo from '../../../assets/images/logo-colorful.png';
import { GiHamburgerMenu } from 'react-icons/gi';

type Props = {
  children: React.ReactNode;
};

function Sidebar({ children }: Props) {
  return (
    <SidebarWrapper
      breakpoint="lg"
      collapsedWidth={0}
      trigger={<GiHamburgerMenu size={19} />}
      collapsible
    >
      <img
        src={logo}
        alt="innovation studio"
        width="100%"
        height="60"
        style={{
          objectFit: 'contain',
        }}
      />
      {children}
    </SidebarWrapper>
  );
}

export default Sidebar;
