import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Radio, Spin, RadioChangeEvent } from 'antd';
import Title from '../../../components/Title';
import useLoading from '../../../core/hooks/useLoading';
import ExerciseService from '../../../services/exercise/ExerciseService';
import ErrorNotification from '../../../components/notifications/errorNotification';
import IObjectiveQuestions from '../../../core/interfaces/course/IObjectiveQuestion';
import DragAndDropFile from '../../../components/DragAndDropFile/DragAndDropFile';
import { BoxContainer, BoxWhite, PurpleButton } from '../../../GlobalStyles';
import Divider from '../../../components/Divider';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { BoxRadio, Description } from './style';

interface IExercise {
  entityId: number;
  name: string;
  description: string;
  exerciseQuizList: Array<{
    entityId: number;
    question: string;
    type: string;
    objectiveQuestions?: IObjectiveQuestions[];
  }>;
}

function ExerciseAnswer() {
  const params = useParams();

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [exercise, setExercise] = useState<IExercise>();
  const [objectiveAnswer, setObjectiveAnswer] = useState<number>();
  const { loading } = useLoading();
  const exerciseService = useMemo(() => ExerciseService.init(), []);

  const getExercise = () => {
    exerciseService
      .findById(Number(params.id))
      .then((response) => {
        setExercise(response);
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  const getDataForm = () => {
    // return {
    //     answer: "Resposta 1",
    //     exerciseQuiz: {
    //         entityId: 28,
    //         type: "OBJECTIVE"
    //     },
    //     file: ""
    // }
  };

  const onFinish = () => {
    // showLoading()
    getDataForm();
    // studentAnswerService
    //     .create(getDataForm())
    //     .then(() => {
    //         notificationMessage()
    //     })
    //     .catch(() =>
    //         ErrorNotification({ defaultMessage: "erro ao responder o exercício" })
    //     )
    //     .finally(hideLoading)
  };

  const uploadFile = (file: File) => {
    console.log(file);
  };

  const objectiveAnswerHandler = (value: number) => {
    setObjectiveAnswer(value);
  };

  const getQuestionType = (
    id: number,
    type: string,
    objectiveQuestions: IObjectiveQuestions[],
  ) => {
    switch (type) {
      case 'OBJECTIVE':
        return (
          <Radio.Group
            key={`options-${id}`}
            onChange={(e: RadioChangeEvent) =>
              objectiveAnswerHandler(e.target.value)
            }
            value={objectiveAnswer}
          >
            <BoxRadio>
              {objectiveQuestions.map((options) => {
                return (
                  <Radio key={options.entityId} value={options.description}>
                    {options.description}
                  </Radio>
                );
              })}
            </BoxRadio>
          </Radio.Group>
        );
        break;
      case 'ATTACHMENT':
        return (
          <DragAndDropFile
            maxFiles={1}
            fileType={['application/pdf']}
            description="Suporta apenas arquivos pdf"
            onFileChange={(file) => uploadFile(file)}
          />
        );
        break;
      case 'DISCURSIVE':
        return (
          <TextArea
            showCount
            style={{ resize: 'none' }}
            rows={5}
            placeholder="Sua resposta aqui"
            maxLength={1024}
          />
        );
        break;
      default:
        return <>Erro ao carregar questão!</>;
    }
  };

  useEffect(() => {
    getExercise();
  }, []);

  return (
    <BoxContainer>
      <BoxWhite>
        <Title size={20} margin="0 0 10px 0">
          {exercise?.name}
        </Title>
        <Divider color="#a209a2" margin="0 0 10px 0" />
        <Description>
          {exercise?.description && parse(exercise.description)}
        </Description>
      </BoxWhite>
      <Spin spinning={loading}>
        <Form
          name={'exerciseAnswerForm'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          {exercise?.exerciseQuizList.map((exerciseItem) => (
            <BoxWhite key={exerciseItem.entityId}>
              <Form.Item
                name={`question-${exerciseItem.entityId}`}
                label={parse(exerciseItem.question)}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                {getQuestionType(
                  exerciseItem.entityId,
                  exerciseItem.type,
                  exerciseItem.objectiveQuestions ?? [],
                )}
              </Form.Item>
            </BoxWhite>
          ))}
          <Divider color="#a209a2" margin="0 0 10px 0" />
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{
              width: '200px',
              marginLeft: 'calc(50% - 100px)',
              marginTop: '10px',
            }}
          >
            Enviar respostas
          </PurpleButton>
        </Form>
      </Spin>
    </BoxContainer>
  );
}

export default ExerciseAnswer;
