import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  CloseCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Empty, Input, Row, Table } from 'antd';
import api from '../../core/services/api';
import ErrorNotification from '../notifications/errorNotification';
import notificationMessage from '../notifications/notificationMessage';
import { BodyList, BoxButton, TableList } from './style';
import { ButtonContainer } from '../../GlobalStyles';
import { clone, isEmpty } from 'lodash';
import ActionsCrud from '../ActionsCrud/ActionsCrud';
import {
  EnabledActionsCrudEnum,
  ParamsActionsCrud,
} from '../../core/interfaces/IActionsCrud';
import { IListRef } from '../../core/interfaces/IListRef';

export interface IList extends Omit<ParamsActionsCrud, 'edit' | 'view'> {
  id: string;
  columns: Array<any>;
  model: string;
  title?: string;
  dataSource?: string;
  sortEntityId?: boolean;
  edit?: boolean;
  formName?: string;
  changeStatus?: boolean;
  changeEnabled?: boolean;
  modalWidth?: string;
  params?: any;
  paginationTop?: TablePaginationPosition;
  paginationBottom?: TablePaginationPosition;
  buttonText?: string;
  modalBackgroundColor?: string;
  closeDrawer?: () => void;
  view?: (row: IRow) => void;
  getActionsEnable?: (row: IRow) => EnabledActionsCrudEnum[];

  form(initialValues: Record<string, unknown>): React.ReactElement;
}

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type IRow = Record<string, unknown>;

const List = forwardRef<IListRef, IList>((props, ref) => {
  const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');
  const [search, setSearch] = useState<boolean>(true);
  const [searchedText, setSearchedText] = useState<string>('');
  const [state, setState] = useState({
    dataSource: [] as Array<any>,
    isLoading: true,
    modal: false,
    editData: {} as Record<string, any>,
    forceTableUpdate: false,
  });
  const [initialValues, setInitialValues] = useState<IRow>({});

  useImperativeHandle(ref, () => ({
    closeDrawer: () => setState({ ...state, modal: false }),
  }));

  const columns = [
    ...props.columns,
    {
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      dataIndex: 'actions',
      title: 'Ações',
      fixed: 'right',
      width: 120,
      render: (_: unknown, row: IRow) => {
        const { view, enabledActions, getActionsEnable } = props;
        const actions = getActionsEnable
          ? getActionsEnable(row)
          : enabledActions;

        return (
          <ActionsCrud
            boxPosition="leftTop"
            view={() => view && view(row)}
            edit={() => handleEdit(row)}
            enabledActions={actions}
            remove={() => handleDelete(row.entityId as number)}
          />
        );
      },
    },
  ];

  const paginationPosition = () => {
    if (props.paginationBottom !== undefined) {
      setBottom(props.paginationBottom);
    }
  };

  const showSearch = () => {
    const inputSearch = document.getElementById(
      'inputSearch',
    ) as HTMLInputElement;
    setSearch(!search);

    if (search) {
      inputSearch.classList.remove('hideAnimation');
      inputSearch.classList.add('showAnimation');
    } else {
      inputSearch.classList.remove('showAnimation');
      inputSearch.classList.add('hideAnimation');
    }
  };

  const onSearch = (target: any) => {
    if (target.value.length > 0) {
      setSearchedText(target.value);
    } else {
      ErrorNotification({ defaultMessage: 'Digite algo para buscar!' });
    }
  };

  const Reset = (target: any) => {
    if (target.value.length === 0) {
      setSearchedText('');
    }
  };

  const removeActionRow = (row: IRow) => {
    const copy = clone(row);
    delete copy.actions;
    return copy;
  };

  const handleEdit = (row: IRow) => {
    setInitialValues(removeActionRow(row));
    setState((prev) => ({ ...prev, editData: row, modal: true }));
  };

  const handleCreate = () => {
    setInitialValues({});
    setState((prev) => ({ ...prev, editData: [], modal: true }));
  };

  const handleDelete = async (entityId: number) => {
    await api
      .delete(`${props.model}/${entityId}`)
      .then(() => {
        notificationMessage('status');
        setState((prev) => ({ ...prev, modal: false }));
        listAll();
      })
      .catch((error) => {
        ErrorNotification({ error: error.response.data });
      });
  };

  const handleCloseDrawer = () => {
    setInitialValues({});
    setState((prev) => ({
      ...prev,
      editeData: [],
      modal: false,
    }));

    if (props.closeDrawer) props.closeDrawer();
  };

  const listAll = async () => {
    const newDataSource: Array<any> = [];

    setState((prev) => ({ ...prev, isLoading: true }));

    try {
      let response;
      if (props.dataSource && !isEmpty(props.params)) {
        response = await api.get(props.dataSource || props.model, {
          params: props.params,
        });
      } else {
        response = await api.get(props.dataSource || props.model);
      }

      if (response && response.status === 200) {
        newDataSource.push(...response.data);
      }
    } catch (e: any) {
      ErrorNotification({ error: e.response.message });
      setState((prev) => ({ ...prev, isLoading: false }));
    }

    if (props.sortEntityId !== false) {
      newDataSource.sort((a, b) => a.entityId - b.entityId);
      newDataSource.forEach((data, i) => {
        data.index = i + 1;
      });
    }
    setState((prev) => ({
      ...prev,
      dataSource: newDataSource,
      isLoading: false,
    }));
  };

  useEffect(() => {
    listAll();
    paginationPosition();
  }, [props.params]);

  return (
    <>
      <BodyList>
        <ButtonContainer>
          <Row
            style={{
              marginLeft: '5px',
              marginTop: '5px',
              position: 'relative',
            }}
          >
            <Col>
              <Button
                className="colorButton circledButton"
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleCreate}
              />
              <Button
                className="noColorButton circledButton"
                style={{
                  position: 'absolute',
                  zIndex: '10',
                  height: '36px',
                }}
                type="text"
                icon={<SearchOutlined />}
                onClick={showSearch}
              />
            </Col>
            <Col>
              <Input
                id="inputSearch"
                maxLength={18}
                placeholder="Pressione ENTER para buscar"
                style={{ opacity: 0 }}
                onPressEnter={(e) => onSearch(e.target)}
                onChange={(e) => Reset(e.target)}
              />
            </Col>
          </Row>
        </ButtonContainer>
        <TableList>
          <Table
            scroll={{ x: 400 }}
            tableLayout="auto"
            rowKey={props.id}
            dataSource={state.dataSource}
            columns={columns}
            loading={state.isLoading}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Não existem dados cadastrados'}
                />
              ),
            }}
            pagination={{
              position: [bottom],
              showTitle: false,
            }}
          />
        </TableList>
        <Drawer
          title={
            (state.editData.entityId ? 'Editar ' : 'Cadastrar ') + props.title
          }
          open={state.modal}
          bodyStyle={
            props.modalBackgroundColor !== undefined
              ? { backgroundColor: props.modalBackgroundColor }
              : { backgroundColor: 'var(--white-color)' }
          }
          closable={false}
          destroyOnClose={true}
          className="reset-padding-body-mobile"
          extra={<CloseCircleOutlined onClick={handleCloseDrawer} />}
          footer={
            <>
              {props.formName && (
                <BoxButton>
                  <Button
                    className="colorButton"
                    key="submit"
                    form={props.formName ?? 'form'}
                    htmlType="submit"
                    formAction={'form'}
                    type="primary"
                  >
                    {props.buttonText ?? 'Salvar'}
                  </Button>
                </BoxButton>
              )}
            </>
          }
          width={props.modalWidth}
        >
          {props.form(initialValues)}
        </Drawer>
      </BodyList>
    </>
  );
});

List.displayName = 'List';

export default List;
