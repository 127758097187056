import { FC, useMemo } from 'react';
import { Radio, Checkbox } from 'antd';
import FieldWrapper from '../FieldWrapper';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import IFieldComponentParams from '../../../../core/interfaces/attend-course/IFieldComponentParams';
import { CheckboxOptionType } from 'antd/es';
import { WrapperCheckbox } from './styles';
import { StatusAnswerEnum } from '../../../../core/enums/StatusAnswerEnum';

function ObjectiveField({ data, values, changeValue }: IFieldComponentParams) {
  const { question, entityId, type, objectiveQuestions } = data.quiz;
  const containAnswer = data.answers.length;

  const options: CheckboxOptionType[] = useMemo(() => {
    return (objectiveQuestions ?? []).map((objectiveItem) => ({
      value: objectiveItem.description,
      label: (
        <div className={containAnswer && objectiveItem.status ? 'active' : ''}>
          {objectiveItem.description}
        </div>
      ),
    }));
  }, [objectiveQuestions, containAnswer]);

  const isMultiple = type.toString().includes('MULTIPLE');
  const styles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
  };

  const name = `objective-${entityId}`;
  const { answer } = values[name] ?? {};

  const value = containAnswer ? data.answers : answer;

  const getStatus = () => {
    const rightQuestions = (objectiveQuestions ?? [])
      .filter((objectiveItem) => objectiveItem.status)
      .map((objectiveItem) => objectiveItem.description);

    const questionApproved = data.answers.every((item) =>
      rightQuestions.includes(item),
    );

    return questionApproved
      ? StatusAnswerEnum.Approved
      : StatusAnswerEnum.Disapproved;
  };

  return (
    <FieldWrapper
      question={question}
      status={containAnswer ? getStatus() : null}
    >
      {isMultiple ? (
        <WrapperCheckbox>
          <Checkbox.Group
            options={options}
            value={(value as CheckboxValueType[]) ?? []}
            style={styles}
            onChange={(value) =>
              changeValue(name, {
                answer: value as string[],
              })
            }
          />
        </WrapperCheckbox>
      ) : (
        <Radio.Group
          options={options}
          onChange={({ target }) =>
            changeValue(name, {
              answer: target.value,
            })
          }
          value={answer}
          style={styles}
        />
      )}
    </FieldWrapper>
  );
}

export default ObjectiveField;
