import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IAmountInvestment } from '../core/interfaces/IAmountInvestment';
import IInvestmentRound from '../core/interfaces/IInvestmentRound';
import IInvestmentRoundStartup from '../core/interfaces/IInvestmentRoundStartup';
import { IStartup } from '../core/interfaces';

class InvestmentRoundService extends CrudService {
  static init(): InvestmentRoundService {
    return new InvestmentRoundService();
  }

  constructor() {
    super({
      requestMapping: '/investment-round',
      params: {
        sort: 'entityId, asc',
      },
    });
  }

  public listAll(): Promise<IInvestmentRound[]> {
    return api
      .get<IInvestmentRound[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(
    ivestimentRound: IInvestmentRound,
    id: number,
  ): Promise<IInvestmentRound> {
    return api
      .post<IInvestmentRound>(
        `${this.requestMapping}/user/${id}`,
        ivestimentRound,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getByUserInvestor(
    id: number,
    active: boolean,
  ): Promise<IInvestmentRound[]> {
    return api
      .get<IInvestmentRound[]>(`${this.requestMapping}/user/${id}/${active}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllActive(active: boolean): Promise<IInvestmentRound[]> {
    return api
      .get<IInvestmentRound[]>(
        `${this.requestMapping}/get-all-active/${active}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public sendRequestInvestmentRound(
    id: number,
    payload: IInvestmentRoundStartup,
  ): Promise<IInvestmentRoundStartup> {
    return api
      .post(`${this.requestMapping}/send-request-investment/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllInvestmentRoundStartupByInvestmentRoundId(
    id: number,
  ): Promise<IInvestmentRoundStartup[]> {
    return api
      .get<IInvestmentRoundStartup[]>(
        `${this.requestMapping}/get-all-investment-round-startup/${id}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllInvestmentRoundStartupByStartupIdAndInvestmentRoundId(
    startupId: number,
    roundInvestmentId: number,
  ): Promise<IInvestmentRoundStartup[]> {
    return api
      .get<IInvestmentRoundStartup[]>(
        `${this.requestMapping}/get-all-investment-round-startup/${startupId}/round-investment/${roundInvestmentId}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public findByStartupId(
    id: number,
    investmentRoundId: number,
  ): Promise<IInvestmentRoundStartup> {
    return api
      .get<IInvestmentRoundStartup>(
        `${this.requestMapping}/get-investment-round-startup-by-startup/${id}`,
        {
          params: { investmentRoundId },
        },
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public sendSignalInvestment(
    id: number,
    payload: IInvestmentRoundStartup,
  ): Promise<IInvestmentRoundStartup> {
    return api
      .post(`${this.requestMapping}/send-signal-investment/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public finishInvestmentRound(id: number): Promise<IInvestmentRound> {
    return api
      .put(`${this.requestMapping}/finish-investment-round/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllInitialized(): Promise<IInvestmentRound[]> {
    return api
      .get<IInvestmentRound[]>(`${this.requestMapping}/get-all-initialized`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getStartupsByInvestmentRound(id: number): Promise<IStartup[]> {
    return api
      .get<IStartup[]>(
        `${this.requestMapping}/get-startups-by-investment-round/${id}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getInvestmentRoundStartupByInvestmentRoundIdAndStartupId(
    id: number,
    startupId: number,
  ): Promise<IInvestmentRoundStartup> {
    return api
      .get<IInvestmentRoundStartup>(
        `${this.requestMapping}/get-investment-round-startup/${id}/startup/${startupId}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default InvestmentRoundService;
