import { isNull } from 'lodash';
import IExercise from '../core/interfaces/course/IExercise';
import ITopic from '../core/interfaces/course/ITopic';

enum StatusExerciseEnum {
  PENDING,
  APPROVED,
  DISAPPROVED,
  PENDING_APPROVAL,
}

const getNumberOfClassesAttended = (topic: ITopic): number => {
  return topic.lessons.filter((lesson) => lesson.isComplete).length;
};

const getAmountOfExercisesPerformed = (topic: ITopic): number => {
  return topic.exercises.filter((exercise) => !isNull(exercise.grade)).length;
};

const getStatusExercise = (exercise: IExercise) => {
  const { grade, isPendingApproval } = exercise;
  const { PENDING_APPROVAL, APPROVED, DISAPPROVED, PENDING } =
    StatusExerciseEnum;

  if (isPendingApproval) return PENDING_APPROVAL;
  if (isNull(grade)) return PENDING;

  return grade >= 5 ? APPROVED : DISAPPROVED;
};

const getColorExercise = (exercise: IExercise) => {
  const colors = {
    [StatusExerciseEnum.PENDING_APPROVAL]: '#05233D',
    [StatusExerciseEnum.PENDING]: '#EE7714',
    [StatusExerciseEnum.APPROVED]: '#3e9f31',
    [StatusExerciseEnum.DISAPPROVED]: '#fd0d03',
  };
  const status = getStatusExercise(exercise);
  return colors[status];
};

const getLabelExercise = (exercise: IExercise) => {
  const grade = exercise.grade?.toFixed(1);
  const texts = {
    [StatusExerciseEnum.PENDING_APPROVAL]: 'Aguardando avaliação',
    [StatusExerciseEnum.PENDING]: 'Pendente',
    [StatusExerciseEnum.APPROVED]: `Nota ${grade}`,
    [StatusExerciseEnum.DISAPPROVED]: `Nota ${grade}`,
  };
  const status = getStatusExercise(exercise);
  return texts[status];
};

export {
  getLabelExercise,
  getColorExercise,
  getNumberOfClassesAttended,
  getAmountOfExercisesPerformed,
};
