import React from 'react';
import BoxInfo from './Box/InfoBox';

function InvestmentRoundInfo() {
  return (
    <BoxInfo
      title="Rodada de investimento"
      description={
        <p>
          A rodada de investimento é uma forma das startups captar recursos para
          acelerar o crescimento de seus negócios. Neste momento, você,
          investidor, pode cadastrar uma nova rodada de investimento e as
          startups poderão visualizar e se cadastrar. Você terá acesso às
          startups cadastradas e sinalizar interesse em investir ou enviar um
          mensagem.
        </p>
      }
    />
  );
}

export default InvestmentRoundInfo;
