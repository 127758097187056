import styled from 'styled-components';
import { ModalBody } from '../../GlobalStyles';

const CustomModal = styled(ModalBody)`
  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-close {
    display: none;
  }
`;

const ContentPicture = styled.div`
  background-image: linear-gradient(to right, #36175c, #05233d);
  border-radius: 10px;

  & > *:nth-child(1),
  & > *:nth-child(2) {
    border-bottom: 1px solid #fff;
  }

  & > * {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  & > footer {
    gap: 3rem;
  }
`;

const ImageContainer = styled.div<{
  url: string;
}>`
  width: 180px;
  height: 180px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
`;

export { ContentPicture, ImageContainer, CustomModal };
