import React from 'react';
import { BiBuildings } from 'react-icons/bi';
import { Label } from '../../GlobalStyles';
import Text from '../Text';
import Title from '../Title';
import {
  Body,
  Footer,
  Wrapper,
  ImgPicture,
  SkillsWrapper,
  CompanyInformation,
} from './styles';
import imgDefault from '../../assets/images/image-default.png';
import { isEmpty } from 'lodash';

type Props = {
  img?: string;
  name: string;
  company: string;
  office: string;
  skills?: string[];
};

function MentorCard({
  name,
  office,
  company,
  skills = [],
  img = imgDefault,
}: Props) {
  const startSkills = skills.splice(0, 3);

  return (
    <Wrapper>
      <Body>
        <div>
          <ImgPicture src={img} alt="imagem perfil" width={65} height={65} />
        </div>

        <div>
          <Title lineClamp={1} color="#960096" size={20}>
            {name}
          </Title>
          <Text>{office}</Text>
        </div>

        <div>
          <CompanyInformation>
            <BiBuildings size={20} />
            <Text weight={700}>{company}</Text>
          </CompanyInformation>

          {!isEmpty(skills) && (
            <SkillsWrapper>
              {startSkills.map((skill) => (
                <Label key={`skill_key_${skill}`}>{skill}</Label>
              ))}
              <Label bgColor="#36175C" color="#fff">
                +{skills.length}
              </Label>
            </SkillsWrapper>
          )}
        </div>
      </Body>
      <Footer>Visitar Perfil</Footer>
    </Wrapper>
  );
}

export default MentorCard;
