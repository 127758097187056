/**
 * Check if password is valid
 *
 * Rules:
 * - Password must have at least 8 characters and at most 12
 * - Password must have at least one number
 * - Password must have at least one uppercase letter
 * - Password must have at least one lowercase letter
 * - Password must have at least one special character
 *
 * @param password
 * @returns boolean
 */
export function isValidPassword(password: string): boolean {
  const isValidLength = /^.{8,12}$/.test(password);
  if (!isValidLength) {
    return false;
  }

  const isValidFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(password);
  if (!isValidFormat) {
    return false;
  }

  const hasSpecialCharacter = /[^A-Za-z0-9]/.test(password);

  return hasSpecialCharacter;
}
