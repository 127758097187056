import styled from 'styled-components';

const Wrapper = styled.div`
  max-height: calc(100vh - 130px);
  height: 100%;
  border-right: 1px solid var(--support-color);

  & ul {
    overflow-y: auto;
  }
`;

const Header = styled.header`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid var(--support-color);
`;

const BodyItem = styled.li`
  padding: 11px;
  border-bottom: 1px solid var(--support-color);
  display: flex;
  justify-content: space-between;
  gap: 6px;
  cursor: pointer;

  &:hover,
  &.active {
    background: #f1f3f2;
    box-shadow: inset 3px 0 0 0 var(--thirdy-color);
  }

  & > div:first-child {
    display: flex;
    gap: 8px;
    max-width: 190px;
    align-items: center;
  }

  & img {
    border-radius: 50%;
    object-fit: cover;
  }
`;

const ActionWrapper = styled.div`
  display: grid;
  gap: 6px;
  align-items: flex-start;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: #960096;
    justify-self: flex-end;
  }
`;

export { Wrapper, ActionWrapper, Header, BodyItem };
