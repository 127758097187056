import React, { useState, useMemo, useEffect } from 'react';
import { Collapse, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { Card } from '../../../../GlobalStyles';
import { CaretRightOutlined } from '@ant-design/icons';
import ContentBase from '../../../templates/ContentBase';
import { Panel, StepItem, WrapperStep } from './styles';
import IPhases from '../../../../core/interfaces/models/IPhases';
import IPillars from '../../../../core/interfaces/models/IPillars';
import MethodologyService from '../../../../services/MethodologyService';
import CoursePhaseService from '../../../../services/course-phase/CoursePhaseService';
import IPillarPhaseCourse from '../../../../core/interfaces/course-phase/IPillarPhaseCourse';
import ErrorNotification from '../../../../components/notifications/errorNotification';
import useLoading from '../../../../core/hooks/useLoading';
import CoursePreviewCard from '../../../../components/form/methodology/CoursePreviewCard';
import { sumBy } from 'lodash';

function MethodologyFormView() {
  const params = useParams();
  const methodologyService = MethodologyService.init();
  const coursePhaseService = useMemo(() => CoursePhaseService.init(), []);

  const [nameMethodology, setNameMethodology] = useState<string>('');
  const [pillars, setPillars] = useState<IPillars[]>([]);
  const [phases, setPhases] = useState<IPhases[]>([]);
  const [coursePhases, setCoursePhases] = useState<IPillarPhaseCourse[]>([]);

  const { loading, showLoading, hideLoading } = useLoading();

  const getData = async () => {
    try {
      showLoading();

      const methodology = await methodologyService.findById(Number(params.id));
      const courses = await coursePhaseService.findByMethodology(
        methodology.entityId,
      );

      setCoursePhases(courses);
      setPillars(methodology.pillars);
      setPhases(methodology.phases);
      setNameMethodology(methodology.name);
    } catch (error) {
      ErrorNotification({
        defaultMessage: 'Erro ao obter informações',
      });
    } finally {
      hideLoading();
    }
  };

  const courseListing = (phase: IPhases, pillar: IPillars, name: string) => {
    return (
      <StepItem>
        {name}
        {coursePhases
          .filter((coursePhase) => {
            const isPhase = coursePhase.phase.entityId === phase.entityId;
            const isPillar = coursePhase.pillar.entityId === pillar.entityId;
            return isPillar && isPhase;
          })
          .map(({ course, entityId }, index) => {
            const topics = course.topics ?? [];
            const { duration, lessonQuantity } = topics.reduce(
              (acum, topic) => {
                acum.lessonQuantity += topic.lessons.length;
                acum.duration += sumBy(topic.lessons, 'duration');
                return acum;
              },
              { lessonQuantity: 0, duration: 0 },
            );

            return (
              <CoursePreviewCard
                showCloseIcon={false}
                duration={duration}
                lessonQuantity={lessonQuantity}
                title={course.name}
                key={`course_key_${index}`}
                srcImage={course.pictureName}
                entityId={Number(entityId)}
              />
            );
          })}
      </StepItem>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ContentBase
      questionIsVisible={false}
      title="Metodologia Cadastrada"
      content={
        <Spin spinning={loading}>
          <Card>
            <Text size={13} color="#960096" weight={700}>
              Nome da metodologia
            </Text>
            <Title lineHeight={1} size={24} margin="0 0 20px 0">
              {nameMethodology}
            </Title>

            <Text margin="0 0 6px 0" size={13} color="#960096" weight={700}>
              Pilares, fases, passos e conteúdos
            </Text>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 180 : 90} />
              )}
            >
              {pillars.map((pillar, index) => (
                <Panel
                  className="parent-item"
                  header={pillar.name}
                  key={`key_panel_${index}`}
                >
                  <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 180 : 90} />
                    )}
                  >
                    {phases.map((phase, index) => (
                      <Panel
                        header={phase.name}
                        key={`key_children_panel_${index}`}
                      >
                        <WrapperStep>
                          {phase.phases
                            ? phase.phases.map((subPhase) =>
                                courseListing(
                                  subPhase,
                                  pillar,
                                  `${index + 1} - ${phase.name}`,
                                ),
                              )
                            : courseListing(
                                phase,
                                pillar,
                                `${index + 1} - ${phase.name}`,
                              )}
                        </WrapperStep>
                      </Panel>
                    ))}
                  </Collapse>
                </Panel>
              ))}
            </Collapse>
          </Card>
        </Spin>
      }
    />
  );
}

export default MethodologyFormView;
