import styled from 'styled-components';
import { Carousel } from 'antd';

export const CarouselMobile = styled(Carousel)`
  &::before {
    content: '';
    left: 0;
    right: 0;
    top: 11px;
    height: 2px;
    display: block;
    background: var(--primary-color);
    position: absolute;
  }

  @media (min-width: 601px) {
    display: none !important;
  }
`;

const Box = styled.div<{
  marginBox?: string;
}>`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  position: relative;
  margin: ${({ marginBox }) => marginBox || '0 0 40px 0'};

  &::before {
    content: '';
    left: 0;
    right: 0;
    top: 11px;
    height: 2px;
    display: block;
    background: var(--primary-color);
    position: absolute;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const ItemProgress = styled.div<{
  accomplished?: boolean;
  selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 0;
  margin-top: 5px;

  & > span {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -5px;
    background-color: var(--white-color);
    border: 1px solid transparent;
    border-color: ${({ selected }) =>
      selected ? 'var(--thirdy-color)' : 'transparent'};
    border-radius: 50%;
    cursor: pointer;
  }

  & > span::before {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: calc(50% - 8px);
    background-color: ${({ accomplished }) =>
      `var(${accomplished ? '--primary-color' : '--support-color'})`};
  }

  @media (max-width: 500px) {
    min-width: 100%;
  }
`;

const Header = styled.div<{
  noExtraAlign?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 8px;

  .extra {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ noExtraAlign }) => (noExtraAlign ? '' : 'center')};
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 16px;

    & .extra {
      width: 100%;
    }
  }
`;

const BoxCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const Card = styled.div<{
  flexDirection?: string;
  noPointer?: boolean;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  min-width: 220px;
  max-width: 220px;
  padding: 5px 5px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  cursor: ${({ noPointer }) => (noPointer ? 'normal' : 'pointer')};

  .text {
    width: calc(100% - 20px);
  }

  .select,
  .selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border: 1px solid var(--primary-color);
  }

  .selected {
    background-color: var(--thirdy-color);
    border-color: var(--thirdy-color);

    svg {
      font-size: 12px;
      color: var(--white-color);
    }
  }
`;

const BoxCourse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin: 10px 0 10px 0;

  .course-name {
    font-weight: bold;
    background-color: var(--white-color);
    padding-right: 10px;
    z-index: 10;
  }

  .line::before {
    content: '';
    width: 100%;
    display: table-cell;
    position: absolute;
    left: 0;
    top: 12px;
    z-index: 1;
    border: 1px dotted var(--support-color);
  }

  .progress {
    padding-left: 10px;
    background-color: var(--white-color);
    z-index: 10;
  }
`;

export { Box, BoxCard, BoxCourse, Card, ItemProgress, Header };
