import React from 'react';
import InvestorInfo from '../Infos/InvestorInfo';
import ContentBase from '../templates/ContentBase';
import InvestorList from './InvestorList';

function Investor() {
  return (
    <>
      <ContentBase
        title="Investidor"
        modalContent={<InvestorInfo />}
        content={<InvestorList />}
      />
    </>
  );
}

export default Investor;
