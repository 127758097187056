import { useState } from 'react';
import { ButtonContainer, FormContainer, RegisterBox } from '../style';
import { Button, Form, FormInstance, Input, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import BaseScreen from '..';
import api from '../../../core/services/api';

function ForgotPassword() {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));
    const email = valuelMap.get('email');

    setConfirmLoading(true);
    await api
      .post('forgot-password', null, {
        params: {
          email,
        },
      })
      .then(() => {
        notification.success({
          message: `Siga as instruções enviadas para o email ${email}`,
        });
        navigate('/login');
      })
      .catch(() => {
        notification.info({
          message: 'E-mail não encontrado',
        });
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  return (
    <BaseScreen
      title="Esqueceu a senha?"
      subTitle="Diga seu e-mail para que possamos reconfigurar sua senha"
      content={
        <FormContainer>
          <Form
            name="loginForm"
            layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor insira um email válido!',
                },
              ]}
            >
              <Input placeholder="Digite seu e-mail" maxLength={100} />
            </Form.Item>
            <ButtonContainer>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                Enviar
              </Button>
            </ButtonContainer>
            <RegisterBox>
              <Link to="/">Voltar para login</Link>
            </RegisterBox>
          </Form>
        </FormContainer>
      }
    />
  );
}

export default ForgotPassword;
