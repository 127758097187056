import styled from 'styled-components';

const Container = styled.div<{
  borderTop: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-top: ${({ borderTop }) =>
    borderTop ? '1px solid var(--support-color)' : ''};

  .dashed,
  .default {
    background-color: var(--white-color);
    border-radius: 4px;
    border: 1px dashed var(--support-color);
  }

  .default {
    border: 1px solid var(--support-color);

    svg {
      margin-left: 5px;
      font-size: 12px;
    }
  }
`;

const BoxTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 10px 0;
`;

export { Container, BoxTags };
