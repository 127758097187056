import { onlyNumbers } from './string.helper';

const maskMoney = (value: number | string): string =>
  Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

const monetaryValueFormat = (value: string, isWithPrefix = false): string => {
  try {
    const cleanNumber = Number(onlyNumbers(value));
    const formattedNumber = maskMoney(cleanNumber / 100);

    return isWithPrefix
      ? formattedNumber
      : formattedNumber.replace('R$', '').trim();
  } catch (error) {
    return '0';
  }
};

const plainMoney = (value: string): string => {
  return value.replace(/\./g, '').replace(',', '.');
};

//mask percent
const maskPercent = (value: number | string): string =>
  Number(value).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });

export { maskMoney, monetaryValueFormat, plainMoney, maskPercent };
