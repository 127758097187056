import React from 'react';
import BoxInfo from '../../Infos/Box/InfoBox';
import image from '../../../assets/icons/actions-icons/ic-help.svg';

function ModalInfo() {
  return (
    <BoxInfo
      title="Usuários"
      image={image}
      description={
        <>
          <p>
            Aqui visualizamos todos os usuários cadastrados na plataforma
            Innovation Studio, contendo e-mail, status atual, nome do usuário e
            data de entrada. Podendo o Administrador editar ou excluir quaisquer
            desses usuários.
          </p>
        </>
      }
    />
  );
}

export default ModalInfo;
