import styled from 'styled-components';
import backgroundProfile from '../../../assets/images/background-profile.png';

const Box = styled.header`
  background-size: 100% 110px;
  padding: 22px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #05233d29;
  background-repeat: no-repeat;
  background-image: url(${backgroundProfile});
`;

const ProfilePicture = styled.div`
  position: relative;
  cursor: pointer;

  & > div {
    opacity: 0;
    position: absolute;
    top: 0;
    color: #fff;
    text-align: center;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  & img {
    object-fit: cover;
    box-shadow:
      0 0 0 1px #eee,
      0 0 0 5px #fff;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: calc(100% + 10px);
    left: -5px;
    height: calc(100% + 10px);
    bottom: -5px;
  }

  & > div,
  &::before {
    transition: 0.2s;
  }

  &:hover > div {
    opacity: 1;
  }

  &:hover::before {
    background-color: #05233d80;
  }
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;

  & > :nth-child(2) {
    margin-bottom: 20px;
  }
`;

export { ProfilePicture, PictureWrapper, Box };
