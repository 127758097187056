import React from 'react';
import { ButtonIconRound } from './style';

type colorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'default';
type typeType = 'default' | 'dashed';
interface ButtonIconProps {
  icon: React.ReactNode;
  onClick?: () => void;
  color: colorType;
  textColor?: string;
  iconWidth?: string;
  iconHeight?: string;
  typeShape?: typeType;
}

function ButtonIcon({
  icon,
  onClick,
  color,
  textColor = 'white',
  iconWidth = '24px',
  iconHeight = '24px',
  typeShape = 'default',
}: ButtonIconProps) {
  return (
    <ButtonIconRound
      color={color}
      onClick={onClick}
      textColor={textColor}
      width={iconWidth}
      height={iconHeight}
      typeShape={typeShape}
    >
      {icon}
    </ButtonIconRound>
  );
}

export default ButtonIcon;
