import { MenuProps } from 'rc-menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../core/hooks/auth';
import { routes } from '../../../core/routes/routes';
import { MenuWrapper } from './styles';

function TopicMenu() {
  const auth = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleMenu: MenuProps['onClick'] = ({ key }) => {
    navigate(key);
  };

  const menuItems = routes.map(
    (item) =>
      auth.isPermission(item.authorize) &&
      item.isMenu && {
        key: item.path,
        label: item.title,
        icon: item.icon,
        path: item.path,
      },
  );

  return (
    <MenuWrapper
      onClick={handleMenu}
      theme="dark"
      mode="inline"
      selectedKeys={[pathname]}
      items={menuItems}
    />
  );
}

export default TopicMenu;
