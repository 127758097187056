import React, { useMemo, useState } from 'react';
import ProfileBase from '../../../components/Profiles/ProfilesBase';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxContainer, BoxWhite } from '../../../GlobalStyles';
import { Collapse, Divider, Progress } from 'antd';
import GraphicPrePosMoney from './GraphicPrePosMoney';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import useLoading from '../../../core/hooks/useLoading';
import { BoxCollapse, BoxFile } from './style';
import PDFImage from '../../../assets/images/file-pdf-solid-240.png';
import FileService from '../../../services/FileService';
import errorNotification from '../../../components/notifications/errorNotification';

/* REMOVER OS COMENTÁRIOS A MEDIDA QUE FOR ADAPTANDO OS DADOS */

function InvestedStartup() {
  const { Panel } = Collapse;
  const { loading, showLoading, hideLoading } = useLoading();
  const [activePanel, setActivePanel] = useState<number[]>([]);

  const fileService = useMemo(() => FileService.init(), []);
  /* DADOS DA STARTUP INVESTIDA */
  const MOCKENTITY = {
    entityId: 1,
    name: 'Lutti',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet',
    speeder: 'aceleradora1@gmail.com',
    entry: '2023/01/01',
    lastUpdate: '2023/01/25',
    status: 'Ativo',
    email: 'startup1@gmail.com',
  };
  /* DADOS DO GRÁFICO PRÉ/PÓS-MONEY */
  const MOCKGRAPHICADATA = [
    {
      month: 'Janeiro',
      value: '1.235.01,00',
    },
    {
      month: 'Fevereiro',
      value: '2.235.01,00',
    },
    {
      month: 'Março',
      value: '3.235.01,00',
    },
    {
      month: 'Abril',
      value: '4.235.01,00',
    },
    {
      month: 'Maio',
      value: '3.111.01,00',
    },
    {
      month: 'Junho',
      value: '4.444.01,00',
    },
    {
      month: 'Julho',
      value: '5.235.01,00',
    },
    {
      month: 'Agosto',
      value: '7.235.01,00',
    },
    {
      month: 'Setembro',
      value: '6.235.01,00',
    },
  ];

  const getBoxItem = (
    title: string,
    content: any,
    width?: string,
    height?: string,
  ) => {
    return (
      <BoxWhite width={width} height={height}>
        <Title size={18}>{title}</Title>
        {content}
      </BoxWhite>
    );
  };

  const checkPanel = (panelKey: number) => {
    return activePanel.includes(panelKey);
  };

  const handlePanel = (panelKey: number) => {
    if (checkPanel(panelKey)) {
      setActivePanel(activePanel.filter((key) => panelKey !== key));
    } else {
      setActivePanel(activePanel.concat(panelKey));
    }
  };

  const openPDF = (fileName: string) => {
    showLoading();
    fileService
      .openByName(fileName)
      .catch(() =>
        errorNotification({ defaultMessage: 'Erro ao exibir o arquivo' }),
      )
      .finally(hideLoading);
  };

  return (
    <BoxContainer>
      <ProfileBase
        goBackPath="dashboard"
        title="Startup investida"
        entity={MOCKENTITY}
      />
      <BoxContainer flexDirection="row" noPadding={true} gap="10px">
        {getBoxItem(
          'Maturidade atual da startup',
          <BoxContainer flexDirection="row" noPadding={true}>
            <Text style={{ width: '80%' }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas
              officia, perferendis doloremque voluptate odio quas omnis nemo
              fuga error deserunt. Officia quasi soluta quis dolorum,
              necessitatibus maxime corporis cum voluptates!
            </Text>
            <Text color="var(--thirdy-color)" weight={700}>
              Pré-operação
            </Text>
          </BoxContainer>,
          '50%',
        )}
        {getBoxItem(
          'Valuation pré-money atual da startup',
          <BoxContainer flexDirection="row" noPadding={true}>
            <Text style={{ width: '80%' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
              quaerat, eius sequi blanditiis totam consectetur unde culpa sunt
              ullam ut? Assumenda, similique! Reiciendis dolorem praesentium et
              quis aspernatur quia sunt.
            </Text>
            <Text color="var(--thirdy-color)" weight={700}>
              R$ 6.585.732,00
            </Text>
          </BoxContainer>,
          '50%',
        )}
      </BoxContainer>
      <Divider style={{ backgroundColor: 'var(--support-color)' }} />
      <BoxContainer flexDirection="row" noPadding={true} gap="10px">
        <BoxContainer
          flexDirection="row"
          noPadding={true}
          gap="10px"
          flexWrap="wrap"
          width="50%"
        >
          {getBoxItem(
            'Seu total investido',
            <Text color="var(--thirdy-color)" weight={700}>
              R$ 6.143.000,00
            </Text>,
            'calc(50% - 10px)',
            '80px',
          )}
          {getBoxItem(
            'Percentual no negócio',
            <Text color="var(--thirdy-color)" weight={700}>
              40%
            </Text>,
            '50%',
            '80px',
          )}
          {getBoxItem(
            'Histórico de investimentos',
            <BoxContainer noPadding={true} gap="10px">
              {
                /* HISTÓRICO DE INVESTIMENTOS */
                Array.from(Array(5)).map((_, index) => {
                  return (
                    <BoxContainer
                      key={index}
                      flexDirection="row"
                      noPadding={true}
                      justifyContent="space-between"
                    >
                      <Text>Investidor {index}</Text>
                      <Text>25/01/2023</Text>
                      <Text>Valor investido XXXXXXX</Text>
                    </BoxContainer>
                  );
                })
              }
            </BoxContainer>,
            '100%',
            'calc(100% - 110px)',
          )}
        </BoxContainer>
        <BoxContainer noPadding={true} width="50%">
          {getBoxItem(
            'Gráfico pré/pós-money',
            <GraphicPrePosMoney
              data={MOCKGRAPHICADATA}
            /> /* DADOS DO GRÁFRICO*/,
            '100%',
          )}
        </BoxContainer>
      </BoxContainer>
      <BoxContainer noPadding={true}>
        {getBoxItem(
          'OKR',
          <BoxContainer noPadding={true} gap="10px">
            {
              /* OKR */
              Array.from(Array(3)).map((_, index) => {
                return (
                  <BoxCollapse key={index}>
                    <Collapse ghost activeKey={activePanel}>
                      <Panel
                        key={index}
                        extra={
                          <div onClick={() => handlePanel(index)}>
                            {checkPanel(index) ? (
                              <CaretUpOutlined />
                            ) : (
                              <CaretDownOutlined />
                            )}
                          </div>
                        }
                        showArrow={false}
                        header={
                          <BoxContainer
                            flexDirection="row"
                            noPadding={true}
                            justifyContent="space-between"
                          >
                            <BoxContainer noPadding={true}>
                              <BoxContainer
                                flexDirection="row"
                                noPadding={true}
                              >
                                <Text
                                  color="var(--thirdy-color)"
                                  weight={700}
                                  margin="0 10px 0 0"
                                >
                                  Eu vou
                                </Text>
                                <Text>Aumentar o faturamento em 30%</Text>
                              </BoxContainer>
                              <BoxContainer
                                flexDirection="row"
                                noPadding={true}
                              >
                                <Text
                                  color="var(--thirdy-color)"
                                  weight={700}
                                  margin="0 10px 0 0"
                                >
                                  Medido por
                                </Text>
                                <Text>quantidade de vendas</Text>
                              </BoxContainer>
                            </BoxContainer>
                            <BoxContainer noPadding={true} width="20%">
                              <Text weight={700}>Progresso</Text>
                              <Progress
                                percent={80} /* PORCENTAGEM DO PROGRESSO */
                                status="active"
                              />
                            </BoxContainer>
                            <BoxContainer noPadding={true}>
                              <Text weight={700}>Conclusão</Text>
                              <Text>12/01/2023</Text>
                            </BoxContainer>
                            <BoxContainer flexDirection="row" noPadding={true}>
                              <BoxContainer noPadding={true}>
                                <Text weight={700}>Crescimento %</Text>
                                <Text>6,35%</Text>
                              </BoxContainer>
                            </BoxContainer>
                          </BoxContainer>
                        }
                      >
                        <BoxContainer noPadding={true}>
                          <BoxContainer flexDirection="row" noPadding={true}>
                            <BoxContainer
                              noPadding={true}
                              style={{ marginRight: '5%' }}
                            >
                              <Text color="var(--support-color)">KPIs</Text>
                              <Text>Aumento no número de vendas</Text>
                            </BoxContainer>
                            <BoxFile
                              onClick={() => openPDF('NOME_DO_PDF_AQUI')}
                            >
                              <img alt="" src={PDFImage} />
                              <Text weight={700}>Evidência.pdf</Text>
                            </BoxFile>
                          </BoxContainer>
                        </BoxContainer>
                      </Panel>
                    </Collapse>
                  </BoxCollapse>
                );
              })
            }
          </BoxContainer>,
          '100%',
        )}
      </BoxContainer>
      <BoxContainer flexDirection="row" noPadding={true} gap="10px">
        {getBoxItem(
          'Total de clientes',
          <Text color="var(--thirdy-color)" weight={700}>
            10
          </Text>,
          '20%',
          '80px',
        )}
        {getBoxItem(
          'Novos clientes',
          <Text color="var(--thirdy-color)" weight={700}>
            2
          </Text>,
          '20%',
          '80px',
        )}
        {getBoxItem(
          'Churn',
          <Text color="var(--thirdy-color)" weight={700}>
            2
          </Text>,
          '20%',
          '80px',
        )}
        {getBoxItem(
          'CAC',
          <Text color="var(--thirdy-color)" weight={700}>
            R$300
          </Text>,
          '20%',
          '80px',
        )}
        {getBoxItem(
          'LTV',
          <Text color="var(--thirdy-color)" weight={700}>
            ??????????
          </Text>,
          '20%',
          '80px',
        )}
      </BoxContainer>
      <BoxContainer flexDirection="row" noPadding={true} gap="10px">
        {getBoxItem(
          'Despesas orçado',
          <Text color="var(--thirdy-color)" weight={700}>
            ??????????
          </Text>,
          '50%',
          '80px',
        )}
        {getBoxItem(
          'Despesas realizado',
          <Text color="var(--thirdy-color)" weight={700}>
            R$ 0,00
          </Text>,
          '50%',
          '80px',
        )}
      </BoxContainer>
    </BoxContainer>
  );
}

export default InvestedStartup;
