import React from 'react';

import { Card, Col, Row } from 'antd';
import Text from '../../../../components/Text';
import { maskMoney } from '../../../../helpers/mask.helper';
import DocumentIcon from '../../../../assets/icons/document-icon.png';
import { IValuationResult } from '../../../../core/interfaces';
import { useNavigate } from 'react-router-dom';
import MenuTopic from '../MenuTopic';
import { EnabledActionsCrudEnum } from '../../../../core/interfaces/IActionsCrud';
import { convertDateToString } from '../../../../helpers/dates.helper';

function ValuationTopic(props: IValuationResult) {
  const navigate = useNavigate();
  const getResult = () => {
    navigate('/valuations/results', {
      state: {
        id: props?.entityId,
        name: props?.startup?.name,
      },
    });
  };

  const getEnabledActions = () => {
    const { EDIT, VIEW } = EnabledActionsCrudEnum;

    const isOPen = props?.status === 'OPEN';
    const isActive = props?.active;

    if (isActive && isOPen) {
      return [EDIT];
    }

    if (isActive && !isOPen) {
      return [VIEW, EDIT];
    }

    return [VIEW];
  };

  return (
    <Card bodyStyle={{ padding: '8px 12px' }}>
      <Row align="middle" gutter={8}>
        <Col xs={2} sm={2} md={2}>
          <img
            height={78}
            width={78}
            src={DocumentIcon}
            alt="icone documento"
          />
        </Col>
        <Col xs={6} sm={6} md={6}>
          <Text align="left" size={16} color="#05233D" weight={700}>
            {props.valuation?.name}
          </Text>
        </Col>
        <Col xs={6} sm={6} md={6}>
          <Text align="left" size={16} color="#05233D" weight={500}>
            <strong data-bold="true">Data: </strong>
            {convertDateToString(props.creation)}
          </Text>
          <Text align="left" size={16} color="#05233D" weight={500}>
            <strong data-bold="true">Investimento: </strong>
            {maskMoney(props.investmentAmount)}
          </Text>
          <Text align="left" size={14} color="#05233D" weight={500}>
            <strong data-bold="true">Moeda: </strong>
            Real
          </Text>
        </Col>
        <Col xs={6} sm={6} md={6}>
          <Text size={18} color="#05233D" weight={700}>
            Pré-money valuation
          </Text>
          <Text size={20} weight={700} color="#960096">
            {maskMoney(props.preMoney || '0')}
          </Text>
        </Col>
        <Col xs={2} sm={2} md={2}>
          <Text size={18} color="#05233D" weight={700}>
            Status
          </Text>
          <Text size={18} color={props.status === 'OPEN' ? 'red' : 'green'}>
            {props.status === 'OPEN' ? 'Não finalizado' : 'Finalizado'}
          </Text>
        </Col>
        <Col
          xs={2}
          sm={2}
          md={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <MenuTopic
            enabledActions={getEnabledActions()}
            edit={props.editResult}
            view={getResult}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default ValuationTopic;
