import { useEffect, useState } from 'react';
import { Col, Divider, Row, Space, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BoxContainer, PurpleButton } from '../../../GlobalStyles';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Loading from '../../../components/Loading/Loading';
import { useAuth } from '../../../core/hooks/auth';
import StartupService from '../../../services/StartupService';
import { ClientServices } from '../../../services/ClientServices';
// import IListStartups from '../../../core/interfaces/list/IListStartups';
import { About, Accelerator } from './About';
// import { StartupItem } from './StartupItem';

import {
  ContainerContent,
  // StartupListWrapper,
  MoreItemsWrapper,
} from './styles';
import ICourseShowCase from '../../../core/interfaces/course/ICourseShowCase';
import CardCourse from '../../../components/CardCourse/CardCourse';
import CourseService from '../../../services/course/CourseService';

export function MyAccelerator() {
  const [isFetching, setIsFetching] = useState(true);
  const [accelerator, setAccelerator] = useState<Accelerator>(
    {} as Accelerator,
  );
  // const [startups, setStartups] = useState<IListStartups[]>([]);
  const [courses, setCourses] = useState<ICourseShowCase[]>([]);

  const navigate = useNavigate();
  const { logged, isStartup } = useAuth();

  const ownerId = isStartup() ? logged?.entityId : undefined;

  async function getAcceleratorInfoByStartupOwnerId(ownerId: number) {
    const startupService = StartupService.init();
    const acceleratorService = ClientServices.init();

    const { entityId } = await startupService.getByOwner(ownerId);
    const startup = await startupService.findById(entityId as number);
    return await acceleratorService.findById(
      startup.owner.inviter.entityId as number,
    );
  }

  // async function fetchStartupsByAcceleratorId(acceleratorId: number) {
  //   const startupService = StartupService.init();
  //   const response =
  //     await startupService.listAllStartupsByClient(acceleratorId);
  //   setStartups(response);
  // }

  async function fetchCoursesByAcceleratorId(acceleratorId: number) {
    const courseService = CourseService.init();
    const response =
      await courseService.fetchCoursesByAcceleratorId(acceleratorId);
    setCourses(response);
  }

  // function handleNavigateToStartupList() {
  //   navigate('startups', { state: { startups: JSON.stringify(startups) } });
  // }

  function handleNavigateToCoursesList() {
    navigate('courses', {
      state: { acceleratorId: accelerator.entityId },
    });
  }

  useEffect(() => {
    function navigateToHomeWhenNotStartup() {
      notification.error({
        message: 'Problemas ao consultar aceleradora',
        description:
          'Não conseguimos consultar sua aceleradora. Por favor, tente novamente mais tarde',
      });

      return navigate('/dashboard');
    }

    if (!ownerId) {
      return navigateToHomeWhenNotStartup();
    }

    getAcceleratorInfoByStartupOwnerId(ownerId)
      .then(async (response) => {
        setAccelerator(response as Accelerator);

        // await fetchStartupsByAcceleratorId(response.entityId);
        await fetchCoursesByAcceleratorId(response.entityId);

        setIsFetching(false);
      })
      .catch(() => {
        navigateToHomeWhenNotStartup();
      });
  }, [navigate, ownerId]);

  if (isFetching) {
    return (
      <BoxContainer noPadding>
        <Loading width={132} height={132} />
      </BoxContainer>
    );
  }

  return (
    <BoxContainer>
      <Space direction="vertical" size="large">
        <Title tag="h1">Sua aceleradora</Title>

        <About accelerator={accelerator} />

        {/* <ContainerContent>
          <Row align="middle" justify={'space-between'} gutter={16}>
            <Col>
              <Title size={20}>Startups</Title>
            </Col>
            <Col>
              <Text color="#9AA4A8">
                {startups.length} startups cadastradas
              </Text>
            </Col>
            <Col flex={1}>
              <Divider />
            </Col>
          </Row>

          <StartupListWrapper>
            {startups.slice(0, 4).map((startup) => {
              return <StartupItem key={startup.entityId} startup={startup} />;
            })}
          </StartupListWrapper>

          {startups.length > 4 && (
            <MoreItemsWrapper>
              <PurpleButton onClick={handleNavigateToStartupList}>
                Ver todas as Startups
              </PurpleButton>
            </MoreItemsWrapper>
          )}
        </ContainerContent> */}

        <ContainerContent>
          <Row align="middle" justify={'space-between'} gutter={16}>
            <Col>
              <Title size={20}>Cursos</Title>
            </Col>
            <Col>
              <Text color="#9AA4A8">{courses.length} cursos cadastrados</Text>
            </Col>
            <Col flex={1}>
              <Divider />
            </Col>
          </Row>

          <Text size={16} style={{ paddingBottom: 12 }}>
            Últimos cursos lançados
          </Text>

          <Row gutter={[16, 16]} align="stretch">
            {courses.slice(0, 4).map((course) => {
              return (
                <Col key={course.entityId}>
                  <CardCourse
                    name={course.name}
                    amountExercises={course.amountExercises}
                    categoryName={course.categoryName}
                    description={course.description}
                    duration={course.duration}
                    lessonQuantity={course.lessonQuantity}
                    price={course.price}
                    coverPicture={course.coverPicture}
                    entityId={course.entityId}
                    extraMaterialAmount={course.extraMaterialAmount}
                    progress={course.progress}
                  />
                </Col>
              );
            })}
          </Row>

          {courses.length > 4 && (
            <MoreItemsWrapper>
              <PurpleButton onClick={handleNavigateToCoursesList}>
                Ver todos os cursos
              </PurpleButton>
            </MoreItemsWrapper>
          )}
        </ContainerContent>
      </Space>
    </BoxContainer>
  );
}
