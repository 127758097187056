import styled from 'styled-components';

const Box = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 20px 16px;
  background-color: #fff;
  justify-content: flex-start;
  color: var(--primary-color);
  box-shadow: 0px 3px 6px #05233d29;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 40px;

  & p {
    margin: 5px 0 0 0;
  }

  & p.underline {
    text-decoration: underline;
  }

  & strong {
    font-size: 16px;
  }

  & p,
  & strong {
    font-family: 'Segoe UI', sans-serif;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

export { Box };
