import CrudService from '../CrudService';

class LessonService extends CrudService {
  constructor() {
    super({
      requestMapping: '/lesson',
    });
  }

  public static init(): LessonService {
    return new LessonService();
  }
}

export default LessonService;
