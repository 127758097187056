import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import GoBack from '../../../components/GoBack/GoBack';
import Title from '../../../components/Title';
import { BoxContainer } from '../../../GlobalStyles';
import InviteNewUser from './ModalNewUser/InviteNewUser';
import ProfileItem from './ProfileItem/ProfileItem';
import { Box, Header } from './style';
import { IUser } from '../../../core/interfaces';
import UserService from '../../../services/UserService';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import { convertDateToString } from '../../../helpers/dates.helper';

function ProfileList() {
  const [modalNewUserVisible, setModalNewUserVisible] = useState(false);
  const userService = useMemo(() => UserService.init(), []);
  const { loading, showLoading, hideLoading } = useLoading();
  const [actives, setActives] = useState<IUser[]>([]);
  const [inactives, setInactives] = useState<IUser[]>([]);

  const getUsers = () => {
    showLoading();
    userService
      .getAllUsers()
      .then((res) => {
        setActives(res.filter((user) => user.active));
        setInactives(res.filter((user) => !user.active));
      })
      .finally(hideLoading);
  };

  const showModalInvite = () => {
    setModalNewUserVisible(!modalNewUserVisible);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <InviteNewUser
        title="Novo usuário"
        visible={modalNewUserVisible}
        onCancel={() => showModalInvite()}
      />
      <BoxContainer>
        <Header style={{ marginBottom: '10px' }}>
          <GoBack
            style={{ alignSelf: 'center', marginRight: '10px' }}
            enableBackground={true}
            path="profiles"
          />
          <Title>Perfis</Title>
        </Header>
        <Loading
          spinning={loading}
          width={200}
          height={200}
          position={'absolute'}
        >
          <Box>
            <div style={{ marginBottom: '10px' }}>
              <Title size={16}>Perfis pendentes</Title>
              <Button
                size="small"
                shape="circle"
                type="dashed"
                className="addItem"
                onClick={showModalInvite}
                icon={<UserAddOutlined />}
              />
            </div>
            <OnEmptyOrList
              list={inactives}
              description={'Nao exite perfis pendentes'}
            >
              {inactives.map((item, index) => {
                return (
                  <>
                    <ProfileItem
                      key={index}
                      updateList={getUsers}
                      entityId={item.entityId as number}
                      name={item.profiles && item?.profiles[0]?.description}
                      date={convertDateToString(item.creation as string)}
                      lastUpdate={
                        convertDateToString(item.lastUpdated as string) || '-'
                      }
                      email={item.email}
                      status={item.active || false}
                      showLoading={showLoading}
                      hideLoading={hideLoading}
                    />
                  </>
                );
              })}
            </OnEmptyOrList>
            <div style={{ marginBottom: '10px' }}>
              <Title size={16}>Perfis ativos</Title>
            </div>
            <OnEmptyOrList
              list={actives}
              description={'Nao exite perfis ativos'}
            >
              {actives?.map((item: IUser, index) => {
                return (
                  <ProfileItem
                    showLoading={showLoading}
                    hideLoading={hideLoading}
                    key={index}
                    updateList={getUsers}
                    entityId={item.entityId as number}
                    name={item.profiles && item?.profiles[0]?.description}
                    date={convertDateToString(item.creation as string)}
                    lastUpdate={
                      convertDateToString(item.lastUpdated as string) || '-'
                    }
                    email={item.email}
                    status={item.active || false}
                  />
                );
              })}
            </OnEmptyOrList>
          </Box>
        </Loading>
      </BoxContainer>
    </>
  );
}

export default ProfileList;
