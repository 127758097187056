import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
    height: 150px;
  }
`;

const Description = styled.div`
  text-align: justify;
  margin-bottom: 10px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  margin: 10px 0;
`;

const Extra = styled.div`
  display: flex;
  flex-direction: column;
`;

export { BoxContent, BoxImg, Description, Title, Extra };
