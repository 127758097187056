import { Col, Divider, Row } from 'antd';
import React from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useModal } from 'sunflower-antd';
import BaseInvitation from '../BaseInvitation';
import Text from '../Text';

type Props = {
  totalElements: number;
};

function MentorInvitation({ totalElements }: Props) {
  const modalInvitation = useModal({
    defaultVisible: false,
  });

  return (
    <>
      <BaseInvitation
        title="Convidar mentor"
        description="Aumente seu time de mentores para entregar uma experiência mais completa para suas startups."
        {...modalInvitation.modalProps}
      />

      <Row align="middle" gutter={[20, 12]}>
        <Col>
          <Text color="var(--support-color)">
            {totalElements} mentores cadastrados
          </Text>
        </Col>
        <Col flex={1}>
          <Divider style={{ color: '#9AA4A8' }} />
        </Col>
        <Col>
          <AiOutlineUserAdd
            size={20}
            cursor="pointer"
            onClick={modalInvitation.show}
          />
        </Col>
      </Row>
    </>
  );
}

export default MentorInvitation;
