import React from 'react';
import MethodologyInfo from '../Infos/MethodologyInfo';
import ContentBase from '../templates/ContentBase';
import MethodologyList from './MethodologyList';

function Methodology() {
  return (
    <ContentBase
      title="Metodologia"
      modalContent={<MethodologyInfo />}
      content={<MethodologyList />}
    />
  );
}

export default Methodology;
