import React, { useEffect, useImperativeHandle, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import Papa from 'papaparse';
import Title from '../../../components/Title';
import { TextAttachFile } from './styles';
import closeIcon from '../../../assets/icons/close_icon.svg';
import ReferenceType from '../../../types/ReferenceFormType';
import { onlyNumbers } from '../../../helpers/string.helper';
import { IconOptions, PurpleButton } from '../../../GlobalStyles';
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Divider,
  Space,
  message,
  FormInstance,
  notification,
} from 'antd';
import { monetaryValueFormat } from '../../../helpers/mask.helper';
import pathUploadDocument from '../../../assets/icons/upload_document.png';
import { FileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import api from '../../../core/services/api';
import { ITaxes } from '../../../core/interfaces/ITaxes';
import AddListButton from '../../../components/AddListButton';
import notificationMessage from '../../../components/notifications/notificationMessage';
import InputPercent from '../../../components/Input/InputPercent';
import FooterPage from '../../../components/FooterPage';
import ContentBase from '../../templates/ContentBase';
import ValuationToolsInfo from '../../Infos/ValuationToolsInfo';
import AlertMessage from '../../../components/Alert/AlertMessage';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import { ISector } from '../../../core/interfaces/ISector';

type AttachType = Array<Array<string>>;

const WeightedAverageCapitalCost = React.forwardRef<ReferenceType>((_, ref) => {
  const [form] = Form.useForm();
  const refFile = React.createRef<HTMLInputElement>();
  const { hideLoading, loading, showLoading } = useLoading();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const arraySectors = (values: FormInstance) => {
    const map = new Map(Object.entries(values));
    const sectors: ISector[] = [];
    map.forEach((value, key) => {
      sectors.push(value);
    });

    return sectors;
  };

  const handleSubmitForm = (): void => {
    showLoading();
    form
      .validateFields()
      .then(() => {
        const taxes: ITaxes = {
          prm: 0,
          ipca: 0,
          inflation: 0,
          riskCountry: 0,
          riskFreeRate: 0,
          sizePremium: 0,
        };

        taxes.prm = Number(
          form.getFieldValue('prm').replace('%', '').replace(',', '.'),
        );
        taxes.ipca = Number(
          form.getFieldValue('ipca').replace('%', '').replace(',', '.'),
        );
        taxes.inflation = Number(
          form.getFieldValue('inflation').replace('%', '').replace(',', '.'),
        );
        taxes.riskCountry = Number(
          form.getFieldValue('riskCountry').replace('%', '').replace(',', '.'),
        );
        taxes.riskFreeRate = Number(
          form.getFieldValue('riskFreeRate').replace('%', '').replace(',', '.'),
        );
        taxes.sizePremium = Number(
          form.getFieldValue('sizePremium').replace('%', '').replace(',', '.'),
        );

        api
          .post('taxes', taxes)
          .then(() => {
            notificationMessage('custom', 'WACC salvo com sucesso!');
          })
          .catch(() => {
            notification.error({
              message: 'Erro ao salvar WACC',
            });
          });

        api
          .post('sectors/lote', arraySectors(form.getFieldValue('sectors')))
          .then(() => {
            notificationMessage('custom', 'Múltiplos salvo com sucesso!');
            setModalVisible(false);
          })
          .catch(() => {
            notification.error({
              message: 'Erro ao salvar múltiplos',
            });
          });
      })
      .catch(() => {
        message.error('Preencha todos os campos obrigatórios');
      })
      .finally(hideLoading);
  };

  useImperativeHandle(ref, () => ({
    handleSubmitForm,
  }));

  const fieldRequired = [
    {
      required: true,
      message: 'Campo obrigatório',
    },
  ];

  const validateNumberOfNumbers = (value: string): boolean => {
    const numbers = onlyNumbers(value);
    return numbers.length >= 3;
  };

  const attach = (value: AttachType): void => {
    const headers = value.shift();
    const getIndexHeaders = (column: string) => {
      return (
        headers?.findIndex((item) =>
          item.toString().toLowerCase().trim().includes(column),
        ) ?? 0
      );
    };

    const newItems = [] as Record<string, unknown>[];
    const NUMBER_COLUMNS = 5;

    let index = 0;
    while (index < value.length) {
      const row = value[index];
      if (row.length >= NUMBER_COLUMNS) {
        const name = String(row[getIndexHeaders('setor')]);
        const ratio = String(row[getIndexHeaders('ratio')]);
        const beta = String(row[getIndexHeaders('beta')]);
        const unlevered = String(row[getIndexHeaders('unlevered')]);
        const ebitda = String(row[getIndexHeaders('ev/ebitda')]);
        newItems.push({
          name: name,
          beta: beta,
          unlevered: unlevered,
          deratio: ratio,
          evEBITDA: validateNumberOfNumbers(ebitda) ? ebitda : null,
        });
      }

      index++;
    }

    const sectors = form.getFieldValue('sectors') as unknown[];

    form.setFieldValue('sectors', [...newItems]);
  };

  const cleanAttachment = (): void => {
    const { current } = refFile;
    if (current) current.value = '';
  };

  const attachFile = (target: HTMLInputElement) => {
    const firstFile = target.files?.item(0);
    if (firstFile) {
      if (firstFile.type.includes('csv')) {
        Papa.parse(firstFile, {
          complete: (result) => {
            attach(result.data as AttachType);
            cleanAttachment();
          },
          error: () => cleanAttachment(),
        });
      } else {
        readXlsxFile(firstFile)
          .then((value) => attach(value as AttachType))
          .catch((error) => {
            window.alert('colunas não conferem');
          })
          .finally(() => cleanAttachment());
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    setTimeout(() => {
      const data = sessionStorage.getItem('form-wacc-multiples');
      if (data) {
        const {
          prm,
          ipca,
          sectors,
          inflation,
          riskCountry,
          sizePremium,
          riskFreeRate,
        } = JSON.parse(data);

        form.setFieldValue('prm', prm);
        form.setFieldValue('ipca', ipca);
        form.setFieldValue('riskCountry', riskCountry);
        form.setFieldValue('riskFreeRate', riskFreeRate);
        form.setFieldValue('sizePremium', sizePremium);
        form.setFieldValue('inflation', inflation);
        form.setFieldValue('sectors', sectors);
      }
    }, 150);

    return () => {
      sessionStorage.setItem(
        'form-wacc-multiples',
        JSON.stringify({
          ipca: form.getFieldValue('ipca'),
          riskCountry: form.getFieldValue('riskCountry'),
          riskFreeRate: form.getFieldValue('riskFreeRate'),
          sizePremium: form.getFieldValue('sizePremium'),
          prm: form.getFieldValue('prm'),
          sectors: form.getFieldValue('sectors'),
          inflation: form.getFieldValue('inflation'),
        }),
      );
    };
  }, [form]);

  return (
    <ContentBase
      title="WACC e Múltiplos"
      goBack={true}
      path={'tools'}
      modalContent={<ValuationToolsInfo />}
      content={
        <>
          <Loading spinning={loading} width={200} height={200}>
            <AlertMessage
              visible={modalVisible}
              message="Salvar e publicar?"
              description="Os dados preenchidos nesses campos são cruciais para o cálculo de valuations de todas as aceleradoras e irá impactar no resultado dos próximos executados."
              textOkButton="Salvar e publicar"
              onCancel={handleCancel}
              onOk={handleSubmitForm}
            />
            <Form
              form={form}
              layout={'vertical'}
              autoComplete={'off'}
              name={'diagnosisPhaseForm'}
            >
              <Card
                bodyStyle={{
                  padding: '9px 16px 0 16px',
                }}
                style={{
                  borderRadius: '6px',
                  boxShadow: '0px 3px 6px #05233d1a',
                }}
              >
                <Row align="middle" gutter={16}>
                  <Col>
                    <Title size={26}>WACC</Title>
                  </Col>
                  <Col>
                    <IconOptions size="23px">
                      <QuestionCircleOutlined
                        onClick={() => window.alert('info')}
                      />
                    </IconOptions>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: '10px' }}>
                  <Col span={4}>
                    <Form.Item name={'ipca'} label="IPCA" rules={fieldRequired}>
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={'riskCountry'}
                      label="Risco País"
                      rules={fieldRequired}
                    >
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={'riskFreeRate'}
                      label="Taxa livre de risco"
                      rules={fieldRequired}
                    >
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={'sizePremium'}
                      label="Size Premium"
                      rules={fieldRequired}
                    >
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'prm'} label="PRM" rules={fieldRequired}>
                      <InputPercent />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={'inflation'}
                      label="Inflação BRA"
                      rules={fieldRequired}
                    >
                      <InputPercent />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Card
                bodyStyle={{
                  padding: '9px 16px',
                }}
                style={{
                  marginBottom: '60px',
                  borderRadius: '6px',
                  boxShadow: '0px 3px 6px #05233d1a',
                }}
              >
                <Row align="middle" gutter={22}>
                  <Col>
                    <Title size={26}>Múltiplos</Title>
                  </Col>
                  <Col>
                    <IconOptions size="23px">
                      <QuestionCircleOutlined
                        onClick={() => window.alert('info')}
                      />
                    </IconOptions>
                  </Col>
                </Row>
                <Row align="middle" gutter={23}>
                  <Col span={4}>
                    <img
                      src={pathUploadDocument}
                      alt="icone upload"
                      height={160}
                      width={160}
                    />
                  </Col>
                  <Col span={16}>
                    <Title size={20} tag="h2">
                      Anexar arquivo (opcional)
                    </Title>
                    <TextAttachFile>
                      O arquivo para cálculo dos múltiplos podem ser em formato{' '}
                      <strong>Excel</strong> ou em <strong>CSV</strong>.
                      Anexando um arquivo nesse formato e que possua os mesmos
                      títulos do formulário Setores da Indústria, os campos
                      serão preenchidos automaticamente com os dados do arquivo.
                    </TextAttachFile>
                  </Col>
                  <Col span={3}>
                    <input
                      accept=".xlsx, .xls, .csv"
                      ref={refFile}
                      type="file"
                      onChange={({ target }) => attachFile(target)}
                      style={{ display: 'none' }}
                    />
                    <PurpleButton
                      type="primary"
                      onClick={() => refFile.current?.click()}
                      icon={<FileOutlined />}
                    >
                      Anexar arquivo
                    </PurpleButton>
                  </Col>
                </Row>
                <Divider />

                <Title margin="0 0 8px 0" tag="h3" size={20}>
                  Setores da Indústria
                </Title>
                <Form.List name="sectors" initialValue={['']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Row align="middle" gutter={16}>
                            <Col span={5}>
                              <Form.Item
                                label="Setor"
                                rules={fieldRequired}
                                {...restField}
                                name={[name, 'name']}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name={[name, 'beta']}
                                label="Beta"
                                rules={fieldRequired}
                              >
                                <Input
                                  placeholder="0,00"
                                  onChange={({ target }) => {
                                    form.setFields([
                                      {
                                        name: ['sectors', index, 'beta'],
                                        value: monetaryValueFormat(
                                          target.value,
                                        ),
                                      },
                                    ]);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name={[name, 'deratio']}
                                label="D/E Ratio (%)"
                                rules={fieldRequired}
                              >
                                <InputPercent />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                name={[name, 'unlevered']}
                                label="Unlevered"
                                rules={fieldRequired}
                              >
                                <Input
                                  placeholder="00,00"
                                  onChange={({ target }) => {
                                    form.setFields([
                                      {
                                        name: ['sectors', index, 'unlevered'],
                                        value: monetaryValueFormat(
                                          target.value,
                                        ),
                                      },
                                    ]);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                name={[name, 'evEBITDA']}
                                label="EV/EBITDA"
                              >
                                <Input
                                  placeholder="0,00"
                                  onChange={({ target }) => {
                                    form.setFields([
                                      {
                                        name: ['sectors', index, 'evEBITDA'],
                                        value: monetaryValueFormat(
                                          target.value,
                                        ),
                                      },
                                    ]);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ minWidth: '30px' }}>
                              {index !== 0 && (
                                <img
                                  width={15}
                                  height={15}
                                  src={closeIcon}
                                  alt="icone remover"
                                  onClick={() => remove(index)}
                                  style={{
                                    marginTop: '4px',
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Space>
                      ))}
                      <AddListButton onClick={add}>Adicionar</AddListButton>
                    </>
                  )}
                </Form.List>
              </Card>
              <FooterPage save={showModal} />
            </Form>
          </Loading>
        </>
      }
    />
  );
});

WeightedAverageCapitalCost.displayName = 'WeightedAverageCapitalCost';

export default WeightedAverageCapitalCost;
