import api from '../core/services/api';
const getValuationResult = () => {
  return api.get('/valuationResult/list').then((response) => response.data);
};
const getValuation = () => {
  return api.get('/valuation/list').then((response) => response.data);
};
const getQuestions = () => {
  return api.get('/valuation/questions/list').then((response) => response.data);
};

const createValuation = () => {
  return api.post('/valuation/create').then((response) => response.data);
};

const getSectors = () => {
  return api.get('/sectors/').then((response) => response.data);
};

const saveDCF = () => {
  return api.post('/dcf').then((response) => response.data);
};

const verifyValuation = (idUser: number) => {
  return api
    .get<boolean>(`/valuation/exists/${idUser}`)
    .then((response) => response.data);
};

export {
  getValuation,
  getQuestions,
  createValuation,
  getValuationResult,
  getSectors,
  saveDCF,
  verifyValuation,
};
