import { IValuationResult } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';

class ValuationResultService extends CrudService {
  constructor() {
    super({
      requestMapping: '/valuationResult',
    });
  }

  public static init(): ValuationResultService {
    return new ValuationResultService();
  }

  public getLastValuation(startupId: number): Promise<IValuationResult> {
    const params = {
      startup: startupId,
    };
    return api
      .get<IValuationResult>(`${this.requestMapping}/get-last-valuation`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ValuationResultService;
