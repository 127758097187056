import { useEffect, useState } from 'react';
import { Card, Form, Input } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { BoxContainer } from '../../GlobalStyles';
import Tab from '../../components/tab';
import MethodologyAssociation from '../../components/form/diagnosis/MethodologyAssociation';
import PhasesAssociation from '../../components/form/diagnosis/PhasesAssociation';
import QuestionsAssociation from '../../components/form/diagnosis/QuestionsAssociation';

interface IParams {
  setFormName: (formName: string) => void;
  closeForm: () => void;
}

interface IData {
  entityId: number;
  name: string;
}

function DiagnosisForm(props: IParams) {
  const [ask, setAsk] = useState<boolean>(false);
  const [next, setNext] = useState<string>('');
  const [form] = useForm();
  const nameDiagnosis = useWatch('nameDiagnosis', form);
  const [data, setData] = useState<IData>();
  const content = [
    {
      tab: 'Associar metodologia',
      body: (
        <MethodologyAssociation
          ask={ask}
          setAsk={setAsk}
          setFormName={props.setFormName}
          nameDiagnostic={nameDiagnosis}
          setNext={setNext}
          setData={setData}
        />
      ),
    },
    {
      tab: 'Associar fases',
      body: (
        <PhasesAssociation
          ask={ask}
          setAsk={setAsk}
          setFormName={props.setFormName}
          setNext={setNext}
          initialData={data}
          setData={setData}
          nameDiagnostic={nameDiagnosis}
        />
      ),
    },
    {
      tab: 'Associar perguntas',
      body: (
        <QuestionsAssociation
          ask={ask}
          setAsk={setAsk}
          setFormName={props.setFormName}
          setNext={setNext}
          initialData={data}
          setData={setData}
          nameDiagnostic={nameDiagnosis}
          closeForm={props.closeForm}
        />
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        nameDiagnosis: data.name,
        idDiagnosis: data.entityId,
      });
    }
  }, [data]);

  return (
    <BoxContainer noPadding>
      <Form form={form} layout={'vertical'}>
        <Card>
          <Form.Item
            name={'nameDiagnosis'}
            label={'Nome da diagnóstico'}
            rules={[
              {
                required: true,
                message: 'O nome do diagnóstico é obrigatório',
              },
            ]}
          >
            <Input
              readOnly={!!data}
              placeholder="digite o nome do diagnóstico"
              maxLength={255}
            />
          </Form.Item>
          <Form.Item name={'idDiagnosis'} hidden={true}>
            <Input value={data?.entityId} />
          </Form.Item>
        </Card>
      </Form>
      <Tab
        content={content}
        ask={ask}
        setAsk={setAsk}
        changeActiveKey={setNext}
        activeKey={next}
      />
    </BoxContainer>
  );
}

export default DiagnosisForm;
