import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IInvestor } from '../core/interfaces/IInvestor';

class InvestorService extends CrudService {
  constructor() {
    super({
      requestMapping: '/investor',
    });
  }

  public static init(): InvestorService {
    return new InvestorService();
  }

  public async getAllInvestor(): Promise<IInvestor[]> {
    return api
      .get<IInvestor[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getWithoutUser(id: number): Promise<IInvestor> {
    return api
      .get<IInvestor>(`${this.requestMapping}/without-user/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public updateBusinessInformation(id: number, data: Record<string, unknown>) {
    return api
      .put(`${this.requestMapping}/update-business-information/${id}`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default InvestorService;
