import { Input } from 'antd';
import FieldWrapper from '../FieldWrapper';
import IFieldComponentParams from '../../../../core/interfaces/attend-course/IFieldComponentParams';

function DiscursiveField({ data, values, changeValue }: IFieldComponentParams) {
  const { entityId, question } = data.quiz;
  const name = `discursive-${entityId}`;
  const { answer } = values[name] ?? {};

  return (
    <FieldWrapper
      question={question}
      comment={data.comment}
      status={data.status}
    >
      <Input
        value={data.answers.at(0) ?? answer}
        placeholder="Sua resposta"
        onChange={({ target }) => changeValue(name, { answer: target.value })}
      />
    </FieldWrapper>
  );
}

export default DiscursiveField;
