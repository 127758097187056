import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IPhaseInvestment } from '../core/interfaces/IPhaseInvestment';

class PhaseInvestmentService extends CrudService {
  static init(): PhaseInvestmentService {
    return new PhaseInvestmentService();
  }

  constructor() {
    super({
      requestMapping: '/phaseInvestiment',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IPhaseInvestment[]> {
    return api
      .get<IPhaseInvestment[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(phaseInvestment: IPhaseInvestment): Promise<IPhaseInvestment> {
    return api
      .post<IPhaseInvestment>(`${this.requestMapping}`, phaseInvestment)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default PhaseInvestmentService;
