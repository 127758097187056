import { Image, Card } from 'antd';
import Title from '../../../../components/Title';

import { CardContent } from './styles';
import { maskMoney } from '../../../../helpers/mask.helper';

interface CardProps {
  image: string;
  title: string;
  amount: number;
  description: string;
}

export function PlanCard({ image, title, amount, description }: CardProps) {
  const average = maskMoney(amount / 12);

  return (
    <Card style={{ maxWidth: 400, minWidth: 300, border: '1px solid #B2B9BD' }}>
      <CardContent>
        <Image preview={false} src={image} alt="" />
        <Title size={18} align="center">
          {title}
        </Title>

        <Title size={22} align="center">
          {maskMoney(amount)}
        </Title>
        <Title size={12} weight={400} align="center">
          ou <strong>12x</strong> de <strong>{average}</strong> com juros Cartão
          Amex - Vindi
        </Title>

        <Title size={12} weight={400} align="center" margin="1rem 0 0">
          {description}
        </Title>
      </CardContent>
    </Card>
  );
}
