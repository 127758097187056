import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px 10px;
  background-color: var(--white-color);
  border: 1px solid var(--support-color);
  border-radius: 6px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;

  .description {
    width: calc(70% - 10px);
    text-align: justify;
    margin-right: 10px;
  }

  .grade {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;

    .subtitle {
      font-size: 0.8rem;
      font-style: italic;
      color: var(--secondary-color);
    }

    .score {
      margin-top: 10px;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
`;

const BoxIcons = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-around;
  margin-top: 10px;

  .icons {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    svg {
      font-size: 18px;
      color: var(--primary-color);
    }

    :hover {
      background-color: var(--primary-color);

      svg {
        color: var(--white-color);
      }
    }
  }
`;

const BoxQuestions = styled.div`
  display: flex;
  flex-direction: column;

  .description {
    position: relative;

    .text {
      width: calc(100% - 100px);
      text-align: justify;
    }

    .points {
      position: absolute;
      top: 0;
      right: 5px;
      font-weight: 700;
    }
  }
`;

const BoxLine = styled.div<{
  flexDirection?: string;
  margin?: string;
  border?: boolean;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  margin: ${({ margin }) => (margin ? margin : '')};
  border-bottom: ${({ border }) =>
    border ? '1px dotted var(--thirdy-color)' : 'none'};
`;

const Option = styled.div<{
  width?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => (width ? width : '100%')};

  & [data-option] {
  }

  .text {
    width: 80%;
    margin-left: 10px;
  }
`;

const Point = styled.div<{
  border?: string;
}>`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: ${({ border }) => (border ? border : '50%')};
  border: 1px solid var(--primary-color);

  .chosen {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    background-color: var(--thirdy-color);
  }
`;

const CorrectAnswer = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
  }
`;

const BoxAnswer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  border: 1px solid var(--support-color);
  border-radius: 6px;
  margin: 10px 0 10px 0;

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    img {
      height: 32px;
      margin-right: 10px;
    }
  }
`;

const BoxApproval = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  .comment {
    position: absolute;
    top: 0;
    right: 5px;
  }

  svg {
    font-size: 18px;
  }
`;

export {
  Box,
  Header,
  Info,
  BoxIcons,
  BoxQuestions,
  BoxLine,
  Option,
  Point,
  CorrectAnswer,
  BoxAnswer,
  BoxApproval,
};
