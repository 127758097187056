import { Divider, Tree } from 'antd';
import React, { useEffect, useState } from 'react';
import Title from '../../../../components/Title';
import { BoxLevels, BoxTree, Header, PurppleText } from '../style';
import { IProfile } from '../../../../core/interfaces';
import ProfileService from '../../../../services/ProfileService';
import { DownOutlined } from '@ant-design/icons';
import { findRole } from '../../../../core/enums';
import { EnabledActionsCrudEnum } from '../../../../core/interfaces/IActionsCrud';
import ActionsCrud from '../../../../components/ActionsCrud';
import ProfileLevel from './ProfileLevel';
import useLoading from '../../../../core/hooks/useLoading';

function ProfileLevelList() {
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [profile, setProfile] = React.useState<IProfile>();
  const [modalProfileVisible, setModalProfileVisible] = useState(false);
  const profilesService = ProfileService.init();
  const { showLoading, hideLoading } = useLoading();

  const getProfiles = () => {
    showLoading();
    profilesService
      .getAllProfiles()
      .then((res) => {
        setProfiles(res);
      })
      .finally(hideLoading);
  };

  const editProfile = (value: IProfile) => {
    showModalProfileLevel();
    setProfile(value);
  };

  const showModalProfileLevel = () => {
    setModalProfileVisible(!modalProfileVisible);
    setProfile(undefined);
  };

  useEffect(() => {
    getProfiles();
  }, []);

  return (
    <BoxLevels>
      <ProfileLevel
        title="Editar perfil"
        visible={modalProfileVisible}
        onCancel={showModalProfileLevel}
        initialValues={profile}
      />
      {profiles.map((profile, index) => (
        <BoxTree key={index} style={{ padding: '8px 10px 8px 10px' }}>
          <Header
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Title size={14}>{profile.description}</Title>
              <PurppleText fontSize={'0.8rem'} style={{ marginBottom: '10px' }}>
                {' '}
                {profile.contUsers} usuários{' '}
              </PurppleText>
            </div>
            <ActionsCrud
              style={{
                display: 'flex',
              }}
              edit={() => editProfile(profile)}
              enabledActions={[EnabledActionsCrudEnum.EDIT]}
            />
          </Header>
          <Divider style={{ margin: '0px' }} />
          <Title size={14}>Níveis de acesso</Title>
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={profile.features?.map((feature, index) => {
              return {
                key: `${index}-${feature.id}`,
                title: findRole(feature.description),
                children: feature?.subFeatures?.map((subFeature, key) => {
                  return {
                    title: subFeature.description,
                    key: `${index}-${key}-${subFeature.id}`,
                  };
                }),
              };
            })}
          />
        </BoxTree>
      ))}
    </BoxLevels>
  );
}

export default ProfileLevelList;
