import { IProfile } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';

class ProfileService extends CrudService {
  constructor() {
    super({
      requestMapping: '/profile',
    });
  }

  public static init(): ProfileService {
    return new ProfileService();
  }

  public async getAllProfiles(): Promise<IProfile[]> {
    return api
      .get<IProfile[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createProfile(profile: IProfile): Promise<IProfile> {
    return api
      .post<IProfile>(`${this.requestMapping}`, profile)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllProfilesByStartup(id: number): Promise<IProfile[]> {
    return api
      .get<IProfile[]>(`${this.requestMapping}/startup/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllProfilesByAccelarator(id: number): Promise<IProfile[]> {
    return api
      .get<IProfile[]>(`${this.requestMapping}/accelerator/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ProfileService;
