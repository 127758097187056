import React, { useEffect, useState } from 'react';
import { Col, Form, FormInstance, Row, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IBusinessModel } from '../../../../../core/interfaces/IBusinessModel';
import { IPhaseInvestment } from '../../../../../core/interfaces/IPhaseInvestment';
import { IAmountInvestment } from '../../../../../core/interfaces/IAmountInvestment';
import { IBusinessType } from '../../../../../core/interfaces/IBusinessTypel';
import { IPeriodInvestment } from '../../../../../core/interfaces/IPeriodInvestment';
import { IValuesInvestment } from '../../../../../core/interfaces/IValuesInvestment';
import BusinessModelService from '../../../../../services/BusinessModelService';
import PhaseInvestmentService from '../../../../../services/PhaseInvestmentService';
import AmountInvestmentService from '../../../../../services/AmountInvestmentService';
import BusinessTypeService from '../../../../../services/BusinessTypeService';
import PeriodInvestmentService from '../../../../../services/PeriodInvestmentService';
import ValuesInvestmentService from '../../../../../services/ValuesInvestmentService';
import api from '../../../../../core/services/api';
import notificationMessage from '../../../../../components/notifications/notificationMessage';
import ErrorFormNotification from '../../../../../components/notifications/errorFormNotification';

interface IParams {
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setButtonState: (buttonState: boolean) => void;
  setFormName: (value: string) => void;
  initialValues?: any;
}

function InvestorForm(props: IParams) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [businessModels, setBusinessModels] = useState<IBusinessModel[]>([]);
  const [phasesInvestment, setPhasesInvestment] = useState<IPhaseInvestment[]>(
    [],
  );
  const [amountInvestment, setAmountInvestment] = useState<IAmountInvestment[]>(
    [],
  );
  const [businessTypes, setBusinessTypes] = useState<IBusinessType[]>([]);
  const [periodInvestments, setPeriodInvestments] = useState<
    IPeriodInvestment[]
  >([]);
  const [valuesInvestment, setValuesInvestment] = useState<IValuesInvestment[]>(
    [],
  );

  const [phasesForm, setPhasesForm] = useState<IPhaseInvestment[]>([]);
  const [areas, setAreas] = useState<IBusinessType[]>([]);

  const navigate = useNavigate();

  // add todos os sevices
  const businessModelsService = BusinessModelService.init();
  const phaseInvestmentService = PhaseInvestmentService.init();
  const amountInvestmentService = AmountInvestmentService.init();
  const businessTypeService = BusinessTypeService.init();
  const periodInvestmentService = PeriodInvestmentService.init();
  const valuesInvestmentService = ValuesInvestmentService.init();

  const arrageValues = (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));

    const result: any = {
      phaseInvestiment: phasesForm.map((phase: any) => {
        return {
          entityId: phase,
        };
      }),
      amountInvestments: {
        entityId: valuelMap.get('investmentAmount'),
      },
      investimentValues: {
        entityId: valuelMap.get('investimentValues'),
      },
      businessTypes: areas.map((area: any) => {
        return {
          entityId: area,
        };
      }),
      businessModels: {
        entityId: valuelMap.get('businessModel'),
      },
      periodInvestiment: {
        entityId: valuelMap.get('timeFoundation'),
      },

      user: {
        entityId: props.initialValues?.entityId,
        email: props.initialValues?.email,
        name: props.initialValues?.name,
        cellPhone: props.initialValues?.cellPhone,
        cpf: props.initialValues?.cpf,
        birthDate: props.initialValues?.birthDate,

        profiles: [
          {
            id: 5,
          },
        ],
      },
    };

    return result;
  };

  const getBusinessModels = () => {
    businessModelsService.listAll().then((response) => {
      setBusinessModels(response);
    });
  };

  const getPhases = () => {
    phaseInvestmentService.listAll().then((response) => {
      setPhasesInvestment(response);
    });
  };

  const getAmountInvested = () => {
    amountInvestmentService.listAll().then((response) => {
      setAmountInvestment(response);
    });
  };

  const getBusinessTypes = () => {
    businessTypeService.listAll().then((response) => {
      setBusinessTypes(response);
    });
  };

  const getInvestmentPeriod = () => {
    periodInvestmentService.listAll().then((response) => {
      setPeriodInvestments(response);
    });
  };

  const getValues = () => {
    valuesInvestmentService.listAll().then((response) => {
      setValuesInvestment(response);
    });
  };

  const onFinish = (values: FormInstance) => {
    api
      .post('/investor', arrageValues(values))
      .then((response) => {
        notificationMessage('create');
        props.setCurrent(props.current + 1);
        localStorage.setItem('@innovation:auth', response.data.jwttoken);
        navigate('/register/conclude/profile/final');
      })
      .catch((error) => {
        ErrorFormNotification(form, error.response.data.message);
      });
  };

  const changePhase = (value: any) => {
    setPhasesForm(value);
  };

  const changeArea = (value: any) => {
    setAreas(value);
  };

  useEffect(() => {
    props.setFormName('formInvestor');
    getBusinessModels();
    getPhases();
    getAmountInvested();
    getBusinessTypes();
    getInvestmentPeriod();
    getValues();
  }, []);

  return (
    <Form
      name={'formInvestor'}
      onFinish={onFinish}
      form={form}
      layout={'vertical'}
      autoComplete={'off'}
    >
      <Row gutter={[8, 4]}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Quais fases você investe?"
            name="investmentPhase"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione as áreas de interesse"
              onChange={changePhase}
            >
              {phasesInvestment.map((item) => {
                return (
                  <Option key={`PHASES_${item.entityId}`} value={item.entityId}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Quanto você normalmente investe"
            name={'investmentAmount'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select allowClear placeholder="Selecione o valor">
              {amountInvestment.map((item) => {
                return (
                  <Option key={`AMOUNT_${item.entityId}`} value={item.entityId}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Em quais áreas de investimento?"
            name={'investmentArea'}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione as áreas de interesse"
              onChange={changeArea}
            >
              {businessTypes.map((item) => {
                return (
                  <Option key={`TYPES_${item.entityId}`} value={item.entityId}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Modelo de negócio"
            name="businessModel"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select allowClear placeholder="Selecione as áreas de interesse">
              {businessModels?.map((item) => {
                return (
                  <Option
                    key={`BUSINESS_${item.entityId}`}
                    value={item.entityId}
                  >
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Tempo de fundação da startup que vc investe?"
            name="timeFoundation"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select placeholder="Selecione o tempo">
              {periodInvestments.map((item) => {
                return (
                  <Option key={`PERIOD_${item.entityId}`} value={item.entityId}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="Quantas empresas você tem no portfolio?"
            name="investimentValues"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Select placeholder="Selecione o tempo">
              {valuesInvestment.map((item) => {
                return (
                  <Option key={`VALUES_${item.entityId}`} value={item.entityId}>
                    {item.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default InvestorForm;
