import React, { useEffect, useState } from 'react';
import { Form, Slider } from 'antd';
import { BoxContainer } from '../../../GlobalStyles';
import { PhasesHeader, PhaseSliders } from './style';
import AlertMessage from '../../Alert/AlertMessage';
import api from '../../../core/services/api';
import notificationMessage from '../../notifications/notificationMessage';
import ErrorNotification from '../../notifications/errorNotification';
import { isEmpty } from '../../../helpers/array.helper';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  ask: boolean;
  setAsk: (ask: boolean) => void;
  setFormName: (formName: string) => void;
  setNext: (next: string) => void;
  initialData?: any;
  setData?: (data: any) => void;
  nameDiagnostic?: string;
}

interface PhaseType {
  entityId: number;
  text: string;
  value: number[];
  min: number;
  max: number;
}

interface IPhase {
  entityId: number;
  name?: string;
}

interface IScaleLikert {
  entityId: number;
  orderScale: number;
}

interface IScalePhase {
  phase: IPhase;
  diagnostic: IDiagnostic;
  scaleLikert: IScaleLikert[];
}

interface IDiagnostic {
  entityId: number;
  name: string;
}

function PhasesAssociation(props: IParams) {
  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [configMessage, setConfigMessage] = useState({
    title: 'Escala definida com sucesso!',
    description: 'Avance para a próxima etapa.',
    textOkButton: 'Continuar',
    tabNext: '2',
  });
  const [phases, setPhases] = useState<PhaseType[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();

  const getPhases = () => {
    showLoading();
    api
      .get('methodology/listPhasesByMethodology', {
        params: {
          diagnostic: props.initialData?.entityId,
        },
      })
      .then((response: any) => {
        const phases = response.data;
        const newPhases = phases.map((phase: IPhase, index: number) => {
          return {
            entityId: phase.entityId,
            text: phases[index].name,
            value: [index, index],
            min: index,
            max: 10 - phases.length + index + 1,
          };
        });
        setPhases([...newPhases]);
      })
      .finally(hideLoading);
  };
  const arrageValues = (values: any) => {
    const result: IScalePhase[] = values.map((value: any, index: number) => {
      const [min, max] = value.value;
      return {
        phase: {
          entityId: value.entityId,
        },
        diagnostic: {
          entityId: props.initialData.entityId,
          name: props.nameDiagnostic,
        },
        scaleLikert: Array.from(Array(max - min + 1), (_, i) => i + min).map(
          (value: number) => {
            return {
              orderScale: value,
            };
          },
        ),
      };
    });

    return result;
  };

  const onOk = () => {
    props.setNext(configMessage.tabNext);
    setIsOpenModal(false);
  };

  const onFinish = () => {
    showLoading();
    api
      .post('/scalesPhases/create', arrageValues(phases))
      .then((response: any) => {
        setIsOpenModal(true);
        notificationMessage('create');
        props.setData && props.setData(response.data);
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(hideLoading);
  };

  const getPhaseByIndex = (
    searchIndex: number,
    action: 'next' | 'previous',
  ): PhaseType[] => {
    const items = phases.filter((_, index) =>
      action.includes('next') ? index > searchIndex : index < searchIndex,
    );

    return action.includes('previous') ? items.reverse() : items;
  };

  const getAction = (
    phase: PhaseType,
    range: number[],
  ): 'decrement' | 'increment' => {
    const min = phase.value[0];
    return range[0] === min ? 'increment' : 'decrement';
  };

  const changePhase = (phaseIndex: number, range: number[]) => {
    let [min, max] = range;
    const phaseSelected = phases[phaseIndex];
    const next = getPhaseByIndex(phaseIndex, 'next');
    const previous = getPhaseByIndex(phaseIndex, 'previous');
    const action = getAction({ ...phaseSelected }, range);
    phaseSelected.value = range;

    if (action.includes('increment')) {
      next.forEach((phase) => {
        const [minItem, maxItem] = phase.value;
        const quantity = maxItem - minItem;
        phase.value = [++max, max + quantity];
        max += quantity;
      });
    } else {
      previous.forEach((phase) => {
        const value = --min;
        const [minItem, maxItem] = phase.value;
        const quantity = maxItem - minItem;

        phase.value = [value - quantity, value];
        min -= quantity;
      });
    }

    setPhases([...phases]);
  };

  const executeMarks: any = (min: number, max: number) => {
    const result: any = {};

    for (let i = min; i <= max; i++) {
      result[i] = i;
    }

    return result;
  };

  useEffect(() => {
    props.setFormName('diagnosisPhasesAssociation');

    if (props.initialData === undefined) {
      setIsOpenModal(true);
      setConfigMessage({
        title: 'Atenção!',
        description:
          'Você precisa escolher uma metodologia antes de prosseguir.',
        textOkButton: 'Adicionar metodologia',
        tabNext: '0',
      });
    }
  }, []);

  useEffect(() => {
    const data = sessionStorage.getItem('phases-diagnostic');
    if (data) {
      setPhases(JSON.parse(data));
    } else {
      getPhases();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(phases)) {
      sessionStorage.setItem('phases-diagnostic', JSON.stringify(phases));
    }
  }, [phases]);

  return (
    <BoxContainer noPadding>
      <Loading
        spinning={loading}
        width={300}
        height={300}
        position={'absolute'}
      >
        <Form
          name="diagnosisPhasesAssociation"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onFieldsChange={(_, allFields) => {
            !props.ask &&
              allFields.map((item) => {
                if (item.value !== undefined) {
                  props.setAsk(true);
                }
                return null;
              });
          }}
        >
          <PhasesHeader>
            <div className="subTitle">
              Nós trabalhamos com a escala likert de 0 a 10, então será
              necessário associar <b>&nbsp;cada fase com pontos da escala</b>
            </div>
          </PhasesHeader>
          {phases.map((phase, index: number) => {
            const [min, max] = phase.value;

            return (
              <PhaseSliders key={`phase_key_${phase.entityId}`}>
                <div className="label">
                  {`Fase ${index + 1} : ${phase.text}`}
                </div>
                <div className="slider">
                  <Slider
                    min={0}
                    max={10}
                    marks={executeMarks(phase.min, phase.max)}
                    key={index}
                    range={true}
                    value={[min, max]}
                    onChange={(range) => changePhase(index, range)}
                  />
                </div>
              </PhaseSliders>
            );
          })}
        </Form>

        <AlertMessage
          visible={isOpenModal}
          message={configMessage.title}
          description={configMessage.description}
          textCancelButton="Fechar"
          textOkButton={configMessage.textOkButton}
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Loading>
    </BoxContainer>
  );
}

export default PhasesAssociation;
