import styled from 'styled-components';

interface IProps {
  width?: string;
  margin?: string;
}

export const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  overflow: auto;
  background-color: #fff;
`;

export const Wrapper = styled.div<IProps>`
  padding: 0;
  margin: ${(props) => props.margin || '0 auto'};

  @media print {
    padding: 28px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh - 80px);
  color: var(--primary-color);
  padding: 14px 20px;
`;

export const BoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--primary-color);
  .ant-btn-primary,
  .ant-btn-default {
    display: flex;
    width: 200px;
    text-align: center;
    background-color: var(--thirdy-color);
    border: none;
    border-radius: 6px;
    letter-spacing: 1px;
    font-weight: bold;
    justify-content: center;
    align-items: center;

    & span {
      font-size: 0.8em;
      line-height: 32px;
      font-weight: 800;
    }

    :hover {
      background-color: var(--primary-color);
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 1.8em;
  font-weight: 800;
`;

export const BoxData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & .boxLine {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    & .question {
      font-weight: bold;
    }

    & .answer {
      color: #888;
    }
  }
`;

export const BoxResult = styled.div`
  display: flex;
  flex-direction: row;

  & .value {
    color: var(--secondary-color);
  }
`;

export const Result = styled.div<IProps>`
  width: ${(props) => (props.width ? props.width : '40%')};
  margin-top: 10px;

  & .box {
    margin-left: 5%;
    width: 90%;

    & .title,
    .line,
    .result {
      display: flex;
      flex-direction: row;
      width: 100%;

      & .item,
      .value {
        width: 50%;
        font-size: 1.2em;
        text-align: center;
      }

      & .value {
        color: var(--secondary-color);
      }
    }

    & .line,
    .result {
      padding: 4px 4px;
      border-bottom: 1px solid #ddd;
    }

    & .title {
      & .item {
        font-weight: bold;
      }
    }

    & .result {
      background-color: var(--primary-color);
      color: var(--white-color);

      & .value {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-color);
      }
    }

    & .operation {
      width: 100%;
      text-align: center;
      font-weight: bold;
      padding: 4px 4px;
      border: 1px dashed;
      border-top: none;
    }
  }

  & .title {
    margin-bottom: 5px;
    font-weight: bold;
  }

  & .subtitle {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    & .value {
      margin: 0 5px;
      color: var(--secondary-color);
      font-weight: bold;
    }
  }

  & .text {
    margin: 10px 0;
    font-size: 12px;
    text-align: justify;
    color: #05233d;
  }
`;

export const BoxOperation = styled.div`
  margin-top: 10px;
  text-align: justify;
  font-size: 0.8em;
  padding: 2px 10px;
  color: #888;
`;

export const Graphic = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '60%')};
`;

export const BoxAnswers = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  & .title {
    font-weight: bold;
    margin-bottom: 0px;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 10px 10px;
  }

  & .collapse {
    border: 1px dashed;
    border-top: none;

    & .answer {
      margin-bottom: 10px;
      padding: 10px 10px;

      & .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;

        .question {
          font-weight: bold;
        }

        .value {
          width: 100px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: var(--secondary-color);
        }
      }
    }
  }
`;

export const BoxPreMoneyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
`;

const BoxPreMoney = styled.div<{
  boxShadow?: string;
  border?: string;
  maxWidth?: string;
}>`
  justify-self: center;
  text-align: center;
  box-shadow: ${(props) => props.boxShadow || '0px 0px 10px #05233D4D'};
  border: ${(props) => props.border || '1px solid #05233D4D'};
  border-radius: 5px;
  padding: 11px;
  max-width: ${({ maxWidth = '370px' }) => maxWidth};
  width: 100%;
  background-color: #fff;

  @media (max-width: 800px) {
    margin: 0 auto;
  }
`;

const ChartWrapper = styled.div<{
  chart: 'view' | 'print';
  left?: number;
  width?: string;
  widthMd?: string;
  right?: number | string;
}>`
  width: ${({ chart, width }) => {
    if (width) return width;
    return chart === 'view' ? '100%' : '61%';
  }};

  left: ${({ left }) => left};
  right: ${({ right }) => right};
  opacity: ${({ chart }) => (chart === 'view' ? 1 : 0)};
  position: ${({ chart }) => (chart === 'view' ? 'relative' : 'absolute')};

  @media print {
    opacity: ${({ chart }) => (chart === 'print' ? 1 : 0)};
    position: ${({ chart }) => (chart === 'print' ? 'relative' : 'absolute')};
  }

  @media (max-width: 1400px) {
    width: ${({ chart, widthMd }) => {
      if (widthMd) return widthMd;
      return chart === 'view' ? '100%' : '90%';
    }};
  }

  min-width: ${({ chart }) => (chart === 'view' ? '500px' : '0')};
`;

const ButtonBackToTop = styled.button<{
  show?: boolean;
}>`
  position: fixed;
  bottom: 20px;
  right: 120px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100px)')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  &:hover {
    background-color: var(--quaternary-support);
  }
`;

export { ButtonBackToTop, BoxPreMoney, ChartWrapper };
