import styled from 'styled-components';

const BoxContent = styled.div<{
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '')};
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div<{
  width?: string;
  justify?: string;
}>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  width: ${({ width }) => (width ? width : '100%')};
  font-size: 1.8rem;
  font-weight: bold;

  & .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    height: 100%;
  }

  svg {
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 3px;
    margin-top: 10px;
    font-size: 18px;
  }
`;

const Extra = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
`;

const SubTitle = styled.p`
  font-size: 1.2em;
  font-weight: bold;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export { BoxContent, Content, Extra, Header, Title, SubTitle };
