import styled from 'styled-components';

const WrapperBannerImg = styled.img`
  margin: 11px 0 14px 0;
  object-fit: contain;
  object-position: left center;
  cursor: pointer;
`;

export { WrapperBannerImg };
