import { Col, Row, Spin } from 'antd';
import { useMemo, useEffect, useState } from 'react';
import Title from '../../../../components/Title';
import { Card, PurpleButton } from '../../../../GlobalStyles';
import { Gauge } from '@ant-design/plots';
import { useNavigate } from 'react-router-dom';
import DashboardStartupService from '../../../../services/dashboard-startup/DashboardStartupService';
import { useAuth } from '../../../../core/hooks/auth';
import ICurrentTrack from '../../../../core/interfaces/ICurrentTrack';
import { notification } from '../../../../helpers/notification.helper';
import useLoading from '../../../../core/hooks/useLoading';

function CurrentTrack() {
  const { logged } = useAuth();
  const navigate = useNavigate();
  const { showLoading, hideLoading, loading } = useLoading();

  const [currentTrack, setCurrentTrack] = useState<ICurrentTrack>({
    realized: 0,
    total: 0,
  });
  const dashboardService = useMemo(() => DashboardStartupService.init(), []);

  useEffect(() => {
    showLoading();
    dashboardService
      .getCurrentTrack(Number(logged?.entityId))
      .then((data) => setCurrentTrack(data))
      .catch(() => notification.error('Erro ao recuperar trilha'))
      .finally(hideLoading);
  }, [dashboardService, logged, showLoading, hideLoading]);

  const config = {
    percent: 0.75,
    range: {
      color: '#960096',
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: false as const,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '30px',
          fontWeight: 700,
          color: '#05233D',
        },
        formatter: () => `${currentTrack.realized}/${currentTrack.total}`,
      },
      content: {
        style: {
          fontSize: '16px',
          color: '#05233D',
        },
        formatter: () => 'Passos finalizados',
      },
    },
  };

  return (
    <Card>
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Title align="center" size={22}>
              Trilha atual
            </Title>
          </Col>
          <Col span={24}>
            <Gauge
              {...config}
              style={{
                height: '250px',
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto',
              }}
            />
          </Col>
          <Col span={24}>
            <PurpleButton
              type="primary"
              style={{ margin: '0 auto', display: 'block' }}
              onClick={() => navigate('/startup-journey')}
            >
              Verificar passos
            </PurpleButton>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
}

export default CurrentTrack;
