import styled from 'styled-components';

const StartupListWrapper = styled.div`
  overflow: auto;
  padding-bottom: 8px;

  & > div + div {
    margin-top: 16px;
  }

  & > div {
    display: grid;
    gap: 8px;
    cursor: pointer;
    grid-template-columns: repeat(3, minmax(180px, 1fr)) minmax(100px, 1fr);
  }
`;

export { StartupListWrapper };
