import React, { useEffect, useState } from 'react';
import { Box, BoxContent, Header } from './style';
import image from '../../../assets/images/logo-rocket.png';
import { Col, Divider, notification, Row, Select } from 'antd';
import IMethodology from '../../../core/interfaces/models/IMethodology';
import { useAuth } from '../../../core/hooks/auth';
import api from '../../../core/services/api';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';

function VentureCapitalView() {
  const [methodologies, setMethodologies] = useState<IMethodology[]>([]);
  const [methodologyId, setMethodologyId] = useState(0);
  const [phases, setPhases] = useState<any[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();
  const { logged } = useAuth();
  const id = logged?.client;

  const getMethodology = () => {
    showLoading();
    api
      .get<IMethodology[]>('/methodology/list', {
        params: {
          client: id,
        },
      })
      .then((response) => {
        setMethodologies(response.data);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao listar metodologias',
        });
      })
      .finally(hideLoading);
  };

  const changeMethodology = (entityId: number) => {
    const methodology = methodologies.find(
      (methodology) => methodology.entityId === entityId,
    );
    setPhases(methodology?.phases || []);
    setMethodologyId(entityId);
  };

  useEffect(() => {
    getMethodology();
  }, []);

  useEffect(() => {
    console.log(phases);
  }, [phases]);

  return (
    <BoxContent>
      <Loading width={200} height={200} spinning={loading}>
        <Header>
          <img alt="" src={image} />
          <div className="description">
            Conhecido também como Capital de Risco, é a forma de avaliar uma
            startup adotando a perspectiva de um investidor, que olha para o
            valor da empresa com enfoque no retorno sobre o investimento feito
            em determinado período de tempo.
          </div>
        </Header>
        <Box>
          <div className="colunm">
            <Select
              style={{ minWidth: '300px' }}
              placeholder="Selecione a metodologia"
              value={methodologyId || null}
              onChange={(entityId: number) => changeMethodology(entityId)}
            >
              {methodologies?.map((item: IMethodology, index: number) => {
                return (
                  <Select.Option
                    key={`option_methodology_${index}`}
                    value={item?.entityId}
                  >
                    {item?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>

          <div className="content">
            <Row gutter={[16, 16]} className={'row'}>
              <Col span={6}>
                <b>Nome da fase</b>
              </Col>
              <Col span={6}>
                <b>Descrição</b>
              </Col>
              <Col span={6}>
                <b>Ordem da fase</b>
              </Col>
              <Col span={6}>
                <b>Estágio</b>
              </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]} className={'row'}>
              {phases?.map((phase: any) => {
                return (
                  <>
                    <Col span={6}>{phase?.name}</Col>
                    <Col span={6}>{phase?.description}</Col>
                    <Col span={6}>{phase?.orderPhase}</Col>
                    <Col span={6}>{phase?.stage?.name || 'Sem estágio'}</Col>
                  </>
                );
              })}
            </Row>
          </div>
        </Box>
      </Loading>
    </BoxContent>
  );
}

export default VentureCapitalView;
