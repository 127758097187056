import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid var(--support-color);
  img {
    object-fit: cover;
    object-position: left center;
  }
`;

const BodyWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 115px;
  align-items: center;
  flex-direction: column;

  & h1,
  p {
    margin-left: 1rem;
  }
`;

const ProgressBar = styled.div<{
  progress: number;
}>`
  height: 8px;
  position: relative;
  align-self: flex-end;
  background-color: #f0f2f5;

  &::before {
    content: '';
    width: ${({ progress }) => `${progress}%`};
    height: inherit;
    position: absolute;
    background-color: #960096;
  }
`;

export { Wrapper, BodyWrapper, ProgressBar };
