import CrudService from './CrudService';
import { IMentor } from '../core/interfaces/IMentor';
import api from '../core/services/api';
import { errorCallback } from '../helpers/promise.helper';
import IPageable from '../core/interfaces/IPageable';
import IMentorBusinessInformation from '../core/interfaces/IMentorBusinessInformation';

class MentorService extends CrudService {
  public static init(): MentorService {
    return new MentorService({
      requestMapping: 'mentor',
    });
  }

  public async getAllMentors(): Promise<IMentor[]> {
    return api
      .get<IMentor[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createMentor(mentor: IMentor): Promise<any> {
    return api
      .post<any>(`${this.requestMapping}`, mentor)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getMentorById(id: number): Promise<IMentor> {
    return api
      .get<IMentor>(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public findAllByAccelerator(id: number, params: Record<string, unknown>) {
    return api
      .get<IPageable<IMentor>>(`${this.requestMapping}/accelerator/${id}`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getBusinessInformation(id: number) {
    return api
      .get<IMentorBusinessInformation>(
        `${this.requestMapping}/business-information/${id}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public updateBusinessInformation(
    id: number,
    request: Record<string, unknown>,
  ) {
    return api
      .put(`${this.requestMapping}/business-information/${id}`, request)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default MentorService;
