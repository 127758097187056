import { Content } from './style';
import logo from '../../assets/images/logo-blue.svg';
import Title from '../../components/Title';
import GoBack from '../GoBack/GoBack';

interface IParams {
  goBack?: boolean;
}

function PrivacyPolice(props: IParams) {
  return (
    <Content url={logo}>
      {props.goBack && (
        <div className="back">
          <GoBack enableBackground={true} path={'/login'} />
        </div>
      )}
      <Title margin={'20px 0 0 0'} size={24}>
        Política de privacidade
      </Title>
      <div className="bgLogo"></div>
      <div className="text">
        Queremos ajudar você a entender como armazenamos os seus dados no
        momento da utilização do nosso site, bem como para quê coletamos esses
        dados e como você pode gerenciar isso. Caso não concorde com as
        condições aqui descritas, interrompa o uso. Preocupamo-nos em estar em
        conformidade com as Leis Federais que regem a proteção de dados pessoais
        na internet.
      </div>
      <div className="text">
        Este documento foi elaborado com base na Lei Federal nº 13.709/2018 –
        Lei Geral de Proteção de Dados (LGPD). Leia com bastante atenção nossa
        Política de Privacidade antes de utilizar.
      </div>
      <div className="title">1. Atualização da Política de Privacidade</div>
      <div className="text">
        Este documento poderá, a qualquer momento, ser modificado ou readequado
        de acordo com o aprimoramento das operações. Ao ocorrer, iremos
        notificá-lo através do e-mail cadastrado para que você analise as novas
        condições antes de optar por continuar a utilizar nossos serviços, te
        dando a opção de revogar o consentimento do uso dos seus dados. Ao
        cadastrar-se, você deve dar o aceite nos termos de uso e política de
        privacidade para concordar com o tratamento dos dados que lhe são
        coletados.
      </div>
      <div className="title">2. Segurança das informações fornecidas</div>
      <div className="text">
        Comprometemo-nos a não divulgar os dados dos usuários cadastrados sem
        autorização para fins que não seja para consecução dos serviços
        oferecidos, a não ser mediante ordem judicial. Utilizamos os seus dados
        com o objetivo de melhorar cada vez mais a sua experiência de compra e
        ir adequando nossas funcionalidades de acordo com suas necessidades. Os
        usuários do sistema, devem comprometer-se a cumprir o aceite dos termos
        de uso e política de privacidade e não violar nenhum componente de
        segurança.
      </div>
      <div className="title">3. Lei Geral de Proteção de Dados (LGPD)</div>
      <div className="text">
        3.1 Por meio desta cláusula, o usuário declara ciência e seu expresso
        consentimento para o sistema coletar e armazenar dados quando julgar
        necessário.
      </div>
      <div className="text">
        3.2 O usuário autoriza a coleta de dados pessoais imprescindíveis à
        execução deste contrato, tendo sido informado quanto ao tratamento de
        dados que será realizado pelo sistema, nos termos da Lei nº13.709/2018,
        especificamente quanto a coleta dos seguintes dados:
        <ol type="a">
          <li>Nome Completo;</li>
          <li>Data de nascimento;</li>
          <li>Número do Cadastro de Pessoas Físicas (CPF);</li>
          <li>Números de telefone, WhatsApp e endereções de e-mail;</li>
          <li>Número do Cadastro de Pessoas Jurídica (CNPJ);</li>
          <li>Nome da Aceleradora;</li>
          <li>Endereço;</li>
        </ol>
      </div>
      <div className="text">
        3.3 Finalidade do tratamento de dados: o usuário autoriza que o sistema
        utilize os dados pessoais e dados pessoais sensíveis listados neste
        contrato para as seguintes finalidades:
        <ol type="a">
          <li>Possibilitar a execução do presente contrato;</li>
          <li>Possibilitar que o sistema identifique o usuário;</li>
          <li>
            Possibilitar que o sistema entre em contato com o usuário quando
            necessário;
          </li>
          <li>
            Possibilitar que o sistema elabore contratos comerciais e emita
            cobranças contra o usuário;
          </li>
          <li>Proporcionar a melhor experiência e suporte;</li>
          <li>Cumprir as obrigações legais e regulatórias;</li>
          <li>Gerar maior segurança e autenticidade;</li>
          <li>Aprimoramento dos serviços prestados;</li>
          <li>Prevenção e resolução de ´problemas técnicos ou de segurança;</li>
          <li>Exercício regular de direitos do sistema;</li>
          <li>
            Cumprimento de ordem judicial, de autoridade competente ou de órgão
            fiscalizador;
          </li>
          <li>Fornecer produtos ou informar promoções.</li>
        </ol>
      </div>
      <div className="text">
        3.4 Compartilhamento de dados: Os dados coletados poderão ser utilizados
        para identificação de terrorismo, compartilhamento para órgãos de
        segurança, conforme solicitação legal pertinente, compartilhamento com
        autoridade administrativa e judicial no âmbito de suas competências com
        base no estrito cumprimento do dever legal, bem como com os órgãos de
        proteção ao crédito a fim de garantir a adimplência do USUÁRIO perante o
        sistema.
      </div>
      <div className="text">
        3.5 O USUÁRIO poderá registrar e gravar todos os dados fornecidos em
        toda comunicação realizada com a sua equipe, seja por correio
        eletrônico, mensagens, telefone ou qualquer outro meio.
      </div>
      <div className="text">
        3.6 Os dados coletados com base no legítimo interesse do USUÁRIO, bem
        como para garantir a fiel execução do contrato por parte do sistema,
        fundamentam-se no artigo 7º da LGPD.
      </div>
      <div className="text">
        3.7 Responsabilidade pela Segurança dos dados: o sistema se
        responsabiliza por manter medidas de segurança, técnicas e
        administrativas suficientes a proteger os dados pessoais do Titular e à
        Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao Titular,
        caso ocorra algum incidente de segurança que possa acarretar risco ou
        dano relevante, conforme artigo 48 da Lei n° 13.709/2020.
      </div>
      <div className="text">
        3.8 Exclusão dos dados: será efetuado sem que haja prejuízo por parte do
        sistema, para tanto, caso o USUÁRIO deseje efetuar a revogação de algum
        dado, deverá preencher uma declaração neste sentido, ciente que a
        revogação de determinados dados poderá importar em eventuais prejuízos
        na prestação de serviços.
      </div>
      <div className="text">
        3.9 Término do Tratamento de dados: Ao sistema é permitido manter e
        utilizar os dados pessoais do Titular durante todo o período
        contratualmente firmado para as finalidades relacionadas neste termo e
        ainda após o término da contratação, pelo prazo de 05 (cinco) anos, para
        cumprimento de obrigação legal ou impostas por órgãos de fiscalização,
        nos termos do artigo 16 da Lei n° 13.709/2018. Dados pessoais
        anonimizados, sem possibilidade de associação ao indivíduo, poderão ser
        mantidos por período indefinido.
      </div>
      <div className="text">
        3.10 O USUÁRIO autoriza, neste mesmo ato, a guarda dos documentos
        (contratos/documentos pessoais ou fiscais/notificações/ordens de
        serviços) - em que pese eles possuam dados pessoais - por parte do
        EMISSOR a fim de que ele cumpra com o determinado nas demais normas que
        regulam o presente contrato, bem como para o cumprimento da obrigação
        legal nos termos do artigo 16, inciso I, da Lei Geral de Proteção de
        Dados.
      </div>
      <div className="text">
        3.11 O sistema informa que a gerência de dados ocorrerá através de um
        sistema que colherá e tratará os dados na forma da lei.
      </div>
      <div className="text">
        3.12 O sistema informa que efetuará a manutenção do registro das
        operações de tratamento de dados pessoais da forma mencionada na
        cláusula anterior.
      </div>
      <div className="text">
        3.13 Rescindido o contrato, os dados pessoais coletados serão
        armazenados pelo tempo determinado previsto neste termo de uso.
      </div>
      <div className="text">
        3.14 Passado o termo de guarda pertinente ao sistema se compromete a
        efetuar o descarte dos dados adequadamente.
      </div>
      <div className="text">
        3.15 Seus direitos: O USUÁRIO tem direito a obter do sistema, em relação
        aos dados por ele tratados, a qualquer momento, e mediante requisição,
        conforme previsão do art. 18 da LGPD:
        <ol type="a">
          <li>Solicitar confirmação da existência de tratamento;</li>
          <li>Acessar seus dados pessoais;</li>
          <li>
            Solicitar a correção de dados incompletos, anexados ou
            desatualizado;
          </li>
          <li>
            Solicitar anonimização, bloqueio ou eliminação de dados
            desnecessários, excessivos ou tratados em desconformidade com o
            disposto na LGPD;
          </li>
          <li>
            Solicitar a portabilidade dos dados a outro fornecedor de serviço ou
            produto, mediante requisição expressa, de acordo com a
            regulamentação da autoridade nacional, observados os segredos
            comercial e industrial;
          </li>
          <li>
            Solicitar a eliminação dos dados pessoais tratados com o seu
            consentimento, exceto nas hipóteses previstas neste termo e no
            artigo 16 da LGPD;
          </li>
          <li>
            Solicitar informação das autoridades públicas e privadas com as
            quais o controlador realizou uso compartilhado de dados;
          </li>
          <li>
            Obter informações sobre a possibilidade de não fornecer
            consentimento e sobre as consequências da negativa; e
          </li>
          <li>
            Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº
            13.709, de 2018.
          </li>
        </ol>
      </div>
      <div className="text">
        3.16 O consentimento conferido por meio deste instrumento particular
        poderá ser revogado a qualquer momento pelo usuário, mediante sua
        manifestação expressa, por meio de solicitação via e-mail ou por
        correspondência encaminhada ao sistema, ratificados os tratamentos
        realizados sob amparo do presente consentimento, nos termos do inciso VI
        do “caput” do art. 18 da Lei nº 13.709, de 2018. Nossa equipe estará
        pronta para atender sua solicitação no prazo de até 72 horas.
      </div>
    </Content>
  );
}

export default PrivacyPolice;
