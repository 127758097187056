import React from 'react';
import { BoxTitle } from './styles';

interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: React.ReactNode;
  color?: string;
  size?: number;
  weight?: number;
  align?: string;
  margin?: string;
  className?: string;
  lineHeight?: number;
  lineClamp?: number;
  sizeXs?: number;
}

function Title({ tag = 'h1', children, ...props }: Props) {
  const title = children?.toString();
  return (
    <BoxTitle {...props}>
      {tag.includes('h1') && <h1 title={title}>{children}</h1>}
      {tag.includes('h2') && <h2 title={title}>{children}</h2>}
      {tag.includes('h3') && <h3 title={title}>{children}</h3>}
      {tag.includes('h4') && <h4 title={title}>{children}</h4>}
      {tag.includes('h5') && <h5 title={title}>{children}</h5>}
    </BoxTitle>
  );
}

export default Title;
