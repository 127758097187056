import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { useSearchParams } from 'react-router-dom';
import api from '../../../core/services/api';
import BaseScreen from '..';
import useLoading from '../../../core/hooks/useLoading';
import { WrapperButtonAcess } from './styles';

function ValidateEmail() {
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<string>('');
  const { loading, hideLoading, showLoading } = useLoading();

  const validate = React.useCallback(() => {
    const params = {
      validateHash: searchParams.get('key'),
    };

    showLoading();
    api
      .post('user/account/validate', null, {
        params,
      })
      .then((response) => {
        if (response.data.emailValidated === true) {
          setResult('Seu e-mail foi validado com sucesso!');
        } else {
          return Promise.reject();
        }
      })
      .catch(() => {
        setResult('Algo deu errado!');
        notification.error({
          message: 'Algo deu errado!',
          description: 'Não foi possível validar seu email!',
        });
      })
      .finally(hideLoading);
  }, [searchParams, hideLoading, showLoading]);

  useEffect(() => {
    validate();
  }, [validate]);

  return (
    <BaseScreen
      alignTitle="center"
      title={loading ? 'Aguarde, seu e-mail esta sendo validado...' : result}
      content={
        <WrapperButtonAcess>
          <Button disabled={loading} size="small" type="primary" href="/">
            Acessar a plataforma do Innovation Studio
          </Button>
        </WrapperButtonAcess>
      }
    />
  );
}

export default ValidateEmail;
