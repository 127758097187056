import React from 'react';
import { useAuth } from '../hooks/auth';
import { useLocation, Navigate } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
  roles: any[];
}

function ProtectedRoute({ children, roles }: IProps) {
  const location = useLocation();
  const { isLogged, isPermission } = useAuth();

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isLogged && !isPermission(roles)) {
    return <Navigate to="/error/403" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
