import React from 'react';
import { ModalBody } from '../../../GlobalStyles';
import { ModalContent } from '../style';
import { Button } from 'antd';

interface Props {
  save(): void;

  close(): void;

  formName?: string;

  withoutSave(): void;

  modalVisible: boolean;
}

function ConfirmChangeTab({
  save,
  close,
  formName,
  withoutSave,
  modalVisible,
}: Props) {
  return (
    <ModalBody
      width={550}
      footer={null}
      closable={true}
      onCancel={close}
      destroyOnClose={true}
      open={modalVisible}
    >
      <ModalContent>
        <div className="title">Salvar antes de sair?</div>
        <div className="text">
          Deseja salvar antes de avançar para a próxima seção?
          <p>
            Avançar sem salvar apagará o progresso das questões da sua seção
            atual.
          </p>
        </div>
        {formName ? (
          <Button type="primary" form={formName} key="submit" htmlType="submit">
            Salvar e avançar
          </Button>
        ) : (
          <Button type="primary" onClick={save}>
            Salvar e avançar
          </Button>
        )}
        <Button type="default" onClick={withoutSave}>
          Avançar sem salvar
        </Button>
      </ModalContent>
    </ModalBody>
  );
}

export default ConfirmChangeTab;
