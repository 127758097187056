import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxLevels,
  BoxMessage,
  BoxProfilesList,
  BoxTree,
  Header,
  PurppleText,
} from '../../admin/profiles/style';
import Title from '../../../components/Title';
import { Button, Divider, Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ButtonIcon from '../../../components/Icons/ButtonIcon';
import { AddUser, Plus } from '../../../components/Icons/Icon';
import Text from '../../../components/Text';
import InviteNewUser from '../../admin/profiles/ModalNewUser/InviteNewUser';
import { findRole } from '../../../core/enums';
import ProfileService from '../../../services/ProfileService';
import MemberService from '../../../services/MemberService';
import { HiEllipsisVertical } from 'react-icons/hi2';
import { useAuth } from '../../../core/hooks/auth';
import { isEmpty } from '../../../helpers/array.helper';
import ProfileItem from '../../admin/profiles/ProfileItem/ProfileItem';
import { IMember } from '../../../core/interfaces/IMember';
import { IProfile } from '../../../core/interfaces';
import ProfileLevelStartup from './ProfileLevelStartup';
import useLoading from '../../../core/hooks/useLoading';
import InviteNewMemberStartup from './InviteNewMemberStartup';
import StartupService from '../../../services/StartupService';

function MemberScreen() {
  const [modalNewUserVisible, setModalNewUserVisible] = useState(false);
  const [modalProfileVisible, setModalProfileVisible] = useState(false);

  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [members, setMembers] = React.useState<IMember[]>([]);
  const profilesService = ProfileService.init();
  const startupService = StartupService.init();
  const { logged } = useAuth();
  const features = logged?.profile && logged?.profile[0]?.features;
  const id = logged?.entityId;
  const { loading, showLoading, hideLoading } = useLoading();

  const getProfiles = () => {
    showLoading();
    profilesService
      .getAllProfilesByStartup(Number(id))
      .then((res) => {
        setProfiles(res);
      })
      .finally(hideLoading);
  };
  const getMembers = () => {
    showLoading();
    startupService
      .getAllMembersByStartup(Number(id))
      .then((res) => {
        setMembers(res);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getProfiles();
    getMembers();
  }, []);

  const showModalInvite = () => {
    setModalNewUserVisible(!modalNewUserVisible);
  };

  const showModalProfileLevel = () => {
    setModalProfileVisible(!modalProfileVisible);
  };

  return (
    <>
      <InviteNewMemberStartup
        title="Novo usuário"
        visible={modalNewUserVisible}
        onCancel={showModalInvite}
        updateList={getMembers}
        id={id}
      />
      <ProfileLevelStartup
        title="Novo perfil de acesso"
        visible={modalProfileVisible}
        onCancel={showModalProfileLevel}
        features={features}
        id={id}
        updateList={getProfiles}
      />
      <Box>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Title size={16}>Perfis</Title>
          <ButtonIcon
            textColor={'#000000'}
            icon={<AddUser />}
            color={'default'}
            onClick={showModalInvite}
          />
        </Header>
        <BoxProfilesList>
          <BoxMessage>
            {isEmpty(members) ? (
              <>
                {isEmpty(profiles) ? (
                  <>
                    <span>
                      Você não pode convidar usuários sem um <b>nível perfil</b>{' '}
                      cadastrado.
                    </span>
                  </>
                ) : (
                  <>
                    <Text margin={'0 0 10px 0'}>
                      Agora você pode criar perfis para seus colaboradores, cada
                      perfil terá um nível de acesso diferente.
                    </Text>
                    <Button onClick={showModalInvite} className={'item-button'}>
                      Convidar Usuário
                    </Button>
                  </>
                )}
              </>
            ) : (
              <BoxProfilesList>
                {members?.map((item, index) => {
                  return (
                    <ProfileItem
                      showLoading={showLoading}
                      hideLoading={hideLoading}
                      key={index}
                      updateList={getMembers}
                      entityId={item.entityId || 0}
                      name={item.profiles ? item.profiles[0].description : ''}
                      date={item.creation || ''}
                      lastUpdate={item.lastUpdated || ''}
                      email={item.email}
                      status={item?.active || false}
                    />
                  );
                })}
              </BoxProfilesList>
            )}
          </BoxMessage>
        </BoxProfilesList>
      </Box>
      <Box>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Title size={16}>Níveis de perfil</Title>
          <ButtonIcon
            typeShape={'dashed'}
            textColor={'#000000'}
            icon={<Plus />}
            color={'default'}
            onClick={showModalProfileLevel}
          />
        </Header>

        <BoxLevels>
          {isEmpty(profiles) ? (
            <BoxMessage>
              <Text size={28} weight={900} margin={'0 0 10px 0'}>
                Você ainda não possui níveis de perfil de acesso
              </Text>
              <Text size={16} weight={400} margin={'0 0 14px 0'}>
                Para convidar seus usuários, cadastre primeiro um nível de
                acesso.
              </Text>
              <Button onClick={showModalProfileLevel} className={'item-button'}>
                Criar Perfil
              </Button>
            </BoxMessage>
          ) : (
            <>
              {profiles.map((profile, index) => (
                <BoxTree key={index} style={{ padding: '8px 10px 8px 10px' }}>
                  <Header
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Title size={14}>{profile.description}</Title>
                      <PurppleText
                        fontSize={'0.8rem'}
                        style={{ marginBottom: '10px' }}
                      >
                        {' '}
                        {profile.contUsers} usuários{' '}
                      </PurppleText>
                    </div>
                    <HiEllipsisVertical
                      className={'icon-button'}
                      onClick={() => {
                        alert('iooioimi');
                      }}
                      size={23}
                    />
                  </Header>
                  <Divider style={{ margin: '0px' }} />
                  <Title size={14}>Níveis de acesso</Title>
                  <Tree
                    showLine
                    switcherIcon={<DownOutlined />}
                    treeData={profile.features?.map((feature) => {
                      return {
                        title: findRole(feature.description),
                        key: feature.id,
                        children: feature?.subFeatures?.map((subFeature) => {
                          return {
                            title: subFeature.description,
                            key: subFeature.id,
                          };
                        }),
                      };
                    })}
                  />
                </BoxTree>
              ))}
            </>
          )}
        </BoxLevels>
      </Box>
    </>
  );
}

export default MemberScreen;
