import React from 'react';
import { Button, Col, Row } from 'antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { ModalBody } from '../../../../GlobalStyles';
import pathWarningIcon from '../../../../assets/icons/warning_icon.png';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';

interface Props extends IPropsModal {
  finish(): void;
}

function FinishActivityModal({ visible, finish, onCancel }: Props) {
  return (
    <ModalBody
      width={420}
      footer={null}
      closable={true}
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <Row gutter={[0, 16]} align="middle" justify="center">
        <Col span={24} style={{ display: 'grid' }}>
          <img
            src={pathWarningIcon}
            alt="icone alerta"
            height={150}
            width={150}
            style={{ margin: '0 auto' }}
          />
          <Title align="center" size={24}>
            Finalizar Atividade
          </Title>
        </Col>
        <Col span={24}>
          <Text align="center" size={15} margin="10px 0 20px 0">
            Ao finalizar uma KPI você considera que essa startup concluiu aquele
            objetivo com êxito e, portanto, deverá seguir com os próximos passos
            já estabelecidos. Dessa forma, após finalizada a atividade,{' '}
            <strong>nenhuma ação ou opção poderá ser mexido novamente</strong>,
            exceto a visualização de tudo que foi posto aqui. <br />
            Deseja finalizar a atividade?
          </Text>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            onClick={finish}
            style={{ width: '100%', backgroundColor: 'var(--thirdy-color)' }}
          >
            Sim, Finalizar atividade
          </Button>
        </Col>
        <Col span={24}>
          <Button style={{ width: '100%' }} type="default" onClick={onCancel}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </ModalBody>
  );
}

export default FinishActivityModal;
