import { Col, Row } from 'antd';
import Chat from '../../../components/Chat';
import Title from '../../../components/Title';
import { useAuth } from '../../../core/hooks/auth';
import ContentBase from '../../templates/ContentBase';

function InvestorChat() {
  const { logged } = useAuth();
  const senderId = logged?.entityId ?? 0;

  return (
    <ContentBase
      questionIsVisible={false}
      content={
        <>
          <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '8px' }}>
            <Col>
              <Title lineHeight={1.3} size={28}>
                Mensagens
              </Title>
            </Col>
          </Row>
          <Chat senderId={senderId} />
        </>
      }
    />
  );
}

export default InvestorChat;
