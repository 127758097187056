import React, { useEffect, useState } from 'react';
import Profiles from '../profiles';
import PersonalForm from './PersonalForm';
import InvestorForm from './InvestorForm';
import { useAuth } from '../../../../core/hooks/auth';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  subStep: number;
  setSubStep: (subStep: number) => void;
  setFormName: (formName: string | undefined) => void;
}

function InvestorFormScreen(props: IParams) {
  const [initialValues, setInitialValues] = useState<any>({});
  const [formData, setFormData] = useState<any>();
  const { logged } = useAuth();
  const subSteps = [
    {
      subTitle: 'Precisamos saber algumas informações a respeito de você',
      description: 'Dados pessoais',
      content: (
        <PersonalForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setButtonState={props.setButtonState}
          setFormName={props.setFormName}
          initialValues={initialValues}
          setFormData={setFormData}
        />
      ),
    },
    {
      subTitle:
        'Agora, precisamos saber algumas informações sobre o seu método como investidor',
      description: 'Dados do investidor',
      content: (
        <InvestorForm
          subStep={props.subStep}
          setSubStep={props.setSubStep}
          setButtonState={props.setButtonState}
          current={props.current}
          setCurrent={props.setCurrent}
          setFormName={props.setFormName}
          initialValues={formData}
        />
      ),
    },
  ];

  useEffect(() => {
    setInitialValues({
      entityId: logged?.entityId,
      name: logged?.name,
      email: logged?.email,
    });
  }, []);

  return (
    <Profiles
      title="Investidor"
      subTitle={subSteps[props.subStep].subTitle}
      description={subSteps[props.subStep].description}
      current={props.current}
      setCurrent={props.setCurrent}
      setButtonState={props.setButtonState}
      setFormName={props.setFormName}
      content={subSteps[props.subStep].content}
    />
  );
}

export default InvestorFormScreen;
