import styled, { css } from 'styled-components';

const Box = styled.p<{
  size?: number;
  weight?: number;
  margin?: string;
  color?: string;
  lineHeight?: number;
  display?: string;
  align?: string;
  maxWidth?: string;
  lineClamp?: number;
  sizeXs?: number;
  wordBreak?: string;
}>`
  display: ${({ display }) => display};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin || 0};
  color: ${({ color }) => color || 'var(--primary-color)'};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  text-align: ${({ align }) => align};
  max-width: ${({ maxWidth }) => maxWidth};
  word-break: ${({ wordBreak }) => wordBreak};

  & [data-bold='true'] {
    font-weight: 700;
    font-family: inherit;
  }

  ${({ lineClamp }) =>
    lineClamp &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${lineClamp};
      -webkit-box-orient: vertical;
    `}

  ${({ sizeXs }) =>
    sizeXs &&
    css`
      @media (max-width: 576px) {
        font-size: ${sizeXs}px;
      }
    `}
`;

export default Box;
