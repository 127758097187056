import { useEffect, useState } from 'react';
import GoBack from '../../../../components/GoBack/GoBack';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import {
  BoxContainer,
  BoxWhite,
  ModalBody,
  PurpleButton,
} from '../../../../GlobalStyles';
import { Header } from '../style';
import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  DatePicker,
  notification,
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useNavigate } from 'react-router-dom';
import PhaseInvestmentService from '../../../../services/PhaseInvestmentService';
import { IPhaseInvestment } from '../../../../core/interfaces/IPhaseInvestment';
import AmountInvestmentService from '../../../../services/AmountInvestmentService';
import { IAmountInvestment } from '../../../../core/interfaces/IAmountInvestment';
import InvestmentRoundService from '../../../../services/InvestmentRoundService';
import IInvestmentRound from '../../../../core/interfaces/IInvestmentRound';
import { useAuth } from '../../../../core/hooks/auth';

function InvestmentForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const [phasesInvestments, setPhasesInvestments] = useState<
    IPhaseInvestment[]
  >([]);
  const [amountInvestments, setAmountInvestments] = useState<
    IAmountInvestment[]
  >([]);
  const phaseInvestmentService = PhaseInvestmentService.init();
  const amountInvestmentService = AmountInvestmentService.init();
  const investmentRoundService = InvestmentRoundService.init();

  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const { logged } = useAuth();
  const id = logged?.entityId;

  const getPhasesInvestments = async () => {
    phaseInvestmentService.listAll().then((response) => {
      setPhasesInvestments(response);
    });
  };

  const getAmountInvestments = async () => {
    amountInvestmentService.listAll().then((response) => {
      setAmountInvestments(response);
    });
  };

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));

    const result: IInvestmentRound = {
      name: valueMap.get('name'),
      thesis: valueMap.get('investmentThesis'),
      phaseInvestmentId: valueMap.get('investmentPhase'),
      amountInvestmentId: valueMap.get('capitalInvestment'),
      startDate: valueMap.get('duration')[0],
      endDate: valueMap.get('duration')[1],
    };

    return result;
  };

  const onFinish = (values: FormInstance) => {
    investmentRoundService
      .save(arrageValues(values), Number(id))
      .then((response) => {
        notification.success({
          message: 'Sucesso',
          description: 'Rodada de investimento cadastrada com sucesso',
        });
        navigate(-1);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro',
          description: 'Erro ao cadastrar rodada de investimento',
        });
      });
  };

  const showModal = () => {
    setVisibleModal(!visibleModal);
  };

  const handleModal = () => {
    const val = form.getFieldsValue();
    Object.values(val).forEach((item: string | unknown) => {
      item !== undefined ? showModal() : navigate('/investment-round/investor');
    });
  };

  useEffect(() => {
    getPhasesInvestments();
    getAmountInvestments();
  }, []);

  return (
    <BoxContainer>
      <ModalBody
        title=""
        width={400}
        open={visibleModal}
        destroyOnClose={true}
        closable={false}
        footer={null}
      >
        <BoxContainer flexDirection="row" alignItems="center" noPadding={true}>
          <GoBack
            style={{ marginRight: '10px' }}
            enableBackground={true}
            goBackFunction={showModal}
          />
          <Title size={24}>Descartar alterações?</Title>
        </BoxContainer>
        <Text align="center" margin="20px 0">
          Voltar agora irá descartar as alterações de criação de uma nova rodada
          de investimento.
        </Text>
        <PurpleButton
          type="primary"
          onClick={handleModal}
          style={{ margin: '0 auto', display: 'block', padding: '0 60px' }}
        >
          Desfazer alterações
        </PurpleButton>
      </ModalBody>
      <Header>
        <GoBack
          style={{ marginRight: '10px' }}
          enableBackground={true}
          goBackFunction={handleModal}
        />
        <Title size={24}>Nova rodada</Title>
      </Header>
      <Text margin="0 0 10px 0">
        Cadastre uma nova rodada de investimento para que startups possam
        visualizar e se candidatar.
      </Text>
      <BoxWhite>
        <Form
          name={'formInvestmentRound'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          <Row gutter={[4, 4]}>
            <Col span={18}>
              <Form.Item
                name={'name'}
                label="Nome da rodada"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Input
                  placeholder="Ex: Rodada de investimento"
                  maxLength={255}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Fase de investimento"
                name="investmentPhase"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Selecione a fase de investimento"
                >
                  {phasesInvestments.map((item) => {
                    return (
                      <Option
                        key={`PHASES_INVEST_${item.entityId}`}
                        value={item.entityId}
                      >
                        {item.description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={'investmentThesis'}
            label="Tese de investimento"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <div>
              <Text margin="0 0 10px 0">
                Descreva sua tese de investimento.
              </Text>
              <TextArea
                required
                showCount
                rows={2}
                placeholder="Descrição da tese"
                maxLength={255}
              />
            </div>
          </Form.Item>
          <Row gutter={[4, 4]}>
            <Col span={6}>
              <Form.Item
                name={'duration'}
                label="Duração"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <RangePicker
                  style={{ width: '100%' }}
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Capital de investimento"
                name="capitalInvestment"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select allowClear placeholder="Selecione o valor">
                  {amountInvestments.map((item) => {
                    return (
                      <Option
                        key={`AMOUNT_INVEST_${item.entityId}`}
                        value={item.entityId}
                      >
                        {item.description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{ margin: '0 auto', display: 'block', padding: '0 60px' }}
          >
            Publicar rodada de investimento
          </PurpleButton>
        </Form>
      </BoxWhite>
    </BoxContainer>
  );
}

export default InvestmentForm;
