import styled from 'styled-components';

interface Contentrops {
  url: string;
}

export const Content = styled.div<Contentrops>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 15px;
  color: var(--primary-color);
  padding-right: 10px;

  .back {
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 5px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .bgLogo {
    position: absolute;
    width: 100%;
    min-height: 1800px;
    background: ${(props) => `url(${props.url})`};
    background-size: contain;
    background-repeat: repeat-y;
    opacity: 0.1;
  }

  & .title {
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0 0 5px;
  }

  & .text {
    text-align: justify;
    text-indent: 1.5em;

    & li {
      text-indent: 0.5em;
    }
  }
`;
