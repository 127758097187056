import React, { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined, DashOutlined } from '@ant-design/icons';
import { notification, Select } from 'antd';
import { Box, BoxContent, PhasesList, Stages } from '../vc/style';
import api from '../../../core/services/api';
import { useAuth } from '../../../core/hooks/auth';
import notificationMessage from '../../../components/notifications/notificationMessage';
import errorNotification from '../../../components/notifications/errorNotification';
import { isEmpty } from '../../../helpers/array.helper';
import FooterPage from '../../../components/FooterPage';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import AddListButton from '../../../components/AddListButton';

interface IPhase {
  entityId: number;
  name: string;
  isSelected: boolean;
  active?: boolean;
  stage?: IStage;
}

interface IStage {
  entityId: number;
  name: string;
  phases?: IPhase[];
}

interface IMethodology {
  entityId: number;
  name: string;
  phases: IPhase[];
}

interface IData {
  entityId: number;
  name: string;
  stage: IStage;
}

interface IParams {
  closeDrawer: () => void;
}
function VcForm({ closeDrawer }: IParams) {
  const [phases, setPhases] = useState<IPhase[]>([]);
  const [stages, setStages] = useState<IStage[]>([]);
  const [methodologyId, setMethodologyId] = useState(0);
  const [methodologies, setMethodologies] = useState<IMethodology[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const { loading, showLoading, hideLoading } = useLoading();

  const { logged } = useAuth();
  const id = logged?.client;

  const getStages = () => {
    api.get('/stages/').then((response: any) => {
      setStages(
        response.data.map((stage: IStage) => {
          return {
            ...stage,
            phases: [],
          };
        }),
      );
    });
  };

  const getMethodology = () => {
    showLoading();
    api
      .get<IMethodology[]>('/methodology/list', {
        params: {
          client: id,
        },
      })
      .then((response) => {
        setMethodologies(response.data);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao listar metodologias',
        });
      })
      .finally(hideLoading);
  };

  const changeMethodology = React.useCallback(
    (entityId: number) => {
      const methodology = methodologies.find(
        (methodology) => methodology.entityId === entityId,
      );

      if (methodology) {
        setMethodologyId(entityId);

        const phases = methodology.phases.map((phase) => ({
          ...phase,
          isSelected: true,
        }));

        phases.forEach((phase) => {
          if (phase.stage) {
            const stage = stages.find(
              (stage) => stage.entityId === phase.stage?.entityId,
            );

            if (stage) {
              stage.phases?.push(phase);
            }

            setPhases(
              methodology?.phases?.map((phase) => ({
                ...phase,
                isSelected: true,
              })),
            );
          } else {
            setStages((oldValue) =>
              oldValue.map((item) => ({ ...item, phases: [] })),
            );

            setPhases(
              methodology?.phases?.map((phase) => ({
                ...phase,
                isSelected: false,
              })),
            );
          }
        });
      }
    },
    [methodologies],
  );

  const onFinish = () => {
    showLoading();
    const data: IData[] = [];
    stages.forEach((stage) => {
      stage.phases?.forEach((phase) => {
        const { entityId, name } = phase;
        data.push({
          name,
          entityId,
          stage: { ...stage, phases: [] },
        });
      });
    });

    api
      .put('/phases/create', data)
      .then((response) => {
        notificationMessage('create');
        closeDrawer();
      })
      .catch((error) => {
        errorNotification(error.messages);
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getMethodology();
  }, []);

  const addPhase = (stageIndex: number) => {
    const stageSelected = stages[stageIndex];
    const phase = phases.find((phase) => !phase.isSelected);

    if (phase) {
      phase.isSelected = true;
      stageSelected.phases?.push(phase);

      setPhases([...phases]);
      setStages([...stages]);
    } else {
      notification.info({
        message: 'Sem fases disponíveis',
      });
    }
  };

  const remove = (stageIndex: number, phaseId: number) => {
    const stageSelected = stages[stageIndex];
    const phase = phases.find((phase) => phase.entityId === phaseId);

    if (stageSelected && phase) {
      stageSelected.phases?.pop();
      phase.isSelected = false;

      setPhases([...phases]);
      setStages([...stages]);
    }
  };

  const actionAddEnabled = (stageIndex: number): boolean => {
    const INDEX_FIRST_STAGE = 0;
    const isFirstStage = stageIndex === INDEX_FIRST_STAGE;

    const nextStage = stages.at(stageIndex + 1);
    const previousStage = stages.at(stageIndex - 1);
    const nextPhases = nextStage?.phases ?? [];
    const previousPhases = previousStage?.phases ?? [];

    return isFirstStage
      ? isEmpty(nextPhases)
      : isEmpty(nextPhases) && !isEmpty(previousPhases);
  };

  useEffect(() => {
    if (phases.length > 0) {
      if (
        stages.every(
          (stage) => stage.phases !== undefined && stage.phases.length > 0,
        ) &&
        phases.every((phase) => phase.isSelected)
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [phases]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem(
        'form-valuation-capital',
        JSON.stringify({
          phases,
          stages,
          methodologyId,
        }),
      );
    };
  }, [phases, stages, methodologyId]);

  useEffect(() => {
    const data = sessionStorage.getItem('form-valuation-capital');
    if (data) {
      const { phases, stages, methodologyId } = JSON.parse(data);

      if (!isEmpty(phases) && !isEmpty(stages)) {
        setPhases(phases);
        setStages(stages);
        setMethodologyId(methodologyId);
      } else {
        getStages();
      }
    } else {
      getStages();
    }
  }, []);

  return (
    <BoxContent>
      <Loading spinning={loading} height={200} width={200}>
        <div className="description">
          Escolha a metodologia que irá definir as fases para compor o seu{' '}
          <b>Venture Caption</b>
        </div>
        <Box>
          <div className="column">
            <Select
              style={{ minWidth: '300px' }}
              placeholder="Selecione a metodologia"
              value={methodologyId || null}
              onChange={(entityId: number) => changeMethodology(entityId)}
            >
              {methodologies?.map((item: IMethodology, index: number) => {
                return (
                  <Select.Option
                    key={`option_methodology_${index}`}
                    value={item?.entityId}
                  >
                    {item?.name}
                  </Select.Option>
                );
              })}
            </Select>
            {stages?.map((stage: IStage, stageIndex: number) => {
              const enabledAction = actionAddEnabled(stageIndex);
              const lastPhaseAdded = stage.phases?.at(-1) ?? null;

              return (
                <Stages key={stageIndex}>
                  <div className="name">
                    {stage.name} - estágio {stageIndex + 1}
                  </div>
                  <div className="boxPhases">
                    {stage.phases?.map((phase: IPhase) => {
                      return (
                        <>
                          <div className="item">
                            {phase.name}
                            {enabledAction &&
                              lastPhaseAdded?.entityId === phase.entityId && (
                                <CloseOutlined
                                  style={{ marginLeft: '10px', color: 'red' }}
                                  onClick={() =>
                                    remove(stageIndex, phase.entityId)
                                  }
                                />
                              )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {Boolean(phases.length) && (
                    <AddListButton
                      width={'35px'}
                      height={'35px'}
                      sizeIcon={'20px'}
                      size={'middle'}
                      type={'dashed'}
                      margin={'5px 0 0 10px'}
                      onClick={() => addPhase(stageIndex)}
                      disabled={!enabledAction}
                    >
                      Adicionar
                    </AddListButton>
                  )}
                </Stages>
              );
            })}
          </div>
          <div className="column">
            {!isEmpty(phases) && (
              <PhasesList>
                {phases.map((item: IPhase, key: number) => {
                  return (
                    <div key={key} className="item">
                      <div className="name">{item.name}</div>
                      <div className="ico">
                        {item.isSelected ? (
                          <CheckOutlined style={{ color: '#00cc66' }} />
                        ) : (
                          <DashOutlined style={{ color: '#bfbfbf' }} />
                        )}
                      </div>
                    </div>
                  );
                })}
              </PhasesList>
            )}
          </div>
        </Box>
        <FooterPage save={onFinish} disabled={isButtonDisabled} />
      </Loading>
    </BoxContent>
  );
}

export default VcForm;
