import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  max-height: calc(100vh - 130px);
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  position: relative;
  border-bottom: 1px solid var(--support-color);

  & img {
    border-radius: 50%;
    object-fit: cover;
  }

  & > div:first-child {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

const ProgressBar = styled.div<{
  loading: boolean;
}>`
  display: ${({ loading }) => (loading ? 'block' : 'none')};
  position: absolute;
  height: 4px;
  background-color: #a209a280;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #a209a2;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
  }

  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
`;

const Content = styled.main<{
  isEmpty: boolean;
}>`
  background-color: #fff;
  flex: 1;
  display: flex;
  gap: 12px;
  overflow-y: auto;
  padding: 8px;
  scroll-behavior: smooth;
  border-bottom: 1px solid var(--support-color);
  align-items: flex-start;
  flex-direction: column;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      align-items: center;
      flex-direction: row;
      justify-content: center;
    `}
`;

const Footer = styled.footer`
  padding: 8px;
  display: flex;
  gap: 16px;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
  flex-direction: column;
`;

const BoxMessage = styled.div<{
  isSender: boolean;
}>`
  word-break: break-all;
  background-color: ${({ isSender }) => (isSender ? '#960096' : '#F1F3F2')};
  padding: 4px 12px;
  color: ${({ isSender }) => (isSender ? '#fff' : '#05233D')};
  border-radius: 6px;
  max-width: 50%;
  margin: ${({ isSender }) => (isSender ? '0 0 0 auto' : '0 auto 0 0')};
`;

export {
  MessageWrapper,
  BoxMessage,
  ProgressBar,
  Wrapper,
  Header,
  Content,
  Footer,
};
