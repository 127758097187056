import styled, { keyframes } from 'styled-components';
import { fadeOutRight, fadeInLeft } from 'react-animations';

import { Switch } from 'antd';

const fadeOutRightAnimation = keyframes`${fadeOutRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 10px 10px;

  & .boxCalc {
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .boxButton {
    display: flex;
  }
`;

const BoxLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoxSlider = styled.div`
  & .ant-slider {
    & .ant-slider-step {
      background-color: #d1d1d1 !important;

      & .ant-slider-dot {
        display: none;
      }
    }

    & .ant-slider-mark {
      & span {
        margin-top: 2px;
        font-size: 0.8em;
        color: var(--font-color);
      }
    }

    & .ant-slider-handle {
      width: 12px;
      height: 12px;
      margin-top: -4px;
      background-color: var(--primary-color);
    }
  }
`;

const BoxDescription = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  width: 30%;

  .description {
    font-size: 18px;
  }
`;

const Question = styled.div`
  width: 70%;
  text-align: justify;
  padding-right: 30px;
`;

const Line = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  animation: 1s ${fadeOutRightAnimation};
`;

const HeaderDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin-bottom: 10px;
  animation: 1s ${fadeOutRightAnimation};
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  animation: 1s ${fadeInLeftAnimation};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Box = styled.div<{
  isVisible: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  animation: 1s
    ${(props) =>
      props.isVisible ? fadeInLeftAnimation : fadeOutRightAnimation};
`;

const Item = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
`;

const LinkText = styled.a<{
  color?: string;
  fontStyle?: string;
  fontSize?: string;
  fontWeight?: number;
  margin?: string;
}>`
  color: ${(props) => props.color || 'var(--thirdy-color)'};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${(props) => props.fontSize || '1em'};
  font-style: ${(props) => props.fontStyle || ''};
  font-weight: ${(props) => props.fontWeight || ''};
  margin: ${(props) => props.margin || '0'};

  &:hover {
    color: ${(props) => props.color || 'var(--thirdy-color)'};
    opacity: 0.8;
  }
`;

// label switch
const LabelSwitch = styled.span`
  font-size: 0.8em;
  color: var(--font-color);
`;

const LabelSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export {
  LabelSwitch,
  LabelSwitchContainer,
  BoxContainer,
  BoxSlider,
  BoxLine,
  BoxDescription,
  Header,
  Line,
  Question,
  LinkText,
  HeaderTitle,
  HeaderDescription,
  BoxText,
  Box,
  Item,
};
