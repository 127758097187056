import React from 'react';
import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useNavigate, useParams } from 'react-router-dom';
function ErrorPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const title = code === '403' ? '403' : '404';
  const subTitle =
    code === '403'
      ? 'Desculpe, você não está autorizado a acessar esta página.'
      : 'Desculpe, a página que você visitou não existe.';

  return (
    <Result
      status={title as ResultStatusType}
      title={title}
      subTitle={subTitle}
      extra={
        <Button onClick={() => navigate('/')} type="primary">
          Voltar à Home
        </Button>
      }
    />
  );
}

export default ErrorPage;
