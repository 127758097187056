import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FormContent, ButtonDelete } from '../form/style';
import { FormInstance } from 'antd/lib/form';
import api from '../../core/services/api';
import notificationMessage from '../notifications/notificationMessage';
import ErrorFormNotification from '../notifications/errorFormNotification';
import { IClient } from '../../core/interfaces';
import IPillars from '../../core/interfaces/models/IPillars';
import IModel from '../../core/interfaces/models/IModel';
import { useDispatch, useSelector } from 'react-redux';
import { _updateValuation } from '../../store/valuation.slice';
import { RootState } from '../../store';
import AlertMessage from '../Alert/AlertMessage';
import { convertCurrencyMaskInputToNumber } from '../../helpers/convertion.helper';
import useLoading from '../../core/hooks/useLoading';
import Loading from '../Loading/Loading';

interface IParams {
  tabName: string;
  pillarId: number;
  weight?: boolean;
  legend?: boolean;
  typeValuation: string;
  setAsk: (ask: boolean) => void;
  ask: boolean;
  clientId: any;
  storageName: string;
  initialValues: any;
  setNext?: (next: string) => void;
  next?: string;
  setFormName: (formName: string) => void;
  formReadOnly?: boolean;
}

interface IScales extends IModel {
  orderScale: number;
  description: string;
}

interface IScoreCard extends IModel {
  entityId: number;
  pillar: IPillars;
  typeValuation: string;
  question: string;
  weight: number;
  legend?: string;
  scales: IScales[];
}

interface IValuationScoreCard {
  entityId?: number | null;
  client: IClient;
  valuationQuestion: IScoreCard[];
  maxValue?: number | null;
}

interface IData extends IModel {
  entityId: number;
  scales: IScales[];
  question: string;
  weight: number;
  legend?: string;
}

function ValuationQuestions(props: IParams) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const storageNameFormatted = `valuation-${props.storageName}`;
  const formList = `${storageNameFormatted}-list`;
  const dispatch = useDispatch();
  let val: number = props.initialValues.entityId;
  const { entityId: ValuationId } = useSelector(
    (state: RootState) => state.valuationSlice,
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { hideLoading, loading, showLoading } = useLoading();

  const getQuestion = async (id: number) => {
    showLoading();
    await api
      .get('/valuation/questions/getByClient/' + id, {
        params: {
          valuation: val,
        },
      })
      .then((response: any) => {
        form.setFieldValue(formList, response.data);
      })
      .finally(hideLoading);
  };

  const arrangeValues = (values: FormInstance) => {
    const valuesMap = new Map(Object.entries(values));
    const data: IData[] = valuesMap.get(formList);
    const result: IValuationScoreCard = {
      entityId: ValuationId,
      maxValue: convertCurrencyMaskInputToNumber(
        sessionStorage.getItem('berkus-form-max-valuation') as string,
      ),
      client: {
        entityId: props.clientId,
      },
      valuationQuestion: data.map((item): IScoreCard => {
        return {
          entityId: item.entityId,
          question: item.question,
          typeValuation: props.typeValuation,
          pillar: { entityId: props.pillarId },
          weight: props.weight ? item.weight : 1,
          legend: props.legend ? item.legend : '',
          scales: [
            {
              ...item.scales[0],
              orderScale: 1,
              description: item.scales[0].description,
            },
            {
              ...item.scales[1],
              orderScale: 2,
              description: item.scales[1].description,
            },
            {
              ...item.scales[2],
              orderScale: 3,
              description: item.scales[2].description,
            },
            {
              ...item.scales[3],
              orderScale: 4,
              description: item.scales[3].description,
            },
            {
              ...item.scales[4],
              orderScale: 5,
              description: item.scales[4].description,
            },
          ],
        };
      }),
    };

    return result;
  };

  const register = async (values: FormInstance) => {
    showLoading();
    await api
      .post('valuation/questions/create', arrangeValues(values))
      .then((response) => {
        val = response.data.entityId;
        dispatch(_updateValuation({ entityId: val }));
        notificationMessage('custom', props.tabName + ' salvo com sucesso!');
        sessionStorage.removeItem(storageNameFormatted);
        setIsModalVisible(true);
      })
      .catch((error: any) => {
        ErrorFormNotification(form, error);
      })
      .finally(hideLoading);
  };

  const options = () => {
    return Array.from(Array(5), (e, i) => {
      return (
        <Select.Option key={i} value={i + 1}>
          {i + 1}
        </Select.Option>
      );
    });
  };

  const onOk = () => {
    setIsModalVisible(false);
    props.setAsk(false);
    props.setNext && props.setNext((Number(props.next) + 1).toString());
  };

  useEffect(() => {
    if (props.initialValues.entityId !== undefined) {
      getQuestion(props.pillarId);
    } else {
      setTimeout(() => {
        const data = sessionStorage.getItem(storageNameFormatted);
        const dataForm = data
          ? JSON.parse(data)
          : Array(1)
              .fill(0)
              .map(() => ({
                question: '',
              }));
        form.setFieldValue(formList, dataForm);
      }, 150);
    }

    return () => {
      sessionStorage.setItem(
        storageNameFormatted,
        JSON.stringify(form.getFieldValue(formList)),
      );
    };
  }, [form, storageNameFormatted, props.initialValues.entityId]);

  useEffect(() => {
    if (props.setFormName) {
      props.setFormName(storageNameFormatted as string);
    }
  }, [storageNameFormatted]);

  return (
    <Loading spinning={loading} width={300} height={300} position="absolute">
      <Form
        disabled={props.formReadOnly}
        name={storageNameFormatted}
        layout="vertical"
        onFinish={register}
        autoComplete="off"
        form={form}
        initialValues={{
          [formList]: [''],
        }}
        onFieldsChange={(_, allFields) => {
          !props.ask &&
            allFields.map((item) => {
              if (item.value !== undefined) {
                props.setAsk(true);
              }
              return null;
            });
        }}
      >
        <Form.List name={formList}>
          {(fields, { add, remove }) => (
            <FormContent
              style={{
                color: '#de1313',
              }}
            >
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  {fields.length > 1 ? (
                    <ButtonDelete>
                      {props.formReadOnly ? null : (
                        <DeleteOutlined
                          className="removeItem"
                          onClick={() => remove(name)}
                        />
                      )}
                    </ButtonDelete>
                  ) : null}
                  <Row gutter={[4, 4]}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        label={'Pergunta'}
                        name={[name, 'question']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Digite a pergunta"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="1º - Escala Likert"
                        name={[name, 'scales', 0, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Ex: Não sofisticado"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="2º - Escala Likert"
                        name={[name, 'scales', 1, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Ex: Tem pontos fracos"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="3º - Escala Likert"
                        name={[name, 'scales', 2, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Ex: Modelo sólido"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="4º - Escala Likert"
                        name={[name, 'scales', 3, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Ex: Modelo promissor"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="5º - Escala Likert"
                        name={[name, 'scales', 4, 'description']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        {props.formReadOnly ? (
                          <Input.TextArea disabled />
                        ) : (
                          <Input
                            placeholder="Ex: Modelo forte"
                            maxLength={255}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {props.weight && (
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          label={'Peso'}
                          name={[name, 'weight']}
                          tooltip="Neste campo você pode considerar os pesos das perguntas de acordo com os pontos mais consideráveis relevantes. Quanto maior a relevância da questão, maior pode ser o peso que você pode definir."
                          rules={[
                            {
                              required: true,
                              message: 'Campo obrigatório!',
                            },
                          ]}
                        >
                          <Select>{options()}</Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  {props.legend && (
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          label={'Legenda'}
                          name={[name, 'legend']}
                        >
                          <TextArea
                            showCount
                            rows={2}
                            placeholder="Digite a legenda"
                            maxLength={255}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  size="small"
                  shape="circle"
                  type="dashed"
                  className="addItem"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                />
              </Form.Item>
            </FormContent>
          )}
        </Form.List>
        <AlertMessage
          message={'Cadastro salvo com sucesso!'}
          description={
            'Parabéns, você completou o cadastro do Valuation! Prossiga para o próximo passo que é a definição dos pesos para cada um dos métodos de avaliação de startups.'
          }
          visible={isModalVisible}
          onOk={onOk}
          textOkButton={'Próximo'}
        />
      </Form>
    </Loading>
  );
}

export default ValuationQuestions;
