import styled from 'styled-components';
import defaultImage from '../../assets/images/image-default.png';
import { WrapperEvidence } from '../client/KpiDetails/EvidencesKpiDetails/styles';

interface IInfo {
  width?: string | 'auto';
  align?: string | 'center';
  color?: string;
}

const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  & .colorButton {
    border: none;
    background-color: var(--thirdy-color) !important;

    :hover {
      background-color: var(--quaternary-color) !important;
    }
  }
`;

const BoxVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #000;
  color: white;
  font-weight: bold;
`;

export const BoxImage = styled.div<{
  srcImage?: string;
}>`
  height: 300px;
  width: 100%;
  background-color: #05233d;
  margin-top: 0.5rem;
  background-image: ${({ srcImage }) => `url(${srcImage || defaultImage})`};
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ srcImage }) => (srcImage ? 'cover' : 'contain')};
`;

const Conteiner = styled.div`
  display: flex;
  flex-direction: column;

  img {
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    object-position: center;
    margin-top: 0.5rem;
    height: 350px;
    width: 100%;
  }
`;

const ContainerTopic = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxTopic = styled.div<{
  noBorder?: boolean;
}>`
  position: relative;
  width: 100%;
  padding: ${({ noBorder }) => (noBorder ? 0 : '10px 10px')};
  margin-bottom: 5px;
  border: ${({ noBorder }) =>
    noBorder ? 'none' : '1px solid var(--support-color)'};
  border-radius: 6px;
  cursor: move;
  background-color: #fff;

  .ant-collapse-header {
    padding: 5px !important;
    cursor: move !important;
  }

  .ant-collapse-header-text {
    width: 100% !important;
  }

  .ant-collapse-extra {
    cursor: pointer;

    svg {
      font-size: 18px;
      margin-top: 6px;
    }
  }

  & .ant-collapse-content-box {
    padding: 0;
  }
`;

const HeaderTopic = styled.div`
  display: grid;
  gap: 8px;
  position: relative;
  align-items: center;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }

  svg {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    #decoration-img {
      display: none;
    }
  }
`;

const Info = styled.div<IInfo>`
  display: flex;
  flex-direction: row;

  .label,
  .value {
    width: ${(props) => (props.width ? props.width : 'calc(100%/3)')};
    text-align: ${(props) => (props.align ? props.align : 'center')};
    margin-right: ${(props) => (props.width ? '10px' : '0')};
    font-size: 0.7rem;

    svg {
      color: ${(props) => (props.color ? props.color : 'var(--thirdy-color)')};
    }
  }

  .label {
    font-weight: bold;
  }
`;

const CourseItemsBox = styled.div`
  display: grid;
  gap: 0.8rem;
`;

const ActionsBox = styled.div<{
  justify?: string;
}>`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
`;

const CourseItem = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(70px, auto);
  gap: 8px;
  padding: 1rem 0.5rem;
  box-shadow: 0 1px 0 #e3e3e3;
  justify-content: space-between;
  align-content: space-between;
  width: calc(100% - 60px);

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Summary = styled.div`
  width: 100%;
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const BoxFile = styled(WrapperEvidence)<{
  margin?: string;
}>`
  width: 100%;
  grid-column: 1 / -1;
  margin: ${({ margin }) => margin || '1rem 0 0 0'};
`;

const PaginationContainer = styled.div`
  display: flex;
  align-self: center;
`;

export {
  BoxFile,
  CourseItemsBox,
  ActionsBox,
  Actions,
  BoxVideo,
  BoxTopic,
  ContainerTopic,
  Conteiner,
  HeaderTopic,
  Info,
  CourseItem,
  Summary,
  PaginationContainer,
};
