const generateSearchParams = (url: string, params: Record<string, string>) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) =>
    searchParams.set(key, value),
  );

  return {
    params: searchParams.toString(),
    url: `${url}?${searchParams.toString()}`,
  };
};

export { generateSearchParams };
