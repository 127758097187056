import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  & .goBack {
    width: 60px;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 0.9em;
    cursor: pointer;

    svg {
      font-size: 12px;
      margin-right: 5px;
    }

    :hover {
      color: var(--thirdy-color);
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & .title,
  .subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
    font-weight: bold;
  }

  & .subTitle {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  & .description {
    font-weight: bold;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export { BoxContent, Content, Header };
