import { useMemo, useCallback } from 'react';
import { Wrapper } from './styles';
import { Col, notification, Row, Tooltip } from 'antd';
import Title from '../../../../components/Title';
import { MdOutlineAddComment } from 'react-icons/md';
import StatusObjectiveKeyResult from '../../../../components/StatusObjectiveKeyResult';
import { IPerformaceIndicatorStatus } from '../../../../core/enums/IKeyPerformaceIndicators';
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { WrapperIconValidation } from '../../StartupDetails/ObjectivesKeyResultsListing/ItemObjectiveKeyResult/styles';
import Text from '../../../../components/Text';
import MetricCrudService from '../../../../services/MetricCrudService';
import GoBack from '../../../../components/GoBack/GoBack';

interface Props {
  title: string;
  kpiId?: number;
  reload(): void;
  addNewComment(): void;
  status?: IPerformaceIndicatorStatus;
}

function HeaderKpiDetails({
  title,
  status,
  reload,
  kpiId,
  addNewComment,
}: Props) {
  const navigate = useNavigate();
  const { startupId } = useParams();
  const id = Number(startupId);

  const metricCrudService = useMemo(() => MetricCrudService.init(), []);

  const updateStatusKpi = useCallback(
    (status: IPerformaceIndicatorStatus) => {
      const action =
        status === IPerformaceIndicatorStatus.Approvaed
          ? 'aprovado'
          : 'desaprovado';

      metricCrudService
        .updateStatusKpi(Number(kpiId), status)
        .then(() => {
          reload();
          notification.success({
            message: `KPI ${action} com sucesso`,
            duration: 3.5,
          });
        })
        .catch(() => {
          notification.error({
            message: `Erro ao ${action} KPI`,
            duration: 3.5,
          });
        });
    },
    [kpiId, metricCrudService, reload],
  );

  return (
    <Wrapper>
      <Row align="middle" justify="space-between" gutter={[16, 16]}>
        <Col>
          <Row gutter={12}>
            <Col>
              <GoBack
                style={{ marginRight: '10px' }}
                enableBackground={true}
                goBackFunction={() =>
                  navigate('/dashboard/startup/', { state: { id } })
                }
              />
            </Col>
            <Col flex={1}>
              <Title size={20}>{title}</Title>
            </Col>
          </Row>
        </Col>

        <Col>
          {status?.includes(IPerformaceIndicatorStatus.Pending) ? (
            <Row align="middle" gutter={12}>
              <Col>
                <Text color="#9AA4A8">Validar esse KPI ?</Text>
              </Col>
              <Col>
                <Tooltip
                  color="#fff"
                  title="Aprovar"
                  placement="bottomRight"
                  overlayClassName="text-tooltip-primary"
                >
                  <WrapperIconValidation
                    color="#36ff2f"
                    onClick={() => {
                      updateStatusKpi(IPerformaceIndicatorStatus.Approvaed);
                    }}
                  >
                    <AiOutlineLike size={22} />
                  </WrapperIconValidation>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  color="#fff"
                  title="Desaprovar"
                  placement="bottomRight"
                  overlayClassName="text-tooltip-primary"
                >
                  <WrapperIconValidation
                    color="#ff2e2e"
                    onClick={() =>
                      updateStatusKpi(IPerformaceIndicatorStatus.Disapproved)
                    }
                  >
                    <AiOutlineDislike size={22} />
                  </WrapperIconValidation>
                </Tooltip>
              </Col>
            </Row>
          ) : (
            <Row align="middle" gutter={24}>
              <Col>
                <StatusObjectiveKeyResult placement="leftTop" status={status} />
              </Col>
              <Col>
                <Tooltip
                  color="#fff"
                  placement="bottomLeft"
                  title="Novo comentário"
                  overlayClassName="text-tooltip-primary"
                >
                  <MdOutlineAddComment
                    size={22}
                    cursor="pointer"
                    onClick={addNewComment}
                  />
                </Tooltip>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}

export default HeaderKpiDetails;
