import { Col, Form, Row, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../core/hooks/auth';
import { IBusinessModel } from '../../../../core/interfaces/IBusinessModel';
import { IBusinessType } from '../../../../core/interfaces/IBusinessTypel';
import { IInvestor } from '../../../../core/interfaces/IInvestor';
import { IPeriodInvestment } from '../../../../core/interfaces/IPeriodInvestment';
import { IPhaseInvestment } from '../../../../core/interfaces/IPhaseInvestment';
import { IValuesInvestment } from '../../../../core/interfaces/IValuesInvestment';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { ModalBody, PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import BusinessModelService from '../../../../services/BusinessModelService';
import BusinessTypeService from '../../../../services/BusinessTypeService';
import InvestorService from '../../../../services/InvestorService';
import PeriodInvestmentService from '../../../../services/PeriodInvestmentService';
import PhaseInvestmentService from '../../../../services/PhaseInvestmentService';
import ValuesInvestmentService from '../../../../services/ValuesInvestmentService';

const { Option } = Select;

const required = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

interface Props extends IPropsModal {
  investor: IInvestor | null;
  onReload(): void;
}

function BusinessInformationForm({
  visible,
  onCancel,
  investor,
  onReload,
}: Props) {
  const { logged } = useAuth();

  const { entityId = 0 } = logged ?? {};

  const [businessModels, setBusinessModels] = useState<IBusinessModel[]>([]);
  const [phasesInvestment, setPhasesInvestment] = useState<IPhaseInvestment[]>(
    [],
  );
  const [businessTypes, setBusinessTypes] = useState<IBusinessType[]>([]);
  const [periodInvestments, setPeriodInvestments] = useState<
    IPeriodInvestment[]
  >([]);
  const [valuesInvestment, setValuesInvestment] = useState<IValuesInvestment[]>(
    [],
  );

  const investorService = useMemo(() => InvestorService.init(), []);
  const businessModelsService = useMemo(() => BusinessModelService.init(), []);
  const phaseInvestmentService = useMemo(
    () => PhaseInvestmentService.init(),
    [],
  );
  const businessTypeService = useMemo(() => BusinessTypeService.init(), []);
  const periodInvestmentService = useMemo(
    () => PeriodInvestmentService.init(),
    [],
  );
  const valuesInvestmentService = useMemo(
    () => ValuesInvestmentService.init(),
    [],
  );

  const initialValues = useMemo(
    () => ({
      investmentPhase: investor?.phaseInvestiment?.map((item) => item.entityId),
      investmentArea: investor?.businessTypes?.map((item) => item.entityId),
      businessModel: investor?.businessModels?.entityId,
      timeFoundation: investor?.periodInvestiment?.entityId,
      investimentValues: investor?.investimentValues?.entityId,
    }),
    [investor],
  );

  const getBusinessModels = () => {
    businessModelsService.listAll().then((response) => {
      setBusinessModels(response);
    });
  };

  const getPhases = () => {
    phaseInvestmentService.listAll().then((response) => {
      setPhasesInvestment(response);
    });
  };

  const getBusinessTypes = () => {
    businessTypeService.listAll().then((response) => {
      setBusinessTypes(response);
    });
  };

  const getInvestmentPeriod = () => {
    periodInvestmentService.listAll().then((response) => {
      setPeriodInvestments(response);
    });
  };

  const getValues = () => {
    valuesInvestmentService.listAll().then((response) => {
      setValuesInvestment(response);
    });
  };

  const onSubmitForm = async (values: Record<string, number | number[]>) => {
    try {
      await investorService.updateBusinessInformation(entityId, {
        businessModelsId: values.businessModel,
        investimentValuesId: values.investimentValues,
        periodInvestimentId: values.timeFoundation,
        phaseInvestmentIds: Array.isArray(values.investmentPhase)
          ? values.investmentPhase.map((id) => id)
          : [],
        businessTypesIds: Array.isArray(values.investmentArea)
          ? values.investmentArea.map((id) => id)
          : [],
      });

      notification.success('Informações atualizadas com sucesso');
      onCancel();
      onReload();
    } catch (exception) {
      notification.error('Erro ao atualizar informações');
    }
  };

  useEffect(() => {
    getBusinessModels();
    getPhases();
    getBusinessTypes();
    getInvestmentPeriod();
    getValues();
  }, []);

  return (
    <ModalBody
      title="Editar informações de négocio"
      width={'100%'}
      margin="0 auto"
      maxWidth="800px"
      open={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        name={'formInvestor'}
        onFinish={onSubmitForm}
        layout={'vertical'}
        autoComplete={'off'}
        labelWrap
        initialValues={initialValues}
      >
        <Row gutter={[8, 4]} style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Quais fases você investe?"
              name="investmentPhase"
              rules={required}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Selecione as áreas de interesse"
              >
                {phasesInvestment.map((item) => {
                  return (
                    <Option
                      key={`phases_${item.entityId}`}
                      value={item.entityId}
                    >
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Em quais áreas de investimento?"
              name={'investmentArea'}
              rules={required}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Selecione as áreas de interesse"
              >
                {businessTypes.map((item) => {
                  return (
                    <Option
                      key={`TYPES_${item.entityId}`}
                      value={item.entityId}
                    >
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]} align={'bottom'}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Modelo de negócio"
              name="businessModel"
              rules={required}
            >
              <Select allowClear placeholder="Selecione as áreas de interesse">
                {businessModels?.map((item) => {
                  return (
                    <Option
                      key={`BUSINESS_${item.entityId}`}
                      value={item.entityId}
                    >
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Tempo de fundação da startup que vc investe?"
              name="timeFoundation"
              rules={required}
            >
              <Select placeholder="Selecione o tempo">
                {periodInvestments.map((item) => {
                  return (
                    <Option
                      key={`PERIOD_${item.entityId}`}
                      value={item.entityId}
                    >
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Quantas empresas você tem no portfolio?"
              name="investimentValues"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Select placeholder="Selecione o tempo">
                {valuesInvestment.map((item) => {
                  return (
                    <Option
                      key={`VALUES_${item.entityId}`}
                      value={item.entityId}
                    >
                      {item.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <PurpleButton htmlType="submit">Salvar</PurpleButton>
          </Col>
        </Row>
      </Form>
    </ModalBody>
  );
}

export default BusinessInformationForm;
