import styled from 'styled-components';

const Box = styled.div`
  display: grid;
  height: 100%;
  justify-content: space-between;
  border: 1px solid var(--support-color);
  border-radius: 5px;
  overflow: hidden;
  grid-template-rows: minmax(400px, auto) auto;

  & div[data-chart] {
    position: absolute;
  }

  & div[data-chart='print'] {
    width: 79%;
    left: 4%;
    opacity: 0;
  }

  & div[data-chart='view'] {
    width: 85%;
    left: 7%;
  }

  @media print {
    & div[data-chart='print'] {
      opacity: 1;
    }

    & div[data-chart='view'] {
      opacity: 0;
    }
  }
`;

const Description = styled.p`
  margin: 0;
  font-weight: 400;
  padding: 11px;
  line-height: 1.4;
  color: var(--primary-color);
  font-family: 'Segoe UI', sans-serif;
  font-size: 13px;
`;

const Divider = styled.span`
  display: inline-block;
  width: 100%;
  background: var(--support-color);
  height: 1px;
`;

export { Box, Divider, Description };
