import styled from 'styled-components';
import Text from '../../../../../components/Text';

const WrapperKpi = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -6px;
    right: 0;
    z-index: -1;
  }

  &:hover::before {
    background-color: #f0f2f5;
  }
`;

const WrapperIconValidation = styled.div<{
  color: string;
}>`
  cursor: pointer;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: ${({ color }) => color};

    & svg {
      color: ${({ color }) => color};
    }
  }
`;

const ActionsObjectiveKeyResult = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 700px) {
    justify-content: flex-start;
  }
`;

const TabText = styled(Text)<{
  isActive?: boolean;
}>`
  color: ${({ isActive }) => (isActive ? 'var(--primary-color)' : '#9aa4a8')};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--primary-color);
  }
`;

export {
  ActionsObjectiveKeyResult,
  WrapperKpi,
  TabText,
  WrapperIconValidation,
};
