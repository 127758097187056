import { Empty, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Divider from '../../../../components/Divider';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import useLoading from '../../../../core/hooks/useLoading';
import IOngoingCourses from '../../../../core/interfaces/course/IOngoingCourses';
import { notification } from '../../../../helpers/notification.helper';
import CourseService from '../../../../services/course/CourseService';
import CardInProgress from '../CardInProgress';
import { WrapperCards } from './styles';
import { useNavigate } from 'react-router-dom';

function CourseProgression() {
  const navigate = useNavigate();
  const { loading, hideLoading, showLoading } = useLoading();
  const courseService = useMemo(() => CourseService.init(), []);

  const [courses, setCourses] = useState<IOngoingCourses[]>([]);

  const getOngoingCourses = useCallback(() => {
    showLoading();
    courseService
      .getOngoingCourses()
      .then((data) => setCourses(data))
      .catch(() => notification.error('Erro ao listar cursos recomendados'))
      .finally(hideLoading);
  }, [courseService, hideLoading, showLoading]);

  useEffect(() => {
    getOngoingCourses();
  }, [getOngoingCourses]);

  return (
    <>
      <Title size={20} weight={700} color="#05233D">
        Em andamento
      </Title>
      <Divider />
      <Spin spinning={loading}>
        {isEmpty(courses) && (
          <Empty
            description={
              <Text size={20} weight={600}>
                Não há cursos em andamento
              </Text>
            }
          />
        )}
        <WrapperCards>
          {courses.map((course, index) => (
            <CardInProgress
              title={course.name}
              progress={course.progress}
              pictureName={course.pictureName}
              key={`card_course_${index}`}
              description={course.description}
              click={() =>
                navigate(`/courses-startup/attend-course/${course.id}`)
              }
            />
          ))}
        </WrapperCards>
      </Spin>
    </>
  );
}

export default CourseProgression;
