import { isNull } from 'lodash';
import { FiUpload } from 'react-icons/fi';
import { ButtonUpload } from './styles';
import FieldWrapper from '../FieldWrapper';
import { GrDocumentPdf } from 'react-icons/gr';
import Text from '../../../../components/Text';
import { BoxFile } from '../../../courses/style';
import { Button, Col, Row, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { FC, useState, useMemo, useRef, useId } from 'react';
import IFieldComponentParams from '../../../../core/interfaces/attend-course/IFieldComponentParams';
import { convertBase64 } from '../../../../helpers/convertion.helper';
import { notification } from '../../../../helpers/notification.helper';
import FileService from '../../../../services/FileService';
import useLoading from '../../../../core/hooks/useLoading';

function AttachmentField({ data, changeValue }: IFieldComponentParams) {
  const { quiz, fileName } = data;
  const { entityId, question } = quiz;

  const fileId = useId();
  const { loading, showLoading, hideLoading } = useLoading();
  const fileService = useMemo(() => FileService.init(), []);

  const inputFile = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);

  const name = `attachment-${entityId}`;

  const removeFile = () => {
    setFile(null);
    changeValue(name, {
      file: '',
    });
  };

  const addFile = () => inputFile.current?.click();

  const changeFile = (files: FileList | null) => {
    const firstFile = files?.item(0);
    if (firstFile) {
      setFile(firstFile);
      convertBase64(firstFile)
        .then((data) =>
          changeValue(name, {
            file: data as string,
          }),
        )
        .catch(() => notification.info('Erro ao adicionar arquivo'));
    }
  };

  const preview = () => {
    if (file) {
      fileService.openTemporary(file);
    }

    if (fileName) {
      showLoading();
      fileService.openByName(fileName, 'arquivo').finally(hideLoading);
    }
  };

  return (
    <FieldWrapper
      question={question}
      comment={data.comment}
      status={data.status}
      loading={loading}
    >
      {isNull(file) && isNull(fileName) ? (
        <>
          <input
            className="d-none"
            accept="application/pdf"
            ref={inputFile}
            type="file"
            id={fileId}
            onChange={({ target }) => changeFile(target.files)}
          />

          <ButtonUpload aria-controls={fileId} onClick={addFile}>
            <FiUpload size={17} />
            <Text size={16} weight={700}>
              Adicionar arquivo
            </Text>
          </ButtonUpload>
        </>
      ) : (
        <Row justify={'space-between'} gutter={16} align={'middle'}>
          <Col flex={1}>
            <BoxFile margin="0" onClick={preview}>
              <GrDocumentPdf size={22} />
              {fileName ? 'Arquivo' : file?.name}
            </BoxFile>
          </Col>
          {isNull(fileName) && (
            <Col>
              <Tooltip title="Excluir" overlayClassName="text-tooltip-primary">
                <Button
                  type="default"
                  onClick={removeFile}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      )}
    </FieldWrapper>
  );
}

export default AttachmentField;
