import { Column } from '@ant-design/charts';
import React from 'react';
import { maskMoney } from '../../../../../helpers/mask.helper';
import { ChartWrapper } from '../../../style';
import { Card } from '../../styles';
import { IValueDrive } from '../../../../../core/interfaces/IValueDrive';

interface IParams {
  vcs: IValueDrive[];
}
function VentureCapitalSectionChart(props: IParams) {
  const data = [
    {
      name: '2019',
      value: 882000.0,
    },
    {
      name: '2020',
      value: 106200.0,
    },
    {
      name: '2021',
      value: 1471800.0,
    },
  ];

  const config = {
    data: props.vcs,
    appendPadding: 10,
    xField: 'name',
    yField: 'value',
    seriesField: '',
    color: '#960096',
    tooltip: true,
    meta: {
      value: {
        formatter: (v: number) => maskMoney(v),
      },
    },
    label: {
      // formatter: function (params: any) {
      //   return maskMoney(params.value);
      // },
      style: {
        fill: '#FFFFFF',
        opacity: 1,
        fontSize: 16,
        fontFamily: 'Segoe UI',
        fontWeight: 700,
      },
    },
    xAxis: {
      label: {
        autoEllipsis: true,
        style: {
          fill: '#05233D',
          fontWeight: 500,
          fontSize: 16,
          fontFamily: 'Segoe UI',
        },
      },
    },
  };

  const component = (
    <Column {...config} tooltip={{ customContent: () => '' }} />
  );
  return (
    <Card>
      <ChartWrapper chart="view">{component}</ChartWrapper>
      <ChartWrapper chart="print" width="59%" widthMd="80%">
        {component}
      </ChartWrapper>
    </Card>
  );
}

export default VentureCapitalSectionChart;
