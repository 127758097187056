import styled from 'styled-components';

const BodyList = styled.div`
  float: left;
  overflow: hidden;

  & .ant-btn {
    margin-right: 5px;
  }

  & .ant-input {
    width: 300px !important;
    height: 38px !important;
    position: absolute;
    margin: -1px 0 0 -1px !important;
    border-radius: 25px !important;
    padding-left: 45px;
    z-index: 1;

    :focus {
      box-shadow: none;
    }
  }

  & .ant-pagination .ant-pagination-item-active {
    border-color: var(--thirdy-color) !important;

    a {
      color: var(--thirdy-color) !important;
    }
  }

  & .colorButton {
    background-color: var(--thirdy-color) !important;

    :hover {
      background-color: var(--quaternary-color) !important;
    }
  }

  & .noColorButton {
    background-color: #fff !important;

    & span svg {
      color: var(--primary-color) !important;
    }

    :hover {
      background-color: var(--primary-color) !important;

      & span svg {
        color: var(--white-color) !important;
      }
    }
  }

  & .circledButton {
    border-radius: 50% !important;
  }

  & .hideAnimation {
    opacity: 0 !important;
  }

  & .showAnimation {
    opacity: 1 !important;
    transition: opacity 1s ease-out !important;
  }
`;

const BoxButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & .colorButton {
    background-color: var(--thirdy-color) !important;
    border-color: var(--thirdy-color) !important;
  }
`;

const TableList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 5px 5px;

  & .ant-table-thead tr > .ant-table-cell {
    color: var(--font-color);
    text-align: center;
    font-weight: bold;
    border-right: 1px solid #fff;
    padding: 5px 5px;
  }

  & .ant-table-tbody tr > .ant-table-cell {
    text-align: center;
    text-transform: capitalize;
  }

  & .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }

  & .ant-table {
    height: 100%;
    min-height: 100%;
  }

  & .ant-pagination {
    & .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border-color: var(--secondary-color);

      & a {
        color: var(--secondary-color);
      }
    }

    & :not(.ant-pagination-disabled) .ant-pagination-item-link:hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

export { BodyList, BoxButton, TableList };
