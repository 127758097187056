import styled from 'styled-components';

const ContentWrapper = styled.div`
  gap: 8px;
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;

  & > div:first-child {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (max-width: 400px) {
    & img {
      width: 50px;
      height: 50px;
    }
  }
`;

export { ContentWrapper };
