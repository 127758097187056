import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #05233d66;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 6px;
  padding: 6px 12px;
  min-width: 100px;
  min-height: 42px;

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #a209a2 !important;
    background-color: #a209a2 !important;
  }

  & .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #a209a2 !important;
  }
`;

export { Wrapper };
