import { Empty, Input } from 'antd';
import moment from 'moment';
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { FiSend } from 'react-icons/fi';
import { Client } from 'webstomp-client';
import {
  IMessage,
  IParticipant,
  ISendMessageRequest,
} from '../../../core/interfaces/chat/IChat';
import {
  IRemoveParticipant,
  IUpdateParticipant,
} from '../../../core/interfaces/chat/IChatHook';
import { notification } from '../../../helpers/notification.helper';
import ChatService from '../../../services/chat/ChatService';
import GetChatService from '../../../services/chat/GetChatService';
import Text from '../../Text';
import Title from '../../Title';
import ChatActions from '../ChatActions';
import {
  BoxMessage,
  Content,
  Footer,
  Header,
  MessageWrapper,
  ProgressBar,
  Wrapper,
} from './styles';
import { debounce } from 'lodash';
import IPageable from '../../../core/interfaces/IPageable';
import CrudService from '../../../services/CrudService';
import usePagination from '../../../core/hooks/usePagination';
import useLoading from '../../../core/hooks/useLoading';

type Props = {
  senderId: number;
  stomp?: Client | null;
  participant: IParticipant;
  removeParticipant: IRemoveParticipant;
  updateParticipant: IUpdateParticipant;
};

function SelectedConversation({
  stomp,
  senderId,
  participant,
  removeParticipant,
  updateParticipant,
}: Props) {
  const { loading, showLoading, hideLoading } = useLoading();
  const { currentPage, changePagination } = usePagination();
  const refContent = useRef<HTMLElement>(null);

  const [text, setText] = useState<string>('');
  const [messages, setMessages] = useState<IPageable<IMessage>>(
    CrudService.pageableDefault,
  );

  const chatService = useMemo(() => ChatService.init(), []);
  const getChatService = useMemo(() => GetChatService.init(), []);

  const scrollContentBottom = (): void => {
    setTimeout(() => {
      const element = refContent.current;
      if (element) element.scrollTo(0, element.scrollHeight);
    }, 100);
  };

  const deleteConversation = () => {
    const { conversationId } = participant;
    return chatService
      .deleteConversation(conversationId, senderId)
      .then(() => {
        removeParticipant(conversationId);
        notification.success('Conversa removida com sucesso');
      })
      .catch(() => {
        notification.info('Erro ao remover conversa');
      });
  };

  const sendMessage = () => {
    if (text.trim()) {
      const data: ISendMessageRequest = {
        senderId,
        conversationId: participant.conversationId,
        message: text.trim(),
        recipientId: participant.userId,
      };
      stomp?.send('/app/send-message', JSON.stringify(data));
      updateParticipant({
        ...participant,
        lastMessage: text,
      });
      setMessages({
        ...messages,
        content: messages.content.concat({
          authorId: senderId,
          message: text,
          id: messages.content.length + 1,
          creationDate: moment().format('DD/MM/YY HH:mm'),
        }),
      });
      setText('');
      scrollContentBottom();
    } else {
      notification.info('Preencha a mensagem');
    }
  };

  const onScrollContent = debounce(() => {
    const element = refContent.current;
    if (element) {
      const page = currentPage + 1;
      if (element.scrollTop < 50 && page < messages.totalPages) {
        changePagination(page + 1);
        getMessages(false, page, true);
      }
    }
  }, 350);

  const scrollLastMessage = () => {
    const lastMessage = document.querySelector('.item-message');
    setTimeout(() => lastMessage?.scrollIntoView());
  };

  const getMessages = useCallback(
    (scroll = true, page = 0, scrollAnchor = false) => {
      showLoading();
      return getChatService
        .getMessages({
          senderId,
          size: 15,
          page,
          conversationId: participant.conversationId,
        })
        .then((data) => {
          setMessages((oldValues) => {
            const newItems = [...data.content].reverse();
            oldValues.content.unshift(...newItems);
            return {
              ...data,
              content: oldValues.content,
            };
          });

          if (scrollAnchor) scrollLastMessage();
        })
        .catch(() => {
          notification.error('Erro ao carregar mensagens');
        })
        .finally(() => {
          hideLoading();
          if (scroll) scrollContentBottom();
        });
    },
    [
      participant.conversationId,
      showLoading,
      hideLoading,
      senderId,
      getChatService,
    ],
  );

  useEffect(() => {
    if (stomp && senderId) {
      stomp.subscribe(`/topic/get-message/${senderId}`, function ({ body }) {
        const payload: IMessage = JSON.parse(body);
        setMessages((oldValues) => ({
          ...oldValues,
          content: oldValues.content.concat(payload),
        }));
        scrollContentBottom();
      });
    }
  }, [stomp, senderId]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  useEffect(() => {
    setMessages(CrudService.pageableDefault());
  }, [participant]);

  const isEmpty = messages.content.length === 0;

  return (
    <Wrapper>
      <Header>
        <div>
          <img
            height={37}
            width={37}
            src={`https://www.gravatar.com/avatar/${participant.name}/?s=200&r=pg&d=retro`}
            alt={participant.name}
          />
          <Title tag="h2" size={14} weight={600}>
            {participant.name}
          </Title>
        </div>
        <ChatActions deleteConversation={deleteConversation} />
        <ProgressBar loading={loading} />
      </Header>
      <Content onScroll={onScrollContent} ref={refContent} isEmpty={isEmpty}>
        {isEmpty ? (
          <Empty
            description={
              <Text weight={600}>
                Inicie uma conversa com {participant.name}
              </Text>
            }
          />
        ) : (
          messages.content.map((message) => {
            const isSender = message.authorId === senderId;
            return (
              <MessageWrapper
                className="item-message"
                key={`message_key_${message.id}`}
              >
                <BoxMessage isSender={isSender}>{message.message}</BoxMessage>
                <Text
                  color="#9AA4A8"
                  size={10}
                  margin={isSender ? '0 0 0 auto' : '0 auto 0 0'}
                >
                  {message.creationDate}
                </Text>
              </MessageWrapper>
            );
          })
        )}
      </Content>
      <Footer>
        <Input.TextArea
          autoFocus
          autoSize
          value={text}
          onChange={({ target: { value } }) => setText(value)}
          onPressEnter={(event) => {
            event.preventDefault();
            sendMessage();
          }}
          placeholder="Sua mensagem"
        />
        <FiSend cursor="pointer" size={18} onClick={sendMessage} />
      </Footer>
    </Wrapper>
  );
}

export default SelectedConversation;
