import React from 'react';
import { Tag } from 'antd';
import { BoxName } from '../../GlobalStyles';

interface Params {
  status: boolean;
}

function BoxStatus(props: Params) {
  const getFeature = (status: boolean) => {
    const color = status ? 'green' : 'red';
    const text = status ? 'ativo' : 'inativo';

    return {
      color: color,
      text: text,
    };
  };

  return (
    <>
      <Tag color={getFeature(props.status).color}>
        <BoxName>{getFeature(props.status).text}</BoxName>
      </Tag>
    </>
  );
}

export default BoxStatus;
