import { DownloadOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Spin } from 'antd';
import { isNull } from 'lodash';
import moment from 'moment';
import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import useLoading from '../../core/hooks/useLoading';
import useStartupProgress from '../../core/hooks/useStartupProgress';
import { IStartup } from '../../core/interfaces';
import IRecommendedTrail from '../../core/interfaces/dashboard-startup/IRecommendedTrail';
import { ButtonCloseDrawer, PurpleButton } from '../../GlobalStyles';
import { notification } from '../../helpers/notification.helper';
import StartupProgress from '../../pages/client/StartupDetails/StartupProgress/StartupProgress';
import DashboardStartupService from '../../services/dashboard-startup/DashboardStartupService';
import StartupService from '../../services/StartupService';
import Text from '../Text';
import Title from '../Title';
import PhaseCoursesSection from './PhaseCoursesSection';

function StartupJourneyReport() {
  const navigate = useNavigate();
  const { loading, showLoading, hideLoading } = useLoading();

  const { userId: id } = useParams();
  const userId = Number(id);

  const { progress } = useStartupProgress(userId);

  const [startup, setStartup] = useState<IStartup | null>(null);
  const [dataRecommended, setDataRecommended] = useState<IRecommendedTrail[]>(
    [],
  );

  const dashboardService = useMemo(() => DashboardStartupService.init(), []);
  const startupService = useMemo(() => StartupService.init(), []);

  const getData = useCallback(() => {
    showLoading();
    Promise.all([
      startupService.getByUser(userId),
      dashboardService.getStartupTrailReport(userId),
    ])
      .then(([startup, trailReport]) => {
        setStartup(startup);
        setDataRecommended(trailReport);
      })
      .catch(() => notification.error('Erro ao obter dados do relatório'))
      .finally(hideLoading);
  }, [dashboardService, startupService, showLoading, hideLoading, userId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'progresso-trilha-atual',
    pageStyle:
      '@page { size: A4; margin: 0 10px; ' +
      '@bottom-right { content: counter(page); } ' +
      '@media print { body { -webkit-print-color-adjust: exact; } }' +
      'body { font-family: "Roboto", sans-serif; min-height: 1200px; min-width: 1000px }' +
      '}',
  });

  return (
    <Spin spinning={loading}>
      <Drawer
        className="reset-padding-body-mobile"
        width={'100%'}
        closable={false}
        footer={null}
        open={true}
      >
        <div ref={componentRef}>
          <Row
            align={'top'}
            justify={'space-between'}
            wrap={false}
            style={{ marginBottom: '1rem' }}
          >
            <Col>
              <Title sizeXs={22} size={28}>
                Progresso da Trilha Atual
              </Title>
            </Col>
            <Col className="hide-print">
              <ButtonCloseDrawer onClick={() => navigate(-1)} />
            </Col>
          </Row>
          <Row align={'middle'} justify={'space-between'} gutter={[8, 8]}>
            <Col>
              <Row>
                <Col span={24}>
                  <Title sizeXs={20} tag="h2" color="#960096" size={26}>
                    Startup: {startup?.name ?? 'não identificado'}
                  </Title>
                </Col>
                <Col span={24}>
                  <Text>
                    <span data-bold="true">Relatório gerado</span>:{' '}
                    {moment().format('DD/MM/YY')}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col className="hide-in-mobile hide-print">
              <PurpleButton type="primary" onClick={handlePrint}>
                <DownloadOutlined /> Baixar PDF
              </PurpleButton>
            </Col>
          </Row>

          <Text margin="1rem 0  0 0">
            Pelo diagnóstico da startup, a trilha completa de sua aceleração
            corresponde a:
          </Text>

          {dataRecommended.map((item, index) => (
            <Text color="#960096" key={`text_recommend_${index}`}>
              <span data-bold="true">{item.phase.name}: </span>
              {item.courses.map((course) => course.name).join('; ')}
            </Text>
          ))}

          <Text margin="0.5rem 0 1.5rem 0">
            Será segmentado, por ordem de visualização, primeiramente a fase
            atual e, na sequência, as fases por ordem cronológica.
          </Text>

          <StartupProgress
            marginBox="0"
            boxShadow="0px 0px 10px #05233d4d"
            bordered={false}
            title="Trilha de Aceleração"
            showSelected={false}
            phases={progress.phases}
          />

          <div className="wrapper-sections" style={{ margin: '2rem 0' }}>
            {dataRecommended.map((item, index) => {
              let amount = 0;
              let realized = 0;

              item.courses.forEach(({ lessons, exercises }) => {
                amount += lessons.length + exercises.length;
                realized += lessons.filter(
                  (lesson) => lesson.isComplete,
                ).length;
                realized += exercises.filter(
                  (exercise) => !isNull(exercise.grade),
                ).length;
              });

              const average = (realized / amount) * 100;

              return (
                <PhaseCoursesSection
                  average={average}
                  courses={item.courses}
                  title={item.phase.name}
                  key={`phase_section_${index}`}
                />
              );
            })}
          </div>
        </div>
      </Drawer>
    </Spin>
  );
}
export default StartupJourneyReport;
