import { useCallback, useMemo, useState } from 'react';
import { notification } from '../../helpers/notification.helper';
import GetChatService from '../../services/chat/GetChatService';
import { IParticipant } from '../interfaces/chat/IChat';
import IChatHook from '../interfaces/chat/IChatHook';

const INDICE_NOT_FOUND = -1;

const useChat = (): IChatHook => {
  const [participant, setParticipant] = useState<IParticipant | null>(null);
  const [participants, setParticipants] = useState<IParticipant[]>([]);

  const getChatService = useMemo(() => GetChatService.init(), []);

  const getParticipants = useCallback(
    (senderId: number, params = {}) => {
      getChatService
        .getParticipants({ senderId, ...params })
        .then((data) => setParticipants(data))
        .catch(() => notification.info('Erro ao listar participantes'));
    },
    [getChatService],
  );

  const searchIndexParticipant = (participants: IParticipant[], id: number) => {
    return participants.findIndex(
      ({ conversationId }) => conversationId === id,
    );
  };

  const removeParticipant = useCallback((conversationId: number) => {
    setParticipants((participants) => {
      const searchIndex = searchIndexParticipant(participants, conversationId);
      if (searchIndex !== INDICE_NOT_FOUND) {
        participants.splice(searchIndex, 1);
        changeParticipant(null);
      }

      return [...participants];
    });
  }, []);

  const updateParticipant = useCallback((participant: IParticipant): void => {
    setParticipants((participants) => {
      const searchIndex = searchIndexParticipant(
        participants,
        participant.conversationId,
      );

      if (searchIndex === INDICE_NOT_FOUND) {
        participants.unshift({
          ...participant,
        });
      } else {
        const search = participants.splice(searchIndex, 1)[0];
        participants.unshift({
          ...search,
          lastMessage: participant.lastMessage,
        });
      }

      return [...participants];
    });
  }, []);

  const changeParticipant = (participant: IParticipant | null) => {
    setParticipant(participant);
  };

  return {
    participant,
    participants,
    getParticipants,
    removeParticipant,
    updateParticipant,
    changeParticipant,
  };
};

export default useChat;
