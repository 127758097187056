import CrudService from '../CrudService';

class CourseCategoryService extends CrudService {
  constructor() {
    super({
      requestMapping: '/course-category',
    });
  }

  public static init() {
    return new CourseCategoryService();
  }
}

export default CourseCategoryService;
