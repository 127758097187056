import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  border-radius: 4px;
  background-color: #fff;
  height: calc(100vh - 130px);
  grid-template-columns: 320px 1fr;
  box-shadow: 0 0 0 1px var(--support-color);
`;

export { Wrapper };
