import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function SpeederMemberInfo() {
  return (
    <BoxInfo
      title="Membros"
      image={image}
      description={
        <p>
          Neste espaço você poderá adicionar os membros da sua aceleradora para
          lhe ajudar no desenvolvimento do negócio. Também poderá adicionar
          competências e permissões para este usuário.
        </p>
      }
    />
  );
}

export default SpeederMemberInfo;
