import React from 'react';
import { useAuth } from '../hooks/auth';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

function InnovationPagesRoutes() {
  const { isLogged, logged } = useAuth();
  return isLogged ? <PrivateRoutes user={logged} /> : <PublicRoutes />;
}

export default InnovationPagesRoutes;
