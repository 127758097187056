import styled from 'styled-components';

export const WrapperButtonAcess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    height: auto;
    padding: 6px 12px;
    white-space: normal;
  }
`;
