import { ConfigProvider } from 'antd';
import './App.css';

import InnovationPagesRoutes from './core/routes/';
import { AuthProvider } from './core/hooks/auth';
import ptBR from 'antd/es/locale/pt_BR';

function App() {
  return (
    <AuthProvider>
      <ConfigProvider locale={ptBR}>
        <InnovationPagesRoutes />
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
