import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  FormInstance,
  Input,
  InputRef,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ContentForm from '../ContentForm';
import Loading from '../../../../../components/Loading/Loading';
import { useAuth } from '../../../../../core/hooks/auth';
import UserService from '../../../../../services/UserService';
import useLoading from '../../../../../core/hooks/useLoading';
import api from '../../../../../core/services/api';
import { IProfessional } from '../../../../../core/interfaces/professional/IProfessional';
import { convertStringToDate } from '../../../../../helpers/dates.helper';
import notificationMessage from '../../../../../components/notifications/notificationMessage';

interface IParams {
  profileId: number;
  setButtonState: (buttonState: boolean) => void;
  current: number;
  setCurrent: (current: number) => void;
  setFormName: (value: string) => void;
}

interface ITags {
  entityId: number;
  name: string;
}

function ScreenProfessional(props: IParams) {
  const [form] = Form.useForm();
  const { logged } = useAuth();
  const { email } = logged as any;

  const [tagsList, setTags] = useState<ITags[]>([]);
  const [birthDay, setBirthDay] = useState<string>();
  const [tagsFrom, setTagsFrom] = useState<any[]>([]);
  const { loading, showLoading, hideLoading } = useLoading();

  const navigate = useNavigate();

  const { Option } = Select;

  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const userServices = UserService.init();

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const getTags = () => {
    api.get('/tags').then((response) => {
      setTags(response.data);
    });
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name) {
      api.post('/tags', { name }).then((response) => {
        setTags([...tagsList, response.data]);
        getTags();
      });
    }
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const genderOptions = [
    {
      id: 'masculino',
      name: 'Masculino',
    },
    {
      id: 'feminino',
      name: 'Feminino',
    },
    {
      id: 'binario',
      name: 'Binário',
    },
    {
      id: 'naobinario',
      name: 'Não binário',
    },
    {
      id: 'naoinformado',
      name: 'Prefiro não informar',
    },
  ];

  const arrageValue = (value: FormInstance) => {
    const valueMap = new Map(Object.entries(value));

    const result: IProfessional = {
      email: email,
      name: valueMap.get('name'),
      cpf: valueMap.get('cpf'),
      cellPhone: valueMap.get('cellphone'),
      genre: valueMap.get('genre'),
      birthDate: convertStringToDate(String(birthDay)),
      tags: valueMap.get('tags'),
      profileId: 7,
    };

    return result;
  };

  const onFinish = (values: FormInstance) => {
    showLoading();
    userServices
      .createProfessional(arrageValue(values))
      .then((response) => {
        notificationMessage('create');
        localStorage.setItem('@innovation:auth', response.jwttoken);
        props.setCurrent(props.current + 1);

        navigate('/register/conclude/profile/final');
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao criar usuário',
          description: error.response.data.message,
        });
      })
      .finally(hideLoading);
  };

  const setInitialValues = () => {
    form.setFieldsValue({
      name: logged?.name,
      email: logged?.email,
    });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setBirthDay(dateString);
  };

  useEffect(() => {
    props.setFormName('formProfessional');
    setInitialValues();
    getTags();
  }, []);

  return (
    <ContentForm
      title="Profissional"
      subTitle="Precisamos saber algumas informações a respeito de você"
      description="Dados pessoais"
      content={
        <Loading spinning={loading} width={200} height={200}>
          <Form
            name={'formProfessional'}
            onFinish={onFinish}
            form={form}
            layout={'vertical'}
            autoComplete={'off'}
            labelWrap
          >
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name={'name'}
                  label="Qual seu nome?"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório',
                    },
                  ]}
                >
                  <Input placeholder="Nome completo" maxLength={255} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label="CPF"
                  name={'cpf'}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório',
                    },
                    ({ getFieldValue }) => ({
                      validator(_) {
                        if (getFieldValue('cpf').includes('_')) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'CPF inválido',
                    }),
                  ]}
                >
                  <MaskedInput mask="000.000.000-00" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label="Telefone"
                  name="cellphone"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_) {
                        if (getFieldValue('cellphone').includes('_')) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'Telefone inválido',
                    }),
                  ]}
                >
                  <MaskedInput
                    placeholder="Digite seu número"
                    mask="(00) 00000-0000"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="bottom" gutter={[8, 8]}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Qual seu gênero?"
                  name="genre"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select placeholder="Selecione seu gênero">
                    {genderOptions.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Quando você nasceu?"
                  name="birthdate"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    locale={locale}
                    format={'DD/MM/YYYY'}
                    placeholder="Seleciona a data de nascimento"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Quais suas áreas de interesse?"
                  name="tags"
                  tooltip="Selecione as áreas de interesse, você pode selecionar mais de uma"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select
                    placeholder="custom dropdown render"
                    allowClear
                    mode="multiple"
                    onChange={(value) => {
                      setTagsFrom(value);
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                            placeholder="Insira nova área"
                            ref={inputRef}
                            value={name}
                            required
                            onChange={onNameChange}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addItem}
                          >
                            Adicionar novo
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {tagsList.map((item: any, key: number) => (
                      <Option value={item.entityId} key={item.entityId}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      }
    />
  );
}

export default ScreenProfessional;
