import { Button } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text';
import Title from '../../components/Title';
import useLoading from '../../core/hooks/useLoading';
import { IZoomMeeting } from '../../core/interfaces/zoom/IZoom';
import { notification } from '../../helpers/notification.helper';
import AuthZoomService from '../../services/zoom/AuthZoomService';
import ZoomMeetingService from '../../services/zoom/ZoomMeetingService';
import { Wrapper } from './zoom-style';

function Zoom() {
  const navigate = useNavigate();
  const { loading, hideLoading, showLoading } = useLoading();

  const [dataMeeting, setDataMeeting] = useState<null | IZoomMeeting>(null);

  const authZoomService = useMemo(() => AuthZoomService.init(), []);
  const zoomMeetingService = useMemo(() => ZoomMeetingService.init(), []);

  const startAuthorize = () => {
    showLoading();
    authZoomService.authorize();
  };

  const createMeeting = () => {
    showLoading();
    zoomMeetingService
      .create(0)
      .then((data) => {
        setDataMeeting(data);
      })
      .catch(() => notification.error('Erro ao criar reunião'))
      .finally(hideLoading);
  };

  useEffect(() => {
    if (authZoomService.fetchEnabled()) {
      authZoomService
        .fetchToken()
        .then(() => {
          notification.success('Autenticado com sucesso!');
          navigate('/zoom');
        })
        .catch(() => notification.error('Erro ao logar no zoom'));
    }
  }, [authZoomService, navigate]);

  return (
    <Wrapper>
      <Title align="center" color="#fff">
        Mentoria de innovação
      </Title>
      {authZoomService.hasToken() ? (
        <>
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={createMeeting}
            className="btn"
          >
            Criar reunião
          </Button>

          {dataMeeting && (
            <>
              <div style={{ display: 'flex' }}>
                <Text maxWidth="500px" weight={600} color="#fff">
                  Link mentor:{' '}
                  <a
                    href={dataMeeting.start_url}
                    target={'_blank'}
                    style={{ color: '#fff', wordBreak: 'break-all' }}
                    rel="noreferrer"
                  >
                    {dataMeeting.start_url}
                  </a>
                </Text>
              </div>
              <Text maxWidth="500px" weight={600} color="#fff">
                Link aluno: {dataMeeting.join_url}
              </Text>
            </>
          )}
        </>
      ) : (
        <Button
          type="primary"
          loading={loading}
          disabled={loading}
          onClick={startAuthorize}
          className="btn"
        >
          Login
        </Button>
      )}
    </Wrapper>
  );
}

export default Zoom;
