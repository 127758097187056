import React from 'react';
import Text from '../Text';
import { Popover } from 'antd';
import { WrapperPopover, WrapperStatus } from './styles';
import MetricService from '../../services/MetricService';
import {
  getStatusText,
  IPerformaceIndicatorStatus,
} from '../../core/enums/IKeyPerformaceIndicators';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useAuth } from '../../core/hooks/auth';

interface Props {
  styles?: React.CSSProperties;
  placement?: TooltipPlacement;
  status?: IPerformaceIndicatorStatus;
}

function StatusObjectiveKeyResult({
  styles,
  placement,
  status = IPerformaceIndicatorStatus.Pending,
}: Props) {
  const { logged } = useAuth();
  const metricService = React.useMemo(() => MetricService.init(), []);
  const text = getStatusText(status);

  const profiles = logged?.profile ?? [];
  const isStartup = profiles.some(({ description = '' }) =>
    description.toLowerCase().includes('startup'),
  );

  return (
    <Popover
      trigger="click"
      placement={placement}
      overlayClassName={'reset-padding-popover'}
      content={() => (
        <WrapperPopover title={text}>
          {metricService.getStatusDescription(status, isStartup)}
        </WrapperPopover>
      )}
    >
      <WrapperStatus
        style={styles}
        color={metricService.getColorStatus(status)}
      >
        <Text weight={700} color="inherit">
          {text}
        </Text>
      </WrapperStatus>
    </Popover>
  );
}

export default StatusObjectiveKeyResult;
