import moment from 'moment';
import { SQL_FORMAT_WITH_TIME } from '../../constants/date-formats';
import IManageTokenZoom from '../../core/interfaces/zoom/IZoom';
import api from '../../core/services/api';
import { generateSearchParams } from '../../helpers/location.helper';
import { errorCallback } from '../../helpers/promise.helper';

class AuthZoomService {
  private TOKEN_STORAGE_KEY = '@innovation:zoom';
  private responseType = 'code';
  private clientId = '6vv1FOw7RISM5CWxz6ke6Q';

  public static init() {
    return new AuthZoomService();
  }

  public getCode(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('code');
  }

  public getToken(): string | null {
    const login = localStorage.getItem(this.TOKEN_STORAGE_KEY);
    if (!login) return null;

    const { data } = JSON.parse(login) as IManageTokenZoom;
    return data.access_token;
  }

  public hasCode(): boolean {
    return this.getCode() !== null;
  }

  public fetchEnabled(): boolean {
    return this.hasCode() && !this.hasToken();
  }

  public hasToken() {
    const login = localStorage.getItem(this.TOKEN_STORAGE_KEY);
    if (!login) return false;

    const { date } = JSON.parse(login) as IManageTokenZoom;
    const nextDate = moment(date).add(1, 'hour').subtract(1, 'minute');

    const tokenIsValid = nextDate.isAfter(moment());
    if (!tokenIsValid) {
      localStorage.removeItem(this.TOKEN_STORAGE_KEY);
    }

    return tokenIsValid;
  }

  public fetchToken() {
    const params = {
      code: this.getCode(),
    };

    return api
      .get('zoom/token', {
        params,
      })
      .then((response) => {
        const data = {
          data: response.data,
          date: moment().format(SQL_FORMAT_WITH_TIME),
        };
        localStorage.setItem(this.TOKEN_STORAGE_KEY, JSON.stringify(data));
      })
      .catch(errorCallback);
  }

  public authorize(state?: string) {
    const redirectUri = process.env.REACT_APP_ZOOM_REDIRECT_URI;
    if (!redirectUri) {
      throw new Error('redirect uri zoom not defined');
    }

    const params = {
      client_id: this.clientId,
      redirect_uri: redirectUri,
      response_type: this.responseType,
      ...(state && { state }),
    };

    localStorage.removeItem(this.TOKEN_STORAGE_KEY);

    const { url } = generateSearchParams(
      'https://zoom.us/oauth/authorize',
      params,
    );

    window.location.replace(url);
  }
}

export default AuthZoomService;
