import React from 'react';
import { Space } from 'antd';
import Title from '../../../../components/Title';
import { Card } from '../../../../GlobalStyles';
import IMetric from '../../../../core/interfaces/IMetric';
import ItemObjectiveKeyResult from './ItemObjectiveKeyResult';

interface Props {
  objectivesKeyResults: IMetric[];
  reload(): void;

  id: number;
}

function ObjectivesKeyResultsListing({
  reload,
  objectivesKeyResults,
  id,
}: Props) {
  return (
    <Card>
      <Title size={22} margin="0 0 16px 0">
        OKR
      </Title>

      <Space direction="vertical" style={{ display: 'flex' }}>
        {objectivesKeyResults.map((item) => (
          <ItemObjectiveKeyResult
            reload={reload}
            metricId={item.entityId}
            startupId={id}
            comments={item.comments ?? []}
            key={`objective_item_${item.entityId}`}
            keyResult={item.keyResult}
            objectives={item.objectives}
            keyPerformanceIndicators={item.keyPerformanceIndicators}
          />
        ))}
      </Space>
    </Card>
  );
}

export default ObjectivesKeyResultsListing;
