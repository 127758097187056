import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import Title from '../../../components/Title';
import { useAuth } from '../../../core/hooks/auth';
import ProfileHeader from '../../../components/Profiles/ProfileHeader';
import { CONVENTIONAL_FORMAT } from '../../../constants/date-formats';
import MentorProfileForm from './MentorProfileForm';
import BusinessInformationCard from './BusinessInformationCard';

function MentorProfile() {
  const { logged } = useAuth();

  return (
    <>
      <Title size={28} margin="0 0 10px 0">
        Meu perfil
      </Title>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <ProfileHeader
            name={logged?.name}
            dateExpiration={moment(logged?.expiration).format('DD/MM/YYYY')}
            description={logged?.description}
            metaData={[
              {
                label: 'Entrada',
                value:
                  moment(logged?.creation).format(CONVENTIONAL_FORMAT) ?? '--',
              },
              {
                label: 'Última atualização',
                value:
                  moment(logged?.lastUpdated).format(CONVENTIONAL_FORMAT) ??
                  '--',
              },
              {
                label: 'Status atual',
                value: logged?.active ? 'Ativo' : 'Inativo',
              },
              {
                label: 'E-mail',
                isLink: true,
                value: logged?.email ?? '--',
              },
            ]}
          >
            {(props) => <MentorProfileForm {...props} />}
          </ProfileHeader>
        </Col>
        <Col span={24}>
          <BusinessInformationCard />
        </Col>
      </Row>
    </>
  );
}

export default MentorProfile;
