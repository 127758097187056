import React, { useEffect, useRef, useState } from 'react';
import Tab from '../../../components/tab';
import BodySlider from './BodySlider';
import CashFlow from './CashFlow';
import { Card, Form, Input } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import PotentialStartup from './PotentialStartup';
import AnalyzeRisk from './AnalyzeRisk';
import { useLocation } from 'react-router-dom';
import { convertNumberToCurrencyMaskInputWithoutPrefix } from '../../../helpers/convertion.helper';
import Loading from '../../../components/Loading/Loading';
import useLoading from '../../../core/hooks/useLoading';
import FooterPage from '../../../components/FooterPage';
import { IModalRef } from '../../../core/interfaces/IModalRef';

interface Props {
  investmentAmount?: number;
}

function ValuationStartup(props: Props) {
  const [form] = Form.useForm();
  const offer = useWatch('offer', form);
  const [next, setNext] = useState<string>('0');
  const { state }: { state: any } = useLocation();
  const { loading, showLoading, hideLoading } = useLoading();
  const [formName, setFormName] = useState<string>('valuation');
  const [textButton, setTextButton] = useState<string>('Próximo');
  const [ask, setAsk] = useState<boolean>(true);
  const modalRef = useRef<IModalRef>(null);

  const TabContent = [
    {
      tab: 'Potencial da startup',
      body: (
        <PotentialStartup
          showLoading={showLoading}
          hideLoading={hideLoading}
          loding={loading}
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          formName={formName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Análise de riscos',
      body: (
        <AnalyzeRisk
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Financeiro',
      body: (
        <BodySlider
          id={17}
          tabName="Financeiro"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Modelo de negócio',
      body: (
        <BodySlider
          id={5}
          tabName="Modelo de negócio"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Protótipo disponível',
      body: (
        <BodySlider
          id={6}
          tabName="Prtótipo disponível"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Gestão de time',
      body: (
        <BodySlider
          id={7}
          tabName="Gestão de time"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Redução de riscos',
      body: (
        <BodySlider
          id={9}
          tabName="Redução de riscos"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Clientes existentes',
      body: (
        <BodySlider
          id={10}
          tabName="Clientes existentes"
          setNext={setNext}
          next={next}
          params={offer}
          setFormName={setFormName}
          closeModal={modalRef.current?.closeModal}
        />
      ),
    },
    {
      tab: 'Fluxo de caixa',
      body: <CashFlow next={next} params={offer} setFormName={setFormName} />,
    },
  ];

  const maskMoney = (e: React.ChangeEvent<HTMLInputElement>) => {
    const money = e.target.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');

    form.setFieldsValue({
      offer: money,
    });
  };

  useEffect(() => {
    form.setFieldValue(
      'offer',
      convertNumberToCurrencyMaskInputWithoutPrefix(state.investmentAmount),
    );
  }, [state.investmentAmount]);

  useEffect(() => {
    if (next === '8') {
      setTextButton('Concluir');
    } else {
      setTextButton('Próximo');
    }
  }, [next]);

  return (
    <>
      <Loading spinning={false}>
        <Card
          bodyStyle={{ padding: '14px 14px 0px 14px' }}
          style={{ marginBottom: '22px' }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Valor pedido na oferta"
              name="offer"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                placeholder="R$ 0,00"
                onChange={maskMoney}
                prefix={'R$'}
                type="text"
                size="large"
                maxLength={13}
              />
            </Form.Item>
          </Form>
        </Card>
        <Tab
          ref={modalRef}
          content={TabContent}
          fontSize={'12px'}
          activeKey={next}
          changeActiveKey={(value: any) => {
            setNext(value);
          }}
          formName={formName}
        />
        <FooterPage formName={formName} text={textButton} />
      </Loading>
    </>
  );
}

export default ValuationStartup;
