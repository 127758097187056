import styled from 'styled-components';
import { Button } from 'antd';

const Wrapper = styled.div`
  .d-flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .mb-1 {
    margin-bottom: 15px;
  }

  .mb-2 {
    margin-bottom: 25px;
  }

  @media print {
    padding: 22px;

    .mt-2-print {
      margin-top: 100px;
    }
  }
`;

const ButtonExport = styled(Button)`
  color: var(--white-color);
  border: none;
  background-color: var(--thirdy-color);
`;

const Divider = styled.hr`
  border-color: #a72aa7;
  margin: 30px 0;
`;

const MaturityStatus = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 14px 11px;
  align-items: center;
  border: 1px solid var(--support-color);
  border-radius: 5px;

  & p,
  & strong {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Segoe UI', sans-serif;
    color: var(--primary-color);
  }

  & strong {
    font-size: 22px;
    line-height: 0.75;
    color: var(--thirdy-color);
  }

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export { Wrapper, Divider, ButtonExport, MaturityStatus };
