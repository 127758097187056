import Icon from '@ant-design/icons';

import { ReactComponent as IconHome } from '../../assets/icons/menu-icon/ic-home.svg';
import { ReactComponent as IconAccelerator } from '../../assets/icons/menu-icon/ic-accelerator.svg';
import { ReactComponent as IconProfile } from '../../assets/icons/menu-icon/ic-profile.svg';
import { ReactComponent as IconDiagnostic } from '../../assets/icons/menu-icon/ic-diagnostic.svg';
import { ReactComponent as IconMetric } from '../../assets/icons/menu-icon/ic-metric.svg';
import { ReactComponent as IconMethodology } from '../../assets/icons/menu-icon/ic-methodology.svg';
import { ReactComponent as IconNewComnent } from '../../assets/icons/menu-icon/ic-new-comment.svg';
import { ReactComponent as IconCourse } from '../../assets/icons/menu-icon/ic-course.svg';
import { ReactComponent as IconMember } from '../../assets/icons/menu-icon/ic-member.svg';
import { ReactComponent as IconValuation } from '../../assets/icons/menu-icon/ic-valuation.svg';
import { ReactComponent as IconStartup } from '../../assets/icons/menu-icon/ic-startup.svg';
import { ReactComponent as IconMentor } from '../../assets/icons/menu-icon/ic-mentor.svg';
import { ReactComponent as IconUser } from '../../assets/icons/menu-icon/ic-add-user.svg';
import { ReactComponent as IconInvestor } from '../../assets/icons/menu-icon/ic-investor.svg';
import { ReactComponent as IconToll } from '../../assets/icons/menu-icon/ic-tool.svg';
import { ReactComponent as IconMentorship } from '../../assets/icons/menu-icon/ic-mentorships.svg';
import { ReactComponent as IconHiring } from '../../assets/icons/menu-icon/ic-hiring.svg';
import { ReactComponent as IconCalendar } from '../../assets/icons/menu-icon/ic-calendar.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/menu-icon/ic-plus.svg';
import { ReactComponent as IconProfileInfo } from '../../assets/icons/menu-icon/ic-profile-info.svg';
import { ReactComponent as IconInvestmentRound } from '../../assets/icons/menu-icon/ic-investment-round.svg';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

interface IProps {
  onclick?: () => void;
  size?: number;
}

function Home() {
  return <Icon component={IconHome} />;
}

function Accelerator() {
  return <Icon component={IconAccelerator} />;
}

function Valuation() {
  return <Icon component={IconValuation} />;
}

function Member() {
  return <Icon component={IconMember} />;
}

function Diagnostic() {
  return <Icon component={IconDiagnostic} />;
}

function Profile() {
  return <Icon component={IconProfile} />;
}

function Metric() {
  return <Icon component={IconMetric} />;
}

function Course() {
  return <Icon component={IconCourse} />;
}

function Methodology() {
  return <Icon component={IconMethodology} />;
}

function NewComment(props: Partial<CustomIconComponentProps>) {
  return <Icon component={IconNewComnent} {...props} />;
}

function Startup() {
  return <Icon component={IconStartup} />;
}

function Mentor() {
  return <Icon component={IconMentor} />;
}

function AddUser(props: IProps) {
  return <Icon onClick={props.onclick} component={IconUser} />;
}

function Investor(props: IProps) {
  return (
    <Icon size={props.size} onClick={props.onclick} component={IconInvestor} />
  );
}

function Tool(props: IProps) {
  return (
    <Icon size={props.size} onClick={props.onclick} component={IconToll} />
  );
}

function Mentorship(props: IProps) {
  return (
    <Icon
      size={props.size}
      onClick={props.onclick}
      component={IconMentorship}
    />
  );
}

function Hiring(props: IProps) {
  return (
    <Icon size={props.size} onClick={props.onclick} component={IconHiring} />
  );
}

function Calendar(props: IProps) {
  return (
    <Icon size={props.size} onClick={props.onclick} component={IconCalendar} />
  );
}

function Plus(props: IProps) {
  return (
    <Icon size={props.size} onClick={props.onclick} component={IconPlus} />
  );
}

function ProfileInfo(props: IProps) {
  return (
    <Icon
      size={props.size}
      onClick={props.onclick}
      component={IconProfileInfo}
    />
  );
}

function InvestmentRound(props: IProps) {
  return (
    <Icon
      size={props.size}
      onClick={props.onclick}
      component={IconInvestmentRound}
    />
  );
}

export {
  Home,
  Accelerator,
  Valuation,
  Member,
  Profile,
  Diagnostic,
  Metric,
  Course,
  Methodology,
  NewComment,
  Startup,
  Mentor,
  AddUser,
  Investor,
  Tool,
  Mentorship,
  Hiring,
  Calendar,
  Plus,
  ProfileInfo,
  InvestmentRound,
};
