import { Col, Row } from 'antd';
import { isNull } from 'lodash';
import Divider from '../../Divider';
import Text from '../../Text';
import Title from '../../Title';
import CourseItem from './CourseItem';
import { IRecommendedCourse } from '../../../core/interfaces/dashboard-startup/IRecommendedTrail';
import { HeaderPhaseCourse, ProgressPhaseCourse } from './styles';

interface Props {
  title: string;
  average: number;
  courses: IRecommendedCourse[];
}

function PhaseCoursesSection({ title, average, courses }: Props) {
  return (
    <>
      <Text color="#960096" margin="0 0 6px 0" size={20} weight={700}>
        {title} - {average.toFixed(1)}% concluído
      </Text>

      <div style={{ display: 'grid', gap: '1.5rem', margin: '0.5rem 0' }}>
        {courses.map((course, index) => (
          <div key={`course_key_${course.id}`}>
            <HeaderPhaseCourse>
              <Title weight={600} size={16}>
                {`${index + 1}. ${course.name}`}
              </Title>
              <Divider margin="2px 0" color="#9AA4A8" />
              <ProgressPhaseCourse
                percent={+course.progress.toFixed(1)}
                strokeColor="#960096"
                style={{ minWidth: '200px', margin: 0 }}
              />
            </HeaderPhaseCourse>
            <Row gutter={[12, 12]}>
              {course.lessons.map((lesson, lessonIndex) => (
                <Col key={`lesson_key_${lessonIndex}`}>
                  <CourseItem
                    isComplete={lesson.isComplete}
                    title={`${lessonIndex + 1}. ${lesson.name}`}
                  />
                </Col>
              ))}
              {course.exercises.map((exercise, exerciseIndex) => (
                <Col key={`lesson_key_${exerciseIndex}`}>
                  <CourseItem
                    grade={exercise.grade}
                    isComplete={!isNull(exercise.grade)}
                    title={`${exerciseIndex + 1}. ${exercise.name}`}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </div>
    </>
  );
}

export default PhaseCoursesSection;
