import React from 'react';
import { Form, Input } from 'antd';

interface IProps {
  name: number;
  setText: string;
}

function QuestionForm({ name }: IProps) {
  return (
    <>
      <Form.Item
        name={[name, 'question']}
        label={`Pergunta ${name + 1}`}
        initialValue={''}
        rules={[
          { required: true, message: 'Pergunta é um campo obrigatório!' },
          {
            max: 1024,
            message: 'O campo deve possuir no máximo 1024 caracteres',
          },
        ]}
      >
        <Input.TextArea rows={5} />
      </Form.Item>
    </>
  );
}

export default QuestionForm;
