import styled from 'styled-components';
import backgroundImage from '../../assets/images/background-login.png';
import backgroundLigthImage from '../../assets/images/background.png';
import iconStartupMobile from '../../assets/icons/mobile-version-icons/startup-profile-mob.svg';
import iconAceleratorMobile from '../../assets/icons/mobile-version-icons/acelerator-profile-mob.svg';
import iconProfessionalMobile from '../../assets/icons/mobile-version-icons/professional-profile-mob.svg';
import iconStudentMobile from '../../assets/icons/mobile-version-icons/student-profile-mob.svg';
import iconMentorMobile from '../../assets/icons/mobile-version-icons/mentor-profile-mob.svg';
import iconInvestorMobile from '../../assets/icons/mobile-version-icons/investor-profile-mob.svg';
import logoInnovationWhite from '../../assets/images/logo-white.svg';
import logoInnovationBlue from '../../assets/images/logo-blue.svg';

interface IParams {
  url?: string;
  width?: string;
  right?: string;
  top?: string;
  maxWidth?: string;
  position?: string;
}

interface IButtonConteiner {
  position?: string;
}

const CardSecurityDefault = styled.div`
  background-color: #fff;
  max-width: 385px;
  width: 100%;
  border-radius: 15px;
  padding: 22px;

  @media (max-width: 500px) {
    padding: 18px;
  }

  @media (max-width: 400px) {
    > div:first-child > h1 {
      font-size: 20px;
    }
  }
`;

const Content = styled.div<{
  isLight?: boolean;
}>`
  min-height: 100vh;
  background-color: ${({ isLight }) =>
    isLight ? '#fff' : 'var(--primary-color)'};
  background-image: ${({ isLight }) =>
    `url(${isLight ? backgroundLigthImage : backgroundImage})`};
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;

  & .logo {
    width: 400px;
    height: 120px;
    object-fit: cover;
    background-image: ${({ isLight }) =>
      `url(${isLight ? logoInnovationBlue : logoInnovationWhite})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  & .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: ${({ isLight }) =>
      isLight ? 'var(--primary-color)' : 'var(--white-color)'};

    .action:hover {
      cursor: pointer;
      color: var(--secondary-color);
    }
  }
`;

const FlexPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 850px) {
    gap: 1rem;
    justify-content: space-evenly;
  }
`;

const BackgroundImage = styled.div<IParams>`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1024px;
  min-height: 650px;
  width: 100%;
  height: 100%;
  background: ${(props) => `url(${props.url}) no-repeat top center`};
  background-size: cover;
`;

const BoxContainer = styled.div<IParams>`
  position: ${({ position = 'absolute' }) => position};
  top: ${(props) => (props.top ? props.top : '30%')};
  right: ${(props) => (props.right ? props.right : '15%')};
  width: ${(props) => (props.width ? props.width : '380px')};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: 30px 20px;
  border-radius: 20px;
  background-color: var(--white-color);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.4);

  & .ant-steps {
    margin-bottom: 20px;
  }

  & .ant-steps {
    margin-bottom: 20px;
  }

  & .ant-steps .ant-steps-item-icon,
  .ant-steps .ant-steps-item-content {
    display: none;
  }

  & .ant-steps .ant-steps-item-tail,
  .ant-steps-item-tail:after {
    width: 100%;
    margin: 0;
    height: 2px !important;
  }

  & .ant-steps .ant-steps-item-finish .ant-steps-item-tail:after {
    background-color: var(--thirdy-color);
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
`;

const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  color: var(--primary-color);
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  & .ant-form-item .ant-form-item-required {
    height: 20px;
    font-size: 0.8em !important;
    color: var(--primary-color) !important;
    font-weight: bold;
    margin-left: -10px;
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: transparent;
  }
`;

const LostPassword = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: -5px;

  a {
    font-size: 0.8em;
    font-weight: bold;
    color: var(--secondary-color);
  }
`;

const ButtonContainer = styled.div<IButtonConteiner>`
  display: flex;
  align-items: ${(props) =>
    props.position === 'right' ? 'flex-end' : 'center'};
  justify-content: ${(props) =>
    props.position === 'right' ? 'end' : 'center'};
  width: 100%;

  & .ant-btn-primary {
    padding: 0 40px;
    margin-top: 15px;
    margin-right: ${(props) =>
      props.position === 'right' ? 'calc(8% + 5px)' : '0'};
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;

    & a,
    span {
      font-size: 0.8em;
    }
  }

  & .ant-btn-primary[disabled] {
    background-color: var(--support-color);
  }
`;

const RegisterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-color);
  margin-top: 10px;
  font-size: 0.8em;

  & a,
  div {
    font-size: 1em;
    font-weight: bold;
    color: var(--secondary-color);
    margin-left: 6px;
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  & .text {
    margin-top: 10px;
    color: var(--font-color);
    text-align: left;
  }

  & .counter {
    text-align: center;
    color: var(--font-color);
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const RocketImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & img {
    width: 72px;
    height: 72px;
  }
`;

const Introduction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 84%;
  margin-left: 8%;
  margin-top: 10px;

  & p {
    margin-top: 10px;
  }
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1.5rem;

  & .selectedCard {
    background-color: var(--primary-color) !important;

    & .ant-card-meta-title,
    .ant-card-meta-description {
      color: var(--white-color) !important;
    }

    & .tip {
      & svg {
        color: #fff;
      }
    }
  }

  & .ant-card {
    border: 1px solid var(--support-color);
    border-radius: 6px;
    cursor: pointer;
  }

  & .ant-card:hover {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
  }

  & .ant-card-body {
    padding: 5px;
  }

  & .ant-card-meta {
    gap: 4px;
  }

  & .ant-card-meta-avatar {
    padding-right: 2px;

    & img {
      width: 84px;
    }
  }

  & .ant-card-meta-title {
    font-weight: bold;
  }

  & .ant-card-meta-description {
    font-size: 0.8em;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    img {
      width: 35px !important;
      height: 50px !important;
    }

    .card-startup img {
      content: url(${iconStartupMobile});
    }

    .card-acelerator img {
      content: url(${iconAceleratorMobile});
    }

    .card-professional img {
      content: url(${iconProfessionalMobile});
    }

    .card-student img {
      content: url(${iconStudentMobile});
    }

    .card-mentor img {
      content: url(${iconMentorMobile});
    }

    .card-investor img {
      content: url(${iconInvestorMobile});
    }
  }
`;

const CardTitle = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 1em;

  & .tip {
    position: absolute;
    top: 0;
    right: 0;

    & svg {
      color: var(--support-color);
    }
  }
`;

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export {
  CardSecurityDefault,
  ButtonContainer,
  BackgroundImage,
  CardContent,
  CardTitle,
  Content,
  BoxContainer,
  FlexPage,
  Introduction,
  SubTitle,
  Title,
  FormContainer,
  LostPassword,
  ModalContent,
  RegisterBox,
  RocketImage,
  ImageBox,
};
