import { IProfile, IStartup } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';
import IDashboardResponse from '../core/interfaces/models/IDashboardResponse';
import { IMember } from '../core/interfaces/IMember';
import { errorCallback } from '../helpers/promise.helper';
import IMemberAccelerator from '../core/interfaces/accelerator/IMemberAccelerator';
import IListStartups from '../core/interfaces/list/IListStartups';

class StartupService extends CrudService {
  public static init(): StartupService {
    return new StartupService({
      requestMapping: 'startup',
    });
  }

  public getByUser(userId: number): Promise<IStartup> {
    const params = {
      user: userId,
    };

    return api
      .get<IStartup>(`${this.requestMapping}/startup`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAll(): Promise<IStartup[]> {
    return api
      .get<IStartup[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllByUser(userId: number): Promise<IDashboardResponse[]> {
    return api
      .get<IDashboardResponse[]>(`${this.requestMapping}/user/${userId}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllDiagnostics(id: number): Promise<any> {
    return api
      .get<any>(`${this.requestMapping}/diagnostics/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createProfileToMembers(
    payload: IProfile,
    id: number,
  ): Promise<IProfile> {
    return api
      .post<any>(`${this.requestMapping}/save-profile/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllMembersByStartup(id: number): Promise<IMember[]> {
    return api
      .get<IMember[]>(`${this.requestMapping}/members/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createMember(id: number, payload: IMemberAccelerator) {
    return api
      .post<any>(`${this.requestMapping}/members/create/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getByOwner(userId: number): Promise<IStartup> {
    return api
      .get<IStartup>(`${this.requestMapping}/by-owner`, {
        params: {
          user: userId,
        },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async listAllStartups(): Promise<IListStartups[]> {
    return api
      .get<IListStartups[]>(`${this.requestMapping}/list-all-startups`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async listAllStartupsByClient(
    clientId: number,
  ): Promise<IListStartups[]> {
    return api
      .get<IListStartups[]>(`${this.requestMapping}/client/${clientId}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getStartup(id: number) {
    return api
      .get(`${this.requestMapping}/startup/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async inviteStartups(loggedId: number, email: string, token: string) {
    return api
      .post('startup-invite', {
        id: loggedId,
        email,
        inviterToken: token,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default StartupService;
