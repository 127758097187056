import React from 'react';
import BoxInfo from './Box/InfoBox';
import image from '../../assets/icons/actions-icons/ic-help.svg';

function MentorshipInfo() {
  return (
    <BoxInfo
      title="MENTORIAS"
      image={image}
      description={
        <p>
          Neste espaço você poderá cadastrar suas mentorias e torná-las
          acessíveis para as startups. Nesse espaço, você precisará adicionar o
          tema, descrição e o valor que você cobra por hora. Finalizado o
          cadastro, você tem acesso a todas as suas mentorias cadastradas e
          também ao calendário mostrando dias disponíveis e mentoria agendadas.
        </p>
      }
    />
  );
}

export default MentorshipInfo;
