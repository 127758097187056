import React, { useEffect, useState } from 'react';
import ContentBase from '../templates/ContentBase';
import MentorshipInfo from '../Infos/MentorshipInfo';
import { BoxContainer, BoxWhite } from '../../GlobalStyles';
import MentorshipList from './MentorshipList';
import ScheduleCalendar from '../../components/ScheduleCalendar/ScheduleCalendar';
import { useAuth } from '../../core/hooks/auth';
import ScheduleService from '../../services/ScheduleService';
import { ISchedule } from '../../core/interfaces/ISchedule';

function Mentorship() {
  const { logged } = useAuth();
  const id = logged?.entityId;
  const scheduleService = ScheduleService.init();
  const [schedule, setSchedule] = useState<ISchedule[]>([]);

  const getSchedule = async () => {
    scheduleService
      .getAllSchedulesByStartup(Number(id))
      .then((response) => {
        setSchedule(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSchedule();
  }, []);

  return (
    <>
      <ContentBase
        title="Mentorias"
        modalContent={<MentorshipInfo />}
        content={
          <BoxContainer noPadding={true}>
            <MentorshipList />
            <BoxWhite noPadding={true}>
              <ScheduleCalendar title="Agendados" />
            </BoxWhite>
          </BoxContainer>
        }
      />
    </>
  );
}

export default Mentorship;
