import React, { useState, useEffect } from 'react';
import { BoxContainer } from '../../GlobalStyles';
import Tab from '../../components/tab';
import Berkus from './berkus/Berkus';
import ScoreCard from './socre-card/ScoreCard';
import Vc from './vc/Vc';
import IValuation from '../../core/interfaces/models/IValuation';
import WeightValuation from './weight-valuation/WeightValuation';

interface IParams {
  setFormName: (formName: string) => void;
  initialValues: Record<string, unknown>;
  closeForm: () => void;
}

function ValuationForm(props: IParams) {
  const [activeKey, setActiveKey] = useState('0');
  const INDEX_WCC = 4;
  const isActiveWcc = +activeKey === INDEX_WCC;
  //const [next, setNext] = useState<string>('0')
  const [data, setData] = useState<IValuation>();
  const content = [
    {
      tab: 'Score',
      body: (
        <ScoreCard
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
          initialValues={props.initialValues}
          setFormName={props.setFormName}
        />
      ),
    },
    {
      tab: 'Berkus',
      body: (
        <Berkus
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
          initialValues={props.initialValues}
          setFormName={props.setFormName}
        />
      ),
    },
    {
      tab: 'Pesos',
      body: (
        <WeightValuation
          setFormName={props.setFormName}
          initialValues={props.initialValues}
          setNext={setActiveKey}
          next={activeKey}
          setParamsResponse={setData}
        />
      ),
    },
    {
      tab: 'VC',
      body: <Vc closeDrawer={props.closeForm} />,
    },
  ];

  useEffect(() => {
    const dataScoreCard = [
      'valuation-form-startup-potential',
      'valuation-form-startup-innovation',
      'valuation-form-startup-entrepreneur',
      'valuation-form-risk-mart',
      'valuation-form-risk-innovation',
      'valuation-form-risk-entrepreneur',
      'valuation-form-financial',
    ];

    const dataBerkus = [
      'berkus-form-business-model',
      'berkus-form-prototype-available',
      'berkus-form-management-team',
      'berkus-form-risk-reduction',
      'berkus-form-existing-customers',
      'berkus-form-max-valuation',
    ];

    const dataValuationCapital = ['form-valuation-capital'];
    const dataWaccMultiples = ['form-wacc-multiples'];

    dataScoreCard
      .concat(dataBerkus)
      .concat(dataWaccMultiples)
      .concat(dataValuationCapital)
      .forEach((data) => sessionStorage.removeItem(data));
  }, []);

  return (
    <BoxContainer>
      <Tab
        title="Valuation"
        content={content}
        activeKey={activeKey}
        changeActiveKey={(value) => setActiveKey(value)}
        backgroundColor={isActiveWcc ? 'transparent' : '#fff'}
      />
    </BoxContainer>
  );
}

export default ValuationForm;
