import api from '../core/services/api';
import IFileStorage from '../core/interfaces/IFileStorage';

class FileService {
  private requestMapping = '/file';

  public static init() {
    return new FileService();
  }

  public async export(fileStorage: IFileStorage): Promise<void> {
    try {
      const { originalName, name } = fileStorage;
      const src = await this.get(name);

      const temporaryTag = document.createElement('a');
      temporaryTag.setAttribute('href', src);
      temporaryTag.setAttribute('download', originalName);
      temporaryTag.click();

      setTimeout(() => temporaryTag.remove(), 100);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public open(file: IFileStorage) {
    const { name, originalName } = file;
    return this.openByName(name, originalName);
  }

  public async openByName(name: string, originalName?: string): Promise<void> {
    try {
      const src = await this.get(name);
      const newTab = window.open(src, '_blank');

      if (newTab) {
        newTab.addEventListener('load', () => {
          setTimeout(() => (newTab.document.title = originalName ?? name), 200);
        });
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public openTemporary(file: File): void {
    const src = window.URL.createObjectURL(file);
    window.open(src, '_blank');
  }

  public get(fileName: string): Promise<string> {
    return api
      .get(`${this.requestMapping}/${fileName}`, {
        responseType: 'blob',
      })
      .then((response) => window.URL.createObjectURL(response.data))
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default FileService;
