import styled from 'styled-components';

export const BoxBread = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 35px;
  width: 50%;

  & .ant-breadcrumb {
    line-height: 60px;
    margin: 0;
    padding: 0;

    a {
      background-color: transparent !important;
    }

    & span {
      margin-left: 5px;

      & a {
        color: var(--font-color);
        font-weight: bold;
      }

      & a:hover {
        color: var(--secondary-color);
      }
    }

    & .ant-breadcrumb-separator {
      margin: 0;
      padding: 0;
      margin-left: 5px !important;
      color: var(--primary-color);
    }
  }
`;
