import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HorizontalScroll = styled.div`
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HorizontalContent = styled.div`
  width: max-content;

  display: flex;
  align-items: stretch;
  gap: 1rem;
`;
