import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import {
  ParamsActionsCrud,
  EnabledActionsCrudEnum,
} from '../../core/interfaces/IActionsCrud';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { IconOptions, MenuOption } from '../../GlobalStyles';
import ConfirmAction from '../ConfirmAction';

function ActionsCrud({
  edit,
  view,
  remove,
  enabledActions,
  sizeIcon = 14,
  ...props
}: ParamsActionsCrud) {
  const actions = [
    {
      label: <p>Editar</p>,
      event: edit,
      icon: <EditOutlined />,
      value: EnabledActionsCrudEnum.EDIT,
    },
    {
      label: <p>Visualizar</p>,
      event: view,
      icon: <EyeOutlined />,
      value: EnabledActionsCrudEnum.VIEW,
    },
    {
      label: (
        <ConfirmAction
          title="Deseja remover este item?"
          onConfirm={() => remove && remove()}
        >
          <p>Deletar</p>
        </ConfirmAction>
      ),
      icon: <DeleteOutlined />,
      value: EnabledActionsCrudEnum.REMOVE,
    },
  ];

  const items: MenuProps['items'] = (
    props.actions ??
    actions.filter((action) =>
      enabledActions ? enabledActions.includes(action.value) : true,
    )
  ).map((action, index) => {
    return {
      key: `action_crud_item_${index}`,
      label: (
        <MenuOption onClick={action.event}>
          <IconOptions display="flex" size="14px !important">
            {action.icon}
          </IconOptions>
          {action.label}
        </MenuOption>
      ),
    };
  });

  return (
    <Dropdown trigger={['hover']} placement="topLeft" menu={{ items: items }}>
      <IconOptions cursor="pointer" size={`${sizeIcon}px`}>
        <MoreOutlined />
      </IconOptions>
    </Dropdown>
  );
}

export default ActionsCrud;
