import Okrs from './Okrs';
import { Col, Row } from 'antd';
import ProgressStartup from '../../../components/ProgressStartup';
import Valuation from './Valuation';
import CurrentTrack from './CurrentTrack';
import ContentBase from '../../templates/ContentBase';
import { useAuth } from '../../../core/hooks/auth';

function Dashboard() {
  const { logged } = useAuth();
  return (
    <ContentBase
      title={`Bem vindo, ${logged?.name}`}
      questionIsVisible={false}
      content={
        <Row align={'top'} gutter={[16, 16]}>
          <Col span={24}>
            <ProgressStartup
              userId={Number(logged?.entityId)}
              showSelected={false}
            />
          </Col>
          <Col sm={24} md={12} lg={12} xl={8}>
            <CurrentTrack />
          </Col>
          <Col sm={24} md={12} lg={12} xl={8}>
            <Okrs />
          </Col>
          <Col sm={24} md={24} lg={24} xl={8}>
            <Valuation />
          </Col>
        </Row>
      }
    />
  );
}

export default Dashboard;
