import React, { useState } from 'react';
import styled from 'styled-components';
import IComment from '../../../../core/interfaces/models/IComment';
import { isEmpty } from '../../../../helpers/array.helper';

const PopHoverButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
`;

const PopHoverContainer = styled.div`
  position: relative;
`;

const CommentsList = styled.div`
  z-index: 100000;
  position: absolute;
  top: 40px;
  right: 0;
  width: 700px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 10px 0 10px;
  box-shadow: 1px 1px 1px #ccc;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    word-break: break-all;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
    }

    & .comment {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      color: var(--thirdy-color);
      font-style: italic;
    }

    & .date {
      font-size: 12px;
      font-style: normal;
      line-height: 1.5;
      right: 0;
      width: 100px;
      margin-right: 0;
      letter-spacing: 0.00938em;
      color: #696969;
      font-weight: bold;
      position: relative;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 40px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white-color);
  }

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    right: 40px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary-color);
  }

  @media (max-width: 768px) {
    right: 0;
    width: 100%;
  }

  @media (max-width: 480px) {
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
  }

  @media (max-width: 320px) {
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
  }
`;

interface IProps {
  children: React.ReactNode;
  comments: IComment[];
}

function Tooltip({ children, comments }: IProps) {
  const [showComments, setShowComments] = useState(false);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  return (
    <PopHoverContainer>
      <PopHoverButton onClick={toggleComments}>{children}</PopHoverButton>
      {showComments && (
        <CommentsList>
          {isEmpty(comments) ? (
            <p
              style={{
                color: '#696969',
              }}
            >
              Nenhum comentário
            </p>
          ) : (
            comments?.map((comment, index) => (
              <div key={index} className="content">
                <p className="comment">{comment.comment}</p>
                <p className="date">{comment.creation}</p>
              </div>
            ))
          )}
        </CommentsList>
      )}
    </PopHoverContainer>
  );
}

export default Tooltip;
