enum StatusAnswerEnum {
  Pending = 'PENDING',
  Disapproved = 'DISAPPROVED',
  Approved = 'APPROVED',
}

const getDescription = (status: StatusAnswerEnum) => {
  const { Approved, Disapproved, Pending } = StatusAnswerEnum;
  const texts = {
    [Pending]: 'Aguardando correção',
    [Approved]: 'Atividade concluída',
    [Disapproved]: 'Refazer exercício',
  };

  return texts[status];
};

export { getDescription, StatusAnswerEnum };
