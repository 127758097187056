import { Col, Form, Input, Row, Spin } from 'antd';
import React, { useMemo } from 'react';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IMentorBusinessInformation from '../../../../core/interfaces/IMentorBusinessInformation';
import { IPropsModal } from '../../../../core/interfaces/modal/IPropsModal';
import { ModalBody, PurpleButton } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import MentorService from '../../../../services/MentorService';

const required = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

interface Props extends IPropsModal {
  information: IMentorBusinessInformation;
  onReload(): void;
}

function BusinessInformationForm({
  visible,
  onCancel,
  onReload,
  information,
}: Props) {
  const { logged } = useAuth();
  const { loading, showLoading, hideLoading } = useLoading();

  const { entityId = 0 } = logged ?? {};

  const mentorService = useMemo(() => MentorService.init(), []);
  const initialValues = useMemo(
    () => ({
      link_lattes: information.linkLattes,
      link_linkedin: information.linkLinkedin,
      occupation_area: information.occupationArea,
    }),
    [information],
  );

  const onSubmitForm = (values: Record<string, unknown>) => {
    showLoading();
    mentorService
      .updateBusinessInformation(entityId, {
        linkLattes: values.link_lattes,
        linkLinkedin: values.link_linkedin,
        occupationArea: values.occupation_area,
      })
      .then(() => {
        onCancel();
        onReload();
        notification.success('Informações salvas com sucesso.');
      })
      .catch(() => notification.error('Erro ao salvar informações'))
      .finally(hideLoading);
  };

  return (
    <ModalBody
      title="Editar informações de négocio"
      width={'100%'}
      margin="0 auto"
      maxWidth="800px"
      open={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onSubmitForm}
        initialValues={initialValues}
      >
        <Spin spinning={loading}>
          <Row gutter={[12, 0]}>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="link_lattes"
                label="Link Lattes"
                rules={required}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="link_linkedin"
                label="Link Linkedin"
                rules={required}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="occupation_area"
                label="Área de atuação"
                rules={required}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <PurpleButton type="primary" htmlType="submit">
                Salvar
              </PurpleButton>
            </Col>
          </Row>
        </Spin>
      </Form>
    </ModalBody>
  );
}

export default BusinessInformationForm;
