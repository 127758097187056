import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Empty, Row, Spin } from 'antd';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '../../../components/Title';
import { PurpleButton } from '../../../GlobalStyles';
import ContentBase from '../../templates/ContentBase';
import ExerciseService from '../../../services/exercise/ExerciseService';
import StudentAnswerService from '../../../services/exercise/StudentResponse';
import { notification } from '../../../helpers/notification.helper';
import useLoading from '../../../core/hooks/useLoading';
import { Box } from './styles';
import Text from '../../../components/Text';
import { IExerciseQuizList } from '../../../core/interfaces/course/IExercise';
import { isArray, isEmpty, isNull } from 'lodash';
import Divider from '../../../components/Divider';
import IExerciseQuizType, {
  getValueString,
} from '../../../core/enums/IExerciseQuizType';
import DiscursiveField from './DiscursiveField';
import ObjectiveField from './ObjectiveField';
import AttachmentField from './AttachmentField';
import IFieldComponentParams, {
  IFormValue,
} from '../../../core/interfaces/attend-course/IFieldComponentParams';
import { useAuth } from '../../../core/hooks/auth';
import IExerciseWithStatus from '../../../core/interfaces/exercise/IExerciseWithStatus';

function AnswerExercise() {
  const navigate = useNavigate();
  const { logged } = useAuth();

  const { id, courseId } = useParams();
  const { loading, hideLoading, showLoading } = useLoading();

  const [values, setValues] = useState<Record<string, IFormValue>>({});
  const [exercise, setExercise] = useState<IExerciseWithStatus | null>(null);
  const exerciseService = useMemo(() => ExerciseService.init(), []);
  const studentAnswerService = useMemo(() => StudentAnswerService.init(), []);

  const getExercise = useCallback(() => {
    showLoading();
    exerciseService
      .findByIdWithStatus(Number(id), Number(logged?.entityId))
      .then((data) => setExercise(data))
      .catch(() => notification.info('Exercício não encontrado'))
      .finally(hideLoading);
  }, [exerciseService, id, logged, hideLoading, showLoading]);

  const getComponent = (
    quiz: IExerciseQuizList,
  ): React.FC<IFieldComponentParams> => {
    const { ATTACHMENT, DISCURSIVE, OBJECTIVE, MULTIPLE } = IExerciseQuizType;
    const components = {
      [getValueString(MULTIPLE)]: ObjectiveField,
      [getValueString(OBJECTIVE)]: ObjectiveField,
      [getValueString(DISCURSIVE)]: DiscursiveField,
      [getValueString(ATTACHMENT)]: AttachmentField,
    };

    return components[quiz.type];
  };

  const formIsValid = (): boolean => {
    const numberOfExercisesAnswered = Object.keys(values).filter((key) => {
      const item = values[key];
      const value = item.answer ?? item.file;
      return !isEmpty(value);
    }).length;
    const amountOfExercises = (exercise?.quizzes ?? []).length;

    return amountOfExercises === numberOfExercisesAnswered;
  };

  const makeAnwser = (
    exerciseQuiz: IExerciseQuizList,
    answer = '',
    file = '',
  ) => {
    return {
      ...(file && { file }),
      ...(answer && { answer }),
      exerciseQuiz: {
        ...exerciseQuiz,
        objectiveQuestions: (exerciseQuiz?.objectiveQuestions ?? []).map(
          (question) => ({ ...question, status: false }),
        ),
      },
    };
  };

  const getDataForm = () => {
    const items: Array<IFormValue> = [];
    Object.keys(values).forEach((key) => {
      const { answer, file, exerciseQuiz } = values[key];
      if (isArray(answer)) {
        answer.forEach((answerItem) =>
          items.push(makeAnwser(exerciseQuiz, answerItem)),
        );
      } else {
        items.push(makeAnwser(exerciseQuiz, answer, file));
      }
    });

    return {
      exerciseId: Number(id),
      responses: items,
    };
  };

  const back = () => navigate(`/courses-startup/attend-course/${courseId}`);

  const submitForm = () => {
    if (formIsValid()) {
      showLoading();
      studentAnswerService
        .create(getDataForm())
        .then(() => {
          notification.success('Exercício respondido com sucesso');
          back();
        })
        .catch(() => {
          notification.error('Erro ao responder exercício');
        })
        .finally(hideLoading);
    } else {
      notification.info('Preencha todas as perguntas');
    }
  };

  useEffect(() => {
    getExercise();
  }, [getExercise]);

  const exerciseNotFound = isNull(exercise) && !loading;

  return (
    <ContentBase
      content={
        <Spin spinning={loading}>
          <Row gutter={16} align={'middle'} style={{ marginBottom: '1.5rem' }}>
            <Col>
              <PurpleButton
                size="small"
                type="primary"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={back}
              >
                <MdKeyboardArrowLeft size={20} />
              </PurpleButton>
            </Col>
            <Col>
              <Title size={32} lineHeight={1.3}>
                {exercise?.name ?? ''}
              </Title>
            </Col>
          </Row>

          {exerciseNotFound ? (
            <Empty
              description={
                <Text weight={700} size={22}>
                  Exercício não encontrado
                </Text>
              }
            />
          ) : (
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Box>
                  <Title size={20} lineHeight={1.3}>
                    {exercise?.name}
                  </Title>
                  <Divider color="#960096" margin="11px 0" />
                  <Text>{exercise?.description}</Text>
                </Box>
              </Col>
              {exercise?.quizzes.map((data, index) => {
                const Component = getComponent(data.quiz);
                return (
                  <Col key={`key_quiz_${index}`} span={24}>
                    <Component
                      data={data}
                      values={values}
                      changeValue={(fieldName, value) =>
                        setValues({
                          ...values,
                          [fieldName]: { ...value, exerciseQuiz: data.quiz },
                        })
                      }
                    />
                  </Col>
                );
              })}
              {!exercise?.isPendingApproval && isNull(exercise?.grade) && (
                <Col span={24}>
                  <PurpleButton
                    type="primary"
                    onClick={submitForm}
                    style={{ display: 'block', margin: '0 auto' }}
                  >
                    Enviar exercício
                  </PurpleButton>
                </Col>
              )}
            </Row>
          )}
        </Spin>
      }
    />
  );
}

export default AnswerExercise;
