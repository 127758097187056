import React from 'react';
import { useNavigate } from 'react-router-dom';
import StartupJourneyBase from '../../../../components/StartupJourneyBase';
import { useAuth } from '../../../../core/hooks/auth';

function StartupJourney() {
  const { logged } = useAuth();
  const navigate = useNavigate();

  return (
    <StartupJourneyBase
      selectCourse={(course) =>
        navigate(`/courses-startup/attend-course/${course.id}`)
      }
      userId={Number(logged?.entityId)}
    />
  );
}

export default StartupJourney;
