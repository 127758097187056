import styled from 'styled-components';

interface IParams {
  fontSize?: string;
  default?: 'default';
  border?: boolean;
  backgroundColor?: string;
}

const BoxContent = styled.div<IParams>`
  width: 100%;

  & .ant-tabs {
    width: 100%;
  }

  & .ant-tabs-nav {
    height: 38px !important;

    :before {
      border: none !important;
    }
  }

  & .ant-tabs-nav-list {
    width: 100%;
  }

  & .ant-tabs-tab {
    display: flex;
    min-width: 100px;
    height: 39px !important;
    color: var(--support-color);
    background-color: var(--grey-color) !important;
  }

  & .ant-tabs-tab-active,
  .ant-tabs-tab-active div {
    background-color: var(--quaternary-color) !important;
    color: var(--white-color) !important;

    & .ant-tabs-tab-btn {
      background-color: transparent !important;
    }
  }

  & .ant-tabs-content {
    background-color: ${({ backgroundColor }) =>
      backgroundColor || 'var(--white-color)'};
    padding: 10px 10px;
    border: ${(props) =>
      props.border ? '1px solid var(--quaternary-color)' : 'none'};
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & .ant-tabs-tab-btn {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    border: 1px solid var(--quaternary-color);
  }

  & .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
    border-radius: 6px 6px 0 0;
  }

  & .ant-tabs-nav-operations {
    display: none !important;
    background-color: var(--quaternary-color) !important;

    & svg {
      color: var(--white-color);
    }
  }
`;

const BoxTab = styled.div<IParams>`
  & .ant-tabs-nav-list {
    overflow: auto;
  }

  & .ant-tabs-nav {
    background-color: var(--white-color) !important;
  }

  & .ant-tabs-tab {
    height: 26px !important;
    margin: 7px 0 0 5px !important;
    border-radius: 4px !important;
    background-color: var(--support-color) !important;
    color: var(--white-color);
  }

  & .ant-tabs-tab-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid var(--grey-color) !important;
  }

  & .ant-tabs-tab-active {
    background-color: var(--primary-color) !important;
  }

  & .ant-tabs-content {
    background-color: var(--white-color);
    padding: 10px 10px;
    border: ${(props) =>
      props.border ? '1px solid var(--quaternary-color)' : 'none'};
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Title = styled.div`
  float: left;
  height: 38px !important;
  width: 200px;
  font-size: 1.4em;
  font-weight: bold;
  padding-left: 10px;
  line-height: 38px;
  color: var(--white-color);
  background-color: var(--quaternary-color);
  border-top-right-radius: 6px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  & .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  & .text,
  .text p {
    text-align: center;
    font-size: 1em;
  }

  & .ant-btn-primary,
  .ant-btn-default {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 5px;
    background-color: var(--thirdy-color);
  }

  & .ant-btn-primary:hover {
    background-color: var(--thirdy-support) !important;
  }

  & .ant-btn-default {
    background-color: var(--white-color);
  }

  & .ant-btn-default:hover {
    background-color: var(--grey-color) !important;
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
`;

export { BoxContent, BoxTab, ModalContent, Title };
