import styled from 'styled-components';
import { Layout } from 'antd';

const SidebarWrapper = styled(Layout.Sider)`
  .sidebar {
    height: 100vh;
    margin-right: 24px;
  }

  & .ant-layout-sider-zero-width-trigger {
    left: 0;
  }

  .ant-layout-sider-zero-width-trigger:hover::after {
    background: initial;
  }

  &.ant-layout-sider-collapsed {
    & .ant-layout-sider-zero-width-trigger {
      background-color: #fff;
    }

    & svg {
      color: #001529;
    }
  }

  & svg {
    color: #fff;
  }
`;

export { SidebarWrapper };
