import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Error from '../../pages/Error';
import Login from '../../pages/security/login/Login';
import ForgotPassword from '../../pages/security/forgot-password';
import Register from '../../pages/security/register';
import RegisterConclude from '../../pages/security/register-conclude';
import RegisterProfile from '../../pages/security/register-conclude/profile/box';
import ValidateEmail from '../../pages/security/validate-email';
import ErrorPage from '../../pages/error-page';
import SessionExpied from '../../pages/error-page/SessionExpired';
import Terms from '../../components/terms-of-use';
import PrivacyPolice from '../../components/terms-of-use/PrivacyPolice';

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Navigate to={'/login'} />} />

      {/* Login */}
      <Route path="/login" element={<Login />} />

      {/* Terms */}
      <Route path={'/terms-of-use'} element={<Terms goBack={true} />} />
      <Route
        path={'/privacy-policy'}
        element={<PrivacyPolice goBack={true} />}
      />

      {/*Paginas de erro*/}
      <Route path={'error/:code'} element={<ErrorPage />} />
      <Route path={'session-expired'} element={<SessionExpied />} />

      {/* Forgot password page */}
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* Register page */}
      <Route path="/register" element={<Register />} />
      <Route path="/register/conclude" element={<RegisterConclude />} />
      <Route path="/register/conclude/profile" element={<RegisterProfile />} />
      <Route path="/validate-email" element={<ValidateEmail />} />
    </Routes>
  );
}
