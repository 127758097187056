import { Dropdown } from 'antd';
import type { MenuProps } from 'antd/es';
import React from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useModal } from 'sunflower-antd';
import useLoading from '../../../core/hooks/useLoading';
import ModalConfirm from '../../../pages/courses/ModalConfirm';
import Text from '../../Text';

type Props = {
  deleteConversation(): Promise<void>;
};

function ChatActions({ deleteConversation }: Props) {
  const modalConfirmDelete = useModal({
    defaultVisible: false,
  });
  const { loading, hideLoading, showLoading } = useLoading();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Text role={'button'} weight={600} onClick={modalConfirmDelete.show}>
          Excluir
        </Text>
      ),
    },
  ];

  const confirm = () => {
    showLoading();
    deleteConversation().then(modalConfirmDelete.close).finally(hideLoading);
  };

  return (
    <>
      <ModalConfirm
        title="Excluir conversa?"
        textConfirm="Sim, excluir"
        loading={loading}
        confirm={confirm}
        {...modalConfirmDelete.modalProps}
      >
        <Text size={16} align="center">
          Tem certeza que deseja excluir todo o histórico desta conversa ?
        </Text>
      </ModalConfirm>

      <Dropdown
        overlayStyle={{ minWidth: '200px' }}
        menu={{ items }}
        placement="bottomLeft"
        arrow
        trigger={['click']}
      >
        <FiMoreVertical cursor="pointer" size={18} />
      </Dropdown>
    </>
  );
}

export default ChatActions;
