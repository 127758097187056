import React from 'react';
import { Box, Divider, Description } from './styles';

interface Props {
  children: React.ReactNode;
}

function MaturityChart({ children }: Props) {
  return (
    <Box>
      <div style={{ width: '100%', height: '100%' }}>
        <div data-chart="view">{children}</div>
        <div data-chart="print">{children}</div>
      </div>
      <Divider />
      <Description>
        Por meio deste gráfico é possível a identificação desde o pilar mais
        fraco ao mais forte e, a partir de então, tomar as devidas decisões em
        fortalecer cada área fraca do seu projeto.
      </Description>
    </Box>
  );
}

export default MaturityChart;
