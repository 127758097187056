import { Col, Empty, Pagination, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import MentorCard from '../../../components/MentorCard';
import MentorInvitation from '../../../components/MentorInvitation';
import Text from '../../../components/Text';
import { useAuth } from '../../../core/hooks/auth';
import useAcceleratorMentor from '../../../core/hooks/useAcceleratorMentor';
import usePagination from '../../../core/hooks/usePagination';
import { Card } from '../../../GlobalStyles';
import ContentBase from '../../templates/ContentBase';

function AcceleratorMentor() {
  const { logged } = useAuth();
  const { loading, mentors, getMentors } = useAcceleratorMentor();
  const { changePagination, currentPage } = usePagination();

  const { name = '', entityId = 0 } = logged ?? {};

  useEffect(() => {
    getMentors(entityId, {
      page: currentPage,
    });
  }, [entityId, currentPage, getMentors]);

  return (
    <ContentBase
      title="Mentores"
      questionIsVisible={false}
      content={
        <Spin spinning={loading}>
          <Card>
            <MentorInvitation totalElements={mentors.totalElements} />
            {isEmpty(mentors.content) && (
              <Empty
                description={
                  <Text weight={500} size={16}>
                    Mentores vazios
                  </Text>
                }
              />
            )}
            <Row wrap={true} gutter={[12, 12]} align={'middle'}>
              {mentors.content.map((mentor) => (
                <Col key={`mentor_key_${mentor.entityId}`}>
                  <MentorCard
                    office="Design Thiking"
                    skills={[
                      'UI',
                      'UX',
                      'Identidade',
                      'Tipografia',
                      'Heuristicas',
                    ]}
                    name={mentor.user?.name ?? ''}
                    company={name}
                  />
                </Col>
              ))}
            </Row>

            <Pagination
              style={{
                padding: '1rem',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              total={mentors.totalElements}
              pageSize={mentors.size}
              onChange={changePagination}
            />
          </Card>
        </Spin>
      }
    />
  );
}

export default AcceleratorMentor;
