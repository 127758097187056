import React, { useState } from 'react';
import { useModal } from 'sunflower-antd';
import { useLocation, useParams } from 'react-router-dom';
import StartupJourneyBase from '../../../../components/StartupJourneyBase';
import CourseProgress from '../../StartupDetails/StartupProgress/CourseProgress';
import { IRecommendedCourse } from '../../../../core/interfaces/dashboard-startup/IRecommendedTrail';

function StartupJourney() {
  const { userId: id } = useParams();
  const { visible, show, close } = useModal({
    defaultVisible: false,
  });
  const { state } = useLocation();
  const { startup } = state;

  const [selectedCourse, setSelectedCourse] =
    useState<IRecommendedCourse | null>(null);

  const userId = Number(id);

  return (
    <>
      <StartupJourneyBase
        nameStartup={startup}
        userId={userId}
        showDetails={false}
        selectCourse={(course) => {
          show();
          setSelectedCourse(course);
        }}
      />

      <CourseProgress
        userId={userId}
        onClose={close}
        drawerVisible={visible}
        courseId={selectedCourse?.id}
      />
    </>
  );
}

export default StartupJourney;
