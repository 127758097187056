import { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Switch,
} from 'antd';
import {
  Box,
  BoxContainer,
  BoxLine,
  BoxText,
  Header,
  HeaderDescription,
  HeaderTitle,
  Item,
  LabelSwitch,
  LabelSwitchContainer,
  LinkText,
} from './style';
import api from '../../../core/services/api';
import { ModalContent } from '../../../components/form/diagnosis/style';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ModalBody } from '../../../GlobalStyles';
import { getSectors } from '../../../services/ValuationService';
import InputCurrency from '../../../components/Input/InputCurrency';
import InputPercent from '../../../components/Input/InputPercent';
import notificationMessage from '../../../components/notifications/notificationMessage';
import { IDCF } from '../../../core/interfaces/IDCF';
import {
  convertCurrencyMaskInputToNumber,
  convertNumberToString,
} from '../../../helpers/convertion.helper';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../../components/Loading/Loading';
import AlertMessage from '../../../components/Alert/AlertMessage';
import { QuestionOutlined } from '@ant-design/icons';
import iconRocket from '../../../assets/icons/icon_new_startup.png';
import { onlyNumbers } from '../../../helpers/string.helper';
import { plainMoney } from '../../../helpers/mask.helper';
import { isAxiosError } from 'axios';

interface IParams {
  params?: any;
  next?: string;
  setFormName: (formName: string) => void;
}

function CashFlow(props: IParams) {
  const [form] = Form.useForm<IDCF>();
  const year = new Date().getFullYear();
  const { Option } = Select;
  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();
  const [isModalFinishedVisible, setIsModalFinishedVisible] = useState(false);
  const [dcf, setDcf] = useState<IDCF>();
  const { loading, showLoading, hideLoading } = useLoading();
  const [alertMessage, setAlertMessage] = useState(false);
  const [projection, setProjection] = useState(false);
  const [projectionInfoModal, setProjectionInfoModal] = useState(false);

  const valuation = state?.entityId;
  const [presumption, setPresumption] = useState([
    {
      name: 'Lucro real',
      value: 0,
    },
    {
      name: 'Lucro presumido',
      value: 1,
    },
  ]);
  const [regimeTribute, setRegimeTribute] = useState([
    {
      name: '8%',
      value: 0.8,
    },
    {
      name: '16%',
      value: 0.16,
    },
    {
      name: '32%',
      value: 0.32,
    },
  ]);
  const [sectors, setSectors] = useState([]);
  const Items = [
    {
      label: 'Receita bruta',
      name: 'grossRevenue',
      negative: false,
    },
    {
      label: 'Imposto sobre receita',
      name: 'incomeTax',
      negative: true,
    },
    {
      label: 'Custo de mercadoria vendida',
      name: 'coastMerchandiseSold',
      negative: true,
    },
    {
      label: 'Despesas',
      name: 'expenses',
      negative: true,
    },
    {
      label: 'Depreciação',
      name: 'depreciation',
      negative: true,
    },
    {
      label: 'Receitas financeiras',
      name: 'financialIncome',
      negative: false,
    },
    {
      label: 'Despesas financeiras',
      name: 'financialExpenses',
      negative: true,
    },
    {
      label: 'IRPJ',
      name: 'irpj',
      negative: true,
    },
    {
      label: 'CSLL',
      name: 'csll',
      negative: true,
    },
  ];

  const onFinish = (values: any) => {
    let dcfSave;

    if (projection) {
      dcfSave = {
        entityId: dcf?.entityId,
        active: dcf?.active,
        dcfList: [
          {
            entityId: dcf && dcf?.dcfList[0]?.entityId,
            active: dcf && dcf?.dcfList[0]?.active,
            year: year,
            ...values[year],
          },
        ],
        icms: convertCurrencyMaskInputToNumber(values.icms),
        presumption: values?.presumption,
        sector: { entityId: values?.sector },
        taxRegime: values?.taxRegime,
        avgCustomerReceiptTerm: values?.avgCustomerReceiptTerm,
        avgInventoryTerm: values?.avgInventoryTerm,
        avgSupplierPaymentTerm: values?.avgSupplierPaymentTerm,
        valuationResult: { entityId: state?.entityId },
        iss: convertCurrencyMaskInputToNumber(values?.iss),
        projectedRevenueGrowth: convertCurrencyMaskInputToNumber(
          values.projectedRevenueGrowth,
        ),
      };
    } else {
      dcfSave = {
        entityId: dcf?.entityId,
        active: dcf?.active,
        dcfList: [
          {
            entityId: dcf && dcf?.dcfList[0]?.entityId,
            active: dcf && dcf?.dcfList[0]?.active,
            year: year - 1,
            ...values[year - 1],
          },
          {
            entityId: dcf && dcf?.dcfList[1]?.entityId,
            active: dcf && dcf?.dcfList[1]?.active,
            year: year - 2,
            ...values[year - 2],
          },
          {
            entityId: dcf && dcf?.dcfList[2]?.entityId,
            active: dcf && dcf?.dcfList[2]?.active,
            year: year - 3,
            ...values[year - 3],
          },
        ],
        icms: convertCurrencyMaskInputToNumber(values.icms),
        presumption: values?.presumption,
        sector: { entityId: values?.sector },
        taxRegime: values?.taxRegime,
        avgCustomerReceiptTerm: values?.avgCustomerReceiptTerm,
        avgInventoryTerm: values?.avgInventoryTerm,
        avgSupplierPaymentTerm: values?.avgSupplierPaymentTerm,
        valuationResult: { entityId: state?.entityId },
        iss: convertCurrencyMaskInputToNumber(values?.iss),
        projectedRevenueGrowth: convertCurrencyMaskInputToNumber(
          values.projectedRevenueGrowth,
        ),
      };
    }

    dcfSave.dcfList.forEach((item: any) => {
      item.grossRevenue = convertCurrencyMaskInputToNumber(item.grossRevenue);
      item.incomeTax = convertCurrencyMaskInputToNumber(item.incomeTax);
      item.coastMerchandiseSold = convertCurrencyMaskInputToNumber(
        item.coastMerchandiseSold,
      );
      item.expenses = convertCurrencyMaskInputToNumber(item.expenses);
      item.depreciation = convertCurrencyMaskInputToNumber(item.depreciation);
      item.financialIncome = convertCurrencyMaskInputToNumber(
        item.financialIncome,
      );
      item.financialExpenses = convertCurrencyMaskInputToNumber(
        item.financialExpenses,
      );
      item.irpj = convertCurrencyMaskInputToNumber(item.irpj);
      item.csll = convertCurrencyMaskInputToNumber(item.csll);
    });

    //deinir endpoint para finalizaer e gerar o resultado
    api
      .post('dcf/list', dcfSave, { params: { offer: props.params } })
      .then(() => {
        notificationMessage('create');

        if (props.next === '8') {
          setIsModalFinishedVisible(true);
        }
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          if (err.response?.data?.errors) {
            const keyErros = Object.keys(err.response.data.errors);

            return notification.error({
              message: 'Não foi possível concluir o valuation',
              description: err.response.data.errors[keyErros[0]],
              duration: 10,
            });
          }
        }

        return notification.error({
          message: 'Não foi possível concluir o valuation',
          description: 'Tente novamente em instantes.',
        });
      });
  };

  function handleCancel() {
    setIsModalFinishedVisible(false);
    return navigate('/valuations/valuation-startup');
  }

  useEffect(() => {
    getSectors().then((response) => {
      setSectors(response);
    });
  }, [sectors.length]);

  useEffect(() => {
    getDcf();
  }, [dcf !== undefined]);

  const getDcf = async () => {
    showLoading();
    await api
      .get('dcf/valuationResult/', {
        params: {
          valuation: state?.entityId,
        },
      })
      .then((response) => {
        setDcf(response.data);
        form.setFieldValue('sector', dcf?.sector?.entityId);
        form.setFieldValue('iss', convertNumberToString(dcf?.iss as number));
        form.setFieldValue('icms', convertNumberToString(dcf?.icms as number));
        form.setFieldValue('presumption', dcf?.presumption as number);
        form.setFieldValue('taxRegime', dcf?.taxRegime);
        form.setFieldValue(
          'avgCustomerReceiptTerm',
          convertNumberToString(dcf?.avgCustomerReceiptTerm as number),
        );
        form.setFieldValue(
          'avgInventoryTerm',
          convertNumberToString(dcf?.avgInventoryTerm as number),
        );
        form.setFieldValue(
          'projectedRevenueGrowth',
          convertNumberToString(dcf?.projectedRevenueGrowth as number),
        );
        form.setFieldValue(
          'avgSupplierPaymentTerm',
          convertNumberToString(dcf?.avgSupplierPaymentTerm as number),
        );

        dcf?.dcfList.forEach((item: any) => {
          item.grossRevenue = convertNumberToString(item.grossRevenue);
          item.incomeTax = convertNumberToString(item.incomeTax);
          item.coastMerchandiseSold = convertNumberToString(
            item.coastMerchandiseSold,
          );
          item.expenses = convertNumberToString(item.expenses);
          item.depreciation = convertNumberToString(item.depreciation);
          item.financialIncome = convertNumberToString(item.financialIncome);
          item.financialExpenses = convertNumberToString(
            item.financialExpenses,
          );
          item.irpj = convertNumberToString(item.irpj);
          item.csll = convertNumberToString(item.csll);
          item.projectedRevenueGrowth = convertNumberToString(
            item.projectedRevenueGrowth,
          );
        });

        form.setFieldValue(dcf?.dcfList[0]?.year || year - 1, dcf?.dcfList[0]);
        form.setFieldValue(dcf?.dcfList[1]?.year || year - 2, dcf?.dcfList[1]);
        form.setFieldValue(dcf?.dcfList[2]?.year || year - 3, dcf?.dcfList[2]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const finishValuation = () => {
    showLoading();
    api
      .post(`valuationResult/finish/${state.entityId}`)
      .then(() => {
        navigate('/valuations/results', {
          state: { id: state?.entityId, role: 'startup' },
        });
      })
      .catch(() => {
        notification.error({
          message: 'Algo deu errado!',
          description: 'Erro',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const openProjectionInfo = () => {
    setProjectionInfoModal(!projectionInfoModal);
  };

  const projectionByYear = () => {
    setProjection(!projection);
  };

  useEffect(() => {
    if (dcf && dcf?.dcfList?.length === 1) {
      setProjection(true);
    }
  }, [dcf]);

  useEffect(() => {
    props.setFormName('formCashFlow');
  }, []);

  return (
    <BoxContainer>
      <Loading spinning={loading} width={300} height={300}>
        <AlertMessage
          visible={projectionInfoModal}
          message="O que é a projeção?"
          description="É a forma de projetar as receitas e as despesas que o seu negócio terá ao término deste período, se você ainda não possui um histórico de receitas e despesas, você pode, com ajuda de um especialista, fazer sua projeção financeira. Apesar de ter um especialista que saiba fazer projeções, é necessário que você saiba o passo a passo para a construção dessa projeção financeira, pois a partir dela, é possível calcular o seu valuation pelo método de venture capital, que compreende a análise da sua projeção pelos investidores com uma taxa de risco que eles consideram relevante, por isso é necessário ter bom senso, ser bastante criterioso e agir com sinceridade na hora de prever os custos."
          textOkButton="Voltar"
          onCancel={() => {
            setProjectionInfoModal(false);
          }}
          onOk={() => {
            setProjectionInfoModal(false);
          }}
        />

        <AlertMessage
          visible={isModalFinishedVisible}
          urlImage={iconRocket}
          message="Valuation gerado com sucesso!"
          description="Por meio do valuation você poderá acompanhar o valor de sua startup no mercado e ter métricas importantes para quando buscar por investimentos."
          textCancelButton="Salvar e sair"
          textOkButton="Finalizar e ver resultado"
          onOk={finishValuation}
          onCancel={handleCancel}
        />

        <BoxLine>
          <LabelSwitchContainer>
            <Switch
              style={{ margin: '0 10px 0 0' }}
              onChange={projectionByYear}
              checked={projection}
            />
            <LabelSwitch
              style={{
                width: '120px',
                fontSize: '14px',
              }}
            >
              {projection ? 'Projeção' : 'Fluxo de caixa'}
            </LabelSwitch>
          </LabelSwitchContainer>
          <QuestionOutlined
            style={{
              borderRadius: '50%',
              border: '1px solid #000',
              color: '#000000',
              padding: '5px',
              margin: '0 10px 0 0',
            }}
            onClick={openProjectionInfo}
          />
          <LinkText
            style={{
              position: 'absolute',
              right: '0',
            }}
            margin={'0 10px 0 0'}
            fontStyle={'italic'}
            fontWeight={700}
            onClick={() => setAlertMessage(true)}
          >
            Não possuo fluxo de caixa
          </LinkText>
        </BoxLine>
        <Divider />
        <Form
          name="formCashFlow"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          autoComplete={'off'}
          style={{
            marginBottom: '40px',
          }}
        >
          <Header>
            {projection ? (
              <>
                <BoxText>
                  <HeaderTitle>Projeção</HeaderTitle>
                  <HeaderDescription>
                    Insira os dados da projeção de fluxo de caixa da sua startup
                  </HeaderDescription>
                </BoxText>
                <Box isVisible={projection}>
                  <Item>&nbsp;</Item>
                  <Item>{year}</Item>
                </Box>
              </>
            ) : (
              <>
                <BoxText>
                  <HeaderTitle>Fluxo de caixa</HeaderTitle>
                  <HeaderDescription>
                    Insira os dados do fluxo de caixa da sua startup
                  </HeaderDescription>
                </BoxText>
                <Box isVisible={!projection}>
                  <Item>&nbsp;</Item>
                  <Item>{year - 3}</Item>
                  <Item>{year - 2}</Item>
                  <Item>{year - 1}</Item>
                </Box>
              </>
            )}
          </Header>
          {Items.map((item: any, index: number) => {
            return (
              <Row key={index} gutter={[4, 4]}>
                {projection ? (
                  <Box isVisible={projection}>
                    <Col span={6}>
                      <b>{item.label}</b>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[`${year}`, item.name]}
                        label=""
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          {
                            validator(_, value) {
                              if (value === undefined || value.trim() === '') {
                                return Promise.resolve();
                              }

                              const valueAsNumber = Number(plainMoney(value));

                              if (valueAsNumber >= 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('O valor informado não é válido.'),
                              );
                            },
                          },
                        ]}
                      >
                        <InputCurrency negative={item.negative} />
                      </Form.Item>
                    </Col>
                  </Box>
                ) : (
                  <Box isVisible={!projection}>
                    <Col span={6}>
                      <b>{item.label}</b>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[`${year - 3}`, item.name]}
                        label=""
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          {
                            validator(_, value) {
                              if (value === undefined || value.trim() === '') {
                                return Promise.resolve();
                              }

                              const valueAsNumber = Number(plainMoney(value));

                              if (valueAsNumber >= 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('O valor informado não é válido.'),
                              );
                            },
                          },
                        ]}
                      >
                        <InputCurrency negative={item.negative} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[`${year - 2}`, item.name]}
                        label=""
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          {
                            validator(_, value) {
                              if (value === undefined || value.trim() === '') {
                                return Promise.resolve();
                              }

                              const valueAsNumber = Number(plainMoney(value));

                              if (valueAsNumber >= 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('O valor informado não é válido.'),
                              );
                            },
                          },
                        ]}
                      >
                        <InputCurrency negative={item.negative} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[`${year - 1}`, item.name]}
                        label=""
                        rules={[
                          { required: true, message: 'Campo obrigatório' },
                          {
                            validator(_, value) {
                              if (value === undefined || value.trim() === '') {
                                return Promise.resolve();
                              }

                              const valueAsNumber = Number(plainMoney(value));

                              if (valueAsNumber >= 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('O valor informado não é válido.'),
                              );
                            },
                          },
                        ]}
                      >
                        <InputCurrency negative={item.negative} />
                      </Form.Item>
                    </Col>
                  </Box>
                )}
              </Row>
            );
          })}
          <div className="boxCalc">Outros campos para o cálculo</div>
          <Row gutter={[4, 4]}>
            <Col span={12}>
              <Form.Item name="sector" label="Setor de atuação">
                <Select
                  showSearch
                  placeholder="Selecione o setor"
                  optionFilterProp="children"
                >
                  {sectors.map((state: any, index: number) => (
                    <Option value={state.entityId} key={index}>
                      {state?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="iss"
                label="ISS"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <InputPercent />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="icms"
                label="ICMS"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <InputPercent />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[4, 4]}>
            <Col span={6}>
              <Form.Item
                name="avgCustomerReceiptTerm"
                label="Prazo médio de recebimento de cliente"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input type="tel" maxLength={2} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="avgInventoryTerm"
                label="Prazo médio de estoque"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input type="tel" maxLength={2} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="avgSupplierPaymentTerm"
                label="Prazo médio de pagamento de fornecedores"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input type="tel" maxLength={2} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="projectedRevenueGrowth"
                label="Crescimento da Receita Projetado"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <InputPercent />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[4, 4]}>
            <Col span={12}>
              <Form.Item
                name="taxRegime"
                label="Regime tributário"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Selecione o regime tributário">
                  {regimeTribute.map((state: any, index: number) => (
                    <Option value={state.value} key={index}>
                      {state?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="presumption"
                label="Presunção"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Selecione a presunção">
                  {presumption.map((state: any, index: number) => (
                    <Option value={state.value} key={index}>
                      {state?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Loading>

      <AlertMessage
        message={'Não possui fluxo de caixa? Não se preocupe!'}
        description={
          'Se você ainda não possui um histórico de receitas e despesas, não se preocupe, você pode gerar seu valuation da mesma forma, mas será um valuation sem valores de projeção e, futuramente, preenchê-lo para verificar o avanço de sua startup. Porém, se você deseja fazer a projeção financeira da sua startup, clique em Voltar ao Fluxo de Caixa e após em Projeção Financeira.'
        }
        visible={alertMessage}
        textOkButton={'Entendi'}
        textCancelButton={'Voltar para o fluxo de caixa'}
        onCancel={() => setAlertMessage(false)}
        onOk={() => {
          setAlertMessage(false);
          setIsModalFinishedVisible(true);
        }}
      />
    </BoxContainer>
  );
}

export default CashFlow;
