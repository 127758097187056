import styled, { css } from 'styled-components';

const Title = styled.h2<{
  fontSize?: string;
  maxWidth?: string;
}>`
  color: var(--primary-color);
  font-size: ${({ fontSize }) => fontSize || '20px'};
  font-weight: 700;
  margin: 0;
  max-width: ${({ maxWidth }) => maxWidth};
  font-family: 'Segoe UI', sans-serif;
`;

const Box = styled.div<{
  isBlocked?: boolean;
}>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 2px;
    border-radius: 4px;
    background-color: #9ba5a9;
    margin-top: 4px;
  }

  ${({ isBlocked }) =>
    isBlocked &&
    css`
      pointer-events: none;
      user-select: none;

      &::before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(238 238 238 / 60%);
        position: absolute;
        z-index: 2;
      }
    `}
`;

const Card = styled.div`
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #05233d29;
`;

const CardItem = styled.div`
  & > strong {
    color: var(--primary-color);
    font-family: 'Segoe UI';
    font-size: 15px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export { Title, Box, Card, CardItem, Actions };
