import React from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { BoxCheckbox } from './style';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IProps {
  name: number;
  question: string;
  changeBox(name: string, value: boolean): void;
}

function MultipleChoiceForm({ name, question, changeBox }: IProps) {
  const questionCheckBoxHandle = (
    e: CheckboxChangeEvent,
    questionKey: number,
  ) => {
    changeBox(`questionValue-${questionKey}`, e.target.checked);
  };

  return (
    <>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <Form.Item
            name={[name, 'question']}
            initialValue={question}
            label={`Pergunta ${name + 1}`}
            rules={[
              { required: true, message: 'Pergunta é um campo obrigatório!' },
              {
                max: 1024,
                message: 'O campo deve possuir no máximo 1024 caracteres',
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      {Array.from(Array(5)).map((notNeeded, questionKey) => (
        <Row key={questionKey} align="bottom" gutter={[4, 4]} wrap={false}>
          <Col flex={1}>
            <Form.Item
              name={[name, `questionOption-${questionKey}`]}
              label={`Opção ${questionKey + 1}`}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input placeholder="digite a opção de resposta" maxLength={255} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={[name, `checkbox_${questionKey}`]}>
              <BoxCheckbox title="Alternativa correta">
                <Checkbox
                  key={`checkbox_${questionKey}`}
                  onChange={(e) => questionCheckBoxHandle(e, questionKey)}
                />
              </BoxCheckbox>
            </Form.Item>
          </Col>
        </Row>
      ))}
    </>
  );
}

export default MultipleChoiceForm;
