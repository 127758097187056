import Title from '../../components/Title';
import { BoxContainer } from '../../GlobalStyles';

function Notifications() {
  return (
    <BoxContainer>
      <Title size={20} margin="0 0 10px 0">
        Notificações
      </Title>
      <BoxContainer noPadding={true} />
    </BoxContainer>
  );
}

export default Notifications;
