import { Col, Row, Spin } from 'antd';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import IEntrepreneurData from '../../../../core/interfaces/IEntrepreneurData';
import { Card } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import ProfileDashboardService from '../../../../services/profile-dashboard/ProfileDashboardService';

function EntrepreneurData() {
  const { loading, hideLoading, showLoading } = useLoading();

  const { logged } = useAuth();
  const { entityId = 0 } = logged ?? {};

  const [information, setInformation] = useState<IEntrepreneurData>({
    area: '...',
    cellPhone: '...',
    cpf: '...',
    name: '...',
    office: '...',
  });

  const profileDashboardService = useMemo(
    () => ProfileDashboardService.init(),
    [],
  );

  const getInformation = useCallback(() => {
    showLoading();
    profileDashboardService
      .getEntrepreneurData(entityId)
      .then((data) => setInformation(data))
      .catch(() => {
        notification.info('Erro ao listar dados do empreendedor');
      })
      .finally(hideLoading);
  }, [entityId, profileDashboardService, showLoading, hideLoading]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const metaData = [
    {
      label: 'Nome completo',
      value: information.name,
    },
    {
      label: 'CPF',
      value: information.cpf,
    },
    {
      label: 'Número',
      value: information.cellPhone,
    },
    {
      label: 'Área na organização',
      value: information.area,
    },
    {
      label: 'Cargo ocupado',
      value: information.office,
    },
  ];

  return (
    <Spin spinning={loading}>
      <Card boxShadow="0px 3px 6px #05233D29" padding="12px 22px">
        <Title size={20} tag="h2" margin="0 0 12px 0">
          Dados do empreendedor
        </Title>
        <Row wrap={true} gutter={[22, 22]}>
          {metaData.map((data, index) => (
            <Col key={`key_entrepreneur_${index}`}>
              <Text margin="0 0 6px 0" weight={600}>
                {data.label}
              </Text>
              <Text color="#9AA4A8" weight={600}>
                {data.value}
              </Text>
            </Col>
          ))}
        </Row>
      </Card>
    </Spin>
  );
}

export default EntrepreneurData;
