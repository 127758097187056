import React from 'react';
import { Popover, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { StatusContent } from './styles';

interface IProps {
  info?: string;
  color?: string;
  status?: string;
}

function Status(props: IProps) {
  const content = (
    <>
      <p className={'text'}>{props.info}</p>
    </>
  );

  return (
    <StatusContent>
      <Tag className={'status'} color={props.color}>
        {props.status}
      </Tag>
      {props.info && (
        <Popover placement="top" content={content} arrowPointAtCenter>
          <InfoCircleOutlined />
        </Popover>
      )}
    </StatusContent>
  );
}

export default Status;
