import React, { useEffect, useState } from 'react';
import Participants from './Participants';
import SelectedConversation from './SelectedConversation';
import NoConversationSelected from './NoConversationSelected';
import { Wrapper } from './styles';
import SockJS from 'sockjs-client';
import Stomp, { Client } from 'webstomp-client';
import useChat from '../../core/hooks/useChat';

type Props = {
  senderId: number;
};

function Chat({ senderId }: Props) {
  const {
    participant,
    participants,
    getParticipants,
    updateParticipant,
    removeParticipant,
    changeParticipant,
  } = useChat();

  const [stompEnabled, setStompEnabled] = useState<Client | null>(null);

  useEffect(() => {
    getParticipants(senderId);
  }, [getParticipants, senderId]);

  useEffect(() => {
    const socket = SockJS(`${process.env.REACT_APP_BASE_URL_API}/websocket`);
    const stomp = Stomp.over(socket);

    stomp.connect({}, () => setStompEnabled(stomp));
    return () => {
      socket.close();
    };
  }, []);

  return (
    <Wrapper>
      <Participants
        updateParticipant={updateParticipant}
        participants={participants}
        selected={participant}
        senderId={senderId}
        stomp={stompEnabled}
        getParticipants={getParticipants}
        changeParticipant={changeParticipant}
      />

      {participant ? (
        <SelectedConversation
          senderId={senderId}
          stomp={stompEnabled}
          participant={participant}
          updateParticipant={updateParticipant}
          removeParticipant={removeParticipant}
        />
      ) : (
        <NoConversationSelected />
      )}
    </Wrapper>
  );
}

export default Chat;
