import React, { useEffect, useState } from 'react';
import ValuationInfo from '../Infos/ValuationInfo';
import ContentBase from '../templates/ContentBase';
import ValuationList from './ValuationList';
import MethodologyService from '../../services/MethodologyService';
import IMethodology from '../../core/interfaces/models/IMethodology';
import { useAuth } from '../../core/hooks/auth';
import Loading from '../../components/Loading/Loading';
import AlertMessage from '../../components/Alert/AlertMessage';
import { useNavigate } from 'react-router-dom';

function Valuation() {
  const methodologyService = MethodologyService.init();
  const [isVerify, setIsVerify] = useState(false);
  const navigate = useNavigate();

  const { logged } = useAuth();
  const id = logged?.entityId;

  const verifyMethodology = async () => {
    methodologyService.verifyMethodology(Number(id)).then((response) => {
      setIsVerify(!response);
    });
  };

  useEffect(() => {
    verifyMethodology();
  }, []);

  return (
    <>
      <ContentBase
        title="Valuation"
        modalContent={<ValuationInfo />}
        content={<ValuationList />}
      />

      <AlertMessage
        message={
          'Você não possui metodologia cadastrada. Por favor, cadastre uma metodologia para continuar.'
        }
        description={'Clique aqui para cadastrar uma metodologia'}
        visible={isVerify}
        textOkButton={'Cadastrar'}
        onCancel={() => navigate('/methodologies')}
        onOk={() => navigate('/methodologies')}
      />
    </>
  );
}

export default Valuation;
