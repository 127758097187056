import { Col, Empty, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import CardStartup from '../../../components/CardStartup';
import MentorCard from '../../../components/MentorCard';
import MentorInvitation from '../../../components/MentorInvitation';
import ProfileHeader from '../../../components/Profiles/ProfileHeader';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { CONVENTIONAL_FORMAT } from '../../../constants/date-formats';
import { useAuth } from '../../../core/hooks/auth';
import useAcceleratorMentor from '../../../core/hooks/useAcceleratorMentor';
import { Card } from '../../../GlobalStyles';
import FormClientProfile from './FormClientProfile';

function ClientProfile() {
  const { logged } = useAuth();
  const { loading, mentors, getMentors } = useAcceleratorMentor();

  const { name = '', entityId = 0 } = logged ?? {};

  useEffect(() => {
    getMentors(entityId, {
      size: 5,
    });
  }, [entityId, getMentors]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProfileHeader
            name={logged?.name}
            description={logged?.description}
            dateExpiration={moment(logged?.expiration).format('DD/MM/YYYY')}
            metaData={[
              {
                label: 'Entrada',
                value: moment(logged?.creation).format(CONVENTIONAL_FORMAT),
              },
              {
                label: 'Última atualização',
                value: moment(logged?.lastUpdated).format(CONVENTIONAL_FORMAT),
              },
              {
                label: 'Status atual',
                value: logged?.active ? 'Ativo' : 'Inativo',
              },
              {
                label: 'E-mail',
                value: logged?.email,
              },
            ]}
          >
            {({ onCancel }) => <FormClientProfile onCancel={onCancel} />}
          </ProfileHeader>
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Card>
              <Title size={18}>Mentores vinculados</Title>
              <MentorInvitation totalElements={mentors.totalElements} />
              {isEmpty(mentors.content) && (
                <Empty
                  description={
                    <Text weight={500} size={16}>
                      Mentores vazios
                    </Text>
                  }
                />
              )}

              <Row wrap={true} gutter={[12, 12]} align={'middle'}>
                {mentors.content.map((mentor) => (
                  <Col key={`mentor_key_${mentor.entityId}`}>
                    <MentorCard
                      office="Design Thiking"
                      skills={[
                        'UI',
                        'UX',
                        'Identidade',
                        'Tipografia',
                        'Heuristicas',
                      ]}
                      name={mentor.user?.name ?? ''}
                      company={name}
                    />
                  </Col>
                ))}
              </Row>
            </Card>
          </Spin>
        </Col>
        <Col span={24}>
          <CardStartup />
        </Col>
      </Row>
    </>
  );
}

export default ClientProfile;
