import { Button, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { PurpleButton } from '../../../GlobalStyles';
import MentoringService from '../../../services/MentoringService';
import { FormInstance } from 'antd/es/form';
import { useAuth } from '../../../core/hooks/auth';
import { IMentoring } from '../../../core/interfaces/IMentoring';

interface IProps {
  showModal: () => void;
  loadMentoringList: () => void;
  initialValues?: IMentoring;
}

function MentorshipForm({
  showModal,
  loadMentoringList,
  initialValues,
}: IProps) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const mentoringService = MentoringService.init();
  const { logged } = useAuth();

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));
    const result: IMentoring = {
      title: valueMap.get('mentorshipTheme') as string,
      description: valueMap.get('description') as string,
      priceHour: valueMap.get('price') as number,
    };

    return result;
  };

  const onFinish = (values: FormInstance) => {
    mentoringService
      .createMentoring(arrageValues(values), Number(logged?.entityId))
      .then(() => {
        notification.success({
          message: 'Mentoria criada com sucesso!',
          description: 'Sua mentoria foi criada com sucesso',
        });
        showModal();
        loadMentoringList();
        form.resetFields();
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao criar mentoria',
          description: 'Ocorreu um erro ao criar sua mentoria',
        });
      });
  };

  const onUpdate = (values: FormInstance) => {
    mentoringService
      .updateMentoringById(
        Number(initialValues?.entityId),
        arrageValues(values),
      )
      .then(() => {
        notification.success({
          message: 'Mentoria atualizada com sucesso!',
          description: 'Sua mentoria foi atualizada com sucesso',
        });
        showModal();
        loadMentoringList();
        form.resetFields();
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao atualizar mentoria',
          description: 'Ocorreu um erro ao atualizar sua mentoria',
        });
      });
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        mentorshipTheme: initialValues.title,
        description: initialValues.description,
        price: initialValues.priceHour,
      });
    }
  }, [initialValues]);

  return (
    <>
      <Form
        form={form}
        name="formMentor"
        onFinish={
          initialValues === null || initialValues === undefined
            ? onFinish
            : onUpdate
        }
        layout="vertical"
      >
        <Form.Item
          label="Tema da mentoria"
          name="mentorshipTheme"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input maxLength={65} placeholder="nome da mentoria" />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <TextArea
            showCount
            rows={2}
            placeholder="descrição da mentoria"
            maxLength={255}
          />
        </Form.Item>
        <Form.Item
          label="Preço por hora"
          name="price"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input type={'number'} placeholder="R$0,00" />
        </Form.Item>
        <PurpleButton
          type="primary"
          htmlType="submit"
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '10px',
          }}
        >
          {initialValues === null || initialValues === undefined
            ? 'Criar'
            : 'Atualizar'}
        </PurpleButton>
        <Button
          type="default"
          style={{
            width: '200px',
            marginLeft: 'calc(50% - 100px)',
            marginTop: '5px',
          }}
          onClick={() => {
            showModal();
          }}
        >
          Cancelar
        </Button>
      </Form>
    </>
  );
}

export default MentorshipForm;
