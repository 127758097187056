import React, { useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import {
  ButtonContainer,
  FormContainer,
  LostPassword,
  RegisterBox,
} from '../style';
import { Link, useNavigate } from 'react-router-dom';
import { ILogin, IToken } from '../../../core/interfaces';
import { useAuth } from '../../../core/hooks/auth';
import BaseScreen from '..';
import jwtDecode from 'jwt-decode';
import AlertMessage from '../../../components/Alert/AlertMessage';

function Login() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { signIn } = useAuth();

  const onFinish = (values: ILogin) => {
    setLoading(true);
    const login = signIn(values);

    login
      .then(() => {
        const jwtToken = localStorage.getItem('@innovation:auth');
        if (
          jwtToken != null &&
          jwtDecode<IToken>(jwtToken).status === 'FORCED_CHANGE_PASSWORD'
        ) {
          notification.warning({
            message: 'Opa!',
            description: 'É necessário cadastrar nova senha!',
          });
          navigate('/renew-password');
        }
        // else if (jwtToken != null && jwtDecode<IToken>(jwtToken).status === 'PENDING') {
        // 	notification.warning({
        // 		message: 'Atenção!',
        // 		description: 'Você precisa concluír seu cadastro!'
        // 	})
        // 	navigate('/pre-register')
        // }
        else {
          notification.success({
            message: 'Tudo certo!',
            description: 'Login efetuado com sucesso!',
          });
          navigate('/');
        }
      })
      .catch(() => {
        setVisible(true);
        setMessage('Usuário e/ou senha inválidos!');

        /*notification.error({
          message: 'Erro ao efetuar login oifhiofhiohfio',
          description: error.response?.data?.message || 'Algo deu errado ao logar!'
        })*/
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BaseScreen
      title="Login"
      content={
        <FormContainer>
          <Form name="loginForm" layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor insira um email válido!',
                },
              ]}
            >
              <Input placeholder="Digite seu e-mail" maxLength={100} />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira sua senha!',
                },
              ]}
            >
              <Input.Password placeholder="Digite sua senha" />
            </Form.Item>
            <LostPassword>
              <Link to="/forgot-password">Esqueci a senha</Link>
            </LostPassword>
            <ButtonContainer>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Acessar
              </Button>
            </ButtonContainer>
            <RegisterBox>
              Não possui cadastro?
              <Link to="/register">Cadastrar</Link>
            </RegisterBox>
          </Form>

          <AlertMessage
            onCancel={() => setVisible(false)}
            textCancelButton={'Fechar'}
            message={'Atenção'}
            description={message}
            visible={visible}
          />
        </FormContainer>
      }
    />
  );
}

export default Login;
