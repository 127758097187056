import {
  Form,
  FormInstance,
  Input,
  notification,
  Spin,
  Tree,
  TreeProps,
} from 'antd';
import React, { useEffect } from 'react';
import useLoading from '../../../core/hooks/useLoading';
import Title from '../../../components/Title';
import { IPropsModal } from '../../../core/interfaces/modal/IPropsModal';
import { ModalBody, PurpleButton } from '../../../GlobalStyles';
import { SubTitle } from '../../admin/profiles/style';
import { IFeature } from '../../../core/interfaces';
import { findRole } from '../../../core/enums';
import { ClientServices } from '../../../services/ClientServices';

interface IProps extends IPropsModal {
  title: string;
  features?: IFeature[];
  id?: number;
  initialValues?: any;
  updateList: () => void;
  onCancel: () => void;
  setInitialValues: (initialValues: any) => void;
}

function ProfileLevelAccelerator(props: IProps) {
  const [form] = Form.useForm();
  const { hideLoading, loading, showLoading } = useLoading();
  const [features, setFeatures] = React.useState<IFeature[]>([]);
  const [featuresChecked, setFeaturesChecked] = React.useState<any[]>([]);
  const clientService = ClientServices.init();

  const checkedKeys = () => {
    const keys: number[] = [];
    props.initialValues?.features?.forEach((feature: IFeature) => {
      keys.push(feature.id);
      feature.subFeatures?.forEach((subFeature) => {
        keys.push(subFeature.id);
      });
    });
    return keys;
  };

  const arrageValues = (values: FormInstance) => {
    const valueMap = new Map(Object.entries(values));

    const result: any = {
      id: props.initialValues?.id || null,
      description: valueMap.get('description') as string,
      features: featuresChecked.map((feature) => {
        return {
          id: feature,
        };
      }),
    };

    return result;
  };

  const onFinish = (values: FormInstance) => {
    showLoading();
    clientService
      .createProfileToMembers(arrageValues(values), Number(props.id))
      .then(() => {
        notification.success({
          message: 'Tudo certo!!!',
          description: 'Perfil de acesso criado com sucesso!',
        });
        props.onCancel();
        props.updateList();
        form.resetFields();
        props.updateList();
      })
      .catch(() =>
        notification.error({
          message: 'Erro!!!',
          description: 'Erro ao criar perfil de acesso',
        }),
      )
      .finally(hideLoading);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    setFeaturesChecked(checkedKeys as any[]);
  };

  useEffect(() => {
    if (props.features && props?.features?.length > 0) {
      setFeatures(props.features);
    }
  }, []);

  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue(props.initialValues);
    }
  }, [props.initialValues]);

  return (
    <ModalBody
      title={props.title}
      width={800}
      footer={null}
      closable={true}
      open={props.visible}
      onCancel={() => {
        props.onCancel();
        form.resetFields();
        props.setInitialValues(undefined);
      }}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form
          name={'inviteNewUserForm'}
          onFinish={onFinish}
          form={form}
          layout={'vertical'}
          autoComplete={'off'}
        >
          <Form.Item
            name={'description'}
            label="Nome do perfil"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="digite o nome do perfil" maxLength={255} />
          </Form.Item>
          <Title size={16}>*Níveis de acesso</Title>
          <SubTitle>
            Delimite até onde esse perfil terá poder de atuação na plataforma.
          </SubTitle>
          <Form.Item style={{ marginTop: '10px' }} label="" name="profileLevel">
            <Tree
              checkable
              defaultCheckedKeys={checkedKeys()}
              onCheck={onCheck}
              treeData={features.map((feature) => {
                return {
                  title: findRole(feature.description),
                  key: feature.id,
                  value: feature.id,
                  children: feature.subFeatures?.map((subFeature) => {
                    return {
                      title: findRole(subFeature.description),
                      key: subFeature.id,
                    };
                  }),
                };
              })}
            />
          </Form.Item>
          <PurpleButton
            type="primary"
            htmlType="submit"
            style={{
              width: '200px',
              marginLeft: 'calc(50% - 100px)',
              marginTop: '10px',
            }}
          >
            Salvar
          </PurpleButton>
        </Form>
      </Spin>
    </ModalBody>
  );
}

export default ProfileLevelAccelerator;
