import React from 'react';
import { InputProps, Input } from 'antd';

interface IProps extends InputProps {
  value?: number;
  onChange?: (value: any) => void;
}

function InputPercent(props: IProps) {
  const maskPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const percent = e.target.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2');

    props.onChange?.(percent);
  };

  return (
    <Input
      {...props}
      type={'text'}
      placeholder={'0,00'}
      suffix={'%'}
      maxLength={props.maxLength || 6}
      onChange={maskPercent}
    />
  );
}

export default InputPercent;
