import styled from 'styled-components';
import { Card } from 'antd';

const CardListCompent = styled(Card)`
  opacity: 1;
  border: 1px solid #9aa4a8;
  word-wrap: break-word;

  :hover {
    border-color: #960096;
    box-shadow: 0 0 3px #c0c0c0;
  }
`;

export default CardListCompent;
