import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { BoxBread } from './style';
import RoutesName from '../../core/routes/RoutesName';

interface Params {
  location: any;
}

function BreadCrumb(props: Params) {
  const breadCrumbView = () => {
    const location = props.location;
    const { pathname } = location;
    const pathnames = pathname.split('/').filter((item: any) => item);
    return (
      <BoxBread>
        <Breadcrumb separator="&#9679;">
          {
            <Breadcrumb.Item key="home">
              <Link to="/">Início</Link>
            </Breadcrumb.Item>
          }
          {pathnames.map((item: any, index: number) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const last = index === pathnames.length - 1;
            const route = RoutesName(item);
            return last ? (
              <Breadcrumb.Item key={item}>{route}</Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={item}>
                <Link to={routeTo}>{route}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </BoxBread>
    );
  };

  return <>{breadCrumbView()}</>;
}

export default BreadCrumb;
