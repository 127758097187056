import { IAuth, IUser } from '../core/interfaces';
import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IProfessional } from '../core/interfaces/professional/IProfessional';

type UserStatus =
  | 'ENABLED'
  | 'DISABLED'
  | 'BLOCKED'
  | 'FORCED_CHANGE_PASSWORD'
  | 'PENDING'
  | 'PENDING_VALIDATION';
type idAction = 0 | 1 | 5;
class UserService extends CrudService {
  constructor() {
    super({
      requestMapping: '/user',
    });
  }

  public static init(): UserService {
    return new UserService();
  }

  public async getAllUsers(): Promise<IUser[]> {
    return api
      .get<IUser[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async deleteUser(id: number): Promise<IUser> {
    return api
      .delete<IUser>(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async changeStatus(id: number): Promise<IUser> {
    return api
      .put<IUser>(`${this.requestMapping}/change-status/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createUser(
    user: any,
    params: { params: { inviter: any } },
  ): Promise<any> {
    return api
      .post<any>(`${this.requestMapping}`, user, params)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createProfessional(user: IProfessional): Promise<any> {
    return api
      .post<IProfessional>(`${this.requestMapping}/professional`, user)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public updateMyUserProfile(id: number, data: Record<string, unknown>) {
    return api
      .put<IAuth>(`${this.requestMapping}/my-profile/${id}`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllUsersByStatus(
    status: UserStatus,
    active: boolean,
  ): Promise<IUser[]> {
    return api
      .get<IUser[]>(`${this.requestMapping}/status/${status}`, {
        params: {
          active,
        },
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllUsersByProfilesIsNull(): Promise<IUser[]> {
    return api
      .get<IUser[]>(`${this.requestMapping}/rejected`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async changeStatusUser(id: number, idAction: idAction) {
    return api
      .put(`${this.requestMapping}/action/${id}?idAction=${idAction}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default UserService;
