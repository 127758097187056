import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import { BoxContent, Content, Header } from './style';

interface IParams {
  title: string;
  subTitle: string;
  description?: string;
  content: any;
  current: number;
  setCurrent: (current: number) => void;
  setButtonState: (state: boolean) => void;
  setFormName: (name: string) => void;
  subStep?: number;
  setSubStep?: (subStep: number) => void;
}

function Profiles(props: IParams) {
  const goBack = () => {
    if (props.subStep && props.subStep > 0) {
      props.setSubStep && props.setSubStep(props.subStep - 1);
    } else {
      props.setCurrent(props.current - 1);
      props.setFormName('');
      props.setButtonState(true);
    }
  };

  return (
    <BoxContent>
      <div className="goBack" onClick={goBack}>
        <LeftOutlined />
        Voltar
      </div>
      <Header>
        <div className="title">{props.title}</div>
        <div className="subTitle">{props.subTitle}</div>
        {props.description && (
          <div className="description">{props.description}</div>
        )}
      </Header>
      <Content>{props.content}</Content>
    </BoxContent>
  );
}

export default Profiles;
