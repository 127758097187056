import styled from 'styled-components';

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  .site-navigation-steps,
  .site-navigation-steps {
    margin-bottom: 60px;
    box-shadow: 0px -1px 0 0 #e8e8e8 inset;
  }

  .ant-setps-navigation {
    padding-top: 0;
  }

  .steps-content {
    margin-top: 0;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 2px;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;

  & img {
    width: 100px;
  }

  & div {
    text-align: justify;
  }
`;

export { BoxContent, Header };
