import styled from 'styled-components';

const VideoWrapper = styled.video`
  margin: 1rem 0;
`;

const ResetMarginDescription = styled.div`
  & > p {
    margin: 0;
  }
`;

export { VideoWrapper, ResetMarginDescription };
