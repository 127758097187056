import React from 'react';
import { Row, Col, Card } from 'antd';
import { WeightMethod } from './styles';
import { Column } from '@ant-design/plots';
import Title from '../../../../components/Title';
import { maskMoney } from '../../../../helpers/mask.helper';
import Text from '../../../../components/Text';
import { ChartWrapper } from '../../style';

interface IParams {
  scorecard?: number;
  vc?: number;
  berkus: number;
  pepertuide: number;
  multiples: number;
  scorecardWeigth?: number;
  vcWeigth?: number;
  berkusWeigth: number;
  pepertuideWeigth: number;
  multiplesWeigth: number;
}
function ValuationMethodsChart(props: IParams) {
  const data = [
    {
      name: 'Scorecard',
      value: Number(props.scorecard),
      percentage: props.scorecardWeigth,
    },
    {
      name: 'Berkus',
      value: Number(props.berkus),
      percentage: props.berkusWeigth,
    },
    {
      name: 'Venture Capital',
      value: Number(props.vc),
      percentage: props.vcWeigth,
    },
    {
      name: 'Perpetuidade',
      value: Number(props.pepertuide),
      percentage: props.pepertuideWeigth,
    },
    {
      name: 'Múltiplos',
      value: Number(props.multiples),
      percentage: props.multiplesWeigth,
    },
  ];

  const config: any = {
    data,
    xField: 'name',
    yField: 'value',
    color: '#960096',
    tooltip: true,
    autoFit: true,
    padding: 'auto',
    meta: {
      value: {
        formatter: (v: number) => maskMoney(v),
      },
    },
    label: {
      style: {
        fill: '#FFFFFF',
        opacity: 1,
        fontSize: 12,
        fontWeight: 600,
        fontFamily: 'Arial',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#05233D',
          fontWeight: 100,
          fontSize: 14,
          fontFamily: 'Arial',
        },
      },
    },
    yAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#05233D',
          fontWeight: 900,
          fontSize: 16,
          fontFamily: 'Arial',
        },
      },
    },
  };

  const chart = <Column {...config} />;

  return (
    <Card
      bodyStyle={{ overflow: 'auto' }}
      style={{ border: '1px solid #9AA4A8' }}
    >
      <div style={{ minHeight: '400px' }}>
        <ChartWrapper chart="view">{chart}</ChartWrapper>
        <ChartWrapper
          width="58%"
          widthMd="84%"
          left={0}
          right={0}
          chart="print"
        >
          {chart}
        </ChartWrapper>
      </div>
      <Title align="center" size={20} margin="33px 0 10px 60px">
        Pesos dos Métodos
      </Title>
      <Row style={{ marginLeft: '55px' }} gutter={4}>
        {data.map((item) => (
          <Col
            flex={1}
            style={{ textAlign: 'center' }}
            key={`percentage_key_${item.name}`}
          >
            <WeightMethod min={0} max={100} value={item.percentage} />
            <Text weight={500}>{`${item.percentage}%`}</Text>
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default ValuationMethodsChart;
