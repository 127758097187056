import Text from '../Text';
import Title from '../Title';
import { AddUser } from '../Icons/Icon';
import Loading from '../Loading/Loading';
import { useModal } from 'sunflower-antd';
import ButtonIcon from '../Icons/ButtonIcon';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../core/hooks/auth';
import { maskMoney } from '../../helpers/mask.helper';
import { Col, Divider, Empty, Row, Space } from 'antd';
import { BoxContainer, Card } from '../../GlobalStyles';
import StartupService from '../../services/StartupService';
import { notification } from '../../helpers/notification.helper';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { StartupListWrapper } from '../../pages/client/Dashboard/styles';
import IDashboardResponse from '../../core/interfaces/models/IDashboardResponse';
import StartupInvitationModal from '../../pages/client/Dashboard/StartupInvitationModal';
import useLoading from '../../core/hooks/useLoading';
import { convertDateToString } from '../../helpers/dates.helper';

function CardStartup() {
  const { logged } = useAuth();
  const navigate = useNavigate();
  const startupService = useMemo(() => StartupService.init(), []);
  const invitationModal = useModal({
    defaultVisible: false,
  });

  const { loading, showLoading, hideLoading } = useLoading();
  const [startups, setStartups] = useState<IDashboardResponse[]>([]);

  const getStartups = useCallback(() => {
    showLoading();
    startupService
      .getAllByUser(logged?.entityId ?? 0)
      .then((data) => setStartups(data))
      .catch(() => notification.error('Erro ao listar startups'))
      .finally(hideLoading);
  }, [logged?.entityId, startupService]);

  const redirect = (id?: number): void => {
    navigate('/dashboard/startup/', {
      state: {
        id,
      },
    });
  };

  useEffect(() => {
    getStartups();
  }, [getStartups]);

  return (
    <>
      <StartupInvitationModal {...invitationModal.modalProps} />

      <Loading spinning={loading} width={200} height={200}>
        <Card>
          <Row align="middle" justify={'space-between'} gutter={16}>
            <Col>
              <Title size={20}>Startups ({startups.length})</Title>
            </Col>
            <Col flex={1}>
              <Divider />
            </Col>
            <Col>
              <ButtonIcon
                color={'primary'}
                icon={<AddUser />}
                iconHeight={'18px'}
                iconWidth={'18px'}
                onClick={invitationModal.show}
              />
            </Col>
          </Row>
          {startups.length > 0 ? (
            <Space
              direction="vertical"
              size="large"
              style={{ display: 'flex', marginTop: '16px' }}
            >
              <StartupListWrapper>
                {startups.map((startup) => (
                  <div
                    onClick={() => redirect(startup?.entityId)}
                    key={`key_startup_${startup?.entityId}`}
                  >
                    <Text weight={900}>{startup?.name}</Text>
                    <div>
                      <Text weight={600}>Última atualização</Text>
                      <Text size={14} color="#9AA4A8">
                        {convertDateToString(startup?.lastUpdated as string)}
                      </Text>
                    </div>
                    <div>
                      <Text weight={600}>Fase</Text>
                      <Text size={14} color="#9AA4A8">
                        {startup?.phase}
                      </Text>
                    </div>
                    <div>
                      <Text weight={600}>Valuation</Text>
                      <Text size={14} color="#9AA4A8">
                        {startup.preMoney
                          ? maskMoney(startup.preMoney)
                          : 'Não possui valuation'}
                      </Text>
                    </div>
                  </div>
                ))}
              </StartupListWrapper>
            </Space>
          ) : (
            <BoxContainer noPadding={true}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Não existem dados cadastrados'}
              />
            </BoxContainer>
          )}
        </Card>
      </Loading>
    </>
  );
}

export default CardStartup;
