import api from '../core/services/api';
import CrudService from './CrudService';
import IVacancies from '../core/interfaces/IVacancies';
import { errorCallback } from '../helpers/promise.helper';
import IPageable from '../core/interfaces/IPageable';

class VacanciesService extends CrudService {
  public static init() {
    return new VacanciesService({
      requestMapping: '/vacancies',
    });
  }

  public findByUserId(id: number, params: Record<string, unknown> = {}) {
    return api
      .get<IPageable<IVacancies>>(`${this.requestMapping}/by-user/${id}`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default VacanciesService;
