import { Col, Row, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { useModal } from 'sunflower-antd';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { useAuth } from '../../../../core/hooks/auth';
import useLoading from '../../../../core/hooks/useLoading';
import { IInvestor } from '../../../../core/interfaces/IInvestor';
import { Card, Label } from '../../../../GlobalStyles';
import { notification } from '../../../../helpers/notification.helper';
import InvestorService from '../../../../services/InvestorService';
import BusinessInformationForm from '../BusinessInformationForm';

function BusinessInformationCard() {
  const { logged } = useAuth();
  const { loading, showLoading, hideLoading } = useLoading();
  const modalForm = useModal({
    defaultVisible: false,
  });

  const { entityId = 0 } = logged ?? {};

  const [investor, setInvestor] = useState<IInvestor | null>(null);

  const investorService = useMemo(() => InvestorService.init(), []);

  const getDataInvestor = useCallback(() => {
    showLoading();
    investorService
      .getWithoutUser(entityId)
      .then((data) => setInvestor(data))
      .catch(() => {
        notification.error('Erro ao listar informações de négocio');
      })
      .finally(hideLoading);
  }, [entityId, showLoading, hideLoading, investorService]);

  useEffect(() => {
    getDataInvestor();
  }, [getDataInvestor]);

  const metaData = [
    {
      col: 8,
      label: 'Modelo de négocio',
      value: investor?.businessModels?.description ?? '...',
    },
    {
      label: 'Fases de investimento',
      value: (
        <Row wrap={true} gutter={[16, 16]}>
          {investor?.phaseInvestiment?.map((phase, index) => (
            <Col key={`phase_key_${index}`}>
              <Label>{phase.description}</Label>
            </Col>
          ))}
        </Row>
      ),
    },
    {
      label: 'Áreas de investimento',
      value: (
        <Row wrap={true} gutter={[16, 16]}>
          {investor?.businessTypes?.map((type, index) => (
            <Col key={`type_key_${index}`}>
              <Label>{type.description}</Label>
            </Col>
          ))}
        </Row>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <Card boxShadow="0px 3px 6px #05233D29" padding="12px 22px">
          <Row
            style={{ marginBottom: '12px' }}
            justify="space-between"
            wrap={true}
            gutter={[12, 12]}
            align="middle"
          >
            <Col flex={1}>
              <Title size={20} tag="h2">
                Informações de negócio
              </Title>
            </Col>
            <Col>
              <Tooltip
                title="Editar"
                color="#fff"
                placement="bottomRight"
                overlayClassName="text-tooltip-primary"
              >
                <FiEdit2 cursor="pointer" size={20} onClick={modalForm.show} />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[24, 20]} wrap={true}>
            {metaData.map((data, index) => (
              <Col span={data.col} key={`key_information_${index}`}>
                <Text margin="0 0 6px 0" weight={600}>
                  {data.label}
                </Text>
                <Text color="#9AA4A8" weight={600}>
                  {data.value}
                </Text>
              </Col>
            ))}
          </Row>
        </Card>
      </Spin>

      <BusinessInformationForm
        investor={investor}
        onReload={getDataInvestor}
        {...modalForm.modalProps}
      />
    </>
  );
}

export default BusinessInformationCard;
