import React from 'react';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { BoxList, InvestmentItem, Item } from './style';
import image from '../../../assets/icons/actions-icons/ic-investment-round.svg';
import IInvestmentRound from '../../../core/interfaces/IInvestmentRound';
import {
  convertDateToString,
  countDaysBetweenDateNowAndDate,
  daysBetweenDates,
} from '../../../helpers/dates.helper';
import { useNavigate } from 'react-router-dom';
import OnEmptyOrList from '../../../components/Screen/OnEmptyOrList';
import moment from 'moment';

interface IProps {
  entity: IInvestmentRound[];
}

function InvestmentInProgress({ entity }: IProps) {
  const navigate = useNavigate();

  const openInvestmentRoundDetails = (id: number) => {
    navigate('/investment-round/details', {
      state: { entityId: id },
    });
  };

  const getItem = (
    label: string,
    val: string | number,
    style?: React.CSSProperties,
  ) => {
    return (
      <Item>
        <Text weight={700}>{label}</Text>
        <Text style={style}>
          {typeof val === 'number' && val < 10 ? `0${val}` : val}
        </Text>
      </Item>
    );
  };

  const getStatus = (date: Date) => {
    const now = moment().format('YYYY-MM-DD');
    const dateToCompare = moment(date).format('YYYY-MM-DD');

    if (now >= dateToCompare) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Title size={18}>Rodadas em andamento</Title>
      <BoxList>
        <OnEmptyOrList
          list={entity}
          description="Nenhuma rodada de investimento em andamento"
        >
          {entity.map((item) => {
            return (
              <>
                <InvestmentItem
                  onClick={() =>
                    openInvestmentRoundDetails(item.entityId as number)
                  }
                >
                  <div className="first-item">
                    <img alt="" src={image} />
                    <Text title={item.name} weight={700}>
                      {item.name}
                    </Text>
                  </div>
                  {getItem(
                    'Fase de investimento',
                    item?.phaseInvestment?.description as string,
                  )}
                  {getItem(
                    'Data de início',
                    convertDateToString(item.startDate),
                  )}
                  {getItem(
                    'Data de término',
                    convertDateToString(item.endDate),
                  )}
                  {getItem('Startup cadastradas', 10)},
                  {getItem(
                    'Status',
                    getStatus(item.startDate) ? 'Iniciado' : 'Não iniciado',
                    {
                      color: getStatus(item.startDate)
                        ? 'var(--success-color)'
                        : 'var(--error-color)',
                    },
                  )}
                  ,
                  {getItem(
                    'Dias restantes',
                    daysBetweenDates(item.startDate, item.endDate),
                    { color: 'var(--thirdy-color)', fontWeight: 'bold' },
                  )}
                </InvestmentItem>
              </>
            );
          })}
        </OnEmptyOrList>
      </BoxList>
    </>
  );
}

export default InvestmentInProgress;
