import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Col, Empty, Row } from 'antd';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { PurpleButton } from '../../GlobalStyles';
import ContentBase from '../../pages/templates/ContentBase';
import Title from '../Title';
import { ResetMarginDescription } from './styles';
import { notification } from '../../helpers/notification.helper';
import { useAuth } from '../../core/hooks/auth';
import ProgressService from '../../services/attend-course/ProgressService';
import TopicAccordion from './TopicAccordion';
import ITopic from '../../core/interfaces/course/ITopic';
import ILesson from '../../core/interfaces/course/ILesson';
import TopicWithProgressService from '../../services/topic/TopicWithProgressService';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import CourseService from '../../services/course/CourseService';
import Text from '../Text';
import ReactPlayer from 'react-player';
import IRequestLessonProgress from '../../core/interfaces/attend-course/IRequestLessonProgress';

const INDEX_NOT_FOUND = -1;

function AttendCourse() {
  const { logged } = useAuth();
  const { id } = useParams();
  const courseId = Number(id);
  const navigate = useNavigate();

  const courseService = useMemo(() => CourseService.init(), []);
  const progressService = useMemo(() => ProgressService.init(), []);
  const topicProgressService = useMemo(
    () => TopicWithProgressService.init(),
    [],
  );

  const [name, setName] = useState('');
  const [videoUrl, setVideoUrl] = useState('' as string | null);
  const [lessonId, setLessonId] = useState(0);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [selectedLesson, setSelectedLesson] = useState<ILesson | null>(null);

  const registerProgress = useCallback(
    (lessonId: number) => {
      const request: IRequestLessonProgress = {
        lessonId: lessonId,
        courseId,
        studentId: Number(logged?.entityId),
      };

      progressService
        .lesson(request)
        .then(() => {
          setTopics((prev) => {
            const searchLesson = (lesson: ILesson) => {
              return lesson.entityId === lessonId;
            };

            const topicIndex = prev.findIndex((item) =>
              item.lessons.some(searchLesson),
            );

            const topic = prev[topicIndex];
            const lesson = topic?.lessons?.find(searchLesson);
            if (lesson) {
              lesson.isComplete = true;
            }
            return [...prev];
          });
        })
        .catch(() => notification.info('Erro ao registrar progresso'));
    },
    [logged, courseId, progressService],
  );

  const getTopics = useCallback(() => {
    const studentId = Number(logged?.entityId);
    topicProgressService
      .getByCourse(courseId, studentId)
      .then((data) => setTopics(data));
  }, [topicProgressService, courseId, logged]);

  const getName = useCallback(() => {
    courseService
      .getName(courseId)
      .then((data) => {
        setName(data.name);
        setVideoUrl(data.videoUrl);
      })
      .catch(() => notification.error('Curso não encontrado'));
  }, [courseService, courseId]);

  useEffect(() => {
    if (lessonId) registerProgress(lessonId);
  }, [lessonId, registerProgress]);

  useEffect(() => {
    getTopics();
  }, [getTopics]);

  useEffect(() => {
    getName();
  }, [getName]);

  useEffect(() => {
    const getCurrentLesson = () => {
      if (isEmpty(topics)) return null;

      let index = 0;
      let count = 0;
      let search = true;
      let lesson = null as ILesson | null;

      while (index < topics.length && search) {
        const topic = topics[index];
        const lessonIndex = topic.lessons.findIndex(
          (lesson: ILesson) => !lesson.isComplete,
        );

        if (lessonIndex !== INDEX_NOT_FOUND) {
          search = false;
          const item = topic.lessons[lessonIndex];
          lesson = {
            ...item,
            name: `Aula ${1 + lessonIndex + count}: ${item.name}`,
          };
        } else {
          index++;
          count += topic.lessons.length;
        }
      }

      return lesson;
    };

    setSelectedLesson((oldValue) => {
      if (isNull(oldValue)) {
        return getCurrentLesson();
      } else {
        return oldValue;
      }
    });
  }, [topics]);

  return (
    <ContentBase
      content={
        <>
          <Row gutter={16} align={'middle'}>
            <Col>
              <PurpleButton
                size="small"
                type="primary"
                onClick={() => navigate(-1)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <MdKeyboardArrowLeft size={20} />
              </PurpleButton>
            </Col>
            <Col>
              <Title sizeXs={24} size={32} lineHeight={1.3}>
                {name}
              </Title>
            </Col>
          </Row>

          {isEmpty(name) && (
            <Col span={24}>
              <Empty
                description={
                  <Text size={22} weight={700}>
                    Curso não encontrado
                  </Text>
                }
              />
            </Col>
          )}

          {selectedLesson ? (
            <ReactPlayer
              playsinline={true}
              controls
              id="player"
              width={'100%'}
              height={'500px'}
              url={selectedLesson?.videoUrl ?? ''}
              onPlay={() => setLessonId(selectedLesson?.entityId ?? 0)}
            />
          ) : (
            <ReactPlayer
              playsinline={true}
              controls
              id="player"
              width={'100%'}
              height={'500px'}
              url={videoUrl ?? ''}
            />
          )}

          {selectedLesson && (
            <>
              <Title sizeXs={22} size={28}>
                {selectedLesson.name}
              </Title>
              <ResetMarginDescription
                dangerouslySetInnerHTML={{ __html: selectedLesson.description }}
              />
            </>
          )}

          <TopicAccordion
            topics={topics}
            selectLesson={(lesson) => {
              setSelectedLesson(lesson);
            }}
          />
        </>
      }
    />
  );
}

export default AttendCourse;
