import api from '../core/services/api';
import CrudService from './CrudService';
import IWeightMethodValuation from '../core/interfaces/IWeigthMethodsValuations';
import { errorCallback } from '../helpers/promise.helper';

class WeightMethodValuationService extends CrudService {
  static init(): WeightMethodValuationService {
    return new WeightMethodValuationService();
  }

  constructor() {
    super({
      requestMapping: '/weigthMethodsValuation',
      params: {
        sort: 'creation,desc',
      },
    });
  }

  public save(data: IWeightMethodValuation): Promise<IWeightMethodValuation> {
    return api
      .post<IWeightMethodValuation>(`${this.requestMapping}`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public saveAll(
    data: IWeightMethodValuation[],
  ): Promise<IWeightMethodValuation[]> {
    return api
      .post<IWeightMethodValuation[]>(`${this.requestMapping}/save-all`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public updateAll(
    data: IWeightMethodValuation[],
  ): Promise<IWeightMethodValuation[]> {
    return api
      .put<IWeightMethodValuation[]>(`${this.requestMapping}/update-all`, data)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getAllByClient(clientId: number): Promise<IWeightMethodValuation[]> {
    return api
      .get<IWeightMethodValuation[]>(
        `${this.requestMapping}/client/${clientId}`,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default WeightMethodValuationService;
