import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { exampleReducer } from './example.slice';
import { combineReducers } from 'redux';
import { valuationReducer } from './valuation.slice';
import { registeredCoursesReducer } from './registered-courses.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const reducers = combineReducers({
  example: exampleReducer,
  valuationSlice: valuationReducer,
  registeredCoursesSlice: registeredCoursesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
