import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card } from 'antd';
import startupIco from '../../../../../assets/images/ico-startup.svg';
import speederIco from '../../../../../assets/images/ico-speeder.svg';
import ProfessionalIco from '../../../../../assets/images/ico-professional.svg';
import studentIco from '../../../../../assets/images/ico-student.svg';
import mentorIco from '../../../../../assets/images/ico-mentor.svg';
import investorIco from '../../../../../assets/images/ico-investor.svg';
import Startup from '../box-tip/Startup';
import Speeder from '../box-tip/Speeder';
import Professional from '../box-tip/Professional';
import Student from '../box-tip/Student';
import Mentor from '../box-tip/Mentor';
import Investor from '../box-tip/Investor';
import { CardContent, CardTitle, Introduction, Title } from '../../../style';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ModalBody } from '../../../../../GlobalStyles';

interface IParams {
  profileId: number;
  setProfileId: Dispatch<SetStateAction<number>>;
  setButtonState: (value: boolean) => void;
}

function ProfileOptions(props: IParams) {
  const { Meta } = Card;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [itemContent, setItemContent] = useState<any>();

  const stateModal = () => {
    setModalVisible(!modalVisible);
  };

  const HandleBoxTip = (content: any) => {
    stateModal();
    setItemContent(content);
  };

  const cardHandler = (id: string) => {
    const El = document.getElementById(id);

    if (!El?.classList.contains('selectedCard')) {
      const Selected = document.querySelectorAll('.selectedCard');

      Selected.forEach((box) => {
        box.classList.remove('selectedCard');
      });

      El?.classList.add('selectedCard');
      props.setButtonState(false);
      props.setProfileId(Number(id));
    } else {
      El?.classList.remove('selectedCard');
      props.setButtonState(true);
      props.setProfileId(0);
    }
  };

  const cardConfig = [
    {
      id: 1,
      className: 'card-startup',
      title: (
        <CardTitle>
          Startup
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Startup />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Aceleração, mentorias, cursos de capacitação, valuation e muitas outras ferramentas para o crescimento da sua startup.',
      ico: startupIco,
    },
    {
      id: 2,
      className: 'card-acelerator',
      title: (
        <CardTitle>
          Aceleradora
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Speeder />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Vincule startups, crie seus diagnósticos, coordene sua metodologia e acompanhe o desenvolvimento de suas startups.',
      ico: speederIco,
    },
    {
      id: 3,
      className: 'card-professional',
      title: (
        <CardTitle>
          Profissional
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Professional />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Seja procurado e contratado por startups e aceleradoras que precisam do seu know-how como profissional de alguma área específica.',
      ico: ProfessionalIco,
    },
    {
      id: 4,
      className: 'card-student',
      title: (
        <CardTitle>
          Aluno
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Student />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Aprenda com a Plataforma do Innovation Studio por meio do e-learning e, se desenvolva para nível profissional.',
      ico: studentIco,
    },
    {
      id: 5,
      className: 'card-mentor',
      title: (
        <CardTitle>
          Mentor
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Mentor />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Apresente seu portfólio às startups e seja contratado por alguma aceleradora ou startup.',
      ico: mentorIco,
    },
    {
      id: 6,
      className: 'card-investor',
      title: (
        <CardTitle>
          Investidor
          <div className="tip">
            <InfoCircleOutlined
              onClick={() => {
                HandleBoxTip(<Investor />);
              }}
            />
          </div>
        </CardTitle>
      ),
      description:
        'Visualize o perfil das startups que pretende investir, da mesma forma àquelas já estão investidas por você, tenha acesso ao valuation de cada uma delas.',
      ico: investorIco,
    },
  ];

  return (
    <>
      <ModalBody
        title="&nbsp;"
        width={800}
        open={modalVisible}
        destroyOnClose={true}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Fechar"
        onCancel={stateModal}
      >
        {itemContent}
      </ModalBody>
      <Title>Escolha seu tipo de perfil</Title>
      <Introduction>
        Escolha o tipo de perfil que corresponde à finalidade de conta criada
        para que possamos traçar o melhor caminho da sua jornada.
      </Introduction>
      <CardContent>
        {cardConfig.map((item: any) => (
          <Card
            id={item.id}
            key={item.id}
            onClick={() => {
              cardHandler(item.id);
            }}
          >
            <Meta
              className={item.className}
              avatar={<img alt={item.description} src={item.ico} />}
              title={item.title}
              description={item.description}
            />
          </Card>
        ))}
      </CardContent>
    </>
  );
}

export default ProfileOptions;
