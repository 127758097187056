import { Card, Form, Input } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import IMethodology from '../../../core/interfaces/models/IMethodology';
import Tab from '../../tab';
import PhasesForm from './PhasesForm';
import PillarsForm from './PillarsForm';
import ContentMethodologyForm from './ContentMethodologyForm';
import { BoxContainer } from '../../../GlobalStyles';

interface IParams {
  setFormName: (formName: string) => void;
  initialValues: Record<string, unknown>;
}

function MethodologyForm(props: IParams) {
  const [next, setNext] = useState<string>('0');
  const [form] = useForm();
  const nameMothodology = useWatch('methodology', form);
  const [data, setData] = useState<IMethodology>();

  const methodologyEdit = props.initialValues as unknown as
    | IMethodology
    | undefined;

  const content = [
    {
      tab: 'Cadastrar fases',
      body: (
        <PhasesForm
          setFormName={props.setFormName}
          setNext={setNext}
          getNameMethodology={() => form.getFieldValue('methodology')}
          methodology={nameMothodology}
          setParamsResponse={setData}
          initialValues={props.initialValues}
        />
      ),
    },
    {
      tab: 'Cadastrar pilares',
      body: (
        <PillarsForm
          setFormName={props.setFormName}
          setNext={setNext}
          getNameMethodology={() => form.getFieldValue('methodology')}
          params={data}
          setParamsResponse={setData}
          initialValues={props.initialValues}
        />
      ),
    },
    {
      tab: 'Cadastrar conteúdos',
      body: (
        <ContentMethodologyForm
          setFormName={props.setFormName}
          setNext={setNext}
          getNameMethodology={() => form.getFieldValue('methodology')}
          methodologyCreate={data}
          methodology={methodologyEdit}
        />
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        methodology: data.name,
      });
    }
  }, [data]);

  useEffect(() => {
    const values = props.initialValues;
    if (!isEmpty(values)) {
      form.setFieldValue('methodology', values.name);
    }
  }, [form, props.initialValues]);

  useEffect(() => {
    sessionStorage.removeItem('form-phases-methodology');
    sessionStorage.removeItem('form-pillars-methodology');
  }, []);

  return (
    <BoxContainer noPadding={true}>
      <Form form={form} layout={'vertical'}>
        <Card>
          <Form.Item
            name={'methodology'}
            label={'Nome da metodologia'}
            rules={[
              {
                required: true,
                message: 'O nome da metodologia é obrigatório',
              },
              {
                max: 40,
                message: 'Metodologia não pode ter mais de 40 caracteres',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Card>
      </Form>
      <Tab content={content} activeKey={next} changeActiveKey={setNext} />
    </BoxContainer>
  );
}

export default MethodologyForm;
