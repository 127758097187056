import React, { useMemo, useState } from 'react';
import { ActionsObjectiveKeyResult, TabText, WrapperKpi } from './styles';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Tooltip,
} from 'antd';
import Text from '../../../../../components/Text';
import { MdOutlineAddComment } from 'react-icons/md';
import Divider from '../../../../../components/Divider';
import { IKpi } from '../../../../../core/interfaces/IMetric';
import StatusObjectiveKeyResult from '../../../../../components/StatusObjectiveKeyResult';
import { IPerformaceIndicatorStatus } from '../../../../../core/enums/IKeyPerformaceIndicators';
import Title from '../../../../../components/Title';
import { FiEdit2 } from 'react-icons/fi';
import { BiTime } from 'react-icons/bi';
import IComment from '../../../../../core/interfaces/models/IComment';
import { PurpleButton } from '../../../../../GlobalStyles';
import MetricCommentService from '../../../../../services/MetricCommentService';
import MetricService from '../../../../../services/MetricService';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

interface Props {
  metricId: number;
  startupId: number;
  keyResult: string;
  comments: IComment[];
  keyPerformanceIndicators: IKpi[];
  objectives: string;
  reload(): void;
}

enum TabEnum {
  KPI,
  COMMENT,
}

function ItemObjectiveKeyResult({
  keyResult,
  comments,
  reload,
  metricId,
  objectives,
  keyPerformanceIndicators,
  startupId,
}: Props) {
  const navigate = useNavigate();

  const [tab, setTab] = useState<TabEnum | null>(null);
  const [commentId, setCommentId] = useState<number>(0);
  const [isAddComment, setIsAddComment] = useState<boolean>(false);

  const [form] = Form.useForm();
  const styles = {
    weight: 700,
    color: '#960096',
    margin: '0 16px 0 0',
    display: 'inline-block',
  };

  const fieldRequired = [{ required: true, message: 'Campo obrigatório' }];
  const existsTabActive = tab !== null;

  const metricService = useMemo(() => MetricService.init(), []);
  const metricCommentService = useMemo(() => MetricCommentService.init(), []);

  const checkTabIsActive = (value: TabEnum): boolean => tab === value;
  const changeTab = (value: TabEnum): void => {
    setTab(checkTabIsActive(value) ? null : value);
  };

  const sendComment = (comment?: IComment) => {
    const textComment = form.getFieldValue('comment');
    const data = {
      ...comment,
      comment: textComment,
      metric: {
        entityId: metricId,
      },
    };

    metricCommentService
      .create(data)
      .then(() => {
        reload();
        notification.success({
          message: 'Comentário salvo com sucesso',
          duration: 3.5,
        });
        setCommentId(0);
        form.resetFields();
        setIsAddComment(false);
      })
      .catch(() => {
        notification.error({
          message: 'Erro ao salvar comentário',
          duration: 3.5,
        });
      });
  };

  const formComment = () => (
    <Form form={form} name="form-comment">
      <Form.Item name={'comment'} rules={fieldRequired}>
        <Input placeholder="Seu comentário" maxLength={500} />
      </Form.Item>
    </Form>
  );

  const actionsComment = (comment?: IComment) => (
    <Row gutter={[8, 8]} style={{ marginBottom: '25px' }}>
      <Col>
        <PurpleButton
          size="small"
          type="primary"
          onClick={() => {
            form.validateFields().then(() => sendComment(comment));
          }}
        >
          Comentar
        </PurpleButton>
      </Col>
      <Col>
        <Button
          size="small"
          type="default"
          htmlType="button"
          onClick={() => {
            setCommentId(0);
            setIsAddComment(false);
          }}
        >
          Cancelar
        </Button>
      </Col>
    </Row>
  );

  const newComment = (): void => {
    setCommentId(0);
    form.resetFields();
    setTab(TabEnum.COMMENT);
    setIsAddComment(checkTabIsActive(TabEnum.COMMENT) ? !isAddComment : true);
  };

  const getPercentage = (): number => {
    const { Approvaed, Concluded } = IPerformaceIndicatorStatus;
    const total = keyPerformanceIndicators.filter((kpi) =>
      [Approvaed, Concluded].includes(kpi.status),
    ).length;

    if (total) {
      return (total / keyPerformanceIndicators.length) * 100;
    }

    return 0;
  };

  return (
    <>
      <Row align={'bottom'} gutter={[12, 12]} style={{ marginBottom: '8px' }}>
        <Col xs={24} sm={24} md={7}>
          <Text {...styles}>Eu vou</Text>
          <Text display={styles.display}>{objectives}</Text>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Text {...styles}>Medido por</Text>
          <Text display={styles.display}>{keyResult}</Text>
        </Col>
        <Col xs={24} sm={24} md={10}>
          <ActionsObjectiveKeyResult>
            <StatusObjectiveKeyResult
              placement="leftTop"
              status={metricService.getStatusMetric(keyPerformanceIndicators)}
            />
            <Tooltip
              color="#fff"
              placement="bottomLeft"
              title="Novo comentário"
              overlayClassName="text-tooltip-primary"
            >
              <MdOutlineAddComment
                size={22}
                cursor="pointer"
                onClick={newComment}
              />
            </Tooltip>
          </ActionsObjectiveKeyResult>
        </Col>
      </Row>
      <Row gutter={[12, 12]} align="bottom" justify="start">
        <Col>
          <TabText
            onClick={() => changeTab(TabEnum.KPI)}
            isActive={checkTabIsActive(TabEnum.KPI)}
          >
            {keyPerformanceIndicators.length} KPI cadastrados
          </TabText>
        </Col>
        <Col>
          <TabText
            onClick={() => changeTab(TabEnum.COMMENT)}
            isActive={checkTabIsActive(TabEnum.COMMENT)}
          >
            {comments.length} Comentário
          </TabText>
        </Col>
        <Col>
          <Text color="#960096" weight={500}>
            {getPercentage().toFixed(1)}%
          </Text>
        </Col>
      </Row>
      <div
        style={{ display: checkTabIsActive(TabEnum.KPI) ? 'block' : 'none' }}
      >
        <Title size={17} margin="12px 0">
          KPIS
        </Title>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {keyPerformanceIndicators.map((kpi, index) => (
            <WrapperKpi
              key={`kpi_key_${index}`}
              onClick={() =>
                navigate(
                  `/dashboard/startup/detail/${startupId}/kpi/${kpi.entityId}`,
                )
              }
            >
              <Text weight={500}>{kpi.text}</Text>
              <Row justify="space-between" align="middle">
                <Col xs={24} span={16}>
                  <Row gutter={[8, 8]}>
                    {!isEmpty(kpi.files) && (
                      <Col>
                        <Text size={15} color="#9AA4A8">
                          {kpi.files.length} evidência
                        </Text>
                      </Col>
                    )}
                    {kpi.comments && (
                      <Col>
                        <Text size={15} color="#9AA4A8">
                          {kpi.comments.length} comentário
                        </Text>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col>
                  {kpi.status.includes(IPerformaceIndicatorStatus.Pending) ? (
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>
                        <BiTime color="#9AA4A8" display={'flex'} size={20} />
                      </Col>
                      <Col>
                        <Text color="#9AA4A8">Aguardando validação</Text>
                      </Col>
                    </Row>
                  ) : (
                    <StatusObjectiveKeyResult
                      placement="leftTop"
                      status={kpi.status}
                    />
                  )}
                </Col>
              </Row>
            </WrapperKpi>
          ))}
        </Space>
      </div>

      <div
        style={{
          display: checkTabIsActive(TabEnum.COMMENT) ? 'block' : 'none',
        }}
      >
        <Title size={17} margin="12px 0">
          Comentários
        </Title>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          {comments.map((comment, index) => {
            const isEditing = commentId === comment.entityId;
            return (
              <Row
                key={`comment_key_${index}`}
                justify="space-between"
                align="middle"
                gutter={[12, 12]}
                wrap={isEditing}
                style={{ marginRight: '25px' }}
              >
                <Col flex={1}>
                  {isEditing ? (
                    formComment()
                  ) : (
                    <Text weight={500}>{comment.comment}</Text>
                  )}
                </Col>
                <Col>
                  {isEditing ? (
                    actionsComment(comment)
                  ) : (
                    <Tooltip
                      color="#fff"
                      title="Editar"
                      placement="bottomRight"
                    >
                      <FiEdit2
                        cursor="pointer"
                        size={22}
                        onClick={() => {
                          form.setFieldValue('comment', comment.comment);
                          setIsAddComment(false);
                          setCommentId(Number(comment.entityId));
                        }}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            );
          })}
          {isAddComment && (
            <Row
              justify="space-between"
              align="middle"
              gutter={[12, 12]}
              style={{ marginRight: '25px' }}
            >
              <Col flex={1}>{formComment()}</Col>
              <Col>{actionsComment()}</Col>
            </Row>
          )}
        </Space>
      </div>

      <Divider
        margin="16px 0 25px 0"
        color={existsTabActive ? '#a209a2' : '#f0f2f5'}
      />
    </>
  );
}

export default ItemObjectiveKeyResult;
