import React, { useState, useMemo, useEffect } from 'react';
import Text from '../../../Text';
import Title from '../../../Title';
import { IoMdClose } from 'react-icons/io';
import defaultImg from '../../../../assets/images/image-default.png';
import { ContentWrapper, Wrapper, WrapperIcon } from './styles';
import CoursePhaseService from '../../../../services/course-phase/CoursePhaseService';
import { notification, Spin } from 'antd';
import ErrorNotification from '../../../notifications/errorNotification';
import ConfirmAction from '../../../ConfirmAction';
import useLoading from '../../../../core/hooks/useLoading';
import FileService from '../../../../services/FileService';

interface Props {
  title: string;
  srcImage?: string;
  entityId: number;
  reload?: () => void;
  duration: number;
  lessonQuantity: number;
  showCloseIcon?: boolean;
}

function CoursePreviewCard({
  title,
  reload,
  entityId,
  duration,
  srcImage,
  showCloseIcon = true,
  lessonQuantity,
}: Props) {
  const [filePath, setFilePath] = useState<string>(defaultImg);

  const image = useMemo(() => srcImage, [srcImage]);
  const coursePhaseService = useMemo(() => CoursePhaseService.init(), []);
  const fileService = useMemo(() => FileService.init(), []);

  const imageLoading = useLoading();

  const unlinkCourse = () => {
    coursePhaseService
      .unlinkCourse(entityId)
      .then(() => {
        if (reload) reload();
        notification.success({
          duration: 3.5,
          message: 'Curso desvinculado com sucesso',
        });
      })
      .catch(() =>
        ErrorNotification({
          defaultMessage: 'Erro ao desvincular curso',
        }),
      );
  };

  const fetchImage = (image?: string) => {
    if (image) {
      imageLoading.showLoading();
      fileService
        .get(image)
        .then((data) => setFilePath(data))
        .catch(() => setFilePath(defaultImg))
        .finally(imageLoading.hideLoading);
    } else {
      setFilePath(defaultImg);
    }
  };

  useEffect(() => {
    fetchImage(image);
  }, [image]);

  return (
    <Wrapper>
      <Spin spinning={imageLoading.loading}>
        <img width={120} height={99} src={filePath} alt="imagem curso" />
      </Spin>
      <ContentWrapper>
        <Title size={16}>{title}</Title>
        <div>
          <Title tag="h2" size={14}>
            Nº aulas
          </Title>
          <Text size={12}>{lessonQuantity}</Text>
        </div>
        <div>
          <Title tag="h2" size={14}>
            Duração
          </Title>
          <Text size={12}>{duration}</Text>
        </div>
      </ContentWrapper>
      {showCloseIcon && (
        <WrapperIcon>
          <ConfirmAction
            title="Deseja remover este curso?"
            onConfirm={unlinkCourse}
          >
            <IoMdClose cursor="pointer" size={20} color="#9ba5a9" />
          </ConfirmAction>
        </WrapperIcon>
      )}
    </Wrapper>
  );
}

export default CoursePreviewCard;
