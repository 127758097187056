import { IMessage, IParticipant } from '../../core/interfaces/chat/IChat';
import IPageable from '../../core/interfaces/IPageable';
import api from '../../core/services/api';
import { errorCallback } from '../../helpers/promise.helper';

class GetChatService {
  private baseRequest = '/chat';

  public static init() {
    return new GetChatService();
  }

  public getMessages(params: Record<string, unknown>) {
    return api
      .get<IPageable<IMessage>>(`${this.baseRequest}/messages`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public getParticipants(params: Record<string, unknown>) {
    return api
      .get<IParticipant[]>(`${this.baseRequest}/participants`, {
        params,
      })
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default GetChatService;
