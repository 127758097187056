import React from 'react';
import { BoxContent, Header } from './style';
import image from '../../../assets/images/logo-rocket.png';
import VcForm from '../form/Vc';

interface IParams {
  closeDrawer: () => void;
}

function Vc(props: IParams) {
  return (
    <BoxContent>
      <Header>
        <img alt="" src={image} />
        <div className="description">
          Conhecido também como Capital de Risco, é a forma de avaliar uma
          startup adotando a perspectiva de um investidor, que olha para o valor
          da empresa com enfoque no retorno sobre o investimento feito em
          determinado período de tempo.
        </div>
      </Header>
      <VcForm closeDrawer={props.closeDrawer} />
    </BoxContent>
  );
}

export default Vc;
