import React from 'react';

import { Slider } from './styles';
import { SliderSingleProps } from 'antd';

type IProps = SliderSingleProps;

function SliderComponent(props: IProps) {
  const style = {
    styleTrack: {
      backgroundColor: '#05233D',
      height: '3px',
    },
    styleHandle: {
      borderColor: '#05233D',
      backgroundColor: '#05233D',
      height: 8,
      width: 8,
      marginTop: -2,
    },
  };
  return (
    <Slider
      {...props}
      trackStyle={style.styleTrack}
      handleStyle={style.styleHandle}
    />
  );
}

export default SliderComponent;
