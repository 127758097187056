import styled from 'styled-components';
import { Menu } from 'antd';

const MenuWrapper = styled(Menu)`
  & .ant-menu-item svg {
    height: 17px;
    width: 17px;
  }

  & .ant-menu-item {
    color: #fff;
  }

  & li.ant-menu-item:hover > span,
  & li.ant-menu-item-selected > span {
    color: var(--secondary-color) !important;

    svg {
      color: var(--secondary-color) !important;
    }
  }
`;

export { MenuWrapper };
