import React from 'react';
import { InputProps } from 'antd';
import InputCurrencyComponent from './styles';

interface IProps extends InputProps {
  value?: number;
  onChange?: (value: any) => void;
  negative?: boolean | false;
}

function InputCurrency(props: IProps) {
  const maskMoney = (e: React.ChangeEvent<HTMLInputElement>) => {
    const money = e.target.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');

    props.onChange?.(money);
  };

  return (
    <InputCurrencyComponent
      {...props}
      type={'text'}
      placeholder={'0,00'}
      prefix={'R$'}
      maxLength={props.maxLength || 13}
      onChange={maskMoney}
      negative={props.negative}
    />
  );
}

export default InputCurrency;
