import React, { useEffect, useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  DatePickerProps,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import locale from 'antd/es/date-picker/locale/pt_BR';

interface IParams {
  subStep: number;
  setSubStep: (subStep: number) => void;
  setButtonState: (buttonState: boolean) => void;
  setFormName: (formName: string) => void;
  initialValues?: any;
  setFormData: (data: any) => void;
}

function PersonalForm(props: IParams) {
  const [form] = Form.useForm();
  const [birthDay, setBirthDay] = useState<any>();

  const arrageForm = (values: FormInstance) => {
    const valuelMap = new Map(Object.entries(values));
    const entityId = props.initialValues?.entityId;
    const name: string = valuelMap.get('name');
    const email: string = props.initialValues?.email;
    const cellPhone: string = valuelMap.get('cellphone');
    const cpf: string = valuelMap.get('cpf');
    const birthDate: string = birthDay;

    return {
      entityId,
      name,
      email,
      cellPhone,
      cpf,
      birthDate,
    };
  };

  const onFinish = (values: FormInstance) => {
    props.setSubStep(props.subStep + 1);
    props.setFormData(arrageForm(values));
  };

  const setInitialValues = () => {
    form.setFieldsValue({
      name: props.initialValues?.name,
    });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setBirthDay(dateString);
  };

  useEffect(() => {
    props.setFormName('formInvestorPersonal');
    setInitialValues();
  }, [props.initialValues]);

  return (
    <Form
      name={'formInvestorPersonal'}
      onFinish={onFinish}
      form={form}
      layout={'vertical'}
      autoComplete={'off'}
      labelWrap
    >
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={'name'}
            label="Qual seu nome?"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <Input placeholder="Nome completo" maxLength={255} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="CPF"
            name={'cpf'}
            rules={[
              { required: true, message: 'Campo obrigatório' },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue('cpf').includes('_')) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'CPF inválido',
              }),
            ]}
          >
            <MaskedInput mask="000.000.000-00" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="Telefone"
            name="cellphone"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue('cellphone').includes('_')) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'Telefone inválido',
              }),
            ]}
          >
            <MaskedInput mask={'(00) 00000-0000'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            label="Quando você nasceu?"
            name="birthdate"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              locale={locale}
              format={'DD/MM/YYYY'}
              placeholder="Seleciona a data de nascimento"
              onChange={onChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default PersonalForm;
