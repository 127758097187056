import CrudService from './CrudService';
import api from '../core/services/api';
import { errorCallback } from '../helpers/promise.helper';
import { ISchedule } from '../core/interfaces/ISchedule';

class ScheduleService extends CrudService {
  public static init(): ScheduleService {
    return new ScheduleService({
      requestMapping: 'schedule',
    });
  }

  public async getAllSchedulesByMentor(id: number): Promise<ISchedule[]> {
    return api
      .get<ISchedule[]>(`${this.requestMapping}/all-by-user/mentor/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllSchedulesByStartup(id: number): Promise<ISchedule[]> {
    return api
      .get<ISchedule[]>(`${this.requestMapping}/all-by-user/startup/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async createSchedule(
    id: number,
    payload: ISchedule,
  ): Promise<ISchedule> {
    return api
      .post<ISchedule>(`${this.requestMapping}/user/${id}`, payload)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllByMentor(id: number): Promise<ISchedule[]> {
    return api
      .get<ISchedule[]>(`${this.requestMapping}/mentor/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllByMentorAndDate(
    id: number,
    date: string,
  ): Promise<ISchedule[]> {
    return api
      .get<ISchedule[]>(`${this.requestMapping}/mentor/${id}/date/${date}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async getAllByUserMentorAndDate(
    id: number,
    date: string,
  ): Promise<ISchedule[]> {
    return api
      .get<ISchedule[]>(`${this.requestMapping}/user/${id}/date/${date}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public async toSchedule(
    id: number,
    iduser: number,
    payload: ISchedule,
  ): Promise<ISchedule> {
    return api
      .put<ISchedule>(
        `${this.requestMapping}/to-schedule/${id}/user/${iduser}`,
        payload,
      )
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default ScheduleService;
