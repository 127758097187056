import React from 'react';
import { Gauge, GaugeConfig } from '@ant-design/charts';

interface Props {
  title: string;
  value: number;
}

function ScoreCardGauche({ title, value }: Props) {
  const config: GaugeConfig = {
    percent: value / 5,
    radius: 0.75,
    range: {
      color: '#960096',
    },
    indicator: {
      pointer: {
        style: {
          lineWidth: 2,
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          lineWidth: 2,
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(value: string) {
          return Number(value) * 5;
        },
      },
      subTickLine: {
        count: 2,
      },
    },
    statistic: {
      title: {
        content: title,
        style: {
          color: 'rgba(0,0,0,0.65)',
        },
      },
    },
  };

  return <Gauge {...config} />;
}

export default ScoreCardGauche;
