import React, { useEffect, useState } from 'react';
import { Form, Input, notification, Space, Spin, Tag } from 'antd';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { BoxTags, Container } from '../../ProfileInfos/style';
import OnEmptyOrList from '../../../../components/Screen/OnEmptyOrList';
import CourseCategoryService from '../../../../services/course/CourseCategoryService';
import useLoading from '../../../../core/hooks/useLoading';
import { useWatch } from 'antd/lib/form/Form';
import ICourseCategory from '../../../../core/interfaces/course/ICourseCategory';

function FormCourseCategory() {
  const courseCategoryService = CourseCategoryService.init();
  const [form] = Form.useForm();
  const value = useWatch('name', form);
  const [courseCategories, setCourseCategories] = useState<ICourseCategory[]>(
    [],
  );
  const { loading, showLoading, hideLoading } = useLoading();
  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const setInputState = () => {
    setInputVisible(!inputVisible);
  };
  const showInput = () => {
    setInputState();
  };

  const getCourseCategories = async () => {
    showLoading();
    courseCategoryService
      .findAll()
      .then((response) => {
        setCourseCategories(response);
      })
      .catch((error) => {
        notification.error({
          message: 'Erro',
          description: error.message,
        });
      })
      .finally(hideLoading);
  };

  const handleInputConfirm = () => {
    if (value === '' || value === undefined) {
      notification.warning({
        message: 'Atenção',
        description: 'O campo não pode ser vazio',
      });
      return;
    }
    showLoading();
    courseCategoryService
      .create({
        name: value,
      })
      .then(() => {
        getCourseCategories();
        setInputVisible(false);
        form.resetFields();
      })
      .catch((error) => {
        notification.error({
          message: 'Erro',
          description: error.errors.message,
        });
      })
      .finally(hideLoading);
  };

  const handleDelete = (id: number) => {
    showLoading();
    courseCategoryService
      .delete(id)
      .then(() => {
        getCourseCategories();
        notification.success({
          message: 'Sucesso',
          description: 'Categoria excluída com sucesso',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Erro',
          description: error.message,
        });
      })
      .finally(hideLoading);
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  return (
    <Container borderTop={false}>
      <Spin spinning={loading}>
        <Title margin={'0 0 10px 0'} size={16}>
          Categorias de cursos
        </Title>
        <Text margin={'0 0 10px 0'} size={14}>
          Crie categorias para seus cursos
        </Text>
        {inputVisible ? (
          <Form form={form}>
            <Form.Item
              name={'name'}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                maxLength={60}
                type="text"
                size="small"
                style={{ maxWidth: '480px' }}
                placeholder="Digite o nome e aperte ENTER para salvar"
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            </Form.Item>
          </Form>
        ) : (
          <Tag
            className="dashed"
            style={{ width: '70px', cursor: 'pointer' }}
            onClick={showInput}
          >
            <PlusOutlined /> Novo
          </Tag>
        )}
        <BoxTags>
          <OnEmptyOrList list={courseCategories}>
            <Space
              style={{
                width: '100%',
                flexWrap: 'wrap',
              }}
              direction="horizontal"
            >
              {courseCategories.map((tag, index) => {
                return (
                  <Tag
                    className="default"
                    key={`TAGS_${index}`}
                    closable={true}
                    onClose={() => handleDelete(tag?.entityId as number)}
                    closeIcon={<CloseOutlined />}
                  >
                    {tag.name}
                  </Tag>
                );
              })}
            </Space>
          </OnEmptyOrList>
        </BoxTags>
      </Spin>
    </Container>
  );
}

export default FormCourseCategory;
