import styled from 'styled-components';
import { Drawer, DrawerProps } from 'antd';

export const DrawerContainer = styled(Drawer)<DrawerProps>`
  & .ant-drawer-content-wrapper {
    padding: 0;
  }

  & .ant-drawer-header {
    & .ant-drawer-title {
      color: var(--primary-color);
      font-weight: bold;
    }

    & .ant-drawer-close {
      display: none;
    }

    & .ant-btn-primary {
      background: var(--primary-color);
    }

    & .ant-btn-primary:hover {
      background: #134072ff;
    }

    & .ant-btn-default:hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
  & .ant-drawer-body {
    color: var(--primary-color);
    & .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background: var(--primary-color);
      border-color: var(--primary-color);
    }

    & .ant-tree-checkbox-inner:hover {
      border-color: #3e091c;
    }

    & .ant-tree-checkbox-inner::after {
      background: var(--primary-color);
    }
  }
`;
