import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { useState } from 'react';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { BoxWhite, PurpleButton } from '../../../../GlobalStyles';
import ValuationToolsInfo from '../../../Infos/ValuationToolsInfo';
import ContentBase from '../../../templates/ContentBase';
import DragAndDropFile from '../../../../components/DragAndDropFile/DragAndDropFile';
import AlertMessage from '../../../../components/Alert/AlertMessage';
import { convertBase64 } from '../../../../helpers/convertion.helper';
import useLoading from '../../../../core/hooks/useLoading';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonAction } from './style';

interface IFormValues {
  ipca?: number;
  countryRisk?: number;
  taxWithoutRisk?: number;
  premiumSize?: number;
  prm?: number;
  inflationBra?: number;
  ideaStages?: number;
  developmentStage?: number;
  startupStage?: number;
  expansionStage?: number;
  fileName?: string;
  fileData?: string;
  sector?: number;
  leveredBeta?: number;
  deRadio?: number;
  unleveredBeta?: number;
  evEbitda?: number;
}

function ConfigValuation() {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IFormValues>({});

  const { showLoading } = useLoading();

  const showModal = () => {
    setModalVisible(!modalVisible);
  };

  const uploadFile = (file: File) => {
    convertBase64(file)
      .then((data) => {
        setFormValues({
          ...formValues,
          fileName: file.name,
          fileData: data as string,
        });
      })
      .catch((er) => {
        console.log('erro: ', er);
      });
  };

  const onFinish = () => {
    showLoading();
    // SERVICEHERE
    //   .updateOrCreate(getDataForm())
    //   .then(() => {
    //     notificationMessage()
    //   })
    //   .catch(() =>
    //     ErrorNotification({ defaultMessage: "Erro ao salvar as configurações do valuation!" })
    //   )
    //   .finally(hideLoading)
  };

  const getItem = (label: string, name: string, placeholder?: string) => {
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: 'Campo obrigatório!',
          },
        ]}
      >
        <Input
          type={'number'}
          min={1}
          max={100}
          placeholder={placeholder || '0,0%'}
        />
      </Form.Item>
    );
  };

  return (
    <ContentBase
      title={'Valuation'}
      goBack={true}
      path={'tools'}
      modalContent={<ValuationToolsInfo />}
      content={
        <>
          <AlertMessage
            visible={modalVisible}
            message="Salvar e publicar?"
            description="Os dados preenchidos nesses campos são cruciais para o cálculo de valuations de todas as aceleradoras e irá impactar no resultado dos próximos executados."
            textOkButton="Salvar e publicar"
            onCancel={showModal}
            onOk={onFinish}
          />
          <Form
            form={form}
            layout={'vertical'}
            autoComplete={'off'}
            name={'valuationToolsForm'}
            onFinish={showModal}
          >
            <BoxWhite>
              <Title>WACC</Title>
              <Row gutter={[4, 4]}>
                <Col span={4}>{getItem('IPCA', 'ipca')}</Col>
                <Col span={4}>{getItem('Risco país', 'countryRisk')}</Col>
                <Col span={4}>
                  {getItem('Taxa livre de risco', 'taxWithoutRisk')}
                </Col>
                <Col span={4}>{getItem('Size premium', 'premiumSize')}</Col>
                <Col span={4}>{getItem('PRM', 'prm')}</Col>
                <Col span={4}>{getItem('Inflação BRA', 'inflationBra')}</Col>
              </Row>
            </BoxWhite>
            <BoxWhite>
              <Title>Estágios das empresas</Title>
              <Text margin={'10px 0'}>
                Taxa de Desconto são atualizadas anualmente, de acordo com
                pesquisa sobre retorno esperado de VC múltiplos, tempo e
                diluição.
              </Text>
              <Row gutter={[4, 4]}>
                <Col span={6}>{getItem('Estágio de ideia', 'ideaStages')}</Col>
                <Col span={6}>
                  {getItem('Estágio de desenvolvimento', 'developmentStage')}
                </Col>
                <Col span={6}>
                  {getItem('Estágio de startup', 'startupStage')}
                </Col>
                <Col span={6}>
                  {getItem('Estágio de expansão', 'expansionStage')}
                </Col>
              </Row>
            </BoxWhite>
            <BoxWhite>
              <Title>Múltiplos</Title>
              <Text size={16} weight={700} margin={'0 0 10px 0'}>
                Anexar aqruivo (opcional)
              </Text>
              <Text>
                O arquivo para cálculo dos múltiplos podem ser em formato{' '}
                <b>Excel</b> ou em <b>CSV</b>. Anexando um arquivo nesse formato
                e que possua os mesmos títulos do formulário Setores da
                Indústria, os campos serão preenchidos automaticamente com os
                dados do arquivo.
              </Text>
              <DragAndDropFile
                maxFiles={1}
                fileType={['application/vnd.ms-excel']}
                title={
                  'Clique e selecione o arquivo desejado ou arraste e solte-o aqui'
                }
                description={'Arquivos em formatos CSV ou XLSX'}
                onFileChange={(file) => {
                  uploadFile(file);
                }}
              />
              <Divider style={{ margin: '5px 0' }} />
              <Title size={18}>Setores das indústria</Title>
              <Form.List name="exerciseList" initialValue={[]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => {
                      return (
                        <Row gutter={[4, 4]} key={`item-${key}`}>
                          <Col span={8}>
                            {getItem('Setor', 'sector', 'Ex: aço')}
                          </Col>
                          <Col span={4}>
                            {getItem('Beta Levered', 'leveredBeta', '0,0')}
                          </Col>
                          <Col span={4}>
                            {getItem('D/E Ratio (%)', 'deRadio')}
                          </Col>
                          <Col span={4}>
                            {getItem('Beta unlevered', 'unleveredBeta', '0,0')}
                          </Col>
                          <Col span={4}>
                            {getItem('EV/EBITDA', 'evEbitda', '0,0')}
                          </Col>
                          {fields.length > 1 && (
                            <ButtonAction>
                              <DeleteOutlined
                                className="removeItem"
                                onClick={() => remove(name)}
                              />
                            </ButtonAction>
                          )}
                        </Row>
                      );
                    })}
                    <ButtonAction>
                      <Form.Item>
                        <Button
                          size="small"
                          shape="circle"
                          type="dashed"
                          className="addItem"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        />
                      </Form.Item>
                    </ButtonAction>
                  </>
                )}
              </Form.List>
              <PurpleButton
                type="primary"
                htmlType="submit"
                style={{
                  display: 'block',
                  margin: '10px auto',
                  padding: '0 50px',
                }}
              >
                Salvar
              </PurpleButton>
            </BoxWhite>
          </Form>
        </>
      }
    />
  );
}

export default ConfigValuation;
