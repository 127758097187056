import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  isBlocked: boolean;
}>`
  cursor: pointer;

  ${({ isBlocked }) =>
    isBlocked &&
    css`
      pointer-events: none;
      user-select: none;

      &::before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(238 238 238 / 60%);
        position: absolute;
        z-index: 2;
      }
    `}
`;

const Grid = styled.div`
  display: grid;
  gap: 4px;
  align-items: center;
  grid-template-columns: 1.5fr 1fr;

  & > div:first-child {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  & img {
    width: 44px;
    height: 44px;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

export { Wrapper, Grid };
