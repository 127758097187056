import styled from 'styled-components';

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const MethodologyFormContent = styled.div`
  & .item {
    width: 100%;
  }

  .line {
    display: flex;
    flex-wrap: wrap;

    & .step {
      flex: 2 auto;
      flex: 0 calc(50% - 5px);
      margin-right: 5px;
      margin-top: 10px;
    }
  }

  & .addItem {
    left: 50%;
  }

  & .removeItem {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    z-index: 100;
  }

  .ant-btn-circle {
    margin-top: 0px;
  }
`;

const TextDescription = styled.div`
  padding: 10px;

  p {
    font-style: italic;
  }
`;

export { BoxContainer, MethodologyFormContent, TextDescription };
