import React, { useState, useRef, useMemo } from 'react';
import { ContentPicture, CustomModal, ImageContainer } from './styles';
import Title from '../Title';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { BiTrashAlt } from 'react-icons/bi';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import pictureDefault from '../../assets/images/image-default.png';
import { IPropsModal } from '../../core/interfaces/modal/IPropsModal';
import ProfilePictureService from '../../services/profile-picture/ProfilePictureService';
import { useAuth } from '../../core/hooks/auth';
import { notification } from '../../helpers/notification.helper';
import useLoading from '../../core/hooks/useLoading';
import { isNull } from 'lodash';
import { PurpleButton } from '../../GlobalStyles';

function ModalProfilePicture({ visible, onCancel }: IPropsModal) {
  const uploadLoading = useLoading();
  const { logged, loadingProfilePicture, profilePicture, updateLogged } =
    useAuth();

  const { entityId = 0 } = logged ?? {};

  const refImg = useRef<HTMLInputElement>(null);

  const profilePictureService = useMemo(() => ProfilePictureService.init(), []);

  const [imageSelected, setImageSelected] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState(() => pictureDefault);

  const cancelUpload = () => {
    const { current: input } = refImg;
    if (input) {
      input.value = '';
      setImageSelected(null);
      setImagePreview(pictureDefault);
    }
  };

  const customCancel = () => {
    cancelUpload();
    onCancel();
  };

  const changeImage = () => {
    const { current: input } = refImg;
    const firstFile = input?.files?.item(0);

    if (firstFile) {
      setImageSelected(firstFile);
      setImagePreview((oldValue) =>
        firstFile ? window.URL.createObjectURL(firstFile) : oldValue,
      );
    }
  };

  const uploadImage = () => {
    if (!imageSelected) return;

    uploadLoading.showLoading();
    profilePictureService
      .add(entityId, imageSelected)
      .then(({ jwttoken }) => {
        customCancel();
        updateLogged(jwttoken);
        notification.success('Imagem atualizada com sucesso!');
      })
      .catch(() => notification.error('Erro ao atualizar dados'))
      .finally(uploadLoading.hideLoading);
  };

  const deleteImage = () => {
    if (!isNull(profilePicture) && !loadingProfilePicture) {
      uploadLoading.showLoading();
      profilePictureService
        .remove(entityId)
        .then(({ jwttoken }) => {
          customCancel();
          updateLogged(jwttoken);
          notification.success('Imagem removida com sucesso!');
        })
        .catch(() => notification.error('Erro ao remover imagem'))
        .finally(uploadLoading.hideLoading);
    }
  };

  const newImage = () => {
    refImg.current?.click();
  };

  const getUrl = () => {
    if (imagePreview !== pictureDefault || !profilePicture) {
      return imagePreview;
    }

    return profilePicture;
  };

  return (
    <CustomModal
      width="100%"
      maxWidth="500px"
      margin="0 auto"
      footer={null}
      paddingBody="0"
      open={visible}
      onCancel={customCancel}
      destroyOnClose={true}
    >
      <input
        ref={refImg}
        onChange={changeImage}
        type="file"
        style={{ display: 'none' }}
      />
      <Spin spinning={uploadLoading.loading}>
        <ContentPicture>
          <header>
            <Title
              color="#fff"
              size={30}
              lineHeight={1.2}
              weight={600}
              align="center"
            >
              Foto de perfil
            </Title>
          </header>
          <main>
            <Spin spinning={loadingProfilePicture}>
              <ImageContainer url={getUrl()} />
            </Spin>
          </main>
          <footer>
            {isNull(imageSelected) ? (
              <>
                <Tooltip
                  color="#fff"
                  title="Nova imagem"
                  placement="bottom"
                  overlayClassName="text-tooltip-primary"
                >
                  <MdOutlinePhotoCamera
                    onClick={newImage}
                    cursor="pointer"
                    color="#fff"
                    size={20}
                  />
                </Tooltip>
                <Tooltip
                  color="#fff"
                  title="Excluir imagem"
                  placement="bottom"
                  overlayClassName="text-tooltip-primary"
                >
                  <BiTrashAlt
                    onClick={deleteImage}
                    cursor="pointer"
                    color="#fff"
                    size={20}
                  />
                </Tooltip>
              </>
            ) : (
              <Row gutter={8} justify={'end'} style={{ width: '100%' }}>
                <Col>
                  <Button
                    type="default"
                    style={{ marginLeft: '5px' }}
                    onClick={cancelUpload}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <PurpleButton
                    type="primary"
                    htmlType="submit"
                    form="formTopic"
                    onClick={uploadImage}
                  >
                    Salvar
                  </PurpleButton>
                </Col>
              </Row>
            )}
          </footer>
        </ContentPicture>
      </Spin>
    </CustomModal>
  );
}

export default ModalProfilePicture;
