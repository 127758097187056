import { Col, Row, Spin } from 'antd';
import React, { FC } from 'react';
import { Box } from '../styles';
import { ResetMarginDescription } from '../../../../components/AttendCourse/styles';
import {
  getDescription,
  StatusAnswerEnum,
} from '../../../../core/enums/StatusAnswerEnum';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';

type IProps = {
  question: string;
  comment?: string | null;
  status?: StatusAnswerEnum | null;
  children: React.ReactNode;
  loading?: boolean;
};

function FieldWrapper({
  loading = false,
  comment,
  status,
  question,
  children,
}: IProps) {
  return (
    <Spin spinning={loading} delay={300}>
      <Box>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <ResetMarginDescription
              dangerouslySetInnerHTML={{ __html: question }}
            />
          </Col>
          <Col span={24}>{children}</Col>
          {
            <Col span={24}>
              {status && (
                <Text
                  weight={600}
                  color={
                    status === StatusAnswerEnum.Pending
                      ? '#EE7714'
                      : status === StatusAnswerEnum.Approved
                        ? '#319125'
                        : '#EC1616'
                  }
                >
                  {getDescription(status)}
                </Text>
              )}
              {comment && (
                <div>
                  <Title margin="8px 0 0 0" size={16} lineHeight={1.3}>
                    Comentários
                  </Title>
                  <Text>{comment}</Text>
                </div>
              )}
            </Col>
          }
        </Row>
      </Box>
    </Spin>
  );
}

export default FieldWrapper;
