import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Col, Divider, Empty, Row, Space, Spin } from 'antd';
import Text from '../Text';
import CardListCompent from './style';
import { useNavigate } from 'react-router-dom';
import IModel from '../../core/interfaces/models/IModel';
import { isEmpty } from '../../helpers/array.helper';
import FileService from '../../services/FileService';

type profileType = 'accelerator' | 'startup';

interface Entity extends IModel {
  name: string;
  email: string;
  countStartup?: number;
  description?: string;
  accelerator?: string;
  phase?: string;
  valuation?: string;
}

interface ICardList {
  urlViewDetail?: string;
  entity: Entity[];
  profileType: profileType;
}

type ImageItem = {
  loading?: boolean;
  src?: string;
};

function CardList({ urlViewDetail, entity, profileType }: ICardList) {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const md5 = require('md5');

  const fileService = useMemo(() => FileService.init(), []);

  const [imgs, setImgs] = useState<Record<number, ImageItem>>({});

  const viewDetail = (id: number) => {
    if (urlViewDetail) {
      navigate(urlViewDetail, { state: { id } });
    }
  };

  const getContent = (item: Entity) => {
    switch (profileType) {
      case 'accelerator':
        return (
          <>
            <Row
              style={{
                textAlign: 'left',
              }}
            >
              <Col xs={12}>
                <Text weight={700} size={12}>
                  Data de entrada
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item.creation}
                </Text>
              </Col>
              <Col xs={12}>
                <Text weight={700} size={12}>
                  Ultima Atualização
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item.lastUpdated}
                </Text>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 16,
                textAlign: 'left',
              }}
            >
              <Col xs={12}>
                <Text weight={700} size={12}>
                  Nº de Startups
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item.countStartup}
                </Text>
              </Col>
            </Row>
          </>
        );
      case 'startup':
        return (
          <>
            <Row>
              <Col
                xs={24}
                style={{
                  textAlign: 'left',
                }}
              >
                <Text weight={700} size={12}>
                  Ultima Atulização
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item?.lastUpdated}
                </Text>
              </Col>
            </Row>
            <Row
              style={{
                textAlign: 'left',
              }}
            >
              <Col xs={12}>
                <Text weight={700} size={12}>
                  Fase
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item?.phase}
                </Text>
              </Col>
              <Col xs={12}>
                <Text weight={700} size={12}>
                  Valuation
                </Text>
                <Text size={12} color={'#9AA4A8'}>
                  {item?.valuation}
                </Text>
              </Col>
            </Row>
          </>
        );
    }
  };

  const getImage = (index: number, img?: string) => {
    if (img) {
      setImgs({
        ...imgs,
        [index]: {
          loading: true,
        },
      });

      if (profileType === 'startup') {
        setImgs({
          ...imgs,
          [index]: {
            src: img,
          },
        });
      } else {
        fileService
          .get(img)
          .then((data) => {
            setImgs({
              ...imgs,
              [index]: {
                src: data,
              },
            });
          })
          .catch(() => {
            setImgs({
              ...imgs,
              [index]: {
                loading: false,
              },
            });
          });
      }
    }
  };

  useEffect(() => {
    entity.forEach((item: any, index: number) => {
      getImage(index, item.img);
    });
  }, [entity]);

  return (
    <Space wrap={true} size={16}>
      {isEmpty(entity) ? (
        <Empty description={'Não há dados para exibir'} />
      ) : (
        entity.map((item: any, key: number) => {
          return (
            <div key={`CARDLIS_${key}`}>
              <CardListCompent
                onClick={() => viewDetail(item.entityId)}
                bordered={true}
                bodyStyle={{
                  padding: 12,
                  height: 344,
                  width: 280,
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
              >
                <Spin spinning={imgs[key]?.loading ?? false}>
                  <Avatar
                    size={64}
                    src={
                      imgs[key]?.src ??
                      `https://www.gravatar.com/avatar/${md5(
                        item.email,
                      )}?d=identicon`
                    }
                  />
                </Spin>
                <Text
                  margin={'16px 0 0 0'}
                  color={'#960096'}
                  weight={700}
                  size={18}
                >
                  {item.name}
                </Text>
                <Text
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                  size={14}
                  color={'#9AA4A8'}
                >
                  {item?.description || ''}
                </Text>
                {/*Nome da aceleradora(opcional*/}
                <Text color={'#960096'} size={14}>
                  {item?.accelerator}
                </Text>
                <Divider
                  style={{
                    margin: '14px 0',
                  }}
                />
                {getContent(item)}
              </CardListCompent>
            </div>
          );
        })
      )}
    </Space>
  );
}

export default CardList;
