import api from '../core/services/api';
import CrudService from './CrudService';
import { errorCallback } from '../helpers/promise.helper';
import { IBusinessModel } from '../core/interfaces/IBusinessModel';

class BusinessModelService extends CrudService {
  static init(): BusinessModelService {
    return new BusinessModelService();
  }

  constructor() {
    super({
      requestMapping: '/businessModels',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IBusinessModel[]> {
    return api
      .get<IBusinessModel[]>(`${this.requestMapping}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public delete(id: number): Promise<any> {
    return api
      .delete(`${this.requestMapping}/${id}`)
      .then((response) => response.data)
      .catch(errorCallback);
  }

  public save(businessModel: IBusinessModel): Promise<IBusinessModel> {
    return api
      .post<IBusinessModel>(`${this.requestMapping}`, businessModel)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default BusinessModelService;
