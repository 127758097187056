import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../components/GoBack/GoBack';
import ScheduleWeekCalendar from '../../components/ScheduleCalendar/ScheduleWeekCalendar/ScheduleWeekCalendar';
import Text from '../../components/Text';
import { BoxContainer } from '../../GlobalStyles';
import { BoxSchedule, Header } from './style';
import moment from 'moment';
import useLoading from '../../core/hooks/useLoading';
import AuthZoomService from '../../services/zoom/AuthZoomService';
import { notification } from '../../helpers/notification.helper';
import ModalConfirm from '../courses/ModalConfirm';
import { useModal } from 'sunflower-antd';
import { SQL_FORMAT } from '../../constants/date-formats';
import ScheduleService from '../../services/ScheduleService';
import { ISchedule } from '../../core/interfaces/ISchedule';
import { useAuth } from '../../core/hooks/auth';

moment.locale('pt-br', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
});

function MentorshipScheduling() {
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { state } = useLocation();

  const { loading, hideLoading, showLoading } = useLoading();
  const zoomModal = useModal({
    defaultVisible: false,
  });

  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [date, setDate] = useState<string>(() =>
    moment(state?.date).format(SQL_FORMAT),
  );

  const scheduleService = useMemo(() => ScheduleService.init(), []);
  const authZoomService = useMemo(() => AuthZoomService.init(), []);

  const startAuthorize = () => {
    try {
      showLoading();
      authZoomService.authorize(moment(date).format(SQL_FORMAT));
    } catch (exception) {
      notification.info('Não foi possível se autenticar no zoom');
    }
  };

  const getAllScheduleByDateAndDate = useCallback(
    (date: string) => {
      scheduleService
        .getAllByUserMentorAndDate(
          Number(logged?.entityId),
          moment(date).format('DD-MM-YYYY'),
        )
        .then((data) => setSchedules(data))
        .catch(() => {
          notification.error('Erro ao buscar agendamentos');
        });
    },
    [scheduleService, logged],
  );

  useEffect(() => {
    if (authZoomService.fetchEnabled()) {
      showLoading();
      authZoomService
        .fetchToken()
        .then(() => {
          const params = new URLSearchParams(window.location.search);
          const state = params.get('state');
          if (state) setDate(state);

          notification.success('Autenticado com sucesso!');
          navigate('/mentorship/scheduling');
        })
        .catch(() => notification.error('Erro ao logar no zoom'))
        .finally(hideLoading);
    }
  }, [authZoomService, hideLoading, showLoading, navigate]);

  useEffect(() => {
    setTimeout(() => {
      if (authZoomService.hasToken()) {
        zoomModal.close();
      } else {
        zoomModal.show();
      }
    }, 100);
  }, [authZoomService, zoomModal]);

  useEffect(() => {
    if (date) getAllScheduleByDateAndDate(date);
  }, [date, getAllScheduleByDateAndDate]);

  return (
    <BoxContainer>
      <Header>
        <GoBack enableBackground={true} path="mentorship" />
        <div className="date">
          <Text size={20} weight={700}>
            {moment(date).format('MMMM [de] Y')}
          </Text>
        </div>
      </Header>
      <BoxSchedule>
        <ScheduleWeekCalendar
          schedules={schedules}
          reloadDates={() => getAllScheduleByDateAndDate(date)}
          date={date}
          minRange="04:00"
          maxRange="18:00"
        />
      </BoxSchedule>

      <ModalConfirm
        title="Zoom"
        loading={loading}
        textConfirm="Login Zoom"
        confirm={startAuthorize}
        {...zoomModal.modalProps}
        onCancel={() => navigate(-1)}
      >
        <Text align="center" size={15} margin="10px 0 20px 0">
          A plataforma <i>Innovation Studio</i> utiliza o Zoom para realizar as
          mentorias agendadas aqui. Clique no botão abaixo e entre em sua conta.
        </Text>
      </ModalConfirm>
    </BoxContainer>
  );
}

export default MentorshipScheduling;
