import { Col, Row } from 'antd';
import React from 'react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { BoxPreMoney } from '../../style';
import VentureCapitalSectionChart from './VentureCapitalSectionChart';
import { maskMoney } from '../../../../helpers/mask.helper';
import { IValueDrive } from '../../../../core/interfaces/IValueDrive';
interface IParams {
  preMoney?: number;
  vcs: IValueDrive[];
}
function VentureCapitalSection(props: IParams) {
  return (
    <section className="mt-1-print">
      <Title size={25}>Venture Capital</Title>
      <Row gutter={[12, 12]} style={{ marginBottom: '40px' }}>
        <Col xs={24} sm={24} md={16}>
          <Text size={17}>
            VC ou Capital de Risco é um método que se baseia na perspectiva do
            investidor para quantificar a saída do investimento. Por meio deste
            método é possível estimar por quanto a sua empresa seria vendida no
            futuro e trazer esse valor de venda a um valor presente. O valor de
            saída é calculado tomando o EBITDA do último ano projetado e
            aplicando o múltiplo do EBITDA. Este valor é então descontado a uma
            taxa determinada para obter o valor presente.
          </Text>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <BoxPreMoney boxShadow={'0'}>
            <Title size={20}>Valuation Pré-money</Title>
            <Title size={25} color="#960096">
              {maskMoney(props.preMoney || '0')}
            </Title>
          </BoxPreMoney>
        </Col>
      </Row>
      <VentureCapitalSectionChart vcs={props.vcs} />
    </section>
  );
}

export default VentureCapitalSection;
