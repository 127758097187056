import api from '../core/services/api';
import CrudService from './CrudService';
import { IStages } from '../core/interfaces/IStages';
import { errorCallback } from '../helpers/promise.helper';

class StagesService extends CrudService {
  static init(): StagesService {
    return new StagesService();
  }

  constructor() {
    super({
      requestMapping: '/stages',
      params: {
        sort: 'entityId, desc',
      },
    });
  }

  public listAll(): Promise<IStages[]> {
    return api
      .get<IStages[]>(`${this.requestMapping}/list`)
      .then((response) => response.data)
      .catch(errorCallback);
  }
}

export default StagesService;
