import React, { useEffect, useState } from 'react';
import TabGray from '../../tab/tabGray';
import Questions from './Questions';
import api from '../../../core/services/api';
import IPillars from '../../../core/interfaces/models/IPillars';
import AlertMessage from '../../Alert/AlertMessage';
import useLoading from '../../../core/hooks/useLoading';
import Loading from '../../Loading/Loading';

interface IParams {
  setFormName: (formName: string) => void;
  ask: boolean;
  setAsk: (ask: boolean) => void;
  nameDiagnostic: string;
  setNext: (next: string) => void;
  initialData?: any;
  setData?: (data: any) => void;
  closeForm: () => void;
}

function QuestionsAssociation(props: IParams) {
  const [pillars, setPillars] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [tabGrey, setTabGray] = useState<string>('0');
  const { loading, showLoading, hideLoading } = useLoading();
  const [nameStorage, setNameStorage] = useState<string>('');

  const getPillars = () => {
    showLoading();
    api
      .get('/methodology/listPillarsByDiagnostic', {
        params: {
          diagnostic: props.initialData?.entityId,
        },
      })
      .then((response: any) => {
        setPillars(response.data);
      })
      .finally(hideLoading);
  };

  const createTabToPillars = (pillarArray: IPillars[]) => {
    return pillarArray.map((pillar: IPillars) => {
      return {
        tab: pillar.name,
        body: (
          <Questions
            idPillars={pillar.entityId ?? 0}
            ask={props.ask}
            setAsk={props.setAsk}
            setFormName={props.setFormName}
            nameDiagnostic={props.nameDiagnostic}
            initialData={props.initialData}
            setData={props.setData}
            next={tabGrey}
            setNext={setTabGray}
            setNameStorage={setNameStorage}
          />
        ),
      };
    });
  };

  const onOk = () => {
    props.setNext('0');
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (props.initialData === undefined) {
      setIsOpenModal(true);
    } else {
      getPillars();
    }
  }, []);

  useEffect(() => {
    const { length } = pillars;
    if (length && Number(tabGrey) === length) {
      props.closeForm();
      sessionStorage.removeItem(nameStorage);
      sessionStorage.length === 0 && sessionStorage.clear();
      while (sessionStorage.length > 0) {
        sessionStorage.removeItem(sessionStorage.key(0) ?? '');
      }
    }
  }, [tabGrey, nameStorage]);

  return (
    <>
      <Loading
        position={'absolute'}
        spinning={loading}
        width={300}
        height={300}
      >
        <TabGray
          content={createTabToPillars(pillars)}
          tabKey={tabGrey}
          setNext={setTabGray}
        />

        <AlertMessage
          visible={isOpenModal}
          message="Atenção!"
          description="Você precisa adicionar uma metodologia para poder adicionar perguntas."
          textCancelButton="Fechar"
          textOkButton="Adicionar metodologia"
          onCancel={() => {
            setIsOpenModal(false);
          }}
          onOk={onOk}
        />
      </Loading>
    </>
  );
}

export default QuestionsAssociation;
